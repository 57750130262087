import React, {useContext, useEffect, useState} from 'react';
import {
  FaDog,
  FaLuggageCart,
  FaWalking,
} from "react-icons/fa";
import {BiAddToQueue} from 'react-icons/bi';
import {RiExchangeFill, RiMapPinUserLine, RiReservedFill} from 'react-icons/ri';
import {
  cancelReservationsInAllTripsOrders,
  getStatusText,
  sendMessageAboutDeletingForDriver,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import TripStatusBadge from "./TripStatusBadge";
import DisplayTripPointsData from "./DisplayTripPointsData";
import PlacesInTripDisplaying from "./PlacesInTripDisplaying";
import ModalTestimonials from "../modals/ModalTestimonials";
import {FcApproval} from "react-icons/fc";
import {AiFillSafetyCertificate} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import LoaderSmall from "./LoaderSmall";
import DisplayVehicleData from "./DisplayVehicleData";
import DisplayUserData from "./DisplayUserData";
import ModalConfirmAction from "../modals/ModalConfirmAction";
import {
  deleteDocumentFromCollectionWithID, getDocInCollection
} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, pagesInterface} from "../utils/models";
import {
  getTranslatedLocalityName
} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import {tripService} from "../utils/tripService";

const DisplayTripDetails = ({
  isNotePad = true,
  addClasses = '',
  handleShowAddTripScreen = () => {},
  handleShowTripPublishScreen = () => {},
  isFromTripReservation = false,
  handleShowReservations = () => {},
  isFromExchange = false,
  handleSearchMyPassengersForTrip = () => {},
  isDispatcher = false,
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip, setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Детали поездки');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTrip?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Loaders
  const [isLoading, setIsLoading] = useState(false);
  const [isBoardingAddressesLoading, setIsBoardingAddressesLoading]
    = useState(false);
  //#endregion

  //#region Manage displaying tripPoints
  const [areTripPointsVisible, setAreTripPointsVisible] = useState(false);
  //#endregion

  //#region Manage displaying scheme
  const [isSchemeVisible, setIsSchemeVisible] = useState(false);
  //#endregion

  //#region Manage displaying testimonials
  const [areTestimonialsVisible, setAreTestimonialsVisible] = useState(false);
  const [currentDriver, setCurrentDriver] = useState({});

  const hideTestimonials = () => {
    setAreTestimonialsVisible(false);
    setCurrentDriver({});
  }
  //#endregion

  //#region Handle show intermediate points click
  const handleShowIntermediatePointsClick = () => {
    setAreTripPointsVisible(state => !state);
  };
  //#endregion

  //#region Handle search passengers click
  const handleSearchPassengersClick = async (currentTrip, user) => {
    setIsLoading(true);

    try {
      await handleSearchMyPassengersForTrip(currentTrip, user);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Delete confirmation modal
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  //#endregion

  //#region Delete trip
  const handleDeleteClick = () => {
    setIsConfirmationVisible(true);
    setConfirmationTitle('Подтверждение действия');
    setConfirmationMessage('Вы действительно хотите удалить поездку?');
  }

  const confirmDelete = async () => {
    try {
      setIsLoading(true);
      setIsConfirmationVisible(false);

      const actualTrip = await getDocInCollection(collectionsInterface
        .trips, currentTrip.idPost);

      if (actualTrip.hasAnyReservations) {
        await cancelReservationsInAllTripsOrders(actualTrip, 'Перевозчик удалил поездку');
      }

      await deleteDocumentFromCollectionWithID(collectionsInterface.trips,
        currentTrip.idPost);

      actualTrip.drivers.forEach(driver => {
        if (driver.idPost !== user.idPost) {
          sendMessageAboutDeletingForDriver(user.companyName || user.fullName, actualTrip, {
            idPost: driver.idPost,
            email: driver.email,
          })
        }
      });

      if (isNotePad) {
        navigate('/notepad');
      } else {
        navigate('/trips/myTrips');
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  const cancelDelete = () => {
    setIsConfirmationVisible(false);
  }
  //#endregion

  //#region Handle copy trip
  const handleCopyTrip = async () => {
    setIsLoading(true);

    const tripCopy = await tripService.getTripCopyForNewTrip(currentTrip.idPost);

    setCurrentTrip(tripCopy);

    if (isNotePad) {
      navigate('/notepad/createTrip');
    } else {
      navigate(`/${pagesInterface.trips}/createTrip`);
    }

    setIsLoading(false);
  }
  //#endregion

  //#region Handle move to active
  const handleMakeActive = async () => {
    setIsLoading(true);

    try {
      const modifiedTrip = await tripService.getTripToActivate(currentTrip.idPost);

      setCurrentTrip(modifiedTrip);

      navigate(`/${pagesInterface.trips}/createTrip`);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Buttons displaying
  const defineIfDeleteButtonShouldBeDisplayed = () => {
    return ((!isDispatcher && !isFromExchange && !isFromTripReservation) && (
      !currentTrip.isActive && !currentTrip.hasGivenAwayPassengers))
  }

  const defineIfCopyButtonShouldBeDisplayed = () => {
    return ((!isFromExchange && !isFromTripReservation && currentTrip
      .creatorIdPost === user.idPost) && (!currentTrip.isCancelled));
  }

  const defineIfMainActionsButtonsShouldBeShown = () => {
    return (((user.idPost === currentTrip.creatorIdPost)
      && !isFromTripReservation) && !currentTrip?.isCancelled)
  }

  const defineIfMoveToActualButtonShouldBeShown = () => {
    return currentTrip.isCancelled && currentTrip.acceptedDeals === null;
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div className={`TripDetails ${addClasses}`}>
          {!isFromTripReservation &&
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>
          }

          <div className={'TripDetails-Head'}>
            <div
              className={'TripDetails-Block TripDetails-DepartureTime'}>
              {`${currentTrip?.startDate ? showFormattedToLanguageDate(lang,
                currentTrip?.startDate, true) : 'Invalid time'}`}
            </div>

            <div className={'TripDetails-StatusBadge'}>
              <TripStatusBadge
                statusText={getStatusText(currentTrip?.status)}
              />
            </div>
          </div>

          <h5 className={'RegularTitle'}>
            {currentTrip?.companyName}
          </h5>

          {currentTrip?.drivers?.map((driver, dIndex) => (
            <React.Fragment key={dIndex}>
              <DisplayUserData
                userId={driver.idPost}
                isFullInfo={true}
                isDriver={true}
                isFavouriteIconVisible={(currentTrip?.creatorIdPost
                  && user.idPost !== currentTrip?.creatorIdPost)}
                areContactsVisible={!isDispatcher}
              />
            </React.Fragment>
          ))}

          <div className={'TripDetails-Block'}>
            <DisplayVehicleData
              vehicle={currentTrip?.vehicle}
              isFullInfo={true}
            />
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div
            className={'TripDetails-Block TripDetails-RouteBlock'}
          >
            <DisplayTripPointsData
              tripPoints={[currentTrip?.startPoint, currentTrip?.endPoint]}
            />
          </div>

          <div className={'TripDetails-Block FlexBlock'}>
            {currentTrip?.tripPoints?.length > 2 &&
              <button
                type={'button'}
                className={currentTrip?.isInProgress ?
                  'RegularButton FlexBlock-HorizontalItem ' +
                  'FlexBlock-HorizontalItem_W60'
                  : 'RegularButton'
                }
                onClick={() => handleShowIntermediatePointsClick()}
              >
                {translate('Промежуточные пункты')}
              </button>
            }

            {currentTrip?.isInProgress &&
              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast ' +
                  'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
                onClick={() => {
                }}
              >
                {translate('Авто на карте')}
              </button>
            }
          </div>

          {areTripPointsVisible &&
            <DisplayTripPointsData
              tripPoints={currentTrip?.tripPoints?.slice(1,
                currentTrip?.tripPoints.length - 1)}
            />
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div
            className={'TripDetails-Block TripDetails-TripOrderType'}
          >
            <span className='TripDetails-BlockLabel'>
              {`${translate('Тип поездки')}: `}
            </span>

            {`${currentTrip.isPassengerTrip ?
              translate('Пассажирская')
              :
              translate('Грузовая')
            }`}
          </div>

          {currentTrip?.isPassengerTrip &&
            <div className={'TripDetails-Block'}>
              <h6 className={'RegularTitle RegularTitle_Small'}>
                {translate('Места посадки пассажиров')}
              </h6>

              <div>
                <span className='TripDetails-BlockLabel'>
                  {currentTrip?.isBoardingByDriverAddresses ?
                    `${translate('Адреса водителя')}: `
                    : `${translate('По адресу пассажира')}`
                  }
                </span>

                {isBoardingAddressesLoading ? <LoaderSmall />
                  :
                  <div>
                    {currentTrip.boardingPlacesFromDriver?.map(address =>
                      <div key={address?.placeId}>
                        {getTranslatedLocalityName(address?.fullAddress, lang)}
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div
            className={'TripDetails-Block TripDetails-TripPrice'}
          >
            <div>
              <span className='TripDetails-BlockLabel'>
                {`${translate('Стоимость поездки')}: `}
              </span>

              {`${currentTrip?.pricePerPerson || 0}
                ${translate('грн.')}`}
            </div>

            {!isNotePad &&
              <>
                <div>
                  <span className='TripDetails-BlockLabel'>
                    {`${translate('Гонорар диспетчера')}: `}
                  </span>

                  {`${currentTrip.dispatcherFee || 0}
                    ${translate('грн.')}`}
                </div>

                <div>
                  <span
                    className='TripDetails-SecondaryBlockLabel'>
                      {`${translate('Тип получения')}: `}
                  </span>

                  <div className={'TripDetails-SecondaryBlockText'}>
                    {currentTrip?.isPaymentOnCard &&
                      <div className={'FlexBlock FlexBlock_JustifyLeft'}>
                        <span>
                          {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                        </span>

                        <span
                          className={'ColoredText TripDetails-SafeBadge'}
                        >
                            <AiFillSafetyCertificate/>
                        </span>
                      </div>
                    }

                    {currentTrip?.isPaymentByCash &&
                      <div>
                        {translate('Наличными')}
                      </div>
                    }

                    {currentTrip?.isForChange &&
                      <div>
                        {translate('По обмену')}
                      </div>
                    }
                  </div>
                </div>
              </>
            }
          </div>

          {currentTrip?.isPassengerTrip &&
            <>
              <div className={'TripDetails-Block'}>
                <span className='TripDetails-BlockLabel'>
                  {`${translate('Тип рассадки')}: `}
                </span>

                <span>
                  {currentTrip?.isFreeSeating ? translate('Свободная')
                    : translate('Индивидуальная')}
                </span>
              </div>

              {(!currentTrip?.isFreeSeating && currentTrip?.placesScheme?.rows) &&
                <>
                  <div className={'TripDetails-Block'}>
                    <button
                      type={'button'}
                      className={'RegularButton RegularButton_Contrast'}
                      onClick={() => {
                        setIsSchemeVisible(state => !state)
                      }}
                    >
                      {isSchemeVisible ? translate('Скрыть места')
                        : translate('Посмотреть места')}
                    </button>
                  </div>

                  <div className={'TripDetails-Block'}>
                    {isSchemeVisible &&
                      <PlacesInTripDisplaying
                        scheme={currentTrip?.placesScheme || []}
                      />
                    }
                  </div>
                </>
              }
            </>
          }

          {currentTrip.isPassengerTrip &&
            <>
              <div
                className={'TripDetails-Block TripDetails-PlacesAmount'}
              >
                <span className='TripDetails-BlockLabel'>
                  {`${translate('Количество свободных мест')}: `}
                </span>

                {`${currentTrip?.freePlaces}`}
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          {currentTrip?.isPlaceForCargo &&
            <>
              <div className={'TripDetails-Block'}>
                {translate('Грузовое место')}

                <p className={''}>
                  {currentTrip?.cargoDescription || translate('Отсутствует')}
                </p>
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          {currentTrip?.isPassengerTrip &&
            <>
              <div
                className={'TripDetails-Block FlexBlock FlexBlock_JustifyLeft ' +
                  'TripDetails-OptionsBlock'}
              >
                {currentTrip?.isByFoot &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <FaWalking/>
                  </p>
                }

                {currentTrip?.isTripWithPets &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <FaDog/>
                  </p>
                }

                {currentTrip?.isForChange &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <RiExchangeFill/>
                  </p>
                }

                {currentTrip?.isPassengerAddress &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <RiMapPinUserLine/>
                  </p>
                }

                {currentTrip?.isSeparatePlaceForLuggage &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <FaLuggageCart/>
                  </p>
                }

                {currentTrip?.addOptions?.length > 0 &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <BiAddToQueue/>
                  </p>
                }

                {currentTrip?.hasAnyReservations &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <RiReservedFill/>
                  </p>
                }

                {currentTrip?.isPaymentOnCard &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <AiFillSafetyCertificate/>
                  </p>
                }
              </div>
            </>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {currentTrip?.addOptions?.length > 0 &&
            <>
              <div className={'TripDetails-Block'}>
                <h5 className={'RegularTitle RegularTitle_Small'}>
                  {translate('Дополнительные опции')}
                </h5>

                {currentTrip?.addOptions?.map((option, oIndex) => {
                  if (option.checked) {
                    return (
                      <p
                        key={oIndex}
                        className={
                          'FlexBlock FlexBlock_JustifyLeft TripDetails-Option'
                        }
                      >
                        <FcApproval/>

                        <span className={'TripDetails-AddOptionText'}>
                          {option?.optionContent[lang] || option?.optionContent
                            ?.defaultName}
                        </span>
                      </p>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {defineIfMainActionsButtonsShouldBeShown() &&
            <>
              {isNotePad &&
                <div className={'TripDetails-Block'}>
                  <button
                    type={'button'}
                    className={'RegularButton TripDetails-FindTripButton'}
                    onClick={() => handleShowTripPublishScreen(setIsLoading)}
                  >
                    {translate('Перенести в раздел Поездки')}
                  </button>
                </div>
              }

              {currentTrip?.hasAnyReservations &&
                <div className={'TripDetails-Block'}>
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={() => handleShowReservations(currentTrip)}
                  >
                    {translate('Посмотреть брони')}
                  </button>
                </div>
              }

              <div className={'TripDetails-Block'}>
                <button
                  type={'button'}
                  className={'RegularButton'}
                  onClick={() => handleShowAddTripScreen(currentTrip)}
                >
                  {translate('Редактировать')}
                </button>
              </div>
            </>
          }

          {defineIfMoveToActualButtonShouldBeShown() &&
            <div className={'TripDetails-Block'}>
              <button
                type={'button'}
                className={'RegularButton'}
                onClick={handleMakeActive}
              >
                {translate('Перевести в актуальные')}
              </button>
            </div>
          }

          {isFromExchange &&
            <>
              <div className={'TripDetails-Item'}>
                <div className={'PaddingBlock'}></div>

                <button
                  type={'button'}
                  className={'RegularButton'}
                  onClick={() => handleSearchPassengersClick(currentTrip, user)}
                >
                  {translate('Найти пассажиров')}
                </button>
              </div>
            </>
          }

          {defineIfDeleteButtonShouldBeDisplayed() &&
            <div className={'TripDetails-Block'}>
              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast' +
                  ' RegularButton_Danger'}
                onClick={handleDeleteClick}
              >
                {translate('Удалить')}
              </button>
            </div>
          }

          {defineIfCopyButtonShouldBeDisplayed() &&
            <div className={'TripDetails-Block'}>
              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast' +
                  ' RegularButton_Danger'}
                onClick={() => handleCopyTrip()}
              >
                {translate('Копировать поездку')}
              </button>
            </div>
          }
        </div>
      }

      {areTestimonialsVisible &&
        <ModalTestimonials
          personUid={currentDriver.uid}
          showModal={areTestimonialsVisible}
          hideTestimonials={hideTestimonials}
          isDispatcher={isDispatcher}
        />
      }

      {isConfirmationVisible &&
        <ModalConfirmAction
          message={confirmationMessage}
          title={confirmationTitle}
          isVisible={isConfirmationVisible}
          hideModal={() => setIsConfirmationVisible(false)}
          confirmAction={confirmDelete}
          cancelAction={cancelDelete}
        />
      }
    </>
  );
  //#endregion
};

export default DisplayTripDetails;
