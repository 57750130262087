import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  catalogsNamesInterface,
  collectionsInterface,
  rolesInterface
} from "../utils/models";
import {getCatalogName} from "../utils/helpers";
import {
  getCollection,
  updateDocumentInCollection,
  updateFieldInDocumentInCollection,
  updateFieldsInDocumentInCollection,
} from "../utils/firebaseConfigAndFunctions";
import DisplayCatalog from "./DisplayCatalog";
import {Dropdown} from "react-bootstrap";
import DisplayBrandsForTranslation from "./DisplayBrandsForTranslation";
import useTranslation from "../hooks/useTranslation";
import '../styles/Catalog.scss';

const Catalogs = ({
  addClasses = '',
}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get language from context, manage languages
  const {
    lang,
    dictionaries,
  } = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }
    setScreenTitle('Справочники');
  }, []);
  //#endregion

  //#region Loaders
  const [loaders, setLoaders] = useState({
    vehiclesBodyTypes: true,
    vehicleColors: true,
    vehicleBrands: true,
  })
  //#endregion

  //#region Set shorten dictionaries info
  const [dictionariesInfo, setDictionariesInfo] = useState([]);

  useEffect(() => {
    if (dictionaries.length > 0) {
      const info = dictionaries.map(dictionary => ({
        name: dictionary.name,
        description: dictionary.description,
      }));

      setDictionariesInfo(info);
    }
  }, [dictionaries]);
  //#endregion

  //#region Catalogs and their handling
  const [catalogsIds, setCatalogsIds] = useState([]);
  const [vehiclesBodyTypes, setVehiclesBodyTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleColors, setVehicleColors] = useState([]);
  const [currentCatalog, setCurrentCatalog] = useState(
    catalogsNamesInterface.bodyTypes);
  const [currentBrand, setCurrentBrand] = useState({});
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  useEffect(() => {
    switch (currentCatalog) {
      case catalogsNamesInterface.brands:
        if (vehicleBrands.length === 0) {
          setLoaders(state => ({
            ...state,
            vehicleBrands: true,
          }));

          getCollection(collectionsInterface.vehiclesCatalog)
            .then(result => {
              setVehicleBrands(Object.values(result[0].vehiclesCatalog));
              setCatalogsIds(state => ({
                ...state,
                brandsId: result[0].idPost,
              }));
              setCurrentBrand(Object.values(result[0].vehiclesCatalog)[0]);
            })
            .catch(() => {})
            .finally(() => setLoaders(state => ({
              ...state,
              vehicleBrands: false,
            })));
        }
        break;
      case catalogsNamesInterface.colors:
        if (vehicleColors.length === 0) {
          setLoaders(state => ({
            ...state,
            vehicleColors: true,
          }));

          getCollection(collectionsInterface.vehiclesColors)
            .then(result => {
              setVehicleColors(result[0].vehiclesColors);
              setCatalogsIds(state => ({
                ...state,
                colorsId: result[0].idPost,
              }));
            })
            .catch(() => {})
            .finally(() =>  setLoaders(state => ({
              ...state,
              vehicleColors: false,
            })));
        }
        break;
      default:
        setLoaders(state => ({
          ...state,
          vehiclesBodyTypes: true,
        }));

        getCollection(collectionsInterface.vehiclesBodyTypes)
          .then(result => {
            setVehiclesBodyTypes(result[0].vehiclesBodyTypes);
            setCatalogsIds(state => ({
              ...state,
              bodyTypesId: result[0].idPost,
            }));
          })
          .catch(() => {})
          .finally(() => setLoaders(state => ({
            ...state,
            vehiclesBodyTypes: false,
          })));
        break;
    }
  }, [currentCatalog]);

  const updateCatalog = (newValue, id, languageType, catalogName) => {
    switch (catalogName) {
      case catalogsNamesInterface.bodyTypes:
        setVehiclesBodyTypes(bodyTypes => bodyTypes
          .map(bodyType => bodyType.idPost === id ? {
          ...bodyType,
          [languageType]: newValue,
        } : bodyType));
        break;
      case catalogsNamesInterface.brands:
        setVehicleBrands(brands => brands
          .map(brand => brand.idPost === id ? {
          ...brand,
          [languageType]: newValue,
        } : brand));
        setCurrentBrand(brand => ({
          ...brand,
          [languageType]: newValue,
        }))
        break;
      case catalogsNamesInterface.models:
        const updatedModels = currentBrand.models
          .map(model => model.id === id ? {
          ...model,
          [languageType]: newValue,
        } : model);

        setVehicleBrands(brands => brands
          .map(brand => brand.idPost === currentBrand.idPost ? {
          ...brand,
          models: updatedModels,
        } : brand));
        setCurrentBrand(brand => ({
          ...brand,
          models: updatedModels,
        }))
        break;
      case catalogsNamesInterface.colors:
        setVehicleColors(colors => colors
          .map(color => color.idPost === id ? {
          ...color,
          [languageType]: newValue,
        } : color));
        break;
    }
  };

  const saveBodyTypesCatalog = async (catalogId) => {
    setLoaders(state => ({
      ...state,
      vehiclesBodyTypes: true,
    }));

    try {
      await updateDocumentInCollection(collectionsInterface.vehiclesBodyTypes, {
        idPost: catalogId,
        vehiclesBodyTypes: vehiclesBodyTypes,
      }, catalogId);
    } catch (error) {
      return error;
    } finally {
      setLoaders(state => ({
        ...state,
        vehiclesBodyTypes: false,
      }));
    }
  };

  const saveColorsCatalog = async (catalogId) => {
    setLoaders(state => ({
      ...state,
      vehicleColors: true,
    }));

    try {
      await updateDocumentInCollection(collectionsInterface.vehiclesColors, {
        idPost: catalogId,
        vehiclesColors: vehicleColors,
      }, catalogId);
    } catch (error) {
      return error;
    } finally {
      setLoaders(state => ({
        ...state,
        vehicleColors: false,
      }));
    }
  };

  const saveBrandInBrandsCatalog = async (catalogId) => {
    setLoaders(state => ({
      ...state,
      vehicleBrands: true,
    }));

    try {
      let fieldsToUpdate = {
        [`vehiclesCatalog.${currentBrand.id}.defaultName`]:
        currentBrand.defaultName,
      };

      dictionariesInfo.forEach(info => fieldsToUpdate = {
        ...fieldsToUpdate,
        [`vehiclesCatalog.${currentBrand.id}.${info.name}`]:
          currentBrand[info.name],
      });

      await updateFieldsInDocumentInCollection(
        collectionsInterface.vehiclesCatalog, catalogId, fieldsToUpdate);
    } catch (error) {
      return error;
    } finally {
      setLoaders(state => ({
        ...state,
        vehicleBrands: false,
      }));
    }
  };

  const saveModelInBrandsCatalog = async (catalogId) => {
    setLoaders(state => ({
      ...state,
      vehicleBrands: true,
    }));

    try {
      await updateFieldInDocumentInCollection(collectionsInterface
        .vehiclesCatalog, catalogId, `vehiclesCatalog.${
        currentBrand.id}.models`, currentBrand.models);
    } catch (error) {
      return error;
    } finally {
      setLoaders(state => ({
        ...state,
        vehicleBrands: false,
      }));
    }
  }
  //#endregion

  const [catalogsNames, setCatalogsNames] = useState([]);

  useEffect(() => {
    setCatalogsNames([
      {
        type: catalogsNamesInterface.bodyTypes,
        name: translate('Типы кузова'),
      },
      {
        type: catalogsNamesInterface.brands,
        name: translate('Марки авто'),
      },
      {
        type: catalogsNamesInterface.colors,
        name: translate('Цвета авто')
      }

    ])
  }, [lang]);

  const handleCatalogSelect = (catalog) => {
    setCurrentCatalog(catalog.type);
  }

  //#region Render
  return (
    <>
      <div className={`Catalogs ${addClasses}`}>
        <div className={'PaddingBlock'}></div>
        <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {translate(screenTitle)}
        </h2>

        <div className={'FlexBlock'}>
          <span className={'DataLabel'}>
            {translate('Выбор справочника')}
          </span>

          <Dropdown
            className={'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W50'}
            defaultValue={catalogsNamesInterface.bodyTypes}
          >
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              className={'FullWidthButton'}
            >
              {translate(getCatalogName(currentCatalog))}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {catalogsNames.map((catalog, index) => {
                return (
                  <React.Fragment key={index}>
                    <Dropdown.Item
                      value={catalog.type}
                      onClick={() => handleCatalogSelect(catalog)}
                    >
                      {catalog.name}
                    </Dropdown.Item>
                  </React.Fragment>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className={'PaddingBlock'}></div>

        {currentCatalog === catalogsNamesInterface.bodyTypes &&
          <DisplayCatalog
            catalog={vehiclesBodyTypes}
            dictionariesInfo={dictionariesInfo}
            updateCatalog={updateCatalog}
            catalogId={catalogsIds.bodyTypesId}
            saveCatalog={saveBodyTypesCatalog}
            catalogName={catalogsNamesInterface.bodyTypes}
            isReduced={false}
            isLoading={loaders.vehiclesBodyTypes}
            isPagination={true}
          />
        }

        {currentCatalog === catalogsNamesInterface.colors &&
          <DisplayCatalog
            catalog={vehicleColors}
            dictionariesInfo={dictionariesInfo}
            updateCatalog={updateCatalog}
            catalogId={catalogsIds.colorsId}
            saveCatalog={saveColorsCatalog}
            catalogName={catalogsNamesInterface.colors}
            isReduced={false}
            isLoading={loaders.vehicleColors}
            isPagination={true}
          />
        }

        {currentCatalog === catalogsNamesInterface.brands &&
          <>
            <DisplayBrandsForTranslation
              catalogId={catalogsIds.brandsId}
              brands={vehicleBrands}
              dictionariesInfo={dictionariesInfo}
              currentBrand={currentBrand}
              setCurrentBrand={setCurrentBrand}
              currentBrandIndex={currentBrandIndex}
              setCurrentBrandIndex={setCurrentBrandIndex}
              saveCatalog={saveBrandInBrandsCatalog}
              updateCatalog={updateCatalog}
              isLoading={loaders.vehicleBrands}
            />

            <div className={'PaddingBlock'}></div>

            <DisplayCatalog
              catalog={currentBrand.models}
              dictionariesInfo={dictionariesInfo}
              updateCatalog={updateCatalog}
              catalogId={catalogsIds.brandsId}
              saveCatalog={saveModelInBrandsCatalog}
              isReduced={true}
              catalogName={catalogsNamesInterface.models}
              isLoading={loaders.vehicleBrands}
              additionalId={currentBrand.idPost}
              isPagination={true}
            />
          </>
        }

        <div className={'PaddingBlock'}></div>
      </div>
    </>
  );
  //#endregion
};

export default Catalogs;
