import React, {useEffect, useState} from 'react';
import {AiFillSave} from "react-icons/ai";
import {getCatalogName} from "../utils/helpers";
import Loader from "./Loader";
import {entitiesNumberInterface} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";

const DisplayCatalog = ({
  addClasses = '',
  dictionariesInfo = [],
  catalog = [],
  updateCatalog = () => {},
  saveCatalog = () => {},
  catalogId = '',
  catalogName = '',
  areButtonsForEntity = false,
  buttonsData = [],
  isLoading = false,
  isReduced = false,
  additionalId = null,
  isPagination = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [catalogChunk, setCatalogChunk] = useState([]);
  const [portionSize] = useState(isPagination ? (isReduced
    ? entitiesNumberInterface.catalogsReduced
    : entitiesNumberInterface.catalogs) : catalog.length)

  useEffect(() => {
    if (additionalId) {
      setStartPage(1);
      setCurrentPage(1);
    }
  }, [additionalId]);

  useEffect(() => {
    setCatalogChunk(catalog
      .slice((currentPage - 1) * portionSize,
        currentPage * portionSize));
  }, [currentPage, catalog.length]);
  //#endregion

  //#region Update data
  const updateData = (newValue, element, dictionaryName, catalogName,
    isIdPost = false) => {
    const idField = isIdPost ? 'idPost' : 'id';

    setCatalogChunk(chunk => chunk
      .map(item => item[idField] === element[idField] ? {
        ...item,
        [dictionaryName]: newValue,
      } : item
    ));

    updateCatalog(newValue, element[idField], dictionaryName,
      catalogName);
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`Catalog ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small TitleWithButton'}>
            {translate(getCatalogName(catalogName))}

            <button
              className={'SaveButton'}
              type={'button'}
              onClick={() => saveCatalog(catalogId)}
            >
              <AiFillSave/>
            </button>
          </h2>

          <div className={'DataListWithPagination Catalog-DataList'}>
            <table className={'DataTableForAdmin'}>
              <thead>
                <tr>
                  <th
                    className={`DataTableForAdmin-Cell HeaderCell HeaderCell_W${
                      Math.round(100 / (dictionariesInfo.length + 1) + (
                        areButtonsForEntity ? 5 : 0))}`}
                  >
                    {translate('Дефолт')}
                  </th>

                  {dictionariesInfo.map((dictionary, index) =>
                    <React.Fragment key={index}>
                      <th
                        className={'DataTableForAdmin-Cell HeaderCell'}
                      >
                        {dictionary.name.toUpperCase()}
                      </th>
                    </React.Fragment>
                  )}
                </tr>
              </thead>

            <tbody className={'DataTableForAdmin-Body'}>
              {catalogChunk?.map((element, eIndex) =>
                <React.Fragment key={element.idPost || element.id}>
                  <tr className={'Catalog-DataRow'}>
                    <td className={'DataTableForAdmin-Cell'}>
                      <div className={'DataTableForAdmin-CellWithButtons'}>
                        {areButtonsForEntity &&
                          <div className={'DataTableForAdmin-ButtonsBlock'}>
                            {buttonsData.map((buttonData, index) => (
                              <React.Fragment key={index}>
                                <button
                                  className={'DataTableForAdmin-EntityButton'}
                                  type={'button'}
                                  onClick={() => buttonData.handler(
                                    buttonData.type, !element[buttonData
                                    ?.valueName] || '', eIndex, element.idPost ||
                                    element.id)}
                                >
                                  {index === 0 && buttonData.icon.length > 1
                                    ? (element.isVisible ? buttonData.icon[1]
                                      : buttonData.icon[0])
                                    : buttonData.icon
                                  }
                                </button>
                              </React.Fragment>
                            ))}
                          </div>
                        }

                        <textarea
                          value={element.defaultName}
                          onChange={(event) =>  updateData(event.target.value,
                            element, 'defaultName', catalogName,
                            !!element.idPost)}
                          className={'FormControl Catalog-TextArea'}
                        />
                      </div>
                    </td>

                    {dictionariesInfo.map((dictionary, dIndex) =>
                      <React.Fragment key={dIndex}>
                        <td className={'DataTableForAdmin-Cell'}>
                          <textarea
                            value={element[dictionary.name]}
                            onChange={(event) => updateData(
                              event.target.value, element, dictionary.name,
                              catalogName, !!element.idPost)}
                            className={'FormControl Catalog-TextArea'}
                          />
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>

            {(isPagination && catalog.length > portionSize) &&
              <PaginationBlock
                startPage={startPage}
                currentPage={currentPage}
                setStartPage={setStartPage}
                setCurrentPage={setCurrentPage}
                portionSize={portionSize}
                listLength={catalog.length}
              />
            }
          </div>
        </div>
      }
    </>
  );
  //#endregion
};

export default DisplayCatalog;
