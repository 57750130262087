import React, {useContext} from 'react';
import {
  FaDog,
  FaLuggageCart,
  FaWalking,
} from "react-icons/fa";
import {AiFillSafetyCertificate} from 'react-icons/ai';
import {BiAddToQueue} from 'react-icons/bi';
import {GiBus, GiTruck} from 'react-icons/gi';
import {RiExchangeFill, RiMapPinUserLine} from 'react-icons/ri';
import {AppContext} from "../context/appContext";
import {tripOrderModel} from "../utils/models";
import {
  checkIsTripOrderAlreadyReservedInTrip,
  getStatusText,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import TripStatusBadge from "./TripStatusBadge";
import DisplayTripPointsData from "./DisplayTripPointsData";
import DisplayVehicleData from "./DisplayVehicleData";
import DisplayUserData from "./DisplayUserData";
import useTranslation from "../hooks/useTranslation";
import {indicatorsNewService} from "../utils/indicatorsNewService";

const TripCard = ({
  trip,
  addClasses = '',
  handleShowTripDetailsScreen = () => {},
  isDispatcher = false,
  tripOrder = tripOrderModel,
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Render
  return (
    <div
      onClick={() => {
        handleShowTripDetailsScreen(trip);
        setCurrentTrip(trip);
      }}
      className={`TripCard ${addClasses}`}
    >
      <div className='FlexBlock TripCard-Item FlexBlock_JustifyLeft'>
        {trip?.isPassengerTrip ?
          <>
            <div
              className={'TripCard-HeadBlock TripCard-DateBlock'}>
              {trip?.startDate ? showFormattedToLanguageDate(lang,
                  trip?.startDate) : 'Invalid time'}
            </div>

            <div
              className={'TripCard-HeadBlock TripCard-PriceBlock'}>
              {`${trip?.pricePerPerson || ''} ${translate('грн')}`}
            </div>

            <div
              className={'TripCard-HeadBlock TripCard-PlacesAmountBlock'}>
              {`${translate('Мест')}: ${trip?.freePlaces || 0}`}
            </div>
          </> :
          <>
            <div
              className={'TripCard-HeadBlock TripCard-DateBlock'}>
              {trip?.startDate ? showFormattedToLanguageDate(lang,
                  trip?.startDate) : 'Invalid time'}
            </div>

            <div
              className={'TripCard-HeadBlock TripCard-PriceBlock'}>
              {`${trip?.pricePerPerson || ''} ${translate('грн')}`}
            </div>
          </>
        }

        {indicatorsNewService.shouldBadgeNewBeDisplayedOnTripCard(isDispatcher,
            tripOrder, trip, user.idPost) &&
          <div
            className={'TripCard-HeadBlock TripCard-BadgeNew'}>
            {'new'}
          </div>
        }

        {checkIsTripOrderAlreadyReservedInTrip(tripOrder, trip) &&
          <div
            className={'TripCard-HeadBlock TripCard-BadgeNew'}>
            {'reserved'}
          </div>
        }
      </div>

      <div className={'TripCard-MainSection'}>
        <div className={'TripCard-StatusBadge'}>
          <TripStatusBadge
            statusText={getStatusText(trip?.status)}
          />
        </div>

        <div className={'TripCard-Item TripCard-RouteBlock'}>
          <DisplayTripPointsData
            tripPoints={[trip.startPoint, trip.endPoint]}
          />
        </div>

        <div
          className={'TripCard-Item TripCard-CompanyNameBlock'}>
          {trip?.companyName || ''}
        </div>

        <div className={'TripCard-Block TripCard-DriversBlock'}>
          {trip?.drivers?.map((driver, dIndex) => (
            isDispatcher ?
              <React.Fragment key={dIndex}>
                <DisplayUserData
                  userId={driver.idPost}
                  isAdmin={false}
                  isFullInfo={false}
                  isDriver={true}
                  isFavouriteIconVisible={false}
                />
              </React.Fragment>
              :
              <div key={dIndex}
                className={''}
              >
                {driver?.fullName}
              </div>
          ))}
        </div>

        <div
          className={'TripCard-Block TripCard-Transport'}
        >
          <DisplayVehicleData
            vehicle={trip?.vehicle}
            isFullInfo={false}
          />
        </div>

        <div className={'TripCard-Block TripCard-TripTypeBlock'}>
          {trip?.isPassengerTrip ? translate('Пассажирская') : translate('Грузовая')}
        </div>

        <div
          className={
            'TripCard-Item FlexBlock FlexBlock_JustifyLeft TripCard-OptionsBlock'
          }
        >
          {trip?.isByFoot &&
            <FaWalking/>
          }

          {trip?.isTripWithPets &&
            <FaDog/>
          }

          {trip?.isSeparatePlaceForLuggage &&
            <FaLuggageCart/>
          }

          {trip?.isPassengerAddress &&
            <RiMapPinUserLine/>
          }

          {trip?.isForChange &&
            <RiExchangeFill/>
          }

          {trip?.isPaymentOnCard &&
            <AiFillSafetyCertificate/>
          }

          {trip?.addOptions?.length > 0 &&
            <BiAddToQueue/>
          }
        </div>
      </div>

      <div className={'TripCard-TripTypeIcon'}>
        {trip?.isPassengerTrip ? <GiBus/> : <GiTruck/>}
      </div>
    </div>
  );
  //endregion
};

export default TripCard;
