import React from 'react';
import {Route, Routes} from "react-router-dom";
import TestimonialsListForAdmin from "./TestimonialsListForAdmin";
import TestimonialDetails from "./TestimonialDetails";

const DisplayTestimonialsForAdmin = ({addClasses = ''}) => {
    //#region Render
    return (
      <div className={`DisplayTestimonialsForAdmin ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <Routes>
            <Route
              index
              element={
                <TestimonialsListForAdmin
                  addClasses={''}
                />
              }
            />

            <Route
              path={'testimonialDetails'}
              element={
                <TestimonialDetails
                  isAdmin={true}
                  addClasses={'App-Screen_Narrow'}
                />
              }
            />
          </Routes>
        </div>
    );
    //#endregion
};

export default DisplayTestimonialsForAdmin;
