import React, {useState} from 'react';
import {
    dictionaryModel,
    reservationModel,
    testimonialModel,
    tripModel,
    tripOrderModel,
    userModel
} from "../utils/models";
import {DEFAULT_LANG} from "../utils/data";

export const AppContext = React.createContext({
    user: userModel,
    userVehicles: [],
    isUserDataComplete: false,
    lang: DEFAULT_LANG,
    dictionary: dictionaryModel,
    dictionaries: [],
    defaultDictionary: dictionaryModel,
    screenTitle: '',
    currentScreen: '',
    previousScreen: '',
    vehiclesCatalog: [],
    vehiclesBodyTypes: [],
    vehiclesColors: [],
    hasTripBecomeInProgress: false,
    currentTripOrder: tripOrderModel,
    currentTrip: tripModel,
    currentReservation: reservationModel,
    editingUser: userModel,
    currentTestimonial: testimonialModel,
    languages: [],
    setUser: () => {},
    setUserVehicles: () => {},
    setIsUserDataComplete: () => {},
    setLang: () => {},
    setDictionary:  () => {},
    setDictionaries: () => {},
    setDefaultDictionary: () => {},
    setScreenTitle: () => {},
    setCurrentScreen: () => {},
    setPreviousScreen: () => {},
    setVehiclesCatalog: () => {},
    setVehiclesBodyTypes: () => {},
    setVehiclesColors: () => {},
    setHasTripBecomeInProgress: () => {},
    setCurrentTripOrder: () => {},
    setCurrentTrip: () => {},
    setCurrentReservation: () => {},
    setEditingUser: () => {},
    setCurrentTestimonial: () => {},
    setLanguages: () => {},
});

export const AppProvider = ({ children }) => {
    const [user,setUser] = useState(userModel);
    const [userVehicles, setUserVehicles] = useState([]);
    const [isUserDataComplete, setIsUserDataComplete] = useState(false);
    const [lang, setLang] = useState(DEFAULT_LANG);
    const [dictionary, setDictionary] = useState(dictionaryModel);
    const [defaultDictionary, setDefaultDictionary] = useState(dictionaryModel);
    const [dictionaries, setDictionaries] = useState([]);
    const [screenTitle, setScreenTitle] = useState('');
    const [currentScreen, setCurrentScreen] = useState('');
    const [previousScreen, setPreviousScreen] = useState('');
    const [vehiclesCatalog, setVehiclesCatalog] = useState([]);
    const [vehiclesBodyTypes, setVehiclesBodyTypes] = useState([]);
    const [vehiclesColors, setVehiclesColors] = useState([]);
    const [hasTripBecomeInProgress, setHasTripBecomeInProgress] = useState(false);
    const [currentTripOrder, setCurrentTripOrder] = useState(tripOrderModel);
    const [currentTrip, setCurrentTrip] = useState(tripModel);
    const [currentReservation, setCurrentReservation] = useState(reservationModel);
    const [editingUser, setEditingUser] = useState(userModel);
    const [currentTestimonial, setCurrentTestimonial] = useState(userModel);
    const [languages, setLanguages] = useState([{
        name: 'en',
        description: 'English',
    }, {
        name: 'ru',
        description: 'Русский',
    }, {
        name: 'uk',
        description: 'Українська',
    },]);

    const contextValue = {
        user,
        userVehicles,
        setUser,
        setUserVehicles,
        isUserDataComplete,
        setIsUserDataComplete,
        lang,
        setLang,
        dictionary,
        setDictionary,
        dictionaries,
        setDictionaries,
        defaultDictionary,
        setDefaultDictionary,
        screenTitle,
        setScreenTitle,
        currentScreen,
        setCurrentScreen,
        previousScreen,
        setPreviousScreen,
        vehiclesColors,
        setVehiclesColors,
        vehiclesCatalog,
        setVehiclesCatalog,
        vehiclesBodyTypes,
        setVehiclesBodyTypes,
        hasTripBecomeInProgress,
        setHasTripBecomeInProgress,
        currentTripOrder,
        setCurrentTripOrder,
        currentTrip,
        setCurrentTrip,
        currentReservation,
        setCurrentReservation,
        editingUser,
        setEditingUser,
        currentTestimonial,
        setCurrentTestimonial,
        languages,
        setLanguages,
    }

    return (
        <AppContext.Provider
            value={contextValue}
        >
            {children}
        </AppContext.Provider>
    )
};
