import React from 'react';
import '../styles/TestimonialCard.scss';

const TestimonialCard = ({addClasses = '', testimonial}) => {
  //#region Render
  return (
    <div className={`TestimonialCard ${addClasses}`}>
      <div
        className={'TestimonialCard-Head FlexBlock FlexBlock_JustifyLeft'}
      >
        <span className={'TestimonialCard-HeadItem'}>
          {testimonial?.author?.fullName}
        </span>

        <span
          className={'TestimonialCard-HeadItem TestimonialCard-IconContainer'}
        >
          <img src={'/img/mark-star-icon.svg'} alt={'Star icon'}/>
        </span>

        <span className={'TestimonialCard-HeadItem'}>
          {testimonial?.mark}
        </span>
      </div>

      <div className={'TestimonialCard-Text'}>
        {testimonial?.text}
      </div>
    </div>
  );
  //#endregion
};

export default TestimonialCard;
