import React, {useState, useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {
  defineListOfNewTripsForTripOrder,
  updateObservedTripsListInTripOrder
} from "../utils/helpers";
import DisplayTripCard from "./DisplayTripCard";
import {entitiesNumberInterface} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/CardsListScreen.scss';

const DisplayCreatedTrips = ({
  trips = [],
  addClasses = '',
  handleShowTripDetailsScreen = () => {},
  handleShowCreateTrip = undefined,
  isExtendedDisplaying = false,
  isReducedDisplaying = false,
  isDispatcher = false,
  isFromReservedTrips = false,
  isFromExchange = false,
  isNotepad = true,
  cardsNumber = entitiesNumberInterface.tripsCards,
  isLoading = true,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region get Screen title from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Handle add trip click
  const handleAddTripClick = () => {
    if (handleShowCreateTrip) {
      handleShowCreateTrip();
    }
  }
  //#endregion

  //#region Add trips to observed trips for trip order
  // if it is displaying suitable trips for tripOrder
  useEffect(() => {
    return () => {
      if (isDispatcher && trips.length > 0) {
        const newTripsForTripOrder = defineListOfNewTripsForTripOrder(trips,
          currentTripOrder);

        if (newTripsForTripOrder.length > 0) {
          updateObservedTripsListInTripOrder(newTripsForTripOrder,
            currentTripOrder)
            .then((updatedTripOrder) => {
              setCurrentTripOrder(updatedTripOrder);
            }).catch(() => {});
        }
      }
    }
  }, [trips]);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    if (isFromReservedTrips) {
      setScreenTitle('Забронированные поездки');
    } else if (isDispatcher) {
      setScreenTitle('Найденные поездки');
    } else {
      setScreenTitle('Мои поездки');
    }
  }, []);
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsChunk, setTripsChunk] = useState([]);

  useEffect(() => {
    setTripsChunk(trips
      .slice((currentPage - 1) * cardsNumber,
        currentPage * cardsNumber));
  }, [trips, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div
          className={trips?.length > 0 ? `CardsListScreen ${addClasses}`
            : 'CardsListScreen CardsListScreen_Empty CardsListScreen_Reduced'}>
          <div className={'PaddingBlock'}></div>

          {!isFromExchange && !isNotepad &&
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>
          }

          {trips?.length > 0 ?
            <></>
            :
            <p className={'CardsListScreen-NoEntriesText'}>
              {translate('Нет поездок')}
            </p>
          }

          <div className={'DataListWithPagination'}>
            <div
              className={isExtendedDisplaying
                ? 'ListContainer ListContainer_Extended'
                : (isReducedDisplaying
                  ? 'ListContainer ListContainer_ExtremelyReduced'
                  : 'ListContainer')}
            >
              <div className={'CardsList'}>
                {isNotepad &&
                  <button
                    className={'RegularButton CardsList-AddButton'}
                    onClick={() => handleAddTripClick()}
                  >
                    {translate('Добавить поездку')}
                  </button>
                }

                {tripsChunk.map((userTrip, index) => (
                  <React.Fragment key={userTrip.idPost}>
                    <DisplayTripCard
                      isDispatcher={isDispatcher}
                      trip={userTrip}
                      handleShowTripDetailsScreen={handleShowTripDetailsScreen}
                      tripOrder={currentTripOrder}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>

            {trips.length > cardsNumber &&
              <PaginationBlock
                portionSize={cardsNumber}
                listLength={trips.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          </div>
        </div>
      }
    </>
  );
  //#endRegion
};

export default DisplayCreatedTrips;
