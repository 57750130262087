import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  collectionsInterface,
  entitiesNumberInterface,
  tripOrderModel
} from "../utils/models";
import ReservationCard from "./ReservationCard";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import {queryArraysService} from "../utils/queryArraysService";
import {
  getCollectionWhereKeysValues
} from "../utils/firebaseConfigAndFunctions";
import {sortCardsByDateOfCreation} from "../utils/helpers";

const DisplayActiveTripsOrders = ({
  handleTripOrderCardClick = () => {},
  addClasses = '',
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region get setCurrentTripOrder context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screen title managing function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title and current trip order on component mount
  useEffect(() => {
    setScreenTitle('Активные поездки');

    setCurrentTripOrder(tripOrderModel);
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Trips orders list
  const [tripsOrdersToDisplay, setTripsOrdersToDisplay] = useState([]);
  //#endregion

  //#region Get data and set to state
  const getTripsOrdersAndSet = async () => {
    setIsLoading(true);

    try {
      const queryArray = queryArraysService.passengerActiveTrips(user.idPost);
      const orders = await getCollectionWhereKeysValues(collectionsInterface
        .tripsOrders, queryArray, queryArray.length);

      setTripsOrdersToDisplay(sortCardsByDateOfCreation(orders, false));
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTripsOrdersAndSet().then();
  }, [])
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsOrdersChunk, setTripsOrdersChunk] = useState([]);

  useEffect(() => {
    setTripsOrdersChunk(tripsOrdersToDisplay
      .slice((currentPage - 1) * entitiesNumberInterface.reservationCards,
        currentPage * entitiesNumberInterface.reservationCards));
  }, [tripsOrdersToDisplay.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <>
          <div className={'PaddingBlock_Half'}></div>
          <div
            className={tripsOrdersToDisplay.length > 0
              ? `CardsListScreen ${addClasses}`
              : `CardsListScreen CardsListScreen_Empty  ${addClasses}`}
          >
            {tripsOrdersToDisplay.length > 0 ?
              <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
                {translate(screenTitle)}
              </h2>
              :
              <p className={'CardsListScreen-NoEntriesText'}>
                {translate('Нет пассажиров')}
              </p>
            }

            <div className={'DataListWithPagination'}>
              <div className={'ListContainer ListContainer_Extended'}>
                <div className='CardsList'>
                  {tripsOrdersChunk?.map((tripOrder, tIndex) => (
                    <React.Fragment key={tripOrder.idPost}>
                      <ReservationCard
                        reservation={tripOrder.acceptedDeal}
                        tripOrder={tripOrder}
                        trip={tripOrder.acceptedDeal?.originalTrip}
                        isDispatcher={true}
                        isActive={true}
                        handleReservationCardClick={handleTripOrderCardClick}
                        isActiveTrip={true}
                      />
                    </React.Fragment>))
                  }
                </div>
              </div>

              {tripsOrdersToDisplay.length > entitiesNumberInterface
                  .reservationCards &&
                <PaginationBlock
                  portionSize={entitiesNumberInterface.reservationCards}
                  listLength={tripsOrdersToDisplay.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  startPage={startPage}
                  setStartPage={setStartPage}
                />
              }
            </div>
          </div>
        </>
      }
    </>
  );
  //#endregion
};

export default DisplayActiveTripsOrders;
