import React from 'react';
import {Modal} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";

const ModalAddOptionsDifferenceConfirmation = ({
  showModal,
  title,
  message,
  setShowModal = () => {},
  goToReservationConfirmation = () => {},
  rejectReservation = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {translate(title)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {translate(message)}
        </div>
        <div className={'FlexBlock'}>
          <button
            type={'button'}
            className={'RegularButton FlexBlock-HorizontalItem' +
              ' FlexBlock-HorizontalItem_W40'}
            onClick={() => goToReservationConfirmation()}
          >
            {translate('Забронировать')}
          </button>

          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast' +
              ' FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W25'}
            onClick={() => rejectReservation()}
          >
            {translate('Отменить')}
          </button>

          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast' +
              ' FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W25'}
            onClick={() => {
              setShowModal(false)
            }}
          >
            {translate('Вернуться')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
  //#endregion
};

export default ModalAddOptionsDifferenceConfirmation;
