import React from "react";
import {Modal} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";

const ModalDeleteIndicationTrip = ({
  tripId,
  deleteTrip,
  showModal = false,
  setShowModal = () => {},
  addClasses = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <>
      <Modal
        show={showModal}
        fullscreen='true'
        onHide={() => {
          setShowModal(false);
        }}
        className={`ModalAddDriverAddress ${addClasses}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className={'RegularTitle RegularTitle_Small'}>
              {translate('Вы уверены, что хотите удалить поездку?')}
            </h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <div className={'FlexBlock ModalAddDriverAddress-Item'}>
            <button
              type={'button'}
              className={'RegularButton FlexBlock-HorizontalItem ' +
                'FlexBlock-HorizontalItem_W40'}
              onClick={() => deleteTrip(tripId)}
            >
              {translate('Да')}
            </button>

            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast ' +
                'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W40'}
              onClick={() => {
                setShowModal(false)
              }}
            >
              {translate('Нет')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
  //#endregion
}

export default ModalDeleteIndicationTrip;
