import React from 'react';
import {Modal} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";

const ModalConfirmAction = ({
  title = '',
  message = '',
  isVisible,
  hideModal = () => {},
  confirmAction = () => {},
  cancelAction = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <Modal
      show={isVisible}
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {translate(title)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {message}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className={'FlexBlock w-100'}>
            <button
              type={'button'}
              className={'RegularButton FlexBlock-HorizontalItem_W48'}
              onClick={() => {
                confirmAction();
              }}
            >
              {translate('Да')}
            </button>

            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast FlexBlock-HorizontalItem_W48'}
              onClick={() => {
                cancelAction();
              }}
            >
              {translate('Нет')}
            </button>
          </div>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalConfirmAction;
