import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  deleteTripOrderHandler,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import {
  pagesInterface,
  tripOrderModel
} from "../utils/models";
import {FaTrash} from "react-icons/fa";
import ReactTable from "react-table-v6";
import {MdOutlineSlideshow} from "react-icons/md";
import Loader from "./Loader";
import FiltersControls from "./FiltersControls";
import '../styles/TripsOrdersListForAdmin.scss';
import {getTranslatedLocalityName} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";

const TripsOrdersListForAdmin = ({
  addClasses = '',
  tripsOrders = [],
  setTripsOrders = () => {},
  isLoading = false,
  setIsLoading = () => {},
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder data from context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Set screen title and clear currentTripOrder
  useEffect(() => {
    setScreenTitle('Заявки на поездки');

    setCurrentTripOrder(tripOrderModel);
  }, []);
  //#endregion

  //#region Data for React table

  //#region Search and filters
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');
  const statusesList = [{
    name: 'Активная',
    value: 'active',
  }, {
    name: 'Неактивная',
    value: 'notActive',
  }];


  const getStatusName = (status) => {
    if (status === 'active') {
      return translate('Активная');
    }

    return translate('Неактивная');
  }
  //#endregion

  const getTripsOrdersToDisplay = (searchText, filtersData, orders) => {
    let searchResult = [];

    if (searchText) {
      searchResult = orders.filter(order => {
        let res = false;

        if (order.hasOwnProperty('dispatcherFullName')) {
          if (order.dispatcherFullName.toUpperCase().includes(searchText
            .toUpperCase())) {
            res = true;
          }
        }

        if (order.startPoint?.hasOwnProperty('city')) {
          if (Object.values(order.startPoint.city).some(city => city
            .toUpperCase().includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        if (order.endPoint?.hasOwnProperty('city')) {
          if (Object.values(order.endPoint.city).some(city => city.toUpperCase()
            .includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        return res;
      });
    } else {
      searchResult = orders;
    }

    let result = [];

    if (filtersData) {
      if (Object.values(filtersData).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(order => {
          let res = true;

          if (filtersData.status === 'active' && !order.isActive) {
            return false;
          }

          if ((filtersData.status === 'notActive' && order.isActive)) {
            return false;
          }

          if (filtersData.date) {
            const dateAsTimestamp = new Date(filtersData.date).getTime();

            if (dateAsTimestamp !== order.departureDateWithoutTime) {
              return false;
            }
          }

          if (filtersData.amount) {
            const pricePerPerson = order?.pricePerPerson || 0;

            if (pricePerPerson < (Number(filtersData?.amount.from || 0))
              || pricePerPerson > Number(filtersData.amount.to || 0)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  }

  const handleTripOrderClick = (order) => {
    setCurrentTripOrder(order);

    navigate(`/${pagesInterface.passengersAdmin}/editTripOrder`)
  }

  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e =>
        <div>
          {e.idPost === '' ?
            translate('Не указан')
            :
            e.idPost
          }
        </div>
    },
    {
      id: 'departureTime',
      width: 150,
      Header: translate('Время выезда'),
      accessor: e =>
        <div>
          {!e.departureTime ?
            translate('Не указано')
            :
            showFormattedToLanguageDate(lang, e.departureTime, true)
          }
        </div>
    },
    {
      id: 'dispatcherName',
      minWidth: 100,
      Header: translate('Диспетчер'),
      accessor: e =>
        <div>
          {!e.dispatcherFullName ?
            translate('Не указано')
            :
            e.dispatcherFullName
          }
        </div>
    },
    {
      id: 'from',
      Header: translate('Откуда'),
      accessor: e =>
        <div>
          {!e.startPoint.city ?
            translate('Не указано')
            :
            getTranslatedLocalityName(e.startPoint.city, lang)
          }
        </div>
    },
    {
      id: 'to',
      Header: translate('Куда'),
      accessor: e =>
        <div>
          {!e.endPoint.city ?
            translate('Не указано')
            :
            getTranslatedLocalityName(e.endPoint.city, lang)
          }
        </div>
    },
    {
      id: 'price',
      Header: translate('Цена'),
      accessor: e =>
        <div>
          {e?.pricePerPerson || 0}
        </div>
    },
    {
      id: 'status',
      Header: translate('Статус'),
      accessor: e =>
        <div>
          {e.isActive ? translate('Активная')
            : translate('Неактивная')
          }
        </div>
    },
    {
      id: 'editAndDelete',
      Header: '',
      width: 80,
      accessor: e =>
        <div>
          <div
            className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}
          >
            <button
              type={'button'}
              className={'ShowButton'}
              onClick={() => handleTripOrderClick(e)}
            >
              <MdOutlineSlideshow/>
            </button>

            <button
              type={'button'}
              className={'DelButton'}
              onClick={() => {
                setIsLoading(true);

                deleteTripOrderHandler(e, setTripsOrders)
                  .finally(() => setIsLoading(false));
              }}
            >
              <FaTrash/>
            </button>
          </div>
        </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`TripsOrdersListForAdmin ${addClasses}`}>
          <div className={'PaddingBlock PaddingBlock_Half'}/>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <FiltersControls
            searchText={searchText}
            setSearchText={setSearchText}
            setAppliedFilters={setAppliedFilters}
            statusesList={statusesList}
            getStatusName={getStatusName}
            priceFilterName={'Цена'}
          />

          <ReactTable
            data={getTripsOrdersToDisplay(searchText, appliedFilters,
              tripsOrders)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}

          />

          <div className={'PaddingBlock'}></div>
        </div>
      }
    </>
  );
  //#endregion
};

export default TripsOrdersListForAdmin;
