import React from 'react';
import DisplayVehicleData from "./DisplayVehicleData";
import Loader from "./Loader";
import {entitiesNumberInterface} from "../utils/models";
import ShowDriverOrVehicleTrip from "./ShowDriverOrVehicleTrip";
import useTranslation from "../hooks/useTranslation";

const DisplayVehicleTrips = ({
  addClasses = '',
  vehicle,
  trips = [],
  cardsNumber= entitiesNumberInterface.vehicleTrips,
  isReducedDisplaying = false,
  isLoading = true,
  deleteTrip = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={`DisplayVehicleTrips ${addClasses}`}>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <DisplayVehicleData
        vehicle={vehicle}
        isFullInfo={false}
        addClasses={'RegularTitle RegularTitle_Tiny'}
      />

      <h2 className={'DataLabel DataLabel_Centered'}>
        {translate('Тут отображаются промежутки дат, в которых занят' +
          ' данный автомобиль')}
      </h2>

      {isLoading ?
          <Loader/>
          :
          <ShowDriverOrVehicleTrip
            addClasses={addClasses}
            trips={trips}
            isReducedDisplaying={isReducedDisplaying}
            cardsNumber={cardsNumber}
            deleteTrip={deleteTrip}
          />
        }
    </div>
  );
  //#endregion
};

export default DisplayVehicleTrips;
