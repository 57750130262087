import React, {useState} from 'react';
import DisplayCreatedTripsOrders from "./DisplayCreatedTripsOrders";
import useTranslation from "../hooks/useTranslation";
import {Nav, Tab} from "react-bootstrap";
import DisplayCreatedTrips from "./DisplayCreatedTrips";
import {entitiesNumberInterface} from "../utils/models";

const SuitableTripsOrdersScreen = ({
  addClasses = '',
  tripsOrders = [],
  addTripsOrders = [],
  isLoading = false,
  handleTripOrderCardClick = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Toggle trips orders
  const [activeTabKey, setActiveTabKey] = useState('otherPassengers');
  //#endregion

  //#region Render
  return (
    <div className={`SuitableTripsScreen ${addClasses}`}>
      <div className={'PaddingBlock'} ></div>

      <Tab.Container
        activeKey={activeTabKey}
      >
        <Nav variant="pills" className={'flex-row TabControls'}>
          <Nav.Item>
            <Nav.Link
              eventKey='otherPassengers'
              onClick={() => setActiveTabKey('otherPassengers')}
            >
              {translate('Пассажиры диспетчеров')}
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey='myPassengers'
              onClick={() => setActiveTabKey('myPassengers')}
            >
              {translate('Мои пассажиры')}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey='otherPassengers'>
            <DisplayCreatedTripsOrders
              isReadOnly={true}
              isNotepad={false}
              tripsOrdersToDisplay={tripsOrders}
              handleTripOrderCardClick={handleTripOrderCardClick}
              isInPassengersSearchList={true}
              addClasses={'App-Screen'}
              isLoading={isLoading}
            />
          </Tab.Pane>

          <Tab.Pane eventKey='myPassengers'>
            <DisplayCreatedTripsOrders
              isReadOnly={true}
              isNotepad={false}
              tripsOrdersToDisplay={addTripsOrders}
              handleTripOrderCardClick={handleTripOrderCardClick}
              isInPassengersSearchList={true}
              addClasses={'App-Screen'}
              isLoading={isLoading}
              cardsNumber={entitiesNumberInterface.tripsOrdersCardsMiddle}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
  //#endregion
};

export default SuitableTripsOrdersScreen;
