import React from 'react';
import LoaderSmall from "./LoaderSmall";
import useTranslation from "../hooks/useTranslation";
import '../styles/InputWithLoader.scss';

const InputWithLoader = ({
  addClassesForInput = '',
  placeholder = '',
  value = '',
  handleChange = () => {},
  isLoader = true,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={'InputWithLoader'}>
      <input
        type={'text'}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        className={`FormControl ${addClassesForInput}`}
        placeholder={translate(placeholder)}
      />

      {isLoader &&
        <div className={'InputWithLoader_Loader'}>
          <LoaderSmall />
        </div>
      }
    </div>
  );
  //#endregion
};

export default InputWithLoader;
