import React, {useState, useEffect, useContext} from 'react';
import {
  FaDog,
  FaLuggageCart,
  FaWalking,
} from "react-icons/fa";
import {BiAddToQueue} from 'react-icons/bi';
import {GiBus, GiTruck} from 'react-icons/gi';
import {RiExchangeFill, RiMapPinUserLine} from 'react-icons/ri';
import {AppContext} from "../context/appContext";
import {
  findIfTripOrderHasNewReservationsFromDrivers,
  findNewTripsForTripOrder,
  isTripOrderHasReservationInUsersTrip,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import DisplayTripPointsData from "./DisplayTripPointsData";
import {AiFillSafetyCertificate} from "react-icons/ai";
import {MdOutlineTransferWithinAStation} from "react-icons/md";
import useTranslation from "../hooks/useTranslation";
import {indicatorsNewService} from "../utils/indicatorsNewService";

const DisplayTripOrderCard = ({
  tripOrder,
  isDispatcher = false,
  handleTripOrderCardClick = undefined,
  handleShowCreateTrip = () => {},
  isNotepad,
  isReadOnly = false,
  isInMyPassengersList = false,
  isInReservedList = false,
  isInPassengersSearchList = false,
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Managing displaying info
  const handleCardClick = (tripOrder) => {
    if (handleTripOrderCardClick) {
      handleTripOrderCardClick(tripOrder);
    }
  };

  const handleEditTripOrderClick = (event) => {
    event.stopPropagation();

    setCurrentTripOrder(tripOrder);

    handleShowCreateTrip();
  }
  //#endregion

  //#region Check is trip order should have badge new
  const [isBadgeNew, setIsBadgeNew] = useState(false);

  useEffect(() => {
    indicatorsNewService.shouldBadgeNewBeOnTripOrderCard(isNotepad,
      isInMyPassengersList, user.idPost, tripOrder)
      .then(isNew => setIsBadgeNew(isNew));
  }, []);
  //#endregion

  //#region Render
  return (
    <div
      className='TripCard'
      onClick={() => handleCardClick(tripOrder)}
    >
      <div className='FlexBlock FlexBlock_JustifyLeft'>
        {tripOrder.isPassengerTrip ?
          <>
            <div
              className={'TripCard-HeadBlock TripCard-DateBlock'}>
              {tripOrder?.departureTime ? showFormattedToLanguageDate(lang,
                tripOrder.departureTime) : 'Invalid time'}
            </div>
            <div
              className={'TripCard-HeadBlock TripCard-PriceBlock'}>
              {`${tripOrder?.pricePerPerson} ${translate('грн')}`}
            </div>
            <div
              className={'TripCard-HeadBlock TripCard-PlacesAmountBlock'}
            >
              {`${translate('Мест')}: ${tripOrder?.requiredNumberOfPlaces}`}
            </div>
          </> :
          <>
            <div
              className={'TripCard-HeadBlock TripCard-DateBlock'}>
              {tripOrder?.departureTime ? showFormattedToLanguageDate(lang,
                tripOrder.departureTime) : 'Invalid time'}
            </div>
            <div
              className={'TripCard-HeadBlock TripCard-PriceBlock'}>
              {`${tripOrder?.pricePerPerson} ${translate('грн')}`}
            </div>
          </>
        }
      </div>

      {isBadgeNew &&
        <div
          className={'TripCard-HeadBlock TripCard-BadgeNew'}>
          {'new'}
        </div>
      }

      {((isDispatcher && (!isNotepad && isInReservedList))
          && findIfTripOrderHasNewReservationsFromDrivers(tripOrder)) &&
        <div
          className={'TripCard-HeadBlock TripCard-BadgeNew'}>
          {'new res'}
        </div>
      }

      {((!isDispatcher && isInPassengersSearchList)
          && isTripOrderHasReservationInUsersTrip(user, tripOrder)) &&
        <div
          className={'TripCard-HeadBlock TripCard-BadgeNew'}
        >
          {'reserved'}
        </div>
      }

      <div className={'TripCard-MainSection'}>
        {!isReadOnly && !tripOrder.isTransferred &&
          <div
            className={'TripCard-EditButton'}
            onClick={
              (event) => handleEditTripOrderClick(event)
            }
          >
            <img
              src={'/img/pencil-icon.svg'}
              alt={'Pencil'}
            />
          </div>
        }

        <div
          className={'TripCard-Block' +
          ' TripCard-RouteBlock'}
        >
          <DisplayTripPointsData
            tripPoints={[tripOrder?.startPoint, tripOrder?.endPoint]}
          />
        </div>

        <div
          className={'TripCard-Block TripCard-PassengerNameBlock'}
        >
          {`${tripOrder?.passengerName}`}
        </div>

        {user?.idPost !== tripOrder.creatorIdPost &&
          <div
            className={'TripCard-Block TripCard-DispatcherNameBlock'}
          >
            {`${translate('Имя диспетчера')}: ${tripOrder?.dispatcherFullName}`}
          </div>
        }

        <div
          className={'TripCard-Block TripCard-TripTypeBlock'}
        >
          {tripOrder?.isPassengerTrip ? translate('Пассажирская')
            : translate('Грузовая')}
        </div>

        <div
          className={'FlexBlock FlexBlock_JustifyLeft TripCard-Block ' +
            'TripCard-OptionsBlock'}
        >
          {tripOrder?.isByFoot &&
            <FaWalking/>
          }

          {tripOrder?.isTripWithPets &&
            <FaDog/>
          }

          {tripOrder?.isSeparatePlaceForLuggage &&
            <FaLuggageCart/>
          }

          {tripOrder?.isPassengerAddress &&
            <RiMapPinUserLine/>
          }

          {tripOrder?.isForChange &&
            <RiExchangeFill/>
          }

          {tripOrder?.isPaymentOnCard &&
            <AiFillSafetyCertificate/>
          }

          {tripOrder?.isTransferred &&
            <MdOutlineTransferWithinAStation/>
          }

          {tripOrder?.addOptions?.length > 0 &&
            <BiAddToQueue/>
          }
        </div>
      </div>

      <div className={'TripCard-TripTypeIcon'}>
        {tripOrder?.isPassengerTrip ? <GiBus/> : <GiTruck/>}
      </div>
    </div>
  );
  //#endregion
};

export default DisplayTripOrderCard;
