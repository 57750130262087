import React, {useState} from 'react';
import {MdOutlineSlideshow} from "react-icons/md";
import {FaTrash} from "react-icons/fa";
import {Dropdown} from "react-bootstrap";
import ModalNotification from "../modals/ModalNotification";
import useTranslation from "../hooks/useTranslation";
import '../styles/FiltersControls.scss';

const FiltersControls = ({
  searchText = '',
  setSearchText = () => {},
  setAppliedFilters = () => {},
  getStatusName = () => {},
  statusesList = [],
  priceFilterName = 'Цена',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Search and filters
  const [filtersData, setFiltersData] = useState('');

  const searchUpdate = (text) => {
    setSearchText(text);
  }

  const filterUpdate = (fieldName, value) => {
    let updatedValue = value;

    if (fieldName === 'amount') {
      if (value?.to && value.to.toString()[0] === '-') {
        updatedValue = {
          ...value,
          to: Number(value?.to?.toString().slice(1)),
        }
      }

      if (value?.from && value.from.toString()[0] === '-') {
        updatedValue = {
          ...value,
          from: Number(value?.from?.toString().slice(1)),
        }
      }
    }

    setFiltersData(data => ({
      ...data,
      [fieldName]: updatedValue,
    }));
  };

  const applyFilter = (fieldName, value) => {
    if (fieldName === 'price') {
      if (Number(value.priceFrom) > (Number(value?.priceTo || 0))) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Ошибка в фильтре: верхняя граница не может быть меньше нижней');

        return;
      }
    }

    setAppliedFilters(data => ({
      ...data,
      [fieldName]: value,
    }));
  };

  const clearFilter = (fieldName) => {
    setAppliedFilters(data => ({
      ...data,
      [fieldName]: '',
    }));
    setFiltersData(data => ({
      ...data,
      [fieldName]: '',
    }));
  };
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={'FiltersControls'}>
        <div className={'FlexBlock FlexBlock_Columns mb-2'}>
          <input
            value={searchText}
            type="text"
            placeholder={translate('Поиск')}
            onChange={(e) => searchUpdate(e.target.value)}
            className={'FormControl FiltersControls-SearchBlock'}
          />

          <div className={'FiltersControls-DateFilter'}>
            <div className={'FlexBlock FlexBlock_AlignCenter'}>
              <label>
                {translate('Дата')}
              </label>

              <input
                type={'date'}
                className={'FormControl LeftMarginTiny'}
                value={filtersData?.date || ''}
                onChange={(event) => filterUpdate('date',
                  event.target.value)}
              />

              <div
                className={
                  'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'
                }
              >
                <button
                  type={'button'}
                  className={'ShowButton LeftMarginTiny'}
                  onClick={() => applyFilter('date', filtersData.date)}
                >
                  <MdOutlineSlideshow/>
                </button>

                <button
                  type={'button'}
                  className={'DelButton LeftMarginTiny'}
                  onClick={() => clearFilter('date')}
                >
                  <FaTrash/>
                </button>
              </div>
            </div>
          </div>

          <div className={'FiltersControls-PriceFilter'}>
            <div className={'FlexBlock FlexBlock_AlignCenter'}>
              <label>
                {translate(priceFilterName)}
              </label>

              <input
                type={'number'}
                value={filtersData?.amount?.from || ''}
                placeholder={'От'}
                className={'FormControl LeftPaddingTiny LeftMarginTiny'}
                onChange={(event) => filterUpdate('amount', {
                  ...filtersData.amount,
                  from: event.target.value,
                })}
              />

              <input
                type={'number'}
                className={'FormControl LeftPaddingTiny LeftMarginTiny'}
                value={filtersData?.amount?.to || ''}
                placeholder={'До'}
                onChange={(event) => filterUpdate('amount', {
                  ...filtersData.amount,
                  to: event.target.value,
                })}
              />

              <div
                className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}>
                <button
                  type={'button'}
                  className={'ShowButton LeftMarginTiny'}
                  onClick={() => {
                    applyFilter('amount', filtersData.amount);
                  }}
                >
                  <MdOutlineSlideshow/>
                </button>

                <button
                  type={'button'}
                  className={'DelButton LeftMarginTiny'}
                  onClick={() => {
                    clearFilter('amount');
                  }}
                >
                  <FaTrash/>
                </button>
              </div>
            </div>
          </div>

          <Dropdown className={'FiltersControls-StatusFilter'}>
            <Dropdown.Toggle
              className={'FormControl DropdownControl'}
              id="dropdown-basic"
            >
              {filtersData?.status ? getStatusName(filtersData.status)
                : translate('Статус')}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {statusesList.map((status, index) =>
                <React.Fragment key={index}>
                  <Dropdown.Item
                    onClick={() => {
                      filterUpdate('status', status.value);
                      applyFilter('status', status.value);
                    }}
                  >
                    <span>
                      {translate(status.name)}
                    </span>
                  </Dropdown.Item>
                </React.Fragment>
                )
              }

              <Dropdown.Item
                onClick={() => {
                  clearFilter('status', '');
                }}
              >
                <span>
                  {translate('Сбросить')}
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default FiltersControls;
