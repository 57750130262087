import React, {useState} from 'react';
import {Route, Routes} from "react-router-dom";
import TransactionsListForAdmin from "./TransactionsListForAdmin";
import TransactionDetails from "./TransactionDetails";
import {transactionModel} from "../utils/models";
import WithdrawalsListForAdmin from "./WithdrawalsListForAdmin";

const TransactionsHistoryForAdmin = ({addClasses = ''}) => {
  //#region Current transaction
  const [currentTransaction, setCurrentTransaction] = useState(transactionModel);
  //#endregion

  //#region Render
  return (
    <div className={`TransactionsHistoryForAdmin ${addClasses}`}>
      <Routes>
        <Route
          index
          element={
            <TransactionsListForAdmin
                setCurrentTransaction={setCurrentTransaction}
            />
          }
        />

        <Route
          path={'withdrawals/*'}
          element={
            <WithdrawalsListForAdmin />
          }
        />

        <Route
          path={'transactionDetails/*'}
          element={
            <TransactionDetails
                transaction={currentTransaction}
                isAdmin={true}
                addClasses={'App-Screen_Narrow'}
            />
          }
        />
      </Routes>
    </div>
  );
  //#endregion
};

export default TransactionsHistoryForAdmin;
