import React from 'react';
import useTranslation from "../hooks/useTranslation";
import '../styles/TripStatusBadge.scss';

const TripStatusBadge = ({addClasses='', statusText='status'}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={`TripStatusBadge ${addClasses}`}>
      <span>
        {translate(statusText)}
      </span>
    </div>
  );
  //#endregion
};

export default TripStatusBadge;
