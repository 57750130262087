import React, {useContext, useEffect, useState} from 'react';
import {getVehicleData} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import LoaderSmall from "./LoaderSmall";
import "../styles/DisplayVehicleData.scss"
import useTranslation from "../hooks/useTranslation";

const DisplayVehicleData = ({
  addClasses = '',
  isFullInfo = false,
  vehicle,
}) => {
  //#region Get language from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Get brand name, model name
  const [names, setNames] = useState({});

  useEffect(() => {
    getVehicleData(lang, vehicle?.brand, vehicle?.model, setNames,
      setIsLoading, isFullInfo ? vehicle?.color : undefined,
      isFullInfo ? vehicle?.bodyType : undefined)
      .catch(() => {});
  }, [lang, vehicle]);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
        <div
          className={`DisplayVehicleData ${addClasses} ${!isFullInfo 
          ? 'DisplayVehicleData_Short' : ''}`}
        >
          {isLoading ? <LoaderSmall />
            :
            <>
              {isFullInfo &&
                <div>
                  <span className={'BlockLabel'}>
                    {`${translate('Тип кузова')}: `}
                  </span>

                  <span>
                    {names?.bodyTypeName}
                  </span>
                </div>
              }

                <div>
                  {isFullInfo &&
                    <span className={'BlockLabel'}>
                      {`${translate('Авто')}: `}
                    </span>
                  }

                  <span>
                    {`${names?.brandName} ${names?.modelName} ${vehicle?.licensePlate}`}
                  </span>
                </div>

              {isFullInfo &&
                <div>
                  <span className={'BlockLabel'}>
                    {`${translate('Цвет авто')}: `}
                  </span>

                  <span>
                    {names?.colorName}
                  </span>
                </div>
              }
            </>
            }
        </div>
    </React.Fragment>
  );
  //#endregion
};

export default DisplayVehicleData;
