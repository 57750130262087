import React, {useContext, useEffect, useState} from 'react';
import {deleteAdminHandler} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import {MdOutlineSlideshow} from "react-icons/md";
import {FaTrash} from "react-icons/fa";
import ReactTable from "react-table-v6";
import ModalNotification from "../modals/ModalNotification";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";

const AdminsList = ({
  addClasses = '',
  admins = [],
  toggleList = () => {},
  handleAddAdminClick = () => {},
  setInitialAdmins = () => {},
  isExternalLoading = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Администраторы');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Delete admin
  const deleteAdmin = async (user, setInitialAdmins) => {
    setIsLoading(true);

    try {
      const result = await deleteAdminHandler(user, setInitialAdmins);

      if (!result) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Не удалось выполнить действие');

        return false;
      }

      return true;
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Не удалось выполнить действие');

      return error;
    } finally {
      setIsLoading(false)
    }
  }
  //#endregion

  //#region Data for React table
  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'name',
      minWidth: 150,
      Header: translate('ФИО'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.fullName === '' ?
          translate('Не указаны')
          :
          e.fullName
        }
      </div>
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: e => <div className='UsersTable-Cell'>{e.email === '' ?
        translate('Не указан')
        :
        e.email
      }
      </div>
    },
    {
      id: 'delete',
      Header: '',
      width: 80,
      accessor: e => <div className='UsersTable-Cell'>{
        <div className={'FlexBlock FlexBlock_SpaceAround'}>
          <button
            type={'button'}
            className={'ShowButton'}
            onClick={() => handleAddAdminClick(e)}
          >
            <MdOutlineSlideshow/>
          </button>

          <button
            type={'button'}
            className={'DelButton'}
            onClick={() => deleteAdmin(e, setInitialAdmins)}
          >
            <FaTrash/>
          </button>
        </div>}
      </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <>
      {isExternalLoading ? <Loader/>
        :
        <div className={`AdminsList ${addClasses}`}>
          <div className={'PaddingBlock'}></div>
          {isLoading ?
            <Loader/>
            :
            <>
              <div className={`mb-2`}>
                <div className={'TitleWithButton mb-2'}>
                  <div
                    className={'RegularTitle RegularTitle_Small DesktopTitle'}>
                    {translate(screenTitle)}
                  </div>
                  <div
                    className={'ToggleButton'}
                    onClick={() => toggleList(true)}
                  >
                    {translate('К пользователям')}
                  </div>
                </div>
              </div>

              <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => handleAddAdminClick()}
              >
                {translate('Добавить')}
              </button>

              <div className={'PaddingBlock'}></div>

              <ReactTable
                data={admins}
                columns={columns}
                defaultPageSize={10}
                showPaginationTop={true}
                showPaginationBottom={false}
              />
            </>
          }

          {isNotification &&
            <ModalNotification
              showModal={isNotification}
              setShowModal={setIsNotification}
              title={notificationTitle}
              message={notificationMessage}
            />
          }
        </div>
      }
    </>
  );
  //#endregion
};

export default AdminsList;
