import React, {useContext, useEffect} from 'react';
import {
  deleteUserHandler,
  handleUserClick
} from "../utils/helpers";
import {Dropdown} from "react-bootstrap";
import {rolesInterface} from "../utils/models";
import ReactTable from "react-table-v6";
import UserPhonesInfo from "./UserPhonesInfo";
import {MdOutlineSlideshow} from "react-icons/md";
import {FaTrash} from "react-icons/fa";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/UsersList.scss';

const UsersList = ({
  addClasses = '',
  usersToDisplay = [],
  setInitialUsers = () => {},
  searchUpdate = () => {},
  filterUpdate = () => {},
  searchText = '',
  filterData = {},
  toggleList = () => {},
  isLoading = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Пользователи');
  }, []);
  //#endregion

  //#region Data for React table
  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
        onClick={() => handleUserClick(e.idPost, setEditingUser, navigate)}
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'name',
      width: 150,
      Header: translate('ФИО'),
      accessor: e => <div
        className='UsersTable-Cell UserLink'
        onClick={() => handleUserClick(e.idPost, setEditingUser, navigate)}
      >
        {e.fullName === '' ?
          translate('Не указаны')
          :
          e.fullName
        }
      </div>
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: e => <div className='UsersTable-Cell'>{e.email === '' ?
        translate('Не указан')
        :
        e.email
      }
      </div>
    },
    {
      id: 'mainPhone',
      Header: translate('Телефон'),
      accessor: e => <div className='UsersTable-Cell'>{
        (e.phoneNumbers.length > 0 && e.phoneNumbers[0].phoneNumber) ?
          e.phoneNumbers[0].phoneNumber
          :
          translate('Не указан')
      }</div>
    },
    {
      id: 'role',
      Header: translate('Роль'),
      accessor: e => <div className='UsersTable-Cell'>{
        (e.role === rolesInterface.admin ? translate('Админ')
          :
          translate('Юзер'))
      }</div>
    },
    {
      id: 'country',
      Header: translate('Страна'),
      accessor: e => <div className='UsersTable-Cell'>{
        e.country || translate('Не указана')
      }</div>
    },
    {
      id: 'city',
      Header: translate('Город'),
      accessor: e => <div className='UsersTable-Cell'>{
        e.city || translate('Не указан')
      }
      </div>
    },
    {
      id: 'mark',
      Header: translate('Оценка'),
      accessor: e => <div className='UsersTable-Cell'>{
        e.averageMark || 0
      }
      </div>
    },
    {
      id: 'delete',
      Header: '',
      accessor: e => <div className='UsersTable-Cell'>{
        <div className={'FlexBlock FlexBlock_SpaceAround'}>
          <button
            type={'button'}
            className={'ShowButton'}
            onClick={() => handleUserClick(e.idPost, setEditingUser, navigate)}
          >
            <MdOutlineSlideshow/>
          </button>

          <button
            type={'button'}
            className={'DelButton'}
            onClick={() => deleteUserHandler(e.idPost, setInitialUsers)}
          >
            <FaTrash/>
          </button>
        </div>}
      </div>
    },
  ];
  //#endregion

  const marksRange = [1, 2, 3, 4, 5];

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`UsersList ${addClasses} `}>
          <div className={'PaddingBlock'}></div>

          <div className={`mb-2`}>
            <div className={'TitleWithButton mb-2'}>
              <div className={'RegularTitle RegularTitle_Small DesktopTitle'}>
                {translate(screenTitle)}
              </div>
              <div
                className={'ToggleButton'}
                onClick={() => toggleList(false)}
              >
                {translate('К админам')}
              </div>
            </div>

            <div className={'FlexBlock FlexBlock_Columns mb-2'}>
              <div className={'UsersList-SearchBlock'}>
                <input
                  value={searchText}
                  type="text"
                  placeholder={translate('Поиск')}
                  onChange={(e) => searchUpdate(e.target.value)}
                  className={'FormControl'}
                />
              </div>

              <div className={'UsersList-FiltersBlock FlexBlock'}>
                <Dropdown className={'FlexBlock-HorizontalItem_W48'}>
                  <Dropdown.Toggle
                    className={'FormControl DropdownControl'}
                    id="dropdown-basic"
                  >
                    {filterData?.role ? (filterData.role === rolesInterface.admin ?
                        translate('Админ')
                        : translate('Юзер'))
                      : translate('Роль')
                    }
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => filterUpdate('role', rolesInterface.user)}
                    >
                  <span>
                    {translate('Юзер')}
                  </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => filterUpdate('role', rolesInterface.admin)}
                    >
                  <span>
                    {translate('Админ')}
                  </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => filterUpdate('role', 'remove')}
                    >
                  <span>
                    {translate('Сбросить')}
                  </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className={'FlexBlock-HorizontalItem_W48'}>
                  <Dropdown.Toggle
                    className={'FormControl DropdownControl'}
                    id="dropdown-basic"
                  >
                    {filterData?.averageMark || translate('Оценка')}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {marksRange.map(mark =>
                      <React.Fragment key={mark}>
                        <Dropdown.Item
                          onClick={() => filterUpdate('averageMark', mark)}
                        >
                      <span>
                        {mark}
                      </span>
                        </Dropdown.Item>
                      </React.Fragment>
                    )}

                    <Dropdown.Item
                      onClick={() => filterUpdate('averageMark', 'remove')}
                    >
                  <span>
                    {translate('Сбросить')}
                  </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <ReactTable
              data={usersToDisplay}
              columns={columns}
              defaultPageSize={10}
              pageSize={10}
              showPageSizeOptions={false}
              showPaginationTop={true}
              showPaginationBottom={false}
              sortable={true}
              SubComponent={(row) => (
                <UserPhonesInfo
                  userPhones={row.original.phoneNumbers}
                  addClasses={'SmallFont'}
                />)}
              pageText={translate('Страница')}
              ofText={translate('из')}
              previousText={translate('Предыдущая')}
              nextText={translate('Следующая')}
            />
          </div>

        </div>
      }
    </>
  );
  //#endregion
};

export default UsersList;
