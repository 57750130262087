import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {getDriverCompany} from "../utils/helpers";
import FavouriteDriverCard from "./FavouriteDriverCard";
import {
  getCollectionWhereKeysValues,
  getDocInCollection,
  updateFieldInDocumentInCollection,
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  entitiesNumberInterface,
  tripStatusesInterface
} from "../utils/models";
import DisplayDriverTrips from "./DisplayDriverTrips";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/FavouriteDriversList.scss';

const FavouriteDriversList = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get drivers from DB
  const [driversList, setDriversList] = useState([]);

  useEffect(() => {
    const resultsArray = [];
    const driversArray = [];

    if (user.favouriteDrivers.length === 0) {
      setIsLoading(false);
    }

    user.favouriteDrivers.forEach((driver, index, array) => {
      const curResult = getDocInCollection(collectionsInterface.users,
        driver.idPost).then(res => {
          driversArray.push(res);
        }).catch(() => {});

      resultsArray.push(curResult);

      if (index === array.length - 1) {
        Promise.all(resultsArray).then(() => setDriversList(driversArray))
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    });
  }, []);
  //#endregion

  //#region Delete from favourite handler
  const deleteFromFavouriteDrivers = async (driver, event) => {
    event.stopPropagation();

    const newFavouriteDrivers = user.favouriteDrivers
      .filter(driverFromList => driver.idPost !== driverFromList.idPost);

    await updateFieldInDocumentInCollection(collectionsInterface.users,
      user.idPost, 'favouriteDrivers', newFavouriteDrivers)

    setUser(prevUser => ({
      ...prevUser,
      favouriteDrivers: newFavouriteDrivers,
    }));

    setDriversList(prevList => prevList
      .filter(driverFromList => driver.idPost !== driverFromList.idPost))
  }
  //#endregion

  //#region Manage displaying drivers list or driver trips
  const [isDriversList, setIsDriversList] = useState(true);
  const [driverTrips, setDriverTrips] = useState([]);
  //#endregion

  //#region Handle driver card click
  const handleDriverCardClick = async (driver) => {
    setIsLoading(true);

    try {
      const driverCompany = await getDriverCompany(driver);

      const driverCompanyTrips = await getCollectionWhereKeysValues(
        collectionsInterface.trips, [
        {
          key: 'isDraft',
          value: false,
        },
        {
          key: 'isVisibleForDispatchers',
          value: true,
        },
        {
          key: 'status',
          value: tripStatusesInterface.openToSearch,
        },
        {
          key: 'creatorUid',
          value: driverCompany.uid,
        },
      ], 4);

      const suitableTrips = driverCompanyTrips.filter(trip => trip.drivers
        .find(tripDriver => tripDriver.uid === driver.uid));

      setDriverTrips(suitableTrips);

      setIsDriversList(false);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title on component mount
  useEffect(() => {
    if (isDriversList) {
      setScreenTitle('Избранные водители');
    } else {
      setScreenTitle('Поездки водителя');
    }
  }, [isDriversList]);
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [driversChunk, setDriversChunk] = useState([]);

  useEffect(() => {
    setDriversChunk(driversList
      .slice((currentPage - 1) * entitiesNumberInterface.favouriteDriversCards,
        currentPage * entitiesNumberInterface.favouriteDriversCards));
  }, [driversList.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/> :
        <>
          <div className={'PaddingBlock'}></div>

          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          {isDriversList ?
            <div className={`${addClasses}`}>
              <div className={'DataListWithPagination'}>
                <div className={`ListContainer`}>
                  <div className={'CardsList'}>
                    {driversChunk?.map((driver, index) =>
                      <React.Fragment key={driver.idPost}>
                        <FavouriteDriverCard
                          driver={driver}
                          deleteFromFavouriteDrivers={
                            deleteFromFavouriteDrivers
                          }
                          handleDriverCardClick={handleDriverCardClick}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>


                {driversList.length > entitiesNumberInterface
                  .favouriteDriversCards &&
                  <PaginationBlock
                    portionSize={entitiesNumberInterface.favouriteDriversCards}
                    listLength={driversList.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    startPage={startPage}
                    setStartPage={setStartPage}
                  />
                }
              </div>
            </div>
            :
            <DisplayDriverTrips
              addClasses={'App-Screen'}
              driverTrips={driverTrips}
            />
          }
        </>
      }
    </>
  );
  //#endregion
};

export default FavouriteDriversList;
