import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  compareSchemeAndDesirablePlaces,
  getAvailablePlacesFromList,
  getFreePlacesArrayFromPlacesScheme,
  transformStringsArrayToSelectOptions
} from "../utils/helpers";
import {Modal} from "react-bootstrap";
import FormInputAlertText from "../components/FormInputAlertText";
import {
  anotherFinancialConditionsModel,
  collectionsInterface,
  pagesInterface,
  placesSchemeModel, subpagesInterface
} from "../utils/models";
import {
  getDocInCollection, updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import PlacesInTripDisplaying from "../components/PlacesInTripDisplaying";
import LoaderSmall from "../components/LoaderSmall";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import useTranslation from "../hooks/useTranslation";

const ModalChangeReservedPlaces = ({
  showModal,
  setShowModal = () => {},
  title = '',
  acceptablePlaces = [],
  conflictPlaces = [],
  updateAfterReservationSubmit = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentReservation, currentTrip, currentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Proposed places
  const [proposedPlacesNumbers, setProposedPlacesNumbers] = useState([]);
  //#endregion

  //#region Handle errors
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Actual places scheme
  const [actualScheme, setActualScheme] = useState(placesSchemeModel);
  const [placesList, setPlacesList] = useState([]);

  useEffect(() => {
    getDocInCollection(collectionsInterface.trips, currentTrip.idPost)
      .then(trip => {
        const places = getFreePlacesArrayFromPlacesScheme(
          trip?.placesScheme);

        setPlacesList(places);

        const reservedPlacesNumbers = [];

        for (let i = 0; i < currentTripOrder.requiredNumberOfPlaces; i++) {
          reservedPlacesNumbers.push(places[i]);
        }

        setProposedPlacesNumbers(reservedPlacesNumbers);

        setActualScheme(trip.placesScheme);
      }).catch(() => {})
      .finally(() => {setIsLoading(false)});
  }, []);
  //#endregion

//#region Handle places selection
  const changeReservedPlaceNumber = (placeNumber, index) => {
    try {
      const newReservedPlacesArray = proposedPlacesNumbers
        ?.map((place, pIndex) => index !== pIndex ? place : placeNumber);

      setProposedPlacesNumbers(newReservedPlacesArray);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Handle submit new proposition
  const handleSubmitConfirmation = async () => {
    try {
      setIsLoading(true);

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        currentTrip.idPost);

      if (!actualTrip?.idPost) {
        setIsError(true);
        setErrorText('Поездка была удалена');

        return;
      }

      const updatedFreePlaces = parseInt(actualTrip.freePlaces) - parseInt(
        currentReservation.reservedPlaces);

      if (updatedFreePlaces < 0) {
        setIsError(true);
        setErrorText('Количество бронируемых мест больше ' +
          'количества свободных мест в поездке');

        return;
      }

      const occupiedRequiredPlaces = compareSchemeAndDesirablePlaces(
        actualTrip.placesScheme, proposedPlacesNumbers);

      if (occupiedRequiredPlaces.length > 0) {
        setActualScheme(actualTrip.placesScheme);
        const places = getFreePlacesArrayFromPlacesScheme(actualTrip?.placesScheme);

        const reservedPlacesNumbers = [];

        for (let i = 0; i < currentTripOrder.requiredNumberOfPlaces; i++) {
          reservedPlacesNumbers.push(places[i]);
        }

        setPlacesList(places);
        setProposedPlacesNumbers(reservedPlacesNumbers);

        setIsError(true);
        setErrorText('Вы пытаетесь предложить места, которые уже заняты');

        return;
      }

      const actualOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, currentTripOrder.idPost);

      if (!actualOrder?.idPost) {
        setIsError(true);
        setErrorText('Пассажир был удален');

        return;
      }

      if (actualOrder?.isActive) {
        setIsError(true);
        setErrorText('У пассажира уже есть подтвержденная поездка');

        return;
      }

      let fieldToUpdate = 'reservationsFromDispatchers';
      let updatedFieldValueForTripOrder = [];
      let updatedFieldValueForTrip = [];

      if (currentReservation.initiator === 'driver') {
        fieldToUpdate = 'reservationsFromDrivers';
      }

      let updatedReservation = {
        ...currentReservation,
        anotherPlacesProposition: {
          initiatorId: user.idPost,
          proposedPlacesNumbers,
        },
        isSubmittedByDriver: true,
        isSubmittedByDispatcher: true,
      }

      const conditionsInitiatorId = currentReservation?.anotherFinancialConditions
        ?.initiatorId;

      if (conditionsInitiatorId && conditionsInitiatorId !== user.idPost) {
        updatedReservation = {
          ...updatedReservation,
          anotherFinancialConditions: anotherFinancialConditionsModel,
          dispatcherFee: currentReservation.anotherFinancialConditions
            .dispatcherFee,
          isForChange: currentReservation?.anotherFinancialConditions
            ?.isForChange || false,
          isPaymentByCash: currentReservation?.anotherFinancialConditions
            ?.isPaymentByCash || false,
          isPaymentOnCard: currentReservation?.anotherFinancialConditions
            ?.isPaymentOnCard || false,
        }
      }

      updatedFieldValueForTrip = actualTrip[fieldToUpdate]
        .map(reserv => reserv.uuid === currentReservation.uuid
          ? updatedReservation : reserv);

      let fieldsToUpdate = {
        [fieldToUpdate]: updatedFieldValueForTrip,
        // freePlaces: updatedFreePlaces,
      };

      updatedFieldValueForTripOrder = actualOrder[fieldToUpdate]
        .map(reserv => reserv.uuid === currentReservation.uuid
          ? updatedReservation : reserv);

      await updateFieldsInDocumentInCollection(collectionsInterface.trips,
        actualTrip.idPost, fieldsToUpdate);

      await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
        actualOrder.idPost, {
          [fieldToUpdate]: updatedFieldValueForTripOrder,
        });

      if (currentTripOrder.creatorIdPost === user.idPost) {
        const updatedTripOrder = await getDocInCollection(collectionsInterface
          .tripsOrders, currentTripOrder.idPost);

        updateAfterReservationSubmit(updatedTripOrder);

        navigate(`/${pagesInterface.passengers}/${subpagesInterface.reservedTrips}`);
      } else {
        const updatedTrip = await getDocInCollection(collectionsInterface.trips,
          currentTrip.idPost);

        updateAfterReservationSubmit(updatedTrip);

        navigate(`/${pagesInterface.trips}/${subpagesInterface.reservedTrips}`);
      }
    } catch (error) {
      setIsError(true);
      setErrorText('Ошибка')
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {translate(title)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? <LoaderSmall /> :
          <>
            <div>
              {`${translate('Можно оставить места')}: ${acceptablePlaces
                .toString()}`}
            </div>

            <div>
              {`${translate('Необходимо заменить места')}: ${conflictPlaces
                .toString()}`}
            </div>

            {isError &&
              <FormInputAlertText
                text={errorText}
                setIsVisible={setIsError}
                addClasses={'LeftPadding'}
              />
            }

            <>
              <div className={'PaddingBlock PaddingBlock_Half'}></div>

              <div className={'DataLabel'}>
                {translate('Выбор мест')}
              </div>

              <div>
                <div className={'ConfirmReservation-PlacesSelects'}>
                  {currentReservation?.reservedPlacesNumbers?.map((placeNumber,
                    index) =>
                    <React.Fragment key={placeNumber}>
                      <Select
                        classNames={{
                          control: (state) =>
                            state.isFocused
                              ? 'FormControl ConfirmReservation-Select'
                              : 'FormControl ConfirmReservation-Select',
                        }}
                        options={transformStringsArrayToSelectOptions(
                          getAvailablePlacesFromList(placesList,
                            proposedPlacesNumbers))}
                        onChange={(event) => changeReservedPlaceNumber(
                          event.value, index)}
                        value={{
                          value: proposedPlacesNumbers[index] || '',
                          label: proposedPlacesNumbers[index] || '',
                        }}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className={'PaddingBlock PaddingBlock_Half'}></div>

              <PlacesInTripDisplaying
                scheme={actualScheme || []}
              />
            </>
          </>
        }
      </Modal.Body>

      <div className={'PaddingBlock'}></div>
      <div className={'PaddingBlock'}></div>

      <Modal.Footer>
        <div className={'FlexBlock FlexBlock-HorizontalItem_W100'}>
          <button
            type={'button'}
            className={'RegularButton FlexBlock-HorizontalItem_W49'}
            onClick={() => handleSubmitConfirmation()}
          >
            {translate('Подтвердить')}
          </button>

          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast FlexBlock-HorizontalItem_W49'}
            onClick={() => {
              setShowModal(false)
            }}
          >
            {translate('Вернуться')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalChangeReservedPlaces;
