import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import AddToBalance from "./AddToBalance";
import {collectionsInterface, pagesInterface} from "../utils/models";
import {Route, Routes, useNavigate} from "react-router-dom";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import TransactionsMainScreen from "./TransactionsMainScreen";
import WithdrawFromBalance from "./WithdrawFromBalance";
import InviteToApp from "./InviteToApp";
import WithdrawalsList from "./WithdrawalsList";
import '../styles/MyWallet.scss';
import ReferralsList from "./ReferralsList";

const MyWallet = ({addClasses=''}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate(`/${pagesInterface.profile}`);
    }
  }, []);
  //#endregion

  //#region Get user balance
  const [balance, setBalance] = useState({});

  useEffect(() => {
    getDocInCollection(collectionsInterface.balanceAccounts, user.balanceAccount)
      .then(result => setBalance(result));
  }, []);
  //#endregion

  //#region Handle action buttons click (make deposit, withdrawal, invite to app)
  const handleMakeDepositButtonClick = () => {
    navigate(`/${pagesInterface.profile}/myWallet/makeDeposit`);
  }

  const handleMakeWithdrawalButtonClick = () => {
    navigate(`/${pagesInterface.profile}/myWallet/makeWithdrawal`);
  }

  const handleInviteToAppButtonClick = () => {
    navigate(`/${pagesInterface.profile}/myWallet/inviteToApp`);
  }

  const handleSeeWithdrawalButtonClick = () => {
    navigate(`/${pagesInterface.profile}/myWallet/ordersForWithdrawal`)
  }

  const handleShowReferralsButtonClick = () => {
    navigate(`/${pagesInterface.profile}/myWallet/referrals`)
  }
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={`MyWallet ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <TransactionsMainScreen
                balance={balance}
                handleMakeDepositButtonClick={handleMakeDepositButtonClick}
                handleMakeWithdrawalButtonClick={handleMakeWithdrawalButtonClick}
                handleInviteToAppButtonClick={handleInviteToAppButtonClick}
                handleSeeWithdrawalButtonClick={handleSeeWithdrawalButtonClick}
                handleShowReferralsButtonClick={handleShowReferralsButtonClick}
                addClasses={'App-Screen App-Screen_Narrow'}
              />
            }
          />

          <Route
            path={'makeDeposit/*'}
            element={
              <AddToBalance
                addClasses={'App-Screen'}
              />
            }
          />

          <Route
            path={'makeWithdrawal/*'}
            element={
              <WithdrawFromBalance
                addClasses={'App-Screen'}
              />
            }
          />

          <Route
            path={'inviteToApp/*'}
            element={
              <InviteToApp
                addClasses={'App-Screen App-Screen_Narrow'}
              />
            }
          />

          <Route
            path={'referrals/*'}
            element={
              <ReferralsList
                addClasses={'App-Screen App-Screen_Narrow'}
              />
            }
          />

          <Route
            path={'ordersForWithdrawal/*'}
            element={
              <WithdrawalsList
                addClasses={'App-Screen'}
              />
            }
          />
        </Routes>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default MyWallet;
