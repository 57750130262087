import React, {useState, useContext, useEffect} from 'react';
import CounterPartyCard from "./CounterPartyCard";
import ModalConfirmPaymentForExchange
  from "../modals/ModalConfirmPaymentForExchange";
import {AppContext} from "../context/appContext";
import {
  getDocInCollection,
  updateFieldInDocumentInCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface, entitiesNumberInterface, transactionsTypesInterface
} from "../utils/models";
import {createTransactionAndSaveToTransactions} from "../utils/helpers";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import '../styles/DebtsList.scss';

const DebtsList = ({
  addClasses = '',
  counterparties = [],
  isCreditor = true,
  handleCardClick = () => {},
  isArchive = false,
  cardsNumber = entitiesNumberInterface.counterpartyCards,
}) => {
  //#region get userData from context
  const {setUser} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Payment for exchange confirmation or denial
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentDeal, setCurrentDeal] = useState({});

  const paymentConfirmationClick = (deal) => {
    setShowConfirmationModal(true);
    setCurrentDeal(deal);
  }

  const confirmPayment = async (deal, user) => {
    setIsLoading(true);

    try {
      const actualUser = await getDocInCollection(collectionsInterface.users,
        user.idPost);

      const updatedArchivedExchanges = actualUser.archivedExchanges
        .map(exchange => exchange.id !== deal.id ? exchange : {
          ...exchange,
          isFeePaid: true,
        });

      await updateFieldInDocumentInCollection(collectionsInterface.users,
        user.idPost, 'archivedExchanges', updatedArchivedExchanges);

      const actualCounterparty = await getDocInCollection(
        collectionsInterface.users, deal.payer.idPost);

      const updatedArchivedExchangesForPayer =
        actualCounterparty.archivedExchanges
        .map(exchange => exchange.id !== deal.id ? exchange : {
          ...exchange,
          isFeePaid: true,
        });

      await updateFieldInDocumentInCollection(collectionsInterface.users,
        deal.payer.idPost, 'archivedExchanges',
        updatedArchivedExchangesForPayer);

      await createTransactionAndSaveToTransactions(transactionsTypesInterface
        .compensationForExchange, deal, actualUser, actualCounterparty);

      setUser({
        ...actualUser,
        archivedExchanges: updatedArchivedExchanges,
      });

      setShowConfirmationModal(false);

      setIsLoading(true);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }

  const denyPayment = async (deal, user) => {
    setIsLoading(true);

    try {
      const actualUser = await getDocInCollection(collectionsInterface.users,
        user.idPost);
      const actualCounterParty = await getDocInCollection(
        collectionsInterface.users, deal.payer.idPost);

      let counterpartyInDebtors;
      let counterpartyInCreditors;
      let newBalance = 0;
      let newCreditors;
      let newDebtors;
      const newArchivedExchangesForUser = actualUser.archivedExchanges
        .filter(exchange => exchange.id !== deal.id);
      const newArchivedExchangesForCounterparty = actualCounterParty
        .archivedExchanges.filter(exchange => exchange.id !== deal.id);

      counterpartyInDebtors = actualUser.debtors
        .find(debtor => debtor.idPost === deal.payer.idPost);
      counterpartyInCreditors = actualUser.creditors
        .find(creditor => creditor.idPost === deal.payer.idPost);

      if (counterpartyInCreditors) {
        newBalance = counterpartyInCreditors.amount - deal.amount;

        if (newBalance === 0) {
          newCreditors = user.creditors
            .filter(creditor => creditor.idPost !== deal.payer.idPost);

          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            actualUser.idPost, {
            creditors: newCreditors,
            archivedExchanges: newArchivedExchangesForUser,
          });

          newDebtors = actualCounterParty.debtors.filter(debtor =>
            debtor.idPost !== user.idPost);

          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            actualCounterParty.idPost,
            {
              debtors: newDebtors,
              archivedExchanges: newArchivedExchangesForCounterparty,
            });

          setUser(state => ({
            ...state,
            creditors: newCreditors,
            archivedExchanges: newArchivedExchangesForUser,
          }));

          setShowConfirmationModal(false);
          return;
        }

        if (newBalance > 0) {
          newCreditors = user.creditors
            .map(creditor => creditor.idPost !== deal.payer.idPost ?
            creditor : {
              ...creditor,
              amount: newBalance,
            });

          newDebtors = actualCounterParty.debtors
            .map(debtor => debtor.idPost !== user.idPost ?
            debtor : {
              ...debtor,
              amount: newBalance,
            }
          );

          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            actualCounterParty.idPost, {
            debtors: newDebtors,
            archivedExchanges: newArchivedExchangesForCounterparty,
          });

          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            user.idPost, {
            creditors: newCreditors,
            archivedExchanges: newArchivedExchangesForUser,
          });

          setUser(state => ({
            ...state,
            creditors: newCreditors,
            archivedExchanges: newArchivedExchangesForUser,
          }));

          setShowConfirmationModal(false);
          return;
        }

        if (newBalance < 0) {
          newCreditors = user.creditors
            .filter(creditor => creditor.idPost !== deal.payer.idPost);
          newDebtors = actualCounterParty.debtors
            .filter(debtor => debtor.idPost !== user.idPost);

          const newCreditorsOfCounterparty = [...actualCounterParty.creditors, {
            idPost: user.idPost,
            amount: -newBalance,
            fullName: user.fullName,
          }];

          const newDebtorsOfUser = [...user.debtors, {
            idPost: actualCounterParty.idPost,
            amount: -newBalance,
            fullName: actualCounterParty.fullName,
          }];


          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            actualCounterParty.idPost, {
            debtors: newDebtors,
            creditors: newCreditorsOfCounterparty,
            archivedExchanges: newArchivedExchangesForCounterparty,
          });

          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            user.idPost, {
            creditors: newCreditors,
            debtors: newDebtorsOfUser,
            archivedExchanges: newArchivedExchangesForUser,
          });

          setUser(state => ({
            ...state,
            creditors: newCreditors,
            debtors: newDebtorsOfUser,
            archivedExchanges: newArchivedExchangesForUser,
          }));

          setShowConfirmationModal(false);

          return;
        }
      } else {
        if (counterpartyInDebtors) {
          newBalance = counterpartyInDebtors.amount + deal.amount;

          newCreditors = actualCounterParty.creditors
            .map(creditor => creditor.idPost !== user.idPost ?
            creditor : {
              ...creditor,
              amount: newBalance,
            }
          );

          newDebtors = user.debtors
            .map(debtor => debtor.idPost !== actualCounterParty.idPost ?
            debtor : {
              ...debtor,
              amount: newBalance,
            }
          );
        } else {
          newBalance = deal.amount;

          newCreditors = [...actualCounterParty.creditors, {
            idPost: user.idPost,
            amount: newBalance,
            fullName: user.fullName,
          }];

          newDebtors = [...user.debtors, {
            idPost: actualCounterParty.idPost,
            amount: newBalance,
            fullName: actualCounterParty.fullName,
          }];
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.users,
          user.idPost, {
          debtors: newDebtors,
          archivedExchanges: newArchivedExchangesForUser,
        });

        await updateFieldsInDocumentInCollection(collectionsInterface.users,
          actualCounterParty.idPost, {
          creditors: newCreditors,
          archivedExchanges: newArchivedExchangesForCounterparty,
        });

        setUser(state => ({
          ...state,
          debtors: newDebtors,
          archivedExchanges: newArchivedExchangesForUser,
        }));
      }

      setShowConfirmationModal(false);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [counterpartiesChunk, setCounterpartiesChunk] = useState([]);

  useEffect(() => {
    setCounterpartiesChunk(counterparties
      .slice((currentPage - 1) * cardsNumber,
        currentPage * cardsNumber));
  }, [counterparties, currentPage, isArchive]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={'DataListWithPagination'}>
          <div className={'ListContainer ListContainer_Reduced'}>
            <div className={`DebtsList ${addClasses}`}>
              {counterpartiesChunk.map((counterparty, cIndex) => (
                <React.Fragment key={counterparty.idPost}>
                  <CounterPartyCard
                    counterparty={counterparty}
                    isCreditor={isCreditor}
                    handleCardClick={handleCardClick}
                    isArchive={isArchive}
                    paymentConfirmationClick={paymentConfirmationClick}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>

          {counterparties.length > cardsNumber &&
            <PaginationBlock
              portionSize={cardsNumber}
              listLength={counterparties.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
            />
          }
        </div>
      }

      {showConfirmationModal &&
        <ModalConfirmPaymentForExchange
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
          deal={currentDeal}
          confirmPayment={confirmPayment}
          denyPayment={denyPayment}
          isLoading={isLoading}
        />
      }
    </>
  );
  //#endregion
};

export default DebtsList;
