import React, {useContext} from 'react';
import {
  checkIsDriverInFavourites,
  handleChangeFavouriteClick
} from "../utils/helpers";
import {AppContext} from "../context/appContext";

const DisplayCounterPartyData = ({
  counterparty,
  isCreditor,
}) => {
  //#region get userData from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Render
  return (
    <>
      <div className={'CounterPartyCard-Item FlexBlock'}>
        <span>
          {counterparty?.fullName}
        </span>

        <div className={'FlexBlock FlexBlock_JustifyLeft'}>
          <span
            className={'CounterPartyCard-IconContainer LeftPaddingTiny'}
          >
            <img
              src={'/img/mark-star-icon.svg'}
              alt={'Star icon'}
            />
          </span>

          <span className={'LeftPaddingSmall'}>
            {isCreditor ? counterparty.tripsAsDispatcher
              : counterparty.tripsAsDriver}
          </span>

          <span className={'LeftPaddingSmall'}>
            {`(${counterparty.averageMark})`}
          </span>

          {!isCreditor &&
            <span
              className={'CounterPartyCard-IconContainer  LeftPaddingSmall'}
              onClick={(event) =>
                handleChangeFavouriteClick(event, counterparty, user, setUser)}
            >
              {checkIsDriverInFavourites(counterparty, user) ?
                <img
                  className={!isCreditor ? 'MenuIcon_Clickable' : ''}
                  src={'/img/filled-heart-icon.svg'}
                  alt={'Heart icon'}
                />
                :
                <img
                  className={!isCreditor ? 'MenuIcon_Clickable' : ''}
                  src={'/img/pale-heart-icon.svg'}
                  alt={'Heart icon'}
                />
              }
            </span>
          }
        </div>
      </div>
    </>
  );
  //#endregion
};

export default DisplayCounterPartyData;
