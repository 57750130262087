import React, {useContext} from 'react';
import {Modal} from "react-bootstrap";
import {AppContext} from "../context/appContext";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";

const ModalConfirmPaymentForExchange = ({
    showModal = false,
    setShowModal = () => {},
    confirmPayment = () => {},
    denyPayment = () => {},
    deal,
    addClasses='',
    isLoading = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
        setShowModal(false);
      }}
      className={`ModalConfirmPaymentForExchange ${addClasses}`}
    >
      {isLoading ? <Loader />
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className={'RegularTitle RegularTitle_Small'}>
                {translate('Подтверждение оплаты за обмен')}
              </h2>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {translate('Вы подтверждаете, что получили выплату по ' +
              'обмену')}{'?'}
          </Modal.Body>

          <Modal.Footer>
            <button
                type={'button'}
                className={'RegularButton FlexBlock-HorizontalItem ' +
                  'FlexBlock-HorizontalItem_W47'}
                onClick={() => confirmPayment(deal, user)}
            >
                {translate('Да')}
            </button>
            <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast ' +
                  'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W47'}
                onClick={() => denyPayment(deal, user)}
            >
                {translate('Нет')}
            </button>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
  //#endregion
};

export default ModalConfirmPaymentForExchange;
