import React from 'react';
import {Modal} from "react-bootstrap";
import EvaluateUser from "../components/EvaluateUser";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";

const ModalEvaluation = ({
    title = '',
    showModal,
    setShowModal = () => {},
    isDispatcher,
    evaluatedUsers = [],
    isLoading = false,
    setIsLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      {(isLoading || evaluatedUsers.length === 0) ? <Loader />
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {translate(title)}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EvaluateUser
              handleEvaluateLater={setShowModal}
              addClasses={'ModalEvaluation-EvaluateUser'}
              isDispatcher={isDispatcher}
              evaluatedUsers={evaluatedUsers}
              setShowModal={setShowModal}
              setIsLoading={setIsLoading}
            />
          </Modal.Body>
        </>
      }
    </Modal>
  );
  //#endregion
};

export default ModalEvaluation;
