import React, {useState, useContext} from 'react';
import {checkPasswordsAndSetErrorText} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from "firebase/auth";
import {auth} from '../utils/firebaseConfigAndFunctions';
import ModalNotification from "../modals/ModalNotification";
import FormInputAlertText from "./FormInputAlertText";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/ChangePassword.scss';

const ChangePassword = ({
  addClasses = '',
  setIsChangePasswordScreen = () => {},
  setIsMainScreen = () => {}
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get language from context, manage languages
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Notification
  const [isModalNotification, setIsModalNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Errors handling and displaying
  const [isOldPasswordError, setIsOldPasswordError] = useState(false);
  const [isPasswordsCompareError, setIsPasswordsCompareError] = useState(false);
  //#endregion

  //#region Manage password visibility
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
  //#endregion

  //#region Sign in data and it's update
  const [signInData, setSignInData] = useState({
    rulesAccepted: true,
  });

  const updateSignInData = (fieldName, newValue) => {
    setSignInData(state => ({
      ...state,
      [fieldName]: newValue,
    }))
  }
  //#endregion

  //#region Submit form and reauth user
  async function reAuthUserAndUpdateEmailOrPassword(event, email, authData) {
    event.preventDefault();

    setIsLoading(true);

    const checkResult = checkPasswordsAndSetErrorText(authData.password,
      authData.repeatPassword);

    if (checkResult) {
      setIsPasswordsCompareError(true);
      setNotificationMessage(checkResult);

      setIsLoading(false);
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        authData.currentPassword
      );

      await reauthenticateWithCredential(auth.currentUser, credential);

      const curUser = auth.currentUser;

      await updatePassword(curUser, authData.password);

      setIsModalNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Пароль успешно изменен');


      setTimeout(() => {
        navigate('/profile');
      }, 1000);
    } catch (error) {
      if (error?.message?.includes('auth/wrong-password')) {
        setIsOldPasswordError(true);
        setNotificationMessage('Неверный действующий пароль');
      }

      if (error?.message?.includes('auth/too-many-requests')) {
        setIsOldPasswordError(true);
        setNotificationMessage(
          'Превышено число попыток входа. Попробуйте позже');
      }
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div className={`ChangePassword ${addClasses}`}>
          <div className={'ChangePassword-Item'}>
            <h2 className={'RegularTitle'}>
              {translate('Смена пароля')}
            </h2>
          </div>

          <div className={'ChangePassword-Item'}>
            <label className={'ChangePassword-Label'}>
              {translate('Старый пароль')}
            </label>

            <div className={'ChangePassword-InputContainer'}>
              {signInData.currentPassword ?
                <img
                  src={'/img/lock-blue.svg'}
                  className={'ChangePassword-InputIcon LockIcon'}
                  alt={'Lock icon'}
                />
                :
                <img
                  src={'/img/lock-icon.svg'}
                  className={'ChangePassword-InputIcon LockIcon'}
                  alt={'Lock icon'}
                />
              }

              <input
                className={signInData.currentPassword ? 'FormControl ' +
                  'ChangePassword-Input PasswordInputWithText'
                  : 'FormControl ChangePassword-Input'}
                type={isCurrentPasswordVisible ? 'text' : 'password'}
                placeholder={translate('Введите старый пароль')}
                onChange={(event) => updateSignInData(
                  'currentPassword', event.target.value)}
                value={signInData.currentPassword}
              />

              {signInData.currentPassword ?
                (isCurrentPasswordVisible ?
                    <img
                      src={'/img/eye-with-line-icon-blue.svg'}
                      className={'ChangePassword-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsCurrentPasswordVisible(false)}
                    />
                    :
                    <img
                      src={'/img/eye-icon-blue.svg'}
                      className={'ChangePassword-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsCurrentPasswordVisible(true)}
                    />
                ) : (isCurrentPasswordVisible ?
                  <img
                    src={'/img/eye-with-line-icon.svg'}
                    className={'ChangePassword-InputIconRight EyeIcon'}
                    alt={'Eye icon'}
                    onClick={() => setIsCurrentPasswordVisible(false)}
                  />
                  :
                  <img
                    src={'/img/eye-icon.svg'}
                    className={'ChangePassword-InputIconRight EyeIcon'}
                    alt={'Eye icon'}
                    onClick={() => setIsCurrentPasswordVisible(true)}
                  />)
              }
            </div>

            {isOldPasswordError &&
              <FormInputAlertText
                text={notificationMessage}
                setIsVisible={setIsOldPasswordError}
              />
            }
          </div>

          <div className={'ChangePassword-Item'}>
            <label className={'ChangePassword-Label'}>
              {translate('Новый пароль')}
            </label>

            <div className={'ChangePassword-InputContainer'}>
              {signInData.password ?
                <img
                  src={'/img/lock-blue.svg'}
                  className={'ChangePassword-InputIcon LockIcon'}
                  alt={'Lock icon'}
                />
                :
                <img
                  src={'/img/lock-icon.svg'}
                  className={'ChangePassword-InputIcon LockIcon'}
                  alt={'Lock icon'}
                />
              }

              <input
                className={signInData?.password ? 'FormControl ' +
                  'ChangePassword-Input PasswordInputWithText'
                  : 'FormControl ChangePassword-Input'}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={translate('Новый пароль')}
                onChange={(event) => updateSignInData('password',
                  event.target.value)}
                value={signInData.password}
              />

              {signInData.password ?
                (isPasswordVisible ?
                    <img
                      src={'/img/eye-with-line-icon-blue.svg'}
                      className={'ChangePassword-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsPasswordVisible(false)}
                    />
                    :
                    <img
                      src={'/img/eye-icon-blue.svg'}
                      className={'ChangePassword-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsPasswordVisible(true)}
                    />
                ) : (isPasswordVisible ?
                  <img
                    src={'/img/eye-with-line-icon.svg'}
                    className={'ChangePassword-InputIconRight EyeIcon'}
                    alt={'Eye icon'}
                    onClick={() => setIsPasswordVisible(false)}
                  />
                  :
                  <img
                    src={'/img/eye-icon.svg'}
                    className={'ChangePassword-InputIconRight EyeIcon'}
                    alt={'Eye icon'}
                    onClick={() => setIsPasswordVisible(true)}
                  />)
              }
            </div>
          </div>

          <div className={'ChangePassword-Item'}>
            <label className={'ChangePassword-Label'}>
              {translate('Подтвердите новый пароль')}
            </label>

            <div className={'ChangePassword-InputContainer'}>
              {signInData.repeatPassword ?
                <img
                  src={'/img/lock-blue.svg'}
                  className={'ChangePassword-InputIcon LockIcon'}
                  alt={'Lock icon'}
                />
                :
                <img
                  src={'/img/lock-icon.svg'}
                  className={'ChangePassword-InputIcon LockIcon'}
                  alt={'Lock icon'}
                />
              }

              <input
                className={signInData?.repeatPassword ? 'FormControl ' +
                  'ChangePassword-Input PasswordInputWithText'
                  : 'FormControl ChangePassword-Input'}
                type={isRepeatPasswordVisible ? 'text' : 'password'}
                placeholder={translate('Подтвердите новый пароль')}
                onChange={(event) => updateSignInData(
                  'repeatPassword', event.target.value)}
                value={signInData.repeatPassword}
              />

              {signInData.repeatPassword ?
                (isRepeatPasswordVisible ?
                    <img
                      src={'/img/eye-with-line-icon-blue.svg'}
                      className={'ChangePassword-InputIconRight'}
                      alt={'Eye icon'}
                      onClick={() => setIsRepeatPasswordVisible(false)}
                    />
                    :
                    <img
                      src={'/img/eye-icon-blue.svg'}
                      className={'ChangePassword-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsRepeatPasswordVisible(true)}
                    />
                ) : (isRepeatPasswordVisible ?
                  <img
                    src={'/img/eye-with-line-icon.svg'}
                    className={'ChangePassword-InputIconRight'}
                    alt={'Eye icon'}
                    onClick={() => setIsRepeatPasswordVisible(false)}
                  />
                  :
                  <img
                    src={'/img/eye-icon.svg'}
                    className={'ChangePassword-InputIconRight EyeIcon'}
                    alt={'Eye icon'}
                    onClick={() => setIsRepeatPasswordVisible(true)}
                  />)
              }
            </div>

            {isPasswordsCompareError &&
              <FormInputAlertText
                text={notificationMessage}
                setIsVisible={setIsPasswordsCompareError}
              />
            }
          </div>

          <div className={'ChangePassword-Item'}>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={(event) => reAuthUserAndUpdateEmailOrPassword(
                event, user.email, signInData)}
            >
              {translate('Сохранить')}
            </button>
          </div>
          <div className={'ChangePassword-Item'}>
            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast'}
              onClick={() => {
                setIsMainScreen(true);
                setIsChangePasswordScreen(false);
              }}
            >
              {translate('Вернуться')}
            </button>
          </div>
        </div>
      }

      {isModalNotification &&
        <ModalNotification
          showModal={isModalNotification}
          setShowModal={setIsModalNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default ChangePassword;
