import React, {useContext, useEffect} from 'react';
import {rolesInterface} from "../utils/models";
import {checkAreThereNewNotificationsInList} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {logOut} from "../utils/firebaseConfigAndFunctions";
import useTranslation from "../hooks/useTranslation";
import '../styles/Profile.scss';

const ProfilePageMenu = ({addClasses = '', notifications = []}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screens from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Профиль');
  }, []);
  //#endregion

  //#region Render
  return (
    <nav className={`Profile-Nav SectionNavMenu ${addClasses}`}>
      <div
        className={'SectionNavMenu-Item'}
        onClick={() => {
          navigate('personData');
          setScreenTitle('Личные данные');
        }}
      >
        <div className={'SectionNavMenu-Icon'}>
          <img
            className={'MenuIcon'}
            src={'/img/profile-menu/document-icon.svg'}
            alt={'Profile icon'}
          />
        </div>

        <div className={'SectionNavMenu-Text'}>
          {translate('Личные данные')}
        </div>

        <div className={'SectionNavMenu-Icon'}>
          <img
            src={'/img/profile-menu/right-arrow-icon.svg'}
            alt={'Arrow icon'}
          />
        </div>
      </div>

      {user?.role === rolesInterface.user &&
        <>
          {user.idPost && !user.attachedToCompany &&
            <>
              <div
                className={'SectionNavMenu-Item'}
                onClick={() => {
                  setScreenTitle('Мои автомобили');
                  navigate('myVehicles');
                }}
              >
                <div className={'SectionNavMenu-Icon'}>
                  <img
                    className={'MenuIcon'}
                    src={'/img/profile-menu/car-icon.svg'}
                    alt={'Car icon'}
                  />
                </div>

                <div className={'SectionNavMenu-Text'}>
                  {translate('Мои автомобили')}
                </div>

                <div className={'SectionNavMenu-Icon'}>
                  <img
                    src={'/img/profile-menu/right-arrow-icon.svg'}
                    alt={'Arrow icon'}
                  />
                </div>
              </div>

              <div
                className={'SectionNavMenu-Item'}
                onClick={() => {
                  setScreenTitle('Мои водители');
                  navigate('myDrivers');
                }}
              >
                <div className={'SectionNavMenu-Icon'}>
                  <img
                    className={'MenuIcon'}
                    src={'/img/profile-menu/avatar-icon.svg'}
                    alt={'Avatar icon'}
                  />
                </div>

                <div className={'SectionNavMenu-Text'}>
                  {translate('Мои водители')}
                </div>

                <div className={'SectionNavMenu-Icon'}>
                  <img
                    src={'/img/profile-menu/right-arrow-icon.svg'}
                    alt={'Arrow icon'}
                  />
                </div>
              </div>

              <div
                className={'SectionNavMenu-Item'}
                onClick={() => {
                  setScreenTitle('Приглашения');
                  navigate('invitations');
                }}
              >
                <div className={'SectionNavMenu-Icon'}>
                  <img
                    className={'MenuIcon'}
                    src={'/img/profile-menu/share-icon.svg'}
                    alt={'Share icon'}
                  />
                </div>

                <div className={'SectionNavMenu-Text'}>
                  {translate('Приглашения от компаний')}
                </div>

                <div className={'SectionNavMenu-Icon'}>
                  <img
                    src={'/img/profile-menu/right-arrow-icon.svg'}
                    alt={'Arrow icon'}
                  />
                </div>
              </div>

              <div
                className={'SectionNavMenu-Item'}
                onClick={() => {
                  setScreenTitle('Мой кошелек');
                  navigate('myWallet');
                }}
              >
                <div className={'SectionNavMenu-Icon'}>
                  <img
                    className={'MenuIcon'}
                    src={'/img/profile-menu/wallet-icon.svg'}
                    alt={'Wallet icon'}
                  />
                </div>

                <div className={'SectionNavMenu-Text'}>
                  {translate('Мой кошелек/история транзакций')}
                </div>

                <div className={'SectionNavMenu-Icon'}>
                  <img
                    src={'/img/profile-menu/right-arrow-icon.svg'}
                    alt={'Arrow icon'}
                  />
                </div>
              </div>

              <div
                className={'SectionNavMenu-Item'}
                onClick={() => {
                  setScreenTitle('О реферальной программе');
                  navigate('refProgramDescription');
                }}
              >
                <div className={'SectionNavMenu-Icon'}>
                  <img
                    className={'MenuIcon'}
                    src={'/img/profile-menu/share-icon.svg'}
                    alt={'Share icon'}
                  />
                </div>

                <div className={'SectionNavMenu-Text'}>
                  {translate('О реферальной программе')}
                </div>

                <div className={'SectionNavMenu-Icon'}>
                  <img
                    src={'/img/profile-menu/right-arrow-icon.svg'}
                    alt={'Arrow icon'}
                  />
                </div>
              </div>
            </>
          }

          <div
            className={'SectionNavMenu-Item'}
            onClick={() => {
              setScreenTitle('Служба поддержки');
              navigate('support');
            }}
          >
            <div className={'SectionNavMenu-Icon'}>
              <img
                className={'MenuIcon'}
                src={'/img/profile-menu/question-icon.svg'}
                alt={'Question icon'}
              />
            </div>

            <div className={'SectionNavMenu-Text'}>
              {translate('Служба поддержки')}
            </div>

            <div className={'SectionNavMenu-Icon'}>
              <img
                src={'/img/profile-menu/right-arrow-icon.svg'}
                alt={'Arrow icon'}
              />
            </div>
          </div>

          <div
            className={'SectionNavMenu-Item'}
            onClick={() => {
              setScreenTitle('Политика конфиденциальности');
              navigate('rulesAndPolicy');
            }}
          >
            <div className={'SectionNavMenu-Icon'}>
              <img
                className={'MenuIcon'}
                src={'/img/profile-menu/shield-icon.svg'}
                alt={'Shield icon'}
              />
            </div>

            <div className={'SectionNavMenu-Text'}>
              {translate('Политика конфиденциальности')}
            </div>

            <div className={'SectionNavMenu-Icon'}>
              <img
                src={'/img/profile-menu/right-arrow-icon.svg'}
                alt={'Arrow icon'}
              />
            </div>
          </div>

          <div
            className={'SectionNavMenu-Item'}
            onClick={() => {
              navigate('notifications');
            }}
          >
            <div className={'SectionNavMenu-Icon'}>
              <img
                className={'MenuIcon'}
                src={'/img/profile-menu/bell-icon.svg'}
                alt={'Bell icon'}
              />
            </div>

            <div className={'SectionNavMenu-Text'}>
              {translate('Уведомления')}
            </div>


            {checkAreThereNewNotificationsInList(notifications) &&
              <span
                className={'SectionNavMenu-NewIndicator NewIndicator'}
              ></span>
            }

            <div className={'SectionNavMenu-Icon'}>
              <img
                src={'/img/profile-menu/right-arrow-icon.svg'}
                alt={'Arrow icon'}
              />
            </div>
          </div>
        </>
      }

      <div
        className={'SectionNavMenu-Item'}
        onClick={() => {
          logOut(setUser).then(() => {
            navigate('/login');
          }).catch(() => {});
        }}
      >
        <div className={'SectionNavMenu-Icon'}>
          <img
            className={'MenuIcon'}
            src={'/img/profile-menu/exit-icon.svg'}
            alt={'Exit icon'}
          />
        </div>

        <div className={'SectionNavMenu-Text'}>
          {translate('Выйти из профиля')}
        </div>
      </div>
    </nav>
  );
  //#endregion
};

export default ProfilePageMenu;
