import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import PersonData from "../components/PersonData";
import CarsData from "../components/CarsData";
import MyDrivers from "../components/MyDrivers";
import MyWallet from "../components/MyWallet";
import RefProgramDescription from "../components/RefProgramDescription";
import Support from "../components/Support";
import RulesAndConfidentialityPolicy from "./RulesAndConfidentialityPolicy";
import {Route, Routes} from "react-router-dom";
import ProfilePageMenu from "../components/ProfilePageMenu";
import Notifications from "../components/Notifications";
import {reservationModel, tripModel, tripOrderModel} from "../utils/models";
import InvitationsList from "../components/InvitationsList";
import {sortCardsByDateOfCreation} from "../utils/helpers";
import '../styles/Profile.scss';

const Profile = ({addClasses='', notifications = []}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get current trip, tripOrder, reservation from context
  const {
    setCurrentTripOrder,
    setCurrentTrip,
    setCurrentReservation,
  } = useContext(AppContext);
  //#endregion

  //#region Clear current trip, trip order and reservation
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
    setCurrentReservation(reservationModel);
  }, []);
  //#endregion

  //#region Render
  return (
    <main className={`Profile ${addClasses}`}>
      <Routes>
        <Route
          index
          element={<ProfilePageMenu notifications={notifications} />}
        />

        <Route
          path={'/personData/*'}
          element={<PersonData />}
        />

        <Route
          path={'/myVehicles/*'}
          element={<CarsData />}
        />

        <Route
          path={'/myDrivers/*'}
          element={<MyDrivers />}
        />

        <Route
          path={'/invitations/*'}
          element={
          <InvitationsList
            addClasses={'App-Screen'}
            invitations={sortCardsByDateOfCreation(typeof user.invitations === 'object'
              ? Object.values(user?.invitations) : [], false)}
          />}
        />

        <Route
          path={'/myWallet/*'}
          element={<MyWallet />}
        />

        <Route
          path={'/refProgramDescription/*'}
          element={
            <RefProgramDescription
              addClasses={'App-Screen'}
            />
          }
        />

        <Route
          path={'/support/*'}
          element={<Support addClasses={'App-Screen App-Screen_Narrow'}/>}
        />

        <Route
          path={'/rulesAndPolicy/*'}
          element={
            <RulesAndConfidentialityPolicy
              addClasses={'Profile-RulesAndConfidentialPolicy'}
            />
          }
        />

        <Route
          path={'/notifications/*'}
          element={
            <Notifications
              notifications={notifications}
            />
          }
        />
      </Routes>
    </main>
  );
  //#endregion
};

export default Profile;
