import React, {useRef, useContext} from 'react';
import ReservationCard from "./ReservationCard";
import {useDrag, useDrop} from "react-dnd";
import {AppContext} from "../context/appContext";

const type = "TripCard";

const DraggableReservationCard = ({
  handleReservationCardClick = () => {},
  tripOrder = undefined,
  reservation = undefined,
  trip,
  index,
  moveCard = () => {},
  isDispatcher = false,
}) => {
  //#region Get tripOrder, trip, reservation data from context
  const {currentReservation} = useContext(AppContext);
  //#endregion

  //#region Create ref for current card
  const ref = useRef(null);
  //#endregion

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag(() => ({
    type: type,
    item: {id: currentReservation?.id, index},
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  drag(drop(ref));

  return (
    <React.Fragment>
      <div
        className={isDragging ? 'DraggableReservationCard ' +
          'DraggableReservationCard_Dragging' : 'DraggableReservationCard'}
        ref={ref}
      >
        <ReservationCard
          tripOrder={tripOrder}
          reservation={reservation}
          trip={trip}
          isPassengerInTrip={true}
          isDispatcher={isDispatcher}
          handleReservationCardClick={handleReservationCardClick}
          isActiveTrip={true}
        />
      </div>
    </React.Fragment>
  );
};

export default DraggableReservationCard;
