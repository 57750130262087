import {endPointsInterface, placesTypes, serverUrl} from "./models";
import axios from "axios";
import {DEFAULT_LANG} from "./data";

export async function getLocationIqPlaceDetails(lat, lon, lang, type) {
  const url = endPointsInterface.getPlace;
  const search = `?lat=${lat}&lon=${lon}&lang=${lang}&type=${type}`

  try {
    const result = await axios.get(`${serverUrl}${url}${search}`);

    return result?.data || null;
  } catch (error) {
    return null;
  }
}

export function getTranslatedLocalityName(locality, lang) {
  if (!locality) {
    return '';
  }

  return locality[lang] || locality.default;
}

export async function getTripPointWithTranslations(tripPoint, lang, languages) {
  const updatedTripPoint = JSON.parse(JSON.stringify(tripPoint));

  // if (lang === DEFAULT_LANG) {
  //   updatedTripPoint.city.default = tripPoint?.city[lang] || '';
  //   updatedTripPoint.country.default = tripPoint?.country[lang] || '';
  //   updatedTripPoint.fullAddress.default = tripPoint?.fullAddress[lang] || '';
  // }
  //
  // const filteredLanguages = languages.filter(language => language !== lang);

  for (let language of languages) {
    const translatedPlace = await getLocationIqPlaceDetails(tripPoint.lat,
      tripPoint.lon, language, placesTypes.city);

    updatedTripPoint.city[language] = translatedPlace?.city || '';
    updatedTripPoint.country[language] = translatedPlace?.country || '';
    updatedTripPoint.fullAddress[language] = translatedPlace?.fullAddress || '';

    if (language === DEFAULT_LANG) {
      updatedTripPoint.city.default = translatedPlace?.city || tripPoint?.city
        ?.default || '';
      updatedTripPoint.country.default = translatedPlace?.country || tripPoint
        ?.country.default || '';
      updatedTripPoint.fullAddress.default = translatedPlace?.fullAddress ||
        tripPoint?.fullAddress?.default || '';
    }

    if (!updatedTripPoint.city[language]) {
      const translatedPlaceAsBuilding = await getLocationIqPlaceDetails(
        tripPoint.lat, tripPoint.lon, language, placesTypes.building);

      updatedTripPoint.city[language] = translatedPlaceAsBuilding?.city || '';

      if (language === DEFAULT_LANG) {
        updatedTripPoint.city.default = translatedPlaceAsBuilding?.city
          || tripPoint?.city?.default || '';
      }
    }
  }

  return updatedTripPoint;
}

export async function getTranslatedBoardingAddress(address, lang, languages) {

}

export async function getTripPointsWithTranslation(tripPoints, lang, languages) {
  const updatedTripPoints = [];

  for (let point of tripPoints) {
    const updatedPoint = await getTripPointWithTranslations(point, lang, languages);

    updatedTripPoints.push(updatedPoint);
  }

  return updatedTripPoints;
}

export function getPointForDbFromLocationIqPlace(place, lang) {
  return {
    ...place,
    city: {
      [lang]: place.city,
      default: place.city,
    },
    country: {
      [lang]: place.country,
      default: place.country,
    },
    fullAddress: {
      [lang]: place.fullAddress,
      default: place.fullAddress,
    }
  }
}

export function getTranslatedFullAddress(fullAddress, lang) {
  if (!fullAddress) {
    return '';
  }

  return fullAddress[lang] || fullAddress.default;
}

export function getBoardingAddressesTranslation(boardingPlacesFromDriver, lang) {
  const boardingPlaces = [];

  try {
    for (const place of boardingPlacesFromDriver) {
      const currentPlace = getLocationIqPlaceDetails(place.lat, place.lon,
        lang, placesTypes.building);

      boardingPlaces.push({
        placeId: currentPlace?.placeId || place.placeId,
        fullAddress: currentPlace?.fullAddress || place.fullAddress,
      });
    }
  } catch (error) {
    return error;
  } finally {
    return boardingPlaces;
  }
}

export function getCoordinatesStringFromWaypoint(point) {
  return `${point.location.lat}, ${point.location.lng}`
}
