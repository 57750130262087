import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AppProvider} from "./context/appContext";
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
