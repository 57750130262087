import React, {useState, useContext, useEffect} from 'react';
import {Nav, Tab} from "react-bootstrap";
import {
  sortCardsByDateOfCreation,
  updateLastCheckOfReservesDateForTripOrder
} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import ReservationCard from "./ReservationCard";
import {useNavigate} from "react-router-dom";
import {
  collectionsInterface,
  entitiesNumberInterface,
  pagesInterface,
  subpagesInterface
} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";
import '../styles/ReservedTripOrderDetails.scss';
import Loader from "./Loader";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";

const ReservedTripOrderDetails = ({
  addClasses = '',
  activeTabKey = 'myReservations',
  handleReservationCardClick = () => {},
  setActiveTabKey = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screen title function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Брони для пассажира');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Get actual trip order and set to state
  const getTripOrderAndSet = async () => {
    setIsLoading(true);

    try {
      const actualTripOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, currentTripOrder.idPost);

      setCurrentTripOrder(actualTripOrder);
      updateDataChunks(actualTripOrder);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Redirect if no data, update otherwise
  useEffect(() => {
    if (!currentTripOrder?.idPost) {
      navigate(`/${pagesInterface.passengers}/${subpagesInterface.reservedTrips}`);
    } else {
      getTripOrderAndSet().then();
    }
  }, []);
  //#endregion

  //#region Update data about new reservations
  const updateDataAboutNewReservations = async (tripOrder) => {
    try {
      await updateLastCheckOfReservesDateForTripOrder(tripOrder);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reservationsFromDriversChunk, setReservationsFromDriversChunk]
    = useState([]);
  const [reservationsFromDispatchersChunk, setReservationsFromDispatchersChunk]
    = useState([]);


  const updateDataChunks = (currentTripOrder) => {
    if (activeTabKey === 'myReservations') {
      setReservationsFromDispatchersChunk(sortCardsByDateOfCreation(currentTripOrder
        ?.reservationsFromDispatchers, false)
        ?.filter(reservation => !reservation.isTotallyAccepted)
        ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
          currentPage * entitiesNumberInterface.reservationCardsReduced));
    } else {
      setReservationsFromDriversChunk(sortCardsByDateOfCreation(currentTripOrder
        ?.reservationsFromDrivers)?.filter(reservation => !reservation.isTotallyAccepted)
        ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
          currentPage * entitiesNumberInterface.reservationCardsReduced));
    }
  }

  // useEffect(() => {
  //   setReservationsFromDispatchersChunk(sortCardsByDateOfCreation(currentTripOrder
  //     ?.reservationsFromDispatchers, false)
  //     ?.filter(reservation => !reservation.isTotallyAccepted)
  //     ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
  //       currentPage * entitiesNumberInterface.reservationCardsReduced));
  // }, [currentTripOrder?.reservationsFromDispatchers?.length, currentPage]);
  //
  // useEffect(() => {
  //   setReservationsFromDriversChunk(sortCardsByDateOfCreation(currentTripOrder
  //     ?.reservationsFromDrivers)?.filter(reservation => !reservation.isTotallyAccepted)
  //     ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
  //       currentPage * entitiesNumberInterface.reservationCardsReduced));
  // }, [currentTripOrder?.reservationsFromDrivers?.length, currentPage]);

  useEffect(() => {
    updateDataChunks(currentTripOrder);
  },[activeTabKey, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader /> :
        <div className={`ReservedTripOrderDetails ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <Tab.Container
            activeKey={activeTabKey}
          >
            <Nav variant="pills" className={'flex-row TabControls'}>
              <Nav.Item>
                <Nav.Link
                  eventKey='myReservations'
                  onClick={() => {
                    setActiveTabKey('myReservations');
                    setStartPage(1);
                    setCurrentPage(1);
                  }}

                >
                  {translate('Мои брони')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='reservationsFromDrivers'
                  onClick={() => {
                    setActiveTabKey('reservationsFromDrivers');
                    setStartPage(1);
                    setCurrentPage(1);
                  }}
                >
                <span
                  onClick={() => updateDataAboutNewReservations(
                    currentTripOrder)}
                >
                  {translate('Брони от водителей')}
                </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey='myReservations'>
                <div className={'PaddingBlock_Half'}></div>

                <div className={'DataListWithPagination'}>
                  <div className={'ListContainer'}>
                    <div className={'CardsList'}>
                      {reservationsFromDispatchersChunk
                        ?.map((reservation, index) => (
                          <React.Fragment key={reservation.id || index}>
                            <ReservationCard
                              reservation={reservation}
                              tripOrder={currentTripOrder}
                              trip={reservation.originalTrip}
                              isDispatcher={true}
                              handleReservationCardClick={
                                handleReservationCardClick
                              }
                            />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>

                  {currentTripOrder?.reservationsFromDispatchers
                      ?.filter(reservation => !reservation.isTotallyAccepted)
                      ?.length > entitiesNumberInterface.reservationCards &&
                    <PaginationBlock
                      portionSize={entitiesNumberInterface.reservationCards}
                      listLength={
                        currentTripOrder?.reservationsFromDispatchers?.length || 0
                      }
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      startPage={startPage}
                      setStartPage={setStartPage}
                    />
                  }
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey='reservationsFromDrivers'>
                <div className={'PaddingBlock_Half'}></div>

                <div className={'DataListWithPagination'}>
                  <div className={'ListContainer'}>
                    <div className={'CardsList'}>
                      {reservationsFromDriversChunk?.map((reservation, index) => (
                        <React.Fragment key={reservation.id || index}>
                          <ReservationCard
                            reservation={reservation}
                            tripOrder={currentTripOrder}
                            trip={reservation.originalTrip}
                            isDispatcher={true}
                            handleReservationCardClick={handleReservationCardClick}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>

                  {currentTripOrder?.reservationsFromDrivers
                      ?.filter(reservation => !reservation.isTotallyAccepted)
                      ?.length > entitiesNumberInterface.reservationCards &&
                    <PaginationBlock
                      portionSize={entitiesNumberInterface.reservationCards}
                      listLength={
                        currentTripOrder?.reservationsFromDrivers?.length || 0
                      }
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      startPage={startPage}
                      setStartPage={setStartPage}
                    />
                  }
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      }
    </>
  );
  //#endregion
};

export default ReservedTripOrderDetails;
