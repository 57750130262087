import React, {useEffect, useState} from 'react';
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface} from "../utils/models";
import DisplayCounterPartyData from "./DisplayCounterPartyData";
import PaymentStatusBadge from "./PaymentStatusBadge";
import useTranslation from "../hooks/useTranslation";
import '../styles/CounterPartyCard.scss';

const CounterPartyCard = ({
    addClasses='',
    counterparty,
    isCreditor = true,
    handleCardClick = () => {},
    isArchive = false,
    paymentConfirmationClick = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Actual counterparty data
  const [actualCounterparty, setActualCounterParty] = useState(counterparty);
  //#endregion

  useEffect(() => {
    if (isArchive) {
    getDocInCollection(collectionsInterface.users, isCreditor ?
      counterparty.recipient.idPost : counterparty.payer.idPost)
      .then(userFromDb => setActualCounterParty({
        ...counterparty,
        ...userFromDb,
        }));
    } else {
      getDocInCollection(collectionsInterface.users, counterparty.idPost)
        .then(userFromDb => setActualCounterParty({
          ...userFromDb,
          amount: counterparty.amount,
        }));
    }
  }, [counterparty]);

  //#region Render
  return (
    <>
      <div
        className={isArchive
          ? `CounterPartyCard CounterPartyCard_Archived ${addClasses}`
          : `CounterPartyCard ${addClasses}`}
        onClick={() => isArchive ? () => {} : handleCardClick(actualCounterparty,
          isCreditor)}
      >
        {isArchive &&
          <div className={'CounterPartyCard-PaymentStatusBadgeContainer'}>
            <PaymentStatusBadge
              addClasses={counterparty?.isFeePaid ?
                'CounterPartyCard-PaymentStatusBadge PaymentStatusBadge-Paid'
                :
                'CounterPartyCard-PaymentStatusBadge PaymentStatusBadge-Awaiting'}
              statusText={counterparty?.isFeePaid ? 'Оплачено'
                : 'Ожидает оплаты'}
            />
          </div>
        }

        <div className={'CounterPartyCard-Item FlexBlock ColoredText'}>
          <span>
            {!isCreditor ? translate('Водитель')
            : translate('Диспетчер')}
          </span>

          <span>
            {`${actualCounterparty.amount} ${translate('грн')}`}
          </span>
        </div>

        <DisplayCounterPartyData
          counterparty={actualCounterparty}
          isCreditor={isCreditor}
        />

        {((isArchive && !isCreditor) && !counterparty.isFeePaid) &&
          <button
            type={'button'}
            className={'RegularButton'}
            onClick={() => paymentConfirmationClick(counterparty)}
          >
            {translate('Подтвердить получение')}
          </button>
        }
      </div>
    </>
  );
  //#endregion
};

export default CounterPartyCard;
