import React, {useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Route, Routes} from "react-router-dom";
import ReservationDetails from "./ReservationDetails";
import ReservedTripDetails from "./ReservedTripDetails";
import DisplayCreatedTrips from "./DisplayCreatedTrips";
import {subpagesInterface} from "../utils/models";
import useTripsList from "../hooks/useTripsList";
import {queryArraysService} from "../utils/queryArraysService";

const TripsWithReserves = ({
  handleShowCreateTrip = () => {},
  handleShowTripDetails = () => {},
  handleReservationCardClick = () => {},
  addClasses='',
}) => {
  //#region Get user from context
  const { user } = useContext(AppContext);
  //#endregion

  //#region Get trip data from context
  const {
    setCurrentTrip,
  } = useContext(AppContext);
  //#endregion

  //#region Trips data and loader
  const {isLoading, tripsList, setTripsList} = useTripsList(queryArraysService
    .reservedTrips(user.idPost), user.idPost)
  //#endregion

  //#region Manage displaying tabs
  const [activeTabKey, setActiveTabKey] = useState('myReservations');
  //#endregion

  //#region Update after actions
  const updateAfterReservationChange = (trip) => {
    setTripsList(trips => trips
      .map(curTrip => curTrip.idPost === trip.idPost ? trip : curTrip));
  }
  //#endregion

  //#region Update indicator new after unmount
  // const updateTripAfterReservationDetailsUnmoumnt = async (tripId, tripOrderId,
  //   reservationId) => {
  //   const actualTrip = await getDocInCollection(collectionsInterface.trips, tripId);
  //
  //   setCurrentTrip(actualTrip);
  // }
  //#endregion

  //#region Render
  return (
    <div className={`ReservedTripsForDrivers ${addClasses}`}>
      <Routes>
        <Route
          element={
            <DisplayCreatedTrips
              trips={tripsList}
              handleShowCreateTrip={user.attachedToCompany ? () => {}
                : handleShowCreateTrip}
              handleShowTripDetailsScreen={user.attachedToCompany ? () => {}
                : handleShowTripDetails}
              isReadMode={true}
              addClasses={'App-Screen'}
              isFromReservedTrips={true}
              isNotepad={false}
              isLoading={isLoading}
            />
          }
          index
        />

        <Route
          path={`/${subpagesInterface.reservedTripDetails}`}
          element={
            <ReservedTripDetails
              handleReservationCardClick={handleReservationCardClick}
              setActiveTabKey={setActiveTabKey}
              activeTabKey={activeTabKey}
            />
          }
        />

        {!user?.attachedToCompany &&
          <Route
            path={`/${subpagesInterface.reservationDetails}`}
            element={
              <ReservationDetails
                isDispatcher={false}
                updateAfterReservationSubmit={updateAfterReservationChange}
                updateAfterReservationReject={updateAfterReservationChange}
                // updateEntityOnUnmount={updateTripAfterReservationDetailsUnmoumnt}
                addClasses={'App-Screen_Narrow'}
              />
            }
          />
        }
      </Routes>
    </div>
  );
  //#endregion
};

export default TripsWithReserves;
