import React, {useContext} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {AppContext} from "../context/appContext";
import {checkAreThereNewNotificationsInList} from "../utils/helpers";
import {auth, logOut} from "../utils/firebaseConfigAndFunctions";
import {Dropdown} from "react-bootstrap";
import {rolesInterface} from "../utils/models";
import AdminNavMenu from "./AdminNavMenu";
import { LuBell, LuBellDot } from 'react-icons/lu';
import '../styles/Header.scss';
import useTranslation from "../hooks/useTranslation";

const Header = ({addClasses = '', notifications = []}) => {
  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get dictionaries from context
  const {
    setLang,
    languages,
    dictionary,
    dictionaries,
    setDictionary,
  } = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle} = useContext(AppContext);
  //#endregion

  //#region Get screen data and functions from context
  const {previousScreen} = useContext(AppContext);
  //#endregion

  //#region Function for handling back arrow click
  const handleBackButtonClick = () => {
    if (previousScreen) {
      navigate(previousScreen);
    } else {
      navigate(-1);
    }
  }
  //#endregion

  //#region Handle language change
  const handleLanguageChange = (newLang) => {
    const newDictionary = dictionaries.find(dict => dict.name === newLang);

    setLang(newLang);
    setDictionary(newDictionary);
  };
  //#endregion

  //#region Render
  return (
    <>
      <header
        className={auth?.currentUser ? `Header Header_Dark ${addClasses}`
          : `Header ${addClasses}`}
      >
        <div
          className={'BackArrow Header-DesktopBackButton'}
          onClick={() => {
            handleBackButtonClick();
          }}
        >
        </div>

        <div className={'Header-Content'}>
          <div className={'Header-Left'}>
            <img
              src={'/logo.svg'}
              className={'Logo Header-Logo'}
              alt={'Logo thumb up'}
              onClick={() => {
                navigate('/')
              }}
            />

            {user?.role === rolesInterface.admin ?
              <AdminNavMenu isFooter={false} addClasses={'Header-Nav'}/>
              :
              <>
                {auth.currentUser &&
                  <nav className={'Header-Nav NavMenu'}>
                    {!user.attachedToCompany &&
                      <NavLink
                        to={'notepad'}
                        className={({isActive}) =>
                          isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
                        }
                      >
                        {translate('Блокнот')}
                      </NavLink>
                    }

                    <NavLink
                      to={'trips'}
                      className={({isActive}) =>
                        isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
                      }
                    >
                      {translate('Поездки')}
                    </NavLink>

                    {!user.attachedToCompany &&
                      <NavLink
                        to={'passengers'}
                        className={({isActive}) =>
                          isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
                        }
                      >
                        {translate('Пассажиры')}
                      </NavLink>
                    }

                    {!user.attachedToCompany &&
                      <NavLink
                        to={'exchange'}
                        className={({isActive}) =>
                          isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
                        }
                      >
                        {translate('Обмен')}
                      </NavLink>
                    }

                    <NavLink
                      to={'profile'}
                      className={({isActive}) =>
                        isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
                      }
                    >
                      {translate('Профиль')}
                    </NavLink>
                  </nav>
                }

              </>
            }

            <div className={'Header-Title-Container'}>
              <div
                className={'BackArrow Header-BackButton'}
                onClick={() => {
                  handleBackButtonClick();
                }}
              >
              </div>
              <div className={'Header-Title'}>
                {translate(screenTitle)}
              </div>
            </div>
          </div>

          <div className={'Header-Right'}>
            <Dropdown>
              <Dropdown.Toggle
                className={'RegularButton Header-Lang'}
                id="dropdown-basic"
              >
                {dictionary.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {languages.map((language, lIndex) => (
                  <React.Fragment key={lIndex}>
                    <Dropdown.Item
                      onClick={() => handleLanguageChange(language.name)}
                    >
                      <span>
                        {language.description}
                      </span>
                    </Dropdown.Item>
                  </React.Fragment>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {auth.currentUser &&
              <button
                type={'button'}
                className={'RegularButton Header-Auth'}
                onClick={() => logOut(setUser).then(() => navigate('/login'))}
              >
                {translate('Выйти')}
              </button>
            }
          </div>
        </div>

        {auth?.currentUser &&
          <>
              <span
                className={checkAreThereNewNotificationsInList(notifications) ?
                  'NotificationsLink NotificationsLink_HasNew' +
                  ' Header-NewIndicator' : 'NotificationsLink Header-NewIndicator'}
                onClick={() => navigate('profile/notifications')}
              >
                {checkAreThereNewNotificationsInList(notifications)
                  ? <LuBellDot />
                  : <LuBell />
                }
              </span>
          </>
        }
      </header>
    </>
  );
  //#endregion
};

export default Header;
