import React from 'react';
import {BsFillPersonFill} from "react-icons/bs";
import {FaSuitcase} from "react-icons/fa";
import useTranslation from "../hooks/useTranslation";

const PlacesInTripDisplaying = ({addClasses='', scheme}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={`PlacesInTripEditing ${addClasses}`}>
      <div className={'VehicleBody PlacesInTripEditing-VehicleBody'}>
        {scheme?.rows?.map((row, index) => (
          <React.Fragment key={index}>
            <div className={'VehicleRow'}>
              {row?.row?.map((cell, cIndex) => (
                <React.Fragment key={cIndex}>
                  <div className={'PlacesInTripEditing-CellContainer'}>
                    <div
                      className={
                        cell.isFreeSpace ? ('VehicleCell'
                        ) : (cell.isOccupied ?
                          'VehicleCell VehicleCell-Place ' +
                            'PlacesInTripEditing-Place ' +
                            'PlacesInTripEditing-Place_Occupied'
                            :
                          'VehicleCell VehicleCell-Place ' +
                            'PlacesInTripEditing-Place'
                        )}
                      title={cell.description}
                    >
                      {!cell.isFreeSpace &&
                        <span className={'VehicleCell-PlaceIcon'}>
                          {cell.isPassengerPlace ?
                            <BsFillPersonFill
                              title={translate('Пассажирское')}
                            />
                            :
                            <FaSuitcase
                                title={translate('Грузовое')}
                            />
                          }
                        </span>}

                        {cell.placeNumber !== 0 &&
                          <span className={'VehicleCell-PlaceNumber'}>
                            {cell.placeNumber}
                          </span>
                        }
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
  //#endregion
};

export default PlacesInTripDisplaying;
