import React, {useContext, useEffect, useState} from 'react';
import {Nav, Tab} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import {
  getCollectionWhereKeysValues
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  entitiesNumberInterface,
  pagesInterface
} from "../utils/models";
import {
  sortCardsByDateOfCreation
} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import DisplayCreatedTripsOrders from "./DisplayCreatedTripsOrders";
import {useNavigate} from "react-router-dom";

const MyPassengers = ({
  handleShowCreateTrip = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get tripOrder data from context
  const {
    setCurrentTripOrder,
  } = useContext(AppContext);
  //#endregion

  //#region Loaders
  const [isActualLoading, setIsActualLoading] = useState(false);
  const [isArchivedLoading, setIsArchivedLoading] = useState(false);
  //#endregion

  //#region Tabs keys
  const [activeTabKey, setActiveTabKey] = useState('actual');
  //#endregion

  //#region Trips orders lists
  const [actualTripsOrders, setActualTripsOrders] = useState([]);
  const [archivedTripsOrders, setArchivedTripsOrders] = useState([]);
  //#endregion

  //#region Get actual trips orders
  const getActualOrdersAndSet = async () => {
    setIsActualLoading(true);

    try {
      const orders = await getCollectionWhereKeysValues(collectionsInterface
        .tripsOrders, [
        {
          key: 'creatorIdPost',
          value: user.idPost,
        },
        {
          key: 'isArchived',
          value: false,
        },
        {
          key: 'isDraft',
          value: false,
        },
        {
          key: 'isOpenForSearch',
          value: true,
        },
        {
          key: 'isActive',
          value: false,
        },
      ], 5);

      setActualTripsOrders(sortCardsByDateOfCreation(orders, false));
    } catch (error) {

    } finally {
      setIsActualLoading(false);
    }
  }
  //#endregion

  //#region Get archived trips orders
  const getArchivedOrdersAndSet = async () => {
    setIsArchivedLoading(true);

    try {
      const orders = await getCollectionWhereKeysValues(collectionsInterface
        .tripsOrders, [
        {
          key: 'creatorIdPost',
          value: user.idPost,
        },
        {
          key: 'isArchived',
          value: true,
        },
        {
          key: 'isDraft',
          value: false,
        },
        {
          key: 'isCancelled',
          value: true,
        },
        {
          key: 'acceptedDeal',
          value: null,
        },
      ], 5);

      setArchivedTripsOrders(sortCardsByDateOfCreation(orders, false));
    } catch (error) {

    } finally {
      setIsArchivedLoading(false);
    }
  }

  //#endregion

  //#region Set data
  useEffect(() => {
    getActualOrdersAndSet().then();
    getArchivedOrdersAndSet().then();
  }, []);
  //#endregion

  //#region Handle trip order card click
  const handleTripOrderCardClick = (tripOrder) => {
    setCurrentTripOrder(tripOrder);
    navigate(`/${pagesInterface.passengers}/displayTripOrderDetails`);
  }
  //#endregion

  //#region Render
  return (
    <div>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <Tab.Container
        activeKey={activeTabKey}
      >
        <Nav variant="pills" className={'flex-row TabControls'}>
          <Nav.Item>
            <Nav.Link
              eventKey='actual'
              onClick={() => setActiveTabKey('actual')}
            >
              {translate('Актуальные')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='archived'
              onClick={() => setActiveTabKey('archived')}
            >
              {translate('Архивные')}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey='actual'>
            <DisplayCreatedTripsOrders
              isNotepad={false}
              tripsOrdersToDisplay={actualTripsOrders}
              handleShowCreateTrip={handleShowCreateTrip}
              handleTripOrderCardClick={handleTripOrderCardClick}
              isDispatcher={true}
              isInMyPassengersList={true}
              addClasses={'Passengers-DisplayTripsOrders App-Screen'}
              isLoading={isActualLoading}
              cardsNumber={entitiesNumberInterface.tripsOrdersCardsMiddle}
            />
          </Tab.Pane>

          <Tab.Pane eventKey='archived'>
            <DisplayCreatedTripsOrders
              isNotepad={false}
              tripsOrdersToDisplay={archivedTripsOrders}
              handleShowCreateTrip={handleShowCreateTrip}
              handleTripOrderCardClick={handleTripOrderCardClick}
              isDispatcher={true}
              isInMyPassengersList={true}
              addClasses={'Passengers-DisplayTripsOrders App-Screen'}
              isLoading={isArchivedLoading}
              cardsNumber={entitiesNumberInterface.tripsOrdersCardsMiddle}
              isReadOnly={true}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
  //#endregion
};

export default MyPassengers;
