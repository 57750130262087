import React from 'react';
import {Route, Routes} from "react-router-dom";
import ShowProfileDataScreen from "./ShowProfileDataScreen";
import AddVehicle from "./AddVehicle";
import '../styles/ShowProfileData.scss';

const ShowProfileData = ({
    addClasses='',
    setIsEditMode = () => {},
}) => {
  //#region Render
  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ShowProfileDataScreen
              addClasses={addClasses}
              setIsEditMode={setIsEditMode}
            />
          }
        />

        <Route
          path={'/addVehicle'}
          element={
            <AddVehicle
              isRegistration={false}
              addClasses={'ShowProfileData-AddVehicle'}
              goBack={false}
            />
          }
        />
      </Routes>
    </>
  );
  //#endregion
};

export default ShowProfileData;
