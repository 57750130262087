export const getDismissedDrivers = (oldDrivers, newDrivers) => {
  const dismissedDrivers = oldDrivers.filter(oDriver => !newDrivers
    .some(nDriver => nDriver.idPost === oDriver.idPost));

  return dismissedDrivers;
}

export const getAddedDrivers = (oldDrivers, newDrivers) => {
  const addedDrivers = newDrivers.filter(nDriver => !oldDrivers
    .some(oDriver => oDriver.idPost === nDriver.idPost));

  return addedDrivers;
}

export const compareDriversForTrip = (changesDecected, oldDrivers, newDrivers) => {
  const dismissed = getDismissedDrivers(oldDrivers, newDrivers);
  const added = getAddedDrivers(oldDrivers, newDrivers);

  if (dismissed.length === 0 && added.length === 0) {
    return {
      wereDriversChanged: false,
    }
  } else {
    return {
      wereDriversChanged: true,
      dismissed,
      added,
    }
  }
}
