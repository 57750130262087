import React, {useState, useEffect, useContext, useRef} from 'react';
import {AppContext} from "../context/appContext";
import {
  collectionsInterface, pagesInterface,
  vehicleModel,
} from "../utils/models";
import {countriesList} from "../utils/data";
import {
  convertSchemeIntoTable,
  createNewUser,
  createYearsArray, getAddVehicleErrorObjectForEmptyFields,
  getAppropriateBodyTypesList,
  getAppropriateBrandsList,
  getAppropriateColorsList,
  getAppropriateCountriesList,
  getAppropriateModelsList,
  getEmptyAddVehicleFields,
  sendMessageAboutReferralRegistration,
  transformStringsArrayToSelectOptions,
  updateUserVehicles
} from "../utils/helpers";
import {
  getCollection,
  getDocInCollection,
  setDocumentToCollection,
  signUp,
  updateFieldInDocumentInCollection, updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {useNavigate} from "react-router-dom";
import RadioFieldSet from "../elements/RadioFieldSet";
import PlacesConstructor from "./PlacesConstructor";
import Select from "react-select";
import {auth} from '../utils/firebaseConfigAndFunctions';
import ModalNotification from "../modals/ModalNotification";
import PlacesSchemeDraw from "./PlacesSchemeDraw";
import Loader from "./Loader";
import '../styles/AddVehicle.scss';
import FormInputAlertText from "./FormInputAlertText";
import {busyInTripsService} from "../utils/busyInTripsService";
import useTranslation from "../hooks/useTranslation";

const AddVehicle = ({
  addClasses = '',
  goBack = () => {},
  isRegistration = false,
  referrerData,
  isAdmin = false,
  vehicle = vehicleModel,
  isEditing = false,
  setCurrentVehicle = () => {},
}) => {
  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {editingUser, setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    isEditing ? setScreenTitle('Редактирование ТС')
      : setScreenTitle('Добавление ТС');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if admin and no data
  useEffect(() => {
    if (isAdmin && !vehicle.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Data arrays
  const [years, setYears] = useState([]);
  const [colors, setColors] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [countries, setCountries] = useState([]);
  //#endregion

  //#region Vehicle data
  const [vehicleData, setVehicleData] = useState(vehicle);
  const [schemeId, setSchemeId] = useState('');
  const [updatedFields, setUpdatedFields] = useState({});

  let placesNumber = [];
  for (let i = 1; i < 61; i++) {
    placesNumber.push(i);
  }
  //#endregion

  //#region Setting data
  //#region Set initial vehicleData
  useEffect(() => {
    if (isEditing && !vehicle.idPost) {
      navigate(`/${pagesInterface.profile}`);
    }

    setVehicleData(data => ({
      ...data,
      permissionType: translate('Лицензия'),
      ownerUid: user.uid,
    }));
  }, []);
  //#endregion

  //#region Set colors, body types, brands from DB
  useEffect(() => {
    getCollection('vehiclesCatalog').then(brandsFromDb => {
      const brands = Object.values(brandsFromDb[0].vehiclesCatalog);
      brands.sort((brand1, brand2) => brand1.defaultName
        .localeCompare(brand2.defaultName));

      setBrands(brands);
    }).catch(() => {});

    getCollection(collectionsInterface.vehiclesColors).then(colorsFromDb => {
      const colors = colorsFromDb[0].vehiclesColors;
      colors.sort((color1, color2) => color1.defaultName
        .localeCompare(color2.defaultName));

      setColors(colors);
    }).catch(() => {});

    getCollection(collectionsInterface.vehiclesBodyTypes).then(bodyTypesFromDb => {
      const bodyTypes = bodyTypesFromDb[0].vehiclesBodyTypes;
      bodyTypes.sort((bodyType1, bodyType2) => bodyType1
        .defaultName.localeCompare(bodyType2.defaultName));

      setBodyTypes(bodyTypes);
    }).catch(() => {});
  }, []);
  //#endregion

  //#region Get years
  useEffect(() => {
    setYears(createYearsArray(1980, Number(new Date().getFullYear())));
  }, []);
  //#endregion

  //#region Get countries list
  useEffect(() => {
    setCountries(getAppropriateCountriesList(lang, countriesList));
  }, [lang]);
  //#endregion

  //#endregion

  //#region Error data
  const [errorData, setErrorData] = useState({});
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Refs
  const registrationCountry = useRef(null);
  const licensePlate = useRef(null);
  const brand = useRef(null);
  const model = useRef(null);
  const bodyType = useRef(null);
  const color = useRef(null);
  //#endregion

  //#region Handle editing and saving vehicle data
  const getCarModels = (currentBrand) => {
    const modelsList = currentBrand?.models || [];

    setModels(modelsList);
  }

  const controlChangeHandler = (newValue, inputName) => {
    let newData = newValue;

    if (inputName === 'brand') {
      newData = {...newValue};

      delete newData.models;

      getCarModels(newValue);
    }

    setVehicleData(data => ({
      ...data,
      [inputName]: newData,
    }));

    if (isEditing) {
      setUpdatedFields(fields => ({
        ...fields,
        [inputName]: newData,
      }));
    }
  }

  const handleSchemeToggle = (newValue) => {
    setVehicleData(data => ({
      ...data,
      isFreeSeating: newValue,
    }));

    if (isEditing) {
      setUpdatedFields(fields => ({
        ...fields,
        isFreeSeating: newValue,
        placesScheme: newValue ? '' : vehicleData.placesScheme,
        placesSchemeId: newValue ? '' : vehicleData.placesSchemeId,
      }));
    }
  }

  const vehicleRegistrationSubmit = async (event, user) => {
    event.preventDefault();
    setIsLoading(true);

    let dataToSave = {};

    if (isAdmin || isEditing) {
      const isVehicleInAnyTrip = await busyInTripsService
        .checkIsThereTripWithVehicle(vehicle.idPost, user.idPost);

      if (isVehicleInAnyTrip) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage("Нельзя редактировать транспортное средство," +
          " занятое в поездке");

        setIsLoading(false);
        return;
      }

      dataToSave = {...updatedFields, placesSchemeId: schemeId};
    } else {
      dataToSave = {
        ...vehicleData,
        placesSchemeId: schemeId,
        ownerUid: user.uid
      };
    }

    const emptyFields = getEmptyAddVehicleFields(dataToSave);

    let errorObject = {};

    if (emptyFields.length > 0) {
      errorObject = getAddVehicleErrorObjectForEmptyFields(emptyFields);

      switch (true) {
        case emptyFields.includes('registrationCountry'):
          // registrationCountry?.current?.scrollIntoView({ behavior: 'smooth' });
          window.scrollTo({top: 0, behavior: 'smooth'});
          break;
        case emptyFields.includes('licensePlate'):
          licensePlate?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case emptyFields.includes('brand'):
          brand?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case emptyFields.includes('model'):
          model?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case emptyFields.includes('bodyType'):
          bodyType?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case emptyFields.includes('color'):
          color?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
      }

      setErrorData(errorObject);

      setIsLoading(false);

      return;
    }

    try {
      if (!vehicleData.isFreeSeating) {
        if (!schemeId) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage(
            'Создайте и сохраните схему или выберите свободную рассадку');

          return;
        }
      }

      if (isRegistration) {
        const vehicleDoc = await setDocumentToCollection(
          collectionsInterface.vehicles, dataToSave);

        const vehicleId = vehicleDoc.result.id;

        const authResult = await signUp(user.email, user.password);

        await updateFieldInDocumentInCollection(
          collectionsInterface.vehicles, vehicleId, 'ownerUid',
          authResult.user.uid);

        const currentDate = new Date();

        const userToSave = {
          ...user,
          vehicles: [{
            ...dataToSave,
            idPost: vehicleId,
          }],
          uid: authResult.user.uid,
          tripOrdersLastObserveDate: currentDate,
          tripsLastObserveDate: currentDate,
        };

        delete userToSave.password;
        delete userToSave.confirmPassword;

        if (referrerData?.referrerId) {
          userToSave.referrerId = referrerData.referrerId;

          await sendMessageAboutReferralRegistration(referrerData.referrerId, {
            fullName: userToSave.fullName,
          });
          // await sendMessageAboutBonusReceived(referrerData.referrerId, user, amount);
        }

        await createNewUser(userToSave);

        navigate('/profile');
      } else if (isEditing) {
        await updateFieldsInDocumentInCollection(collectionsInterface.vehicles,
          vehicleData.idPost, dataToSave);

        const updatedVehicles = user.vehicles.map(vehicle => vehicle
          .idPost === vehicleData.idPost ? {
          ...vehicle,
          ...dataToSave,
        } : vehicle);

        await updateFieldInDocumentInCollection(collectionsInterface.users,
          user.idPost, 'vehicles', updatedVehicles);

        if (isAdmin) {
          setEditingUser(data => ({
            ...data,
            vehicles: updatedVehicles,
          }));
        } else {
          setUser(data => ({
            ...data,
            vehicles: updatedVehicles,
          }));

          setCurrentVehicle(vehicleData);
        }

        navigate(-1);
      } else {
        const vehicleDoc = await setDocumentToCollection(
          collectionsInterface.vehicles, dataToSave);

        const vehicleId = vehicleDoc.result.id;

        await updateUserVehicles(collectionsInterface.users, 'uid',
          user.uid, {
            ...dataToSave,
            idPost: vehicleId,
          });

        const updatedUser = await getDocInCollection(
          collectionsInterface.users, user.idPost);

        setUser(updatedUser);

        navigate('/profile/myVehicles');
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }

  const userRegistrationSubmitWithoutVehicle = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const authResult = await signUp(user.email, user.password);

      const currentDate = Date.now();

      const userToSave = {
        ...user,
        uid: authResult.user.uid,
        tripOrdersLastObserveDate: currentDate,
        tripsLastObserveDate: currentDate,
      };

      delete userToSave.password;
      delete userToSave.confirmPassword;

      if (referrerData?.referrerId) {
        userToSave.referrerId = referrerData.referrerId;

        await sendMessageAboutReferralRegistration(referrerData.referrerId, {
          fullName: userToSave.fullName,
        });
        // await sendMessageAboutBonusReceived(referrerData.referrerId, user, amount);
      }

      await createNewUser(userToSave, () => {
        navigate('/profile')
      });
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <main className={`AddVehicle ${addClasses}`}>
          <form
            className={'AddVehicle-AddForm AddVehicleForm'}
            onSubmit={
              (event) => vehicleRegistrationSubmit(
                event, isAdmin ? editingUser : user)}
          >
            <div ref={registrationCountry}></div>

            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>

            <div className={'AddVehicleForm-Item'} ref={licensePlate}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Страна')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.registrationCountry?.isError && !vehicleData
                      ?.registrationCountry) ? 'FormControl FormControl_Error' +
                      ' AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={countries}
                placeholder={translate('Страна')}
                onChange={(event) => controlChangeHandler(event.value,
                  'registrationCountry')}
                value={{
                  label: vehicleData?.registrationCountry[lang],
                  value: vehicleData?.registrationCountry,
                }}
              />

              {(errorData?.registrationCountry?.isError &&
                !vehicleData?.registrationCountry) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'} ref={brand}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Номер авто')}
              </label>

              <input
                name={'LicensePlate'}
                type={'text'}
                className={(errorData?.licensePlate?.isError &&
                  !vehicleData?.licensePlate) ? 'FormControl' +
                  ' FormControl_Error AddVehicleForm-Input' : 'FormControl' +
                  ' AddVehicleForm-Input'}
                onChange={(event) => controlChangeHandler(event.target.value,
                  'licensePlate')}
                placeholder={translate('Номер авто')}
                value={vehicleData?.licensePlate}
              />

              {(errorData?.licensePlate?.isError && !vehicleData?.licensePlate) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'}  ref={model}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Марка авто')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.brand?.isError && !vehicleData?.brand)
                      ? 'FormControl FormControl_Error' +
                      ' AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={getAppropriateBrandsList(lang, brands)}
                placeholder={translate('Марка авто')}
                onChange={(event) => controlChangeHandler(event.value,
                  'brand')}
                value={{
                  value: vehicleData?.brand,
                  label: vehicleData?.brand[lang] || vehicleData?.brand?.defaultName
                }}
              />

              {(errorData?.brand?.isError && !vehicleData?.brand) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'}  ref={bodyType}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Модель авто')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.model?.isError && !vehicleData?.model)
                      ? 'FormControl FormControl_Error' +
                      ' AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={getAppropriateModelsList(lang, models)}
                placeholder={translate('Модель авто')}
                onChange={(event) => controlChangeHandler(event.value,
                  'model')}
                value={{
                  value: vehicleData?.model,
                  label: vehicleData?.model[lang] || vehicleData?.model?.defaultName
                }}
              />

              {(errorData?.model?.isError && !vehicleData?.model) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'} ref={color}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Тип кузова')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.bodyType?.isError && !vehicleData?.bodyType)
                      ? 'FormControl FormControl_Error' +
                      ' AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={getAppropriateBodyTypesList(lang, bodyTypes)}
                placeholder={translate('Тип кузова')}
                onChange={(event) => controlChangeHandler(event.value,
                  'bodyType')}
                value={{
                  value: vehicleData?.bodyType,
                  label: vehicleData?.bodyType[lang] || vehicleData?.bodyType
                    ?.defaultName
                }}
              />

              {(errorData?.bodyType?.isError && !vehicleData?.bodyType) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Цвет транспорта')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.color?.isError && !vehicleData?.color)
                      ? 'FormControl FormControl_Error ' +
                      'AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={getAppropriateColorsList(lang, colors)}
                placeholder={translate('Цвет транспорта')}
                onChange={(event) => controlChangeHandler(event.value, 'color')}
                value={{
                  value: vehicleData?.color,
                  label: vehicleData?.color[lang] || vehicleData?.color?.defaultName
                }}
              />

              {(errorData?.color?.isError && !vehicleData?.color) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Год выпуска транспорта')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.productionDate?.isError && !vehicleData
                      ?.productionDate) ? 'FormControl FormControl_Error' +
                      ' AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={years}
                placeholder={translate('Год выпуска транспорта')}
                onChange={(event) => controlChangeHandler(event.value,
                  'productionDate')}
                value={{
                  value: vehicleData?.productionDate,
                  label: vehicleData?.productionDate,
                }}
              />

              {(errorData?.productionDate?.isError && !vehicleData
                ?.productionDate) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Количество мест')}
              </label>

              <Select
                classNames={{
                  control: () =>
                    (errorData?.placesNumber?.isError && !vehicleData
                      ?.placesNumber) ? 'FormControl FormControl_Error' +
                      ' AddVehicleForm-PlaceSelect' : 'FormControl' +
                      ' AddVehicleForm-PlaceSelect',
                }}
                options={transformStringsArrayToSelectOptions(placesNumber)}
                placeholder={translate('Количество мест')}
                onChange={(event) => controlChangeHandler(event.value,
                  'placesNumber')}
                value={{
                  value: vehicleData?.placesNumber,
                  label: vehicleData?.placesNumber,
                }}
              />

              {(errorData?.placesNumber?.isError && !vehicleData?.placesNumber) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div className={'AddVehicleForm-Item'}>
              <RadioFieldSet
                elementName={'permissionType'}
                elementText={`${translate('Лицензия')}_${translate('Приказ')}`}
                inputChangeHandler={controlChangeHandler}
                checkedInput={vehicleData?.permissionType}
              />
            </div>

            <div className={'AddVehicleForm-Item'}>
              <label
                className={'AddVehicleForm-Label'}
              >
                {translate('Введите номер')}
              </label>

              <input
                className={(errorData?.permissionNumber?.isError && !vehicleData
                  ?.permissionNumber) ? 'FormControl FormControl_Error' +
                  ' AddVehicleForm-Input' : 'FormControl AddVehicleForm-Input'}
                type={'text'}
                placeholder={translate('Введите номер')}
                name={'PermissionNumber'}
                onChange={(event) => controlChangeHandler(event.target.value,
                  'permissionNumber')}
                value={vehicleData?.permissionNumber}
              />

              {(errorData?.placesNumber?.isError && !vehicleData?.placesNumber) &&
                <FormInputAlertText
                  text={'Обязательное поле для заполнения'}
                />
              }
            </div>

            <div
              className={
                'AddVehicleForm-Item CheckboxWithText ' +
                'AddVehicleForm-CheckboxWithText'
              }
            >
              <input
                type={'checkbox'}
                id={'placement'}
                className={'OptionCheckBox CheckboxWithText-CheckBox'}
                checked={vehicleData?.isFreeSeating}
                onChange={(event) => handleSchemeToggle(event.target.checked)}
              />

              <label
                htmlFor={'placement'}
                className={'OptionText AddVehicleForm-FreePlacingText'}
              >
                {translate('Свободная рассадка')}
              </label>
            </div>

            {!vehicleData.isFreeSeating &&
              <>
                {vehicleData?.placesScheme?.rows ?
                  <>
                    <PlacesSchemeDraw
                      schemeToDisplay={
                        convertSchemeIntoTable(vehicleData.placesScheme)
                      }
                      addClasses={'AddVehicle-SchemeDraw'}
                    />
                  </>
                  :
                  <>
                    <div className={'AddVehicleForm-Item'}>
                      <PlacesConstructor
                        addClasses={'AddVehicleForm-PlacesConstructor'}
                        setSchemeId={setSchemeId}
                        addPlacesScheme={controlChangeHandler}
                        placesNumber={vehicleData.placesNumber}
                        setIsLoading={setIsLoading}
                      />
                    </div>
                  </>
                }
              </>
            }

            <div className={'AddVehicleForm-Item'}>
              <button
                className={'RegularButton'}
                type={'submit'}
              >
                {auth.currentUser ?
                  translate('Сохранить')
                  :
                  translate('Сохранить и зарегистрироваться')
                }
              </button>
            </div>

            {!auth.currentUser &&
              <div className={'AddVehicleForm-Item'}>
                <button
                  className={'RegularButton AddVehicleForm-ContrastButton'}
                  type={'button'}
                  onClick={
                    (event) => userRegistrationSubmitWithoutVehicle(event)
                  }
                >
                  {translate('Пропустить и зарегистрироваться')}
                </button>
              </div>
            }

            {(goBack && !isAdmin) &&
              <div className={'AddVehicleForm-Item'}>
                <button
                  className={'RegularButton AddVehicleForm-ContrastButton'}
                  type={'button'}
                  onClick={() => goBack()}
                >
                  {translate('Вернуться')}
                </button>
              </div>
            }
          </form>
        </main>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default AddVehicle;
