import React, {useContext, useEffect, useState} from 'react';
import {findAllSuitableTripsOrdersAndSendMessagesToDispatchers} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import ModalNotification from "../modals/ModalNotification";
import {updateDocumentInCollection} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, tripStatusesInterface} from "../utils/models";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";
import '../styles/ReplaceTripScreen.scss';

const ReplaceTripScreen = ({
  addClasses = '',
  handleGoBack = () => {},
  updateTripsListAfterTripReplacing = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTrip?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region New trip order data
  const [updatedTripData, setUpdatedTripData] = useState(currentTrip);
  //#endregion

  //#region Handle change data
  const updateTrip = (fieldName, newValue) => {
    setUpdatedTripData(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  }
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Перенесение в поездки');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Handle replace trip to trips section
  const replaceTrip = async (tripData, user) => {
    setIsLoading(true);

    if (tripData?.isVisibleForDispatchers) {
      let isPaymentMethodDefined = false;

      switch (true) {
        case tripData.isPaymentOnCard:
        case tripData.isPaymentByCash:
        case tripData.isForChange:
          isPaymentMethodDefined = true;
          break;
      }

      if (!isPaymentMethodDefined) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Необходимо выбрать способ оплаты');

        setIsLoading(false);
        return;
      }

      if (!tripData?.dispatcherFee || tripData?.dispatcherFee <= 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Сумма гонорара должна быть больше нуля');

        setIsLoading(false);
        return;
      }


      if (tripData?.dispatcherFee > tripData?.pricePerPerson / 2) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Гонорар не может быть больше 50% от суммы поездки');

        setIsLoading(false);
        return;
      }
    }

    try {
      const updatedTripData = {
        ...tripData,
        isDraft: false,
        isOpenForSearch: true,
        status: tripStatusesInterface.openToSearch,
      }
      await updateDocumentInCollection(collectionsInterface.trips,
        updatedTripData, tripData.idPost);

      if (tripData.isVisibleForDispatchers) {
        await findAllSuitableTripsOrdersAndSendMessagesToDispatchers(user,
          updatedTripData);
      }
      updateTripsListAfterTripReplacing(updatedTripData);
      handleGoBack();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      {isLoading ? <Loader/>
        :
        <div className={`ReplaceTripScreen ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'ReplaceTripScreen-Item'}>
            <label className={'ReplaceTripScreen-Label'}>
              {translate('Гонорар диспетчера')}
            </label>

            <NumberInputWithValidation
              addClasses={'ReplaceTripScreen-Input'}
              externalChangeHandler={updateTrip}
              fieldName={'dispatcherFee'}
              placeholder={translate('Гонорар диспетчера')}
              value={updatedTripData?.dispatcherFee}
            />
          </div>

          <div className={'ReplaceTripScreen-Item'}>
            <h5 className={'RegularTitle RegularTitle_Small'}>
              {translate('Способ оплаты')}
            </h5>

            <div
              className='FlexBlock FlexBlock_JustifyLeft ReplaceTripScreen-Block'
            >
              <div className={'OptionCheckBox'}>
                <input
                  type={'checkbox'}
                  className={'OptionCheckBox'}
                  checked={updatedTripData?.isPaymentOnCard}
                  onChange={(event) => updateTrip('isPaymentOnCard',
                    event.target.checked)}
                  disabled={updatedTripData?.isPaymentByCash}
                  id={'isPaymentOnCard'}
                />
              </div>

              <label
                className={'OptionText'}
                htmlFor={'isPaymentOnCard'}
              >
                {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
              </label>

              <div className={'ReplaceTripScreen-IconContainer'}>
                {updatedTripData?.isPaymentOnCard &&
                  <img
                    src={'/img/bank-safe-icon.svg'}
                    className={'ReplaceTripScreen-Icon'}
                  />
                }
              </div>
            </div>

            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripScreen-Block'
              }
            >
              <input
                type={'checkbox'}
                className={'OptionCheckBox'}
                checked={updatedTripData?.isPaymentByCash}
                onChange={(event) => updateTrip('isPaymentByCash',
                  event.target.checked)}
                disabled={updatedTripData?.isPaymentOnCard}
                id={'isPaymentByCash'}
              />

              <label
                htmlFor={'isPaymentByCash'}
                className={'OptionText'}
              >
                {translate('Наличные по договоренности')}
              </label>
            </div>

            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripScreen-Block'
              }
            >
              <input
                id={'isForChange'}
                type={'checkbox'}
                className={'OptionCheckBox'}
                onChange={(event) => updateTrip('isForChange',
                  event.target.checked)}
                checked={updatedTripData?.isForChange}
              />

              <label
                className={'OptionText'}
                htmlFor={'isForChange'}
              >
                {translate('Обмен')}
              </label>
            </div>
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div className={'ReplaceTripScreen-Item'}>
            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripScreen-Block'
              }
            >
              <input
                id={'isVisibleForDispatchers'}
                type={'checkbox'}
                className={'OptionCheckBox'}
                onChange={(event) => updateTrip(
                  'isVisibleForDispatchers', event.target.checked)}
                checked={updatedTripData?.isVisibleForDispatchers}
              />

              <label
                htmlFor={'isVisibleForDispatchers'}
                className={'OptionText OptionText_Bold'}
              >
                {translate('Опубликовать предложение для диспетчеров')}
              </label>
            </div>
          </div>

          <div className={'ReplaceTripScreen-Item'}>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={() => replaceTrip(updatedTripData, user)}
            >
              {translate('Сохранить')}
            </button>
          </div>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default ReplaceTripScreen;
