import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {getCurrentTripLocation} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import useTranslation from "../hooks/useTranslation";
import '../styles/CarOnMap.scss';

const iconPath = '/img/profile-menu/car-icon.svg';

const CarOnMap = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen title managing function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title and current trip order on component mount
  useEffect(() => {
    setScreenTitle('Авто на карте');
  }, []);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTrip?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Init map
  function initMap(location) {
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: {lat: location.lat || 0, lng: location.lng || 0},
      zoom: 6,
    });

    new window.google.maps.Marker({
      position: location,
      icon: iconPath,
      map,
      title: "Car on map",
    });
  }

  useEffect(() => {
    getCurrentTripLocation(currentTrip).then(location => {
      initMap(location);
    }).catch(() => {})
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      <div
        id={'map'}
        className={`CarOnMap ${addClasses}`}
      >
      </div>
    </>
  );
  //#endregion
};

export default CarOnMap;
