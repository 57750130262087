import React, {useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Modal} from "react-bootstrap";
import FormInputAlertText from "../components/FormInputAlertText";
import useTranslation from "../hooks/useTranslation";

const ModalSelectPaymentMethod = ({
  title = '',
  showModal,
  setShowModal = () => {},
  isCash = false,
  updateReservation = () => {},
  submitReservation = () => {},
  reservationData,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentReservation, currentTrip, currentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Handle errors
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  //#endregion

  //#regiton Handle reservation submit confirmation
  const handleSubmitConfirmation = async () => {
    if (!reservationData?.isForChange && !reservationData?.isPaymentByCash &&
      !reservationData?.isPaymentOnCard) {
      setIsError(true);
      setErrorText('Необходимо выбрать способ получения гонорара');

      return;
    }

    try {
      setShowModal(false);

      await submitReservation(currentReservation,
        reservationData, currentTripOrder, currentTrip, true);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={'ConfirmReservation-Item'}>
          <h5 className={'RegularTitle RegularTitle_Small'}>
            {translate('Способ оплаты')}
          </h5>

          {isCash ? (
            <div
              className={'FlexBlock FlexBlock_JustifyLeft ' +
                'ReplaceTripOrderScreen-Block'}
            >
              <input
                id={'isPaymentByCash'}
                type={'checkbox'}
                className={'OptionCheckBox'}
                checked={reservationData?.isPaymentByCash}
                onChange={(event) => {
                  updateReservation('isPaymentByCash', event.target.checked);
                  updateReservation('isForChange', !event.target.checked);
                }}
              />
              <label
                className={'ReplaceTripOrderScreen-OptionText'}
                htmlFor={'isPaymentByCash'}
              >
                {translate('Наличные по договоренности')}
              </label>
            </div>
          ) : (
            <div
              className={'FlexBlock FlexBlock_JustifyLeft' +
                ' ReplaceTripOrderScreen-Block'}
            >
              <div className={'OptionCheckBox'}>
                <input
                  id={'isPaymentOnCard'}
                  type={'checkbox'}
                  className={'OptionCheckBox'}
                  checked={reservationData?.isPaymentOnCard}
                  onChange={(event) => {
                    updateReservation('isPaymentOnCard', event.target.checked);
                    updateReservation('isForChange', !event.target.checked);
                  }}

                />
              </div>

              <label
                className={'ReplaceTripOrderScreen-OptionText'}
                htmlFor={'isPaymentOnCard'}
              >
                {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
              </label>

              {reservationData?.isPaymentOnCard &&
                <div className={'ReplaceTripOrderScreen-IconContainer'}>
                  <img
                    src={'/img/bank-safe-icon.svg'}
                    className={'ReplaceTripOrderScreen-Icon'}
                  />
                </div>
              }
            </div>
          )}

          <div
            className={'FlexBlock FlexBlock_JustifyLeft ' +
              'ReplaceTripOrderScreen-Block'}
          >
            <input
              id={'isForChange'}
              type={'checkbox'}
              className={'OptionCheckBox'}
              onChange={(event) => {
                updateReservation('isForChange', event.target.checked);
                updateReservation('isPaymentOnCard', !event.target.checked);
                updateReservation('isPaymentByCash', !event.target.checked);
              }}
              checked={reservationData?.isForChange}
            />

            <label className={'ReplaceTripOrderScreen-OptionText'}
              htmlFor={'isForChange'}>
              {translate('Обмен')}
            </label>
          </div>
        </div>

        {isError &&
          <FormInputAlertText
            text={errorText}
            setIsVisible={setIsError}
            addClasses={'LeftPadding'}
          />
        }
      </Modal.Body>

      <div className={'PaddingBlock'}></div>

      <Modal.Footer>
        <button
          type={'button'}
          className={'RegularButton'}
          onClick={() => handleSubmitConfirmation()}
        >
          {translate('Подтвердить')}
        </button>

        <button
          type={'button'}
          className={'RegularButton RegularButton_Contrast'}
          onClick={() => {
            setShowModal(false)
          }}
        >
          {translate('Вернуться')}
        </button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalSelectPaymentMethod;
