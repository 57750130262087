import React, { useContext } from 'react';
import {
  signInWithAppleButtonClickHandler,
  signInWithFacebookButtonClickHandler,
  signInWithGoogleButtonClickHandler,
} from '../utils/helpers';
import { AppContext } from '../context/appContext';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../hooks/useTranslation';
import '../styles/SideRegisterServices.scss';

const SideRegisterServices = ({ addClasses = '' }) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get setUser function from context
  const { setUser } = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Render
  return (
    <>
      <div className={`SideRegisterServices ${addClasses}`}>
        <div className={'SideRegisterServices-TextContainer'}>
          <div className={'SideRegisterServices-Line'}></div>
          <div className={'SideRegisterServices-Text'}>
            {translate('Войти через')}
          </div>
          <div className={'SideRegisterServices-Line'}></div>
        </div>

        <div className={'SideRegisterServices-Buttons'}>
          {/* <button
            type={'button'}
            onClick={(event) => signInWithFacebookButtonClickHandler(event,
              setUser, navigate)}
            className={'RegularButton SideRegisterServices-Button'}
          >
            <img
              className={'SideRegisterServices-Icon'}
              src={'/img/facebook-icon.svg'}
              alt={'Facebook icon'}
            />
          </button> */}

          <button
            type={'button'}
            onClick={(event) =>
              signInWithGoogleButtonClickHandler(event, setUser, navigate)
            }
            className={'RegularButton SideRegisterServices-Button'}
          >
            <img
              className={'SideRegisterServices-Icon'}
              src={'/img/google-icon.svg'}
              alt={'Google icon'}
            />
          </button>

          <button
            type={'button'}
            onClick={(event) =>
              signInWithAppleButtonClickHandler(event, setUser, navigate)
            }
            className={'RegularButton SideRegisterServices-Button'}
          >
            <img
              className={'SideRegisterServices-Icon'}
              src={'/img/apple-icon.svg'}
              alt={'Apple icon'}
            />
          </button>
        </div>
      </div>
    </>
  );
  //#endregion
};

export default SideRegisterServices;
