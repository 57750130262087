import {
  getCollectionWhereKeysValuesWithOperatorOrderedAndLimited,
  getLengthOfCollectionWhereKeysValuesWithOperators,
} from "./firebaseConfigAndFunctions";
import {collectionsInterface} from "./models";

async function getTripWithTheNearestEndDateAfter(collectionName, dateForCheck,
  idName, id) {
  const nearestTrip = await getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
      collectionName,
      [
        {
          key: idName,
          value: id,
          operator: '==',
        },
        {
          key: 'endDate',
          value: dateForCheck,
          operator: '>=',
        },
      ],
      'endDate', true, 1);

  return nearestTrip[0] || null;
}

async function getTripWithTheNearestStartDateAfter(collectionName, dateForCheck,
  idName, id) {
  const nearestTrip =
    await getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
      collectionName, [
        {
          key: idName,
          value: id,
          operator: '==',
        },
        {
          key: 'startDate',
          value: dateForCheck,
          operator: '>=',
        },
      ], 'startDate', true, 1);

  return nearestTrip[0] || null;
}

function checkIsDateAfterTripStart(startDate, dateForCheck) {
  return startDate <= dateForCheck;
}

async function checkIsDateInsideSomeTripDates(collectionName, dateForCheck,
  idName, id) {
  const nearestTrip = await getTripWithTheNearestEndDateAfter(collectionName,
    dateForCheck, idName, id);

  const isInside = nearestTrip ? checkIsDateAfterTripStart(
    nearestTrip.startDate, dateForCheck) : false;

  return isInside;
}

async function checkIsDateInsideNewTripDates(collectionName, startDate, endDate,
  idName, id) {
  const nearestTrip = await getTripWithTheNearestStartDateAfter(collectionName,
    startDate, idName, id);

  const isInside = nearestTrip ? checkIsDateAfterTripStart(
    nearestTrip.endDate, endDate) : false;

  return isInside;
}

async function checkIsAlreadyBusyInTrip(collectionName, startDate, endDate,
  idName, id) {
  const isStartDateInsideSomeDates = await checkIsDateInsideSomeTripDates(
    collectionName, startDate, idName, id)

  if (isStartDateInsideSomeDates) {
    return true;
  }

  const isEndDateInsideSomeDates = await checkIsDateInsideSomeTripDates(
    collectionName, endDate,  idName, id);

  if (isEndDateInsideSomeDates) {
    return true;
  }

  const isSomeTripInsideNewTrip = await checkIsDateInsideNewTripDates(
    collectionName, startDate, endDate,  idName, id);

  return isSomeTripInsideNewTrip;
}

async function checkIsThereTripWithVehicle(vehicleId, userId) {
  const tripsWithVehicle = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.trips, [
      {
        key: 'creatorIdPost',
        value: userId,
        operator: '==',
      },
      {
        key: 'isArchived',
        value: false,
        operator: '==',
      },
      {
        key: 'vehicle.idPost',
        value: vehicleId,
        operator: '==',
      },
    ], 3)

  return tripsWithVehicle > 0;
}

export const busyInTripsService = {
  checkIsAlreadyBusyInTrip,
  checkIsThereTripWithVehicle,
}
