import React, {useEffect, useState} from 'react';
import {Pagination} from "react-bootstrap";
import '../styles/PaginationBlock.scss';

const PaginationBlock = ({
  addClasses = '',
  portionSize = 10,
  listLength = 0,
  startPage = 1,
  setStartPage = () => {},
  currentPage = 1,
  setCurrentPage = () => {},
}) => {
  //#region Pages number
  const [pagesNumber, setPagesNumber] = useState(1);
  const pagesList = [0, 1, 2, 3, 4];

  useEffect(() => {
    setPagesNumber(Math.ceil(listLength / portionSize))
  }, [listLength, portionSize]);
  //#endregion

  //#region Handlers for clicks
  const clickNext = () => {
    if (currentPage < pagesNumber && startPage + 4 < pagesNumber) {
      setStartPage(state => state + 1);
      setCurrentPage(state => state + 1);
    } else {
      setCurrentPage(state => state + 1);
    }
  }

  const clickPrevious = () => {
    if (startPage > 1) {
      setStartPage(state => state - 1);
      setCurrentPage(state => state - 1);
    } else {
      setCurrentPage(state => state - 1);
    }
  }

  const exactPageClick = (page) => {
    setCurrentPage(startPage + page);
  }
  //#endregion

  //#region Render
  return (
    <div className={`PaginationBlock ${addClasses}`}>
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => clickPrevious(currentPage)}
        />

        {pagesList.map(page =>
          <React.Fragment key={page}>
            {pagesNumber > page &&
              <>
                <Pagination.Item
                  active={currentPage === startPage + page}
                  onClick={() => exactPageClick(page)}
                >
                  {startPage + page}
                </Pagination.Item>

                {page === 4 && (startPage + page < pagesNumber) &&
                  <Pagination.Ellipsis
                    className={'PaginationBlock-More'}
                  />
                }
              </>
            }
          </React.Fragment>
        )}

        <Pagination.Next
          onClick={() => clickNext(currentPage)}
          disabled={currentPage === pagesNumber}
        />
      </Pagination>
    </div>
  );
  //#endregion
};

export default PaginationBlock;
