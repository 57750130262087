import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Route, Routes} from "react-router-dom";
import DisplayArchivedTripsOrders from "./DisplayArchivedTripsOrders";
import HistoryTripOrderDetails from "./HistoryTripOrderDetails";
import {subpagesInterface, tripModel} from "../utils/models";
import {sortCardsByDateOfCreation} from "../utils/helpers";
import useTripsList from "../hooks/useTripsList";
import {queryArraysService} from "../utils/queryArraysService";
import {tripService} from "../utils/tripService";

const TripsHistoryForDrivers = ({
  addClasses = '',
  handleTripOrderCardClick = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setCurrentTrip(tripModel);
  }, []);
  //#endregion

  //#region Data to display
  const [tripsOrdersToDisplay, setTripsOrdersToDisplay] = useState([]);

  const creatorId = user.attachedToCompany || user.idPost;

  const {isLoading, setIsLoading, tripsList, setTripsList} = useTripsList(queryArraysService
    .archivedTrips(creatorId), user.idPost, user.attachedToCompany);

  const getTripsOrdersAndSet = async () => {
    setIsLoading(true);

    try {
      const orders = await tripService.getArchivedPassengersListOfTransporter(
        creatorId, user.idPost);

      setTripsOrdersToDisplay(sortCardsByDateOfCreation(orders, false));
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (tripsList.length > 0) {
      getTripsOrdersAndSet().then();
    }
  }, [tripsList]);
  //#endregion


  //#region Update trips lists, current entities after actions
  const updateAfterPaymentConfirmation = (tripOrder) => {
    setTripsOrdersToDisplay(orders => orders
      .map(order => order.idPost === tripOrder.idPost ? tripOrder : order));
  }
  //#endregion

  //#region Render
  return (
    <div className={`TripsHistoryDrivers ${addClasses}`}>
      <Routes>
        <Route
          index
          element={
            <DisplayArchivedTripsOrders
              trips={tripsList}
              isNotepad={false}
              tripsOrdersToDisplay={tripsOrdersToDisplay}
              handleTripOrderCardClick={handleTripOrderCardClick}
              addClasses={'Passengers-DisplayTripsOrders'}
              isDispatcher={false}
              isFullDispatcherInfo={true}
              isLoading={isLoading}
            />
          }
        />

        <Route
          path={`/${subpagesInterface.archivedTripOrderDetails}`}
          element={
            <HistoryTripOrderDetails
              isDispatcher={false}
              updateAfterPaymentConfirmationOrClaimCreation={
                updateAfterPaymentConfirmation
              }
            />
          }
        />
      </Routes>
    </div>
  );
  //#endregion
};

export default TripsHistoryForDrivers;
