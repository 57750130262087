import React, {useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  createLinkToInviteDriver,
  getPossibleAuthOptionsForEmail,
  sendMessageAboutInvitationCancellation,
  sendMessageToUser
} from "../utils/helpers";
import {
  deleteFieldInDocumentInCollection,
  getCollectionWhereKeyValue, getDocInCollection,
  updateFieldInDocumentInCollection, updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  authProvidersCheckResultsInterface,
  collectionsInterface
} from "../utils/models";
import Loader from "./Loader";
import ModalNotification from "../modals/ModalNotification";
import '../styles/DriverCard.scss';
import useTranslation from "../hooks/useTranslation";

const DriverCard = ({
  addClasses = '',
  driver,
  setCurrentDriver = () => {},
  isEditMode = false,
  setIsDriversListVisible = () => {},
  setDriverScreens = () => {},
  goBack
}) => {
  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Handle invite driver click
  const handleInviteDriverClick = async (user, driverEmail) => {
    try {
      setIsLoading(true);

      const checkRegistration = await getPossibleAuthOptionsForEmail(driverEmail);

      if (checkRegistration === authProvidersCheckResultsInterface.noProviders) {
        const link = createLinkToInviteDriver(user, driverEmail);

        let emailText = `${translate('Компания')} ${user.companyName}
        ${translate('приглашает Вас в приложение IntDriver')}
        ${translate('Чтобы зарегистрироваться, перейдите по ссылке')} ${link}`;

        const title = 'Приглашение';

        await sendMessageToUser({ email: driverEmail }, title, emailText);
      } else {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Пользователь с такой электронной почтой уже существует');
      }
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handle delete driver click
  const handleDeleteDriverClick = async (userId, driver) => {
    try {
      setIsLoading(true);

      const actualUser = await getDocInCollection(collectionsInterface.users,
        userId);

      const isInList = actualUser.companyDrivers
        .some(curDriver => (curDriver.email === driver.email || curDriver
          .supposedEmail === driver.email));

      if (!isInList) {
        setIsLoading(false);
        setIsNotification(true)
        setNotificationTitle('Уведомление');
        setNotificationMessage('Водителя уже нет в списке');

        setUser(actualUser);


        setTimeout(() => {
          goBack();
        }, 1500);

        return;
      }

      const newDriversArray = actualUser.companyDrivers
        .filter(cDriver => cDriver.supposedEmail !== driver.supposedEmail);

      let isExistingDriver = false;
      let driverId = '';

      await updateFieldInDocumentInCollection(collectionsInterface.users,
        userId, 'companyDrivers', newDriversArray);

      if (driver.isFulfilledRegistration && driver.uid) {
        const driversInDb = await getCollectionWhereKeyValue(
          collectionsInterface.users, 'uid', driver.uid);
        const driverInDb = driversInDb[0];

        if (driverInDb.attachedToCompany === userId) {
          await updateFieldsInDocumentInCollection(collectionsInterface.users,
            driverInDb.idPost, {
              attachedToCompany: '',
              isDriverInCompany: false,
            });
        }
      } else {
        const checkRegistration = await getPossibleAuthOptionsForEmail(driver
          .supposedEmail);

        if (checkRegistration !== authProvidersCheckResultsInterface.noProviders) {
          const drivers = await getCollectionWhereKeyValue(collectionsInterface.users,
            'email', driver.supposedEmail);

          const driverFromDb = drivers[0];

          const invitation = Object.values(driverFromDb.invitations)
            .find(invitation => invitation.companyId === userId);

          if (invitation) {
            await deleteFieldInDocumentInCollection(collectionsInterface.users,
              driverFromDb.idPost, `invitations.${invitation.id}`);

            isExistingDriver = true;
            driverId = driverFromDb.idPost;
          }
        }
      }

      await sendMessageAboutInvitationCancellation(actualUser?.companyName || actualUser
        ?.fullName || '', driverId, driver.supposedEmail, isExistingDriver);

      setUser(state => ({
        ...state,
        companyDrivers: newDriversArray,
      }));
      goBack();

    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handle show trips
  const handleShowTrips = () => {
    setDriverScreens({
      isAddTripScreen: false,
      isDriverScreen: false,
      isTripsScreen: true,
    });
  }
  //#endregion

  //#region Handle add trip
  const handleAddTrip = () => {
    setDriverScreens({
      isAddTripScreen: true,
      isDriverScreen: false,
      isTripsScreen: false,
    });
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div
          className={`DriverCard ${addClasses}`}
          onClick={() => {
            setCurrentDriver(driver);
            setIsDriversListVisible(false);
          }}
        >
          <div className={'DriverCard-Item'}>
            {driver?.fullName}
          </div>

          <div className={'DriverCard-Item'}>
            {driver?.idPost === user?.idPost ? driver?.email
              : driver?.supposedEmail}
          </div>

          {driver?.idPost !== user?.idPost &&
            <div
              className={driver?.isFulfilledRegistration
                ? 'DriverCard-Status DriverCard-Status_Confirmed'
                : 'DriverCard-Status'}
            >
              {driver?.isFulfilledRegistration ? translate('Подтвержден')
                : translate('Ожидает')}
            </div>
          }

          {isEditMode &&
            <>
              {driver?.idPost !== user?.idPost &&
                <>
                  {!driver?.isFulfilledRegistration &&
                    <div className={'DriverCard-Item'}>
                      <button
                        className={'RegularButton'}
                        onClick={() => handleInviteDriverClick(user,
                          driver.supposedEmail)}
                      >
                        {translate('Пригласить повторно')}
                      </button>
                    </div>
                  }

                  <div className={'DriverCard-Item'}>
                    <button
                      className={'RegularButton RegularButton_Contrast'}
                      onClick={() => handleDeleteDriverClick(user.idPost, driver)}
                    >
                      {translate('Удалить')}
                    </button>
                  </div>
                </>
              }

              {goBack &&
                <div className={'DriverCard-Item'}>
                  <button
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={() => goBack()}
                  >
                    {translate('Вернуться')}
                  </button>
                </div>
              }

              {(driver?.isFulfilledRegistration || driver.idPost === user.idPost) &&
                <>
                  <div className={'DriverCard-Item'}>
                    <button
                      className={'RegularButton'}
                      onClick={() => handleShowTrips(driver.idPost)}
                    >
                      {translate('Поездки')}
                    </button>
                  </div>

                  <div className={'DriverCard-Item'}>
                    <button
                      className={'RegularButton'}
                      onClick={() => handleAddTrip()}
                    >
                      {translate('Добавить поездку')}
                    </button>
                  </div>
                </>
              }
            </>
          }
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default DriverCard;
