import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context/appContext';
import {
  correctCardNumber,
  makeOrderForWithdrawal,
  validateAndCorrectCardNumber,
  validateCardNumberLength,
} from '../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { getDocInCollection } from '../utils/firebaseConfigAndFunctions';
import { collectionsInterface } from '../utils/models';
import ModalNotification from '../modals/ModalNotification';
import Loader from './Loader';
import NumberInputWithValidation from '../elements/NumberInputWithValidation';
import useTranslation from '../hooks/useTranslation';
import '../styles/WithdrawFromBalance.scss';

const WithdrawFromBalance = ({ addClasses = '' }) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const { user } = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Data
  const [paymentData, setPaymentData] = useState({});

  const updatePaymentData = (fieldName, newValue) => {
    let validatedValue = '';

    if (newValue === '') {
      validatedValue = '';

      setPaymentData((state) => ({
        ...state,
        [fieldName]: validatedValue,
      }));

      return;
    }

    switch (fieldName) {
      case 'cardNumber':
        validatedValue = newValue.replace(/\D/g, '');

        if (validatedValue.length > 18) {
          validatedValue = validatedValue.slice(0, 18);
        }

        break;
      default:
        validatedValue = newValue;
    }

    setPaymentData((state) => ({
      ...state,
      [fieldName]: validatedValue,
    }));
  };

  const onCardNumberFocus = (event) => {
    const currentValue = event.target.value;

    const clearedValue = currentValue.replace(/\D/g, '');

    setPaymentData((state) => ({
      ...state,
      cardNumber: clearedValue,
    }));
  };

  const onCardNumberBlur = (event) => {
    const newValue = event.target.value;

    setPaymentData((state) => ({
      ...state,
      cardNumber: correctCardNumber(newValue),
    }));
  };
  //#endregion

  //#region Get screen data and functions from context
  const { screenTitle, setScreenTitle } = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Вывести средства');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Handle withdrawal button click
  const handleWithdrawalButtonClick = async (paymentData) => {
    setIsLoading(true);

    try {
      if (!paymentData.amount || Number(paymentData.amount) < 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Неверная сумма');

        return;
      }

      if (
        !paymentData?.cardNumber ||
        !validateCardNumberLength(paymentData.cardNumber)
      ) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Неверный номер карты');

        return;
      }

      if (!paymentData?.iban) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Неверный IBAN');

        return;
      }

      if (!paymentData?.fullName) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Неверное имя владельца карты');

        return;
      }

      if (!paymentData?.swiftCode) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Неверный SWIFT-код');

        return;
      }

      const actualBalance = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        user.balanceAccount
      );

      if (Number(paymentData.amount > actualBalance.amount)) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Нельзя выводить больше, чем есть на счету');

        return;
      }

      const updatedPaymentData = {
        ...paymentData,
        userId: user.idPost,
        userFullName: user.fullName,
      };

      const result = await makeOrderForWithdrawal(
        updatedPaymentData,
        actualBalance.idPost
      );

      if (result) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Заявка на вывод средст принята');

        setTimeout(() => {
          navigate('/profile');
        }, 1000);
      } else {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Произошла ошибка, заявка не была создана');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Произошла ошибка, заявка не была создана');
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={`WithdrawFromBalance ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'WithdrawFromBalance-Item'}>
            <label className={'WithdrawFromBalance-Label'}>
              {translate('Сумма вывода')}
            </label>

            <NumberInputWithValidation
              addClasses={'WithdrawFromBalance-Input'}
              externalChangeHandler={updatePaymentData}
              fieldName={'amount'}
              name={'Amount'}
              placeholder={translate('Сумма вывода')}
              value={paymentData.amount}
            />
          </div>

          <div className={'WithdrawFromBalance-Item'}>
            <label className={'WithdrawFromBalance-Label'}>
              {translate('Номер карты')}
            </label>

            <input
              required={true}
              className={'FormControl WithdrawFromBalance-Input'}
              name={'CardNumber'}
              type={'text'}
              placeholder={translate('Номер карты')}
              value={paymentData.cardNumber}
              onChange={(event) =>
                updatePaymentData('cardNumber', event.target.value)
              }
              onFocus={onCardNumberFocus}
              onBlur={onCardNumberBlur}
            />
          </div>

          <div className={'WithdrawFromBalance-Item'}>
            <label className={'WithdrawFromBalance-Label'}>
              {translate('IBAN')}
            </label>

            <input
              required={true}
              className={'FormControl WithdrawFromBalance-Input'}
              name={'IBAN'}
              type={'text'}
              placeholder={translate('IBAN')}
              value={paymentData.iban}
              onChange={(event) =>
                updatePaymentData('iban', event.target.value)
              }
            />
          </div>

          <div className={'WithdrawFromBalance-Item'}>
            <label className={'WithdrawFromBalance-Label'}>
              {translate('Имя владельца карты')}
            </label>

            <input
              required={true}
              className={'FormControl WithdrawFromBalance-Input'}
              name={'FullName'}
              type={'text'}
              placeholder={translate('Имя владельца карты')}
              value={paymentData.fullName}
              onChange={(event) =>
                updatePaymentData('fullName', event.target.value)
              }
            />
          </div>

          <div className={'WithdrawFromBalance-Item'}>
            <label className={'WithdrawFromBalance-Label'}>
              {translate('SWIFT-код')}
            </label>

            <input
              addClasses={'WithdrawFromBalance-Input'}
              onChange={(event) =>
                updatePaymentData('swiftCode', event.target.value)
              }
              name={'SWIFT'}
              placeholder={translate('SWIFT-код')}
              value={paymentData.swiftCode}
              className={'FormControl WithdrawFromBalance-Input'}
            />
          </div>

          <div className={'WithdrawFromBalance-Item'}>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={() =>
                handleWithdrawalButtonClick(paymentData, user.balanceAccount)
              }
            >
              {translate('Вывести')}
            </button>
          </div>
        </div>
      )}

      {isNotification && (
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      )}
    </>
  );
  //#endregion
};

export default WithdrawFromBalance;
