import {
  checkIsTripSuitableForPassengerByFootOption,
  checkIsTripSuitableForPassengerByPetsOption,
  checkIsTripSuitableForPassengerByRoute
} from "./checkTripService";
import {
  getDocInCollection, setDocumentToCollection,
  updateFieldsInDocumentInCollection
} from "./firebaseConfigAndFunctions";
import {collectionsInterface} from "./models";
import {
  getDataFromTripToSaveInReservation,
  getMoneyAmountToFreeze,
  sendMessageAboutMoneyUnfreezed,
  sendMessageAboutReservationRejectionByTransporter,
  sendMessageAboutTripCancellationByDispatcher,
  sendMessageAboutTripCancellationByTransporter,
  unfreezeFeeAfterDealCancellation
} from "./helpers";

const initiatorTypes = {
  dispatcher: 'dispatcher',
  driver: 'driver',
}

export const getMergedReservationsFromTwoArrays = (baseReservations,
  addedReservations) => {
  const filteredAddedReservations = addedReservations
    .filter(reservation => !baseReservations
      .some(curReservation => curReservation.uid === reservation.uid));

  const unsuitableReservations = [...baseReservations,
    ...filteredAddedReservations];

  return unsuitableReservations;
}

export const getUnsuitableReservationsAfterTripPointsChange = (tripPoints,
  reservations) => {
  const unsuitableReservations = [];

  reservations.filter(reservation => !reservation.isCancelled)
    .forEach(reservation => {
      const checkResult = checkIsTripSuitableForPassengerByRoute(tripPoints,
        reservation.originalTripOrder?.startPoint?.placeId, reservation
          .originalTripOrder?.endPoint.placeId);

      if (!checkResult) {
        unsuitableReservations.push(reservation);
      }
    });

  return unsuitableReservations;
}

export const createRejectedReservationsList = (reservations,
  cancelComment = 'Перевозчик отклонил Вашу бронь по пассажиру') => {
  const updatedReservations = reservations.map(reservation => ({
    ...reservation,
    isCancelled: true,
    cancelComment: cancelComment,
  }));

  return updatedReservations;
}

export const getUpdatedReservationsList = (initialList,
  changedReservations) => {
  if (changedReservations.length === 0) {
    return initialList;
  }

  const updatedList = initialList.map(reservation => {
    const updatedReservation = changedReservations
      .find(uReservation => uReservation.uuid === reservation.uuid);

    if (updatedReservation) {
      return updatedReservation;
    }

    return reservation;
  });

  return updatedList;
}

export const checkReservationsByRouteAndGetRejectedList = (tripPoints = [],
  reservations = []) => {
  const unsuitableReservations =
    getUnsuitableReservationsAfterTripPointsChange(tripPoints,
      reservations);

  if (unsuitableReservations.length === 0) {
    return [];
  }

  const rejectedReservations = createRejectedReservationsList(
    unsuitableReservations, 'Поездка больше не подходит пассажиру');

  return rejectedReservations;
}

export const getUnsuitableReservationsAfterPetsOptionChangeInTripOrder = (
  reservations) => {
  const unsuitableReservations = [];

  reservations.filter(reservation => !reservation.isCancelled)
    .forEach(reservation => {
      const checkResult = checkIsTripSuitableForPassengerByPetsOption(reservation
        .originalTrip.isTripWithPets, true);

      if (!checkResult) {
        unsuitableReservations.push(reservation);
      }
    });

  return unsuitableReservations;
}

export const getUnsuitableReservationsAfterByFootOptionChangeInTripOrder = (
  reservations) => {
  const unsuitableReservations = [];

  reservations.filter(reservation => !reservation.isCancelled)
    .forEach(reservation => {
      const checkResult = checkIsTripSuitableForPassengerByFootOption(reservation
        .originalTrip.isByFoot, false);

      if (!checkResult) {
        unsuitableReservations.push(reservation);
      }
    });

  return unsuitableReservations;
}

export const getUnsuitableReservationsAfterPetsOptionChangeInTrip = (
  reservations) => {
  const unsuitableReservations = [];

  reservations.filter(reservation => !reservation.isCancelled)
    .forEach(reservation => {
      const checkResult = checkIsTripSuitableForPassengerByPetsOption(false,
        reservation.originalTripOrder.isTripWithPets);
      if (!checkResult) {
        unsuitableReservations.push(reservation);
      }
    });

  return unsuitableReservations;
}

export const getUnsuitableReservationsAfterByFootOptionChangeInTrip = (
  reservations) => {
  const unsuitableReservations = [];

  reservations.filter(reservation => !reservation.isCancelled)
    .forEach(reservation => {
      const checkResult = checkIsTripSuitableForPassengerByFootOption(true,
        reservation.originalTripOrder.isByFoot);
      if (!checkResult) {
        unsuitableReservations.push(reservation);
      }
    });

  return unsuitableReservations;
}

export const getUnsuitableReservationsAfterAddOptionsChangeInTrip = (
  reservations, needPetOptionCheck, needByFootOptionCheck) => {
  const unsuitableReservationsByPetsOption = needPetOptionCheck ?
    getUnsuitableReservationsAfterPetsOptionChangeInTrip(reservations) : [];

  const unsuitableReservationsByFootOption = needByFootOptionCheck ?
    getUnsuitableReservationsAfterByFootOptionChangeInTrip(reservations) : [];

  const unsuitableReservations = getMergedReservationsFromTwoArrays(
    unsuitableReservationsByPetsOption, unsuitableReservationsByFootOption);

  return unsuitableReservations;
}

export const getUnsuitableReservationsAfterAddOptionsChangeInTripOrder = (
  reservations, needPetOptionCheck, needByFootOptionCheck) => {
  const unsuitableReservationsByPetsOption = needPetOptionCheck ?
    getUnsuitableReservationsAfterPetsOptionChangeInTripOrder(reservations) : [];

  const unsuitableReservationsByFootOption = needByFootOptionCheck ?
    getUnsuitableReservationsAfterByFootOptionChangeInTripOrder(reservations) : [];

  const unsuitableReservations = getMergedReservationsFromTwoArrays(
    unsuitableReservationsByPetsOption, unsuitableReservationsByFootOption);

  return unsuitableReservations;
}

export const checkReservationsByAddOptionsAndGetRejectedList = (reservations = [],
  needPetOptionCheck, needByFootOptionCheck, isTrip = false) => {
  const unsuitableReservations = isTrip ?
    getUnsuitableReservationsAfterAddOptionsChangeInTrip(reservations,
      needPetOptionCheck, needByFootOptionCheck) :
    getUnsuitableReservationsAfterAddOptionsChangeInTripOrder(reservations,
      needPetOptionCheck, needByFootOptionCheck);

  if (unsuitableReservations.length === 0) {
    return [];
  }

  const rejectedReservations = createRejectedReservationsList(
    unsuitableReservations, 'Поездка больше не подходит пассажиру');

  return rejectedReservations;
}

export const rejectAllUnsuitableReservationsInTrip = async (tripId, companyName,
  reservationsFromDispatchers, reservationsFromDrivers,
  rejectedReservationsFromDispatchers, rejectedReservationsFromDrivers) => {

  let shouldBeUpdated = false;

  const fieldsToUpdate = {};

  if (rejectedReservationsFromDispatchers.length > 0) {
    const updatedReservationsFromDispatchers =
      getUpdatedReservationsList(reservationsFromDispatchers,
        rejectedReservationsFromDispatchers);

    shouldBeUpdated = true;

    fieldsToUpdate.reservationsFromDispatchers = updatedReservationsFromDispatchers;
  }

  if (rejectedReservationsFromDrivers.length > 0) {
    const updatedReservationsFromDrivers =
      getUpdatedReservationsList(reservationsFromDrivers,
        rejectedReservationsFromDrivers);

    shouldBeUpdated = true;

    fieldsToUpdate.reservationsFromDrivers = updatedReservationsFromDrivers;
  }

  if (shouldBeUpdated) {
    await updateFieldsInDocumentInCollection(collectionsInterface.trips,
      tripId, fieldsToUpdate);

    for (const reservation of rejectedReservationsFromDispatchers) {
      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, reservation.originalTripOrder.idPost);

      const updatedReservationsFromDispatchers = actualTripOrder
        .reservationsFromDispatchers.map(orderReservation => orderReservation
          .uuid === reservation.uuid ? reservation : orderReservation);

      await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
        actualTripOrder.idPost, {
          reservationsFromDispatchers: updatedReservationsFromDispatchers,
        });

      const tripForMessage = {
        startDate: reservation.originalTrip.startDate,
        creatorIdPost: reservation.originalTrip.creatorIdPost,
        startPoint: reservation.originalTrip.startPoint,
        endPoint: reservation.originalTrip.endPoint,
        companyName,
      }

      const tripOrderForMessage = {
        creatorIdPost: actualTripOrder.creatorIdPost,
        passengerName: actualTripOrder.passengerName,
        startPoint: actualTripOrder.startPoint,
        endPoint: actualTripOrder.endPoint,
      }

      await sendMessageAboutReservationRejectionByTransporter(tripForMessage,
        tripOrderForMessage, 'Поездка больше не подходит пассажиру');
    };

    for (const reservation of rejectedReservationsFromDrivers) {
      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, reservation.originalTripOrder.idPost);

      const updatedReservationsFromDrivers = actualTripOrder
        .reservationsFromDrivers.map(orderReservation => orderReservation
          .uuid === reservation.uuid ? reservation : orderReservation);

      await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
        actualTripOrder.idPost, {
          reservationsFromDrivers: updatedReservationsFromDrivers,
        });

      const tripForMessage = {
        creatorIdPost: reservation.originalTrip.creatorIdPost,
        startDate: reservation.originalTrip.startDate,
        startPoint: reservation.originalTrip.startPoint,
        endPoint: reservation.originalTrip.endPoint,
        companyName,
      }

      const tripOrderForMessage = {
        creatorIdPost: actualTripOrder.creatorIdPost,
        passengerName: actualTripOrder.passengerName,
        startPoint: actualTripOrder.startPoint,
        endPoint: actualTripOrder.endPoint,
      }

      await sendMessageAboutReservationRejectionByTransporter(tripForMessage,
        tripOrderForMessage, 'Поездка больше не подходит пассажиру');
    };
    return {
      wasUpdated: true,
      fieldsToUpdate,
    }
  }

  return {
    wasUpdated: false,
    fieldsToUpdate,
  }
}

export const rejectAllUnsuitableReservationsInTripAfterChange = async (tripId,
  tripPoints, reservationsFromDispatchers, reservationsFromDrivers,
  companyName = '', needPetOptionCheck, needByFootOptionCheck) => {
  try {
    const rejectedReservationsFromDispatchersByRoute = tripPoints ?
      checkReservationsByRouteAndGetRejectedList(tripPoints,
        reservationsFromDispatchers) : [];

    const rejectedReservationsFromDispatchersByAddOptions =
      checkReservationsByAddOptionsAndGetRejectedList(reservationsFromDispatchers,
        needPetOptionCheck, needByFootOptionCheck, true);

    const rejectedReservationsFromDispatchers = getMergedReservationsFromTwoArrays(
      rejectedReservationsFromDispatchersByRoute,
      rejectedReservationsFromDispatchersByAddOptions);

    const rejectedReservationsFromDriversByRoute = tripPoints ?
      checkReservationsByRouteAndGetRejectedList(tripPoints,
        reservationsFromDispatchers) : [];

    const rejectedReservationsFromDriversByAddOptions =
      checkReservationsByAddOptionsAndGetRejectedList(reservationsFromDrivers,
        needPetOptionCheck, needByFootOptionCheck, true);

    const rejectedReservationsFromDrivers = getMergedReservationsFromTwoArrays(
      rejectedReservationsFromDriversByRoute,
      rejectedReservationsFromDriversByAddOptions);

    const updateResult = await rejectAllUnsuitableReservationsInTrip(tripId,
      companyName, reservationsFromDispatchers, reservationsFromDrivers,
      rejectedReservationsFromDispatchers, rejectedReservationsFromDrivers);

    return updateResult;
  } catch (error) {
    return {
      wasUpdated: false,
      fieldsToUpdate: {},
    }
  }
}

export const cancelAcceptedDeal = async (tripOrder, currentReservation,
  isDispatcher, cancelComment, userId, tripId, updateAfterDealCancellation = () => {}, shouldTripBeUpdated = true) => {
  const currentDate = Date.now();

  const tripOrderForNotepad = {
    ...tripOrder,
    acceptedDeal: {},
    dispatcherFee: 0,
    isActive: false,
    isDraft: true,
    isVisibleForDrivers: false,
    isForChange: false,
    isPaymentOnCard: false,
    isPaymentByCash: false,
    hasAnyReservations: false,
    hasReservationsFromDrivers: false,
    hasReservationsFromDispatchers: false,
    reservationsFromDispatchers: [],
    reservationsFromDrivers: [],
    dateCreating: currentDate,
    dateEditing: currentDate,
  }

  const fieldsToUpdateInTripOrder = {
    acceptedDeal: {
      ...currentReservation,
      isCancelled: true,
      cancellationInitiatorRole: isDispatcher ? 'dispatcher' : 'driver',
      cancellationInitiatorId: userId,
      cancelComment: cancelComment,
    },
    cancelComment: cancelComment,
    cancellationInitiatorRole: isDispatcher ? 'dispatcher' : 'driver',
    isActive: false,
    isArchived: true,
    isCancelled: true,
    completionTime: currentDate,
  }

  await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
    tripOrder.idPost, fieldsToUpdateInTripOrder);

  await setDocumentToCollection(collectionsInterface.tripsOrders,
    tripOrderForNotepad);

  const actualTrip = await getDocInCollection(collectionsInterface.trips,
    tripId);

  let fieldsToUpdateInTrip = {};

  if (shouldTripBeUpdated) {
    const updatedDeals = actualTrip?.acceptedDeals
      ?.map(deal => deal.id !== tripOrder.idPost ? deal : {
        ...deal,
        isCancelled: true,
        cancellationInitiatorRole: isDispatcher ? 'dispatcher' : 'driver',
        cancellationInitiatorId: userId,
        cancelComment: cancelComment,
      });

    const updatedFreePlaces = parseInt(actualTrip.freePlaces) + parseInt(
      tripOrder.requiredNumberOfPlaces);

    fieldsToUpdateInTrip = {
      acceptedDeals: updatedDeals,
      freePlaces: updatedFreePlaces,
    }

    if (!actualTrip?.isFreeSeating) {
      const deal = actualTrip.acceptedDeals
        ?.find(curDeal => curDeal.id === tripOrder.idPost);

      const updatedScheme = {
        ...actualTrip.placesScheme,
        rows: actualTrip.placesScheme.rows.map(curRow => ({
          row: curRow.row
            .map(cell => deal.reservedPlacesNumbers.includes(cell.placeNumber) ? {
              ...cell,
              isOccupied: false,
            } : cell)})),
      }

      fieldsToUpdateInTrip = {
        ...fieldsToUpdateInTrip,
        placesScheme: updatedScheme,
      }
    }

    if (updatedDeals.every(deal => deal.isCancelled)) {
      fieldsToUpdateInTrip = {
        ...fieldsToUpdateInTrip,
        isActive: false,
      }
    }

    await updateFieldsInDocumentInCollection(collectionsInterface.trips,
      tripId, fieldsToUpdateInTrip);
  }

  if (currentReservation.isPaymentOnCard) {
    const amountToUnfreeze = await getMoneyAmountToFreeze(currentReservation
      .dispatcherFee * currentReservation.reservedPlaces);

    await unfreezeFeeAfterDealCancellation(amountToUnfreeze, actualTrip.creatorUid);

    await sendMessageAboutMoneyUnfreezed(actualTrip, tripOrder, amountToUnfreeze);
  }

  if (isDispatcher) {
    await sendMessageAboutTripCancellationByDispatcher(actualTrip, tripOrder);

    updateAfterDealCancellation({
      ...tripOrder,
      ...fieldsToUpdateInTripOrder,
    });
  } else {
    await sendMessageAboutTripCancellationByTransporter(actualTrip, tripOrder);

    updateAfterDealCancellation({
      ...actualTrip,
      ...fieldsToUpdateInTrip,
    }, {
      ...tripOrder,
      ...fieldsToUpdateInTripOrder,
    });
  }
}

// export const checkIsReservationSuitableAfterPetsOptionChangeInTripOrder = (
//   isOriginalTripWithPets, isOriginalTripOrderWithPets, initiatorType) => {
//   const checkResultForUpdatedOrder = checkIsTripSuitableForPassengerByPetsOption(
//     isOriginalTripWithPets, true);
//
//   if (checkResultForUpdatedOrder) {
//     return true;
//   }
//
//   const checkResultForOriginalOrder = checkIsTripSuitableForPassengerByPetsOption(
//     isOriginalTripWithPets, isOriginalTripOrderWithPets);
//
//   return !checkResultForOriginalOrder && (initiatorType ===
//     initiatorTypes.dispatcher);
// }


// export const checkReservationsByPetsOptionAndGetRejectedList = (reservations = []) => {
//   const unsuitableReservations =
//     getUnsuitableReservationsAfterPetsOptionChangeInTripOrder(reservations);
//
//   if (unsuitableReservations.length === 0) {
//     return [];
//   }
//
//   const rejectedReservations = createRejectedReservationsList(
//     unsuitableReservations, 'Поездка больше не подходит пассажиру');
//
//   return rejectedReservations;
// }

export function getReservationsListWithUpdatedOriginalTripOrder(originalOrder,
  initialList) {
  const updatedList = initialList.map(reservation => {
    if (!reservation.isCancelled) {
      return {
        ...reservation,
        originalTripOrder: originalOrder,
      }
    } else {
      return reservation;
    }
  })

  return updatedList;
}

export async function updateOriginalTripOrderInReservationsInTrips(originalOrder,
  changedReservationsList, fieldName) {
  for (const reservation of changedReservationsList) {
    const actualTrip = await getDocInCollection(collectionsInterface.trips,
      reservation.originalTrip.idPost);

    const updatedReservations = actualTrip[fieldName].map(tripRes => tripRes
      .uuid === reservation.uuid ? {
        ...tripRes,
        originalTripOrder: originalOrder,
      } : tripRes);

    await updateFieldsInDocumentInCollection(collectionsInterface.trips,
      actualTrip.idPost, {
        [fieldName]: updatedReservations,
      })
  }
}

export function getReservationsListWithUpdatedOriginalTrip(originalTrip,
  initialList) {
  const updatedList = initialList.map(reservation => {
    if (!reservation.isCancelled) {
      return {
        ...reservation,
        originalTrip,
      }
    } else {
      return reservation;
    }
  })

  return updatedList;
}

export async function updateOriginalTripInReservationsInTripsOrders(originalTrip,
  changedReservationsList, fieldName) {
  for (const reservation of changedReservationsList) {
    const actualTripOrder = await getDocInCollection(collectionsInterface.tripsOrders,
      reservation.originalTripOrder.idPost);

    const updatedReservations = actualTripOrder[fieldName].map(tripRes => tripRes
      .uuid === reservation.uuid ? {
      ...tripRes,
      originalTrip,
    } : tripRes);

    await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
      actualTripOrder.idPost, {
        [fieldName]: updatedReservations,
      })
  }
}

export async function handleOriginalTripInReservationsAfterEditing(updatedTrip) {
  const reservationsFromDispatchersToUpdateOriginalTrip = updatedTrip
    .reservationsFromDispatchers.filter(reservation => !reservation.isCancelled);

  const reservationsFromDriversToUpdateOriginalTrip = updatedTrip
    .reservationsFromDrivers.filter(reservation => !reservation.isCancelled);

  const dataForOriginalTrip = getDataFromTripToSaveInReservation(updatedTrip);

  if (reservationsFromDispatchersToUpdateOriginalTrip.length > 0) {
    updatedTrip.reservationsFromDispatchers =
      getReservationsListWithUpdatedOriginalTrip(dataForOriginalTrip,
        updatedTrip.reservationsFromDispatchers);

    await updateOriginalTripInReservationsInTripsOrders(
      dataForOriginalTrip,
      reservationsFromDispatchersToUpdateOriginalTrip,
      'reservationsFromDispatchers');
  }

  if (reservationsFromDriversToUpdateOriginalTrip.length > 0) {
    updatedTrip.reservationsFromDrivers =
      getReservationsListWithUpdatedOriginalTrip(
        dataForOriginalTrip, updatedTrip.reservationsFromDrivers);

    await updateOriginalTripInReservationsInTripsOrders(dataForOriginalTrip,
      reservationsFromDriversToUpdateOriginalTrip,
      'reservationsFromDrivers');
  }
}

export function checkIsReservationSuitableForExchange(userDebtors, userCreditors, isDispatcher,
  counterpartyId) {
  if (isDispatcher) {
    return userCreditors.some(creditor => creditor.idPost === counterpartyId);
  } else {
    return userDebtors.some(debtor => debtor.idPost === counterpartyId);
  }
}

export function getReservationFromLists(reservationsFromDispatchers,
  reservationsFromDrivers, reservationId) {
  const actualReservation = reservationsFromDispatchers
    .find(reservation => reservation.uuid === reservationId);

  if (actualReservation) {
    return actualReservation;
  }

  return reservationsFromDrivers
    .find(reservation => reservation.uuid === reservationId);
}
