import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import '../styles/FormInputAlertText.scss';
import useTranslation from "../hooks/useTranslation";

const FormInputAlertText = ({
  addClasses = '',
  text = '',
  setIsVisible = () => {},
  visibilityTime = 5
}) => {
  //#region Manage visibility
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, visibilityTime * 1000);
  }, []);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <>
      <div className={`FormInputAlertText ${addClasses}`}>
        {translate(text)}
      </div>
    </>
  );
  //#endregion
};

export default FormInputAlertText;
