import React, {useState} from 'react';
import {
  getPossibleAuthOptionsForEmail,
  validateEmailField,
} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import {authProvidersCheckResultsInterface} from "../utils/models";
import FormInputAlertText from "../components/FormInputAlertText";
import {auth} from '../utils/firebaseConfigAndFunctions';
import {sendPasswordResetEmail} from "firebase/auth";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/RecoverPassword.scss';

const RecoverPassword = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Sign in data and it's update
  const [signInData, setSignInData] = useState({
    rulesAccepted: true,
  });

  const updateSignInData = (fieldName, newValue) => {
    setSignInData(state => ({
      ...state,
      [fieldName]: newValue,
    }))
  };
  //#endregion

  //#region Manage screen content change
  const [isEmailScreen, setIsEmailScreen] = useState(true);
  const [isNotificationScreen, setIsNotificationScreen] = useState(false);
  //#region

  //#region Errors displaying
  const [isEmailFieldError, setIsEmailFieldError] = useState(false);
  const [emailFieldErrorText, setEmailFieldErrorText] = useState('');

  const setErrorTextForEmail = async (email) => {
    const providersResult = await getPossibleAuthOptionsForEmail(email);

    switch (providersResult) {
      case authProvidersCheckResultsInterface.noProviders:
        setIsEmailFieldError(true);
        setEmailFieldErrorText('Пользователь не найден');
        return true;
      case authProvidersCheckResultsInterface.googleCanBeUsed:
        setIsEmailFieldError(true);
        setEmailFieldErrorText(
          'Ошибка! Воспользуйтесь авторизацией через гугл');
        return true;
    }

    return false;
  };
  //#endregion

  //#region Handlers
  const handleSendLinkClick = async () => {
    setIsLoading(true);

    if (!validateEmailField(signInData.email)) {
      setIsEmailFieldError(true);
      setEmailFieldErrorText('Неверный формат почты');

      setIsLoading(false);
      return;
    }

    try {
      const errorResult = await setErrorTextForEmail(signInData.email);

      if (errorResult) {
        return;
      }

      await sendPasswordResetEmail(auth, signInData.email);

      setIsEmailScreen(false);
      setIsNotificationScreen(true);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationOkClick = () => {
    setIsNotificationScreen(false);
    navigate('/login');
  };
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div className={`RecoverPassword ${addClasses}`}>
          <h1 className={'RegularTitle RecoverPassword-Item'}>
            {translate('Смена пароля')}
          </h1>

          {isEmailScreen &&
            <>
              <div className={'RecoverPassword-Item'}>
                <label className={'RecoverPassword-Label'}>
                  {translate('E-mail')}
                </label>

                <div className={'RecoverPassword-InputContainer'}>
                  <img
                    src={'/img/message-icon.svg'}
                    className={'RecoverPassword-InputIcon EnvelopeIcon'}
                    alt={'Envelope icon'}
                  />
                  <input
                    className={'FormControl RecoverPassword-Input'}
                    type={'text'}
                    placeholder={translate('E-mail')}
                    onChange={(event) => updateSignInData('email',
                      event.target.value)}
                    value={signInData.email}
                  />

                  {isEmailFieldError &&
                    <FormInputAlertText
                      text={emailFieldErrorText}
                      setIsVisible={setIsEmailFieldError}
                    />
                  }
                </div>
              </div>

              <div className={'RecoverPassword-Item'}>
                <button
                  type={'button'}
                  onClick={() => handleSendLinkClick()}
                  className={'RegularButton'}
                >
                  {translate('Отправить')}
                </button>
              </div>
            </>
          }

          {isNotificationScreen &&
            <>
              <div className={'RecoverPassword-Item'}>
                <div className={'Notification RecoverPassword-Notification'}>
                  {translate('Вам на почту отправлено письмо с ' +
                    'ссылкой для смены пароля')}
                </div>
              </div>

              <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => handleNotificationOkClick()}
              >
                {translate('Ок')}
              </button>
            </>
          }
        </div>
      }
    </>
  );
  //#endregion
};

export default RecoverPassword;
