import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import PaginationBlock from "./PaginationBlock";
import {entitiesNumberInterface} from "../utils/models";
import useTranslation from "../hooks/useTranslation";
import '../styles/NotificationsList.scss';

const NotificationsList = ({
  addClasses = '',
  notifications = [],
  handleNotificationClick = () => {},
  currentPage = 1,
  setCurrentPage = () => {},
  startPage = 1,
  setStartPage = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Notifications to display
  const [notificationsToDisplay, setNotificationsToDisplay] = useState([]);

  useEffect(() => {
    setNotificationsToDisplay(notifications
      .slice((currentPage - 1) * entitiesNumberInterface.notifications,
        currentPage * entitiesNumberInterface.notifications));
  }, [notifications.length, currentPage]);
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get screens data from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Уведомления');
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      {notifications.length > 0 ?
        <div className={'DataListWithPagination'}>
          <div className={`NotificationsListContainer ${addClasses}`}>
            <div className={`NotificationsList`}>
              {notificationsToDisplay.map((notification) =>
                <React.Fragment
                  key={notification.id}
                >
                  <div
                    className={'NotificationsList-Item'}
                    onClick={() => handleNotificationClick(user, notification)}
                  >
                  <span>
                    {translate(notification.title) || notification.title}
                  </span>

                    {notification.isNew &&
                      <span
                        className={'NotificationsList-NewIndicator NewIndicator'}
                      ></span>
                    }
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>

          {notifications.length > entitiesNumberInterface.notifications &&
            <PaginationBlock
              portionSize={entitiesNumberInterface.notifications}
              listLength={notifications.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
            />
          }
        </div>
        :
        <div className={'NoNotifications'}>
          <p className={'NoNotifications-NoEntriesText'}>
            {translate('У Вас еще нет уведомлений')}
          </p>
        </div>
      }
    </>
  );
  //#endregion
};

export default NotificationsList;
