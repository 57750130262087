import React from 'react';
import {Modal} from "react-bootstrap";
import PlacesSchemeDraw from "../components/PlacesSchemeDraw";
import useTranslation from "../hooks/useTranslation";
import '../styles/ModalShowVehicleScheme.scss';

const ModalShowVehicleScheme = ({showModal, setShowModal, schemeToDisplay}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
        setShowModal(false);
      }}
      className={'ModalShowVehicleScheme'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {translate('Схема ТС')}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <PlacesSchemeDraw
          schemeToDisplay={schemeToDisplay}
          addClasses={'ModalShowVehicleScheme-VehicleBody'}
        />
      </Modal.Body>

      <Modal.Footer>
        <button
          type={'button'}
          className={'RegularButton ModalShowVehicleScheme-Button_Contrast'}
          onClick={() => {
            setShowModal(false)
          }}
        >
          {translate('Вернуться')}
        </button>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalShowVehicleScheme;
