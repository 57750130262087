import React from 'react';
import Loader from "../components/Loader";
import {Modal} from "react-bootstrap";
import {userModel} from "../utils/models";
import useTranslation from "../hooks/useTranslation";

const ModalInviteDriver = ({
  addClasses = '',
  showModal = false,
  isLoading = false,
  driver = userModel,
  confirmInvitation = () => {},
  cancelInvitation = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      onHide={() => cancelInvitation()}
    >
      {isLoading ? <Loader />
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {translate('Приглашение водителя')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className={`${addClasses}`}>
              {translate('Вы приглашаете стать водителем пользователя')}
              <div>
                <strong>
                  {`${driver.fullName}`}
                </strong>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className={'FlexBlock FlexBlock-HorizontalItem_W100'}>
              <button
                type={'button'}
                className={'RegularButton FlexBlock-HorizontalItem_W48'}
                onClick={() => confirmInvitation()}
              >
                {translate('Пригласить')}
              </button>

              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast' +
                  ' FlexBlock-HorizontalItem_W48'}
                onClick={() => cancelInvitation()}
              >
                {translate('Отменить')}
              </button>
            </div>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
  //#endregion
};

export default ModalInviteDriver;
