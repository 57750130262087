import React, {useState} from 'react';
import { FaCopy } from "react-icons/fa";
import ModalNotification from "../modals/ModalNotification";
import useTranslation from "../hooks/useTranslation";
import '../styles/CopyToBufferButton.scss';

const CopyToBufferButton = ({
  addClasses = '',
  textToCopy = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Notification
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Copy handler
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      setIsNotification(true)
      setNotificationMessage('Ссылка скопирована в буфер обмена');

      setTimeout(() => {
        setIsNotification(false)
        setNotificationMessage('');
      }, 1000);
    } catch (err) {
      setIsNotification(true)
      setNotificationMessage('Что-то пошло не так');
      return err;
    }
  };
  //#endregion

  //#region Render
  return (
    <>
      <div
        onClick={handleCopyClick}
        className={`CopyToBufferButton ${addClasses}`}
        title={translate("Копировать")}
      >
        <FaCopy />
      </div>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={'Уведомление'}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default CopyToBufferButton;
