import React, {useState, useEffect, useContext} from 'react';
import {AppContext} from "../context/appContext";
import {
  getTransactionTypeName,
  handleTripClick,
  handleUserClick,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {collectionsInterface, tripModel, tripOrderModel} from "../utils/models";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {useNavigate} from "react-router-dom";
import DisplayTripPointsData from "./DisplayTripPointsData";
import useTranslation from "../hooks/useTranslation";

const TransactionDetails = ({
  addClasses = '',
  transaction,
  isAdmin = false,
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Детали транзакции');
  }, []);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    setCurrentTripOrder,
    setCurrentTrip,
    setCurrentReservation
  } = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!transaction?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Get trip and trip order data
  const [tripOrder, setTripOrder] = useState(tripOrderModel);
  const [trip, setTrip] = useState(tripModel);

  const getTripDataForTransaction = async (transaction) => {
    try {
      const actualTripOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, transaction.dealId);

      if (actualTripOrder) {
        const actualTrip = await getDocInCollection(collectionsInterface.trips,
          actualTripOrder.acceptedDeal?.originalTrip.idPost);

        setTripOrder(actualTripOrder);
        setTrip(actualTrip);
      }
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    if (transaction.idPost) {
      getTripDataForTransaction(transaction).catch(() => {});
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <div
      className={`TransactionDetails ${addClasses}`}
    >
      <div className={'PaddingBlock'}></div>

      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      {isAdmin &&
        <div className={'CreateTripOrder-Item'}>
          <div className={'FlexBlock'}>
            <label className={'DataLabel'}>
              {translate('Id')}
            </label>

            {transaction.idPost}
          </div>
        </div>
      }

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Дата')}
          </label>

          {transaction?.date ? showFormattedToLanguageDate(lang,
            transaction.date) : ''}
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Тип')}
          </label>

          <span>
            {translate(getTransactionTypeName(transaction.type))}
          </span>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Получатель')}
          </label>

          <span
            className={'UserLink'}
            onClick={() => handleUserClick(transaction.recipient.idPost,
              setEditingUser, navigate)}
          >
            {transaction?.recipient?.fullName}
          </span>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Плательщик')}
          </label>

          <span
            className={'UserLink'}
            onClick={() => handleUserClick(transaction.payer.idPost,
              setEditingUser, navigate)}
          >
            {transaction?.payer?.fullName}
          </span>
        </div>
      </div>

      {tripOrder.idPost &&
        <div className={'CreateTripOrder-Item'}>
          <div className={'FlexBlock'}>
            <label className={'DataLabel'}>
              {translate('Поездка')}
            </label>

            <div
              onClick={() => handleTripClick(tripOrder, trip,
                setCurrentReservation, setCurrentTripOrder, setCurrentTrip,
                navigate)}
            >
              <DisplayTripPointsData
                addClasses={'UserLink'}
                tripPoints={[tripOrder.startPoint, tripOrder.endPoint]}
                tripPointsCitiesNames={[tripOrder.startPoint.city,
                  tripOrder.endPoint.city]}
              />
            </div>
          </div>
        </div>
      }

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Сумма')}
          </label>

          <span>
            {transaction.amount}
          </span>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Статус')}
          </label>

          <span>
            {transaction.isSuccessful
              ? translate('Успешная')
              : translate('Отменена')
            }
          </span>
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default TransactionDetails;
