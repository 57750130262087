export const forbiddenToChangeFieldsInReservedTrips = ['isFreeSeating',
  'isPassengerTrip', 'isPlaceForCargo', 'isSeparatePlaceForLuggage',
  'isVisibleForDispatchers', 'startDateWithoutTime', 'pricePerPerson'];

export const defineTripPointIndexInTrip = (tripPoints, pointPlaceId) => {
  const index = tripPoints.findIndex((point) => (
    point.placeId === pointPlaceId));

  return index;
}

export const checkIsTripSuitableForPassengerByDate = (tripStartDate,
  tripOrderStartDate) => {
  return tripStartDate === tripOrderStartDate;
}

export const checkIsTripSuitableForPassengerByType = (isPassengerTrip = true,
  isPassengerTripOrder = true) => {
  return isPassengerTrip === isPassengerTripOrder;
}

export const checkIsTripSuitableForPassengerByRoute = (tripPoints, startPlaceId,
  endPlaceId) => {
  const startPointIndexInTrip = defineTripPointIndexInTrip(tripPoints,
    startPlaceId);

  if (startPointIndexInTrip < 0) {
    return false;
  }

  const endPointIndexInTrip = defineTripPointIndexInTrip(tripPoints,
    endPlaceId);

  if (endPointIndexInTrip < 0) {
    return false;
  }

  if (startPointIndexInTrip >= endPointIndexInTrip) {
    return false;
  }

  return true;
}

export const checkIsTripSuitableForPassengerByPetsOption = (isTripWithPets,
  isTripOrderWithPets) => {
  if (isTripOrderWithPets && !isTripWithPets) {
    return false;
  }

  return true;
}

export const checkIsTripSuitableForPassengerByFootOption = (tripIsByFoot,
  tripOrderIsByFoot) => {
  if (tripIsByFoot && !tripOrderIsByFoot) {
    return false;
  }

  return true;
}

export const checkIsTripSuitableForPassenger = (tripStartDate,
  tripOrderStartDate, tripPoints, startPlaceId, endPlaceId, isPassengerTrip,
  isPassengerTripOrder) => {
  if (!checkIsTripSuitableForPassengerByDate(tripStartDate, tripOrderStartDate)) {
    return {
      isSuitable: false,
      reason: 'date',
    }
  }

  if (!checkIsTripSuitableForPassengerByType(isPassengerTrip, isPassengerTripOrder)) {
    return {
      isSuitable: false,
      reason: 'type',
    }
  }

  if (!checkIsTripSuitableForPassengerByRoute(tripPoints, startPlaceId, endPlaceId)) {
    return {
      isSuitable: false,
      reason: 'route',
    }
  }

  return {
    isSuitable: true,
  }
}

export const checkAreEdgePointsEqual = (initialPoints, newPoints) => {
  const initialStartPointPlaceId = initialPoints[0].placeId;
  const initialEndPointPlaceId = initialPoints[initialPoints.length - 1].placeId;
  const newPointsStartPlaceId = newPoints[0].placeId;
  const newPointsEndPlaceId = newPoints[newPoints.length - 1].placeId;
  const initialStartDate = initialPoints[0].departureDateWithoutTime;
  const newStartDate = newPoints[0].departureDateWithoutTime;

  return (initialStartPointPlaceId === newPointsStartPlaceId &&
    initialEndPointPlaceId === newPointsEndPlaceId &&
    initialStartDate === newStartDate);
}

export const checkIsVehicleWasChanged = (initialVehicle,
  updatedFields) => {
  if (!updatedFields.hasOwnProperty('vehicle')) {
    return false;
  }

  if (initialVehicle.idPost === updatedFields.vehicle.idPost) {
    return false;
  }

  return true;
}

export const checkArePlacesWereChanged = (initialFreePlaces, initialTotalPlaces,
  updatedFields) => {
  if (!updatedFields.hasOwnProperty('totalPlaces')
    && !updatedFields.hasOwnProperty('freePlaces')) {
    return false;
  }

  if (updatedFields.hasOwnProperty('freePlaces') && initialFreePlaces
    !== updatedFields.freePlaces) {
    return true;
  }

  if (updatedFields.hasOwnProperty('totalPlaces') && initialFreePlaces
    !== updatedFields.freePlaces) {
    return true;
  }

  return false;
}

export const checkIsPlacesSchemeWasChanged = (initialScheme, updatedFields) => {
  if (!updatedFields.hasOwnProperty('placesScheme')) {
    return false;
  }

  if (initialScheme.idPost) {
    return true;
  }

  if (!initialScheme.placesScheme && !updatedFields.placesScheme) {
    return false;
  }

  return true;
}

export const checkAreVehicleParamsWereChanged = (initialTrip, updatedFields) => {
  const werePlacesChanged = checkArePlacesWereChanged(initialTrip.freePlaces,
    initialTrip.totalPlaces, updatedFields);

  if (werePlacesChanged) {
    return true;
  }

  const wasSchemeChanged = checkIsPlacesSchemeWasChanged(initialTrip
    .placesScheme, updatedFields);

  return wasSchemeChanged;
}

export const checkIfTripCouldBeChanged = (initialTrip, updatedFields) => {
  const checkIsSomeUpdatedFieldForbidden = Object.keys(updatedFields)
    .some(field => forbiddenToChangeFieldsInReservedTrips.includes(field));

  if (checkIsSomeUpdatedFieldForbidden) {
    return false;
  }

  const areVehicleParamsWereChanged = checkAreVehicleParamsWereChanged(
    initialTrip, updatedFields);

  if (areVehicleParamsWereChanged) {
    return false;
  }

  if (updatedFields.hasOwnProperty('tripPoints')) {
    const pointsCheckResult = checkAreEdgePointsEqual(initialTrip.tripPoints,
      updatedFields.tripPoints);

    if (!pointsCheckResult) {
      return false;
    }
  }

  return true;
}

const checkAreReservationsNeedPetOptionCheck = (initialPetsOption,
  updatedPetsOption) => {

  if (!updatedPetsOption && initialPetsOption) {
    return true;
  }

  return false;
}

const checkAreReservationsNeedByFootOptionCheck = (initialByFootOption,
  updatedByFootOption) => {
  if (!initialByFootOption && updatedByFootOption) {
    return true;
  }

  return false;
}

const checkIsTripShouldAlreadyBeenStarted = (tripStartTime) => {
  const currentTime = Date.now();

  const isAlreadyStarted = tripStartTime < currentTime;

  return isAlreadyStarted;
}

export const checkTripService = {
  checkAreReservationsNeedPetOptionCheck,
  checkAreReservationsNeedByFootOptionCheck,
  checkIsTripShouldAlreadyBeenStarted,
}
