import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {Route, Routes, useNavigate} from "react-router-dom";
import ActiveTripOrderDetails from "./ActiveTripOrderDetails";
import DisplayActiveTrips from "./DisplayActiveTrips";
import PassengersInTripList from "./PassengersInTripList";
import SuitableTripsScreen from "./SuitabeTripsScreen";
import {
  subpagesInterface,
  tripModel,
} from "../utils/models";

const ActiveTripsForDrivers = ({
  addClasses = '',
  suitableTripsForTransfer = [],
  handlePassengerInTripClick = () => {},
  handleTransferPassengerClick = () => {},
}) => {

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Сlear current trip
  useEffect(() => {
    setCurrentTrip(tripModel);
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`ActiveTripForDrivers ${addClasses}`}>

      <Routes>
        <Route
          index
          element={<DisplayActiveTrips />}
        />

        <Route
          path={`${subpagesInterface.passengersList}`}
          element={<PassengersInTripList
            handlePassengerInTripClick={handlePassengerInTripClick}
          />}
        />

        <Route
          path={`/${subpagesInterface.reservationDetails}/*`}
          element={<ActiveTripOrderDetails
            handleTransferPassengerClick={handleTransferPassengerClick}
            isDispatcher={false}
          />}
        />

        <Route
          path={`/${subpagesInterface.suitableTripsForTransfer}/*`}
          element={
            <SuitableTripsScreen
              trips={suitableTripsForTransfer}
              isPassengerTransfer={true}
              isLoading={false}
            />
          }
        />
      </Routes>
    </div>
    </>);
  //#endregion
};

export default ActiveTripsForDrivers;
