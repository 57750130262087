import React from 'react';
import classNames from "classnames";

const RadioFieldSet = ({
  elementName = "",
  elementText = "",
  inputChangeHandler = () => {},
  checkedInput = "",
  classes = "",
  disabled = false,
}) => {
  const choices = elementText.split('_');

  const leftLabelClasses = classNames('RadioFieldSet-Label', {
    "RadioFieldSet-Label_Active": checkedInput === choices[0],
    "RadioFieldSet-Label_Disabled": disabled && checkedInput !== choices[0],
  });
  const rightLabelClasses = classNames('RadioFieldSet-Label', {
    "RadioFieldSet-Label_Active": checkedInput === choices[1],
    "RadioFieldSet-Label_Disabled": disabled && checkedInput !== choices[1],
  });

  //#region Render
  return (
    <fieldset
      name={elementName}
      className={`RadioFieldSet ${classes}`}
    >
      <label
        className={leftLabelClasses}
      >
        {choices[0]}
        <input
          className={'RadioFieldSet-Input'}
          type={"radio"}
          value={choices[0]}
          onChange={(event) => inputChangeHandler(event.target.value,
            elementName)}
          checked={checkedInput === choices[0]}
          disabled={disabled}
        />
      </label>

      <label
        className={rightLabelClasses}
      >
        {choices[1]}
        <input
          className={'RadioFieldSet-Input'}
          type={"radio"}
          value={choices[1]}
          onChange={(event) => inputChangeHandler(event.target.value,
            elementName)}
          checked={checkedInput === choices[1]}
          disabled={disabled}
        />
      </label>
    </fieldset>
  );
  //#endregion
}

export default RadioFieldSet;
