import React, {useEffect, useState, useContext} from 'react';
import {
  getPassengerDepartureText,
  getPaymentBadgeClass,
  getPaymentStatusText,
  getStatusText,
  getStatusTextFromTripOrder,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {
  FaDog,
  FaLuggageCart,
  FaWalking,
} from "react-icons/fa";
import {AiFillSafetyCertificate} from 'react-icons/ai';
import {BiAddToQueue} from 'react-icons/bi';
import {GiBus, GiTruck} from 'react-icons/gi';
import {RiExchangeFill, RiMapPinUserLine} from 'react-icons/ri';
import {AppContext} from "../context/appContext";
import {
  tripModel,
  tripOrderModel,
} from "../utils/models";
import TripStatusBadge from "./TripStatusBadge";
import PaymentStatusBadge from "./PaymentStatusBadge";
import DisplayMessengersRow from "./DisplayMessengersRow";
import {TiCancel} from "react-icons/ti";
import DisplayUserData from "./DisplayUserData";
import DisplayTripPointsData from "./DisplayTripPointsData";
import DisplayVehicleData from "./DisplayVehicleData";
import {getTranslatedLocalityName} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import {indicatorsNewService} from "../utils/indicatorsNewService";

const ReservationCard = ({
  isDispatcher = false,
  reservation,
  handleReservationCardClick = () => {},
  addClasses = '',
  isActive = false,
  tripOrder = tripOrderModel,
  trip = tripModel,
  isPassengerInTrip = false,
  isActiveTrip = false,
  isArchivedTrip = false,
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Badge new
  const [isBadgeNew, setIsBadgeNew] = useState(false);

  useEffect(() => {
    indicatorsNewService.shouldBadgeNewBeOnReservationCard(isDispatcher,
      isActiveTrip, isArchivedTrip, reservation, user.idPost, tripOrder)
      .then(isNew => setIsBadgeNew(isNew));
  }, [reservation.uuid]);
  //#endregion

  //#region Render
  return (
    <div
      onClick={() => {
        handleReservationCardClick(reservation, tripOrder, trip)
      }}
      className={reservation.isCancelled
        ? `TripCard TripCard_Cancelled ${addClasses}`
        : `TripCard ${addClasses}`}
    >
      <div className={'TripCard-Item'}>
        <div className='FlexBlock FlexBlock_JustifyLeft'>
          {trip?.isPassengerTrip ?
            <>
              <div className={'TripCard-HeadBlock TripCard-DateBlock'}>
                {trip?.startPoint?.departureTime ?
                  showFormattedToLanguageDate(lang,
                    trip?.startPoint.departureTime, tripOrder.isArchived)
                    : 'Invalid time'}
              </div>
              <div className={'TripCard-HeadBlock TripCard-PriceBlock'}>
                {`${reservation?.pricePerPerson || ''} ${translate('грн')}`}
              </div>
              <div
                className={'TripCard-HeadBlock TripCard-PlacesAmountBlock'}>
                {`${translate('Мест')}: ${reservation
                    ?.reservedPlaces || ''}`}
              </div>
            </> :
            <>
              <div className={'TripCard-HeadBlock TripCard-DateBlock'}>
                {trip?.startPoint?.departureTime ?
                  showFormattedToLanguageDate(lang,
                    trip?.startPoint.departureTime) : 'Invalid time'}
              </div>

              <div className={'TripCard-HeadBlock TripCard-PriceBlock'}>
                {`${reservation?.pricePerPerson || ''} ${translate('грн')}`}
              </div>
            </>
          }
        </div>

        {tripOrder?.isArchived &&
          <div className='FlexBlock FlexBlock_JustifyLeft'>
            <div className={'TripCard-HeadBlock TripCard-DateBlock'}>
              {tripOrder?.completionTime ?
                showFormattedToLanguageDate(lang, tripOrder?.completionTime,
                  true) : translate('Отменена')}
            </div>
          </div>
        }

        {isBadgeNew &&
          <div
            className={'TripCard-HeadBlock TripCard-BadgeNew'}>
            {'new'}
          </div>
        }
      </div>

      <div className={'TripCard-MainSection'}>
        {isActive &&
          <div className={'TripCard-Item'}>
            <span>
              {`${translate('Дата брони')}: `}
            </span>

            <span className={'TripCard-DateText'}>
              {reservation?.dateCreating ? showFormattedToLanguageDate(lang,
                reservation?.dateCreating, true) : 'Invalid time'}
            </span>
          </div>
        }

        <div className={'TripCard-Item TripCard-RouteBlock'}>
          <DisplayTripPointsData
            tripPoints={[tripOrder?.startPoint, tripOrder?.endPoint]}
          />
        </div>

        {(!reservation.isCancelled && (isActive || isPassengerInTrip)) &&
          <div className={'TripCard-StatusBadge'}>
            <TripStatusBadge
              statusText={isDispatcher ? getStatusTextFromTripOrder(tripOrder)
                : getStatusText(trip?.status, trip)}
            />
          </div>
        }

        {!isDispatcher &&
          <DisplayUserData
            userId={tripOrder.creatorIdPost}
            isFullInfo={false}
            isDriver={false}
          />
        }

        {isActive &&
          <div className={'TripCard-Item'}>
            {tripOrder?.passengerName}
          </div>
        }

        {isPassengerInTrip &&
          <div className={'TripCard-Item'}>
            <div>
              {`${tripOrder?.passengerName}`}
            </div>

            {tripOrder?.passengerPhones?.map((phone, pIndex) => (
              <React.Fragment key={pIndex}>
                <div
                  className='TripDetails-Item'
                >
                  <DisplayMessengersRow
                    phone={phone}
                    isPhoneNumberRefactored={true}
                    isLinkToCall={true}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        }

        {!isPassengerInTrip &&
          <>
            {isDispatcher &&
              <div className={'TripCard-Item TripCard-CompanyNameBlock'}>
                {trip?.companyName || ''}
              </div>
            }

            <div className={'TripCard-Item TripCard-DriversBlock'}>
              {trip?.drivers?.map((driver, dIndex) => (
                isDispatcher ?
                  <React.Fragment key={dIndex}>
                    <DisplayUserData
                      userId={driver.idPost}
                      isFullInfo={false}
                      isFavouriteIconVisible={false}
                      isDriver={true}
                    />
                  </React.Fragment>
                  :
                  <div key={dIndex}
                    className={''}
                  >
                    {driver?.fullName}
                  </div>
              ))}
            </div>

            <div
              className={'TripCard-Item TripCard-Transport'}
            >
              <DisplayVehicleData
                vehicle={trip?.vehicle}
                isFullInfo={false}
              />
            </div>

            <p className={'TripCard-Item TripCard-TripTypeBlock'}>
              {trip?.isPassengerTrip ? translate('Пассажирская') : translate('Грузовая')}
            </p>

            {isActive ? (
              <div
                className={'TripCard-Item FlexBlock FlexBlock_JustifyLeft ' +
                  'TripCard-OptionsBlock'}
              >
                {reservation?.isForChange &&
                  <RiExchangeFill/>
                }

                {reservation?.isPaymentOnCard &&
                  <AiFillSafetyCertificate/>
                }

                {trip?.isByFoot &&
                  <FaWalking/>
                }

                {(trip?.isTripWithPets &&
                    tripOrder?.isTripWithPets) &&
                  <FaDog/>
                }

                {(trip?.isSeparatePlaceForLuggage &&
                    tripOrder?.isSeparatePlaceForLuggage) &&
                  <FaLuggageCart/>
                }

                {trip?.isPassengerAddress &&
                  <RiMapPinUserLine/>
                }

                {trip?.addOptions.length > 0 &&
                  <BiAddToQueue/>
                }
              </div>
            ) : (
              <div
                className={'TripCard-Item FlexBlock FlexBlock_JustifyLeft ' +
                  'TripCard-OptionsBlock'}
              >
                {trip?.isForChange &&
                  <RiExchangeFill/>
                }
              </div>
            )}
          </>
        }

        {tripOrder?.isArchived &&
          <div className={'TripCard-Item TripCard-RelativeBlock'}>
            <span className={'ColoredText'}>
              {`${translate('Сумма выплаты')}: `}
            </span>

            <span>
              {reservation.isCancelled ? 0 : reservation
                .dispatcherFee * reservation.reservedPlaces}
              {` ${translate('грн')}`}
            </span>

            <PaymentStatusBadge
              statusText={getPaymentStatusText(tripOrder, reservation
                ?.isPassengerInOwnTrip)}
              addClasses={`TripCard-PaymentStatusBadge ${getPaymentBadgeClass(
                tripOrder)}`}
            />
          </div>
        }

        {isPassengerInTrip &&
          <>
            <div>
              {getPassengerDepartureText(reservation, trip, translate)}
            </div>
            <div className={'TripCard-AddressText'}>
              {getTranslatedLocalityName(reservation
                ?.passengerDepartureAddress?.fullAddress, lang)}
            </div>
          </>
        }
      </div>

      {reservation.isCancelled ?
        <div className={'TripCard-CancelledTripIcon'}>
          <TiCancel/>
        </div>
        :
        <>
          {(!tripOrder?.isArchived && !isPassengerInTrip) &&
            <div className={'TripCard-TripTypeIcon'}>
              {trip?.isPassengerTrip ? <GiBus/> : <GiTruck/>}
            </div>
          }
        </>
      }
    </div>
  );
  //#endregion
};

export default ReservationCard;
