import React, {useContext, useEffect, useState} from 'react';
import {
  sendMessageAboutAddingToTripAsDriver,
  sendMessageAboutDeletingFromTripAsDriver, sortCardsByDateOfCreation,
} from "../utils/helpers";
import {
  getCollectionWhereKeysValues,
  setDocumentToCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  reservationModel,
  tripModel,
  tripOrderModel,
} from "../utils/models";
import {AppContext} from "../context/appContext";
import CreateTripOrder from "../components/CreateTripOrder";
import AddTrip from "../components/AddTrip";
import DisplayTripOrderDetails from "../components/DisplayTripOrderDetails";
import ReplaceTripOrderScreen from "../components/ReplaceTripOrderScreen";
import DisplayTripDetails from "../components/DisplayTripDetails";
import ReplaceTripScreen from "../components/ReplaceTripScreen";
import {Route, Routes, useNavigate} from "react-router-dom";
import NotePadTabs from "../components/NotePadTabs";
import '../styles/NotePad.scss';
import {isAfter} from "date-fns";
import ModalNotification from "../modals/ModalNotification";
import useTranslation from "../hooks/useTranslation";

const NotePad = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region get User from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    currentTrip,
    setCurrentTrip,
    currentTripOrder,
    setCurrentTripOrder,
    setCurrentReservation,
  } = useContext(AppContext);
  //#endregion

  //#region Get screen managing functions from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Check if trip with past date and show message
  const checkIsDateInPastAndShowMessage = (tripDate) => {
    const today = Date.now();

    if (isAfter(today, tripDate)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Нельзя перенести поездку с прошедшей датой');

      return false;
    }

    return true;
  }
  //#endregion

  //#region Manage displaying screens
  const [activeTabKey, setActiveTabKey] = useState('passengers')

  const handleShowCreateTrip = () => {
    if (activeTabKey === 'passengers') {
      setActiveTabKey('trips')
    }

    setScreenTitle('Создание поездки');
    navigate('createTrip');
  }

  const handleShowCreateTripOrder = () => {
    if (activeTabKey !== 'passengers') {
      setActiveTabKey('passengers')
    }

    setScreenTitle('Создание поездки');
    navigate('createTripOrder');
  }

  const handleShowPublishScreen = () => {
    const checkResult = checkIsDateInPastAndShowMessage(currentTripOrder
      .departureTime);

    if (!checkResult) {
      return;
    }

    if (activeTabKey !== 'passengers') {
      setActiveTabKey('passengers')
    }

    navigate('replaceTripOrder');
  }

  const handleShowTripDetailsScreen = () => {
    if (activeTabKey === 'passengers') {
      setActiveTabKey('trips');
    }

    navigate('tripDetails');
  }

  const handleShowTripsListScreen = () => {
    if (activeTabKey === 'passengers') {
      setActiveTabKey('trips');
    }

    navigate('/notepad');
  }

  const handleShowTripPublishScreen = (setIsLoading = () => {
  }) => {
    const checkResult = checkIsDateInPastAndShowMessage(currentTrip
      .startDate);

    if (!checkResult) {
      return;
    }

    if (activeTabKey === 'passengers') {
      setActiveTabKey('trips');
    }

    setIsLoading(false);
    navigate('replaceTrip');
  }
  //#endregion

  //#region Loaders
  const [isTripsOrdersLoading, setIsTripsOrdersLoading] = useState(true);
  const [isTripsLoading, setIsTripsLoading] = useState(true);
  //#endregion

  //#region Get and manage trips
  const [userTrips, setUserTrips] = useState([]);

  useEffect(() => {
    getCollectionWhereKeysValues(collectionsInterface.trips, [
      {
        key: 'creatorUid',
        value: user.uid,
      },
      {
        key: 'isDraft',
        value: true,
      },
      {
        key: 'isArchived',
        value: false,
      },
    ], 3)
      .then(receivedTrips => setUserTrips(receivedTrips))
      .catch(() => {}).finally(() => {
      setIsTripsLoading(false);
    });
  }, []);
  //#endregion

  //#region Go back handlers from different screens
  const handleGoBackFromCreateTripOrder = () => {
    setCurrentTripOrder(tripOrderModel);
    navigate('/notepad', { replace: true });
  }

  const handleGoBackFromTripOrderDetails = () => {
    setCurrentTripOrder(tripOrderModel);
    navigate('/notepad');
  }

  const handleGoBackFromPublishTripOrder = () => {
    navigate('/notepad', { replace: true });
  }

  const handleGoBackFromCreateTrip = () => {
    setCurrentTrip(tripModel);
    navigate('/notepad', { replace: true });
  }

  const handleGoBackFromTripDetails = () => {
    setCurrentTrip(tripModel);
    navigate('/notepad');
  }

  const handleGoBackFromPublishTrip = () => {
    setCurrentTrip(tripModel);
    navigate('/notepad');
  }
  //#endregion

  //#region Submit add trip
  const submitAddTrip = async (newTripData, isEdit, driversChanges) => {
    try {
      if (isEdit) {
        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          newTripData.idPost, newTripData);

        updateTripsListAfterTripEditing({ ...currentTrip, ...newTripData });

        if (driversChanges && driversChanges.wereDriversChanged) {
          const updatedTrip = {
            ...currentTrip,
            ...newTripData,
          }

          const tripDataForMessage = {
            startPoint: updatedTrip.startPoint,
            endPoint: updatedTrip.endPoint,
            startDate: updatedTrip.startDate,
            driversNames: updatedTrip.drivers.map(driver => driver.fullName),
          }

          driversChanges?.added?.forEach(driver => {
            if (driver.idPost !== user.idPost) {
              sendMessageAboutAddingToTripAsDriver(user?.companyName
                || user.fullName,  tripDataForMessage, {
                idPost: driver.idPost,
                email: driver.email,
              });
            }
          });

          driversChanges?.dismissed?.forEach(driver => {
            if (driver.idPost !== user.idPost) {
              sendMessageAboutDeletingFromTripAsDriver(user?.companyName
                || user.fullName,  tripDataForMessage,{
                idPost: driver.idPost,
                email: driver.email,
              });
            }
          });
        }
      } else {
        const saveResult = await setDocumentToCollection(
          collectionsInterface.trips, newTripData);

        for (let driver of newTripData.drivers) {
          if (driver.idPost !== user.idPost) {
            await sendMessageAboutAddingToTripAsDriver(user
              ?.companyName || user.fullName, {
              startPoint: newTripData.start,
              endPoint: newTripData.endPoint,
              startDate: newTripData.startDate,
              driversNames: newTripData.drivers.map(driver => driver.fullName),
            }, {
              idPost: driver.idPost,
              email: driver.email,
            });
          }
        }

        updateTripsListAfterTripAdding({
          ...newTripData,
          idPost: saveResult.result.id,
        });
      }

      handleGoBackFromCreateTrip();
    } catch (error) {
      return error;
    }

    handleGoBackFromCreateTripOrder();
  }
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Блокнот');
  }, []);
  //#endregion

  //#region Handle trip order card click
  const handleTripOrderCardClick = (tripOrder) => {
    setCurrentTripOrder(tripOrder);
    navigate('displayTripOrderDetails');
  }
  //#endregion

  //#region Cet trip orders from DB and set to state
  const [tripsOrdersToDisplay, setTripsOrdersToDisplay] = useState([]);

  useEffect(() => {
    getCollectionWhereKeysValues(collectionsInterface.tripsOrders, [
      {
        key: 'creatorIdPost',
        value: user.idPost,
      },
      {
        key: 'isDraft',
        value: true,
      },
      {
        key: 'isArchived',
        value: false,
      },
    ], 3)
      .then(orders => setTripsOrdersToDisplay(orders))
      .catch(() => {}).finally(() => {
      setIsTripsOrdersLoading(false);
    });
  }, []);
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Clear data for current tripOrder, trip, reservation
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
    setCurrentReservation(reservationModel);
  }, []);
  //#endregion

  //#region Update tripOrders and trips lists after actions
  const updateTripOrdersListAfterTripOrderAdding = (tripOrder) => {
    setTripsOrdersToDisplay(orders => [...orders, tripOrder]);
  }

  const updateTripOrdersListAfterTripOrderEditing = (tripOrder) => {
    setTripsOrdersToDisplay(orders => orders
      .map(order => order.idPost === tripOrder.idPost ? tripOrder : order));
  }

  const updateTripsOrdersAfterDelete = (tripOrderId) => {
    setTripsOrdersToDisplay(orders => orders
      .filter(order => order.idPost !== tripOrderId));
  }

  const updateTripsListAfterTripAdding = (trip) => {
    setUserTrips(trips => [...trips, trip]);
  }

  const updateTripsListAfterTripEditing = (trip) => {
    setUserTrips(trips => trips
      .map(curTrip => curTrip.idPost === trip.idPost ? {
        ...curTrip,
        ...trip,
      } : curTrip));
  }

  const updateTripOrdersListAfterTripOrderReplacing = (tripOrder) => {
    setTripsOrdersToDisplay(orders => orders
      .filter(order => order.idPost !== tripOrder.idPost));
  }

  const updateTripsListAfterTripReplacing = (trip) => {
    setUserTrips(trips => trips.filter(curTrip => curTrip
      .idPost !== trip.idPost));
  }

  const updateTripsAfterTripDelete = (tripId) => {
    setUserTrips(trips => trips.filter(curTrip => curTrip
      .idPost !== tripId));
  }
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={'NotePad'}>
        <h2 className={'RegularTitle NotePad-Title'}>
          {translate('Блокнот')}
        </h2>

        <div className={'NotePad-Content'}>
          <Routes>
            <Route
              index
              element={
                <NotePadTabs
                  tripsOrdersToDisplay={sortCardsByDateOfCreation(
                    tripsOrdersToDisplay, false)}
                  handleShowCreateTripOrder={handleShowCreateTripOrder}
                  handleShowCreateTrip={handleShowCreateTrip}
                  userTrips={sortCardsByDateOfCreation(userTrips, false)}
                  handleShowTripDetailsScreen={handleShowTripDetailsScreen}
                  handleTripOrderCardClick={handleTripOrderCardClick}
                  activeTabKey={activeTabKey}
                  setActiveTabKey={setActiveTabKey}
                  isTripOrdersLoading={isTripsOrdersLoading}
                  setIsTripOrdersLoading={setIsTripsOrdersLoading}
                  isTripsLoading={isTripsLoading}
                />
              }
            />

            <Route
              path={'createTripOrder'}
              element={
                <CreateTripOrder
                  isNotepad={true}
                  handleGoBack={handleGoBackFromCreateTripOrder}
                  addClasses={'NotePad-CreateTripOrder'}
                  updateTripOrdersListAfterTripOrderAdding={
                  updateTripOrdersListAfterTripOrderAdding}
                  updateTripOrdersListAfterTripOrderEditing={
                  updateTripOrdersListAfterTripOrderEditing}
                />
              }
            />

            <Route
              path={'displayTripOrderDetails'}
              element={
                <DisplayTripOrderDetails
                  addClasses={'App-Screen_Narrow'}
                  handleShowCreateClick={handleShowCreateTripOrder}
                  handleShowPublishScreen={handleShowPublishScreen}
                  isNotePad={true}
                  handleGoBack={handleGoBackFromTripOrderDetails}
                  updateTripsOrdersAfterDelete={updateTripsOrdersAfterDelete}
                />
              }
            />

            <Route
              path={'replaceTripOrder'}
              element={
                <ReplaceTripOrderScreen
                  handleGoBack={handleGoBackFromPublishTripOrder}
                  updateTripOrdersListAfterTripOrderReplacing={
                    updateTripOrdersListAfterTripOrderReplacing}
                />}
            />

            <Route
              path={'createTrip'}
              element={<AddTrip
                completeExternalSubmitAddTripActions={submitAddTrip}
                isCreator={currentTrip.creatorUid === '' || currentTrip
                  .creatorUid === user.uid}
                handleShowTripsListScreen={handleShowTripsListScreen}
                handleGoBack={handleGoBackFromCreateTrip}
                addClasses={'App-Screen App-Screen_Narrow'}
                user={user}
              />}
            />

            <Route
              path={'tripDetails'}
              element={<DisplayTripDetails
                isNotePad={true}
                isReadOnly={false}
                handleShowAddTripScreen={handleShowCreateTrip}
                handleShowTripPublishScreen={handleShowTripPublishScreen}
                handleGoBack={handleGoBackFromTripDetails}
                addClasses={'App-Screen App-Screen_Narrow'}
                updateTripsAfterDelete={updateTripsAfterTripDelete}
              />}
            />

            <Route
              path={'replaceTrip'}
              element={
                <ReplaceTripScreen
                  handleGoBack={handleGoBackFromPublishTrip}
                  updateTripsListAfterTripReplacing={
                    updateTripsListAfterTripReplacing}
                  addClasses={'App-Screen App-Screen_Narrow'}
                />
              }
            />
          </Routes>
        </div>
      </div>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default NotePad;
