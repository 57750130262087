import React, {useState, useContext, useEffect, useRef} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  adminDataTypesInterface,
  collectionsInterface,
  pagesInterface,
  rolesInterface,
  textPageModel,
  textPagesTypesInterface,
  textPagesTypesNamesInterface
} from "../utils/models";
import {
  createImageFromBase64Text,
  transformPageTypesToSelectOptions
} from "../utils/helpers";
import {FaPlus, FaTrash} from "react-icons/fa";
import {
  deleteFileFromStorage,
  getCollectionWhereKeysValues,
  setDocumentToCollection,
  updateDocumentInCollection,
  uploadFileToStorageAndGetLink
} from "../utils/firebaseConfigAndFunctions";
import Select from "react-select";
import ModalNotification from "../modals/ModalNotification";
import Loader from "./Loader";
import {DEFAULT_LANG} from "../utils/data";
import {languageService} from "../utils/languageService";
import EditTextPage from "./EditTextPage";
import useTranslation from "../hooks/useTranslation";
import '../styles/CreateTextPage.scss';

const CreateTextPage = ({addClasses = '', page = textPageModel}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get language from context, manage languages
  const {lang, languages} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }

    setScreenTitle(page?.idPost ? 'Редактирование страницы' : 'Добавление страницы');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Loader
  const [isLoader, setIsLoader] = useState(false);
  //#endregion

  //#region Page data
  const [pageData, setPageData]= useState(page);
  const [pageLang, setPageLang] = useState(lang || DEFAULT_LANG);
  const [initialImages, setInitialImages] = useState([]);
  const [addedImages, setAddedImages] = useState([]);

  const updatePageData = (fieldName, newValue) => {
    if (fieldName === 'text' || fieldName === 'title') {
      setPageData(data => ({
        ...data,
        [fieldName]: {
          ...data[fieldName],
          [pageLang]: newValue,
        }
      }));
    } else {
      setPageData(data => ({
        ...data,
        [fieldName]: newValue,
      }));
    }
  }

  useEffect(() => {
    if (page.idPost) {
      setInitialImages(page?.images || [])
    }
  }, [page]);
  //#endregion

  //#region Work with images
  const inputRef = useRef(null);

  const uploadImage = (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setAddedImages(state => ([
          ...state,
          {
            type: image.type,
            url: reader.result,
          }
        ]));
      },
      false
    );

    reader.readAsDataURL(image);
  }

  const handleAddImageClick = () => {
    inputRef.current.click();
  }

  const deleteImage = (image) => {
    setInitialImages(state => state.filter(img => img.url !== image.url));
    setAddedImages(state => state.filter(img => img.url !== image.url));
  }

  const getImagesList = (initialImages = [], addedImages = []) => {
    return [...initialImages, ...addedImages];
  }

  const getPageText = (pageLang) => {
    return pageData.text[pageLang] || '';
  }
  //#endregion

  //#region Save page data to DB
  const savePageData = async (pageData, initialImages, addedImages) => {
    setIsLoader(true);

    if (!pageData.type) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Необходимо выбрать тип страницы');

      setIsLoader(false);
      return;
    }

    try {
      let updatedImages = [];

      let newPageData = {};

      if (page.idPost) {
        const updatedAddedImages = [];

        for (const image of addedImages) {
          const imageToSave = createImageFromBase64Text(image.url);

          const linkToStorage = await uploadFileToStorageAndGetLink(imageToSave,
            `/textPagesImages`);

          updatedAddedImages.push({
            type: image.type,
            url: linkToStorage,
          });
        }

        updatedImages = [...initialImages, ...updatedAddedImages];

        if (initialImages.length < page.images.length) {
          for (const image of page.images) {
            if (!updatedImages.find(img => img.url === image.url)) {
              await deleteFileFromStorage(image.url);
            }
          }
        }

        newPageData = {
          ...pageData,
          images: updatedImages,
          dateEditing: Date.now(),
        }

        await updateDocumentInCollection(collectionsInterface.adminData,
          newPageData, page.idPost)
      } else {
        try {
          const pagesOfChosenType = await getCollectionWhereKeysValues(
            collectionsInterface.adminData, [
              {
                key: 'dataType',
                value: adminDataTypesInterface.textPages,
              },
              {
                key: 'type',
                value: pageData.type,
              },
            ], 2);

          if (pagesOfChosenType.length > 0) {
            setIsNotification(true);
            setNotificationTitle('Уведомление');
            setNotificationMessage('Страница такого типа уже существует');

            setIsLoader(false);
            return;
          }
        } catch (error) {
          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Ошибка');

          setIsLoader(false);
          return;
        }

        for (const image of addedImages) {
          const imageToSave = createImageFromBase64Text(image.url);

          const linkToStorage = await uploadFileToStorageAndGetLink(imageToSave,
            `/textPagesImages`);

          updatedImages.push({
            type: image.type,
            url: linkToStorage,
          });
        }

        newPageData = {
          ...textPageModel,
          ...pageData,
          dataType: adminDataTypesInterface.textPages,
          images: updatedImages,
          dateCreating: Date.now(),
        }

        await setDocumentToCollection(collectionsInterface.adminData,
          newPageData);
      }

      setIsLoader(false);
      navigate(`/${pagesInterface.textPages}`);

      return true;
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Произошла ошибка, данные не сохранены')

      setIsLoader(false);
      return error;
    }
  };
  //#endregion

  //#region Render
  return (
    <div className={`CreateTextPage ${addClasses}`}>
      {isLoader ? <Loader/>
        :
        <>
          <div className={'PaddingBlock'}></div>

          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <h6>
            {translate('Название страницы')}
          </h6>

          <div className={'FlexBlock'}>
            <input
              type={'text'}
              className={'FormControl FlexBlock-HorizontalItem_W50'}
              onChange={(event) => updatePageData('title',
                event.target.value)}
              value={pageData?.title[pageLang] || ''}
            />

            <div className={'FlexBlock-HorizontalItem_W28'}>
              <Select
                placeholder={translate('Тип страницы')}
                classNames={{
                  control: (state) =>
                    state.isFocused ? 'FormControl AddTrip-Select'
                      : 'FormControl AddTrip-Select',
                }}
                options={transformPageTypesToSelectOptions(
                  textPagesTypesInterface, textPagesTypesNamesInterface,
                  translate)}
                value={{
                  label: textPagesTypesNamesInterface[pageData.type]
                    ? translate(textPagesTypesNamesInterface[pageData.type])
                    : translate('Тип страницы'),
                  value: pageData.type || '',
                }}
                onChange={(event) => updatePageData('type',
                  event?.value || '')}
                isDisabled={!!page?.idPost}
              />
            </div>

            <div className={'FlexBlock-HorizontalItem_W20'}>
              <Select
                id={'PageLang'}
                classNames={{
                  control: () => 'FormControl AddTripForm-Select'
                }}
                options={languageService.transformLanguagesToSelectOptions(
                  languages)}
                placeholder={translate('Язык')}
                onChange={(event) => {setPageLang(event?.value?.name
                  || DEFAULT_LANG)}}
                value={pageLang ? languageService
                  .getLanguageObjectForSelect(pageLang, languages) : ''}
              />
            </div>
          </div>

          <div className={'PaddingBlock'}></div>

          <EditTextPage
            pageLang={pageLang}
            pageText={getPageText(pageLang)}
            updatePageText={updatePageData}
          />

          <div className={'PaddingBlock'}></div>

          <h6>
            {translate('Фото (размер 400х400)')}
          </h6>

          <input
            type={'file'}
            className={'Hidden'}
            onChange={(event) => uploadImage(event)}
            ref={inputRef}
          />

          <div className={'CreateTextPage-ImagesList'}>
            {getImagesList(initialImages, addedImages).map((image, index) =>
              <React.Fragment key={index}>
                <div className={'CreateTextPage-ImageContainer'}>
                  <img
                    src={image.url}
                    alt={'Image for page'}
                    className={'CreateTextPage-Image'}
                  />

                  <div
                    className={'CreateTextPage-DeleteImage'}
                    onClick={() => deleteImage(image)}
                  >
                    <FaTrash/>
                  </div>
                </div>
              </React.Fragment>)
            }

            <div
              className={'CreateTextPage-AddImage'}
              onClick={() => handleAddImageClick()}
            >
              <FaPlus/>
            </div>
          </div>

          <div className={'PaddingBlock'}></div>

          <button
            type={'button'}
            className={'RegularButton'}
            onClick={() => savePageData(pageData, initialImages, addedImages)}
          >
            {translate('Сохранить')}
          </button>

          <div className={'PaddingBlock'}></div>
        </>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </div>
  );
  //#endregion
};

export default CreateTextPage;
