import React, {useContext} from 'react';
import {pagesInterface, userModel} from "../utils/models";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {MdOutlineSlideshow} from "react-icons/md";
import ReactTable from "react-table-v6";
import useTranslation from "../hooks/useTranslation";

const UserVehiclesForAdmin = ({
  addClasses = '',
  user = userModel,
  setCurrentVehicle = () => {},
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Handle vehicle click
  const handleVehicleClick = (vehicle) => {
    setCurrentVehicle(vehicle);

    navigate(`/${pagesInterface.usersManagement}/vehicle`);
  }
  //#endregion

  //#region Data for React table
  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'brand',
      Header: translate('Марка'),
      accessor: e => {
        return <div
          className='UsersTable-Cell'
        >
          {e.brand?.defaultName || translate('Не указана')}
        </div>
      }
    },
    {
      id: 'model',
      Header: 'Модель',
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.model?.defaultName || translate('Не указана')
        }
      </div>
    },
    {
      id: 'bodyType',
      Header: translate('Тип кузова'),
      accessor: e => <div
        className={'UsersTable-Cell'}
      >
        {e.bodyType ?
          (e.bodyType[lang] || e.bodyType?.defaultName)
          :
          translate('Не указан')
        }
      </div>
    },
    {
      id: 'country',
      Header: translate('Страна'),
      accessor: e => <div
        className={`UsersTable-Cell`}
      >
        {e.registrationCountry ?
          (e.registrationCountry[lang] || e.registrationCountry?.alpha2)
          :
          translate('Не указана')
        }
      </div>
    },
    {
      id: 'licensePlate',
      Header: translate('Номер'),
      accessor: e => <div
        className={`UsersTable-Cell`}
      >
        {e.licensePlate ?
          e.licensePlate
          :
          translate('Не указан')
        }
      </div>
    },
    {
      id: 'edit',
      width: 40,
      Header: '',
      accessor: e => <div className='UsersTable-Cell'>{
        <div className={'FlexBlock FlexBlock_SpaceAround'}>
          <button
            type={'button'}
            className={'ShowButton'}
            onClick={() => handleVehicleClick(e)}
          >
            <MdOutlineSlideshow/>
          </button>
        </div>}
      </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <div className={`UserVehiclesForAdmin ${addClasses}`}>
      <div className={'PaddingBlock'}></div>

      <div
        className={'DataLabel DataLabel_Centered'}
      >
        {user?.fullName}
      </div>

      <div className={'PaddingBlock'}></div>

      <ReactTable
        data={user.vehicles || []}
        columns={columns}
        defaultPageSize={10}
        showPaginationTop={false}
        showPaginationBottom={false}
      />
    </div>
  );
  //#endregion
};

export default UserVehiclesForAdmin;
