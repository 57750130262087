import React, {useEffect, useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../context/appContext";
import {
  textPagesTypesInterface,
} from "../utils/models";
import Loader from "./Loader";
import {textPagesDisplayingService} from "../utils/textPagesDisplayingService";

const RefProgramDescription = ({addClasses = ''}) => {
  //#region get User from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get language from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('О реферальной программе');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get text from DB and set to state
  const [programText, setProgramText] = useState('');

  useEffect(() => {
    textPagesDisplayingService.getTranslatedPageData(textPagesTypesInterface
      .referralProgram, lang).then(pageData => {
      setProgramText(pageData?.text || '');
      setScreenTitle(pageData.title || '');
    })
      .catch(() => {}).finally(() => setIsLoading(false));
  }, [lang]);
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {screenTitle}
          </h2>

          <div
            className={'RefProgramDescription'}
            dangerouslySetInnerHTML={{__html: programText}}
          />
        </div>
      }
    </>
  );
  //#endregion
};

export default RefProgramDescription;
