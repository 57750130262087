import React from 'react';

const NumberInputWithValidation = ({
  addClasses = '',
  externalChangeHandler = () => {},
  fieldName = '',
  placeholder = '',
  value = null,
  isRequired = true,
  id = '',
  name = '',
  disabled = false,
}) => {
  //#region Validate value and handle input change
  const handleInputChange = (newValue) => {
    const allowedSymbols = new RegExp(/^\d+$/u);

    if (!newValue) {
      externalChangeHandler(fieldName, '');

      return;
    }

    if (!allowedSymbols.test(newValue)) {
      externalChangeHandler(fieldName, value || '');

      return;
    }

    if (newValue && newValue.toString()[0] === '-') {
      const updatedValue = Number(newValue.toString().slice(1));

      externalChangeHandler(fieldName, Number(updatedValue));
    } else {
      externalChangeHandler(fieldName, Number(newValue));
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      <input
        type={'text'}
        onChange={(event) => handleInputChange(event.target.value)}
        placeholder={placeholder}
        className={`FormControl ${addClasses}`}
        value={value}
        required={isRequired}
        id={id}
        name={name}
        disabled={disabled}
      />
    </>
  );
  //#endregion
};

export default NumberInputWithValidation;
