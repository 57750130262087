import React, {useEffect, useState, useContext} from 'react';
import {AppContext} from "../context/appContext";
import {
  collectionsInterface,
  companyDriverModel,
  tripModel,
  tripPointModel,
  tripStatusesInterface as tripsStatusesInterface,
  tripStatusesInterface,
  userModel,
} from "../utils/models";
import {
  checkTripCompleteness,
  checkTripPoint,
  getHoursAndMinutesFromTime,
  getTripAddOptionsList,
  handleUserClick,
  showFormattedToLanguageDate,
  transformDriversToSelectOptions,
  transformStringsArrayToSelectOptions,
  transformVehiclesToSelectOptions,
  validateDriversAndVehicles,
} from "../utils/helpers";
import {
  getCollection,
  getCollectionWhereKeysValuesWithOperatorOrderedAndLimited,
  getDocInCollection,
} from "../utils/firebaseConfigAndFunctions";
import TripPoint from "./TripPoint";
import RadioFieldSet from "../elements/RadioFieldSet";
import Select from "react-select";
import PlacesInTripEditing from "./PlacesInTripEditing";
import DisplayMessengersRow from "./DisplayMessengersRow";
import ModalNotification from "../modals/ModalNotification";
import {useNavigate} from "react-router-dom";
import ModalAddDriverAddress from "../modals/ModalAddDriverAddress";
import Loader from "./Loader";
import {isAfter} from "date-fns";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import {compareDriversForTrip} from "../utils/comareTripStatesService";
import {checkIfTripCouldBeChanged} from "../utils/checkTripService";
import PlacesInTripDisplaying from "./PlacesInTripDisplaying";
import {
  getPointForDbFromLocationIqPlace,
  getTranslatedLocalityName,
  getTripPointsWithTranslation
} from "../utils/placesService";
import '../styles/AddTrip.scss';
import useTranslation from "../hooks/useTranslation";

const AddTrip = ({
  addClasses = '',
  completeExternalSubmitAddTripActions = () => {},
  isCreator = false,
  isNotePad = true,
  user = userModel,
  isAdmin = false,
}) => {
  //#region Get language from context, manage languages
  const {lang, languages} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip, setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    const currentTitle = currentTrip?.idPost ? 'Редактирование поездки'
      : 'Создание поездки';

    setScreenTitle(currentTitle);
  }, []);
  //#endregion

  //#region Data variables for trip
  const [newTripData, setNewTripData] = useState(currentTrip);
  const [updatedFields, setUpdatedFields] = useState({});
  const [tripPointsNames, setTripPointsNames] = useState(currentTrip
    .tripPointsNames.length > 0 ? currentTrip.tripPointsNames
    : ['startPoint', 'endPoint']);
  const [driversList, setDriversList] = useState([]);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationData, setNotificationData] = useState('');
  //#endregion

  //#region Get addOptions for trip
  const [tripAddOptions, setTripAddOptions] = useState([]);

  useEffect(() => {
    let currentOptions = [];

    getCollection(collectionsInterface.tripAddOptionsList)
      .then(result => {
        currentOptions = [...result[0].options];
        setTripAddOptions(getTripAddOptionsList(currentTrip, currentOptions));
      }).catch(() => {});
  }, []);
  //#endregion

  //#region Handle trip points count
  const findLargestTripPointNameIndex = () => {
    if (tripPointsNames.length < 3) {
      return 0;
    }

    const tripPointsNamesWithoutEdgePoints = tripPointsNames.slice(1,
      tripPointsNames.length - 1);
    const tripPointsOnlyNumbers = tripPointsNamesWithoutEdgePoints.map(
      point => point.slice(17));
    const largestIndex = tripPointsOnlyNumbers.sort((a, b) => b - a)[0];

    return Number(largestIndex) + 1;
  };

  const [countPoints, setCountPoints] = useState(
    findLargestTripPointNameIndex());
  //#endregion

  //#region Check if company has active drivers
  const checkIsCompanyHasDrivers = () => {
    const activeDrivers = user?.companyDrivers
      ?.filter(driver => driver.isFulfilledRegistration);

    if (activeDrivers.length > 0 && newTripData
      ?.drivers?.length === 1) {
      return true;
    }

    return false;
  }
  //#endregion

  //#region Set initial drivers list
  useEffect(() => {
    const userAsDriver = {
      email: user?.email,
      fullName: user?.fullName,
      isFulfilledRegistration: true,
      phoneNumbers: user?.phoneNumbers || [],
      supposedEmail: user?.email,
      uid: user.uid,
      idPost: user.idPost,
    };

    if (newTripData?.drivers?.length === 0) {
      const defaultDrivers = [userAsDriver];
      updateTrip('drivers', defaultDrivers, true);
    }

    setDriversList([...user.companyDrivers
      .filter(driver => driver.isFulfilledRegistration), userAsDriver]);
  }, []);
  //#endregion

  //#region Handle trip data update
  const updateTrip = (fieldName, newValue, isInitial = false) => {
    setNewTripData(state => ({
      ...state,
      [fieldName]: newValue,
    }));

    if (newTripData.idPost && !isInitial) {
      setUpdatedFields(state => ({
        ...state,
        [fieldName]: newValue,
      }));
    }
  }

  const handleChangeDriver = (fieldName, newValue, index) => {
    const newDriversArray = newTripData.drivers.map((driver, dIndex) =>
      index === dIndex ? newValue : driver);

    updateTrip(fieldName, newDriversArray);
  }

  const changeTripTypeHandler = (newValue, fieldName) => {
    const tripType = newValue === translate('Пассажирская');

    updateTrip(fieldName, tripType);

    if (!tripType) {
      updateTrip('isFreeSeating', true);
      updateTrip('placesScheme', '');
      updateTrip('totalPlaces', 0);
      updateTrip('freePlaces', 0);
    } else {
      updateTrip('totalPlaces', newTripData?.vehicle?.placesNumber || 0);
      updateTrip('freePlaces', newTripData?.vehicle?.placesNumber || 0);
    }
  }

  const changeIsPlaceForCargoHandler = (newValue, inputName) => {
    const isPlaceForCargo = newValue === translate('Да');

    updateTrip(inputName, isPlaceForCargo);
  }

  const changePlacingHandler = (newValue, inputName) => {
    const isFree = newValue === translate('Свободная');

    let placesScheme = [];

    if (isFree) {
      placesScheme = '';

      updateTrip('freePlaces', newTripData.vehicle.placesNumber);
    } else {
      placesScheme = newTripData.vehicle.placesScheme;
    }

    updateTrip(inputName, isFree);

    updateTrip('vehiclePlacesScheme', placesScheme);
  }

  const addNewTripPointHandler = (index) => {
    setCountPoints(state => state + 1);
    const currentTripPointsNames = [...tripPointsNames];
    currentTripPointsNames.splice(index + 1, 0,
      ('intermediatePoint' + countPoints));
    setTripPointsNames([...currentTripPointsNames]);

    const newTripPoints = [...newTripData.tripPoints];
    newTripPoints.splice(index + 1, 0, { ...tripPointModel });

    updateTrip('tripPoints', newTripPoints);
  }

  const deleteTripPointHandler = (index) => {
    const currentTripPointsNames = [...tripPointsNames];
    currentTripPointsNames.splice(index, 1);
    setTripPointsNames([...currentTripPointsNames]);

    const newTripPoints = [...newTripData.tripPoints];
    newTripPoints.splice(index, 1);

    updateTrip('tripPoints', [...newTripPoints]);
  }

  const changePlaceInputHandler = (newPlace, index) => {
    const newTripPoints = [...newTripData.tripPoints];
    const placeForDb = getPointForDbFromLocationIqPlace(newPlace, lang);

    newTripPoints.splice(index, 1, {
      ...newTripData.tripPoints[index],
      ...placeForDb,
    });

    updateTrip('tripPoints', newTripPoints);
  };

  const changeDateInputHandler = (newValue, index, typeOfDate = 'departure') => {
    const newTripPoints = [...newTripData.tripPoints];

    const chosenDate = new Date(newValue).getTime();

    if (typeOfDate === 'arriving') {
      newTripPoints.splice(index, 1, {
        ...newTripPoints[index],
        arrivingTime: chosenDate,
      });
    } else {
      newTripPoints.splice(index, 1, {
        ...newTripPoints[index],
        departureDateWithoutTime: chosenDate,
      });
    }

    updateTrip('tripPoints', [...newTripPoints]);
  }

  const changeTimeInputHandler = (newValue, index, typeOfDate = 'departure') => {
    const newTripPoints = [...newTripData.tripPoints];

    if (typeOfDate === 'arriving') {
      newTripPoints.splice(index, 1, {
        ...newTripPoints[index],
        arrivingTime: newValue,
      });
    } else {
      newTripPoints.splice(index, 1, {
        ...newTripPoints[index],
        departureTimeWithoutDate: newValue,
      });
    }

    updateTrip('tripPoints', [...newTripPoints]);
  }

  const addDriverButtonClickHandler = (tripData) => {
    const newDriversArray = [...tripData.drivers, companyDriverModel];

    updateTrip('drivers', newDriversArray);
  }

  const deleteDriverButtonClickHandler = (tripData) => {

    const newDriversArray = [tripData.drivers[0]];

    updateTrip('drivers', newDriversArray);
  }

  const handleAddOptionClick = (index, checked) => {
    let newOptions = [];

    newOptions = tripAddOptions.map((curOption, curIndex) =>
      curIndex !== index ? curOption : {
      ...curOption,
      checked: checked
    });

    setTripAddOptions(newOptions);

    const optionsForTrip = newOptions.filter(option => option.checked);

    updateTrip('addOptions', optionsForTrip);
  }

  const updateBoardingAddresses = async (boardingPlaces, boardingPlacesNames) => {
    updateTrip('boardingPlacesFromDriver', boardingPlaces);
    updateTrip('boardingPlacesNames', boardingPlacesNames);

    // const places = [];

    // for (const place of boardingPlaces) {
    //   const currentPlace = (await getLocationIqPlaceDetails(place.lat, place.lon,
    //     lang, placesTypes.building)) || place;
    //
    //   places.push({
    //     placeId: currentPlace?.placeId,
    //     lat: currentPlace?.lat,
    //     lon: currentPlace?.lon,
    //     fullAddress: currentPlace?.fullAddress || 'Not found',
    //   });
    // }

    setBoardingPlacesFromDriver(boardingPlaces);
  }

  const getVehicleNearestTrip = async (vehicleId) => {
    const today = new Date().setHours(0, 0, 0, 0);

    const pastTrips = await getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
      collectionsInterface.vehiclesTrips, [
        {
          key: 'vehicleId',
          value: vehicleId,
          operator: '==',
        },
        {
          key: 'startDate',
          value: today,
          operator: '<=',
        },
      ], 'startDate', false, 1);

    if (pastTrips.length > 0) {
      const tripInProgress = pastTrips.find(trip => trip.endDate >= today);

      if (tripInProgress) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('ТС находится в поездке');
        setNotificationData(`: ${getTranslatedLocalityName(tripInProgress
          ?.startPoint?.city, lang)} -> ${getTranslatedLocalityName(
            tripInProgress?.endPoint?.city, lang)} | ${tripInProgress?.startDate 
          ? showFormattedToLanguageDate(lang, tripInProgress?.startDate) 
          : 'Invalid date'} -> ${tripInProgress?.endDate 
          ? showFormattedToLanguageDate(lang, tripInProgress?.endDate) : 'Invalid date'}`);

        return;
      }
    }

    const trips = await getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
      collectionsInterface.vehiclesTrips, [
        {
          key: 'vehicleId',
          value: vehicleId,
          operator: '==',
        },
        {
          key: 'startDate',
          value: today,
          operator: '>=',
        },
      ], 'startDate', true, 1);

    if (trips.length > 0) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Ближайшая поездка ТС');
      setNotificationData(`: ${getTranslatedLocalityName(trips[0]
        .startPoint.city, lang)} -> ${getTranslatedLocalityName(trips[0]
        .endPoint.city, lang)} | ${trips[0]?.startDate 
        ? showFormattedToLanguageDate(lang, trips[0]?.startDate) 
        : 'Invalid date'} -> ${trips[0]?.endDate 
        ? showFormattedToLanguageDate(lang, trips[0]?.endDate) : 'Invalid date'}`);
    }
  }

  const handleChangeVehicle = async (fieldName, newValue) => {
    if (currentTrip.hasAnyReservations) {
      if (currentTrip.vehicle.placesNumber !== newValue.placesNumber) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Количество мест в транспортных средствах не' +
          ' совпадает, замена невозможна');

        updateTrip(fieldName, currentTrip.vehicle);

        return;
      }
    }

    updateTrip(fieldName, newValue);
    updateTrip('totalPlaces', newValue?.placesNumber || 0);
    updateTrip('freePlaces', newValue?.placesNumber || 0);
    updateTrip('placesScheme', '');

    await getVehicleNearestTrip(newValue.idPost);
  }
  //#endregion

  //#region Handling boarding address
  const [showDriverAddressesModal, setShowDriverAddressesModal] = useState(false);
  const [areDriverAddresses, setAreDriverAddresses] = useState(
    currentTrip?.isBoardingByDriverAddresses);

  const handleSelectedAddressOption = (addressType) => {
    if (addressType === 'driverAddress') {
      updateTrip('isPassengerAddress', false);
      updateTrip('isBoardingByDriverAddresses', true);
      setAreDriverAddresses(true);
    } else {
      updateTrip('isPassengerAddress', true);
      updateTrip('isBoardingByDriverAddresses', false);

      setAreDriverAddresses(false);
    }
  }
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user?.idPost && user?.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Set initial trip points
  useEffect(() => {
    const tripPointsData = currentTrip.tripPoints.length > 0
      ? currentTrip.tripPoints : [tripPointModel, tripPointModel];

    updateTrip('tripPoints', tripPointsData, true);
  }, []);
  //#endregion

  //#region Submit add trip (or edit)
  const checkDriversAndVehicles = async (trip) => {
    const vehiclesAndDriversValidationResult =
      await validateDriversAndVehicles(trip);

    if (vehiclesAndDriversValidationResult[0]) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage(
        'Транспортное средство занято в другой поездке');

      return false;
    }

    if (vehiclesAndDriversValidationResult[1].length > 0) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');

      if (vehiclesAndDriversValidationResult[1].length === 1) {
        setNotificationMessage(
          'Указанный водитель занят в другой поездке:');
        setNotificationData(' '
          + vehiclesAndDriversValidationResult[1][0]);
      }

      if (vehiclesAndDriversValidationResult[1].length === 2) {
        setNotificationMessage(
          'Указанные водители заняты в другой поездке:');
        setNotificationData(' ' +
          vehiclesAndDriversValidationResult[1][0] +
          ', ' + vehiclesAndDriversValidationResult[1][1]);
      }

      return false;
    }

    return true;
  }

  const submitTripAddingHandler = async (event, isPublish = false) => {
    event.preventDefault();
    setIsLoading(true);

    if (newTripData.tripPoints.some((point, index,
        points) => !checkTripPoint(point, index, index === points
        .length - 1))) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Заполните все поля');
      setIsLoading(false);

      return;
    }

    if (newTripData?.isPlaceForCargo && !newTripData?.cargoDescription) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Отсутствует описание груза');
      setIsLoading(false);

      return;
    }

    if ((newTripData?.drivers?.length === 2) && (!newTripData?.drivers[1]?.uid)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Данные второго водителя не заполнены');
      setIsLoading(false);

      return;
    }

    if ((newTripData?.drivers?.length === 2) && (newTripData?.drivers[0]
      ?.uid === newTripData?.drivers[1]?.uid)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Водители не должны совпадать');
      setIsLoading(false);

      return;
    }

    if (parseInt(newTripData.pricePerPerson) <= 0) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Стоимость должна быть больше нуля');
      setIsLoading(false);

      return;
    }

    if (newTripData?.isVisibleForDispatchers) {
      let isPaymentMethodDefined = false;

      switch (true) {
        case newTripData.isPaymentOnCard:
        case newTripData.isPaymentByCash:
        case newTripData.isForChange:
          isPaymentMethodDefined = true;
          break;
      }

      if (!isPaymentMethodDefined) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Необходимо выбрать способ оплаты');
        setIsLoading(false);

        return;
      }

      if (!newTripData?.dispatcherFee || newTripData?.dispatcherFee <= 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Сумма гонорара должна быть больше нуля');
        setIsLoading(false);

        return;
      }

      if (newTripData?.dispatcherFee > newTripData.pricePerPerson / 2) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Гонорар не может быть больше 50% от суммы поездки');
        setIsLoading(false);

        return;
      }
    }

    if (!newTripData?.isFreeSeating && !newTripData.placesScheme?.idPost) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Необходимо сохранить схему или поменять тип' +
        ' рассадки');
      setIsLoading(false);

      return;
    }

    const driversChangeResult = compareDriversForTrip(newTripData?.drivers,
      currentTrip.drivers, newTripData.drivers);

    try {
      let fullDate = '';

      const timeObj = getHoursAndMinutesFromTime(newTripData
        .tripPoints[0].departureTimeWithoutDate);

      fullDate = new Date(newTripData.tripPoints[0].departureDateWithoutTime)
        .setHours(timeObj?.hours || 0, timeObj?.minutes || 0, 0, 0);

      let newTripPointsData = newTripData.tripPoints.map((tripPoint, index,
        array) => {
        let departureTime = '';

        if (index < array.length - 1) {
          const timeObj = getHoursAndMinutesFromTime(tripPoint
            .departureTimeWithoutDate);

          departureTime = new Date(tripPoint.departureDateWithoutTime)
            .setHours(timeObj?.hours || 0, timeObj?.minutes || 0, 0, 0);
        }

        return {
          ...tripPoint,
          freePlaces: Number(newTripData.freePlaces),
          departureTime: departureTime,
        }
      });

      newTripPointsData = await getTripPointsWithTranslation(newTripPointsData,
        lang, languages.map(language => language.name));

      let tripModelForSave;
      let isEdit = false;

      const currentDate = Date.now();

      if (isAfter(currentDate, fullDate)) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Нельзя создать поездку с прошедшей датой');

        setIsLoading(false);

        return;
      }

      if (newTripData?.isBoardingByDriverAddresses) {
        if (!newTripData?.boardingPlacesFromDriver ||
          newTripData?.boardingPlacesFromDriver?.length === 0) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Не заполнены адреса посадки');

          return;
        }

        const updatedBoardingPlaces = await getTripPointsWithTranslation(
          newTripData.boardingPlacesFromDriver, lang, languages
            .map(language => language.name));

        newTripData.boardingPlacesFromDriver = updatedBoardingPlaces;
      }

      if (newTripData.idPost) {
        const actualTrip = await getDocInCollection(collectionsInterface.trips,
          newTripData.idPost);

        if (actualTrip.hasAnyReservations) {
          const checkResult = checkIfTripCouldBeChanged(currentTrip, updatedFields);

          if (!checkResult) {
            setIsNotification(true);
            setNotificationTitle('Уведомление');
            setNotificationMessage('Поездка имеет брони, нельзя изменять' +
              ' запрещенные для редактирования поля');

            setCurrentTrip(actualTrip);

            return;
          }
        }

        if (updatedFields.hasOwnProperty('tripPoints')) {
          tripModelForSave = {
            ...updatedFields,
            dateEditing: currentDate,
            endPoint: newTripPointsData[newTripPointsData.length - 1],
            idPost: newTripData.idPost,
            startDate: fullDate,
            startDateWithoutTime: newTripPointsData[0].departureDateWithoutTime,
            startPoint: newTripPointsData[0],
            tripPointsNames: [...tripPointsNames],
            tripPoints: newTripPointsData,
          }
        } else {
          tripModelForSave = {
            ...updatedFields,
            idPost: newTripData.idPost,
            dateEditing: currentDate,
          }
        }

        isEdit = true;
      } else {
        tripModelForSave = {
          ...newTripData,
          companyName: user.companyName,
          creatorIdPost: user.idPost,
          creatorUid: user.uid,
          dateCreating: currentDate,
          endPoint: newTripPointsData[newTripPointsData.length - 1],
          isDraft: !isPublish,
          isOpenForSearch: !isNotePad,
          startDate: fullDate,
          startDateWithoutTime: newTripPointsData[0].departureDateWithoutTime,
          startPoint: newTripPointsData[0],
          status: isNotePad ? tripStatusesInterface.draft
            : tripsStatusesInterface.openToSearch,
          totalPlaces: parseInt(newTripData?.vehicle?.placesNumber),
          pricePerPerson: newTripData?.pricePerPerson,
          tripPointsNames: [...tripPointsNames],
          tripPoints: newTripPointsData,
        };
      }

      const checkResult = await checkDriversAndVehicles({
        ...currentTrip,
        ...tripModelForSave,
      });

      if (!checkResult) {
        return;
      }

      if (!checkTripCompleteness(tripModelForSave)) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Заполните все поля');

        return;
      }

      await completeExternalSubmitAddTripActions(tripModelForSave, isEdit,
        driversChangeResult);

      setTripPointsNames(['startPoint', 'endPoint']);
      setNewTripData(tripModel);

    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Places number and its calculating
  const createPlacesNumberArray = (vehicle) => {
    const placesNumber = [];

    for (let i = 1; i <= (vehicle?.placesNumber || 0); i++) {
      placesNumber.push(i);
    }

    return placesNumber;
  }
  //#endregion

  //#region Save places scheme
  const savePlacesSchemeToTrip = (placesScheme) => {
    const calculatedFreePlaces = placesScheme.rows.reduce((accum, curElem) => {
      const rowFreePlaces = curElem.row.reduce((sum, place) => {
        if (!place.isFreeSpace && !place.isOccupied) {
          return sum + 1;
        }
        return sum;
      }, 0);

      return accum + rowFreePlaces;
    }, 0);

    updateTrip('placesScheme', placesScheme);
    updateTrip('freePlaces', calculatedFreePlaces)
  }
  //#endregion

  //#region Translate departureAddresses if necessary
  const [boardingPlacesFromDriver, setBoardingPlacesFromDriver] = useState([]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`AddTrip ${addClasses}`}>
          <div className={'AddTrip-Item'}>
            <h3 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h3>
          </div>

          {isAdmin &&
            <>
              <div className={'CreateTripOrder-Item'}>
                <div className={'FlexBlock'}>
                  <label className={'DataLabel'}>
                    {translate('Id')}
                  </label>

                  {currentTrip.idPost}
                </div>
              </div>

              <div className={'FlexBlock'}>
                <label className={'DataLabel'}>
                  {translate('Статус')}
                </label>

                {currentTrip.isActive
                  ? translate('Активная')
                  : translate('Неактивная')
                }
              </div>

              <div className={'PaddingBlock'}></div>
            </>
          }

          <form
            className={'AddTripForm'}
          >
            <div className={'AddTrip-Item'}>
              <h5
                className={''}
              >
                {translate('Водители в поездке')}
              </h5>

              {newTripData?.drivers?.map((driver, dIndex, dArray) => (
                <React.Fragment
                  key={driver.uid + dIndex}
                >
                  <div
                    className={
                      'FlexBlock FlexBlock_Columns FlexBlock_Columns_OnDesktopToo'
                      }
                  >
                    <label className={'AddTrip-Label'}>
                      {translate('ФИО водителя')}
                    </label>

                    {isAdmin &&
                      <div
                        className={'UserLink'}
                        onClick={() => handleUserClick(driver.idPost,
                          setEditingUser, navigate)}
                      >
                        {driver.fullName}
                      </div>
                    }

                    <Select
                      classNames={{
                        control: (state) =>
                          state.isFocused ? 'FormControl AddTrip-Select'
                            : 'FormControl AddTrip-Select',
                      }}
                      options={transformDriversToSelectOptions(driversList)}
                      placeholder={translate('ФИО водителя')}
                      value={{
                        value: newTripData?.drivers[dIndex],
                        label: newTripData?.drivers[dIndex]?.fullName,
                      }}
                      onChange={(event) => handleChangeDriver('drivers',
                        event.value, dIndex)}
                      isDisabled={!isCreator || currentTrip.isActive}
                    />
                  </div>

                  {driver.phoneNumbers.map(phone => (
                    <React.Fragment key={phone.phoneNumber}>
                      <DisplayMessengersRow
                        isLinkToCall={true}
                        isPhoneNumberRefactored={true}
                        phone={phone}
                      />
                    </React.Fragment>
                  ))}

                  {(dArray.length > 1 && dIndex < dArray.length - 1) &&
                    <div
                      className={'SeparationBlock SeparationBlock_Limited'}
                    ></div>
                  }
                </React.Fragment>
              ))}
            </div>

            {(isCreator && !currentTrip.isActive) &&
              <div className={'AddTrip-Item'}>
                {checkIsCompanyHasDrivers() &&
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={() => addDriverButtonClickHandler(newTripData)}
                  >
                    {translate('Добавить водителя')}
                  </button>
                }

                {(newTripData?.drivers?.length === 2) &&
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={() => deleteDriverButtonClickHandler(newTripData)}
                  >
                    {translate('Удалить водителя')}
                  </button>
                }
              </div>
            }

            <h5>
              {translate('Транспортное средство')}
            </h5>

            <div
              className={'AddTripForm-Item'}
            >
              <label className={'AddTripForm-Label'}>
                {translate('Выбор автомобиля')}
              </label>

              <Select
                classNames={{
                  control: (state) =>
                    state.isFocused ? 'FormControl AddTripForm-Select'
                      : 'FormControl AddTripForm-Select',
                }}
                options={transformVehiclesToSelectOptions(user.vehicles)}
                placeholder={translate('Выбор автомобиля')}
                onChange={(event) => handleChangeVehicle('vehicle',
                  event.value)}
                value={newTripData?.vehicle?.brand ? {
                  value: newTripData?.vehicle,
                  label: newTripData?.vehicle?.brand ? (newTripData.vehicle
                    ?.brand?.defaultName + ' ' + newTripData.vehicle?.model
                    ?.defaultName + ' ' + newTripData.vehicle?.licensePlate)
                  : '',
                } : ''}
                isDisabled={!isCreator || currentTrip.isActive || (currentTrip
                  ?.hasAnyReservations && !currentTrip?.isFreeSeating)}
              />
            </div>

            {newTripData?.vehicle?.color &&
              <div
                className={'AddTripForm-Item'}
              >
                <label className={'AddTripForm-Label'}>
                  {translate('Цвет авто')}
                  {': '}
                  {newTripData?.vehicle?.color[lang] || newTripData?.vehicle
                    ?.color?.default}
                </label>
              </div>
            }

            <h5
              className={''}
            >
              {translate('Маршрут')}
            </h5>

            {newTripData?.tripPoints?.map((point, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={'AddTripForm-Item'}>
                    <TripPoint
                      key={tripPointsNames[index]}
                      index={index}
                      tripPointsNames={tripPointsNames}
                      addNewTripPointHandler={addNewTripPointHandler}
                      deleteTripPointHandler={deleteTripPointHandler}
                      changePlaceInputHandler={changePlaceInputHandler}
                      changeDateInputHandler={changeDateInputHandler}
                      changeTimeInputHandler={changeTimeInputHandler}
                      isPassenger={false}
                      currentPoint={point}
                      isCreator={isCreator}
                      disabled={(index === 0 || index === newTripData
                        .tripPoints.length - 1) && currentTrip.hasAnyReservations
                      }
                    />

                    <div
                      className={'SeparationBlock SeparationBlock_Limited'}
                    ></div>
                  </div>
                </React.Fragment>
              )
            })
            }

            <h5
              className={''}
            >
              {translate('Места посадки пассажиров')}
            </h5>

            <div className={'AddTripForm-Item'}>
              <label
                className={'AddTripForm-Label'}
              >
                {translate('Места посадки пассажиров')}
              </label>

              <select
                onChange={(event) => handleSelectedAddressOption(
                  event.target.value)}
                defaultValue={currentTrip.isBoardingByDriverAddresses
                  ? 'driverAddress' : 'passengerAddress'}
                className={'FormControl'}
                disabled={!isCreator || currentTrip.isActive}
              >
                <option
                  value={'passengerAddress'}
                >
                  {translate('По адресу пассажира')}
                </option>

                <option
                  value={'driverAddress'}
                >
                  {translate('Адреса водителя')}
                </option>
              </select>
            </div>

            {areDriverAddresses &&
              <>
                <div className={'AddTripForm-Item'}>
                  <button
                    type={'button'}
                    onClick={() => setShowDriverAddressesModal(true)}
                    className={'RegularButton RegularButton_Contrast'}
                  >
                    {newTripData?.boardingPlacesFromDriver?.length === 0 ||
                      !newTripData?.boardingPlacesFromDriver ?
                      translate('Добавить адреса')
                      :
                      translate('Обновить адреса')
                    }
                  </button>
                </div>
              </>
            }

            {newTripData.isBoardingByDriverAddresses &&
              <div className={'AddTripForm-Item'}>
                {boardingPlacesFromDriver?.map((place, pIndex) => (
                  <React.Fragment key={pIndex}>
                    <div className={'DriverAddressItem'}>
                      {getTranslatedLocalityName(place.fullAddress, lang)}
                    </div>
                  </React.Fragment>
                ))}
              </div>

            }

            <div className={'AddTripForm-Item'}>
              <label className={'AddTripForm-Label'}>
                {translate('Тип поездки')}
              </label>

              <RadioFieldSet
                elementName={'isPassengerTrip'}
                elementText={`${translate('Пассажирская')}_${translate('Грузовая')}`}
                inputChangeHandler={(isCreator || currentTrip.isActive)
                  ? changeTripTypeHandler : () => {}}
                checkedInput={newTripData.isPassengerTrip ?
                  translate('Пассажирская')
                  :
                  translate('Грузовая')
                }
                classes={'FormControl'}
                disabled={currentTrip.hasAnyReservations}
              />
            </div>

            {newTripData.isPassengerTrip &&
              <div
                className={
                  'SeparationBlock SeparationBlock_Limited AddTripForm-Item'
                }
              ></div>
            }

            {newTripData.isPassengerTrip &&
              <>
                <div className={'AddTripForm-Item'}>
                  {newTripData.isFreeSeating ?
                    <div className={'FlexBlock AddTripForm-PlacesNumber'}>
                      <span
                        className={'FlexBlock-HorizontalItem ' +
                          'FlexBlock-HorizontalItem_W65'}
                      >
                          {`${translate('Количество мест в поездке')}`}
                      </span>

                      <span
                        className={'FlexBlock-HorizontalItem ' +
                          'FlexBlock-HorizontalItem_W30'}
                      >
                        <Select
                          classNames={{
                            control: (state) =>
                              state.isFocused ? 'FormControl AddTripForm-Select'
                                : 'FormControl AddTripForm-Select',
                          }}
                          options={transformStringsArrayToSelectOptions(
                            createPlacesNumberArray(newTripData.vehicle))}
                          onChange={(event) => updateTrip('freePlaces',
                            Number(event.value))}
                          value={{
                            value: newTripData.freePlaces || 0,
                            label: newTripData.freePlaces || 0,
                          }}
                          isDisabled={currentTrip.hasAnyReservations}
                        />
                      </span>
                    </div>
                    :
                    <div className={''}>
                      {`${translate('Количество свободных мест')}: ${newTripData
                        ?.freePlaces}`}
                    </div>
                  }
                </div>

                {(newTripData?.vehicle?.placesSchemeId && !currentTrip
                    .hasAnyReservations) ?
                  <div className={'AddTripForm-Item'}>
                    <label className={'AddTripForm-Label'}>
                      {translate('Выбор рассадки')}
                    </label>

                    <RadioFieldSet
                      elementName={'isFreeSeating'}
                      elementText={`${translate('Свободная')}_${translate(
                        'Индивидуальная')}`}
                      inputChangeHandler={(isCreator || currentTrip.isActive)
                        ? changePlacingHandler : () => {}}
                      checkedInput={newTripData.isFreeSeating ?
                        translate('Свободная')
                        :
                        translate('Индивидуальная')
                      }
                      classes={'FormControl'}
                    />
                  </div> :
                  <div className={'AddTripForm-Item FlexBlock'}>
                    <span
                      className={'FlexBlock-HorizontalItem ' +
                        'FlexBlock-HorizontalItem_W65'}>
                      {translate('Тип рассадки')}
                    </span>

                    <span
                      className={'FlexBlock-HorizontalItem ' +
                        'FlexBlock-HorizontalItem_W30'}
                    >
                      {newTripData.isFreeSeating ?
                        translate('Свободная')
                        :
                        translate('Индивидуальная')
                      }
                    </span>
                  </div>
                }

                <div
                  className={'SeparationBlock SeparationBlock_Limited ' +
                    'AddTripForm-Item'}
                ></div>
              </>
            }

            <div className={'AddTripForm-Item'}>
              <div className={'FlexBlock AddTripForm-PlaceCost'}>
                <span
                  className={
                    'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W70'
                  }
                >
                    {translate('Стоимость билета')}
                </span>

                <NumberInputWithValidation
                  addClasses={'FlexBlock-HorizontalItem ' +
                    'FlexBlock-HorizontalItem_W30'}
                  externalChangeHandler={updateTrip}
                  fieldName={'pricePerPerson'}
                  value={newTripData?.pricePerPerson}
                  disabled={currentTrip.hasAnyReservations}
                />
              </div>
            </div>

            {(newTripData.isPassengerTrip && !newTripData.isFreeSeating) &&
              <>
                <div className={'AddTripForm-Item'}>
                  {currentTrip.hasAnyReservations ?
                    <PlacesInTripDisplaying
                      scheme={currentTrip?.placesScheme || []}
                    />
                    :
                    <PlacesInTripEditing
                      addClasses={'AddTripForm-PlacesInTripEditing'}
                      initialScheme={newTripData?.placesScheme?.idPost
                        ? newTripData.placesScheme
                        : newTripData.vehiclePlacesScheme}
                      saveScheme={savePlacesSchemeToTrip}
                    />
                  }
                </div>
              </>
            }

            <div
              className={
                'SeparationBlock SeparationBlock_Limited AddTripForm-Item'
              }
            ></div>

            {newTripData?.isPassengerTrip &&
              <>
                <div className={'AddTripForm-Item'}>
                  <label className={'AddTripForm-Label'}>
                    {translate('Грузовое место')}
                  </label>

                  <RadioFieldSet
                    elementName={'isPlaceForCargo'}
                    elementText={`${translate('Да')}_${translate('Нет')}`}
                    inputChangeHandler={(isCreator || currentTrip.isActive)
                      ? changeIsPlaceForCargoHandler : () => {}}
                    checkedInput={newTripData.isPlaceForCargo ?
                      translate('Да')
                      :
                      translate('Нет')
                    }
                    classes={'FormControl'}
                    disabled={currentTrip.hasAnyReservations}
                  />
                </div>

                <div
                  className={
                    'SeparationBlock SeparationBlock_Limited AddTripForm-Item'
                  }
                ></div>

                {newTripData?.isPlaceForCargo &&
                  <>
                    <div className={'AddTrip-Item'}>
                      <label
                        htmlFor='CargoDescription'
                        className={'CreateTrip-Label'}
                      >
                        {translate('Описание допустимого груза')}
                      </label>

                      <textarea
                        required
                        id="CargoDescription"
                        placeholder={translate('Описание допустимого груза')}
                        className={'FormControl TextArea'}
                        name={'CargoDescription'}
                        value={newTripData.cargoDescription}
                        onChange={(event) => updateTrip('cargoDescription',
                          event.target.value)}
                      />
                    </div>

                    <div
                      className={
                        'SeparationBlock SeparationBlock_Limited AddTripForm-Item'
                      }
                    ></div>

                  </>
                }
              </>
            }

            {newTripData?.isPassengerTrip &&
              <>
                <h5
                  className={''}
                >
                  {translate('Дополнительные параметры')}
                </h5>

                <div className={'AddTrip-Item'}>
                  <div className='FlexBlock FlexBlock_JustifyLeft'>
                    <input
                      type={'checkbox'}
                      className={'OptionCheckBox'}
                      onChange={(event) => updateTrip('isByFoot',
                        event.target.checked)}
                      checked={newTripData?.isByFoot}
                      disabled={!isCreator || currentTrip.isActive}
                      id={'isByFoot'}
                    />

                    <label
                      className={newTripData?.isByFoot ? 'OptionText ColoredText'
                        : 'OptionText'}
                      htmlFor={'isByFoot'}
                    >
                      {translate('Пеший переход')}
                    </label>
                  </div>

                  <div className={'FlexBlock FlexBlock_JustifyLeft'}>
                    <input
                      type={'checkbox'}
                      className={'OptionCheckBox'}
                      onChange={(event) => updateTrip('isTripWithPets',
                        event.target.checked)}
                      checked={newTripData?.isTripWithPets}
                      disabled={!isCreator || currentTrip.isActive}
                      id={'isTripWithPets'}
                    />

                    <label
                      htmlFor={'isTripWithPets'}
                      className={newTripData?.isTripWithPets
                        ? 'OptionText ColoredText' : 'OptionText'}
                    >
                      {translate('Можно с животными')}
                    </label>
                  </div>

                  {tripAddOptions.map((option, oIndex) => (
                    <React.Fragment key={oIndex}>
                      <div className={'FlexBlock FlexBlock_JustifyLeft'}>
                        <input
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          checked={option.checked}
                          onChange={(event) => handleAddOptionClick(oIndex,
                            event.target.checked)}
                          disabled={!isCreator || currentTrip.isActive}
                          id={option.optionContent?.defaultName + oIndex}
                        />

                        <label
                          className={option.checked ? 'OptionText ColoredText'
                            : 'OptionText'}
                          htmlFor={option.optionContent?.defaultName + oIndex}
                        >
                          {option?.optionContent[lang] || option.optionContent
                            ?.defaultName}
                        </label>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            }

            {(isCreator && !currentTrip.isActive) &&
              <>
                {!isNotePad &&
                  <>
                    <div className={'ReplaceTripScreen-Item'}>
                      <label className={'ReplaceTripScreen-Label'}>
                        {translate('Гонорар диспетчера')}
                      </label>

                      <NumberInputWithValidation
                        value={newTripData?.dispatcherFee}
                        placeholder={translate('Гонорар диспетчера')}
                        fieldName={'dispatcherFee'}
                        externalChangeHandler={updateTrip}
                        addClasses={'ReplaceTripScreen-Input'}
                      />
                    </div>

                    <div className={'ReplaceTripScreen-Item'}>
                      <h5 className={'RegularTitle RegularTitle_Small'}>
                        {translate('Способ оплаты')}
                      </h5>

                      <div
                        className={'FlexBlock FlexBlock_JustifyLeft ' +
                          'ReplaceTripScreen-Block'}
                      >
                        <div className={'OptionCheckBox'}>
                          <input
                            type={'checkbox'}
                            className={'OptionCheckBox'}
                            checked={newTripData?.isPaymentOnCard}
                            onChange={(event) => updateTrip('isPaymentOnCard',
                              event.target.checked)}
                            disabled={newTripData?.isPaymentByCash}
                            id={'isPaymentOnCard'}
                          />
                        </div>

                        <label
                          className={'OptionText'}
                          htmlFor={'isPaymentOnCard'}
                        >
                          {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                        </label>

                        <div className={'ReplaceTripScreen-IconContainer'}>
                          {newTripData?.isPaymentOnCard &&
                            <img
                              src={'/img/bank-safe-icon.svg'}
                              className={'ReplaceTripScreen-Icon'}
                            />
                          }
                        </div>
                      </div>

                      <div
                        className={'FlexBlock FlexBlock_JustifyLeft ' +
                          'ReplaceTripScreen-Block'}
                      >
                        <input
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          checked={newTripData?.isPaymentByCash}
                          onChange={(event) => updateTrip('isPaymentByCash',
                            event.target.checked)}
                          disabled={newTripData?.isPaymentOnCard}
                          id={'isPaymentByCash'}
                        />

                        <label
                          htmlFor={'isPaymentByCash'}
                          className={'OptionText'}
                        >
                          {translate('Наличные по договоренности')}
                        </label>
                      </div>

                      <div
                        className={'FlexBlock FlexBlock_JustifyLeft ' +
                          'ReplaceTripScreen-Block'}
                      >
                        <input
                          id={'isForChange'}
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          onChange={(event) => updateTrip('isForChange',
                            event.target.checked)}
                          checked={newTripData?.isForChange}
                        />

                        <label
                          className={'OptionText'}
                          htmlFor={'isForChange'}
                        >
                          {translate('Обмен')}
                        </label>
                      </div>
                    </div>

                    <div
                      className={'SeparationBlock SeparationBlock_Limited'}
                    ></div>

                    <div className={'ReplaceTripScreen-Item'}>
                      <div
                        className={'FlexBlock FlexBlock_JustifyLeft' +
                          ' ReplaceTripScreen-Block'}
                      >
                        <input
                          id={'isVisibleForDispatchers'}
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          onChange={(event) => updateTrip(
                            'isVisibleForDispatchers', event.target.checked)}
                          checked={newTripData?.isVisibleForDispatchers}
                        />

                        <label
                          htmlFor={'isVisibleForDispatchers'}
                          className={'OptionText OptionText_Bold'}
                        >
                          {translate('Опубликовать предложение для ' +
                            'диспетчеров')}
                        </label>
                      </div>
                    </div>
                  </>
                }

                {!currentTrip?.isInProgress &&
                  <div className={'AddTripForm-Item'}>
                    <button
                      type={'button'}
                      className={'RegularButton '}
                      onClick={(event) => submitTripAddingHandler(event,
                        !isNotePad)}
                    >
                      {currentTrip.idPost ?
                        translate('Сохранить изменения')
                        :
                        translate('Создать')
                      }
                    </button>
                  </div>
                }
              </>
            }
          </form>
        </div>
      }

      {showDriverAddressesModal &&
        <ModalAddDriverAddress
          showModal={showDriverAddressesModal}
          setShowModal={setShowDriverAddressesModal}
          setAreDriverAddresses={setAreDriverAddresses}
          updateBoardingAddresses={updateBoardingAddresses}
          tripData={newTripData}
        />
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
          addData={notificationData}
          setAddData={setNotificationData}
        />
      }
    </>
  );
  //#endregion
};

export default AddTrip;
