import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  checkIsCompanyHasRegisteredDrivers,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import DisplayMessengersRow from "./DisplayMessengersRow";
import {useNavigate} from "react-router-dom";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, rolesInterface} from "../utils/models";
import {languageService} from "../utils/languageService";
import useTranslation from "../hooks/useTranslation";

const ShowProfileDataScreen = ({
  addClasses = '',
  setIsEditMode = () => {},
}) => {
  //#region Get language from context, manage languages
  const {lang, languages} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get handler to manage screen title
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion


  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Личные данные');
  }, []);
  //#endregion

  //#region Company for invited user
  const [employerName, setEmployerName] = useState('');

  useEffect(() => {
    if (user.attachedToCompany) {
      getDocInCollection(collectionsInterface.users, user.attachedToCompany)
        .then(company => setEmployerName(company.companyName))
        .catch(() => {});
    }
  }, [])
  //#endregion

  //#region Render
  return (
    <>
      <div className={`ShowProfileData ${addClasses}`}>
        <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {translate(screenTitle)}
        </h2>

        {(checkIsCompanyHasRegisteredDrivers(user) || user.attachedToCompany) &&
          <div className={'ShowProfileData-Item'}>
            <label
              className={'ShowProfileData-Label'}
              htmlFor="item01"
            >
              {translate('Название компании')}
            </label>

            <input
              disabled={true}
              id="item01"
              placeholder={translate('Название компании')}
              className={'FormControl ShowProfileData-Input'}
              name={'fullName'}
              type={'text'}
              value={user.attachedToCompany ? employerName : user.companyName}
            />
          </div>
        }

        <div className={'ShowProfileData-Item'}>
          <label
            className={'ShowProfileData-Label'}
            htmlFor="item01"
          >
            {translate('ФИО')}
          </label>

          <input
            disabled={true}
            id="item01"
            placeholder={translate('ФИО')}
            className={'FormControl ShowProfileData-Input'}
            name={'fullName'}
            type={'text'}
            value={user.fullName}
          />
        </div>

        <div className={'ShowProfileData-Item'}>
          <label
            className={'ShowProfileData-Label'}
          >
            {translate('Дата рождения')}
          </label>

          <div className={'FormControl ShowProfileData-Input'}>
            {showFormattedToLanguageDate(lang, user.birthDay)}
          </div>
        </div>

        <div className={'ShowProfileData-Item'}>
          <label
            className={'ShowProfileData-Label'}
          >
            {translate('Номера телефона')}
          </label>

          {user.phoneNumbers &&
            <>
              {user.phoneNumbers.map((phone, index) => (
                <React.Fragment key={index}>
                  <div
                    className={index !== 0
                      ? 'FormControl ShowProfileData-Input ' +
                      'ShowProfileData-PhoneNumber'
                      : 'FormControl ShowProfileData-Input'}
                  >
                    <DisplayMessengersRow
                      phone={phone}
                      addClasses={'ShowProfileData-MessengersRow'}
                      isPhoneNumberRefactored={true}
                    />
                  </div>
                </React.Fragment>
              ))}
            </>
          }
        </div>

        <div className={'ShowProfileData-Item'}>
          <label
            className={'ShowProfileData-Label'}
          >
            {translate('E-mail')}
          </label>

          <div
            className={'FormControl ShowProfileData-Input'}
          >
            {user.email}
          </div>
        </div>

        <div className={'ShowProfileData-Item'}>
          <label className={'ShowProfileData-Label'}>
            {translate('Язык для писем')}
          </label>

          <div
            className={'FormControl ShowProfileData-Input'}
          >
            {languageService.getLanguageDescription(user.languageForEmails,
              languages)}
          </div>
        </div>

        <div className={'ShowProfileData-Item'}>
          <label className={'ShowProfileData-Label'}>
            {translate('Населенный пункт')}
          </label>

          <div
            className={'FormControl ShowProfileData-Input'}
          >
            {user?.fullAddress}
          </div>
        </div>

        <div className={'ShowProfileData-Item'}>
          <div className={'FlexBlock'}>
            <button
              type={'button'}
              className={
                'RegularButton FlexBlock-HorizontalItem ' +
                'FlexBlock-HorizontalItem_W45'
              }
              onClick={() => setIsEditMode(true)}
            >
              {translate('Редактировать')}
            </button>

            {(user?.role === rolesInterface.user && !user?.attachedToCompany) &&
              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast ' +
                  'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W45'}
                onClick={() => {
                  navigate('addVehicle');
                  setScreenTitle('Добавление ТС');
                }}
              >
                {translate('Добавить ТС')}
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
  //#endregion
};

export default ShowProfileDataScreen;
