import React, {useEffect, useState, useContext} from 'react';
import DisplayCreatedTripsOrders from "./DisplayCreatedTripsOrders";
import {AppContext} from "../context/appContext";
import {
  getCollectionWhereKeysValues
} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, pagesInterface} from "../utils/models";
import {sortCardsByDateOfCreation} from "../utils/helpers";
import {useNavigate} from "react-router-dom";

const TripsOrdersWithReservations = ({
  handleShowCreateTrip = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get tripOrder data from context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Trips orders list
  const [tripsOrdersToDisplay, setTripsOrdersToDisplay] = useState([]);
  //#endregion

  //#region Get trips orders and set to state
  const getTripOrdersAndSet = async () => {
    setIsLoading(true);
    try {
      const orders = await getCollectionWhereKeysValues(collectionsInterface.tripsOrders, [
        {
          key: 'creatorIdPost',
          value: user.idPost,
        },
        {
          key: 'isOpenForSearch',
          value: true,
        },
        {
          key: 'isActive',
          value: false,
        },
        {
          key: 'isArchived',
          value: false,
        },
        {
          key: 'hasAnyReservations',
          value: true,
        },
      ], 5);

      setTripsOrdersToDisplay(sortCardsByDateOfCreation(orders, false));
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Set data on component mount
  useEffect(() => {
    getTripOrdersAndSet().then()
  }, []);
  //#endregion

  //#region Handle trip order card click
  const handleTripOrderCardClick = (tripOrder) => {
    setCurrentTripOrder(tripOrder);

    navigate(`/${pagesInterface.passengers}/reservedTrips/reservedTripOrderDetails`);
  }
  //#endregion

  //#region Render
  return (
    <>
      <DisplayCreatedTripsOrders
        isNotepad={false}
        isReadOnly={true}
        isDispatcher={true}
        isInReservedList={true}
        tripsOrdersToDisplay={tripsOrdersToDisplay}
        handleShowCreateTrip={handleShowCreateTrip}
        handleTripOrderCardClick={handleTripOrderCardClick}
        addClasses={'Passengers-DisplayTripsOrders'}
        isLoading={isLoading}
      />
    </>
  );
  //#endregion
};

export default TripsOrdersWithReservations;
