import React, {useState, useContext, useEffect} from 'react';
import {
  cancelWithdrawal,
  confirmWithdrawal,
  getWithdrawalStatusText,
  handleTakeInProcessingClick,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {
  collectionsInterface,
  pagesInterface,
  transactionsTypesInterface,
  withdrawalStatusesInterface
} from "../utils/models";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  getCollectionWhereKeysValuesWithOperators
} from "../utils/firebaseConfigAndFunctions";
import Loader from "./Loader";
import ReactTable from "react-table-v6";
import {GiCancel, GiConfirmed, GiTakeMyMoney} from "react-icons/gi";
import WithdrawalPaymentInfo from "./WithdrawalPaymentInfo";
import FiltersControls from "./FiltersControls";
import '../styles/WithdrawalsListForAdmin.scss';
import useTranslation from "../hooks/useTranslation";

const buttonTypes = {
  approve: 'approve',
  cancel: 'cancel',
  toProcess: 'toProcess',
};

const WithdrawalsListForAdmin = ({addClasses = ''}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Заявки на выплаты');
  }, []);
  //#endregion

  //#region Loaders
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Handle buttons click
  const handleButtonClick = async (withdrawal, buttonType) => {
    setIsLoading(true);

    try {
      switch (buttonType) {
        case buttonTypes.approve:
          await confirmWithdrawal(withdrawal, setWithdrawals);
          break;
        case buttonTypes.cancel:
          await cancelWithdrawal(withdrawal, setWithdrawals);
          break;
        case buttonTypes.toProcess:
          await handleTakeInProcessingClick(withdrawal, setWithdrawals);
          break;
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Data for React table
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    getCollectionWhereKeysValuesWithOperators(
      collectionsInterface.transactions,[
      {
        key: 'isVisibleForAdmin',
        value: true,
        operator: '==',
      },
      {
        key: 'type',
        value: transactionsTypesInterface.accountWithdrawal,
        operator: '==',
      }
      ], 2).then(result => {
        setWithdrawals(result);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  //#region Search and filters
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');
  const statusesList = [
    {
      name: 'Создана',
      value: withdrawalStatusesInterface.created,
    }, {
      name: 'Ожидает',
      value: withdrawalStatusesInterface.pending,
    }, {
      name: 'Отклонена',
      value: withdrawalStatusesInterface.cancelled,
    }, {
      name: 'Одобрена',
      value: withdrawalStatusesInterface.approved
    },
  ];

  const getStatusName = (status) => {
    return translate(getWithdrawalStatusText(status));
  };

  const getWithdrawalsToDisplay = (searchText, filtersData, withdrawals) => {
    let searchResult = [];

    if (searchText) {
      searchResult = withdrawals.filter(withdrawal => {
        if (withdrawal?.recipient?.fullName?.toUpperCase()
          .includes(searchText.toUpperCase())) {
          return true;
        }

        return false;
      });
    } else {
      searchResult = withdrawals;
    }

    let result = [];

    if (filtersData) {
      if (Object.values(filtersData).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(withdrawal => {
          let res = true;

          if (filtersData.status &&
            filtersData.status !== withdrawal?.extraData?.status) {
            return false;
          }

          if (filtersData.date) {
            const date = new Date(filtersData.date);
            date.setHours(0, 0, 0, 0);

            const dateAsTimestamp = date.getTime();

            if (dateAsTimestamp !== withdrawal.date) {
              return false;
            }
          }

          if (filtersData.amount) {
            if (withdrawal.amount < (Number(filtersData?.amount.from || 0))
              || withdrawal.amount > Number(filtersData.amount.to || 0)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  }
  //#endregion

  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'date',
      Header: translate('Дата'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e?.date ? showFormattedToLanguageDate(lang, e.date)
          : translate('Не указана')}
      </div>
    },
    {
      id: 'recipientName',
      Header: translate('Получатель'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e?.recipient?.fullName || translate('Не указан')}
      </div>
    },
    {
      id: 'amount',
      Header: translate('Сумма'),
      accessor: e => <div
        className='UsersTable-Cell UsersTable-Cell_Centered'
      >
        {e?.amount || translate('Не указана')}
      </div>
    },
    {
      id: 'status',
      Header: translate('Статус'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {translate(getWithdrawalStatusText(e?.extraData?.status))}
      </div>
    },
    {
      id: 'buttons',
      Header: '',
      width: 100,
      accessor: e =>
        <div
          className='UsersTable-Cell'
        >
          {(e?.extraData?.status !== withdrawalStatusesInterface.cancelled &&
              e?.extraData?.status !== withdrawalStatusesInterface.approved) &&
            <div
              className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}
            >
              {e?.extraData?.status !== withdrawalStatusesInterface.pending &&
                <button
                  type={'button'}
                  className={'ShowButton WithdrawalsListForAdmin-Button'}
                  onClick={() => handleButtonClick(e, buttonTypes.toProcess)}
                >
                  <GiTakeMyMoney />
                </button>
              }

              <button
                type={'button'}
                className={'ConfirmButton WithdrawalsListForAdmin-Button'}
                onClick={() => handleButtonClick(e, buttonTypes.approve)}
              >
                <GiConfirmed />
              </button>

              <button
                type={'button'}
                className={'DelButton WithdrawalsListForAdmin-Button'}
                onClick={() => handleButtonClick(e, buttonTypes.cancel)}
              >
                <GiCancel />
              </button>
            </div>
          }
        </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={` WithdrawalsListForAdmin ${addClasses}`}>
          <div className={'PaddingBlock PaddingBlock_Half'}></div>

          <div className={'TitleWithButton mb-2'}>
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>

            <div
              className={'ToggleButton'}
              onClick={
                () => navigate(`/${pagesInterface.transactionsAdmin}`)
              }
            >
              {translate('Транзакции')}
            </div>
          </div>

          <FiltersControls
            searchText={searchText}
            setSearchText={setSearchText}
            setAppliedFilters={setAppliedFilters}
            getStatusName={getStatusName}
            statusesList={statusesList}
            priceFilterName={'Сумма'}
          />

          <ReactTable
            data={getWithdrawalsToDisplay(searchText, appliedFilters,
              withdrawals)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}
            SubComponent={(row) => (
              <WithdrawalPaymentInfo
                data={row?.original?.extraData}
                addClasses={'SmallFont'}
              />)}
          />

          <div className={'PaddingBlock'}></div>
        </div>
      }
    </>
  );
  //#endregion
};

export default WithdrawalsListForAdmin;
