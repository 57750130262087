import React from 'react';
import useTranslation from "../hooks/useTranslation";

const PageMenuItem = ({
  onClick = () => {},
  iconPath = '',
  iconAlt = '',
  text = '',
  isIndicatorNew = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div
      className={'SectionNavMenu-Item'}
      onClick={onClick}
    >
      <div className={'SectionNavMenu-Icon'}>
        <img
          className={'MenuIcon'}
          src={iconPath}
          alt={iconAlt}
        />
      </div>

      <div className={'SectionNavMenu-Text'}>
        {translate(text)}
      </div>

      {isIndicatorNew &&
        <span className={'SectionNavMenu-NewIndicator NewIndicator'}></span>
      }

      <div className={'SectionNavMenu-Icon'}>
        <img
          src={'/img/profile-menu/right-arrow-icon.svg'}
          alt={'Arrow icon'}
        />
      </div>
    </div>
  );
  //#endregion
};

export default PageMenuItem;
