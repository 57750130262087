import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import RadioFieldSet from "../elements/RadioFieldSet";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";
import '../styles/ModalPlaceDescription.scss';

const ModalPlaceDescription = ({
  showModal,
  setShowModal,
  row,
  col,
  updatePlaceOnScheme,
  cellData
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region State
  const [show, setShow] = useState(showModal);
  const [placeNumber, setPlaceNumber] = useState(0);
  const [placeDescription, setPlaceDescription] = useState('');
  const [isPassengerPlace, setIsPassengerPlace] = useState(
    cellData.isPassengerPlace);
  //#endregion

  //#region Handlers
  const updatePlaceNumber = (fieldName = '', value) => {
    setPlaceNumber(value);
  }

  const changePlaceTypeHandler = (newValue) => {
    const placeType = newValue === translate('Пассажирское') ? true : false;

    setIsPassengerPlace(placeType);
  }

  const handleSavePlaceClick = () => {
    setShow(false);
    setShowModal(false);

    const newPlaceData = {
      description: placeDescription,
      placeNumber: Number(placeNumber),
      isPassengerPlace: isPassengerPlace,
      row: row,
      col: col,
    };

    updatePlaceOnScheme(row, col, newPlaceData);
  }
  //#endregion

  useEffect(() => {
    setPlaceNumber(cellData?.placeNumber ? cellData?.placeNumber : '');
  }, []);

  //#region Render
  return (
    <Modal
      show={show}
      onHide={() => {
        setShowModal(false);
        setShow(false);
      }}
      className={'ModalPlaceDescription'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span className={'RegularTitle'}>
            {`${translate('Введите данные о месте')} ${col + 1}
            ${translate('в ряду')} ${row + 1}`}
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form className={'ModalPlaceDescription-Form PlaceDescriptionForm'}>
          <div className={'PlaceDescriptionForm-Item'}>
            <RadioFieldSet
              elementName={'isPassengerPlace'}
              elementText={
                `${translate('Пассажирское')}_${translate('Грузовое')}`
              }
              inputChangeHandler={changePlaceTypeHandler}
              checkedInput={isPassengerPlace ?
                translate('Пассажирское')
                :
                translate('Грузовое')
              }
            />
          </div>

          <div className={'PlaceDescriptionForm-Item'}>
            <div className={'FlexBlock_Columns'}>
              <label
                className={'PlaceDescriptionForm-Label'}
                htmlFor={'currentPlaceNumber'}
              >
                {translate('Номер места')}
              </label>

              <NumberInputWithValidation
                externalChangeHandler={updatePlaceNumber}
                fieldName={'placeNumber'}
                id={'currentPlaceNumber'}
                value={placeNumber}
              />
            </div>
          </div>

          <div className={'PlaceDescriptionForm-Item'}>
            <div className={'FlexBlock_Columns'}>
              <label
                className={'PlaceDescriptionForm-Label'}
                htmlFor={'currentPlaceDescription'}
              >
                {translate('Описание места')}
              </label>
              <input
                className={'FormControl'}
                id={'currentPlaceDescription'}
                type={'text'}
                onChange={(event) => setPlaceDescription(event.target.value)}
                defaultValue={cellData.description}
              />
            </div>
          </div>
        </form>

        <div className={'FlexBlock'}>
          <button
            type={'button'}
            className={'RegularButton PlaceDescriptionForm-Button'}
            onClick={() => handleSavePlaceClick()}
          >
            {translate('Сохранить')}
          </button>
          <button
            type={'button'}
            className={'RegularButton PlaceDescriptionForm-Button ' +
              'PlaceDescriptionForm-Button_Contrast'}
            onClick={() => {
              setShow(false);
              setShowModal(false)
            }}
          >
            {translate('Вернуться')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
  //#endregion
};

export default ModalPlaceDescription;
