export const englishDictionary = {
  "Пригласить повторно":"Invite again",
  "Выберите один тип получения гонорара":"Select one type of royalty receipt",
  "Ошибка в фильтре: верхняя граница не может быть меньше нижней":"Error in filter: upper bound cannot be less than lower",
  "Название транзакции":"Transaction name",
  "Поездка была завершена автоматически":"The trip was completed automatically",
  "Сообщение в службу поддержки":"Message to support service",
  "Незавершенная поездка":"Unfinished trip",
  "Бронирование пассажира":"Passenger reservation",
  "Дата брони":"Booking date",
  "Вы можете добавить новую поездку, чтобы найти пассажиров":"You can add a new trip to find passengers",
  "Заявить об оплате":"Request payment",
  "Не менять условия":"Don't change the terms",
  "К бронированию":"To book",
  "Добавить адреса":"Add addresses",
  "Код":"Code",
  "Грузовое место":"Cargo space",
  "Оплата за место":"Payment per seat",
  "От кого":"From whom",
  "Регистрация реферала":"Referral registration",
  "Цена и места":"Price and seats",
  "Вам начислен бонус за сделку пользователя":"You have been awarded a bonus for the user's transaction",
  "Забыли пароль":"Forgot your password",
  "CVC/CVV":"CVC/CVV",
  "Перевозчик:":"Carrier:",
  "Отклонить":"Reject",
  "Неверный действующий пароль":"Invalid password",
  "Обновить адрес":"Update address",
  "Комментарий к грузу":"Comment on the cargo",
  "Диспетчер пока не оценил поездку. Ожидайте":"The dispatcher has not yet assessed the trip. Expect",
  "В этом разделе отображаются опубликованные созданные Вами поездки":"This section displays published trips you have created.",
  "Сумма к выплате":"Amount to be paid",
  "Ссылка на Google Play":"Link to Google Play",
  "Водитель пока не оценил поездку. Ожидайте":"The driver has not yet rated the trip. Expect",
  "Откуда":"Where",
  "Было отозвано приглашение в компанию":"The invitation to the company was withdrawn",
  "Смена пароля":"Change Password",
  "Найденные поездки":"Trips found",
  "Языки":"Languages",
  "Марки авто":"Car brands",
  "Опишите багаж":"Describe your luggage",
  "Количество мест в ряду":"Number of seats per row",
  "Количество забронированных мест":"Number of reserved seats",
  "Ошибка при вводе данных":"Data entry error",
  "Водители в поездке":"Drivers on a trip",
  "Невозможно подтвердить бронь. У Вас недостаточно средств":"Unable to confirm reservation. You don't have enough funds",
  "Фильтровать поездки":"Filter trips",
  "Поездки":"Trips",
  "Дополнительные опции":"Additional options",
  "Оценка должна быть больше нуля":"The score must be greater than zero",
  "Пригласить в приложение":"Invite to app",
  "ТС находится в поездке":"The vehicle is on a trip",
  "О реферальной программе":"About the referral program",
  "Нулевой остаток на балансе. Отсутствие незакрытых заявок на вывод":"Zero balance on balance. No unclosed withdrawal requests",
  "Маршрут":"Route",
  "Применить":"Apply",
  "Отклонена":"Rejected",
  "Пароль успешно изменен":"Password changed successfully",
  "Добавить ТС":"Add vehicle",
  "Год выпуска транспорта":"Year of manufacture of the vehicle",
  "История поездок":"Travel history",
  "Транспортное средство":"Vehicle",
  "Все дефолтные значения должны быть заполнены":"All default values must be filled in",
  "Ваше приглашение работать в компании было отклонено водителем":"Your invitation to work for the company was rejected by the driver",
  "Списание комиссии":"Commission write-off",
  "Цвета авто":"Car colors",
  "Войти":"To come in",
  "Справочники":"Directories",
  "От":"From",
  "Не удалось построить маршрут":"Failed to build route",
  "Рейтинг":"Rating",
  "Оплаченные":"Paid",
  "ФИО водителя":"Driver's name",
  "Все поездкы завершены или отменены":"All trips completed or cancelled.",
  "По адресу пассажира":"Passenger's address",
  "Поездка больше не подходит пассажиру":"The trip is no longer suitable for the passenger",
  "Поставьте оценку водителю":"Rate the driver",
  "Стоимость билета:":"Ticket price:",
  "Неверный номер карты":"Invalid card number",
  "Процент бонуса для пассажира":"Referral deals bonus percentage",
  "Поездка имеет брони, нельзя изменять запрещенные для редактирования поля":"The trip has reservations, you cannot change fields that are prohibited for editing",
  "Диспетчер удалил пассажира":"The dispatcher removed the passenger",
  "Средства списаны с Вашей карты, но счет не пополнен. Обратитесь в службу поддержки":"Funds have been debited from your card, but your account has not been replenished. Contact Support",
  "Приглашение от компании":"Invitation from the company",
  "Получен бонус за приглашение":"Received bonus for invitation",
  "грн":"UAH",
  "Бонусы":"Bonuses",
  "На карту":"On the map",
  "Выйти из профиля":"Log out of profile",
  "Бронь отменена, выбран другой контрагент":"Reservation cancelled, another counterparty selected",
  "Описание места":"Description of the place",
  "Показатель":"Index",
  "Ваша поездка до сих пор не завершена. С момента ее начала прошло":"Your trip is still not over. It's been since it started",
  "Выезжает":"Moves out",
  "Должны выполняться такие условия:":"The following conditions must be met:",
  "Все заняты":"Everyone is busy",
  "Вывести":"Withdraw",
  "Удалить пассажира":"Remove passenger",
  "Пассажир удален":"Passenger deleted",
  "Завершенные заявки на поездки":"Completed travel requests",
  "Сохранить словарь":"Save dictionary",
  "Способ выплаты гонорара":"Payment method",
  "Модели авто":"Car models",
  "Произошла ошибка. Администратор не создан":"An error has occurred. Administrator not created",
  "Выплата гонорара":"Payment of fees",
  "Язык для писем":"Language for letters",
  "Редактировать":"Edit",
  "К админам":"To the admins",
  "В архив":"To the archive",
  "Не указана":"Not specified",
  "Ожидает":"Waiting",
  "Комментарий к отзыву":"Comment on the review",
  "Диспетчер":"Dispatcher",
  "Вы еще не оценили поездку":"You haven't rated the trip yet",
  "Политика конфиденциальности":"Privacy Policy",
  "Перенесение поездки":"Postponement of trip",
  "Номера телефона контактного лица":"Phone numbers of the contact person",
  "Передать пассажира":"Pass the passenger",
  "Отмена брони":"Cancellation",
  "Дата приглашения":"Date of invitation",
  "Номер авто":"Car number",
  "Нельзя пригласить водителя, работающего в другой компании":"You cannot invite a driver working for another company",
  "Введите номер карты":"Enter card number",
  "Водителю":"To the driver",
  "Поездка":"Drive",
  "Начало поездки":"Start of the trip",
  "Ошибка":"Error",
  "Описание багажа":"Baggage description",
  "Общая сумма водителю":"Total amount to the driver",
  "Вам на почту отправлено письмо с кодом подтверждения для смены пароля":"An email has been sent to you with a confirmation code to change your password.",
  "Подтвердите согласие с правилами и политикой конфиденциальности":"Confirm your agreement with the rules and privacy policy",
  "Забронировать место в текущей поездке":"Book a seat on your current trip",
  "Отсутствует описание груза":"Cargo description missing",
  "Да":"Yes",
  "Наименование":"Name",
  "Начисление бонуса":"Bonus accrual",
  "Текстовые страницы":"Text pages",
  "Дата рождения":"Date of Birth",
  "Повторить отправку кода":"Resend the code",
  "Комментарий к поездке":"Comment on the trip",
  "Подтвердить и перейти к заполнению":"Confirm and proceed to filling out",
  "Повторно отправить можно через 3 мин":"You can resend in 3 minutes",
  "Превышено число попыток входа. Попробуйте позже":"The number of login attempts has been exceeded. try later",
  "Данные пользователя":"User data",
  "Начать поездку":"Start your trip",
  "Успешная":"Successful",
  "Неверный SWIFT-код":"Invalid SWIFT code",
  "Найденные пассажиры":"Passengers found",
  "ФИО":"Full name",
  "Почта":"Mail",
  "Приглашения от компаний":"Invitations from companies",
  "Добавление":"Addition",
  "Выбранные места недоступны":"The chosen places are unavailable",
  "Забрать пассажиров по адресу":"Pick up passengers at their address",
  "Подобрать поездку":"Find a trip",
  "Модель авто":"Car model",
  "Вознаграждение диспетчера":"Dispatcher's remuneration",
  "Пункт отправления":"Point of departure",
  "Ожидает подтверждения диспетчера":"Waiting for dispatcher confirmation",
  "К пользователям":"To users",
  "Тип":"Type",
  "Пассажиры в поездке":"Passengers on a trip",
  "Нельзя редактировать активную поездку":"Passengers found",
  "Перевозчик":"Carrier",
  "Диспетчер отменил поездку для пассажира":"The dispatcher canceled the passenger's trip",
  "Отменить поездку":"Cancel trip",
  "К поездкам других пользователей":"To the trips of other users",
  "Перенести в раздел Поездки":"Move to Trips section",
  "Мой кошелек/история транзакций":"My wallet/transaction history",
  "Выйти из архива":"Leave the archive",
  "Процент бонуса для перевозчика":"Bonus percentage for carrier",
  "Я должен":"I must",
  "Предложения":"Offers",
  "Пополнить баланс":"Top up balance",
  "Опции":"Options",
  "Подтвердить получение":"Confirm receipt",
  "Свободная":"Free",
  "Количество мест в поездке":"Количество мест в поездке",
  "Отзыв":"Review",
  "Место для багажа в салоне":"Luggage space in the cabin",
  "Название компании-работодателя":"Employer company name",
  "Пассажиры не найдены":"No passengers found",
  "Номера мест":"Seat numbers",
  "Неверный код":"Incorrect code",
  "Добавление водителя":"Adding a driver",
  "Вы не можете видеть отзыв о Вас по поездке пока не оставите свой":"You cannot see a review about your trip until you leave yours.",
  "Цена за одно место":"Price per seat",
  "Стоимость должна быть больше нуля":"Cost must be greater than zero",
  "Пассажирское_Грузовое":"Passenger_Cargo",
  "Произошла ошибка, платеж не осуществлен":"An error occurred, payment was not made",
  "Одобрена":"Approved",
  "Бронь уже отменена":"Reservation has already been cancelled.",
  "Роль":"Role",
  "Наличные по договоренности":"Cash by agreement",
  "Отдельное место для багажа":"Separate place for luggage",
  "Регистрация":"Registration",
  "Отмененные заявки на поездки":"Canceled travel requests",
  "Дефолт":"Default",
  "Активная":"Active",
  "Обозначение языка":"Language designation",
  "Маршрут пассажира":"Passenger route",
  "в ряду":"in a row",
  "Нужно ли отдельное место для перевозки животного":"Do I need a separate place to transport an animal?",
  "Добавить поездку":"Add a trip",
  "Месяц":"Month",
  "Данные о поездке":"Trip details",
  "Пользователи":"Users",
  "В вашу поездку забронирован пассажир":"A passenger has been booked for your trip",
  "грн.":"UAH",
  "Ваш пассажир забронирован в поездку компании":"Your passenger is booked on a company trip",
  "Дата прибытия не может быть раньше даты отправления":"Arrival date cannot be earlier than departure date",
  "Использовать почту как логин":"Use email as login",
  "Принято":"Accepted",
  "Тип транзакции":"Transaction type",
  "Активные поездки":"Active trips",
  "Подтверждение бронирования":"Booking confirmation",
  "Редактирование данных":"Data editing",
  "Редактирование ТС":"Editing a vehicle",
  "Водитель предлагает другие финансовые условия":"The driver offers other financial conditions",
  "Пользовательское соглашение и Политика конфиденциальности":"User Agreement and Privacy Policy",
  "Список поездок":"List of trips",
  "В поездку найдены такие пассажиры":"Such passengers were found on the trip",
  "Создана":"Created",
  "Удалить промежуточный пункт":"Delete waypoint",
  "Сумма вывода":"Withdrawal amount",
  "Ссылка для регистрации невалидна. Вы будете зарегистрированы в качестве обычного пользователя":"The registration link is invalid. You will be registered as a regular user",
  "Время выезда":"Check out time",
  "Отправить":"Send",
  "Брони для пассажира":"Passenger reservations",
  "Пароль изменен":"Password changed",
  "Завершена":"Completed",
  "Раздел":"Chapter",
  "Выполненные":"Completed",
  "Пункт выезда":"Departure point",
  "Населенный пункт":"Locality",
  "Комментарий к отмене поездки":"Comment on trip cancellation",
  "Вид перевозки":"Type of transportation",
  "приглашает Вас в приложение IntDriver":"invites you to the IntDriver application",
  "Указанные водители заняты в другой поездке:":"The following drivers are busy on another trip:",
  "Подтвердите новый пароль":"Confirm your new password",
  "Предложить другие условия гонорара":"Offer other fee conditions",
  "Удалить водителя":"Remove driver",
  "Заморожена сумма на счету по пассажиру":"The amount on the passenger's account is frozen",
  "Подтвержден":"Confirmed",
  "Сумма должна быть больше нуля":"The amount must be greater than zero",
  "Новые дополнительные опции сохранены в базе данных":"New additional options saved in the database",
  "Выполнена":"Completed",
  "Вы пытаетесь сохранить пустой адрес. Ошибка в строке номер":"You are trying to store an empty address. Error in line number",
  "День":"Day",
  "Необходимо заменить места":"Need to replace seats",
  "Приглашение":"Invitation",
  "Нет поездок":"No trips",
  "Список избранных водителей":"List of favorite drivers",
  "Компании":"Companies",
  "Сгенерировать ссылку":"Generate link",
  "Редактирование админа":"Editing admin",
  "Оценка уже поставлена. Вы не можете оставить отзыв":"The rating has already been given. You cannot leave a review",
  "Обязательно добавьте номера мест в схеме с помощью кнопки \"Редактировать\"":"Be sure to add seat numbers in the diagram using the \"Edit\" button",
  "Непринятые":"Unaccepted",
  "Индивидуальная":"Individual",
  "Поездка уже не подходит пассажиру. Осуществите поиск заново":"The trip is no longer suitable for the passenger. Search again",
  "Отправить код":"Send code",
  "Добавьте места посадки пассажиров":"Add passenger boarding areas",
  "У пассажира уже есть подтвержденная поездка":"The passenger already has a confirmed trip",
  "В пути":"On my way",
  "Изменений не было":"There were no changes",
  "Прирост":"Growth",
  "Страна":"A country",
  "Забронирована":"Booked",
  "Схема не может быть пустой":"The schema cannot be empty",
  "Схема не может состоять только из пустых мест":"A diagram cannot consist only of empty spaces",
  "Пополнить":"Top up",
  "Пассажир был удален":"The passenger has been removed",
  "Введите населенный пункт ...":"Enter your locality...",
  "Вас удалили из поездки компании":"Planned departure date and time:",
  "Отзывы о водителе":"Driver reviews",
  "Добавление админа":"Adding an admin",
  "Новые места от диспетчера":"New places from the dispatcher",
  "Подтвердить получение оплаты":"Confirm receipt of payment",
  "Брони диспетчеров":"Dispatcher reservations",
  "Количество мест в транспортных средствах не совпадает, замена невозможна":"The number of seats in vehicles does not match, replacement is not possible",
  "Нет приглашений":"No invitations",
  "Приказ":"Order",
  "Зарегистрироваться через":"Register via",
  "Создайте и сохраните схему или выберите свободную рассадку":"Create and save a chart or choose an open seating arrangement",
  "Список водителей, у которых есть задолженность по пассажиру перед диспетчером":"List of drivers who have a passenger debt to the dispatcher",
  "Договоритесь с водителем о месте посадки после подтверждения брони":"Agree with the driver about the pickup location after confirming your reservation",
  "Адреса водителя":"Driver addresses",
  "Лицензия Приказ":"License Order",
  "Вы уверены, что хотите удалить страницу?":"Are you sure you want to delete the page?",
  "Диспетчер отклонил Вашу бронь по пассажиру":"The dispatcher rejected your passenger reservation",
  "Заявка на вывод средст принята":"The withdrawal request has been accepted",
  "Какой-то текст с коротким описанием сервиса":"Some text with a short description of the service",
  "Спасибо! Ваш счет успешно пополнен!":"Thank you! Your account has been successfully replenished!",
  "Выбор автомобиля":"Car selection",
  "Диспетчер оценил поездку пассажира":"The dispatcher assessed the passenger's trip",
  "Ошибка бронирования":"Booking error",
  "Добавить транзакцию":"Add transaction",
  "Тип кузова":"Body type",
  "Настройки":"Settings",
  "Всего на балансе":"Total balance",
  "Забронировано успешно":"Booked successfully",
  "Сумма транзакции":"Transaction amount",
  "Желаемая цена":"Desired price",
  "Добавить водителя":"Add driver",
  "SWIFT-код":"SWIFT-code",
  "Вознаграждение диспетчеру":"Dispatcher remuneration",
  "Год":"Year",
  "Ошибка ввода данных":"Data entry error",
  "Некорректный CVV":"Incorrect CVV",
  "Добавить номер":"Add a number",
  "Водители из компаний":"Moves out",
  "Обмен":"Exchange",
  "Основные данные":"Basic data",
  "Стоимость билета у перевозчика":"Ticket price from the carrier",
  "Личные данные":"Personal data",
  "Гонорар диспетчера":"Dispatcher's fee",
  "Невозможно подтвердить бронь. У перевозчика недостаточно средств":"Unable to confirm reservation. The carrier does not have enough funds",
  "Имя владельца карты":"Cardholder Name",
  "Транспортное средство занято в другой поездке":"The vehicle is busy on another trip",
  "Ок":"OK",
  "Имя пассажира":"Passenger name",
  "Старый пароль":"Old Password",
  "К сожалению подходящие пассажиры не найдены":"Unfortunately no suitable passengers were found",
  "Выберите транспортное средство":"Select vehicle",
  "Весь период":"All period",
  "Посмотреть места":"View places",
  "Мест":"Places",
  "Общая сумма диспетчеру":"Total amount to dispatcher",
  "Номер":"Number",
  "Брони от диспетчеров":"Reservations from dispatchers",
  "Детали поездки":"Trip details",
  "Не произошла выплата":"Payment did not occur",
  "Новый пассажир для поездки":"New passenger for the trip",
  "Количество билетов":"Number of tickets",
  "C животными":"With animals",
  "Отменена":"Canceled",
  "Редактирование пассажира":"Editing a passenger",
  "Все поездки":"All trips",
  "Описание допустимого груза":"Description of permissible load",
  "Поездка отменена":"Trip canceled",
  "Поездки водителя":"Driver trips",
  "Недопустимые символы в имени или превышена длина":"Invalid characters in name or length exceeded",
  "Обозначение":"Designation",
  "Логин":"Login",
  "Заявки":"Applications",
  "Активные заявки диспетчера":"Active dispatcher requests",
  "По вашей реферальной ссылке зарегистрировался пользователь":"A user registered using your referral link",
  "Дополнительно":"Additionally",
  "Выбрать язык из БД":"Select language from database",
  "Мои водители":"My drivers",
  "Неоплаченные":"Unpaid",
  "Вы уже приглашали водителя с указанным email":"You have already invited a driver with the specified email",
  "Не совпадают дополнительные опции. Все равно хотите забронировать?":"Additional options do not match. Still want to book?",
  "К моим пассажирам":"To my passengers",
  "Тип страницы":"Page type",
  "Появился новый пассажир для поездки":"There is a new passenger for the trip",
  "Списана комиссия за сделку":"Debited commission for the transaction",
  "Сохранить рассадку":"Save seating",
  "Успех":"Success",
  "Вам начислен бонус за приглашение пользователя":"You have been awarded a bonus for inviting a user",
  "Для продолжения работы с приложением необходимо заполнить все данные":"To continue working with the application, you must fill in all the data",
  "Грузовое":"Freight",
  "Оценка диспетчера":"Dispatcher rating",
  "Пригласить":"Invite",
  "Дата отмены":"Cancellation date",
  "Страница":"Page",
  "Маршрут:":"Route:",
  "Принятые":"Accepted",
  "Номер карты должен содержать 16 или 18 цифр":"The card number must contain 16 or 18 digits",
  "Искать место...":"Search for a place...",
  "Добавление в поездку в качестве водителя":"Adding to a trip as a driver",
  "Изменить пароль":"Change password",
  "Марка авто":"car brand",
  "Предыдущая неделя":"Previous week",
  "Диспетчер подтвердил Вашу бронь по пассажиру":"The dispatcher confirmed your passenger reservation",
  "Отправить запрос":"Send request",
  "Заявки на выплаты":"Applications for payments",
  "Дней назад":"Days ago",
  "Редактирование":"Editing",
  "Списание вознаграждения диспетчеру":"Write-off of remuneration to the dispatcher",
  "Имя пассажира:":"Passenger name:",
  "Неверная сумма":"Invalid amount",
  "Статус":"Status",
  "Напишите сообщение":"Write a message",
  "У Вас недостаточно средств. Диспетчер не смог подтвердить бронь для пассажира":"You don't have enough funds. The dispatcher was unable to confirm the passenger's reservation",
  "Данные контактного лица":"Contact details",
  "Сумма выплаты":"Payment amount",
  "Нельзя создать поездку с прошедшей датой":"You cannot create a trip with a past date",
  "Вы подтверждаете, что получили выплату по обмену":"You confirm that you have received the exchange payment",
  "Отклонение брони":"Booking Rejection",
  "Диспетчер изменил данные пассажира":"The dispatcher changed the passenger's data",
  "Заявки на вывод":"Withdrawal requests",
  "Водитель подтвердил Вашу бронь по пассажиру":"The driver confirmed your reservation as a passenger",
  "Войти в учетную запись":"Login to account",
  "Дополнительные параметры":"Extra options",
  "Забронировано":"Booked",
  "Добавление пассажира":"Adding a passenger",
  "Дата отправления":"departure date",
  "Превышено число попыток входе. Попробуйте позже":"The number of login attempts has been exceeded. Try later",
  "Активные":"Active",
  "Тип рассадки":"Seating type",
  "Пропустить и зарегистрироваться":"Skip and register",
  "Выберите подходящую поездку и забронируйте место для пассажиров":"Select a suitable trip and reserve a seat for passengers",
  "весь период":"all period",
  "Необходимо выбрать способ оплаты":"You must select a payment method",
  "Ссылка для приглашения":"Invite link",
  "Невыполненные":"Unfulfilled",
  "Подтвердить":"Confirm",
  "Неверная дата рождения. Указанной даты не существует":"Invalid date of birth. The specified date does not exist",
  "Вы пытаетесь предложить места, которые уже заняты":"You are trying to offer places that are already taken",
  "Не удалось выполнить действие":"Action failed",
  "Свободная рассадка":"Free seating",
  "Текущая неделя":"This week",
  "Количество рядов":"Number of rows",
  "Пеший переход":"Pedestrian crossing",
  "Добавление страницы":"Adding a page",
  "Водители":"Drivers",
  "Логин/почта":"Login/mail",
  "Добавить схему рассадки":"Add seating chart",
  "Описание груза":"Description of cargo",
  "Стоимость перевозки":"Transportation cost",
  "пользователю":"user",
  "Не заполнены адреса посадки":"Pickup addresses not filled in",
  "Маршрут поездки":"Travel route",
  "Желаемые дата и время выезда:":"Desired departure date and time:",
  "Повторить пароль":"repeat password",
  "Автомобили":"Cars",
  "Почта службы поддержки":"Support email",
  "Реферальная программа":"Referral program",
  "Зайдите в пункт меню профиля \"Приглашения от компаний\"":"Visit profile menu item 'Invitations from companies'",
  "Количество мест":"Number of seats",
  "Способ оплаты":"Payment method",
  "Пассажиры":"Passengers",
  "Дней вперед":"days ahead",
  "Перевести в обмен":"Transfer to exchange",
  "Заявки на поездки":"Travel requests",
  "Списана комиссия по пассажиру":"Passenger commission written off",
  "Зарезервированные средства":"Reserved Funds",
  "Фото (размер 400х400)":"Photo (size 400x400)",
  "Новые места от водителя":"New driver's seats",
  "Желаемый гонорар":"Desired fee",
  "дней":"days",
  "Все пользователи":"All users",
  "Водителя уже нет в списке":"The driver is no longer on the list",
  "Все взаиморасчеты по обменам закрыты":"All mutual settlements on exchanges are closed",
  "Ожидание оплаты":"Booked",
  "Отмененные поездки":"Canceled trips",
  "Информация":"Information",
  "Пассажир перемещен в блокнот":"Passenger moved to notepad",
  "Отзыв водителя":"Driver review",
  "Администратор добавлен":"Administrator added",
  "Войти через":"Login via",
  "Пользователь не найден":"User is not found",
  "Добавить номер телефона":"Add a phone number",
  "Брони от водителей":"Reservations from drivers",
  "Перевозчик отменил поездку для пассажира":"The carrier canceled the passenger's trip",
  "Вы не можете принять приглашение":"You cannot accept the invitation",
  "Администратор":"Administrator",
  "Сумма пополнения":"Top up amount",
  "Лицензия":"License",
  "Приглашение водителя":"Driver invitation",
  "Ошибка в имени – недопустимые символы или превышена длина":"Name error - invalid characters or length exceeded",
  "Ошибка ввода имени":"Name input error",
  "Сохранить транзакцию":"Save transaction",
  "Статус поездки":"Travel status",
  "Необходимо мест":"Required seats",
  "Вывод средств":"Withdrawal of funds",
  "Дата":"date",
  "Заполните все поля":"Fill in all the fields",
  "Приглашения":"Invitations",
  "Редактирование страницы":"Editing a page",
  "Чтобы зарегистрироваться, перейдите по ссылке":"To register, follow the link",
  "Введите данные о месте":"Enter location details",
  "Мои автомобили":"My cars",
  "Конечный пункт":"Final destination",
  "Обработка":"Treatment",
  "Комментарий к отмене":"Cancellation comment",
  "Номер телефона":"Phone number",
  "Номера телефона":"Phone numbers",
  "Маршрут следования пассажира в поездке":"Passenger's travel itinerary",
  "Оплачено":"Paid",
  "Наименование языка":"Language name",
  "Водитель оценил поездку пассажира":"The driver rated the passenger's trip",
  "Появилась новая поездка для пассажира":"There is a new ride for the passenger",
  "Комментарий слишком короткий":"Comment is too short",
  "Диспетчеру":"To the dispatcher",
  "Посадка по адресу пассажира":"Boarding at passenger address",
  "Не определено":"Is not defined",
  "Сохранить пассажира":"Save passenger",
  "Работаю сам":"I work by myself",
  "Перевозчик удалил поездку":"The carrier has deleted the trip",
  "Подходящие пассажиры":"Eligible Passengers",
  "Ссылка на App Store":"App Store Link",
  "Отклонено":"Rejected",
  "Сегодня":"Today",
  "IBAN":"IBAN",
  "Пассажир":"Passenger",
  "Выбор справочника":"Selecting a directory",
  "Договоритесь с диспетчером о месте посадки после подтверждения брони":"Agree with the dispatcher about the landing location after confirming your reservation",
  "Опубликовать поездку":"Post a trip",
  "Добавление номера":"Adding a number",
  "Показать рефералов":"Show referrals",
  "Сохранить поездку":"Save trip",
  "Что-то пошло не так":"Something went wrong",
  "Некорректный номер телефона":"Wrong phone number",
  "Внутренняя":"Internal",
  "Не найдено":"Not found",
  "Страницы":"Pages",
  "Оценить диспетчера":"Rate the dispatcher",
  "Типы кузова":"Body types",
  "Добавьте комментарий":"Add a comment",
  "Добавить":"Add",
  "Выбрать даты":"Select dates",
  "Зарегистрироваться может только совершеннолетний":"Only an adult can register",
  "Пополнение счета":"Refill",
  "нужно дополнительное место":"need extra space",
  "Пользователь не существует":"User does not exist",
  "Началась поездка пассажира":"The passenger's journey has begun",
  "Удаление из поездки в качестве водителя":"Removing from a trip as a driver",
  "Обязательное поле для заполнения":"Required field",
  "Мои поездки":"My trips",
  "Новая бронь для пассажира":"New passenger reservation",
  "Неверный формат почты":"Invalid mail format",
  "Количество свободных мест":"Number of available seats",
  "Необходимо выбрать способ получения гонорара":"You must choose a payment method",
  "Схема ТС":"Vehicle diagram",
  "Начисление гонорара":"Calculation of fees",
  "Финансовые результаты":"Financial results",
  "К сожалению, подходящих поездок не найдено":"Sorry, no matching trips found",
  "Неверный пароль":"Incorrect password",
  "Статистика за":"Statistics for",
  "Изменить рассадку":"Change seating",
  "Подтверждение брони":"Booking confirmation",
  "Диспетчер выбрал другого исполнителя по пассажиру":"The dispatcher chose another performer based on the passenger",
  "Id":"Id",
  "Забронированные поездки":"Booked trips",
  "При регистрации необходимо использовать ту же почту, на которую пришло приглашение":"When registering, you must use the same email to which the invitation was sent.",
  "Подтверждение действия":"Action confirmation",
  "Оценить позже":"Rate later",
  "Поездка уже не подходит пассажиру.' +           ' Осуществите поиск заново":"The trip is no longer suitable for the passenger.' + ' Search again",
  "Значение поля невалидно":"Field value is invalid",
  "Отменить":"Cancel",
  "Другие условия гонорара":"Other fee conditions",
  "Служба поддержки":"Support",
  "Указанный водитель занят в другой поездке:":"The specified driver is busy on another trip:",
  "Перенесение в пассажиры":"Transfer to passengers",
  "Дебиторы":"Debtors",
  "Разморожена сумма на счету по пассажиру":"The amount on the passenger's account has been unfrozen",
  "Выбрать новый язык":"Choose a new language",
  "Введите номер":"Enter number",
  "Ошибка в имени пользователя. Допускаются только буквы, пробелы и дефисы. Длина не больше 30 символов":"Error in username. Only letters, spaces and hyphens are allowed. Length no more than 30 characters",
  "Водитель":"Driver",
  "Найти пассажиров в поездку":"Find passengers on your trip",
  "Оценка":"Grade",
  "Количество мест на схеме не совпадает с указанным количеством мест в ТС":"The number of seats on the diagram does not match the indicated number of seats in the vehicle",
  "Выбор типа получения гонорара":"Selecting the type of royalty received",
  "Мой кошелек":"My wallet",
  "Общая сумма":"Total amount",
  "Неверный IBAN":"Invalid IBAN",
  "Имя получателя":"Receiver name",
  "Вы пытаетесь сохранить пустой адрес. Ошибка в строке номер ":"You are trying to store an empty address. Error in line number",
  "Сохранить схему":"Save scheme",
  "Желаемая стоимость билета:":"Desired ticket price:",
  "Наличными":"Cash",
  "Нет пассажиров":"No passengers",
  "Места посадки":"Landing locations",
  "Редактирование запрещено. Поездка взята водителем":"Editing is prohibited. Ride taken by driver",
  "Отобразить схему":"Show diagram",
  "Куда":"Where",
  "Комментарий к отмене брони":"Comment on cancellation",
  "Нельзя перенести поездку с прошедшей датой":"You cannot replace trip with a past date",
  "Сумма гонорара должна быть больше нуля":"The fee amount must be greater than zero",
  "Заморожена сумма на счету":"The amount in the account is frozen",
  "Пассажирское":"Passenger",
  "Новый пароль":"New Password",
  "Ваш счет пополнен на сумму":"Your account has been replenished with the amount",
  "Следующая":"Next",
  "Индивидуальные перевозчики":"Individual carriers",
  "По обмену":"In exchange",
  "Вам отправлен запрос на оплату по обмену":"A request for exchange payment has been sent to you",
  "Введите удобный Вам адрес":"Enter the address convenient for you",
  "Имя диспетчера":"Dispatcher name",
  "Номер документа":"Document Number",
  "Новая поездка для пассажира":"New ride for the passenger",
  "Отзывы о диспетчере":"Reviews about the dispatcher",
  "Активные заявки пользователя":"Active user requests",
  "Создать":"Create",
  "Найдите своего перевозчика":"Find your carrier",
  "Отзыв диспетчера":"Dispatcher review",
  "Отмена поездки":"Trip cancellation",
  "Новый отзыв":"New review",
  "Завершенные поездки":"Completed trips",
  "Грузовая":"Freight",
  "Оценки":"Ratings",
  "Тип получения":"Receipt type",
  "Недостаточно средств":"Insufficient funds",
  "Детали транзакции":"Transaction Details",
  "Завершение поездки":"End of the trip",
  "Избранные водители":"Featured Drivers",
  "Найти пассажира":"Find passenger",
  "Вид рассадки":"Seating type",
  "Необходимо сохранить схему или поменять тип рассадки":"New additional options saved in the database",
  "Гонорары диспетчерам":"Dispatcher fees",
  "Компенсация за обмен":"Compensation for exchange",
  "Перевозчик отклонил Вашу бронь по пассажиру":"The carrier has rejected your passenger reservation",
  "Уведомления":"Notifications",
  "Адрес пассажиров":"Passenger address",
  "У пассажира уже есть бронь в данной поездке":"The passenger already has a reservation for this trip",
  "Промежуточные пункты":"Intermediate points",
  "Выбор мест":"Selection of seats",
  "Авто":"Auto",
  "мм/гг":"mm/yy",
  "Введите сумму пополнения. Сумма должна быть больше нуля":"Enter the top-up amount. The amount must be greater than zero",
  "Изменение статуса поездки":"Change of trip status",
  "Отправлена жалоба":"Complaint sent",
  "Ошибка! Воспользуйтесь авторизацией через гугл":"Error! Use authorization via Google",
  "Выбор рассадки":"Seating selection",
  "Дата прибытия":"Arrival date",
  "Перенести в раздел пассажиры":"Move to passengers section",
  "Сумма":"Sum",
  "Администраторы":"Administrators",
  "Номер карты":"Card number",
  "Список пассажиров":"Passenger list",
  "Отсутствует":"Absent",
  "Ожидает оплаты":"Awaiting payment",
  "Сбросить":"Reset",
  "Опубликовать предложение для диспетчеров":"Publish an offer for dispatchers",
  "Уведомление о брони":"Reservation Notification",
  "Редактирование поездки":"Editing a trip",
  "Оценка водителя":"Driver rating",
  "Детали заявки пассажира на поездку":"Passenger travel request details",
  "Дополнительные опции для поездок":"Additional travel options",
  "Свернуть календарь":"Collapse calendar",
  "Новые места для согласования":"New locations for approval",
  "Вас добавили водителем в поездку компании":"You have been added as a driver to a company trip",
  "Промокод":"Promo code",
  "Перевозчик изменил данные поездки":"The carrier has changed the trip details",
  "Место посадки пассажиров":"Passenger boarding area",
  "Маршрут на карте":"Route on the map",
  "E-mail":"E-mail",
  "Уведомление":"Notification",
  "Предыдущий месяц":"Previous month",
  "Добавить пассажира":"Add a passenger",
  "Максимальное количество мест":"Maximum number of seats",
  "Некорректный срок действия карты":"Invalid card expiration date",
  "Дополнительная информация":"Additional Information",
  "Бонусы от рефералов":"Bonuses from referrals",
  "Оценить водителя":"Rate the driver",
  "Предыдущая":"Previous",
  "Дата и время выезда:":"Departure date and time:",
  "Сохранить изменения":"Save changes",
  "Можно оставить места":"You can leave some space",
  "Оценка приложения":"Application rating",
  "Не выбран адрес посадки пассажиров":"Passenger pick-up address not selected",
  "На счету недостаточно средств":"There are insufficient funds in the account",
  "Добавить язык":"Passenger name",
  "Черновик":"Draft",
  "Не указан":"Not specified",
  "К пассажирам других пользователей":"To passengers of other users",
  "В этом разделе отображаются созданные Вами поездки (их видите только Вы)":"This section displays the trips you have created (only you can see them)",
  "Водители не должны совпадать":"Drivers must not match",
  "Комиссия приложения":"Application commission",
  "Восстановление пароля":"Password recovery",
  "Гонорары от водителей":"Fees from drivers",
  "Выбрать язык":"Select language",
  "Вознаграждение диспетчера:":"Dispatcher remuneration:",
  "Приглашения от компании":"Invitations from the company",
  "Пассажирская":"Passenger",
  "К моим поездкам":"To my trips",
  "Транзакции":"Transactions",
  "Плательщик":"Payer",
  "Что-то пошло не так. Проверьте транзакции":"Something went wrong. Check transactions",
  "Найти пассажиров":"Find passengers",
  "Поездка уже отменена":"The trip has already been cancelled.",
  "Запланированные дата и время выезда:":"Planned departure date and time:",
  "Админ":"Admin",
  "Запланированные поездки пользователя":"User's planned trips",
  "Публикация пассажира в общий доступ":"Publishing a passenger to the public",
  "Резервирование средств":"Reserving funds",
  "Перевозчик выбрал другого пассажира вместо Вашего пассажира":"The carrier has chosen another passenger instead of your passenger",
  "Архивные":"Archival",
  "Пароли не совпадают":"Password mismatch",
  "Сделать взаиморасчет":"Make a settlement",
  "Главная":"home",
  "Сохранить адреса":"Save addresses",
  "Введите старый пароль":"Enter old password",
  "Вывести средства":"Withdraw funds",
  "Желаемое вознаграждение диспетчера:":"Desired remuneration for the dispatcher:",
  "Комиссия":"Commission",
  "Необходимо оставить комментарий":"You must leave a comment",
  "ФИО пользователя":"User's full name",
  "Завтра":"Tomorrow",
  "Компания":"Company",
  "Введен невалидный email":"Invalid email entered",
  "Списано вознаграждение диспетчеру по пассажиру":"The passenger dispatcher's remuneration has been written off",
  "Уведомление о поездке":"Travel Notice",
  "Новые условия от диспетчера":"New conditions from the dispatcher",
  "Направление поездки":"Travel destination",
  "Отмена приглашения":"Cancel invitation",
  "Мои пассажиры":"My passengers",
  "Тип перевозки":"Type of transportation",
  "Время отправления":"Departure time",
  "Обновить адреса":"Update addresses",
  "Тип поездки":"Trip type",
  "Вернуться":"Return",
  "Начисление вознаграждения за пассажира":"Calculation of rewards for passengers",
  "Название страницы":"Page title",
  "Счет не пополнен. Ошибка платежной системы":"The account has not been replenished. Payment system error",
  "Настройки проекта":"Project Settings",
  "Добавление ТС":"Adding a vehicle",
  "Выйти":"Go out",
  "Подтвердить отмену":"Confirm cancellation",
  "По адресу водителя":"To the driver's address",
  "Уведомление об оплате":"The carrier announced payment",
  "Недопустимый номер места или места на схеме не уникальны":"The location number is invalid or the location on the diagram is not unique",
  "Вы приглашаете стать водителем пользователя":"You invite us to become a user driver",
  "Количество бронируемых мест больше количества свободных мест в поездке":"The number of reserved seats is greater than the number of available seats on the trip",
  "Стоимость билета":"Ticket price",
  "Назад к выбору":"Back to selection",
  "Ваш отзыв":"your feedback",
  "Профиль":"Profile",
  "Неизвестная ошибка. Попробуйте позже":"Unknown error. try later",
  "Получен бонус за сделку реферрера":"Received bonus for referrer deal",
  "История транзакций":"Transaction history",
  "Промежуточный пункт":"Intermediate point",
  "Списание гонорара":"Write-off of fees",
  "Нет":"No",
  "Скрыть места":"Hide places",
  "Запланированные поездки водителя":"Scheduled driver trips",
  "Выберите язык":"Choose language",
  "Получение гонорара":"Receiving a fee",
  "Пункт прибытия":"Arrival point",
  "Удалить":"Delete",
  "Цена":"Price",
  "Марка":"Brand",
  "Изменить перевод":"Change translation",
  "Добавление пассажиров в поездку":"Adding passengers to a trip",
  "Найти":"Find",
  "Ближайшая поездка ТС":"Next vehicle trip",
  "Опубликовать предложение для водителей":"Post an offer for drivers",
  "Получатель":"Recipient",
  "Получен бонус за сделку реферала":"Received bonus for referral transaction",
  "Необходимо выбрать тип страницы":"You must select a page type",
  "Создание поездки":"Create a trip",
  "Запрошенный маршрут пассажира":"Requested passenger route",
  "Поставьте оценку диспетчеру":"Rate the dispatcher",
  "Выплата комиссий, бонусов":"Payment of commissions, bonuses",
  "Телефон":"Telephone",
  "Схема рассадки":"Seating chart",
  "Пользователь с такой электронной почтой уже существует":"A user with this email already exists",
  "Пароль":"Password",
  "Поездка была удалена":"Trip has been deleted",
  "Сохранить и зарегистрироваться":"Save and register",
  "Список диспетчеров, у которых есть задолженность по пассажиру перед водителем":"List of dispatchers who have a passenger debt to the driver",
  "Авто на карте":"Auto on the map",
  "Пропустить":"Skip",
  "Цвет авто":"Car color",
  "Водители в поездке:":"Drivers on the trip:",
  "Неактивная":"Inactive",
  "из":"from",
  "Сумма на счету":"Account amount",
  "Завершить поездку":"End the trip",
  "Отзывы":"Reviews",
  "Нельзя выводить больше, чем есть на счету":"You cannot withdraw more than is in your account",
  "Нельзя удалить активную поездку":"Can't delete an active trip",
  "Блокнот":"Notebook",
  "Стоимость поездки":"The cost of travel",
  "Поиск":"Search",
  "Мои брони":"My reservations",
  "Вид разрешающего документа":"Type of permit document",
  "Вас пригласили стать водителем в компании":"You have been invited to become a driver for a company",
  "Город":"City",
  "Бронирование пассажира в поездку":"Booking a passenger for a trip",
  "Брони водителей":"Driver reservations",
  "Выбран другой контрагент":"Another counterparty has been selected",
  "Места посадки пассажиров":"Passenger boarding areas",
  "Добавить промежуточный пункт":"Add waypoint",
  "Перенесение в поездки":"Transfer to travel",
  "Вам на почту отправлено письмо с ссылкой для смены пароля":"An email has been sent to you with a link to change your password.",
  "Забронировать":"Book",
  "Свободные средства":"Available funds",
  "Диспетчер отозвал свою бронь":"The dispatcher has withdrawn his reservation",
  "Детали брони":"Armor details",
  "Разморожена сумма на счету":"Account amount unfrozen",
  "Кому":"To whom",
  "Можно с животными":"Pets allowed",
  "Юзер":"User",
  "Завершилась поездка пассажира":"The passenger's trip has ended",
  "Цена за место":"Price per seat",
  "Произошла ошибка, заявка не была создана":"An error occurred, the application was not created",
  "Пароль должен быть не короче 6 символов":"The password must be at least 6 characters long",
  "Мои рефералы":"My referrals",
  "Нельзя отменить подтвержденную бронь":"Confirmed reservations cannot be cancelled.",
  "Кредиторы":"Creditors",
  "Зарегистрироваться":"Register",
  "Вы действительно хотите удалить поездку?":"Are you sure you want to delete the trip?",
  "Дата действия":"Valid date",
  "Пополнение аккаунта через карту":"Top up your account via card",
  "Пополнение счета аккаунта":"Account replenishment",
  "Необходимо оплатить":"Need to pay",
  "Диспетчер, создавший пассажира:":"Dispatcher who created the passenger:",
  "Соглашаюсь с Политикой конфиденциальности и Пользовательским соглашением":"I agree with the Privacy Policy and User Agreement",
  "Цвет транспорта":"Transport color",
  "Что-то пошло не так. Ваше сообщение не было отправлено":"Something went wrong. Your message was not sent",
  "Название компании":"Company name",
  "Полный маршрут поездки":"Full trip itinerary",
  "Добавить в поездку":"Add to trip",
  "Адреса, предлагаемые водителем":"Addresses offered by the driver",
  "Отменена поездка пассажира":"Passenger trip canceled",
  "Данные второго водителя не заполнены":"The second driver's details are not filled in",
  "Сохранить":"Save",
  "Страница такого типа уже существует":"This type of page already exists",
  "История выполненных поездок":"History of completed trips",
  "Посмотреть брони":"View reservations",
  "ФИО контактного лица":"Full name of contact person",
  "Выбор адреса посадки":"Choosing a pick-up address",
  "Мои должники":"My debtors",
  "Конфликт забронированных мест":"Booking conflict",
  "Сумма гонорара на обмен":"Exchange fee amount",
  "Принять":"Accept",
  "Подтверждение оплаты за обмен":"Confirmation of payment for exchange",
  "Комиссии от пользователей":"Commissions from users",
  "Брони для поездки":"Travel reservations",
  "Номер места":"Place number",
  "Ожидает подтверждения водителя":"Waiting for driver confirmation",
  "Произошла ошибка, данные не сохранены":"An error occurred, the data was not saved",
  "Опубликовать изменения":"Publish changes",
  "Начислено вознаграждение по пассажиру":"Passenger reward accrued",
  "Добавить адрес":"Add address",
  "Новые условия для согласования":"New conditions for agreement",
  "Новые условия от водителя":"New conditions from the driver",
  "Поиск пассажиров в поездку":"Search for passengers on a trip",
  "Текущий месяц":"Current month",
  "Нельзя редактировать транспортное средство, занятое в поездке": "You cannot edit a vehicle that is busy on a trip",
  "Ссылка скопирована в буфер обмена": "The link was copied to the clipboard",
  "Копировать": "Copy",
  "Гонорар не может быть больше 50% от суммы поездки": "The fee cannot be" +
    " more than 50% of the trip cost",
  "Пассажиры водителей": "Drivers' passengers",
  "Пассажиры диспетчеров": "Dispatchers' passengers",
  "Поездки водителей": 'Поездки водителей',
  "Нет рефералов": "No referrals",
  "Изменить статус поездки": "Change trip status",
  "Копировать поездку": "Copy trip",
  "Соглашаюсь с": "I agree with",
  "Политикой конфиденциальности": "Privacy Policy",
  "и": "and",
  "Политикой куки": "Cookies Policy",
  'Политика куки': 'Cookies Policy',
  "Оплата через Аutostop (оплата проводится через платежную систему)": "Payment via Autostop (the payment would be arranged via a payment system)",
  "Рекомендуем забронировать по обмену": "We recommend to make a reservation" +
    " by exchange",
  "Актуальные": "Actual",
  "Перевести в актуальные": "Move to actual",
  "Политика удаления": "Removal policy",
  "Политикой удаления": "Removal policy",
  "Пользовательское соглашение": "Terms of use",
  "Пользовательским соглашением": "Terms of use",
}
