import React, {useEffect, useState, useContext} from 'react';
import useTranslation from "../hooks/useTranslation";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  myTripsTypes,
  pagesInterface,
  subpagesInterface
} from "../utils/models";
import {Nav, Tab} from "react-bootstrap";
import MyTripsTab from "../hooks/MyTripsTab";

const MyTrips = ({
  handleShowCreateTrip = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get trip data from context
  const {
    setCurrentTrip,
  } = useContext(AppContext);
  //#endregion

  //#region Tabs keys
  const [activeTabKey, setActiveTabKey] = useState(myTripsTypes.actual);
  //#endregion


  //#region Handle trip card click
  const handleShowTripDetails = (trip) => {
    setCurrentTrip(trip);
    navigate(`/${pagesInterface.trips}/${subpagesInterface.displayTripDetails}`);
  }
  //#endregion

  //#region Render
  return (
    <div>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <Tab.Container
        activeKey={activeTabKey}
      >
        <Nav variant="pills" className={'flex-row TabControls'}>
          <Nav.Item>
            <Nav.Link
              eventKey={myTripsTypes.actual}
              onClick={() => setActiveTabKey(myTripsTypes.actual)}
            >
              {translate('Актуальные')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={myTripsTypes.archived}
              onClick={() => setActiveTabKey(myTripsTypes.archived)}
            >
              {translate('Архивные')}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <MyTripsTab
            tripType={myTripsTypes.actual}
            handleShowCreateTrip={handleShowCreateTrip}
            handleShowTripDetails={handleShowTripDetails}
          />

          <MyTripsTab
            tripType={myTripsTypes.archived}
            handleShowCreateTrip={handleShowCreateTrip}
            handleShowTripDetails={handleShowTripDetails}
          />
        </Tab.Content>
      </Tab.Container>
    </div>
  );
  //#endregion
};

export default MyTrips;
