import {
  getCollectionWhereKeysValues, getCollectionWhereKeysValuesWithOperators,
  getCollectionWhereKeyValue,
  getDocInCollection, getLengthOfCollectionWhereKeysValuesWithOperators,
  updateFieldsInDocumentInCollection
} from "./firebaseConfigAndFunctions";
import {collectionsInterface} from "./models";
import {queryArraysService} from "./queryArraysService";
import {tripService} from "./tripService";
import {
  checkIsTripInTheObservedTripsList,
  findNewTripsForTripOrder
} from "./helpers";

const updateHistoryObservationByUser = async (userId, isDispatcher) => {
  const currentTime = Date.now();

  const fieldToUpdate = isDispatcher ? 'lastTripsOrdersHistoryObservation'
    : 'lastTripsHistoryObservation';

  await updateFieldsInDocumentInCollection(collectionsInterface.users, userId, {
    [fieldToUpdate]: currentTime,
  });
}

// const checkAreThereNewCompletedTripsOrdersForDispatcher = async (userId) => {
//   const actualUser = await getDocInCollection(collectionsInterface.users, userId);
//
//   if (!actualUser.lastTripsOrdersHistoryObservation) {
//     actualUser.lastTripsOrdersHistoryObservation = actualUser.dateCreating;
//   }
//
//   const queryArray = queryArraysService.archivedTripsOrdersCompletedAfterDate(
//     userId, actualUser.lastTripsOrdersHistoryObservation);
//
//   const unwatchedCompletedTripsOrders = await getLengthOfCollectionWhereKeysValuesWithOperators(
//     collectionsInterface.tripsOrders, queryArray, queryArray.length);
//
//   return unwatchedCompletedTripsOrders > 0;
// }

const checkAreThereNewCompletedTripsOrdersForDispatcher = async (userId) => {
  const queryArray = queryArraysService.archivedTripsOrdersUnwatchedByDispatcher(
    userId);

  const unwatchedCompletedTripsOrders = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.tripsOrders, queryArray, queryArray.length);

  return unwatchedCompletedTripsOrders > 0;
}

const checkAreThereUnwatchedTestimonialsForUser = async (userId) => {
  const queryArray = queryArraysService
    .testimonialsUnwatchedByEvaluatedPerson(userId);

  const unwatchedTestimonials = getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.testimonials, queryArray, queryArray.length);

  return unwatchedTestimonials > 0;
}

const showIndicatorNewForTripsHistoryForPassengers = async (userId) => {
  const areThereUnwatchedCompletedTripsOrders = await checkAreThereNewCompletedTripsOrdersForDispatcher(userId);

  if (areThereUnwatchedCompletedTripsOrders) {
    return true;
  }

  const areThereUnwatchedTestimonials = await checkAreThereUnwatchedTestimonialsForUser(userId);

  return  areThereUnwatchedTestimonials;
}

const checkIfTestimonialDataShouldBeChanged = async (isTransporter, userId, tripOrder) => {
  if (isTransporter) {
    return {
      needsUpdate: !tripOrder.dispatcherTestimonial.hasBeenWatchedByTransporter,
      testimonialId: tripOrder.dispatcherTestimonial.idPost,
    }
  }

  const queryArray = queryArraysService.testimonialForUserInCertainTrip(
    tripOrder.acceptedDeal.idPost, userId);

  const testimonialToCheck = await getCollectionWhereKeysValuesWithOperators(
    collectionsInterface.testimonials, queryArray, queryArray.length);

  return {
    needsUpdate: !testimonialToCheck.hasBeenWatchedByEvaluatedPerson,
    testimonialId: testimonialToCheck.idPost,
  }
}

const makeTestimonialWatched = async (userId, tripOrderId, testimonialId,
  isTransporter) => {
  const fieldToUpdateInTestimonial = isTransporter
    ? `hasBeenWatchedByTransporter` : `hasBeenWatchedByEvaluatedPerson`;

  await updateFieldsInDocumentInCollection(collectionsInterface.testimonials,
    testimonialId, {
      [fieldToUpdateInTestimonial]: true,
    });

  if (isTransporter) {
    await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
      tripOrderId, {
        ['dispatcherTestimonial.hasBeenWatchedByTransporter']: true,
      });
  }
}

const checkTestimonialAndMakeWatchedIfNecessary = async (isTransporter, userId,
  tripOrder) => {
  const { needsUpdate, testimonialId } = await checkIfTestimonialDataShouldBeChanged(
    isTransporter, userId, tripOrder);

  if (needsUpdate) {
    await makeTestimonialWatched(userId, tripOrder.idPost, testimonialId,
      isTransporter);
  }
}

// const checkAreThereNewCompletedTripsOrdersForTransporter = async (userId) => {
//   const actualUser = await getDocInCollection(collectionsInterface.users, userId);
//
//   if (!actualUser.lastTripsHistoryObservation) {
//     actualUser.lastTripsHistoryObservation = actualUser.dateCreating;
//   }
//
//   const queryArray = queryArraysService.archivedTripsOrdersForTransporterCompletedAfterDate(
//     userId, actualUser.lastTripsHistoryObservation);
//
//   const unwatchedCompletedTripsOrders = await getLengthOfCollectionWhereKeysValuesWithOperators(
//     collectionsInterface.tripsOrders, queryArray, queryArray.length);
//
//   return unwatchedCompletedTripsOrders > 0;
// }

const checkAreThereNewCompletedTripsOrdersForTransporter = async (userId) => {
  const queryArray = queryArraysService
    .archivedTripsOrdersForTransporterUnwatchedAfterCompletion(userId);

  const unwatchedCompletedTripsOrders = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.tripsOrders, queryArray, queryArray.length);

  return unwatchedCompletedTripsOrders > 0;
}

const checkAreThereUnwatchedTestimonialsFromDispatcher = async (userId) => {
  const queryArray = queryArraysService
    .archivedTripsOrdersWithNewTestimonialFromDispatcher(userId);

  const ordersWithUnwatchedTestimonials = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.tripsOrders, queryArray, queryArray.length);

  return ordersWithUnwatchedTestimonials > 0;
}

const showIndicatorNewForTripsHistoryForTransporter = async (userId) => {
  const areThereUnwatchedCompletedTripsOrders = await checkAreThereNewCompletedTripsOrdersForTransporter(userId);

  if (areThereUnwatchedCompletedTripsOrders) {
    return true;
  }

  const areThereUnwatchedTestimonials = await checkAreThereUnwatchedTestimonialsFromDispatcher(
    userId);

  return  areThereUnwatchedTestimonials;
}

// const showIndicatorNewForTripsHistoryForDriver = async (userId) => {
//   const actualUser = await getDocInCollection(collectionsInterface.users, userId);
//
//   if (!actualUser.lastTripsHistoryObservation) {
//     actualUser.lastTripsHistoryObservation = actualUser.dateCreating;
//   }
//
//   const completedTripsOrders = await tripService
//     .getArchivedPassengersListOfTransporter(actualUser.attachedToCompany, userId);
//
//   const unwatchedCompletedTripsOrders = completedTripsOrders
//     .filter(order => order.completionTime > actualUser.lastTripsHistoryObservation);
//
//   if (unwatchedCompletedTripsOrders.length > 0) {
//     return true;
//   }
//
//   const areThereUnwatchedTestimonials = await checkAreThereUnwatchedTestimonialsForUser(userId);
//
//   return  areThereUnwatchedTestimonials;
// }

const showIndicatorNewForTripsHistoryForDriver = async (userId) => {
  const actualUser = await getDocInCollection(collectionsInterface.users, userId);

  const completedTripsOrders = await tripService
    .getArchivedPassengersListOfTransporter(actualUser.attachedToCompany, userId);

  const unwatchedCompletedTripsOrders = completedTripsOrders
    .filter(order => !order?.hasBeenWatchedByDriversAfterCompletion ||
      !order?.hasBeenWatchedByDriversAfterCompletion[userId]);

  if (unwatchedCompletedTripsOrders.length > 0) {
    return true;
  }

  const areThereUnwatchedTestimonials = await checkAreThereUnwatchedTestimonialsForUser(userId);

  return  areThereUnwatchedTestimonials;
}

const showIndicatorNewForForTripHistoryInTripsSection = async (isTransporter, userId) => {
  let shouldIndicatorBeShown;

  if (isTransporter) {
    shouldIndicatorBeShown = showIndicatorNewForTripsHistoryForTransporter(userId);
  } else {
    shouldIndicatorBeShown = showIndicatorNewForTripsHistoryForDriver(userId);
  }

  return shouldIndicatorBeShown;
}

const showIndicatorNewForActiveTripsOrders = async (userId) => {
  const queryArray = queryArraysService
    .tripsOrdersWithUnwatchedByDispatcherDeal(userId);

  const ordersWithUnwatchedPassengers = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.tripsOrders, queryArray, queryArray.length);

  return ordersWithUnwatchedPassengers > 0;
}

const showIndicatorNewForActiveTrips = async (userId, companyId) => {
  const queryArray = companyId ? queryArraysService
    .tripsWithPassengerUnwatchedByDriver(companyId, userId)
    : queryArraysService.tripsWithPassengerUnwatchedByTransporter(userId);

  const tripsWithUnwatchedPassengers = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionsInterface.trips, queryArray, queryArray.length);

  return tripsWithUnwatchedPassengers > 0;
}

const showIndicatorNewForReservedTrips = async (userId, isDispatcher) => {
  const queryArray = queryArraysService.tripsWithNewReservations(userId);

  const collectionName = isDispatcher ? collectionsInterface.tripsOrders
    : collectionsInterface.trips;
  const tripsWithNewAction = await getLengthOfCollectionWhereKeysValuesWithOperators(
    collectionName, queryArray, queryArray.length);

  return tripsWithNewAction > 0;
}

const updateNewPassengerIndicatorDataForDispatcherIfNecessary = async (
  tripOrderId, hasBeenWatchedByDispatcher) => {
  if (!hasBeenWatchedByDispatcher) {
    await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
      tripOrderId, {
        ['acceptedDeal.hasBeenWatchedByDispatcher']: true,
      });
  }
}

const updateNewPassengerIndicatorDataForTransporterIfNecessary = async (dealId, tripId,
  tripPassengers, hasBeenWatchedByTransporter) => {
  if (!hasBeenWatchedByTransporter) {
    const updatedTripPassengers = tripPassengers.map(passenger => passenger
      .id === dealId ? {
      ...passenger,
      hasBeenWatchedByTransporter: true,
    } : passenger);

    const hasPassengerUnwatchedByTransporter = updatedTripPassengers
      .some(passenger => !passenger.hasBeenWatchedByTransporter);

    await updateFieldsInDocumentInCollection(collectionsInterface.trips,
      tripId, {
        hasPassengerUnwatchedByTransporter,
        acceptedDeals: updatedTripPassengers,
      });
  }
}

const updateNewPassengerIndicatorDataForDriverIfNecessary = async (userId, tripId,
  dealId, tripPassengers, hasBeenWatchedByDriver) => {
  if (!hasBeenWatchedByDriver) {
    const updatedTripPassengers = tripPassengers.map(passenger => passenger
      .id === dealId ? {
      ...passenger,
      hasBeenWatchedByDrivers: passenger.hasBeenWatchedByDrivers === null
        ? {[userId]: true} : {...passenger.hasBeenWatchedByDrivers,
          [userId]: true,
        },
    } : passenger);

    const driverHasUnwatchedPassengers = updatedTripPassengers
      .some(passenger => !passenger.hasBeenWatchedByDrivers || !passenger
        .hasBeenWatchedByDrivers[userId]);

    await updateFieldsInDocumentInCollection(collectionsInterface.trips,
      tripId, {
        [`hasPassengerUnwatchedByDrivers.${userId}`]: driverHasUnwatchedPassengers,
        acceptedDeals: updatedTripPassengers,
      });
  }
}

const updateNewReservationIndicatorForDispatcherIfNecessary = async (tripOrder,
  reservationUid, needsUpdate) => {
  if (needsUpdate) {
    const updateReservationsList = (reservation) => reservation.uid === reservationUid ? {
      ...reservation,
      hasNewActionFromTransporter: false
    } : reservation;

    const reservationsFromDispatchers = tripOrder.reservationsFromDispatchers
      .map(updateReservationsList);

    const reservationsFromDrivers = tripOrder.reservationsFromDrivers
      .map(updateReservationsList);

    let hasNewAction = reservationsFromDispatchers
      .some(reservation => reservation.hasNewActionFromTransporter);

    if (!hasNewAction) {
      hasNewAction = reservationsFromDrivers
        .some(reservation => reservation.hasNewActionFromTransporter);
    }

    await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
      tripOrder.idPost, {
        reservationsFromDispatchers,
        reservationsFromDrivers,
        hasNewAction,
      });
  }
}

const updateNewReservationIndicatorForTransporterIfNecessary = async (trip,
  reservationUid, needsUpdate) => {
  if (needsUpdate) {
    const updateReservationsList = (reservation) => reservation.uid === reservationUid ? {
      ...reservation,
      hasNewActionFromDispatcher: false
    } : reservation;

    const reservationsFromDispatchers = trip.reservationsFromDispatchers
      .map(updateReservationsList);

    const reservationsFromDrivers = trip.reservationsFromDrivers
      .map(updateReservationsList);

    let hasNewAction = reservationsFromDispatchers
      .some(reservation => reservation.hasNewActionFromDispatcher);

    if (!hasNewAction) {
      hasNewAction = reservationsFromDrivers
        .some(reservation => reservation.hasNewActionFromDispatcher);
    }

    await updateFieldsInDocumentInCollection(collectionsInterface.trips,
      trip.idPost, {
        reservationsFromDispatchers,
        reservationsFromDrivers,
        hasNewAction,
      });
  }
}

const updateNewReservationIndicatorForArchivedTripOrderIfNecessary = async (
  tripOrder, isDispatcher, isDriver, userId) => {
  let fieldToUpdate = 'hasBeenWatchedByDispatcherAfterCompletion';

  if (isDriver) {
    fieldToUpdate = `hasBeenWatchedByDriversAfterCompletion.${userId}`;
  }

  if (!isDispatcher && !isDriver) {
    fieldToUpdate = 'hasBeenWatchedByTransporterAfterCompletion';
  }

  if (tripOrder[fieldToUpdate] !== true) {
    await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
      tripOrder.idPost, {
      [fieldToUpdate]: true,
    });
  }
}

const updateIndicatorsDataOnReservationDetailsMount = async (isDispatcher,
  isDriver, tripOrder, trip, userId, reservation) => {
  if (isDispatcher && tripOrder.isActive) {
    await updateNewPassengerIndicatorDataForDispatcherIfNecessary(
      tripOrder.idPost, reservation.hasBeenWatchedByDispatcher);

    return;
  }

  if (!isDispatcher && tripOrder.isActive && !isDriver) {
    await updateNewPassengerIndicatorDataForTransporterIfNecessary(tripOrder.idPost,
      trip.idPost, trip.acceptedDeals, reservation.hasBeenWatchedByTransporter);

    return;
  }

  if (tripOrder.isActive && isDriver) {
    const hasBeenWatched = reservation
      .hasBeenWatchedByDrivers === null ? false : reservation
      .hasBeenWatchedByDrivers[userId]

    await updateNewPassengerIndicatorDataForDriverIfNecessary(userId, trip.idPost,
      tripOrder.idPost, trip.acceptedDeals, hasBeenWatched);

    return;
  }

  if (!tripOrder.isActive && !tripOrder.isArchived && isDispatcher) {
    await updateNewReservationIndicatorForDispatcherIfNecessary(tripOrder,
      reservation.uid, reservation.hasNewActionFromTransporter);

    return;
  }

  if (!tripOrder.isActive && !tripOrder.isArchived && !isDispatcher) {
    await updateNewReservationIndicatorForTransporterIfNecessary(trip,
      reservation.uid, reservation.hasNewActionFromDispatcher);

    return;
  }

  if (tripOrder.isArchived) {
    await updateNewReservationIndicatorForArchivedTripOrderIfNecessary(tripOrder,
      isDispatcher, isDriver, userId);

    await checkTestimonialAndMakeWatchedIfNecessary(!isDispatcher && !isDriver,
      userId, tripOrder);
  }
}

// const shouldBadgeNewBeOnTripCardInReservedTrips = (reservationsFromDispatchers,
//   reservationsFromDrivers, isDispatcher) => {
//   const reservations = [...reservationsFromDispatchers, ...reservationsFromDrivers];
//
//   return reservations.some(reservation => isDispatcher ? reservation
//     .hasNewActionFromTransporter : reservation.hasNewActionFromDispatcher);
// }

const shouldBadgeNewBeDisplayedOnTripCard = (isDispatcher, tripOrder, trip, userId) => {
  if (isDispatcher) {
    return tripOrder?.idPost && !checkIsTripInTheObservedTripsList(tripOrder, trip);
  }

  if (!trip.isActive && trip.hasAnyReservations) {
    return trip.hasNewAction;
  }

  if (trip.isActive && trip.creatorIdPost === userId) {
    return trip.hasPassengerUnwatchedByTransporter;
  }

  if (trip.isActive && trip.creatorIdPost !== userId) {
    return trip.hasPassengerUnwatchedByDrivers[userId];
  }

  return false;
}

const shouldBadgeNewBeOnTripOrderCard = async (isNotepad, isInMyPassengersList,
  userId, tripOrder) => {
  if (isNotepad) {
    return false;
  }

  if (isInMyPassengersList) {
    const newTrips = await findNewTripsForTripOrder(userId, tripOrder);

    return newTrips.length > 0;
  }

  if (tripOrder.hasAnyReservations) {
    return tripOrder.hasNewAction;
  }
}

const shouldBadgeNewBeOnReservationCard = async (isDispatcher, isActive,
  isArchived, reservation, userId, tripOrder) => {
  if (!isActive && !isArchived) {
    return isDispatcher ? reservation.hasNewActionFromTransporter
      : reservation.hasNewActionFromDispatcher;
  }

  if (isActive && !isDispatcher) {
    if (reservation.originalTrip.creatorIdPost === userId) {
      return !reservation.hasBeenWatchedByTransporter;
    } else {
      return reservation?.hasBeenWatchedByDrivers === null
        || !reservation.hasBeenWatchedByDrivers[userId];
    }
  }

  if (isActive && isDispatcher) {
    return !reservation.hasBeenWatchedByDispatcher;
  }

  if (isArchived && !reservation.isCancelled) {
    if (isDispatcher) {
      return !tripOrder.hasBeenWatchedByDispatcherAfterCompletion || tripOrder
        .driverTestimonial.hasBeenWatchedByEvaluatedPerson === false;
    }

    if (reservation.originalTrip.creatorIdPost === userId) {
      return !tripOrder.hasBeenWatchedByTransporterAfterCompletion || tripOrder
        .dispatcherTestimonial.hasBeenWatchedByTransporter === false;
    } else if (!tripOrder.hasBeenWatchedByDriversAfterCompletion || !tripOrder
      .hasBeenWatchedByDriversAfterCompletion[userId]) {
      return true;
    }

    const queryArray = queryArraysService.testimonialForUserInCertainTrip(
      userId, reservation.originalTrip.idPost);

    const actualTestimonial = await getCollectionWhereKeysValuesWithOperators(
      collectionsInterface.testimonials, queryArray, queryArray.length);

    return actualTestimonial.hasBeenWatchedByEvaluatedPerson;
  }
}

export const indicatorsNewService = {
  updateHistoryObservationByUser,
  showIndicatorNewForTripsHistoryForPassengers,
  checkTestimonialAndMakeWatchedIfNecessary,
  checkAreThereNewCompletedTripsOrdersForTransporter,
  showIndicatorNewForForTripHistoryInTripsSection,
  showIndicatorNewForActiveTripsOrders,
  updateIndicatorsDataOnReservationDetailsMount,
  shouldBadgeNewBeDisplayedOnTripCard,
  shouldBadgeNewBeOnTripOrderCard,
  shouldBadgeNewBeOnReservationCard,
  showIndicatorNewForActiveTrips,
  showIndicatorNewForReservedTrips,
}
