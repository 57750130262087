import React, {useContext} from 'react';
import {
  showFormattedToLanguageDate
} from "../utils/helpers";
import DisplayTripPointsData from "./DisplayTripPointsData";
import {AppContext} from "../context/appContext";
import {FaTrash} from "react-icons/fa";

const TripCardForVehicleOrDriver = ({
  addClasses='',
  trip,
  deleteTrip = () => {},
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#regin Render
  return (
    <div className={`TripCard ${addClasses}`}>
      <div className='FlexBlock TripCard-Item'>
        <div
          className={'TripCard-HeadBlock TripCard-DateBlock'}>
          {trip?.startDate ? showFormattedToLanguageDate(lang,
              trip?.startDate)
            : 'Invalid time'}
        </div>

        <div
          className={'TripCard-HeadBlock TripCard-DateBlock'}>
          {trip?.endDate ? showFormattedToLanguageDate(lang,
              trip?.endDate)
            : 'Invalid time'}
        </div>
      </div>

      <div className={'TripCard-MainSection'}>
        <div className={'TripCard-Block FlexBlock ColoredText'}>
          <div className={'FlexBlock-HorizontalItem_W80'}>
            <DisplayTripPointsData
              tripPoints={[trip.startPoint, trip.endPoint]}
            />
          </div>

          <div className={'FlexBlock FlexBlock_JustifyCenter' +
            ' FlexBlock_AlignCenter FlexBlock-HorizontalItem_W20'}>
            <span onClick={() => deleteTrip(trip.idPost)}>
              <FaTrash />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default TripCardForVehicleOrDriver;
