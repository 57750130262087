import React from 'react';
import TestimonialCard from "./TestimonialCard";
import '../styles/TestimonialsList.scss';

const TestimonialsList = ({addClasses='', testimonials = []}) => {
  //#region Render
  return (
    <div className={`TestimonialsList ${addClasses}`}>
      {testimonials.map((testimonial, tIndex) => (
        <React.Fragment key={tIndex}>
          <TestimonialCard testimonial={testimonial}/>
        </React.Fragment>
      ))}
    </div>
  );
  //#endregion
};

export default TestimonialsList;
