import React, {useContext, useEffect, useState} from 'react';
import {collectionsInterface, userModel} from "../utils/models";
import DisplayMessengersRow from "./DisplayMessengersRow";
import {
  checkIsDriverInFavourites,
  handleChangeFavouriteClick,
  handleUserClick
} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import ModalTestimonials from "../modals/ModalTestimonials";
import {useNavigate} from "react-router-dom";
import LoaderSmall from "./LoaderSmall";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import useTranslation from "../hooks/useTranslation";

const DisplayUserData = ({
  actualUser = undefined,
  addClasses = '',
  userId = '',
  isDriver = false,
  isAdmin = false,
  isFullInfo = true,
  isFavouriteIconVisible = true,
  isCentered = false,
  areContactsVisible = false,
}) => {
  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get actual user data
  const [userData, setUserData] = useState(userModel);

  const getActualUser = async () => {
    setIsLoading(true);

    if (actualUser?.idPost) {
      setUserData(actualUser);
      setIsLoading(false);
    } else if (userId) {
      try {
        const userFromDb = await getDocInCollection(collectionsInterface.users,
          userId);

        setUserData(userFromDb);
      } catch (error) {
        return error;
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    getActualUser().catch(() => {});
  },[userId, actualUser]);
  //#endregion

  //#region Manage displaying dispatcher's testimonials
  const [areTestimonialsVisible, setAreTestimonialsVisible] = useState(false);
  const [modalTestimonialsTitle, setModalTestimonialsTitle] = useState('');

  const showTestimonials = () => {
    setModalTestimonialsTitle(isDriver ? 'Отзывы о водителе' : 'Отзывы о' +
      ' диспетчере')
    setAreTestimonialsVisible(true);
  }

  const hideTestimonials = () => {
    setAreTestimonialsVisible(false);
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <LoaderSmall />
        :
        <div className={addClasses}>
          <div
            className={isCentered
              ? 'FlexBlock FlexBlock_JustifyCenter TripDetails-Block'
              : 'FlexBlock FlexBlock_JustifyLeft TripDetails-Block'
            }
          >
            {!isDriver &&
              <img
                src={'/img/dispatcher-icon.svg'}
                alt={'Headphones image'}
              />
            }

            <span
              className={
                isAdmin ? 'LeftPaddingTiny UserLink' : isDriver ? ''
                  : 'LeftPaddingTiny'
              }
              onClick={() => handleUserClick(userData.idPost,
                setEditingUser, navigate)}
            >
              {userData?.fullName}
            </span>

            <span className={'LeftPaddingTiny StarIconContainer'}>
              <img
                src={'/img/mark-star-icon.svg'}
                alt={'Star icon'}
              />
            </span>

            <span className={'LeftPaddingTiny'}>
              {`${userData?.averageMark || 0} (${(isDriver ? userData
                ?.tripsAsDriver : userData?.tripsAsDispatcher) || 0})`}
            </span>

            {(isDriver && isFavouriteIconVisible) &&
              <span
                className={'LeftMarginTiny'}
                onClick={(event) => handleChangeFavouriteClick(event, userData,
                  user, setUser)}
              >
                {checkIsDriverInFavourites(userData, user) ?
                  <img
                    className={'MenuIcon_Clickable'}
                    src={'/img/filled-heart-icon.svg'}
                    alt={'Heart icon'}
                  />
                  :
                  <img
                    className={'MenuIcon_Clickable'}
                    src={'/img/pale-heart-icon.svg'}
                    alt={'Heart icon'}
                  />
                }
              </span>
            }
          </div>

          {isFullInfo &&
            <>
            {areContactsVisible &&
              <div>
                {userData?.phoneNumbers?.map((phone, pIndex) => (
                  <React.Fragment key={pIndex}>
                    <DisplayMessengersRow
                      phone={phone}
                      isPhoneNumberRefactored={true}
                      isLinkToCall={true}
                    />
                  </React.Fragment>
                ))}
              </div>
            }

              <div className={'TripDetails-Block'}>
                <button
                  type={'button'}
                  className={'RegularButton RegularButton_Contrast'}
                  onClick={() => showTestimonials(userData)}
                >
                  {translate(isDriver ? 'Отзывы о водителе' : 'Отзывы о диспетчере')}
                </button>
              </div>
            </>
          }
        </div>
      }

      {areTestimonialsVisible &&
        <ModalTestimonials
          personUid={userData.uid}
          showModal={areTestimonialsVisible}
          hideTestimonials={hideTestimonials}
          title={modalTestimonialsTitle}
          isDispatcher={!isDriver}
        />
      }
    </>
  );
  //#endregion
};

export default DisplayUserData;
