import React, {useState, useEffect, useContext} from 'react';
import {AppContext} from "../context/appContext";
import {collectionsInterface} from "../utils/models";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {
  compareAllAddOptions,
  findTripPointInTripByPlaceId,
  getRouteFromTrip,
  showFormattedToLanguageDate
} from "../utils/helpers";
import ModalAddOptionsDifferenceConfirmation
  from "../modals/ModalAddOptionsDifferenceConfirmation";
import DisplayTripDetails from "./DisplayTripDetails";
import DisplayTripPointsData from "./DisplayTripPointsData";
import {useNavigate} from "react-router-dom";
import DisplayUserData from "./DisplayUserData";
import ModalNotification from "../modals/ModalNotification";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/TripReservation.scss';
import {
  checkIsReservationSuitableForExchange
} from "../utils/reservationsService";

const TripReservation = ({
  handleGoBack = () => {},
  isDispatcher = true,
  handleShowReservationConfirmationScreen = () => {},
  addClasses = '',
  isPassengerTransfer = false,
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, currentTrip} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Бронирование пассажира');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Trip query
  const [tripQuery] = useState({
    start: currentTripOrder?.startPoint,
    end: currentTripOrder?.endPoint,
    departure: currentTripOrder?.departureTime,
  });
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTrip?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Handle reserve place click and confirmation if add options are different
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  const goToReservationConfirmation = () => {
    setShowConfirmationModal(false);

    handleShowReservationConfirmationScreen();
  }

  const rejectReservation = () => {
    setShowConfirmationModal(false);
    handleGoBack();
  }

  const handleReservePlaceClick = async (tripForReservation, order,
    isTransfer) => {
    setIsLoading(true);

    try {
      const actualOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, order.idPost);

      if (!isTransfer && actualOrder.isActive) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('У пассажира уже есть подтвержденная поездка');

        return;
      }

      if (tripForReservation.reservationsFromDispatchers
          .some(reservation => reservation.originalTripOrder.idPost === order
            .idPost) || tripForReservation.reservationsFromDrivers
              .some(reservation => reservation.originalTripOrder
                .idPost === order.idPost)) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('У пассажира уже есть бронь в данной поездке');

        return;
      }

      if (!compareAllAddOptions(tripForReservation, actualOrder)) {
        setConfirmationModalTitle('Подтверждение бронирования');
        setConfirmationModalMessage('Не совпадают дополнительные опции. ' +
          'Все равно хотите забронировать?');
        setShowConfirmationModal(true);

        return;
      }

      handleShowReservationConfirmationScreen();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`TripReservation ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div
            className={'SeparationBlock SeparationBlock_Limited DesktopTitle'}>
          </div>

          <p className={'TripReservation-RecommendationText'}>
            {checkIsReservationSuitableForExchange(user.debtors, user.creditors,
              isDispatcher, isDispatcher ? currentTrip.creatorIdPost
                : currentTripOrder.creatorIdPost) && translate('Рекомендуем' +
              ' забронировать по обмену')}
          </p>

          <div className={'TripReservation-Item'}>
            <h3 className={'DataLabel'}>
              {translate('Запрошенный маршрут пассажира')}
            </h3>

            <div className={'RouteBlock'}>
              <DisplayTripPointsData
                tripPoints={
                  [findTripPointInTripByPlaceId(tripQuery.start.placeId,
                    currentTrip), findTripPointInTripByPlaceId(tripQuery
                    .end.placeId, currentTrip)]
                }
              />
            </div>
          </div>

          <div className={'TripReservation-Item'}>
            <h3 className={'DataLabel'}>
              {translate('Маршрут следования пассажира в поездке')}
            </h3>

            <DisplayTripPointsData
              addClasses={'RouteBlock'}
              tripPoints={getRouteFromTrip(tripQuery?.start?.placeId,
                tripQuery?.end?.placeId, currentTrip)}
            />
          </div>

          <div className={'TripReservation-Item FlexBlock'}>
            <h3 className={'DataLabel'}>
              {translate('Время отправления')}
            </h3>

            <div className={'TripDetails-DepartureTime'}>
              {`${findTripPointInTripByPlaceId(tripQuery.start.placeId, 
                currentTrip)?.departureTime ?
                showFormattedToLanguageDate(lang, 
                  findTripPointInTripByPlaceId(tripQuery.start.placeId, 
                    currentTrip)?.departureTime,
                  true) : 'Invalid date'}`}
            </div>
          </div>

          <div className={'TripReservation-Item'}>
            <h3 className={'DataLabel'}>
              {translate('Имя пассажира')}
            </h3>

            {currentTripOrder?.passengerName}
          </div>

          {(!isDispatcher && currentTripOrder?.creatorIdPost) &&
            <>
              <h3 className={'DataLabel'}>
                {translate('Диспетчер')}
              </h3>

              <DisplayUserData
                userId={currentTripOrder?.creatorIdPost}
                isDriver={false}
                isFullInfo={true}
              />
            </>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <h3 className={'RegularTitle RegularTitle_Small'}>
            {translate('Данные о поездке')}
          </h3>

          <DisplayTripDetails
            isReadOnly={true}
            isFromTripReservation={true}
            isDispatcher={isDispatcher}
          />

          <div className={'TripReservation-Item'}>
            <div
              className={
                'TripDetails-Block FlexBlock FlexBlock_JustifyLeft'
              }
            >
              {`${translate('Гонорар диспетчера')}: ${currentTrip?.dispatcherFee}`}
            </div>

            <div className={'SeparationBlock SeparationBlock_Limited'}></div>
          </div>

          <div className={'TripReservation-Item'}>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={() => handleReservePlaceClick(currentTrip,
                currentTripOrder, isPassengerTransfer)}
            >
              {currentTrip.creatorIdPost === currentTripOrder.creatorIdPost
                ? translate('Добавить в поездку') : translate('Забронировать')}
            </button>
          </div>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          message={notificationMessage}
          title={notificationTitle}
        />
      }

      {showConfirmationModal &&
        <ModalAddOptionsDifferenceConfirmation
          showModal={setShowConfirmationModal}
          title={confirmationModalTitle}
          message={confirmationModalMessage}
          setShowModal={setShowConfirmationModal}
          goToReservationConfirmation={goToReservationConfirmation}
          rejectReservation={rejectReservation}
        />
      }
    </>
  );
  //#endregion
};

export default TripReservation;
