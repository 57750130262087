import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import Loader from "./Loader";
import {getReferralsList} from "../utils/helpers";
import ReferralCard from "./ReferralCard";
import useTranslation from "../hooks/useTranslation";

const ReferralsList = ({
  addClasses = ''
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Referrals list
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    getReferralsList(user.idPost)
      .then((referralsList) => setReferrals(referralsList))
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader />
        :
        <section className={`${addClasses}`}>
          <h3 className={'RegularTitle RegularTitle_Small'}>
            {translate('Мои рефералы')}
          </h3>

          {referrals.length > 0 ?
            <div className={'TransactionsHistory'}>
              {referrals.map(referral => (
                <React.Fragment key={referral.idPost}>
                  <ReferralCard referral={referral} />

                  <div
                    className={'SeparationBlock SeparationBlock_Limited'}
                  ></div>
                </React.Fragment>
              ))}
            </div>
            :
            <div className={'CardsListScreen CardsListScreen_Empty'}>
              <p className={'CardsListScreen-NoEntriesText'}>
                {translate('Нет рефералов')}
              </p>
            </div>
          }
        </section>
      }
    </>
  );
  //#endregion
};

export default ReferralsList;
