import React, {useEffect, useState} from 'react';
import {tripService} from "../utils/tripService";
import {sortCardsByDateOfCreation} from "../utils/helpers";

const useTripsList = (queryArray, userId, attachedToCompany) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tripsList, setTripsList] = useState([]);

  const getTripsAndSet = async () => {
    setIsLoading(true);

    try {
      const trips = await tripService.getTripsList(queryArray);

      const allUserTrips = tripService.getTripsListDependingOnTransporterStatus(
        trips, userId, attachedToCompany);

      setTripsList(sortCardsByDateOfCreation(allUserTrips, false));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTripsAndSet().then();
  }, []);

  return {isLoading, setIsLoading, tripsList, setTripsList};
};

export default useTripsList;
