import React, {useEffect, useState, useContext} from 'react';
import Loader from "./Loader";
import {collectionsInterface, entitiesNumberInterface} from "../utils/models";
import {
  deleteDocumentFromCollectionWithID,
  getCollectionWhereKeysValuesWithOperators
} from "../utils/firebaseConfigAndFunctions";
import DisplayUserData from "./DisplayUserData";
import ShowDriverOrVehicleTrip from "./ShowDriverOrVehicleTrip";
import {ONE_DAY} from "../utils/data";
import ModalDeleteIndicationTrip from "../modals/ModalDeleteIndicationTrip";
import useTranslation from "../hooks/useTranslation";

const DisplayMyDriverTrips = ({
  addClasses = '',
  driver,
  cardsNumber= entitiesNumberInterface.driverTrips,
  isReducedDisplaying = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Trips
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    getCollectionWhereKeysValuesWithOperators(collectionsInterface.driversTrips,
      [{
        key: 'driverId',
        value: driver.idPost,
        operator: '==',
      }, {
        key: 'endDate',
        value: (new Date().getTime() - ONE_DAY),
        operator: '>=',
      }], 2).then(driverTrips => {
        driverTrips.sort((prev, next) => next.startDate - prev.startDate)
        setTrips(driverTrips);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  //#endregion

  //#region Delete trip modal
  const [isModal, setIsModal] = useState(false);
  const [tripIdToDelete, setTripIdToDelete] = useState(null);

  const deleteTripClick = (tripId) => {
    setIsModal(true);
    setTripIdToDelete(tripId);
  }
  //#endregion

  //#region Handle delete trip
  const deleteDriverTrip = async (tripId) => {
    try {
      await deleteDocumentFromCollectionWithID(collectionsInterface
        .driversTrips, tripId);

      setTrips(trips => trips
        .filter(trip => trip.idPost !== tripId)
        .sort((prev, next) => next.startDate - prev.startDate));

      setIsModal(false);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Render
  return (
    <div className={`DisplayVehicleTrips ${addClasses}`}>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <DisplayUserData
        isDriver={true}
        isFullInfo={false}
        userId={driver.idPost}
        addClasses={'DataLabel DataLabel_Centered'}
        isFavouriteIconVisible={false}
        isCentered={true}
        areContactsVisible={true}
      />

      <h2 className={'DataLabel DataLabel_Centered'}>
        {translate(
          'Тут отображаются промежутки дат, в которых занят данный водитель')}
      </h2>

      {isLoading ?
        <Loader />
        :
        <ShowDriverOrVehicleTrip
          addClasses={addClasses}
          trips={trips}
          isReducedDisplaying={isReducedDisplaying}
          cardsNumber={cardsNumber}
          deleteTrip={deleteTripClick}
        />
      }

      {isModal &&
        <ModalDeleteIndicationTrip
          showModal={isModal}
          setShowModal={setIsModal}
          tripId={tripIdToDelete}
          deleteTrip={deleteDriverTrip}
        />
      }
    </div>
  );
  //#endregion
};

export default DisplayMyDriverTrips;
