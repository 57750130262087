import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  entitiesNumberInterface,
} from "../utils/models";
import Loader from "./Loader";
import PaginationBlock from "./PaginationBlock";
import InvitationCard from "./InvitationCard";
import ModalNotificationWithTextArray
  from "../modals/ModalNotificationWithTextArray";
import useTranslation from "../hooks/useTranslation";

const InvitationsList = ({ addClasses = '', invitations = []}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Приглашения');
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({
    intro: '',
    message: '',
    textArray: '',
    title: '',
  });

  const updateNotificationData = (isVisible, data) => {
    setIsNotification(isVisible);

    if (isVisible) {
      setNotificationData(data);
    }
  }
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [invitationsChunk, setInvitationsChunk] = useState([]);

  useEffect(() => {
    if (invitations.length > 0) {
      setInvitationsChunk(invitations
        .slice((currentPage - 1) * entitiesNumberInterface.withdrawals,
          currentPage * entitiesNumberInterface.withdrawals));
    }
  }, [invitations, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <>
          {invitations.length > 0 ?
            <div className={`${addClasses}`}>
              <div className={'PaddingBlock PaddingBlock_Half'}></div>
              <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
                {translate(screenTitle)}
              </h2>

              <div className={'DataListWithPagination'}>
                <div className={'ListContainer ListContainer_Extended'}>
                  <div className={'CardsList'}>
                    {invitationsChunk.map((invitation, index) =>
                      <React.Fragment key={invitation.id || index}>
                        <InvitationCard
                          invitation={invitation}
                          setListLoading={setIsLoading}
                          setNotificationData={updateNotificationData}
                        />
                      </React.Fragment>)
                    }
                  </div>
                </div>

                {invitations.length > entitiesNumberInterface.invitations &&
                  <PaginationBlock
                    portionSize={entitiesNumberInterface.invitations}
                    listLength={invitations.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    startPage={startPage}
                    setStartPage={setStartPage}
                  />
                }
              </div>
            </div>
            :
            <div
              className={`CardsListScreen CardsListScreen_Empty`}
            >
              <p className={'CardsListScreen-NoEntriesText'}>
                {translate('Нет приглашений')}
              </p>
            </div>
          }
        </>
      }

      {isNotification &&
        <ModalNotificationWithTextArray
          showModal={isNotification}
          setShowModal={setIsNotification}
          intro={notificationData.intro}
          message={notificationData.message}
          textArray={notificationData.textArray}
          title={notificationData.title}
        />
      }
    </>
  );
  //#endregion
};

export default InvitationsList;
