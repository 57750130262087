import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {getCountryName, getVehicleData} from "../utils/helpers";
import LoaderSmall from "./LoaderSmall";
import '../styles/VehicleCard.scss';
import useTranslation from "../hooks/useTranslation";

const VehicleCard = ({
  addClasses='',
  vehicle,
  setCurrentVehicle = () => {},
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Get brand name, model name
  const [names, setNames] = useState({});

  useEffect(() => {
    getVehicleData(lang, vehicle?.brand, vehicle?.model, setNames,
      setIsLoading, undefined, vehicle?.bodyType)
      .catch(() => {});
  }, [lang]);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div
        className={`VehicleCard ${addClasses}`}
        onClick={() => setCurrentVehicle(vehicle)}
      >
        <div className={'VehicleCard-Item'}>
          {isLoading ? <LoaderSmall />
            : `${names?.brandName|| vehicle?.brand?.defaultName}
                ${names?.modelName || vehicle?.model?.defaultName}
                (${vehicle?.licensePlate})`
          }
        </div>
        <div className={'VehicleCard-Item'}>
          {isLoading ? <LoaderSmall/> :
            `${translate('Тип кузова')}: ${names?.bodyTypeName ||
              vehicle?.bodyType?.defaultName}`
          }
        </div>

        <div className={'VehicleCard-Item'}>
          {`${translate('Страна')}: ${getCountryName(lang, 
            vehicle?.registrationCountry)}`
          }
        </div>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default VehicleCard;
