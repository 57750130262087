import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import RegistrationForm from "./RegistrationForm";
import ShowProfileData from "./ShowProfileData";
import '../styles/PersonData.scss';

const PersonData = ({addClasses=''}) => {
  //#region Get userDataCompleteness
  const {isUserDataComplete} = useContext(AppContext);
  //#endregion

  //#region Managing edit mode switch
  const [isEditMode, setIsEditMode] = useState(!isUserDataComplete);

  useEffect(() => {
    setIsEditMode(!isUserDataComplete);
  }, [isUserDataComplete]);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={`PersonData ${addClasses}`}>
        {isEditMode ?
          <RegistrationForm
            isRegistration={false}
            isEditMode={true}
            setIsEditMode={setIsEditMode}
          />
          :
          <ShowProfileData
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            addClasses={'PersonData-ShowProfileData'}
          />
        }
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default PersonData;
