import React, {useContext, useEffect, useState} from 'react';
import useTranslation from "../hooks/useTranslation";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {getCurrentTripLocation} from "../utils/helpers";
import Map, {Marker, FullscreenControl} from 'react-map-gl/maplibre';
import '../styles/CarOnMapLocationIq.scss';

const iconPath = '/img/profile-menu/car-icon.svg';
const mapUrl = `https://tiles.locationiq.com/v3/streets/vector.json?key=${
  process.env.REACT_APP_LOCATION_IQ_API_KEY}`

const CarOnMapLocationIq = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen title managing function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

    //#region Set screen title and current trip order on component mount
  useEffect(() => {
    setScreenTitle('Авто на карте');
  }, []);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTrip?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Car location
  const [carLocation, setCarLocation] = useState(null);
  //#endregion

  useEffect(() => {
    getCurrentTripLocation(currentTrip).then(location => {
      setCarLocation(location);
    }).catch(() => {})
  }, []);
  //#endregion

  return (
    <div className={'MapContainer'}>
      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      {carLocation &&
        <Map
          initialViewState={{
            latitude: carLocation.lat,
            longitude: carLocation.lng,
            zoom: 6,
          }}
          mapStyle={mapUrl}
          style={{
            height: '750px',
            width: '100%',
          }}
        >
          <Marker
            latitude={carLocation.lat}
            longitude={carLocation.lng}
            anchor={'bottom-left'}
          >
            <img alt={'Map marker'} src={iconPath} />
          </Marker>

          <FullscreenControl />
        </Map>
      }
    </div>
  );
};

export default CarOnMapLocationIq;
