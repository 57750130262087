import React, {useContext, useState, useEffect} from 'react';
import 'react-table-v6/react-table.css'
import {
  collectionsInterface,
  pagesInterface,
  rolesInterface,
  userModel,
  vehicleModel,
} from "../utils/models";
import {AppContext} from "../context/appContext";
import {
  getCollection,
  getCollectionWhereKeysValuesWithOperators,
} from "../utils/firebaseConfigAndFunctions";
import RegistrationForm from "./RegistrationForm";
import {Route, Routes, useNavigate} from "react-router-dom";
import ShowUserDataToAdmin from "./ShowUserDataToAdmin";
import UsersList from "./UsersList";
import AdminsList from "./AdminsList";
import AddAdmin from "./AddAdmin";
import ReservationDetails from "./ReservationDetails";
import AddVehicle from "./AddVehicle";

const UsersManagement = ({addClasses = ''}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {editingUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }
    setScreenTitle('Пользователи');
  }, []);
  //#endregion

  //#region Loader
  const [loader, setLoader] = useState({
    isUsersLoading: true,
    isAdminsLoading: true,
  });
  //#endregion

  //#region Users
  const [initialUsers, setInitialUsers] = useState([]);

  useEffect(() => {
    getCollection(collectionsInterface.users).then(users => {
      setInitialUsers(users.filter(curUser => curUser.isVisibleForAdmin));
    }).catch(() => {})
      .finally(() => setLoader(state => ({
        ...state,
        isUsersLoading: false,
      })));
  }, []);
  //#endregion

  //#region Search/Filter
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useState({
    role: '',
    averageMark: '',
  });

  const searchUpdate = (searchQuery) => {
    setSearchText(searchQuery);
  };

  const filterUpdate = (fieldName, value) => {
    switch (fieldName) {
      case 'role':
        if (value === 'remove') {
          setFilterData(data => ({
            ...data,
            role: '',
          }))
        } else {
          setFilterData(data => ({
            ...data,
            role: value,
          }))
        }
        break;
      case 'averageMark':
        if (value === 'remove') {
          setFilterData(data => ({
            ...data,
            averageMark: '',
          }))
        } else {
          setFilterData(data => ({
            ...data,
            averageMark: value,
          }))
        }
        break;
    }
  }

  const getUsersToDisplay = (users, searchQuery, filterQuery) => {
    let searchResult = [];

    if (searchQuery) {
      searchResult = users.filter(user => {
        let res = false;

        if (user.hasOwnProperty('fullName')) {
          if (user.fullName.toUpperCase().includes(searchQuery.toUpperCase())) {
            res = true;
          }
        }

        return res;
      });
    } else {
      searchResult = users;
    }

    let result = [];

    if (filterQuery) {
      if (Object.values(filterQuery).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(user => {
          let res = true;

          if (filterQuery.role && user.role !== filterQuery.role) {
            return false;
          }

          if (filterQuery.averageMark) {
            if (filterQuery.averageMark !== Math.floor(user.averageMark)) {
              return false;
            }
          } else if (filterQuery.averageMark === 0) {
            if (filterQuery.averageMark !== Math.floor(user.averageMark)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  }
  //#endregion

  //#region Admins
  const [initialAdmins, setInitialAdmins] = useState([]);
  const [currentAdmin, setCurrentAdmin] = useState(userModel);

  useEffect(() => {
    getCollectionWhereKeysValuesWithOperators(collectionsInterface.users, [
      {
        key: 'role',
        value: rolesInterface.admin,
        operator: '==',
      },
      {
        key: 'idPost',
        value: user.idPost,
        operator: '!=',
      }
    ], 2)
      .then(admins => {
        setInitialAdmins(admins);
      }).catch(() => {})
      .finally(() => setLoader(state => ({
        ...state,
          isAdminsLoading: false,
      })));
  }, []);
  //#endregion

  //#region Switch between all users and admins
  const toggleList = (areAdmins) => {
    if (areAdmins) {
      navigate(`/${pagesInterface.usersManagement}`)
    } else {
      navigate(`/${pagesInterface.usersManagement}/adminsList`)
    }
  }
  //#endregion

  //#region Add admin click
  const handleAddAdminClick = (user) => {
    setCurrentAdmin(user);

    navigate(`/${pagesInterface.usersManagement}/addAdmin`)
  }
  //#endregion

  //#region Current vehicle
  const [currentVehicle, setCurrentVehicle] = useState(vehicleModel);
  //#endregion

  //#region Manage displaying tabs
  const [activeTabKey, setActiveTabKey] = useState('personData');

  useEffect(() => {
    setActiveTabKey('personData');
  }, [editingUser.idPost]);
  //#endregion

  //#region Render
  return (
    <div className={`${addClasses}`}>
      <Routes>
        <Route
          index
          element={
            <UsersList
              usersToDisplay={getUsersToDisplay(initialUsers, searchText,
                filterData)}
              searchUpdate={searchUpdate}
              filterUpdate={filterUpdate}
              setInitialUsers={setInitialUsers}
              searchText={searchText}
              filterData={filterData}
              toggleList={toggleList}
              isLoading={loader.isUsersLoading}
            />
          }
        />

        <Route
          path={'userData/*'}
          element={
            <ShowUserDataToAdmin
              addClasses={'App-Screen'}
              user={editingUser}
              setUsers={setInitialUsers}
              setCurrentVehicle={setCurrentVehicle}
              activeTabKey={activeTabKey}
              setActiveTabKey={setActiveTabKey}
            />
          }
        />

        <Route
          path={'editUser/*'}
          element={
            <RegistrationForm
              addClasses={'App-Screen'}
              user={editingUser}
            />
          }
        />

        <Route
          path={'adminsList'}
          element={
            <AdminsList
              admins={initialAdmins}
              addClasses={'App-Screen'}
              toggleList={toggleList}
              handleAddAdminClick={handleAddAdminClick}
              setInitialAdmins={setInitialAdmins}
              isExternalLoading={loader.isAdminsLoading}
            />
          }
        />

        <Route
          path={'addAdmin'}
          element={
            <AddAdmin
              admin={currentAdmin}
              setAdmins={setInitialAdmins}
            />
          }
        />

        <Route
          path={'trip'}
          element={
            <ReservationDetails
              addClasses={'App-Screen App-Screen_Narrow'}
              isAdmin={true}
            />
          }
        />

        <Route
          path={'vehicle'}
          element={
            <AddVehicle
              addClasses={'App-Screen App-Screen_Narrow'}
              isAdmin={true}
              vehicle={currentVehicle}
              isRegistration={false}
              isEditing={true}
            />
          }
        />
      </Routes>
    </div>
  );
  //#endregion
};

export default UsersManagement;
