import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {rolesInterface, statisticDataModel} from "../utils/models";
import {
  getMonthsToCompare,
  recalculateAllStatisticForCustomPeriod,
  setAllStatisticDataOnComponentMount,
  showFormattedToLanguageDate
} from "../utils/helpers";
import ru from "date-fns/esm/locale/ru";
import uk from "date-fns/esm/locale/uk";
import en from "date-fns/esm/locale/en-GB";
import {DateRangePicker} from "react-date-range";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/Statistic.scss';

const Statistic = ({addClasses = ''}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Handle calendar appearance
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  //#endregion

  //#region Toggle all period or custom date
  const [isCustomDate, setIsCustomDate] = useState(false);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }
    setScreenTitle('Администратор');
  }, []);
  //#endregion

  //#region Initial data for calendar
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  //#endregion

  const getCalendarLocale = () => {
    switch (lang) {
      case 'ru':
        return ru;
      case 'uk':
        return uk;
      case 'en':
        return en;
      default:
        return en;
    }
  }

  //#region Statistic data from DB
  const [statisticData, setStatisticData] = useState(statisticDataModel);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setAllStatisticDataOnComponentMount()
      .then((result) => {
        setStatisticData(result);
        setIsLoading(false);
      }).catch(() => {});
  }, []);
  //#endregion

  //#region Calendar left block translation
  const translateCalendar = () => {
    const elements = document.getElementsByClassName('rdrStaticRangeLabel');

    for (let element of elements) {
      switch (element.innerHTML) {
        case 'Today':
          element.innerHTML = translate('Сегодня');
          break;
        case 'Yesterday':
          element.innerHTML = translate('Завтра');
          break;
        case 'This Week':
          element.innerHTML = translate('Текущая неделя');
          break;
        case 'Last Week':
          element.innerHTML = translate('Предыдущая неделя');
          break;
        case 'This Month':
          element.innerHTML = translate('Текущий месяц');
          break;
        case 'Last Month':
          element.innerHTML = translate('Предыдущий месяц');
          break;
      }
    }

    const daysElements = document.getElementsByClassName('rdrInputRange');

    for (let element of daysElements) {
      switch (element.lastChild.innerHTML) {
        case 'days up to today':
          element.lastChild.innerHTML = translate('Дней назад');
          break;
        case 'days starting today':
          element.lastChild.innerHTML = translate('Дней вперед');
          break;
      }
    }
  }

  useEffect(() => {
    if (isCalendarVisible) {
      translateCalendar();
    }
  }, [lang, isCalendarVisible]);
  //#endregion

  //#region Handle select dates range
  const handleSelect = (data) => {
    setSelectionRange({
      startDate: data.selection.startDate,
      endDate: data.selection.endDate,
      key: 'selection',
    });
  };
  //#endregion

  //#region Handle apply dates range
  const applyDatesRange = async (data) => {
    setIsCustomDate(true);
    setIsLoading(true);

    const startDate = data.startDate.getTime();
    let endDate = data.endDate.getTime();

    if (startDate === endDate) {
      endDate += 86400000;
    }

    try {
      const newStatistic = await recalculateAllStatisticForCustomPeriod(
        statisticData, startDate, endDate);

      setStatisticData(newStatistic);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
      setIsCalendarVisible(false);
    }
  };

  const applyAllPeriod = async () => {
    setIsCustomDate(false);
    setIsLoading(true);

    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });

    try {
      const data = await setAllStatisticDataOnComponentMount();

      setStatisticData(data);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Get compared months name for growth
  const getTwoPreviousMonthsNames = (lang) => {
    const months = getMonthsToCompare();

    const firstName = new Date(months.firstMonth.startPoint)
      .toLocaleString(lang, { month: 'long' });

    const secondName = new Date(months.secondMonth.startPoint)
      .toLocaleString(lang, { month: 'long' });

    return `${secondName} / ${firstName}`;
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`Statistic ${addClasses}`}>
        <div className={'PaddingBlock'}></div>

        <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {translate(screenTitle)}
        </h2>

        {isLoading ?
          <Loader/>
          :
          <>
            <h2 className={'RegularTitle RegularTitle_Small'}>
              {translate('Статистика за')} {' '}

              {isCustomDate ?
                <span>
                  {`${showFormattedToLanguageDate(lang,
                    selectionRange.startDate)} - ${showFormattedToLanguageDate(
                      lang, selectionRange.endDate)}`}
                </span>
                :
                translate('весь период')
              }
            </h2>

            <div
              className={'Statistic-Item Statistic-CalendarButtons FlexBlock'}>
              <button
                className={'RegularButton FlexBlock-HorizontalItem_W48'}
                onClick={() => setIsCalendarVisible(state => !state)}
              >
                {isCalendarVisible ?
                  translate('Свернуть календарь')
                  :
                  translate('Выбрать даты')
                }
              </button>

              {isCalendarVisible ?
                <button
                  className={'RegularButton RegularButton_Contrast ' +
                    'FlexBlock-HorizontalItem_W48'}
                  onClick={() => applyDatesRange(selectionRange)}
                >
                  {translate('Применить')}
                </button>
                :
                <button
                  className={'RegularButton RegularButton_Contrast ' +
                    'FlexBlock-HorizontalItem_W48'}
                  onClick={() => applyAllPeriod()}
                  disabled={!isCustomDate}
                >
                  {translate('Весь период')}
                </button>
              }
            </div>

            {isCalendarVisible &&
              <div className={'Statistic-DateRangeSelection'}>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  locale={getCalendarLocale()}
                  months={1}
                />
              </div>
            }


            <table className={'DataTableForAdmin'}>
              <thead>
                <tr>
                  <th
                    className={'DataTableForAdmin-Cell HeaderCell'}
                  >
                    {translate('Показатель')}
                  </th>
                  <th
                    className={'DataTableForAdmin-Cell HeaderCell'}
                  >
                    {translate('Сумма')}
                  </th>
                  <th
                    className={'DataTableForAdmin-Cell HeaderCell'}
                  >
                    {translate('Прирост')}{` (${getTwoPreviousMonthsNames(lang)})`}
                  </th>
                </tr>
              </thead>

              <tbody>
              <tr
                className={'TitleRow'}
              >
                <td colSpan={3} className={'DataTableForAdmin-Cell'}>
                  {translate('Пользователи')}
                </td>
              </tr>
              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Все пользователи')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData.allUsersNumber.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData.allUsersNumber.growth}{'%'}
                </td>
              </tr>
              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Администраторы')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsAdmin?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsAdmin?.growth}{'%'}
                </td>
              </tr>
              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Компании')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsCompany?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsCompany?.growth}{'%'}
                </td>
              </tr>
              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Водители из компаний')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsDriver?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsDriver?.growth}{'%'}
                </td>
              </tr>
              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Индивидуальные перевозчики')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsIndividual?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.usersAsIndividual?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'TitleRow'}
              >
                <td colSpan={3} className={'DataTableForAdmin-Cell'}>
                  {translate('Поездки')}
                </td>
              </tr>

              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Все поездки')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.trips?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.trips?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Завершенные поездки')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsCompleted?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsCompleted?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Отмененные поездки')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsCancelled?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsCancelled?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Заявки на поездки')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsOrders?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsOrders?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Завершенные заявки на поездки')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsOrdersCompleted?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsOrdersCompleted?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'DataRow'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Отмененные заявки на поездки')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsOrdersCancelled?.quantity}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.tripsOrdersCancelled?.growth}{'%'}
                </td>
              </tr>

              <tr
                className={'TitleRow'}
              >
                <td colSpan={3} className={'DataTableForAdmin-Cell'}>
                  {translate('Финансовые результаты')}
                </td>
              </tr>

              <tr
                className={'DataRow DataRow_Last'}
              >
                <td className={'DataTableForAdmin-Cell'}>
                  {translate('Комиссии от пользователей')}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.commission?.quantity} {'грн.'}
                </td>
                <td className={'DataTableForAdmin-Cell'}>
                  {statisticData?.commission?.growth}{'%'}
                </td>
              </tr>
              </tbody>
            </table>
          </>
        }
      </div>

      <div className={'PaddingBlock'}></div>
    </>
  );
  //#endregion
};

export default Statistic;
