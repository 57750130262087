import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import DriverCard from "./DriverCard";
import InviteDriver from "./InviteDriver";
import {useNavigate} from "react-router-dom";
import DriversList from "./DriversList";
import DisplayMyDriverTrips from "./DisplayMyDriverTrips";
import AddTripForDriverOrVehicle from "./AddTripForDriverOrVehicle";
import useTranslation from "../hooks/useTranslation";
import '../styles/MyDrivers.scss';

const MyDrivers = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Driver screens
  const [driverScreens, setDriverScreens] = useState({
    isAddTripScreen: false,
    isDriverScreen: true,
    isTripsScreen: false,
  })
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Мои водители');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Manage screens displaying
  const [isDriversListVisible, setIsDriversListVisible] = useState(true);
  const [isAddDriverScreenVisible, setIsAddDriverScreenVisible] = useState(false);
  //#endregion

  //#region Current driver
  const [currentDriver, setCurrentDriver] = useState(undefined);
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`MyDrivers ${addClasses}`}>
        {isDriversListVisible &&
          <DriversList
            setIsDriversListVisible={setIsDriversListVisible}
            setIsAddDriverScreenVisible={setIsAddDriverScreenVisible}
            setCurrentDriver={setCurrentDriver}
            addClasses={'App-Screen'}
          />
        }

        {isAddDriverScreenVisible &&
          <InviteDriver
            addClasses={'MyDrivers-InviteDriver'}
            goBack={() => {
              setIsDriversListVisible(true);
              setIsAddDriverScreenVisible(false);
            }}
          />
        }

        {currentDriver &&
          <>
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>

            {!driverScreens?.isDriverScreen &&
              <button
                type={'button'}
                className={'RegularButton RegularButton_Centered RegularButton_Contrast'}
                onClick={() => setDriverScreens({
                  isAddTripScreen: false,
                  isDriverScreen: true,
                  isTripsScreen: false,
                })}
              >
                {translate('Вернуться')}
              </button>
            }

            {driverScreens?.isDriverScreen &&
              <DriverCard
                driver={currentDriver}
                isEditMode={true}
                goBack={() => {
                  setIsDriversListVisible(true);
                  setCurrentDriver(undefined);
                }}
                addClasses={'MyDrivers-Driver App-Screen_Narrow'}
                setDriverScreens={setDriverScreens}
              />
            }

            {driverScreens?.isAddTripScreen &&
              <AddTripForDriverOrVehicle
                driver={currentDriver}
                addClasses={'App-Screen App-Screen_Narrow'}
                setDriverScreens={setDriverScreens}
              />
            }

            {driverScreens?.isTripsScreen &&
              <DisplayMyDriverTrips
                driver={currentDriver}
                addClasses={'App-Screen'}
                isReducedDisplaying={true}
              />
            }
          </>
        }
      </div>
    </>
  );
  //#endregion
};

export default MyDrivers;
