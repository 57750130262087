import React, {useState, useEffect, useContext} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import TripsOrdersListForAdmin from "./TripsOrdersListForAdmin";
import {collectionsInterface, pagesInterface} from "../utils/models";
import {getCollectionWhereKeyValue} from "../utils/firebaseConfigAndFunctions";
import CreateTripOrder from "./CreateTripOrder";
import {AppContext} from "../context/appContext";

const DisplayTripsOrdersForAdmin = ({addClasses = ''}) => {
  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  ///#region tripsOrders
  const [tripsOrders, setTripsOrders] = useState([]);

  useEffect(() => {
    getCollectionWhereKeyValue(collectionsInterface.tripsOrders,
      'isVisibleForAdmin', true).then(data => setTripsOrders(data))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  const updateTripOrdersListAfterTripOrderEditing = (updatedOrder) => {
    setTripsOrders(orders => orders
      .map(order => order.idPost === updatedOrder.idPost ? updatedOrder
        : order));
  }
  //#endregion

  //#region Render
  return (
    <div className={`DisplayTripsOrdersForAdmin ${addClasses}`}>
      <Routes>
        <Route
          index
          element={
            <TripsOrdersListForAdmin
              tripsOrders={tripsOrders}
              setTripsOrders={setTripsOrders}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          }
        />

        <Route
          path={'editTripOrder'}
          element={
            <CreateTripOrder
              isDispatcher={true}
              isAdmin={true}
              isNotepad={currentTripOrder.isDraft}
              addClasses={'App-Screen_Narrow'}
              handleGoBack={() => navigate(`/${pagesInterface.passengersAdmin}`)}
              updateTripOrdersListAfterTripOrderEditing={
                updateTripOrdersListAfterTripOrderEditing
              }
            />
          }
        />
      </Routes>
    </div>
  );
  //#endregion
};

export default DisplayTripsOrdersForAdmin;
