import React, {useState, useContext, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import ActiveTripOrderDetails from "./ActiveTripOrderDetails";
import DisplayActiveTripsOrders from "./DisplayActiveTripsOrders";
import {
  getCollectionWhereKeysValues
} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, subpagesInterface} from "../utils/models";
import {sortCardsByDateOfCreation} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import {queryArraysService} from "../utils/queryArraysService";

const ActiveTripsForPassengers = ({
  handleShowCreateTrip = () => {},
  handleTripOrderCardClick = () => {},
  addClasses='',
}) => {
  // //#region Update after deal cancellation
  // const updateAfterDealCancellation = (tripOrder) => {
  //   setTripsOrdersToDisplay(orders => orders
  //     .filter(order => order.idPost !== tripOrder.idPost));
  // }
  // //#endregion

  //#region Render
  return (
    <>
      <div className={`ActiveTripsForPassengers ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <DisplayActiveTripsOrders
                // tripsOrdersToDisplay={tripsOrdersToDisplay}
                handleShowCreateTrip={handleShowCreateTrip}
                handleTripOrderCardClick={handleTripOrderCardClick}
                addClasses={'Passengers-DisplayTripsOrders App-Screen'}
                // isLoading={isLoading}
              />
            }
          />

          <Route
            path={`/${subpagesInterface.activeTripOrderDetails}/*`}
            element={
              <ActiveTripOrderDetails
                isDispatcher={true}
                // updateAfterDealCancellation={updateAfterDealCancellation}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
  //#endregion
};

export default ActiveTripsForPassengers;
