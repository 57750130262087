import React, {useEffect, useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  cancelReservationsAfterTripOrderSubmit, compareSchemeAndDesirablePlaces,
  editTripForTransferredPassenger,
  getAvailablePlacesFromList,
  getDataFromTripOrderToSaveInReservation,
  getDataFromTripToSaveInReservation,
  getFreePlacesArrayFromPlacesScheme,
  getPriceForReservation,
  sendMessageAboutPassengerReservation,
  sendMessageAboutTripReservation,
  transformStringsArrayToSelectOptions
} from "../utils/helpers";
import Select from "react-select";
import PlacesInTripEditing from "./PlacesInTripEditing";
import {
  getCollectionWhereKeyValue,
  getDocInCollection, setDocumentToCollection,
  updateFieldInDocumentInCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  dealModel,
  pagesInterface,
  reservationModel
} from "../utils/models";
import ModalNotification from "../modals/ModalNotification";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import LoaderSmall from "./LoaderSmall";
import {v4 as uuidv4} from 'uuid';
import BoardingPlacesFromDriver from "./BoardingPlacesFromDriver";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import {checkIsTripSuitableForPassenger} from "../utils/checkTripService";
import LocationIq from "./LocationIq";
import {
  getTranslatedLocalityName
} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import '../styles/ConfirmReservation.scss';

const ConfirmReservation = ({
  addClasses = '',
  isDispatcher = true,
  isPassengerTransfer = false,
  setTripsToDisplay = () => {},
  isFromExchange = false,
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, currentTrip, setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle(currentTripOrder.creatorIdPost === currentTrip.creatorIdPost
      ? 'Добавить в поездку' : 'Забронировать');
  }, []);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loaders
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  //#endregion

  //#region Reservation data and it's updating
  const [reservationData, setReservationData] = useState(reservationModel);

  const updateReservation = (fieldName, newValue) => {
    if (fieldName === 'pricePerPerson') {
      setReservationData(data => ({
        ...data,
        anotherFinancialConditions: {
          ...data.anotherFinancialConditions,
          pricePerPerson: newValue,
        }
      }));

      return;
    }

    setReservationData(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  }

  const handlePlaceSelect = (newPlace) => {
    updateReservation('passengerDepartureAddress', {
      lat: newPlace.lat,
      lon: newPlace.lon,
      fullAddress: {
        [lang]: newPlace.fullAddress,
        default: newPlace.fullAddress,
      },
      placeId: newPlace.placeId,
    })
  }

  const changeReservedPlaceNumber = (placeNumber, index) => {
    try {
      const newReservedPlacesArray = reservationData?.reservedPlacesNumbers
        ?.map((place, pIndex) => index !== pIndex ? place : placeNumber);

      updateReservation('reservedPlacesNumbers', newReservedPlacesArray);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTripOrder?.idPost || !currentTrip.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Set initial data to reservation
  useEffect(() => {
    if (currentTrip?.startPoint?.placeId === currentTripOrder
      ?.startPoint?.placeId) {
      if (isDispatcher && currentTrip?.isBoardingByDriverAddresses &&
        currentTrip?.boardingPlacesFromDriver && currentTrip
          ?.boardingPlacesFromDriver?.length > 0) {

        updateReservation('passengerDepartureAddress', {
          placeId: currentTrip?.boardingPlacesFromDriver[0]?.placeId,
          fullAddress: currentTrip?.boardingPlacesFromDriver[0]?.fullAddress,
        });
      }
    }

    updateReservation('reservedPlaces', Number(currentTripOrder
      ?.requiredNumberOfPlaces));

    if (currentTripOrder.creatorIdPost !== currentTrip.creatorIdPost) {
      if (!isDispatcher) {
        if (currentTripOrder?.dispatcherFee) {
          updateReservation('dispatcherFee',
            currentTripOrder.dispatcherFee);
        }

        if (currentTripOrder?.isForChange) {
          updateReservation('isForChange',
            currentTripOrder.isForChange);
        }
        if (!isFromExchange && currentTripOrder?.isPaymentByCash) {
          updateReservation('isPaymentByCash',
            currentTripOrder.isPaymentByCash);
        }
        if (!isFromExchange && currentTripOrder?.isPaymentOnCard) {
          updateReservation('isPaymentOnCard',
            currentTripOrder.isPaymentOnCard);
        }
      }
    }
  }, []);
  //#endregion

  //#region Places numbers list and its handling
  const [placesList, setPlacesList] = useState([]);

  useEffect(() => {
    if (currentTrip?.placesScheme?.rows?.length > 0) {
      const places = getFreePlacesArrayFromPlacesScheme(
        currentTrip?.placesScheme);

      setPlacesList(places);

      const reservedPlacesNumbers = [];

      for (let i = 0; i < currentTripOrder.requiredNumberOfPlaces; i++) {
        reservedPlacesNumbers.push(places[i]);
      }

      updateReservation('reservedPlacesNumbers', reservedPlacesNumbers);
    }
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Reservation confirmation
  const [isReserved, setIsReserved] = useState(false);

  const makeReservationForPassenger = async (newReservation, isDispatcher,
    tripOrder, trip, isTransfer = false) => {
    try {
      const actualTripOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, tripOrder.idPost);

      let newReservationsArrayForTripOrder = [];

      if (isDispatcher) {
        newReservationsArrayForTripOrder = [
          ...actualTripOrder.reservationsFromDispatchers, newReservation];

        await updateFieldsInDocumentInCollection(
          collectionsInterface.tripsOrders, tripOrder.idPost, {
          hasAnyReservations: true,
          hasReservationsFromDispatchers: true,
          isTransferred: isTransfer,
          reservationsFromDispatchers: newReservationsArrayForTripOrder,
        });
      } else {
        newReservationsArrayForTripOrder = [
          ...actualTripOrder.reservationsFromDrivers, newReservation];

        await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
          tripOrder.idPost, {
          hasAnyReservations: true,
          hasReservationsFromDrivers: true,
          hasNewAction: true,
          reservationsFromDrivers: newReservationsArrayForTripOrder,
        });
      }

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        trip.idPost);

      let newReservationsArrayForTrip = [];

      let fieldsToUpdateInTrip;

      if (isDispatcher) {
        if (actualTrip?.reservationsFromDispatchers &&
          actualTrip?.reservationsFromDispatchers?.length > 0) {
          newReservationsArrayForTrip = [
            ...actualTrip.reservationsFromDispatchers, newReservation];
        } else {
          newReservationsArrayForTrip = [newReservation];
        }

        fieldsToUpdateInTrip = {
          hasAnyReservations: true,
          hasReservationsFromDispatchers: true,
          hasNewAction: true,
          reservationsFromDispatchers: newReservationsArrayForTrip,
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          trip.idPost, fieldsToUpdateInTrip);

        await sendMessageAboutTripReservation(actualTrip.creatorIdPost,
          actualTrip, actualTripOrder);
      } else {
        if (actualTrip?.reservationsFromDrivers &&
          actualTrip?.reservationsFromDrivers?.length > 0) {
          newReservationsArrayForTrip = [
            ...actualTrip.reservationsFromDrivers, newReservation];
        } else {
          newReservationsArrayForTrip = [newReservation];
        }

        fieldsToUpdateInTrip = {
          hasAnyReservations: true,
          hasReservationsFromDrivers: true,
          reservationsFromDrivers: newReservationsArrayForTrip,
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          trip.idPost, fieldsToUpdateInTrip);

        await sendMessageAboutPassengerReservation(user.idPost, actualTrip,
          actualTripOrder);
      }

      let newTripOrder;

      if (isDispatcher) {
        newTripOrder = {
          ...tripOrder,
          hasAnyReservations: true,
          hasReservationsFromDispatchers: true,
          reservationsFromDispatchers: newReservationsArrayForTripOrder,
        };
      } else {
        newTripOrder = {
          ...tripOrder,
          hasAnyReservations: true,
          hasReservationsFromDrivers: true,
          reservationsFromDrivers: newReservationsArrayForTripOrder,
        };
      }

      setTripsToDisplay(prevTrips => prevTrips
        .filter(trip => trip.idPost !== actualTrip.idPost));

      setIsReserved(true);

      setTimeout(() => {
        if (isFromExchange) {
          navigate(`/${pagesInterface.exchange}`);
          setScreenTitle('Обмен');
        } else if (isTransfer) {
          navigate(`/${pagesInterface.trips}/activeTrips`);
          setScreenTitle('Активные поездки');
        } else if (isDispatcher) {
          navigate(`/${pagesInterface.passengers}/suitableTrips`);
          setScreenTitle('Найденные поездки');
        } else {
          navigate(`/${pagesInterface.trips}/searchPassenger/searchResults`);
          setScreenTitle('Найденные пассажиры');
        }
      }, 500);
    } catch (error) {
      return error;
    }
  };

  const makeReservationForTransferredPassenger = async (tripOrder,
    newReservation, trip, isDispatcher) => {
    await updateFieldInDocumentInCollection(collectionsInterface.tripsOrders,
      tripOrder.idPost, 'wasTransferred', true);

    let tripOrderClone;

    const clonedTripsOrders = await getCollectionWhereKeyValue(
      collectionsInterface.tripsOrders, 'sourceTripOrderId', tripOrder.idPost);

    const currentDate = Date.now();

    if (clonedTripsOrders.length === 0) {
      tripOrderClone = {
        ...tripOrder,
        creatorUid: user.uid,
        creatorIdPost: user.idPost,
        hasReservationsFromDispatchers: false,
        hasReservationsFromDrivers: false,
        hasAnyReservations: false,
        acceptedDeal: {},
        dispatcherEmail: user.email,
        dispatcherFullName: user.fullName,
        isTransferred: true,
        wasTransferred: false,
        isActive: false,
        isDraft: false,
        isOpenForSearch: true,
        isVisibleForDrivers: false,
        dateCreating: currentDate,
        dateEditing: currentDate,
        reservationsFromDrivers: [],
        reservationsFromDispatchers: [],
        sourceTripOrderId: tripOrder.idPost,
        sourceTripOrder: tripOrder,
      }

      const tripOrderSaved = await setDocumentToCollection(
        collectionsInterface.tripsOrders, tripOrderClone);

      tripOrderClone = {
        ...tripOrderClone,
        idPost: tripOrderSaved.result.id,
      }
    } else {
      tripOrderClone = {
        ...clonedTripsOrders[0]
      }
    }

    await editTripForTransferredPassenger(tripOrder.acceptedDeal?.originalTrip
      .idPost, tripOrder.idPost);

    const updatedReservation = {
      ...newReservation,
      initiator: 'dispatcher',
      isSubmittedByDispatcher: true,
      isSubmittedByDriver: false,
      originalTripOrder: tripOrderClone,
      isTransferred: true,
    }

    await makeReservationForPassenger(updatedReservation, isDispatcher,
      tripOrderClone, trip, true);
  }

  const addPassengerToOwnTrip = async (tripOrder, trip, reservationData) => {
    try {
      const currentDate = Date.now();

      const deal = {
        ...dealModel,
        dateCreating: currentDate,
        id: tripOrder.idPost,
        initiator: 'both',
        isPassengerInOwnTrip: true,
        isTotallyAccepted: true,
        dispatcherFee: 0,
        originalTrip: getDataFromTripToSaveInReservation(trip),
        originalTripOrder: getDataFromTripOrderToSaveInReservation(tripOrder),
        passengerDepartureAddress: reservationData.passengerDepartureAddress,
        pricePerPerson: tripOrder.pricePerPerson,
        reservedPlaces: reservationData.reservedPlaces,
        reservedPlacesNumbers: reservationData.reservedPlacesNumbers,
        uuid: uuidv4(),
      }

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        trip.idPost);

      if (!actualTrip?.idPost) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Поездка была удалена');

        return;
      }

      const actualOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, tripOrder.idPost);

      if (!actualOrder?.idPost) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Пассажир был удален');

        return;
      }

      if (actualOrder?.isActive) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'У пассажира уже есть подтвержденная поездка');

        return;
      }

      const updatedFreePlaces = parseInt(actualTrip.freePlaces) - parseInt(
        actualOrder.requiredNumberOfPlaces);

      if (updatedFreePlaces < 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка бронирования');
        setNotificationMessage('Количество бронируемых мест больше ' +
          'количества свободных мест в поездке');

        return;
      }

      if (actualOrder.requiredNumberOfPlaces !== currentTripOrder
        .requiredNumberOfPlaces) {
        setIsNotification(true);
        setNotificationTitle('Ошибка бронирования');
        setNotificationMessage('Было изменено количество мест, необходимых' +
          ' пассажиру');

        return;
      }

      const tripCheckResult = checkIsTripSuitableForPassenger(actualTrip
        .startDateWithoutTime, actualOrder.departureDateWithoutTime, actualTrip
        .tripPoints, actualOrder.startPoint.placeId, actualOrder.endPoint
        .placeId, actualTrip.isPassengerTrip, actualOrder.isPassengerTrip);

      if (!tripCheckResult.isSuitable) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Поездка уже не подходит пассажиру.' +
          ' Осуществите поиск заново');

        return;
      }

      if (actualTrip.acceptedDeals?.some(deal => deal.id === tripOrder.idPost)) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('У пассажира уже есть бронь в данной поездке');

        return;
      }

      let updatedTripFields = {};

      if(!actualTrip?.isFreeSeating) {
        const occupiedRequiredPlaces = compareSchemeAndDesirablePlaces(
          actualTrip.placesScheme, deal.reservedPlacesNumbers);

        if (occupiedRequiredPlaces.length > 0) {
          setIsNotification(true);
          setNotificationTitle('Ошибка бронирования');
          setNotificationMessage('Выбранные места недоступны');
          setCurrentTrip(actualTrip);

          return;
        }

        const updatedScheme = {
          ...actualTrip.placesScheme,
          rows: actualTrip.placesScheme.rows.map(curRow => ({
            row: curRow.row
              .map(cell => deal.reservedPlacesNumbers.includes(cell.placeNumber) ? {
                ...cell,
                isOccupied: true,
              } : cell)})),
        }

        updatedTripFields = {
          placesScheme: updatedScheme,
        }
      }

      updatedTripFields = {
        ...updatedTripFields,
        freePlaces: updatedFreePlaces,
        isActive: true,
        acceptedDeals: actualTrip?.acceptedDeals ? [...actualTrip.acceptedDeals,
          deal] : [deal],
      };

      await updateFieldsInDocumentInCollection(collectionsInterface.trips,
        trip.idPost, updatedTripFields);

      await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
        tripOrder.idPost, {
          acceptedDeal: deal,
          isOpenForSearch: false,
          isActive: true,
        });

      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, tripOrder.idPost)

      await cancelReservationsAfterTripOrderSubmit(deal, actualTripOrder, true);

      if (isDispatcher) {
        navigate(`/${pagesInterface.passengers}/suitableTrips`);
        setScreenTitle('Найденные поездки');
      } else {
        navigate(`/${pagesInterface.trips}/searchPassenger/searchResults`);
        setScreenTitle('Найденные пассажиры');
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  const confirmReservation = async (tripOrder, trip, reservationData) => {
    setIsLoading(true);
    const isPassengerInOwnTrip = currentTrip?.creatorIdPost === currentTripOrder
      ?.creatorIdPost

    try {
      if (!checkIsIntermediatePoint() && (isDispatcher && !reservationData
        ?.passengerDepartureAddress?.fullAddress)) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Не выбран адрес посадки пассажиров');

        return;
      }

      if ((!reservationData?.dispatcherFee || parseInt(reservationData
        .dispatcherFee) <= 0) && !isPassengerInOwnTrip) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Сумма гонорара должна быть больше нуля');

        return;
      }

      const actualOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, tripOrder.idPost);

      if (!isPassengerTransfer && actualOrder.isActive) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('У пассажира уже есть подтвержденная поездка');

        return;
      }

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        trip.idPost);

      if (actualTrip.reservationsFromDispatchers
          .some(reservation => reservation.originalTripOrder
            .idPost === tripOrder.idPost)
        || actualTrip.reservationsFromDrivers
          .some(reservation => reservation.originalTripOrder
            .idPost === tripOrder.idPost)) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('У пассажира уже есть бронь в данной поездке');

        return;
      }

      if (isPassengerInOwnTrip) {
        await addPassengerToOwnTrip(tripOrder, trip, reservationData);

        return;
      }

      let isPaymentMethodDefined = false;

      switch (true) {
        case reservationData.isPaymentOnCard:
        case reservationData.isPaymentByCash:
        case reservationData.isForChange:
          isPaymentMethodDefined = true;
          break;
      }

      if (!isPaymentMethodDefined) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Необходимо выбрать способ оплаты');

        return;
      }

      const tripCheckResult = checkIsTripSuitableForPassenger(actualTrip
        .startDateWithoutTime, actualOrder.departureDateWithoutTime,
        actualTrip.tripPoints, actualOrder.startPoint.placeId, actualOrder
          .endPoint.placeId, actualTrip.isPassengerTrip, actualOrder.isPassengerTrip);

      if (!tripCheckResult.isSuitable) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Поездка уже не подходит пассажиру.' +
          ' Осуществите поиск заново');

        return;
      }

      const pricePerPerson = reservationData?.anotherFinancialConditions
        ?.pricePerPerson || getPriceForReservation(trip, tripOrder,
        isDispatcher ? 'dispatcher' : 'driver')


      if (reservationData?.dispatcherFee > pricePerPerson / 2) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Гонорар не может быть больше 50% от суммы поездки');

        return;
      }

      const reservationDataToSave = {
        ...reservationData,
        dispatcherFee: reservationData.dispatcherFee,
        pricePerPerson,
        initiator: isDispatcher ? 'dispatcher' : 'driver',
        isSubmittedByDispatcher: isDispatcher,
        isSubmittedByDriver: !isDispatcher,
        hasNewActionFromDispatcher: isDispatcher,
        hasNewActionFromTransporter: !isDispatcher,
      }

      const currentDate = Date.now();

      const newReservation = {
        ...reservationDataToSave,
        dateCreating: currentDate,
        dateEditing: currentDate,
        originalTripOrder: getDataFromTripOrderToSaveInReservation(tripOrder),
        originalTrip: getDataFromTripToSaveInReservation(trip),
        uuid: uuidv4(),
      };

      if (isPassengerTransfer) {
        await makeReservationForTransferredPassenger(tripOrder, newReservation,
          actualTrip, isDispatcher);
      } else {
        await makeReservationForPassenger(newReservation, isDispatcher,
          tripOrder, actualTrip);
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Set places points names
  const [startPassengerCity, setStartPassengerCity] = useState('');

  const getPlacesCitiesNamesForReservationConfirmation = () => {
    try {
      const startCity = getTranslatedLocalityName(currentTripOrder?.startPoint
        ?.city, lang);

      setStartPassengerCity(startCity);
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (lang) {
      getPlacesCitiesNamesForReservationConfirmation();
    }
  }, [lang]);
  //#endregion

  //#region Handle choosing departure address
  const handlePassengerDepartureAddressChange  = (address) => {
    updateReservation('passengerDepartureAddress', {
      lat: address.lat,
      lon: address.lon,
      placeId: address.placeId,
      fullAddress: {
        ...address.fullAddress,
      },
    })
  }
  //#endregion

  //#region Check is intermediate point
  const checkIsIntermediatePoint = () => {
    const checkResult = (currentTripOrder?.startPoint?.placeId !== currentTrip?.startPoint
      ?.placeId) || (currentTripOrder?.endPoint?.placeId !== currentTrip
      ?.endPoint?.placeId);

    return checkResult;
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div className={`ConfirmReservation ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'ConfirmReservation-Item'}>
            <h2 className={'DataLabel'}>
              {translate('Пункт выезда')}
            </h2>
            <span>
              {startPassengerCity || getTranslatedLocalityName(currentTripOrder
                ?.startPoint?.city, lang)}
            </span>

            <div className={'SeparationBlock SeparationBlock_Limited'}></div>
          </div>

          {(currentTripOrder?.isPassengerTrip && isDispatcher) &&
            <>
              <h2 className={'DataLabel'}>
                {translate('Место посадки пассажиров')}
              </h2>

              <div className={'ConfirmReservation-Item'}>
                {(currentTrip?.startPoint?.placeId
                  !== currentTripOrder?.startPoint?.placeId) ? (
                  <p>
                    {translate('Договоритесь с водителем о месте ' +
                      'посадки после подтверждения брони')}
                  </p>
                ) : (currentTrip?.isBoardingByDriverAddresses ? (
                    <div>
                      <p>
                        {translate('Адреса, предлагаемые водителем')}
                      </p>
                      <h2 className={'DataLabel'}>
                        {translate('Выбор адреса посадки')}
                      </h2>

                      <BoardingPlacesFromDriver
                        reservationData={reservationData}
                        handlePassengerDepartureAddressChange={
                          handlePassengerDepartureAddressChange
                        }
                        shouldBeChosen={(isDispatcher &&
                          currentTrip?.boardingPlacesFromDriver?.length > 0)}
                        setExternalLoader={setIsAddressLoading}
                      />
                    </div>
                  ) : (
                    <div>
                      <label>
                        {translate('Введите удобный Вам адрес')}
                      </label>

                      <LocationIq
                        externalAction={handlePlaceSelect}
                        receivedAddress={getTranslatedLocalityName(reservationData
                          ?.passengerDepartureAddress?.fullAddress, lang)}
                      />
                    </div>
                  )
                )}
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          {currentTripOrder?.isPassengerTrip &&
            <>
              <div className={'ConfirmReservation-Item'}>
                {translate('Количество билетов')} {': '}
                {currentTripOrder?.requiredNumberOfPlaces}
              </div>

              {currentTrip?.placesScheme &&
                <>
                  <div className={'ConfirmReservation-Item'}>
                    {translate('Выбор мест')}
                  </div>

                  <div className={'ConfirmReservation-Item'}>
                    <div className={'ConfirmReservation-PlacesSelects'}>
                      {reservationData?.reservedPlacesNumbers?.map((placeNumber,
                        index) =>
                        <React.Fragment key={placeNumber}>
                          <Select
                            classNames={{
                              control: (state) =>
                                state.isFocused
                                  ? 'FormControl ConfirmReservation-Select'
                                  : 'FormControl ConfirmReservation-Select',
                            }}
                            options={transformStringsArrayToSelectOptions(
                              getAvailablePlacesFromList(placesList,
                                reservationData.reservedPlacesNumbers))}
                            onChange={(event) => changeReservedPlaceNumber(
                              event.value, index)}
                            value={{
                              value: reservationData
                                ?.reservedPlacesNumbers[index] || '',
                              label: reservationData
                                ?.reservedPlacesNumbers[index] || '',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>

                  <div className={'ConfirmReservation-Item'}>
                    <PlacesInTripEditing
                      addClasses={'AddTripForm-PlacesInTripEditing'}
                      initialScheme={currentTrip?.placesScheme}
                      isDispatcher={true}
                    />
                  </div>
                </>
              }
            </>
          }

          {!currentTripOrder?.isPassengerTrip &&
            <div className={'ConfirmReservation-Item'}>
              <h2
                className={'RegularTitle RegularTitle_Left RegularTitle_Small'}>
                {translate('Описание груза')}
              </h2>

              <p>
                {currentTripOrder?.cargoDescription}
              </p>
            </div>
          }

          {currentTripOrder.creatorIdPost !== currentTrip.creatorIdPost &&
            <>
              <div className={'SeparationBlock SeparationBlock_Limited'}></div>

              <div className={'ConfirmReservation-Item'}>
                <label className={'ReplaceTripOrderScreen-Label'}>
                  {translate('Желаемый гонорар')}
                </label>

                <NumberInputWithValidation
                  addClasses={'ReplaceTripOrderScreen-Input'}
                  externalChangeHandler={updateReservation}
                  fieldName={'dispatcherFee'}
                  placeholder={translate('Желаемый гонорар')}
                  value={reservationData?.dispatcherFee}
                />
              </div>

              {checkIsIntermediatePoint() &&
                <div className={'ConfirmReservation-Item'}>
                  <label className={'ReplaceTripOrderScreen-Label'}>
                    {translate('Желаемая цена')}
                  </label>

                  <NumberInputWithValidation
                    addClasses={'ReplaceTripOrderScreen-Input'}
                    externalChangeHandler={updateReservation}
                    fieldName={'pricePerPerson'}
                    placeholder={translate('Желаемая цена')}
                    value={reservationData?.anotherFinancialConditions?.pricePerPerson}
                  />
                </div>
              }

              <div className={'ConfirmReservation-Item'}>
                <h5 className={'RegularTitle RegularTitle_Small'}>
                  {translate('Способ оплаты')}
                </h5>

                {!isFromExchange &&
                  <>
                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripOrderScreen-Block'}
                    >
                      <div className={'OptionCheckBox'}>
                        <input
                          id={'isPaymentOnCard'}
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          checked={reservationData?.isPaymentOnCard}
                          onChange={(event) => updateReservation(
                            'isPaymentOnCard', event.target.checked)}
                          disabled={reservationData?.isPaymentByCash}
                        />
                      </div>

                      <label
                        className={'ReplaceTripOrderScreen-OptionText'}
                        htmlFor={'isPaymentOnCard'}>
                        {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                      </label>

                      {reservationData?.isPaymentOnCard &&
                        <div className={'ReplaceTripOrderScreen-IconContainer'}>
                          <img
                            src={'/img/bank-safe-icon.svg'}
                            className={'ReplaceTripOrderScreen-Icon'}
                          />
                        </div>
                      }
                    </div>

                    <div
                      className={
                        'FlexBlock FlexBlock_JustifyLeft ReplaceTripOrderScreen-Block'
                      }
                    >
                      <input
                        id={'isPaymentByCash'}
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        checked={reservationData?.isPaymentByCash}
                        onChange={(event) => updateReservation(
                          'isPaymentByCash', event.target.checked)}
                        disabled={reservationData?.isPaymentOnCard}
                      />
                      <label
                        className={'ReplaceTripOrderScreen-OptionText'}
                        htmlFor={'isPaymentByCash'}
                      >
                        {translate('Наличные по договоренности')}
                      </label>
                    </div>
                  </>
                }
                <div
                  className={
                    'FlexBlock FlexBlock_JustifyLeft ReplaceTripOrderScreen-Block'
                  }
                >
                  <input
                    id={'isForChange'}
                    type={'checkbox'}
                    className={'OptionCheckBox'}
                    onChange={(event) => updateReservation('isForChange',
                      event.target.checked)}
                    checked={reservationData?.isForChange}
                  />
                  <label
                    className={'ReplaceTripOrderScreen-OptionText'}
                    htmlFor={'isForChange'}
                  >
                    {translate('Обмен')}
                  </label>
                </div>
              </div>
            </>
          }

          <div className={'ConfirmReservation-Item'}>
            {isReserved ?
              <div
                className={'InfoField InfoField_Contrast'}
              >
                {translate('Забронировано')}
              </div>
              :
              (isAddressLoading ? <LoaderSmall />
                :
                <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => confirmReservation(currentTripOrder, currentTrip,
                  reservationData)}
              >
                {translate(currentTripOrder.creatorIdPost === currentTrip
                    .creatorIdPost ? 'Добавить в поездку' : 'Забронировать')}
              </button>)
            }
          </div>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default ConfirmReservation;
