import React, {useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {collectionsInterface, vehicleTripModel} from "../utils/models";
import Loader from "./Loader";
import DisplayUserData from "./DisplayUserData";
import {
  checkVehicleOrDriverTripCompleteness,
  convertTimestampToCalendarDate,
} from "../utils/helpers";
import ModalNotification from "../modals/ModalNotification";
import DisplayVehicleData from "./DisplayVehicleData";
import {setDocumentToCollection} from "../utils/firebaseConfigAndFunctions";
import {busyInTripsService} from "../utils/busyInTripsService";
import LocationIq from "./LocationIq";
import {
  getPointForDbFromLocationIqPlace,
  getTranslatedLocalityName, getTripPointWithTranslations
} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";

const AddTripForDriverOrVehicle = ({
  addClasses = '',
  vehicle,
  driver,
  setDriverScreens = () => {},
  setActiveTabKey = () => {},
  setTrips = () => {},
}) => {
  //#region Get language from context, manage languages
  const {lang, languages} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Data variables for trip
  const [newTripData, setNewTripData] = useState(vehicleTripModel);
  //#endregion

  //#region Handle trip data update
  const changePlaceInputHandler = (newPlace, index) => {
    if (index === 0) {
      setNewTripData(tripData => ({
        ...tripData,
        startPoint: getPointForDbFromLocationIqPlace(newPlace, lang),
      }));
    } else {
      setNewTripData(tripData => ({
        ...tripData,
        endPoint: getPointForDbFromLocationIqPlace(newPlace, lang),
      }));
    }
  }

  const changeDateInputHandler = (fieldName, newDate) => {
    const chosenDate = new Date(newDate).getTime();

    setNewTripData(tripData => ({
      ...tripData,
      [fieldName]: chosenDate,
    }));
  }
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Save trip
  const saveTrip = async () => {
    setIsLoading(true);

    try {
      if (!checkVehicleOrDriverTripCompleteness(newTripData)) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Заполните все поля');

        setIsLoading(false);

        return;
      }

      const today = new Date().setHours(0, 0, 0, 0);

      if (today > newTripData.startDate) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Нельзя создать поездку с прошедшей датой');

        setIsLoading(false);

        return;
      }

      if (newTripData.startDate > newTripData.endDate) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Дата прибытия не может быть раньше даты' +
          ' отправления');

        setIsLoading(false);

        return;
      }

      const collectionName = driver ? collectionsInterface.driversTrips
        : collectionsInterface.vehiclesTrips;

      const id = driver ? driver.idPost : vehicle.idPost;
      const idField = driver ? 'driverId' : 'vehicleId';

      const isAlreadyBusy = await busyInTripsService.checkIsAlreadyBusyInTrip(
        collectionName, newTripData.startDate, newTripData.endDate, idField, id);

      if (isAlreadyBusy) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Поездка персекается с другой поездкой.' +
          ' Измените даты');

        setIsLoading(false);

        return;
      }

      const translatedStartPoint = await getTripPointWithTranslations(
        newTripData.startPoint, lang, languages.map(lan => lan.name));

      const translatedEndPoint = await getTripPointWithTranslations(
        newTripData.endPoint, lang, languages.map(lan => lan.name));

      newTripData.startPoint = translatedStartPoint;
      newTripData.endPoint = translatedEndPoint;

      const saveResult = await setDocumentToCollection(collectionName, {
        ...newTripData,
        [idField]: id,
      });

      if (driver) {
        setDriverScreens({
          isAddTripScreen: false,
          isDriverScreen: false,
          isTripsScreen: true,
        });
      } else {
        setTrips(trips => [...trips, {
          ...newTripData,
          [idField]: id,
          idPost: saveResult.result.id,
        }]);

        setActiveTabKey('trips');
      }

    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`AddTrip ${addClasses}`}>
          <div className={'PaddingBlock PaddingBlock_Half'}></div>

          {driver &&
            <DisplayUserData
              isDriver={true}
              isFullInfo={false}
              userId={driver.idPost}
              addClasses={'RegularTitle RegularTitle_Tiny'}
              isFavouriteIconVisible={false}
              isCentered={true}
              areContactsVisible={true}
            />
          }

          {vehicle &&
            <DisplayVehicleData
              vehicle={vehicle}
              isFullInfo={false}
              addClasses={'RegularTitle RegularTitle_Tiny'}
            />
          }

          <div className={'PaddingBlock PaddingBlock_Half'}></div>
          <h2 className={'DataLabel DataLabel_Centered'}>
            {driver &&
              translate('Уточните в каких промежутках дат будет занят' +
                ' водитель в поездке. Обратите внимание, в указанный вами' +
                ' промежуток в дальнейшем нельзя будет создать поездку')
            }

            {vehicle &&
              translate('Уточните в каких промежутках дат будет занят' +
                ' автомобиль в поездке. Обратите внимание, в указанный вами' +
                ' промежуток в дальнейшем нельзя будет создать поездку')
            }
          </h2>

          <div
            className={'TripPoint'}
          >
            <div className={'TripPoint-Item'}>
              <label
                className={'TripPoint-Label'}
              >
                {translate('Пункт отправления')}
              </label>

              <LocationIq
                externalAction={changePlaceInputHandler}
                index={0}
                receivedAddress={getTranslatedLocalityName(newTripData
                  ?.startPoint?.fullAddress, lang)}
              />

              <div className={'PaddingBlock PaddingBlock_Half'}></div>

              <label
                className={'TripPoint-Label'}
              >
                {translate('Пункт прибытия')}
              </label>

              <LocationIq
                externalAction={changePlaceInputHandler}
                index={1}
                receivedAddress={getTranslatedLocalityName(newTripData
                  ?.endPoint?.fullAddress, lang)}
              />

              <div className={'PaddingBlock PaddingBlock_Half'}></div>

              <div className={'FlexBlock'}>
                <div
                  className={'FlexBlock-HorizontalItem' +
                    ' FlexBlock-HorizontalItem_W45'}
                >
                  <label
                    className={'TripPoint-Label'}
                  >
                    {translate('Дата отправления')}
                  </label>

                  <input
                    type={'date'}
                    className={'FormControl'}
                    onChange={(event) => changeDateInputHandler('startDate',
                      event.target.value)}
                    value={convertTimestampToCalendarDate(
                      newTripData?.startDate)}
                    required
                  />
                </div>

                <div
                  className={'FlexBlock-HorizontalItem' +
                    ' FlexBlock-HorizontalItem_W45'}
                >
                  <label
                    className={'TripPoint-Label'}
                  >
                    {translate('Дата прибытия')}
                  </label>

                  <input
                    type={'date'}
                    className={'FormControl'}
                    onChange={(event) => changeDateInputHandler('endDate',
                      event.target.value)}
                    value={convertTimestampToCalendarDate(
                      newTripData?.endDate)}
                    required
                  />
                </div>
              </div>

              <div className={'PaddingBlock PaddingBlock_Half'}></div>

              <button
                type={'button'}
                onClick={() => saveTrip()}
                className={'RegularButton'}
              >
                {translate('Сохранить')}
              </button>
            </div>
          </div>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default AddTripForDriverOrVehicle;
