import React, {useContext, useEffect, useState} from 'react';
import {handleTripClick, handleUserClick} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import {collectionsInterface, tripModel, tripOrderModel} from "../utils/models";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import DisplayTripPointsData from "./DisplayTripPointsData";
import {useNavigate} from "react-router-dom";
import useTranslation from "../hooks/useTranslation";

const TestimonialDetails = ({
  addClasses = '',
  isAdmin = false
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
      setScreenTitle('Отзыв');
  }, []);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    setCurrentTripOrder,
    setCurrentTrip,
    setCurrentReservation,
  } = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get current currentTestimonial from context
  const {currentTestimonial} = useContext(AppContext);
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTestimonial?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Get trip and trip order data
  const [tripOrder, setTripOrder] = useState(tripOrderModel);
  const [trip, setTrip] = useState(tripModel);

  const getTripDataForTestimonial = async (currentTestimonial) => {
    try {
      const actualTripOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, currentTestimonial.dealId);
      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        currentTestimonial.tripId);

      setTripOrder(actualTripOrder);
      setTrip(actualTrip);
    } catch (error) {
      return error;
    }}

  useEffect(() => {
    getTripDataForTestimonial(currentTestimonial)
      .catch(() => {});
  }, []);
  //#endregion

  //#region Render
  return (
    <div className={`TestimonialDetails ${addClasses}`}>
      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      {isAdmin &&
        <div className={'CreateTripOrder-Item'}>
          <div className={'FlexBlock'}>
            <label className={'DataLabel'}>
              {translate('Id')}
            </label>

            {currentTestimonial?.idPost}
          </div>
        </div>
      }

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Направление поездки')}
          </label>

          <div
            onClick={() => handleTripClick(tripOrder, trip,
              setCurrentReservation, setCurrentTripOrder, setCurrentTrip,
              navigate)}
          >
            <DisplayTripPointsData
              addClasses={'UserLink'}
              tripPoints={[tripOrder?.startPoint, tripOrder?.endPoint]}
            />
          </div>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Кому')}
          </label>

          <span
            className={'UserLink'}
            onClick={() => handleUserClick(currentTestimonial
              .evaluatedPersonId, setEditingUser, navigate)}
          >
            {currentTestimonial?.evaluatedPersonFullName}
          </span>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('От кого')}
          </label>

          <span
            className={'UserLink'}
            onClick={() => handleUserClick(currentTestimonial?.author?.idPost,
              setEditingUser, navigate)}
          >
            {currentTestimonial?.author?.fullName}
          </span>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <div className={'FlexBlock'}>
          <label className={'DataLabel'}>
            {translate('Оценка')}
          </label>

          <span>
            {currentTestimonial.mark}
          </span>
        </div>
      </div>

      <div className={'CreateTripOrder-Item'}>
        <label className={'DataLabel'}>
          {translate('Комментарий к отзыву')}
        </label>

        <div>
          {currentTestimonial.text}
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default TestimonialDetails;
