import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Modal} from "react-bootstrap";
import {handleChangeTripStatus} from "../utils/helpers";
import {
  collectionsInterface,
  tripModel,
  tripStatusesInterface
} from "../utils/models";
import {useNavigate} from "react-router-dom";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import Loader from "../components/Loader";
import {checkTripService} from "../utils/checkTripService";
import ModalNotification from "./ModalNotification";
import useTranslation from "../hooks/useTranslation";

const ModalChangeTripStatus = ({
  addClasses = '',
  showModal = false,
  setShowModal = true,
  isLoading = false,
  setIsLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get trip in progress trigger from context
  const {setHasTripBecomeInProgress} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip, setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Status value and its change
  const [currentStatus, setCurrentStatus] = useState(
    tripStatusesInterface.departuring);

  useEffect(() => {
    switch (currentTrip.status) {
      case tripStatusesInterface.departuring:
        setCurrentStatus(tripStatusesInterface.inProgress);
        break;
      case tripStatusesInterface.inProgress:
        setCurrentStatus(tripStatusesInterface.completed);
        break;
    }
  }, []);
  //#endregion

  //#region Check if can cancel the trip
  const checkIfCanCancelTrip = () => {
    return (!user.attachedToCompany && !currentTrip.isInProgress) &&
    !checkTripService.checkIsTripShouldAlreadyBeenStarted(
      currentTrip.startDate);
  }
  //#endregion

  //#region Handle save status click
  const handleSaveStatusClick = async (user, trip, status) => {
    setIsLoading(true);

    try {
      if (!status) {
        return;
      }

      if (status === tripStatusesInterface.canceled) {
        const isAlreadyStarted = checkTripService
          .checkIsTripShouldAlreadyBeenStarted(currentTrip.startDate);

        if (isAlreadyStarted) {
          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Поездку уже нельзя отменить')

          return;
        }

        return;
      }

      await handleChangeTripStatus(user, trip, status);
      setShowModal(false);

      if (status === tripStatusesInterface.inProgress) {
        setHasTripBecomeInProgress(true);
      }

      const updatedUser = await getDocInCollection(collectionsInterface.users,
        user.idPost);
      setUser(updatedUser);
      setCurrentTrip(tripModel);

      navigate('/trips');
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      <Modal
        show={showModal}
        fullscreen='true'
        onHide={() => {
          setShowModal(false);
        }}
        className={`ModalChangeTripStatus ${addClasses}`}
      >
        {isLoading ? <Loader/>
          :
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <h2 className={'RegularTitle RegularTitle_Small'}>
                  {translate('Изменение статуса поездки')}
                </h2>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <fieldset>
                {(!user.attachedToCompany && currentTrip.status ===
                    tripStatusesInterface.openToSearch) &&
                  <label
                    htmlFor={tripStatusesInterface.departuring}
                    className={'RadioStatusesLabel'}
                  >
                    <span className={'RadioStatusesLabel_Status'}>
                        {currentStatus === tripStatusesInterface.departuring &&
                          <span
                            className={'RadioStatusesLabel_StatusInner'}
                          ></span>
                        }
                    </span>

                    <span className={'RadioStatusesLabel_Text'}>
                      {translate('Выезжает')}
                    </span>

                    <input
                      className={'RadioStatusesInput'}
                      type={'radio'}
                      value={tripStatusesInterface.departuring}
                      id={tripStatusesInterface.departuring}
                      checked={currentStatus === tripStatusesInterface
                        .departuring}
                      onChange={() => setCurrentStatus(tripStatusesInterface
                        .departuring)}
                    />
                  </label>
                }

                {(currentTrip.status === tripStatusesInterface.departuring) &&
                  <label
                    htmlFor={tripStatusesInterface.inProgress}
                    className={'RadioStatusesLabel'}
                  >
                    <span className={'RadioStatusesLabel_Status'}>
                      {currentStatus === tripStatusesInterface.inProgress &&
                        <span
                          className={'RadioStatusesLabel_StatusInner'}
                        ></span>
                      }
                    </span>

                    <span className={'RadioStatusesLabel_Text'}>
                      {translate('В пути')}
                    </span>

                    <input
                      className={'RadioStatusesInput'}
                      type={'radio'}
                      value={tripStatusesInterface.inProgress}
                      id={tripStatusesInterface.inProgress}
                      checked={currentStatus === tripStatusesInterface
                        .inProgress}
                      onChange={() => setCurrentStatus(
                        tripStatusesInterface.inProgress)}
                    />
                  </label>
                }

                {(currentTrip.status === tripStatusesInterface.inProgress) &&
                  <label
                    htmlFor={tripStatusesInterface.completed}
                    className={'RadioStatusesLabel'}
                  >
                    <span
                      className={'RadioStatusesLabel_Status'}>
                        {currentStatus === tripStatusesInterface.completed &&
                          <span
                            className={'RadioStatusesLabel_StatusInner'}
                          ></span>
                        }
                    </span>

                    <span className={'RadioStatusesLabel_Text'}>
                      {translate('Завершена')}
                    </span>

                    <input
                      className={'RadioStatusesInput'}
                      type={'radio'}
                      value={tripStatusesInterface.completed}
                      id={tripStatusesInterface.completed}
                      checked={
                        currentStatus === tripStatusesInterface.completed
                      }
                      onChange={() => setCurrentStatus(
                        tripStatusesInterface.completed)}
                    />
                  </label>
                }

                {checkIfCanCancelTrip() &&
                  <label
                    htmlFor={tripStatusesInterface.canceled}
                    className={'RadioStatusesLabel'}
                  >
                    <span className={'RadioStatusesLabel_Status'}>
                        {currentStatus === tripStatusesInterface.canceled &&
                          <span
                            className={'RadioStatusesLabel_StatusInner'}
                          ></span>
                        }
                    </span>

                    <span className={'RadioStatusesLabel_Text'}>
                      {translate('Отменена')}
                    </span>

                    <input
                      className={'RadioStatusesInput'}
                      type={'radio'}
                      value={tripStatusesInterface.canceled}
                      id={tripStatusesInterface.canceled}
                      checked={
                        currentStatus === tripStatusesInterface.canceled
                      }
                      onChange={() => setCurrentStatus(
                        tripStatusesInterface.canceled)}
                    />
                  </label>
                }
              </fieldset>
            </Modal.Body>

            <Modal.Footer>
              <div className={'FlexBlock ModalAddDriverAddress-Item'}>
                <button
                  type={'button'}
                  className={'RegularButton FlexBlock-HorizontalItem ' +
                    'FlexBlock-HorizontalItem_W60'}
                  onClick={() => handleSaveStatusClick(user, currentTrip,
                    currentStatus)}
                >
                  {translate('Сохранить')}
                </button>

                <button
                  type={'button'}
                  className={'RegularButton RegularButton_Contrast ' +
                    'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  {translate('Вернуться')}
                </button>
              </div>
            </Modal.Footer>
          </>
        }
      </Modal>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default ModalChangeTripStatus;
