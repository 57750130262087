import {
  getDocInCollection,
  updateFieldsInDocumentInCollection
} from "./firebaseConfigAndFunctions";
import {collectionsInterface} from "./models";

const getFieldsToMakeTripOrderActual = () => {
  return {
    cancelComment: '',
    dateEditing: Date.now(),
    hasAnyReservations: false,
    hasReservationsFromDispatchers: false,
    hasReservationsFromDrivers: false,
    isArchived: false,
    isCancelled: false,
    isOpenForSearch: true,
    observedTrips: [],
    reservationsFromDispatchers: [],
    reservationsFromDrivers: [],
  }
}

const getTripOrderToActivate = async (tripOrderId) => {
  const actualTripOrder = await getDocInCollection(collectionsInterface
    .tripsOrders, tripOrderId);

  const modifiedFields = getFieldsToMakeTripOrderActual();

  await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
    tripOrderId, modifiedFields)

  return {
    ...actualTripOrder,
    ...modifiedFields,
  };
}

export const tripOrderService = {
  getTripOrderToActivate,
}
