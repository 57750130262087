import React, {useEffect, useState, useContext} from 'react';
import {
  balanceAccountModel,
  collectionsInterface,
  transactionsTypesInterface,
  userModel
} from "../utils/models";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import ModalAddTransaction from "../modals/ModalAddTransaction";
import {
  getClassesForTransactionAmount,
  getSignForTransaction,
  getTextForTransaction,
  getTransactionsArrayFromObject,
  handleTripClickAndGetActualData,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import '../styles/UserTransactionsForAdmin.scss';
import ReactTable from "react-table-v6";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";

const UserTransactionsForAdmin = ({
  addClasses = '',
  user = userModel,
  }) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    setCurrentTripOrder,
    setCurrentTrip,
    setCurrentReservation
  } = useContext(AppContext);
  //#endregion

  //#region Add transaction
  const [isAddTransaction, setIsAddTransaction] = useState(false);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Transactions data
  const [balanceAccount, setBalanceAccount] = useState(balanceAccountModel);

  useEffect(() => {
    if (user.idPost) {
      getDocInCollection(collectionsInterface.balanceAccounts,
        user.balanceAccount).then(account => {
            setBalanceAccount(account);
          }
        ).catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, []);
  //#endregion

  //#region Data for React table
  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'date',
      Header: translate('Дата'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.date === '' ?
          translate('Не указана')
          :
          showFormattedToLanguageDate(lang, e.date)
        }
      </div>
    },
    {
      id: 'trip',
      Header: 'Поездка',
      accessor: e => <div
        className='UsersTable-Cell UserLink'
        onClick={() => handleTripClickAndGetActualData(e.dealId,
          setCurrentReservation, setCurrentTripOrder, setCurrentTrip, navigate)}
      >
        {e.type === '' ? ''
          : (e.type === transactionsTypesInterface.dispatcherFee
          || e.type === transactionsTypesInterface.exchange
            ? e.dealId : '')
        }
      </div>
    },
    {
      id: 'type',
      Header: translate('Тип'),
      accessor: e => <div
        className={'UsersTable-Cell'}
      >
        {e.type ?
          translate(getTextForTransaction(e.type,
            e.recipient.idPost === user.idPost))
          :
          translate('Не указан')
        }
      </div>
    },
    {
      id: 'amount',
      Header: translate('Сумма'),
      accessor: e => <div
        className={`UsersTable-Cell UsersTable-Cell_Centered ${
          getClassesForTransactionAmount(e.type, e.recipient.idPost === 
            user.idPost)}`}
      >
        {(e.amount ?
          `${getSignForTransaction(e.type, e.recipient.idPost === 
            user.idPost)}${e.amount}`
          :
          translate('Не указана'))
        }
      </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      {isLoading ? <Loader />
        :
        <div className={`UserTransactionsForAdmin ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <div
            className={'DataLabel DataLabel_Centered'}
          >
            {user?.fullName}
          </div>

          <div className={'DataLabel DataLabel_Centered'}>
            {`${translate('Сумма на счету')}: ${balanceAccount?.amount} ${translate(
              'грн')}.`}
          </div>

          <button
            type={'button'}
            onClick={() => setIsAddTransaction(true)}
            className={
              'RegularButton UserTransactionsForAdmin_AddTransactionButton'
            }
          >
            {translate('Добавить транзакцию')}
          </button>

          <div className={'PaddingBlock'}></div>

          <ReactTable
            data={getTransactionsArrayFromObject(balanceAccount?.transactions)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            SubComponent={(row) => (
              <div className={'SmallFont'}>
                {row?.original?.text || ''}
              </div>
            )}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}
          />
        </div>
      }

      {isAddTransaction &&
        <ModalAddTransaction
          showModal={isAddTransaction}
          setShowModal={setIsAddTransaction}
          editingUser={user}
          setBalanceAccount={setBalanceAccount}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default UserTransactionsForAdmin;
