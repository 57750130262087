import React, {useContext, useEffect, useState} from 'react';
import {
  getPassengerRouteInTrip,
  sendMessageAboutAddingToTripAsDriver, sendMessageAboutDeletingFromTripAsDriver
} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import {Route, Routes, useNavigate} from "react-router-dom";
import TripsListForAdmin from "./TripsListForAdmin";
import {
  getCollectionWhereAnyOfKeysValues,
  getCollectionWhereKeysValues, getDocInCollection,
  updateFieldsInDocumentInCollection,
} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, pagesInterface} from "../utils/models";
import ActiveTripsOrdersListForAdmin from "./ActiveTripsOrdersListForAdmin";
import AddTrip from "./AddTrip";
import ReservationDetails from "./ReservationDetails";
import RouteOnMap from "./RouteOnMap";
import {checkTripService} from "../utils/checkTripService";
import {
  handleOriginalTripInReservationsAfterEditing,
  rejectAllUnsuitableReservationsInTripAfterChange
} from "../utils/reservationsService";
import useTranslation from "../hooks/useTranslation";
import RouteOnMapLocationIq from "./RouteOnMapLocationIq";

const DisplayTripsForAdmin = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

    //#region Get user from context
    const {setScreenTitle} = useContext(AppContext);
    //#endregion

    //#region Get current trip from context
    const {currentTripOrder, currentTrip} = useContext(AppContext);
    //#endregion

    //#region Get editing user from context
    const {editingUser} = useContext(AppContext);
    //#endregion

    //#region Set title
    useEffect(() => {
        setScreenTitle('Предложения');
    }, []);
    //#endregion

    //#region Get navigation from router
    const navigate = useNavigate();
    //#endregion

  //#region Loaders
  const [loaders, setLoaders] = useState({
    tripsOffers: true,
    activeTrips: true,
  })
  //#endregion

  //#region Trips data and it's receiving
  const [tripsOffers, setTripsOffers] = useState([]);
  const [activeTrips, setActiveTrips] = useState([]);

  useEffect (() => {
    getCollectionWhereKeysValues(collectionsInterface.trips, [
      {
          key: 'isActive',
          value: false,
      },
      {
          key: 'isCancelled',
          value: false,
      },
      {
          key: 'isCompleted',
          value: false,
      },
      {
          key: 'isVisibleForAdmin',
          value: true,
      },
    ], 4).then(trips => setTripsOffers(trips))
      .catch(() => {})
      .finally(() => setLoaders(state => ({
        ...state,
        tripsOffers: false,
      })));
  }, []);
  //#endregion

  //#region Handle screens toggle
  const [areActive, setAreActive] = useState(false);

  const toggleScreens = async (toActive) => {
      setAreActive(toActive);

      try {
        if (toActive) {
          navigate(`/${pagesInterface.tripsAdmin}/activeTrips`);

          if (activeTrips.length === 0) {
            setLoaders(state => ({
              ...state,
              activeTrips: true,
            }));

            const suitableTripsOrders = await getCollectionWhereAnyOfKeysValues(
              collectionsInterface.tripsOrders,
              [
                {
                  key: 'isActive',
                  value: true,
                },
                {
                  key: 'isCancelled',
                  value: true,
                },
                {
                  key: 'isCompleted',
                  value: true,
                },
              ], 3);

            setActiveTrips(suitableTripsOrders.filter(tripOrder => tripOrder
              ?.isVisibleForAdmin && tripOrder?.acceptedDeal?.id));
          }
        } else {
          navigate(`/${pagesInterface.tripsAdmin}`);
        }
      } catch (error) {
        return error;
      } finally {
        setLoaders(state => ({
          ...state,
          activeTrips: false,
        }));
      }
  }
  //#endregion

  //#region Handle saving changes in trip
  const saveTripData = async (fieldsToUpdate, isEdit, driversChanges) => {
    try {
      await updateFieldsInDocumentInCollection(collectionsInterface.trips,
        fieldsToUpdate.idPost, fieldsToUpdate);

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        currentTrip.idPost);

      let updatedTrip = actualTrip;

      const needPetOptionCheck = checkTripService
        .checkAreReservationsNeedPetOptionCheck(currentTrip
          .isTripWithPets, updatedTrip.isTripWithPets);

      const needByFootOptionCheck = checkTripService
        .checkAreReservationsNeedByFootOptionCheck(currentTrip.isByFoot,
          updatedTrip.isByFoot);

      const tripCreator = await getDocInCollection(collectionsInterface.users,
        actualTrip.creatorIdPost);

      if (fieldsToUpdate.hasOwnProperty('tripPoints')
        || needPetOptionCheck || needByFootOptionCheck) {
        const actionResult = await rejectAllUnsuitableReservationsInTripAfterChange(
          actualTrip.idPost, fieldsToUpdate.tripPoints, actualTrip
            .reservationsFromDispatchers, actualTrip.reservationsFromDrivers,
          tripCreator?.companyName || tripCreator?.fullName,
          needPetOptionCheck, needByFootOptionCheck);

        if (actionResult?.wasUpdated) {
          updatedTrip = {
            ...actualTrip,
            ...actionResult?.fieldsToUpdate,
          }
        }
      }

      await handleOriginalTripInReservationsAfterEditing(updatedTrip);

      setTripsOffers(offers => offers
        .map(offer => offer.idPost === fieldsToUpdate.idPost ? updatedTrip : offer));

      if (driversChanges  && driversChanges.wereDriversChanged) {
        const tripDataForMessage = {
          startPoint: updatedTrip.startPoint,
          endPoint: updatedTrip.endPoint,
          startDate: updatedTrip.startDate,
          driversNames: updatedTrip.drivers.map(driver => driver.fullName),
        }

        driversChanges?.added?.forEach(driver => {
          if (driver.idPost !== tripCreator.idPost) {
            sendMessageAboutAddingToTripAsDriver(tripCreator?.companyName
              || tripCreator.fullName,  tripDataForMessage, {
              idPost: driver.idPost,
              email: driver.email,
            });
          }
        });

        driversChanges?.dismissed?.forEach(driver => {
          if (driver.idPost !== tripCreator.idPost) {
            sendMessageAboutDeletingFromTripAsDriver(tripCreator?.companyName
              || tripCreator?.fullName,  tripDataForMessage,{
              idPost: driver.idPost,
              email: driver.email,
            });
          }
        });
      }

      navigate(-1);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Handle route on map click
  const [route, setRoute] = useState([]);

  const handleRouteOnMapClick = () => {
    setRoute(getPassengerRouteInTrip(currentTripOrder, currentTrip));

    navigate(`/${pagesInterface.tripsAdmin}/routeOnMap`);
  }
  //#endregion

  //#region Render
  return (
    <div className={`DisplayTripsForAdmin ${addClasses}`}>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <div className={'TitleWithButton mb-2'}>
        <div className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {areActive ? translate('Активные') : translate('Предложения')}
        </div>
        <div
          className={'ToggleButton'}
          onClick={() => toggleScreens(!areActive)}
        >
          {areActive ? translate('Предложения') : translate('Активные')}
        </div>
      </div>

      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <Routes>
        <Route
          index
          element={
            <TripsListForAdmin
              trips={tripsOffers}
              setTrips={setTripsOffers}
              isLoading={loaders.tripsOffers}
            />
          }
        />

        <Route
          path={'activeTrips'}
          element={
            <ActiveTripsOrdersListForAdmin
              tripsOrders={activeTrips}
              setTripsOrders={setActiveTrips}
              isLoading={loaders.activeTrips}
            />
          }
        />

        <Route
          path={'tripEditing'}
          element={
            <AddTrip
              isNotePad={currentTrip.isDraft}
              isAdmin={true}
              isCreator={true}
              addClasses={'App-Screen_Narrow'}
              user={editingUser}
              completeExternalSubmitAddTripActions={saveTripData}
            />
          }
        />

        <Route
          path={'activeTripDetails'}
          element={
            <ReservationDetails
              isAdmin={true}
              addClasses={'App-Screen_Narrow'}
              handleRouteOnMapClick={handleRouteOnMapClick}
            />
          }
        />

        <Route
          path={'routeOnMap'}
          element={
            <RouteOnMap
              route={route}
            />
            // <RouteOnMapLocationIq route={route} />
          }
        />
      </Routes>
    </div>
  );
  //#endregion
};

export default DisplayTripsForAdmin;
