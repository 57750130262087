import React, {useEffect, useState} from 'react';
import TripCardForVehicleOrDriver from "./TripCardForVehicleOrDriver";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";

const ShowDriverOrVehicleTrip = ({
  addClasses = '',
  trips = [],
  cardsNumber = 3,
  isReducedDisplaying = false,
  deleteTrip = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsChunk, setTripsChunk] = useState([]);

  useEffect(() => {
    if (trips.length > 0) {
      setTripsChunk(trips
        .slice((currentPage - 1) * cardsNumber,
          currentPage * cardsNumber));
    } else {
      setTripsChunk([]);
    }
  }, [trips.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <div
      className={trips?.length > 0
        ? `CardsListScreen CardsListScreen_Reduced ${addClasses}`
        : 'CardsListScreen CardsListScreen_Reduced CardsListScreen_Empty'}
    >
      <div className={'PaddingBlock'}></div>

      {trips?.length > 0 ?
        <></>
        :
        <p className={'CardsListScreen-NoEntriesText'}>
          {translate('Нет поездок')}
        </p>
      }

      <div className={'DataListWithPagination'}>
        <div
          className={isReducedDisplaying ? 'ListContainer ' +
            'ListContainer_SharplyReduced' : 'ListContainer'}
        >
          <div className={'CardsList'}>
            {tripsChunk.map((trip, index) => (
              <React.Fragment key={trip.idPost}>
                <TripCardForVehicleOrDriver
                  trip={trip}
                  deleteTrip={deleteTrip}
                />
              </React.Fragment>
            ))}
          </div>
        </div>

        {trips.length > cardsNumber &&
          <PaginationBlock
            portionSize={cardsNumber}
            listLength={trips.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            startPage={startPage}
            setStartPage={setStartPage}
          />
        }
      </div>
    </div>
  );
  //#endregion
};

export default ShowDriverOrVehicleTrip;
