import React, {useEffect, useState} from 'react';
import DisplayTripCard from "./DisplayTripCard";
import {entitiesNumberInterface} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";

const DisplayDriverTrips = ({
  driverTrips,
  handleShowTripDetailsScreen = () => {},
  addClasses = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsChunk, setTripsChunk] = useState([]);

  useEffect(() => {
    setTripsChunk(driverTrips
      .slice((currentPage - 1) * entitiesNumberInterface.tripsCards,
        currentPage * entitiesNumberInterface.tripsCards));
  }, [driverTrips.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`DisplayDriverTrips ${addClasses}`}>
        <div className={driverTrips?.length > 0 ? 'CardsListScreen'
          : 'CardsListScreen CardsListScreen_Empty'}>
          {driverTrips?.length > 0 ?
            <></>
            :
            <p className={'CardsListScreen-NoEntriesText'}>
              {translate('Нет поездок')}
            </p>
          }

          <div className={'DataListWithPagination'}>
            <div className={'ListContainer'}>
              <div className={'CardsList'}>
                {tripsChunk?.map((trip, index) => (
                  <React.Fragment key={trip.idPost}>
                    <DisplayTripCard
                      trip={trip}
                      handleShowTripDetailsScreen={handleShowTripDetailsScreen}
                      isDispatcher={true}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>

            {driverTrips.length > entitiesNumberInterface.tripsCards &&
              <PaginationBlock
                portionSize={entitiesNumberInterface.tripsCards}
                listLength={driverTrips.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
  //#endRegion
};

export default DisplayDriverTrips;
