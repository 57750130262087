import React from 'react';
import {Modal} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";

const ModalNotification = ({
  title = '',
  showModal,
  message = '',
  addData = '',
  setShowModal = () => {},
  setAddData = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setAddData('');
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {translate(title)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          {translate(message)}

          {addData}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className={'FlexBlock'}>
          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast'}
            onClick={() => {
              setShowModal(false);
              setAddData('');
            }}
          >
            {translate('Вернуться')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalNotification;
