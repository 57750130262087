import React, {useEffect, useContext, useState} from 'react';
import {
  deleteTestimonialHandler,
  handleUserClick
} from "../utils/helpers";
import {getCollectionWhereKeyValue} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, pagesInterface} from "../utils/models";
import {MdOutlineSlideshow} from "react-icons/md";
import {FaTrash} from "react-icons/fa";
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import ReactTable from "react-table-v6";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";

const TestimonialsListForAdmin = ({
  addClasses = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Отзывы');
  }, []);
  //#endregion

  //#region Get current testimonial from context
  const {setCurrentTestimonial} = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Data for React table
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    getCollectionWhereKeyValue(collectionsInterface.testimonials,
      'isVisibleForAdmin', true)
      .then(result => setTestimonials(result))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  //#region Search and filters
  const marksRange = [1, 2, 3, 4, 5];
  const [searchText, setSearchText] = useState('');
  const [filtersData, setFiltersData] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');

  const searchUpdate = (text) => {
    setSearchText(text);
  }

  const filterUpdate = (fieldName, value) => {
    setFiltersData(data => ({
      ...data,
      [fieldName]: value,
    }));
  }

  const applyFilter = (fieldName, value) => {
    setAppliedFilters(data => ({
      ...data,
      [fieldName]: value,
    }));
  }

  const clearFilter = (fieldName) => {
    setAppliedFilters(data => ({
      ...data,
      [fieldName]: '',
    }));
    setFiltersData(data => ({
      ...data,
      [fieldName]: '',
    }));
  }
  //#endregion

  const getTestimonialsToDisplay = (searchText, filtersData, testimonials) => {
    let searchResult = [];

    if (searchText) {
      searchResult = testimonials.filter(testimonial => {
        let res = false;

        if (testimonial.hasOwnProperty('evaluatedPersonFullName')) {
          if (testimonial.evaluatedPersonFullName.toUpperCase()
            .includes(searchText.toUpperCase())) {
            res = true;
          }
        }

        return res;
      });
    } else {
      searchResult = testimonials;
    }

    let result = [];

    if (filtersData) {
      if (Object.values(filtersData).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(testimonial => {
          let res = true;

          if (filtersData.mark) {
            if (Number(filtersData.mark) !== Number(testimonial.mark)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  }

  const handleTestimonialClick = async (testimonial) => {
    try {
      setCurrentTestimonial(testimonial);

      navigate(`/${pagesInterface.testimonialsAdmin}/testimonialDetails`);
    } catch (error) {
      return error;
    }
  }

  const columns = [
    {
      id: 'id',
      maxWidth: 250,
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'userName',
      Header: translate('ФИО пользователя'),
      accessor: e => <div
        className='UsersTable-Cell UserLink'
        onClick={() => handleUserClick(e.evaluatedPersonId,
          setEditingUser, navigate)}
      >
        {e.evaluatedPersonFullName || translate('Не указаны')}
      </div>
    },
    {
      id: 'mark',
      width: 80,
      Header: translate('Оценка'),
      accessor: e =>
        <div className='UsersTable-Cell UsersTable-Cell_Centered'>
          {e.mark || 0}
        </div>
    },
    {
      id: 'editAndDelete',
      Header: '',
      width: 80,
      accessor: e =>
        <div
          className='UsersTable-Cell'
        >{
          <div
            className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}
          >
            <button
              type={'button'}
              className={'ShowButton'}
              onClick={() => handleTestimonialClick(e)}
            >
              <MdOutlineSlideshow/>
            </button>

            <button
              type={'button'}
              className={'DelButton'}
              onClick={() => {
                setIsLoading(true);

                deleteTestimonialHandler(e, setTestimonials)
                  .finally(() => setIsLoading(false))
              }}
            >
              <FaTrash/>
            </button>
          </div>}
        </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`TestimonialsListForAdmin ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'FlexBlock mb-2'}>
            <input
              value={searchText}
              type="text"
              placeholder={translate('Поиск')}
              onChange={(e) => searchUpdate(e.target.value)}
              className={'FormControl FlexBlock-HorizontalItem_W50'}
            />

            <Dropdown className={'FlexBlock-HorizontalItem_W40'}>
              <Dropdown.Toggle
                className={'FormControl DropdownControl'}
                id="dropdown-basic"
              >
                {filtersData?.mark || translate('Оценка')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {marksRange.map(mark =>
                  <React.Fragment key={mark}>
                    <Dropdown.Item
                      onClick={() => {
                        filterUpdate('mark', mark);
                        applyFilter('mark', mark);
                      }}
                    >
                  <span>
                    {mark}
                  </span>
                    </Dropdown.Item>
                  </React.Fragment>
                )}

                <Dropdown.Item
                  onClick={() => {
                    filterUpdate('mark', 'remove');
                    clearFilter('mark');
                  }}
                >
              <span>
                {translate('Сбросить')}
              </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <ReactTable
            data={getTestimonialsToDisplay(searchText, appliedFilters,
              testimonials)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}
          />

          <div className={'PaddingBlock'}></div>
        </div>
      }
    </>
  );
  //#endregion
};

export default TestimonialsListForAdmin;
