import React, {useContext} from 'react';
import {Tab} from "react-bootstrap";
import {myTripsTypes} from "../utils/models";
import DisplayCreatedTrips from "../components/DisplayCreatedTrips";
import {AppContext} from "../context/appContext";
import {queryArraysService} from "../utils/queryArraysService";
import useTripsList from "./useTripsList";

const MyTripsTab = ({
  tripType = myTripsTypes.actual,
  handleShowCreateTrip = () => {},
  handleShowTripDetails = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get query array
  const creatorId = user.attachedToCompany || user.idPost;

  const queryArray = tripType === myTripsTypes.actual
    ? queryArraysService.myTripsActual(creatorId)
    : queryArraysService.myTripsArchived(creatorId)
  //#endregion

  //#region Trips list, loader
  const {isLoading, tripsList} = useTripsList(queryArray, user.idPost,
    user.attachedToCompany);
  //#endregion

  //#region Render
  return (
    <Tab.Pane eventKey={tripType}>
      <DisplayCreatedTrips
        handleShowCreateTrip={handleShowCreateTrip}
        handleShowTripDetailsScreen={handleShowTripDetails}
        trips={tripsList}
        isReadMode={false}
        addClasses={'App-Screen'}
        isExtendedDisplaying={false}
        isNotepad={false}
        isLoading={isLoading}
      />
    </Tab.Pane>
  );
  //#endregion
};

export default MyTripsTab;
