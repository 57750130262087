import React from 'react';
import CopyToBufferButton from "./CopyToBufferButton";
import '../styles/ReferralLink.scss';

const ReferralLink = ({addClasses = '', text = ''}) => {
  //#region Render
  return (
    <div className={`ReferralLink ${addClasses}`}>
      <span className={'ReferralLink-Text'}>
        {text}
      </span>

      <CopyToBufferButton textToCopy={text} />
    </div>
  );
  //#endregion
};

export default ReferralLink;
