import React, {useEffect, useState, useContext} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {sendMessageToUser} from "../utils/helpers";
import {getCollectionWhereKeyValue} from "../utils/firebaseConfigAndFunctions";
import {adminDataTypesInterface, collectionsInterface} from "../utils/models";
import Loader from "./Loader";
import ModalNotification from "../modals/ModalNotification";
import useTranslation from "../hooks/useTranslation";

const Support = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen Title
  useEffect(() => {
    setScreenTitle('Служба поддержки');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Message data
  const [messageData, setMessageData] = useState('');
  //#endregion

  //#region Submit sending message to support
  const submitSendMessageToSupport = async (event, message) => {
    setIsLoading(true);

    event.preventDefault();

    const title = 'Обращение в службу поддержки';

    const messageToSend = `${message}. Email пользователя: ${user.email}`

    try {
      const adminData = await getCollectionWhereKeyValue(collectionsInterface
        .adminData, 'dataType', adminDataTypesInterface.email);

      await sendMessageToUser({
        email: adminData[0].text,
      }, title, messageToSend);

      navigate('/profile');
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Ошибка');
      setNotificationMessage('Что-то пошло не так. Ваше сообщение не было' +
        ' отправлено');

      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`Support ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <h3
            className={'DataLabel DataLabel_Centered'}
          >
            {translate('Сообщение в службу поддержки')}
          </h3>

          <form
            onSubmit={(event) => submitSendMessageToSupport(event, messageData)}
          >
            <div className={'CreateTripOrder-Item'}>
              <textarea
                required
                id="MessageToSupport"
                placeholder={translate('Напишите сообщение')}
                className={'FormControl TextArea'}
                name={'MessageToSupport'}
                rows={10}
                value={messageData}
                onChange={(event) => setMessageData(event.target.value)}
              />

              <button
                type={'submit'}
                className={'RegularButton'}
              >
                {translate('Отправить')}
              </button>
            </div>
          </form>
        </div>
      }

      {isNotification &&
        <ModalNotification
          title={notificationTitle}
          message={notificationMessage}
          showModal={isNotification}
          setShowModal={setIsNotification}
        />
      }
    </>
  );
  //#endregion
};

export default Support;
