const forbiddenToChangeFieldsInReservedOrders = ['departureDateWithoutTime',
  'endPoint', 'isVisibleForDrivers', 'isPassengerTrip', 'pricePerPerson',
  'requiredNumberOfPlaces', 'startPoint'];

const checkIfTripOrderCouldBeChanged = (initialTripOrder, updatedFields) => {
  const checkIsSomeUpdatedFieldForbidden = Object.keys(updatedFields)
    .some(field => forbiddenToChangeFieldsInReservedOrders.includes(field));

  if (checkIsSomeUpdatedFieldForbidden) {
    return false;
  }

  return true;
}

const checkAreReservationsNeedPetOptionCheck = (initialPetsOption,
  updatedPetsOption) => {

  if (updatedPetsOption && !initialPetsOption) {
    return true;
  }

  return false;
}

const checkAreReservationsNeedByFootOptionCheck = (initialByFootOption,
  updatedByFootOption) => {
  if (initialByFootOption && !updatedByFootOption) {
    return true;
  }

  return false;
}

export const checkTripOrderService = {
  checkIfTripOrderCouldBeChanged,
  checkAreReservationsNeedPetOptionCheck,
  checkAreReservationsNeedByFootOptionCheck,
}
