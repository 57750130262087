import React, {useContext, useEffect, useState} from 'react';
import DriverCard from "./DriverCard";
import {AppContext} from "../context/appContext";
import {entitiesNumberInterface} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";

const DriversList = ({
  setIsAddDriverScreenVisible = () => {},
  setIsDriversListVisible = () => {},
  setCurrentDriver = () => {},
  addClasses = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Мои водители');
  }, []);
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [driversChunk, setDriversChunk] = useState([]);

  useEffect(() => {
    setDriversChunk([user, ...user?.companyDrivers]
      .slice((currentPage - 1) * entitiesNumberInterface.companyDriversCards,
        currentPage * entitiesNumberInterface.companyDriversCards));
  }, [user.companyDrivers.length, currentPage]);
  //#endregion


  //#region Render
  return (
    <>
      <div className={`${addClasses}`}>
        <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {translate(screenTitle)}
        </h2>

        <div className={'DataListWithPagination'}>
          <div className={'ListContainer MyDrivers-DriversList'}>
            <div
              className={'CardsList MyDrivers-DriversList'}
            >
              {driversChunk?.map((driver, index) =>
                <React.Fragment
                  key={driver.idPost || driver.supposedEmail || index}
                >
                  <DriverCard
                    driver={driver}
                    setCurrentDriver={setCurrentDriver}
                    setIsDriversListVisible={setIsDriversListVisible}
                  />
                </React.Fragment>
              )}
            </div>

            <button
              type={'button'}
              className={'RegularButton DriversList-AddDriverButton'}
              onClick={() => {
                setIsAddDriverScreenVisible(true);
                setIsDriversListVisible(false);
              }}
            >
              {translate('Добавить')}
            </button>
          </div>

          {user?.companyDrivers?.length > entitiesNumberInterface.companyDriversCards &&
            <PaginationBlock
              portionSize={entitiesNumberInterface.companyDriversCards}
              listLength={user?.companyDrivers?.length || 0}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              startPage={startPage}
              setStartPage={setStartPage}
            />
          }
        </div>
      </div>
    </>
  );
  //#endregion
};

export default DriversList;
