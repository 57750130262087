import React, {useState, useContext, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import HistoryTripOrderDetails from "./HistoryTripOrderDetails";
import DisplayArchivedTripsOrders from "./DisplayArchivedTripsOrders";
import {AppContext} from "../context/appContext";
import {getCollectionWhereKeysValuesWithOperators} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface} from "../utils/models";
import {sortCardsByDateOfCreation} from "../utils/helpers";
import {queryArraysService} from "../utils/queryArraysService";

const TripsHistoryForPassengers = ({
  handleTripOrderCardClick = () => {},
  addClasses='',
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Trips orders list
  const [tripsOrdersToDisplay, setTripsOrdersToDisplay] = useState([]);
  //#endregion

  //#region Get data and set to state
  const getTripsOrdersAndSet = async () => {
    setIsLoading(true);

    try {
      const queryArray = queryArraysService.archivedTripsOrders(user.idPost);

      const orders = await getCollectionWhereKeysValuesWithOperators(
        collectionsInterface.tripsOrders, queryArray, queryArray.length);

      setTripsOrdersToDisplay(sortCardsByDateOfCreation(orders, false));
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getTripsOrdersAndSet().then();
  }, [])
  //#endregion

  //#region Update after actions
  const updateAfterPaymentConfirmationOrClaimCreation = (tripOrder) => {
    setTripsOrdersToDisplay(orders => orders
      .map(order => order.idPost === tripOrder.idPost ? tripOrder : order));
  }
  //#endregion

  //#region Render
  return (
    <div className={`TripsHistoryForPassengers ${addClasses}`}>
      <Routes>
          <Route
            index
            element={
              <DisplayArchivedTripsOrders
                isNotepad={false}
                tripsOrdersToDisplay={tripsOrdersToDisplay}
                handleTripOrderCardClick={handleTripOrderCardClick}
                addClasses={'Passengers-DisplayTripsOrders'}
                isLoading={isLoading}
                isDispatcher={true}
              />
            }
          />

          <Route
              path={'/archivedTripOrderDetails'}
              element={
                <HistoryTripOrderDetails
                  isDispatcher={true}
                  updateAfterPaymentConfirmationOrClaimCreation={
                  updateAfterPaymentConfirmationOrClaimCreation}
                />
              }
          />
        </Routes>
    </div>
  );
  //#endregion
}

export default TripsHistoryForPassengers;
