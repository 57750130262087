import React, {useState, useContext, useEffect} from 'react';
import TripStatusBadge from "./TripStatusBadge";
import {
  cancelReservationsAfterTripOrderSubmit,
  checkDealInfoAndMakeAccounting,
  compareSchemeAndDesirablePlaces,
  freezeFeeAfterReservationSubmit,
  getMoneyAmountToFreeze,
  getPaymentStatusText,
  getStatusTextForAdmin,
  sendMessageAboutMoneyFreezed,
  sendMessageAboutReservationConfirmation,
  sendMessageAboutReservationRejectionByDispatcher,
  sendMessageAboutReservationRejectionByTransporter,
  sendMessageNotEnoughMoney,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import DisplayMessengersRow from "./DisplayMessengersRow";
import DisplayTripPointsData from "./DisplayTripPointsData";
import {
  FaDog,
  FaLuggageCart,
  FaWalking,
} from "react-icons/fa";
import {FcApproval} from "react-icons/fc";
import {AiFillSafetyCertificate} from 'react-icons/ai';
import {BiAddToQueue} from 'react-icons/bi';
import {RiExchangeFill, RiMapPinUserLine} from 'react-icons/ri';
import ModalTestimonials from "../modals/ModalTestimonials";
import {AppContext} from "../context/appContext";
import {
  collectionsInterface,
  dealModel,
  pagesInterface, subpagesInterface, tripStatusesInterface,
  userModel
} from "../utils/models";
import {useNavigate} from "react-router-dom";
import {
  getDocInCollection,
  setDocumentToCollection,
  updateFieldInDocumentInCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import ModalNotification from "../modals/ModalNotification";
import ModalCancellingComment from "../modals/ModalCancellingComment";
import ModalSelectPaymentMethod from "../modals/ModalSelectPaymentMethod";
import TestimonialCard from "./TestimonialCard";
import ModalEvaluation from "../modals/ModalEvaluation";
import DisplayUserData from "./DisplayUserData";
import Loader from "./Loader";
import DisplayVehicleData from "./DisplayVehicleData";
import BoardingPlacesFromDriver from "./BoardingPlacesFromDriver";
import ModalChangeReservedPlaces from "../modals/ModalChangeReservedPlaces";
import {
  checkIsTripSuitableForPassenger,
  checkTripService
} from "../utils/checkTripService";
import {
  cancelAcceptedDeal, getReservationFromLists,
} from "../utils/reservationsService";
import LocationIq from "./LocationIq";
import {getTranslatedLocalityName} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import {tripService} from "../utils/tripService";
import {indicatorsNewService} from "../utils/indicatorsNewService";

const ReservationDetails = ({
  addClasses = '',
  isDispatcher = false,
  handleCarOnMapClick = () => {},
  handleTransferPassengerClick = () => {},
  isAdmin = false,
  handleRouteOnMapClick = undefined,
  updateAfterDealCancellation = () => {},
  updateAfterReservationSubmit = () => {},
  updateAfterReservationReject = () => {},
  updateAfterPaymentConfirmationOrClaimCreation = () => {},
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Manage displaying tripPoints
  const [areTripPointsVisible, setAreTripPointsVisible] = useState(false);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    currentTripOrder,
    setCurrentTripOrder,
    currentTrip,
    setCurrentTrip,
    currentReservation,
    setCurrentReservation,
  } = useContext(AppContext);
  //#endregion

  //#region Get screen title function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get actual data and set
  const getActualDataAndSet = async () => {
    setIsLoading(true);

    try {
      const actualTripOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, currentTripOrder.idPost);
      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        currentTrip.idPost);

      let actualReservation;

      if (actualTripOrder.acceptedDeal) {
        actualReservation = actualTripOrder.acceptedDeal;
      } else {
        actualReservation = getReservationFromLists(actualTripOrder
          .reservationsFromDispatchers, actualTripOrder.reservationsFromDrivers,
          currentReservation.uuid);

        setReservationData({
          isForChange: actualReservation?.isForChange,
          isPaymentOnCard: actualReservation?.isPaymentOnCard,
          isPaymentByCash: actualReservation?.isPaymentByCash,
        });
      }

      setCurrentReservation(actualReservation);
      setCurrentTripOrder(actualTripOrder);
      setCurrentTrip(actualTrip);

      if (!isAdmin) {
        await indicatorsNewService.updateIndicatorsDataOnReservationDetailsMount(
          isDispatcher, !!user.attachedToCompany, actualTripOrder, actualTrip,
          user.idPost, actualReservation);
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Get actual data, set screen title, clear state on unmount
  const clearEntities = () => {
    setCurrentReservation(null);
    // setCurrentTrip(null);
    // setCurrentTripOrder(null);
  }

  useEffect(() => {
    if (!currentReservation) {
      navigate('/');
    }

    if (currentTripOrder?.isArchived) {
      setScreenTitle('Детали поездки');
    } else {
      setScreenTitle('Детали брони');
    }

    getActualDataAndSet().then();

    return () => {
      clearEntities();
    }
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if reservation is empty
  useEffect(() => {
    if (!currentReservation?.originalTrip?.idPost) {
      if (isDispatcher) {
        navigate(`/${pagesInterface.passengers}`)
      } else {
        navigate(`/${pagesInterface.trips}`);
      }
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Manage displaying testimonials
  const [areTestimonialsVisible, setAreTestimonialsVisible] = useState(false);
  const [currentDriver, setCurrentDriver] = useState({});

  const hideTestimonials = () => {
    setAreTestimonialsVisible(false);
    setCurrentDriver({});
  }
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Variables for displaying another financial conditions
  const [isAnotherConditionsProposition, setIsAnotherConditionsProposition]
    = useState(false);
  //#endregion

  //#region Reservation data, initial value setting and it's updating
  const [reservationData, setReservationData] = useState({
    isForChange: currentReservation?.isForChange,
    isPaymentOnCard: currentReservation?.isPaymentOnCard,
    isPaymentByCash: currentReservation?.isPaymentByCash,
  });

  const updateReservation = (fieldName, newValue) => {
    setReservationData(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  };

  useEffect(() => {
    if ((isDispatcher && currentReservation.initiator === 'driver')) {
      if (!currentReservation?.passengerDepartureAddress?.fullAddress) {
        if (currentTrip?.boardingPlacesFromDriver?.length > 0) {
          updateReservation('passengerDepartureAddress',
            currentTrip?.boardingPlacesFromDriver[0]);
        }
      } else {
        updateReservation('passengerDepartureAddress',
          currentReservation?.passengerDepartureAddress);
      }
    }

    if (currentReservation?.isForChange && (currentReservation
      ?.isPaymentOnCard || currentReservation?.isPaymentByCash)) {
      updateReservation('isForChange', false);
      updateReservation('isPaymentOnCard', false);
      updateReservation('isPaymentByCash', false)
    }
  }, [currentReservation]);

  const handlePassengerDepartureAddressChange = (address) => {
    updateReservation('passengerDepartureAddress', {
      lat: address.lat,
      lon: address.lon,
      fullAddress: {
        [lang]: address.fullAddress,
        default: address.fullAddress,
      },
      placeId: address.placeId,
    });

    setPassengerDepartureAddress({
      ...address.fullAddress
    });
  }
  //#endregion

  //#region Handling select payment method is necessary
  const [isPaymentMethodSelection, setIsPaymentMethodSelection] = useState(false);
  const [isPaymentMethodByCash, setIsPaymentMethodByCash] = useState(true);
  //#endregion

  //#region Handling places numbers conflict
  const [isPlacesConflict, setIsPlacesConflict] = useState(false);
  const [conflictPlaces, setConflictPlaces] = useState([]);
  const [acceptablePlaces, setAcceptablePlaces] = useState([]);
  //#endregion

  //#region Handle cancel button displaying
  const checkIfCanCancelDeal = () => {
    const isAlreadyStarted = checkTripService
      .checkIsTripShouldAlreadyBeenStarted(currentTrip.startDate);

    return !isAlreadyStarted && (((currentTripOrder?.isActive && !currentTripOrder.isInProgress) &&
      (isDispatcher || currentReservation?.id)) && (!user.attachedToCompany) && !isAdmin);
  }
  //#endregion

  //#region handle Transfer button displaying
  const checkIfCanTransferPassenger = () => {
    const isShouldAlreadyStarted = checkTripService
      .checkIsTripShouldAlreadyBeenStarted(currentTrip.startDate);

    const isAlreadyStarted = currentTrip.status === tripStatusesInterface.inProgress;

    return !isShouldAlreadyStarted && !isAlreadyStarted && (((currentTripOrder
      ?.isActive && (!isDispatcher && currentReservation?.id)) &&
      (!currentTripOrder.isTransferred && !currentTripOrder.wasTransferred)) &&
      (currentTripOrder?.creatorIdPost !== currentTrip?.creatorIdPost) && (!user
      .attachedToCompany));
  }
  //#endregion

  //#region Submit reservation
  const submitReservationAfterPaymentMethodCheck = async (reservation,
    reservationData) => {
    let updatedReservation = {
      ...reservation,
      isSubmittedByDispatcher: true,
      isSubmittedByDriver: true,
      isTotallyAccepted: true,
    };

    updatedReservation = {
      ...updatedReservation,
      isPaymentOnCard: reservationData?.isPaymentOnCard,
      isPaymentByCash: reservationData?.isPaymentByCash,
      isForChange: reservationData?.isForChange,
    }

    if (reservationData?.passengerDepartureAddress?.hasOwnProperty('fullAddress')) {
      updatedReservation = {
        ...updatedReservation,
        passengerDepartureAddress: reservationData.passengerDepartureAddress,
      }
    }

    try {
      let fieldToUpdate = 'reservationsFromDispatchers';
      let updatedFieldValueForTripOrder = [];
      let updatedFieldValueForTrip = [];

      if (reservation.initiator === 'driver') {
        fieldToUpdate = 'reservationsFromDrivers';
      }

      const currentDate = Date.now();

      let deal = {
        ...dealModel,
        dateCreating: currentDate,
        id: updatedReservation.originalTripOrder.idPost,
        initiator: updatedReservation.initiator,
        isPassengerInOwnTrip: false,
        isTotallyAccepted: true,
        passengerDepartureAddress: updatedReservation.passengerDepartureAddress,
        pricePerPerson: updatedReservation.pricePerPerson,
        originalTrip: updatedReservation.originalTrip,
        originalTripOrder: updatedReservation.originalTripOrder,
        reservedPlaces: updatedReservation.reservedPlaces,
        reservedPlacesNumbers: updatedReservation.reservedPlacesNumbers,
        uuid: reservation.uuid,
      };

      if (reservation?.anotherFinancialConditions?.initiatorId) {
        deal = {
          ...deal,
          dispatcherFee: updatedReservation.anotherFinancialConditions
            .dispatcherFee,
          isForChange: updatedReservation?.anotherFinancialConditions
            ?.isForChange || false,
          isPaymentByCash: updatedReservation?.anotherFinancialConditions
            ?.isPaymentByCash || false,
          isPaymentOnCard: updatedReservation?.anotherFinancialConditions
            ?.isPaymentOnCard || false,
        }
      } else {
        deal = {
          ...deal,
          dispatcherFee: updatedReservation.dispatcherFee,
          isForChange: updatedReservation?.isForChange || false,
          isPaymentByCash: updatedReservation?.isPaymentByCash || false,
          isPaymentOnCard: updatedReservation?.isPaymentOnCard || false,
        }
      }

      if (reservation?.anotherPlacesProposition?.initiatorId) {
        deal = {
          ...deal,
          reservedPlacesNumbers: reservation?.anotherPlacesProposition
            ?.proposedPlacesNumbers,
        }
      }

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        reservation.originalTrip.idPost);

      updatedFieldValueForTrip = actualTrip[fieldToUpdate]
        .map(reserv => reserv.uuid === reservation.uuid
          ? updatedReservation : reserv);

      const updatedFreePlaces = parseInt(actualTrip.freePlaces) - parseInt(
        reservation.reservedPlaces);

      if (updatedFreePlaces < 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка бронирования');
        setNotificationMessage('Количество бронируемых мест больше ' +
          'количества свободных мест в поездке');

        return;
      }

      let hasPassengerUnwatchedByDrivers = {};

      if (actualTrip.drivers.some(driver => driver.idPost !== user.idPost)) {
          actualTrip.drivers.forEach(driver => {
            hasPassengerUnwatchedByDrivers[driver.idPost] = true
          });

          deal.hasPassengerUnwatchedByDrivers = hasPassengerUnwatchedByDrivers;
      }

      let fieldsToUpdate = {
        [fieldToUpdate]: updatedFieldValueForTrip,
        freePlaces: updatedFreePlaces,
        isActive: true,
        acceptedDeals: actualTrip?.acceptedDeals
          ? [...actualTrip.acceptedDeals, deal] : [deal],
        hasPassengerUnwatchedByDrivers,
      };

      if(!actualTrip?.isFreeSeating) {
        const occupiedRequiredPlaces = compareSchemeAndDesirablePlaces(
          actualTrip.placesScheme, deal.reservedPlacesNumbers);

        if (occupiedRequiredPlaces.length > 0) {
          return;
        }

        const updatedScheme = {
          ...actualTrip.placesScheme,
          rows: actualTrip.placesScheme.rows.map(curRow => ({
            row: curRow.row
            .map(cell => deal.reservedPlacesNumbers.includes(cell.placeNumber) ? {
              ...cell,
              isOccupied: true,
            } : cell)})),
        }

        fieldsToUpdate = {
          ...fieldsToUpdate,
          placesScheme: updatedScheme,
        }
      }

      if (deal.isPaymentOnCard) {
        const amountToFreeze = await getMoneyAmountToFreeze(deal
          .dispatcherFee * deal.reservedPlaces);

        const freezeResult = await freezeFeeAfterReservationSubmit(amountToFreeze,
          actualTrip.creatorUid);

        if (!freezeResult) {
          setIsNotification(true);
          setNotificationTitle('Ошибка');
          setNotificationMessage(isDispatcher ? 'Невозможно подтвердить' +
            ' бронь. У перевозчика недостаточно средств' : 'Невозможно' +
            ' подтвердить бронь. У Вас недостаточно средств');

          if (isDispatcher) {
            await sendMessageNotEnoughMoney(actualTrip, reservation
              .originalTripOrder, amountToFreeze);
          }

          return;
        }

        await sendMessageAboutMoneyFreezed(actualTrip, reservation
          .originalTripOrder, amountToFreeze);
      }

      await updateFieldsInDocumentInCollection(collectionsInterface.trips,
        reservation.originalTrip.idPost, fieldsToUpdate);

      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, reservation.originalTripOrder.idPost);

      updatedFieldValueForTripOrder = actualTripOrder[fieldToUpdate]
        .map(reserv => reserv.uuid === reservation.uuid
          ? updatedReservation : reserv);

      await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
        reservation.originalTripOrder.idPost, {
          [fieldToUpdate]: updatedFieldValueForTripOrder,
          acceptedDeal: deal,
          isOpenForSearch: false,
          isActive: true,
        })

      await cancelReservationsAfterTripOrderSubmit(updatedReservation,
        actualTripOrder, isDispatcher);

      await sendMessageAboutReservationConfirmation(actualTrip.creatorIdPost, actualTrip,
        actualTripOrder, isDispatcher);

      return true;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };

  const submitReservation = async (reservation, reservationData, tripOrder,
   trip, isAfterFeeTypeSelection = false) => {
    setIsLoading(true);

    try {
      const isForChange = isAfterFeeTypeSelection ? reservationData
        ?.isForChange : reservation?.isForChange;
      const isPaymentByCash = isAfterFeeTypeSelection ? reservationData
        ?.isPaymentByCash : reservation?.isPaymentByCash;
      const isPaymentOnCard = isAfterFeeTypeSelection ? reservationData
        ?.isPaymentOnCard : reservation?.isPaymentOnCard;

      if (isForChange) {
        if (isPaymentByCash) {
          setIsPaymentMethodSelection(true);
          setIsPaymentMethodByCash(true);

          return;
        }

        if (isPaymentOnCard) {
          setIsPaymentMethodSelection(true);
          setIsPaymentMethodByCash(false);

          return;
        }
      }

      if (!(reservationData?.passengerDepartureAddress?.fullAddress?.default
        || reservation?.passengerDepartureAddress?.fullAddress?.default)) {
        if (trip.startPoint.placeId === tripOrder.startPoint.placeId) {
          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Не выбран адрес посадки пассажиров');

          return;
        }
      }

      const actualOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, tripOrder.idPost);

      if (!actualOrder?.idPost) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Пассажир был удален');

        return;
      }

      if (actualOrder?.isActive) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'У пассажира уже есть подтвержденная поездка');

        return;
      }

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        trip.idPost);

      if (!actualTrip?.idPost) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Поездка была удалена');

        return;
      }

      const updatedFreePlaces = parseInt(actualTrip.freePlaces) - parseInt(
        reservation.reservedPlaces);

      if (updatedFreePlaces < 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка бронирования');
        setNotificationMessage('Количество бронируемых мест больше ' +
          'количества свободных мест в поездке');

        return;
      }

      const tripCheckResult = checkIsTripSuitableForPassenger(actualTrip
        .startDateWithoutTime, actualOrder.departureDateWithoutTime, actualTrip
        .tripPoints, actualOrder.startPoint.placeId, actualOrder.endPoint
        .placeId, actualTrip.isPassengerTrip, actualOrder.isPassengerTrip);

      if (!tripCheckResult.isSuitable) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Поездка уже не подходит пассажиру.' +
          ' Осуществите поиск заново');

        return;
      }

      if(!actualTrip?.isFreeSeating) {
        const placesNumbers = reservation?.anotherPlacesProposition
          ?.initiatorId ? reservation.anotherPlacesProposition
          .proposedPlacesNumbers : reservation.reservedPlacesNumbers;

        const occupiedRequiredPlaces = compareSchemeAndDesirablePlaces(
          actualTrip.placesScheme, placesNumbers);

        if (occupiedRequiredPlaces.length > 0) {
          const acceptablePlaces = [];

          placesNumbers.forEach(place => {
            if (!occupiedRequiredPlaces.includes(place)) {
              acceptablePlaces.push(place);
            }
          });

          setIsPlacesConflict(true);
          setAcceptablePlaces(acceptablePlaces);
          setConflictPlaces(occupiedRequiredPlaces);

          return;
        }
      }

      if (actualTrip?.acceptedDeals?.some(deal => deal.id === tripOrder.idPost)) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('У пассажира уже есть бронь в данной поездке');

        return;
      }

      const result = await submitReservationAfterPaymentMethodCheck(reservation,
        reservationData, false);

      if (!result) {
        return;
      }

      if (isDispatcher) {
        const updatedTripOrder = await getDocInCollection(collectionsInterface
          .tripsOrders, tripOrder.idPost);

        updateAfterReservationSubmit(updatedTripOrder);

        navigate(`/${pagesInterface.passengers}/${subpagesInterface.reservedTrips}`);
      } else {
        const updatedTrip = await getDocInCollection(collectionsInterface.trips,
          trip.idPost);

        updateAfterReservationSubmit(updatedTrip);

        navigate(`/${pagesInterface.trips}/${subpagesInterface.reservedTrips}`);
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Handle another conditions propositions show, hide and sending
  const proposeAnotherConditions = async () => {
    if (!(reservationData?.passengerDepartureAddress?.fullAddress?.default
      || currentReservation?.passengerDepartureAddress?.fullAddress?.default)) {

      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Не выбран адрес посадки пассажиров');

      return;
    }

    const actualOrder = await getDocInCollection(collectionsInterface
      .tripsOrders, currentTripOrder.idPost);

    if (!actualOrder.idPost) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage(
        'Пассажир был удален');

      return;
    }


    const actualTrip = await getDocInCollection(collectionsInterface.trips,
      currentTrip.idPost);

    if (!actualTrip?.idPost) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage(
        'Поездка была удалена');

      return;
    }

    setIsAnotherConditionsProposition(true);
  }

  const closeAnotherConditionsProposition = () => {
    setIsAnotherConditionsProposition(false);
    setReservationData(data => ({
      ...data,
      anotherFinancialConditions: {
        initiatorId: '',
        dispatcherFee: 0,
        pricePerPerson: 0,
        isPaymentOnCard: false,
        isPaymentByCash: false,
        isForChange: false,
      },
    }));
  }

  const updateAnotherFinancialConditions = (fieldName, newValue) => {
    const newFinancialConditions = {
      ...reservationData.anotherFinancialConditions,
      [fieldName]: newValue,
    };

    updateReservation('anotherFinancialConditions',
      newFinancialConditions);
  }

  const getUpdatedReservation = async (reservation, anotherFinancialConditions,
    proposedPlacesNumbers, submitterField = 'isSubmittedByDriver') => {
    let updatedReservation = {
      ...reservation,
      anotherFinancialConditions: {
        ...anotherFinancialConditions,
        initiatorId: user.idPost,
      },
      [submitterField]: true,
    };

    if (reservation?.anotherPlacesProposition?.initiatorId) {
      updatedReservation = {
        ...updatedReservation,
        anotherPlacesProposition: {
          initiatorId: '',
          proposedPlacesNumbers: [],
        },
        reservedPlacesNumbers: proposedPlacesNumbers,
      };
    }

    return updatedReservation;
  }

  const redirectAfterAnotherConditionsSubmit = () => {
    if (isDispatcher) {
      navigate(`/${pagesInterface.passengers}/${subpagesInterface.reservedTrips}`);
    } else {
      navigate(`/${pagesInterface.trips}/${subpagesInterface.reservedTrips}`);
    }
  }

  const submitAnotherFinancialConditionsAndSend = async (reservation,
    reservationData) => {
    setIsLoading(true);

    try {
      const fieldForIndicatorNew = isDispatcher ? 'hasNewActionFromDispatcher'
        : 'hasNewActionFromTransporter';

      let isPaymentMethodDefined = false;

      switch (true) {
        case reservationData.anotherFinancialConditions.isPaymentOnCard:
        case reservationData.anotherFinancialConditions.isPaymentByCash:
        case reservationData.anotherFinancialConditions.isForChange:
          isPaymentMethodDefined = true;
          break;
      }

      if (!isPaymentMethodDefined) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Необходимо выбрать способ оплаты');

        return;
      }

      if (!reservationData?.anotherFinancialConditions?.dispatcherFee
        || reservationData?.anotherFinancialConditions?.dispatcherFee <= 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Сумма гонорара должна быть больше нуля');

        return;
      }

      if (reservationData?.anotherFinancialConditions
          ?.dispatcherFee > reservation.pricePerPerson / 2) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Гонорар не может быть больше 50% от суммы поездки');

        return;
      }

      let actualTripOrder;
      let actualTrip;
      let updatedReservationData;

      actualTripOrder = await getDocInCollection(collectionsInterface.tripsOrders,
        reservation.originalTripOrder.idPost);

      if (reservation.initiator === 'dispatcher') {
        updatedReservationData = await getUpdatedReservation(reservation,
          reservationData.anotherFinancialConditions, reservation
            ?.anotherPlacesProposition?.proposedPlacesNumbers, 'isSubmittedByDriver');

        updatedReservationData[fieldForIndicatorNew] = true;

        if (!updatedReservationData.passengerDepartureAddress.placeId) {
          updatedReservationData.passengerDepartureAddress = reservationData
            .passengerDepartureAddress;
        }

        const reservationsFromDispatcherUpdated =
          actualTripOrder.reservationsFromDispatchers
          .map(reserv => reserv.uuid === reservation.uuid ?
            updatedReservationData : reserv);

        const fieldsToUpdateInTripOrder = {
          reservationsFromDispatchers: reservationsFromDispatcherUpdated,
        }

        if (!isDispatcher) {
          fieldsToUpdateInTripOrder.hasNewAction = true;
        }

        await updateFieldsInDocumentInCollection(collectionsInterface
          .tripsOrders, actualTripOrder.idPost, fieldsToUpdateInTripOrder);

        actualTrip = await getDocInCollection(collectionsInterface.trips,
          reservation.originalTrip.idPost);

        const reservationsFromDispatchersInTripUpdated =
          actualTrip.reservationsFromDispatchers
          .map(reserv => reserv.uuid === reservation.uuid ?
            updatedReservationData : reserv);

        const fieldsToUpdateInTrip = {
          reservationsFromDispatchers: reservationsFromDispatchersInTripUpdated,
        }

        if (isDispatcher) {
          fieldsToUpdateInTrip.hasNewAction = true;
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          actualTrip.idPost, fieldsToUpdateInTrip);

        redirectAfterAnotherConditionsSubmit();
      } else {
        updatedReservationData = await getUpdatedReservation(reservation,
          reservationData.anotherFinancialConditions, reservation
            ?.anotherPlacesProposition?.proposedPlacesNumbers, 'isSubmittedByDispatcher');

        updatedReservationData[fieldForIndicatorNew] = true;

        if (!updatedReservationData.passengerDepartureAddress.placeId) {
          updatedReservationData.passengerDepartureAddress = reservationData
            .passengerDepartureAddress;
        }

        const reservationsFromDriversUpdated =
          actualTripOrder.reservationsFromDrivers
          .map(reserv => reserv.uuid === reservation.uuid
            ? updatedReservationData : reserv);

        const fieldsToUpdateInTripOrder = {
          reservationsFromDrivers: reservationsFromDriversUpdated,
        }

        if (!isDispatcher) {
          fieldsToUpdateInTripOrder.hasNewAction = true;
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
          actualTripOrder.idPost, fieldsToUpdateInTripOrder);

        actualTrip = await getDocInCollection(collectionsInterface.trips,
          reservation.originalTrip.idPost);

        const reservationsFromDriversInTripUpdated =
          actualTrip.reservationsFromDrivers
          .map(reserv => reserv.uuid === reservation.uuid
            ? updatedReservationData : reserv);

        const fieldsToUpdateInTrip = {
          reservationsFromDrivers: reservationsFromDriversInTripUpdated,
        }

        if (isDispatcher) {
          fieldsToUpdateInTrip.hasNewAction = true;
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          actualTrip.idPost, fieldsToUpdateInTrip)

        setCurrentReservation(updatedReservationData);

        redirectAfterAnotherConditionsSubmit();
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handle reject reservation
  const handleRejectReservationClick = async () => {
    setIsLoading(true);

    try {
      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, currentReservation.originalTripOrder.idPost);

      if (actualTripOrder?.isCancelled) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Поездка уже отменена');

        return;
      }

      if (!actualTripOrder?.idPost) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Пассажир был удален');

        return;
      }

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        currentTrip.idPost);

      if (!actualTrip?.idPost) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage(
          'Поездка была удалена');

        return;
      }

      let actualReservation;

      if (currentReservation?.initiator === 'dispatcher') {
        actualReservation = actualTripOrder.reservationsFromDispatchers
          .find(reservation => currentReservation.uuid === reservation.uuid);
      } else {
        actualReservation = actualTripOrder.reservationsFromDrivers
          .find(reservation => currentReservation.uuid === reservation.uuid);
      }

      if (actualReservation.isCancelled) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Бронь уже отменена');

        return;
      }

      if (actualReservation.isTotallyAccepted) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Нельзя отменить подтвержденную бронь');

        return;
      }

      setIsReservationCancelling(true);
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Ошибка');

      return error;
    } finally {
      setIsLoading(false);
    }
  }

  const rejectReservation = async (tripOrder, trip, cancelComment, isDispatcher,
   reservation) => {
    setIsLoading(true);

    let actualTrip;
    let actualTripOrder;
    let updatedReservationsForTripOrder = [];
    let updatedReservationsForTrip = [];
    let reservationsToUpdate = '';
    let fieldsInTripToUpdate = {};

    try {
      actualTripOrder = await getDocInCollection(collectionsInterface.tripsOrders,
        tripOrder.idPost);

      if (reservation.initiator === 'dispatcher') {
        reservationsToUpdate = 'reservationsFromDispatchers';
      } else {
        reservationsToUpdate = 'reservationsFromDrivers';
      }

      updatedReservationsForTripOrder = actualTripOrder[reservationsToUpdate]
        .map(reserv => reserv.originalTrip.idPost !== trip.idPost ? reserv : {
          ...reserv,
          isCancelled: true,
          cancelComment: cancelComment,
        });

      await updateFieldInDocumentInCollection(collectionsInterface.tripsOrders,
        actualTripOrder.idPost, reservationsToUpdate,
        updatedReservationsForTripOrder);

      if (actualTripOrder?.isTransferred && reservation.initiator === 'dispatcher') {
        await updateFieldInDocumentInCollection(collectionsInterface.tripsOrders,
          actualTripOrder.sourceTripOrder.idPost, 'isTransferred', false);

        const actualTripForSourceTripOrder = await getDocInCollection(
          collectionsInterface.tripsOrders, actualTripOrder.sourceTripOrder
            .acceptedDeal?.originalTrip.idPost);

        const dealToReplace = actualTripForSourceTripOrder.transferredDeals
          .find(deal => deal.id === actualTripOrder.sourceTripOrder.idPost);

        const updatedTransferredDeals =
          actualTripForSourceTripOrder.transferredDeals
          .filter(deal => deal.id !== actualTripOrder.sourceTripOrder.idPost);

        const updatedAcceptedDeals = [...actualTripForSourceTripOrder
          .acceptedDeals, dealToReplace];

        let fieldsToUpdateInTrip = {
          acceptedDeals: updatedAcceptedDeals,
          transferredDeals: updatedTransferredDeals,
        }

        if (updatedTransferredDeals.length === 0) {
          fieldsToUpdateInTrip = {
            ...fieldsToUpdateInTrip,
            hasGivenAwayPassengers: false,
          }

          fieldsInTripToUpdate = {
            hasTransferredInPassengers: false,
          }
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          actualTripForSourceTripOrder.idPost, fieldsToUpdateInTrip);
      }

      actualTrip = await getDocInCollection(collectionsInterface.trips,
        reservation.originalTrip.idPost);

      updatedReservationsForTrip = actualTrip[reservationsToUpdate]
        .map(reserv => reserv.uuid !== reservation.uuid ? reserv : {
          ...reserv,
          isCancelled: true,
          cancelComment: cancelComment,
        });

      fieldsInTripToUpdate = {
        ...fieldsInTripToUpdate,
        [reservationsToUpdate]: updatedReservationsForTrip,
      }

      await updateFieldsInDocumentInCollection(collectionsInterface.trips,
        actualTrip.idPost, fieldsInTripToUpdate);

      setCurrentReservation({
        ...reservation,
        isCancelled: true,
        cancelComment: cancelComment,
      });

      if (isDispatcher) {
        await sendMessageAboutReservationRejectionByDispatcher(trip, tripOrder);

        updateAfterReservationReject({
          ...actualTripOrder,
          [reservationsToUpdate]: updatedReservationsForTripOrder,
        });
        navigate('/passengers/reservedTrips');
      } else {
        await sendMessageAboutReservationRejectionByTransporter(trip, tripOrder,
          'Перевозчик отклонил Вашу бронь по пассажиру');

        updateAfterReservationReject({
          ...actualTrip,
          ...fieldsInTripToUpdate,
        });
        navigate('/trips/reservedTrips');
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Detect if buttons for actions should be shown
  const detectIfActionButtonsShouldBeShown = (reservation, isDispatcher, user,
    isArchived) => {
    if (isArchived) {
      return false;
    }

    let isShow = false;

    if (reservation?.anotherFinancialConditions?.initiatorId) {
      if (reservation?.anotherFinancialConditions?.initiatorId !== user.idPost) {
        isShow = true;
      }
    } else if (reservation?.anotherPlacesProposition?.initiatorId) {
      if (reservation?.anotherPlacesProposition?.initiatorId !== user.idPost) {
        isShow = true;
      }
    } else if ((isDispatcher && reservation?.isSubmittedByDriver) ||
      (!isDispatcher && reservation.isSubmittedByDispatcher)) {
      isShow = true;
    }

    return isShow;
  };
  //#endregion

  //#region Get text who should agree with new conditions
  const getTextForNewConditionsAgreement = (initiatorId, isDispatcher, user) => {
    let resultText = '';

    if (initiatorId === user.idPost) {
      if (isDispatcher) {
        resultText = translate('Ожидает подтверждения водителя');
      } else {
        resultText = translate('Ожидает подтверждения диспетчера');
      }
    }

    return resultText;
  };
  //#endregion

  //#region Handle cancelling modal appearance
  const [isCancelling, setIsCancelling] = useState(false);
  const [isReservationCancelling, setIsReservationCancelling] = useState(false);
  //#endregion

  //#region Handle Deal cancelling
  const handleCancelPassengerTrip = async () => {
    setIsLoading(true);

    try {
      const canBeCancelled = checkIfCanCancelDeal();

      if (!canBeCancelled) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Поездку уже нельзя отменить')

        return;
      }

      const actualTripOrder = await getDocInCollection(
        collectionsInterface.tripsOrders, currentTripOrder.idPost);

      if (actualTripOrder?.isCancelled) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Поездка уже отменена');

        return;
      }

      setIsCancelling(true);
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Ошибка');
    } finally {
      setIsLoading(false);
    }
  }

  const cancelDeal = async (tripOrder, trip, cancelComment, isDispatcher) => {
    setIsLoading(true);

    try {
      await cancelAcceptedDeal(tripOrder, currentReservation, isDispatcher,
        cancelComment, user.idPost, trip.idPost, updateAfterDealCancellation);

      setCurrentReservation({
        ...reservationData,
        isCancelled: true,
        cancellationInitiatorRole: isDispatcher ? 'dispatcher' : 'driver',
        cancellationInitiatorId: user.idPost,
        cancelComment: cancelComment,
      });

      if (isDispatcher) {
        navigate('/passengers/activeTrips');
      } else {
        navigate('/trips/activeTrips');
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Payment receiving confirmation
  const confirmPayment = async (tripOrder, isDispatcher) => {
    setIsLoading(true);

    try {
      await updateFieldInDocumentInCollection(collectionsInterface.tripsOrders,
        tripOrder.idPost, 'isFeePaid', true);

      updateAfterPaymentConfirmationOrClaimCreation({
        ...tripOrder,
        isFeePaid: true,
      }, currentTrip);

      if (isDispatcher) {
        navigate('/passengers/archivedTrips');
      } else {
        navigate('/trips/archivedTrips');
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Payment not received claim
  const claimPaymentNotReceived = async (tripOrder) => {
    setIsLoading(true);

    try {
      const claim = {
        initiatorId: tripOrder.creatorIdPost,
        text: 'Payment wasn\'t fulfilled',
        suspectId: tripOrder.acceptedDeal?.originalTrip.creatorIdPost,
        tripId: tripOrder.acceptedDeal?.originalTrip.idPost,
        tripOrderId: tripOrder.idPost,
      }

      await setDocumentToCollection(collectionsInterface.claimsToAdmin, claim);

      await updateFieldInDocumentInCollection(collectionsInterface.tripsOrders,
        tripOrder.idPost, 'wasClaimToAdminSent', true);

      updateAfterPaymentConfirmationOrClaimCreation({
        ...tripOrder,
        wasClaimToAdminSent: true,
      });

      navigate(`/${pagesInterface.passengers}/${subpagesInterface.archivedTrips}`);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Replacement to exchange
  const moveToExchange = async (tripOrder) => {
    setIsLoading(true);

    try {
      const fieldsToUpdateInTripOrder = {
        ['acceptedDeal.isForChange']: true,
        ['acceptedDeal.isPaymentByCash']: false,
        ['acceptedDeal.isPaymentOnCard']: false,
      }

      await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
        tripOrder.idPost, fieldsToUpdateInTripOrder);

      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        tripOrder.acceptedDeal?.originalTrip.idPost);

      const updatedAcceptedDeals = actualTrip.acceptedDeals
        ?.map(deal => deal.id !== tripOrder.idPost ? deal : {
        ...deal,
        isForChange: true,
        isPaymentByCash: false,
        isPaymentOnCard: false,
      }) || null;

      await updateFieldInDocumentInCollection(collectionsInterface.trips,
        actualTrip.idPost, 'acceptedDeals', updatedAcceptedDeals);

      const updatedDeal = {
        ...tripOrder.acceptedDeal,
        isForChange: true,
        isPaymentByCash: false,
        isPaymentOnCard: false,
      }

      const updatedTripOrder = {
        ...tripOrder,
        acceptedDeal: updatedDeal,
      }

      const updatedTrip = {
        ...actualTrip,
        acceptedDeals: updatedAcceptedDeals,
      }

      await checkDealInfoAndMakeAccounting(updatedDeal, updatedTripOrder,
        updatedTrip);

      navigate(`/${pagesInterface.passengers}/${subpagesInterface.archivedTrips}`);

      return true;
    } catch (error) {
      return false;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handle evaluation screen appearance and data
  const [isNeedEvaluation, setIsNeedEvaluation] = useState(false);
  const [evaluatedUsers, setEvaluatedUsers] = useState([]);

  const handleEvaluateDispatcherClick = async () => {
    setIsLoading(true);

    try {
      const actualTripOrder = getDocInCollection(
        collectionsInterface.tripsOrders, currentTripOrder.idPost);

      if (actualTripOrder?.driverTestimonial?.evaluatedPersonId) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Другой водитель уже оценил эту поездку.' +
          ' Вы не можете оставить отзыв');

        return;
      }

      setEvaluatedUsers([dispatcherData])
      setIsNeedEvaluation(true);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleEvaluateDriverClick = async () => {
    setIsLoading(true);

    try {
      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, currentTripOrder.idPost);

      if (actualTripOrder?.dispatcherTestimonial?.evaluatedPersonId) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Оценка уже поставлена. Вы не можете ' +
          'оставить отзыв');

        return;
      }

      setEvaluatedUsers(currentTrip?.drivers || [])
      setIsNeedEvaluation(true);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Get dispatcher data
  const [dispatcherData, setDispatcherData] = useState(userModel);

  const getDispatcherData = async (dispatcherId) => {
    try {
      const dispatcher = await getDocInCollection(collectionsInterface.users,
        dispatcherId);

      return dispatcher;
    } catch (error) {
      return userModel;
    }
  }

  useEffect(() => {
    if (currentTripOrder?.creatorIdPost) {
      if (user.idPost !== currentTripOrder?.creatorIdPost) {
        getDispatcherData(currentTripOrder.creatorIdPost)
          .then(dispatcher => setDispatcherData(dispatcher))
          .catch(() => {});
      }
    } else {
      if (user.idPost !== currentReservation?.originalTripOrder?.creatorIdPost) {
        getDispatcherData(currentReservation?.originalTripOrder?.creatorIdPost)
          .then(dispatcher => setDispatcherData(dispatcher))
          .catch(() => {});
      }
    }
  }, []);
  //#endregion

  //#region Handle show intermediate points click
  const handleShowIntermediatePointsClick = async () => {
    setAreTripPointsVisible(state => !state);
  };
  //#endregion

  //#region Translate departureAddresses if necessary
  const [passengerDepartureAddress, setPassengerDepartureAddress] = useState({
    default: '',
  });
  //#endregion

  //#region Handle transfer click
  const handleTransferClick = async (tripOrder) => {
    setIsLoading(true);

    try {
      const canTransfer = checkIfCanTransferPassenger();

      if (!canTransfer) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Пассажира уже нельзя передать');

        return;
      }

      await handleTransferPassengerClick(tripOrder);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handle copy trip
  const handleCopyTrip = async () => {
    setIsLoading(true);

    const tripCopy = await tripService.getTripCopyForNewTrip(currentTrip.idPost);

    setCurrentTrip(tripCopy);

    navigate(`/${pagesInterface.trips}/${subpagesInterface.createTrip}`);

    setIsLoading(false);
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`TripDetails ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          {isAdmin &&
            <>
              <div className={'CreateTripOrder-Item'}>
                <div className={'FlexBlock'}>
                  <label className={'DataLabel'}>
                    {translate('Id')}
                  </label>

                  {currentReservation.id}
                </div>
              </div>

              <div className={'FlexBlock'}>
                <label className={'DataLabel'}>
                  {translate('Статус')}
                </label>

                {translate(getStatusTextForAdmin(currentTripOrder))}
              </div>

              <div className={'PaddingBlock'}></div>
            </>
          }

          <div className={'TripDetails-Head'}>
            <div className={'TripDetails-Block TripDetails-DepartureTime'}>
              {currentTrip?.startDate ? showFormattedToLanguageDate(lang,
                currentTrip?.startDate, true) : 'Invalid time'}
            </div>

            {currentTripOrder?.isArchived &&
              <>
                {currentTripOrder?.isCancelled ? (
                  <div
                    className={'TripDetails-Block TripDetails-DepartureTime'}>
                    {currentTrip?.startDate ? showFormattedToLanguageDate(lang,
                      currentTrip?.startDate, true) : 'Invalid time'}
                  </div>
                ) : (
                  <div
                    className={'TripDetails-Block TripDetails-DepartureTime'}>
                    {currentTripOrder?.completionTime
                      ? showFormattedToLanguageDate(lang, currentTripOrder
                        ?.completionTime, true) : 'Invalid time'}
                  </div>
                )}
              </>
            }

            {currentReservation?.isCancelled &&
              <TripStatusBadge
                statusText={'Отменена'}
                addClasses={'TripDetails-TripStatusBadge'}
              />
            }

            {currentTripOrder?.isArchived &&
              <>
                <TripStatusBadge
                  statusText={currentTripOrder?.isCancelled ? 'Отменена'
                    : 'Выполнена'}
                  addClasses={'TripDetails-TripStatusBadge'}
                />

                <TripStatusBadge
                  statusText={getPaymentStatusText(currentTripOrder,
                    currentReservation?.isPassengerInOwnTrip)}
                  addClasses={'TripDetails-PaymentStatusBadge'}
                />
              </>
            }

            <div className={'PaddingBlock'}/>
          </div>

          {currentReservation?.isCancelled &&
            <>
              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
              <div className={'TripDetails-Block'}>
                <h6>
                  {`${translate('Комментарий к отмене')}: `}
                </h6>

                <div>
                  {translate(currentReservation?.cancelComment) ||
                    currentReservation?.cancelComment}
                </div>
              </div>
              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          {!currentTripOrder?.isArchived &&
            <div className={'TripDetails-Block'}>
              <span>
                {`${translate('Дата брони')}: `}
              </span>

              <span className={'TripCard-DateText'}>
                {currentReservation?.dateCreating
                  ? showFormattedToLanguageDate(lang, currentReservation
                    ?.dateCreating, true) : 'Invalid time'}
              </span>
            </div>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {(!isDispatcher || currentReservation?.isPassengerInOwnTrip) &&
            <>
              <DisplayUserData
                actualUser={currentReservation?.isPassengerInOwnTrip ? user
                  : dispatcherData}
                isAdmin={isAdmin}
                isDriver={false}
                isFullInfo={true}
                areContactsVisible={isDispatcher || currentReservation
                  .isTotallyAccepted}
              />

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          <h5 className={'RegularTitle'}>
            {currentTrip?.companyName}
          </h5>

          <h5 className={'RegularTitle RegularTitle_Left RegularTitle_Small'}>
            {translate('Водители в поездке')}
          </h5>

          {currentTrip?.drivers?.map((driver, dIndex) => (
            <React.Fragment key={dIndex}>
              <DisplayUserData
                userId={driver.idPost}
                isAdmin={isAdmin}
                isFavouriteIconVisible={!isAdmin && isDispatcher}
                isFullInfo={true}
                isDriver={true}
                areContactsVisible={!isDispatcher || currentReservation
                  .isTotallyAccepted}
              />
            </React.Fragment>
          ))}

          <div className={'TripDetails-Block'}>
            <DisplayVehicleData
              vehicle={currentTrip?.vehicle}
              isFullInfo={true}
            />
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div className='TripDetails-BlockLabel'>
            {translate('Маршрут поездки')}
          </div>

          <div className={'TripDetails-Block TripDetails-RouteBlock'}>
            <DisplayTripPointsData
              tripPoints={[currentTrip?.startPoint, currentTrip?.endPoint]}
            />
          </div>

          {(currentTripOrder?.isInProgress || currentTrip?.tripPoints
            ?.length > 2) &&
            <div className={'TripDetails-Block FlexBlock'}>
              {currentTrip?.tripPoints?.length > 2 &&
                <button
                  type={'button'}
                  className={currentTripOrder?.isInProgress
                    ? 'RegularButton FlexBlock-HorizontalItem ' +
                    'FlexBlock-HorizontalItem_W60'
                    : 'RegularButton'
                  }
                  onClick={() => handleShowIntermediatePointsClick()}
                >
                  {translate('Промежуточные пункты')}
                </button>
              }

              {currentTripOrder?.isInProgress && !isAdmin &&
                <button
                  type={'button'}
                  className={'RegularButton RegularButton_Contrast ' +
                    'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
                  onClick={() => handleCarOnMapClick()}
                >
                  {translate('Авто на карте')}
                </button>
              }
            </div>
          }

          {(isAdmin && handleRouteOnMapClick) &&
            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast'}
              onClick={() => handleRouteOnMapClick()}
            >
              {translate('Маршрут на карте')}
            </button>
          }

          {areTripPointsVisible &&
            <DisplayTripPointsData
              tripPoints={currentTrip?.tripPoints?.slice(1,
                currentTrip?.tripPoints.length - 1)}
            />
          }

          <div className='TripDetails-BlockLabel'>
            {translate('Маршрут пассажира')}
          </div>

          <div className={'TripDetails-Block TripDetails-RouteBlock'}>
            <DisplayTripPointsData
              tripPoints={
                [currentTripOrder?.startPoint, currentTripOrder?.endPoint]
              }
            />
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {(isDispatcher || currentReservation.isTotallyAccepted) &&
            <>
              <div className={'TripDetails-Block'}>
                <div
                  className={
                    'RegularTitle RegularTitle_Small RegularTitle_Left'
                  }
                >
                  {`${currentTripOrder?.passengerName}`}
                </div>

                {(currentTripOrder?.isActive || currentTripOrder?.isInProgress ||
                    currentTripOrder?.isArchived || currentReservation?.id) &&
                  (currentTripOrder?.passengerPhones?.map((phone, pIndex) => (
                    <React.Fragment key={pIndex}>
                      <div
                        className='TripDetails-Item'
                      >
                        <DisplayMessengersRow
                          phone={phone}
                          isPhoneNumberRefactored={true}
                          isLinkToCall={true}
                        />
                      </div>
                    </React.Fragment>
                  )))
                }
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          <div className={'TripDetails-Block TripDetails-TripOrderType'}>
            <span className='TripDetails-BlockLabel'>
            {`${translate('Тип поездки')}: `}
            </span>

            {`${currentTrip?.isPassengerTrip ?
              translate('Пассажирская')
              :
              translate('Грузовая')
            }`}
          </div>

          {currentTrip?.isPassengerTrip && !currentReservation?.isCancelled &&
            <div className={'TripDetails-Block'}>
              <h6 className={'RegularTitle RegularTitle_Small'}>
                {translate('Место посадки пассажиров')}
              </h6>

              <div>
                {(currentReservation?.passengerDepartureAddress?.hasOwnProperty(
                  'fullAddress')) ?
                  <>
                    <div className={'TripCard-AddressText'}>
                      {getTranslatedLocalityName(passengerDepartureAddress) ||
                        getTranslatedLocalityName(currentReservation
                          ?.passengerDepartureAddress?.fullAddress, lang) ||
                        translate('Не определено')}
                    </div>

                    <div className={'PaddingBlock'}></div>
                  </>
                  :
                  <div className={'TripCard-AddressText'}>
                    {currentTrip?.startPoint?.placeId !==
                      currentTripOrder?.startPoint?.placeId ?
                      <>
                        {isDispatcher ? (
                          translate('Договоритесь с водителем ' +
                            'о месте посадки после подтверждения брони')
                        ) : (
                          translate('Договоритесь с диспетчером ' +
                            'о месте посадки после подтверждения брони')
                        )}
                      </>
                      :
                      <>
                        {translate('Не определено')}
                      </>
                    }
                  </div>
                }

                {((isDispatcher && currentReservation.initiator === 'driver') &&
                  (!currentTripOrder?.isActive) && (!currentTripOrder
                      ?.isArchived)) &&
                  <>
                    {currentTrip.isBoardingByDriverAddresses ?
                      <BoardingPlacesFromDriver
                        shouldBeChosen={((isDispatcher && currentReservation
                            .initiator === 'driver') &&
                          ((!currentTripOrder?.isActive) && (!currentTripOrder
                            ?.isArchived)) &&
                          currentTrip?.boardingPlacesFromDriver?.length > 0)}
                        handlePassengerDepartureAddressChange={
                          handlePassengerDepartureAddressChange
                        }
                        reservationData={reservationData}
                      />
                      :
                      <div>
                        <label>
                          {translate('Введите удобный Вам адрес')}
                        </label>

                        <LocationIq
                          externalAction={
                            handlePassengerDepartureAddressChange
                          }
                          receivedAddress={getTranslatedLocalityName(
                            reservationData?.passengerDepartureAddress
                              ?.fullAddress, lang)}
                        />
                      </div>}
                  </>
                }
              </div>
            </div>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div className={'TripDetails-Block TripDetails-TripPrice'}>
            <div>
              <span className='TripDetails-BlockLabel'>
                {`${translate('Стоимость билета у перевозчика')}: `}
              </span>

              {`${currentTrip?.pricePerPerson || 0}
              ${translate('грн.')}`}
            </div>

            <div>
              <span className='TripDetails-BlockLabel'>
                {`${translate('Стоимость поездки')}: `}
              </span>

              {`${currentReservation?.pricePerPerson || 0}
              ${translate('грн.')}`}
            </div>

            {(!user.attachedToCompany && !currentReservation
                .isPassengerInOwnTrip) &&
              <>
                <div>
                  <span className='TripDetails-BlockLabel'>
                    {`${translate('Гонорар диспетчера')}: `}
                  </span>

                  {`${currentReservation?.dispatcherFee || 0}
                  ${translate('грн.')}`}
                </div>

                <div>
                  <span className='TripDetails-SecondaryBlockLabel'>
                    {`${translate('Тип получения')}: `}
                  </span>

                  <div className={'TripDetails-SecondaryBlockText'}>
                    {currentReservation?.isPaymentOnCard &&
                      <div className={'FlexBlock FlexBlock_JustifyLeft'}>
                        <span>
                          {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                        </span>

                        <span
                          className={'ColoredText TripDetails-SafeBadge'}
                        >
                          <AiFillSafetyCertificate/>
                        </span>
                      </div>
                    }

                    {currentReservation?.isPaymentByCash &&
                      <div>
                        {translate('Наличными')}
                      </div>
                    }

                    {currentReservation?.isForChange &&
                      <div>
                        {translate('По обмену')}
                      </div>
                    }
                  </div>
                </div>

                {currentReservation?.anotherFinancialConditions?.initiatorId &&
                  <>
                    <div
                      className={'SeparationBlock SeparationBlock_Limited'}
                    ></div>

                    {currentReservation?.anotherFinancialConditions
                      ?.initiatorId === user.idPost ? (
                      <h2
                        className={'RegularTitle RegularTitle_Small AlertText'}
                      >
                        {translate('Новые условия для согласования')}
                      </h2>
                    ) : (isDispatcher ?
                        <h2
                          className={
                            'RegularTitle RegularTitle_Small AlertText'
                          }
                        >
                          {translate('Новые условия от водителя')}
                        </h2>
                        :
                        <h2
                          className={
                            'RegularTitle RegularTitle_Small AlertText'
                          }
                        >
                          {translate('Новые условия от диспетчера')}
                        </h2>
                    )
                    }

                    <div>
                      <span className='TripDetails-BlockLabel'>
                        {`${translate('Гонорар диспетчера')}: `}
                      </span>

                      {`${currentReservation?.anotherFinancialConditions
                        ?.dispatcherFee || 0} ${translate('грн.')}`}
                    </div>

                    <div>
                      <span
                        className='TripDetails-SecondaryBlockLabel'>
                          {`${translate('Тип получения')}: `}
                      </span>

                      <div className={'TripDetails-SecondaryBlockText'}>
                        {currentReservation?.anotherFinancialConditions
                            ?.isPaymentOnCard &&
                          <div>
                            {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                          </div>
                        }

                        {currentReservation?.anotherFinancialConditions
                            ?.isPaymentByCash &&
                          <div>
                            {translate('Наличными')}
                          </div>
                        }

                        {currentReservation?.anotherFinancialConditions
                            ?.isForChange &&
                          <div>
                            {translate('По обмену')}
                          </div>
                        }
                      </div>
                    </div>

                    <div
                      className={'SeparationBlock SeparationBlock_Limited'}
                    ></div>

                    {(currentReservation?.anotherFinancialConditions
                        ?.initiatorId === user?.idPost) &&
                      <>
                        <div className={'TripDetails-Block'}>
                          <div className={'InfoField InfoField_Contrast'}>
                            {getTextForNewConditionsAgreement(currentReservation
                              ?.anotherFinancialConditions?.initiatorId,
                              isDispatcher, user)}
                          </div>
                        </div>

                        <div
                          className={'SeparationBlock SeparationBlock_Limited'}
                        ></div>
                      </>
                    }
                  </>
                }
              </>
            }
          </div>

          {currentTripOrder?.isPassengerTrip &&
            <>
              <div className={'TripDetails-Block'}>
                <span className='TripDetails-BlockLabel'>
                  {`${translate('Тип рассадки')}: `}
                </span>

                <span>
                  {currentTrip?.isFreeSeating ? translate('Свободная')
                    : translate('Индивидуальная')}
                </span>
              </div>
            </>
          }

          {currentTrip?.isPassengerTrip &&
            <>
              <div className={'TripDetails-Block TripDetails-PlacesAmount'}>
                <span className='TripDetails-BlockLabel'>
                  {`${translate('Количество забронированных мест')}: `}
                </span>

                {`${currentReservation?.reservedPlaces}`}
              </div>

              {currentReservation?.reservedPlacesNumbers?.length > 0 &&
                <div className={'TripDetails-Block'}>
                  <span className='TripDetails-BlockLabel'>
                    {`${translate('Номера мест')}: `}
                  </span>

                  {currentReservation?.reservedPlacesNumbers?.sort()
                    ?.map((placeNumber, index, array) =>
                    <span key={placeNumber}>
                      {placeNumber}{index < array.length - 1 ? ', ' : ''}
                    </span>
                  )}
                </div>
              }

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>

              {currentReservation?.anotherPlacesProposition?.initiatorId &&
                <>
                  {currentReservation?.anotherPlacesProposition
                    ?.initiatorId === user.idPost ? (
                    <h2
                      className={'RegularTitle RegularTitle_Small AlertText'}
                    >
                      {translate('Новые места для согласования')}
                    </h2>
                  ) : (isDispatcher ?
                      <h2
                        className={
                          'RegularTitle RegularTitle_Small AlertText'
                        }
                      >
                        {translate('Новые места от водителя')}
                      </h2>
                      :
                      <h2
                        className={
                          'RegularTitle RegularTitle_Small AlertText'
                        }
                      >
                        {translate('Новые места от диспетчера')}
                      </h2>
                  )
                  }

                  <div>
                    <span className='TripDetails-BlockLabel'>
                      {`${translate('Номера мест')}: `}
                    </span>

                    {currentReservation?.anotherPlacesProposition
                      ?.proposedPlacesNumbers?.map((place, index, array) => (
                        <span>
                          {`${place}${index !== array.length - 1 ? ',' : ''} `}
                        </span>
                      ))
                    }
                  </div>

                  <div
                    className={'SeparationBlock SeparationBlock_Limited'}
                  ></div>

                  {(currentReservation
                      ?.anotherPlacesProposition?.initiatorId === user?.idPost) &&
                    <>
                      <div className={'TripDetails-Block'}>
                        <div className={'InfoField InfoField_Contrast'}>
                          {getTextForNewConditionsAgreement(currentReservation
                            ?.anotherPlacesProposition?.initiatorId, isDispatcher, user)}
                        </div>
                      </div>

                      <div
                        className={'SeparationBlock SeparationBlock_Limited'}
                      ></div>
                    </>
                  }
                </>
              }
            </>
          }

          {currentTrip?.isPassengerTrip &&
            <>
              <div
                className={'TripDetails-Block FlexBlock FlexBlock_JustifyLeft' +
                  ' TripDetails-OptionsBlock'}
              >
                {currentTrip?.isByFoot &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <FaWalking/>
                  </p>
                }

                {currentTrip?.isTripWithPets &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <FaDog/>
                  </p>
                }


                {currentTrip?.isPassengerAddress &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <RiMapPinUserLine/>
                  </p>
                }

                {currentTrip?.isSeparatePlaceForLuggage &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <FaLuggageCart/>
                  </p>
                }

                {currentTrip?.addOptions?.length > 0 &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <BiAddToQueue/>
                  </p>
                }

                {currentReservation?.isPaymentOnCard &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <AiFillSafetyCertificate/>
                  </p>
                }

                {currentReservation?.isForChange &&
                  <p
                    className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                  >
                    <RiExchangeFill/>
                  </p>
                }
              </div>
            </>
          }

          {!currentTrip?.isPassengerTrip &&
            <div
              className={'TripDetails-Block FlexBlock FlexBlock_JustifyLeft ' +
                'TripDetails-CargoComment'}
            >
              <span className='TripDetails-BlockLabel'>
                {`${translate('Комментарий к грузу')}: `}
              </span>

              <div>
                {currentTripOrder?.cargoDescription || translate('Отсутствует')}
              </div>
            </div>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {currentTrip?.addOptions?.length > 0 &&
            <>
              <div className={'TripDetails-Block'}>
                <h5 className={'RegularTitle RegularTitle_Small'}>
                  {translate('Дополнительные опции')}
                </h5>

                {currentTrip?.addOptions?.map((option, oIndex) => {
                  if (option.checked) {
                    return (
                      <p
                        key={oIndex}
                        className={
                          'FlexBlock FlexBlock_JustifyLeft TripDetails-Option'
                        }
                      >
                        <FcApproval/>

                        <span className={'TripDetails-AddOptionText'}>
                          {option?.optionContent[lang] || option?.optionContent
                            ?.defaultName}
                        </span>
                      </p>
                    )
                  } else {
                    return <></>
                  }
                })}
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {currentTrip.creatorIdPost === user.idPost &&
            <div className={'TripDetails-Block'}>
              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast' +
                  ' RegularButton_Danger'}
                onClick={() => handleCopyTrip()}
              >
                {translate('Копировать поездку')}
              </button>
            </div>
          }

          <div className={'TripDetails-Block TripDetails-TripOrderComment'}>
            <div className='TripDetails-BlockLabel'>
              {`${translate('Комментарий к поездке')}:`}
            </div>

            <div className={'TripDetails-BlockText'}>
              {currentTrip?.tripOrderComment || translate('Отсутствует')}
            </div>
          </div>

          {!currentReservation.isCancelled &&
            <>
              <div className={'SeparationBlock SeparationBlock_Limited'}></div>

              <div className={'TripDetails-Block'}>
                {(currentReservation?.isSubmittedByDispatcher &&
                    !currentReservation?.isSubmittedByDriver) &&
                  <div className={'InfoField'}>
                    {translate('Ожидает подтверждения водителя')}
                  </div>
                }

                {(currentReservation?.isSubmittedByDriver && !currentReservation
                    ?.isSubmittedByDispatcher) &&
                  <div className={'InfoField InfoField_Contrast'}>
                    {translate('Ожидает подтверждения диспетчера')}
                  </div>
                }
              </div>

              {(!user.attachedToCompany && isAnotherConditionsProposition) ? (
                <div className={'TripDetails-Block'}>
                  <h2 className={'RegularTitle'}>
                    {translate('Другие условия гонорара')}
                  </h2>

                  <div className={'ReplaceTripScreen-Item'}>
                    <label className={'ReplaceTripScreen-Label'}>
                      {translate('Гонорар диспетчера')}
                    </label>

                    <input
                      className={'FormControl ReplaceTripScreen-Input'}
                      type={'text'}
                      placeholder={translate('Гонорар диспетчера')}
                      onChange={(event) => updateAnotherFinancialConditions(
                        'dispatcherFee', Number(event.target.value))}
                      value={reservationData?.anotherFinancialConditions
                        ?.dispatcherFee}
                    />
                  </div>

                  <div className={'ReplaceTripScreen-Item'}>
                    <h5 className={'RegularTitle RegularTitle_Small'}>
                      {translate('Способ оплаты')}
                    </h5>

                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripScreen-Block'}
                    >
                      <div className={'OptionCheckBox'}>
                        <input
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          checked={reservationData?.anotherFinancialConditions
                            ?.isPaymentOnCard}
                          onChange={(event) => updateAnotherFinancialConditions(
                            'isPaymentOnCard',
                            event.target.checked)}
                          disabled={reservationData?.anotherFinancialConditions
                            ?.isPaymentByCash}
                          id={'isPaymentOnCard'}
                        />
                      </div>

                      <label
                        className={'ReplaceTripScreen-OptionText'}
                        htmlFor={'isPaymentOnCard'}
                      >
                        {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                      </label>

                      <div className={'ReplaceTripScreen-IconContainer'}>
                        {reservationData?.anotherFinancialConditions
                            ?.isPaymentOnCard &&
                          <img
                            src={'/img/bank-safe-icon.svg'}
                            className={'ReplaceTripScreen-Icon'}
                          />
                        }
                      </div>
                    </div>

                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripScreen-Block'}
                    >
                      <input
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        checked={reservationData?.anotherFinancialConditions
                          ?.isPaymentByCash}
                        onChange={(event) => updateAnotherFinancialConditions(
                          'isPaymentByCash', event.target.checked)}
                        disabled={reservationData?.anotherFinancialConditions
                          ?.isPaymentOnCard}
                        id={'isPaymentByCash'}
                      />

                      <label
                        htmlFor={'isPaymentByCash'}
                        className={'ReplaceTripScreen-OptionText'}
                      >
                        {translate('Наличные по договоренности')}
                      </label>
                    </div>

                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft ' +
                        'ReplaceTripScreen-Block'}
                    >
                      <input
                        id={'isForChange'}
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        onChange={(event) => updateAnotherFinancialConditions(
                          'isForChange',
                          event.target.checked)}
                        checked={reservationData?.anotherFinancialConditions
                          ?.isForChange}
                      />

                      <label
                        className={'ReplaceTripScreen-OptionText'}
                        htmlFor={'isForChange'}
                      >
                        {translate('Обмен')}
                      </label>
                    </div>
                  </div>

                  <div
                    className={'SeparationBlock SeparationBlock_Limited'}></div>

                  <div className={'ReplaceTripScreen-Item'}>
                    <div className={'TripDetails-Block'}>
                      <button
                        type={'button'}
                        className={'RegularButton'}
                        onClick={() => submitAnotherFinancialConditionsAndSend(
                          currentReservation, reservationData)}
                      >
                        {translate('Отправить')}
                      </button>
                    </div>

                    <div className={'TripDetails-Block'}>
                      <button
                        type={'button'}
                        className={'RegularButton RegularButton_Contrast'}
                        onClick={() => closeAnotherConditionsProposition()}
                      >
                        {translate('Не менять условия')}
                      </button>
                    </div>
                  </div>
                </div>
              ) : ((detectIfActionButtonsShouldBeShown(currentReservation,
                  isDispatcher, user, currentTrip.isArchived)) &&
                <>
                  <div className={'TripDetails-Block'}>
                    <button
                      type={'button'}
                      onClick={() => submitReservation(currentReservation,
                        reservationData, currentTripOrder, currentTrip)}
                      className={'RegularButton'}
                    >
                      {translate('Подтвердить')}
                    </button>
                  </div>

                  <div className={'TripDetails-Block'}>
                    <button
                      type={'button'}
                      onClick={() => proposeAnotherConditions()}
                      className={'RegularButton RegularButton_Contrast'}
                    >
                      {translate('Предложить другие условия гонорара')}
                    </button>
                  </div>

                  <div className={'TripDetails-Block'}>
                    <button
                      type={'button'}
                      onClick={() => handleRejectReservationClick()}
                      className={'RegularButton RegularButton_Contrast'}
                    >
                      {translate('Отменить')}
                    </button>
                  </div>
                </>
              )}

              {checkIfCanCancelDeal() &&
                <div className={'TripDetails-Block'}>
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={() => handleCancelPassengerTrip()}
                  >
                    {translate('Отменить поездку')}
                  </button>
                </div>
              }

              {checkIfCanTransferPassenger() &&
                <div className={'TripDetails-Block'}>
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={() => handleTransferClick(currentTripOrder)}
                  >
                    {translate('Передать пассажира')}
                  </button>
                </div>
              }

              {currentTripOrder?.isArchived && !currentReservation
                ?.isPassengerInOwnTrip &&
                <>
                  {isAdmin ?
                    <>
                      <div className={'TripDetails-Block'}>
                        <div
                          className={'RegularTitle RegularTitle_Small ' +
                            'RegularTitle_Left ColoredText'}
                        >
                          {translate('Отзыв диспетчера')} {': '}
                        </div>

                        {currentTripOrder?.dispatcherTestimonial
                          ?.evaluatedPersonId ?
                          <TestimonialCard
                            testimonial={currentTripOrder.dispatcherTestimonial}
                          /> :
                          <div>
                            {translate('Диспетчер пока не оценил ' +
                              'поездку. Ожидайте')}
                          </div>
                        }
                      </div>

                      <div className={'TripDetails-Block'}>
                        <div
                          className={'RegularTitle RegularTitle_Small ' +
                            'RegularTitle_Left ColoredText'}
                        >
                          {translate('Отзыв водителя')} {': '}
                        </div>

                        {currentTripOrder?.driverTestimonial?.evaluatedPersonId ?
                          <TestimonialCard
                            testimonial={currentTripOrder.driverTestimonial}/>
                          :
                          <div>
                            {translate('Водитель пока не оценил ' +
                              'поездку. Ожидайте')
                            }
                          </div>
                        }
                      </div>
                    </>
                    :
                    <>
                      {isDispatcher ? (
                        <>
                          <div className={'TripDetails-Block'}>
                            <div
                              className={'RegularTitle RegularTitle_Small ' +
                                'RegularTitle_Left ColoredText'}
                            >
                              {translate('Ваш отзыв')} {': '}
                            </div>

                            {currentTripOrder?.dispatcherTestimonial
                              ?.evaluatedPersonId ?
                              <TestimonialCard
                                testimonial={currentTripOrder
                                  .dispatcherTestimonial}
                              />
                              :
                              <div>
                                {translate('Вы еще не оценили поездку')}
                              </div>
                            }
                          </div>

                          <div className={'TripDetails-Block'}>
                            <div
                              className={'RegularTitle RegularTitle_Small ' +
                                'RegularTitle_Left ColoredText'}
                            >
                              {translate('Отзыв водителя')} {': '}
                            </div>

                            {(currentTripOrder?.driverTestimonial
                              ?.evaluatedPersonId && currentTripOrder
                              ?.dispatcherTestimonial?.evaluatedPersonId) ? (
                              <TestimonialCard
                                testimonial={currentTripOrder.driverTestimonial}
                              />
                            ) : (
                              <div>
                                {!currentTripOrder?.driverTestimonial
                                  ?.evaluatedPersonId ? translate(
                                    'Водитель пока не оценил поездку. Ожидайте')
                                  :
                                  translate('Вы не можете видеть отзыв' +
                                    ' о Вас по поездке пока не оставите свой')
                                }
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={'TripDetails-Block'}>
                            <div
                              className={'RegularTitle RegularTitle_Small ' +
                                'RegularTitle_Left ColoredText'}
                            >
                              {translate('Ваш отзыв')}{': '}
                            </div>

                            {currentTripOrder?.driverTestimonial
                              ?.evaluatedPersonId ?
                              <TestimonialCard
                                testimonial={currentTripOrder.driverTestimonial}
                              />
                              :
                              <div>
                                {translate('Вы еще не оценили поездку')}
                              </div>
                            }
                          </div>

                          <div className={'TripDetails-Block'}>
                            <div
                              className={'RegularTitle RegularTitle_Small ' +
                                'RegularTitle_Left ColoredText'}
                            >
                              {translate('Отзыв диспетчера')} {': '}
                            </div>

                            {(currentTripOrder?.dispatcherTestimonial
                              ?.evaluatedPersonId && currentTripOrder
                              ?.dispatcherTestimonial?.shouldBeDisplayed) ? (
                              <TestimonialCard
                                testimonial={
                                  currentTripOrder.dispatcherTestimonial
                                }
                              />
                            ) : (
                              <div>
                                {!currentTripOrder?.dispatcherTestimonial
                                  ?.evaluatedPersonId ? translate(
                                    'Диспетчер пока не оценил поездку. Ожидайте')
                                  :
                                  translate('Вы не можете видеть отзыв' +
                                    ' о Вас по поездке пока не оставите свой')
                                }
                              </div>)
                            }
                          </div>
                        </>)

                      }

                      {!currentTripOrder.isCancelled &&
                        <>
                          {(!user.attachedToCompany && (currentReservation
                            ?.isPaymentByCash && !currentTripOrder?.isFeePaid)) &&
                            <div className={'TripDetails-Block'}>
                              <button
                                type={'button'}
                                className={'RegularButton'}
                                onClick={() => confirmPayment(currentTripOrder,
                                  isDispatcher)}
                              >
                                {isDispatcher ? translate('Подтвердить' +
                                  ' получение оплаты') : translate('Заявить' +
                                  ' об оплате')}
                              </button>
                            </div>
                          }

                          {(isDispatcher && (currentReservation
                              ?.isPaymentByCash && currentTripOrder?.isFeePaid)) &&
                            <>
                              {currentTripOrder.wasClaimToAdminSent ?
                                <div className={'InfoField InfoField_Contrast'}>
                                  {translate('Отправлена жалоба')}
                                </div>
                                :
                                <div className={'TripDetails-Block'}>
                                  <button
                                    type={'button'}
                                    className={
                                      'RegularButton RegularButton_Contrast'
                                    }
                                    onClick={() => claimPaymentNotReceived(
                                      currentTripOrder)}
                                  >
                                    {translate('Не произошла выплата')}
                                  </button>
                                </div>
                              }
                            </>
                          }

                          {(isDispatcher && (currentReservation
                              ?.isPaymentByCash && !currentTripOrder?.isFeePaid)) &&
                            <div className={'TripDetails-Block'}>
                              <button
                                type={'button'}
                                className={'RegularButton'}
                                onClick={() => moveToExchange(currentTripOrder)}
                              >
                                {translate('Перевести в обмен')}
                              </button>
                            </div>
                          }

                          {isDispatcher ?
                            (<>
                              {(!currentTripOrder?.dispatcherTestimonial
                                ?.evaluatedPersonId && !currentTripOrder
                                ?.isCancelled) &&
                                <div className={'TripDetails-Block'}>
                                  <button
                                    type={'button'}
                                    className={'RegularButton'}
                                    onClick={() => handleEvaluateDriverClick()}
                                  >
                                    {translate('Оценить водителя')}
                                  </button>
                                </div>
                              }
                              </>
                            ) : (
                              <>
                                {(!currentTripOrder?.driverTestimonial
                                  ?.evaluatedPersonId && !currentTripOrder
                                    ?.isCancelled) &&
                                  <div className={'TripDetails-Block'}>
                                    <button
                                      type={'button'}
                                      className={'RegularButton'}
                                      onClick={
                                        () => handleEvaluateDispatcherClick()
                                      }
                                    >
                                      {translate('Оценить диспетчера')}
                                    </button>
                                  </div>
                                }
                              </>)
                          }
                        </>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </div>
      }

      {isPaymentMethodSelection &&
        <ModalSelectPaymentMethod
          showModal={isPaymentMethodSelection}
          setShowModal={setIsPaymentMethodSelection}
          isCash={isPaymentMethodByCash}
          reservationData={reservationData}
          updateReservation={updateReservation}
          submitReservation={submitReservation}
          title={translate('Выбор типа получения гонорара')}
        />
      }

      {isPlacesConflict &&
        <ModalChangeReservedPlaces
          title={'Конфликт забронированных мест'}
          showModal={isPlacesConflict}
          setShowModal={setIsPlacesConflict}
          acceptablePlaces={acceptablePlaces}
          conflictPlaces={conflictPlaces}
          updateAfterReservationSubmit={updateAfterReservationSubmit}
        />
      }

      {isCancelling &&
        <ModalCancellingComment
          showModal={isCancelling}
          setShowModal={setIsCancelling}
          isDispatcher={isDispatcher}
          title={'Отмена поездки'}
          message={'Комментарий к отмене поездки'}
          cancelDeal={cancelDeal}
        />
      }

      {isReservationCancelling &&
        <ModalCancellingComment
          showModal={isReservationCancelling}
          setShowModal={setIsReservationCancelling}
          isDispatcher={isDispatcher}
          title={'Отмена брони'}
          message={'Комментарий к отмене брони'}
          cancelDeal={rejectReservation}
        />
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }

      {areTestimonialsVisible &&
        <ModalTestimonials
          personUid={currentDriver.uid}
          showModal={areTestimonialsVisible}
          hideTestimonials={hideTestimonials}
          isDispatcher={true}
        />
      }

      {isNeedEvaluation &&
        <ModalEvaluation
          title={isDispatcher ? 'Оценка водителя' : 'Оценка диспетчера'}
          message={''}
          showModal={isNeedEvaluation}
          setShowModal={setIsNeedEvaluation}
          isDispatcher={isDispatcher}
          evaluatedUsers={evaluatedUsers}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
    </>
  );
  //#endregion
};

export default ReservationDetails;
