import React, {useState, useContext, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import ReservedTripOrderDetails from "./ReservedTripOrderDetails";
import {AppContext} from "../context/appContext";
import ReservationDetails from "./ReservationDetails";
import TripsOrdersWithReservations from "./TripsOrdersWithReservations";
import {subpagesInterface} from "../utils/models";

const ReservedTripsForPassengers = ({
    handleShowCreateTrip = () => {},
    handleReservationCardClick = () => {},
    addClasses='',
}) => {
  //#region Get screen title from context
  const { setScreenTitle } = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Забронированные поездки');
  }, []);
  //#endregion

  //#region Manage displaying tabs
  const [activeTabKey, setActiveTabKey] = useState('myReservations');
  //#endregion

  //#region Render
  return (
    <>
      <div className={`ReservedTripsForPassengers ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <TripsOrdersWithReservations
                handleShowCreateTrip={handleShowCreateTrip}
              />
            }
          />

          <Route
            path={`/${subpagesInterface.reservedTripOrderDetails}`}
            element={
              <ReservedTripOrderDetails
                handleReservationCardClick={handleReservationCardClick}
                activeTabKey={activeTabKey}
                setActiveTabKey={setActiveTabKey}
              />
            }
          />

          <Route
            path={`/${subpagesInterface.reservationDetails}`}
            element={
              <ReservationDetails
                isDispatcher={true}
                addClasses={'App-Screen_Narrow'}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
  //#endregion
};

export default ReservedTripsForPassengers;
