import React, {useState, useEffect, useCallback, useContext} from 'react';
import {debounce} from "lodash";
import axios from "axios";
import {
  endPointsInterface,
  locationIqPlaceModel,
  serverUrl
} from "../utils/models";
import {AppContext} from "../context/appContext";
import PlacesSuggestions from "./PlacesSuggestions";
import InputWithLoader from "./InputWithLoader";
import '../styles/LocationSearch.scss';

const LocationIq = ({
  addClassesForInputs= '',
  receivedAddress = '',
  index = 0,
  externalAction = () => {}
}) => {
  //#region Get lang, dictionaries and functions from context
  const { lang } = useContext(AppContext);
  //#endregion

  //#region Loading
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Component state
  const [address, setAddress] = useState(receivedAddress);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  //#endregion

  //#region Set initial address and change when necessary
  useEffect(() => {
    setAddress(receivedAddress);
  }, [receivedAddress]);
  //#endregion

  //#region Handle autocomplete
  const applyQuery = useCallback(
    debounce(async (text) => {
      if (text) {
        const result = await axios.get(`${serverUrl}${endPointsInterface
          .placesAutocomplete}?search=${text}&lang=${lang}`);

        setSearchResults(result?.data?.suggestions || []);
      } else {
        setSearchResults([]);
      }

      setIsLoading(false)
    }, 500),
    [],
  );

  const handleSearch = async (text) => {
    setIsLoading(true);
    setSearchText(text);

    applyQuery(text);
  }
  //#endregion

  //#region Handle selection
  const handlePlaceSelect = (place) => {
    setSearchResults([]);
    setSearchText('');

    externalAction(place, index);
  }
  //#endregion

  //#region Render
  return (
    <div className={'LocationSearch'}>
      {address ?
        <input
          type={'text'}
          defaultValue={address}
          onFocus={() => {
            externalAction(locationIqPlaceModel, index);
          }}
          className={`FormControl ${addClassesForInputs}`}
        />
        :
        <InputWithLoader
          addClassesForInput={addClassesForInputs}
          placeholder={'Введите населенный пункт ...'}
          value={searchText}
          handleChange={handleSearch}
          isLoader={isLoading}
        />
      }

      <PlacesSuggestions
        addClasses={'LocationSearch_Suggestions'}
        places={searchResults}
        handlePlaceSelect={handlePlaceSelect}
      />
    </div>
  );
  //#endregion
};

export default LocationIq;
