import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  deleteTransactionHandler,
  getTransactionTypeName,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {
  getCollectionWhereKeysValuesWithOperators,
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  pagesInterface,
  transactionsTypesInterface
} from "../utils/models";
import {MdOutlineSlideshow} from "react-icons/md";
import {FaTrash} from "react-icons/fa";
import ReactTable from "react-table-v6";
import Loader from "./Loader";
import FiltersControls from "./FiltersControls";
import useTranslation from "../hooks/useTranslation";

const TransactionsListForAdmin = ({
  addClasses = '',
  setCurrentTransaction = () => {},
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('История транзакций');
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Data for React table
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getCollectionWhereKeysValuesWithOperators(
      collectionsInterface.transactions,[
      {
        key: 'isVisibleForAdmin',
        value: true,
        operator: '==',
      },
      {
        key: 'type',
        value: transactionsTypesInterface.accountWithdrawal,
        operator: '!=',
      }
      ], 2).then(result => setTransactions(result))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  //#region Search and filters
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');
  const statusesList = [{
    name: 'Успешная',
    value: 'successful',
  }, {
    name: 'Отменена',
    value: 'unSuccessful'
  }];

  const getStatusName = (status) => {
    if (status === 'successful') {
      return translate('Успешная');
    }

    return translate('Отменена');
  };
  //#endregion

  const getTransactionsToDisplay = (searchText, filtersData, transactions) => {
    let searchResult = [];

    if (searchText) {
      searchResult = transactions.filter(transaction => {
        if (transaction?.recipient?.fullName?.toUpperCase()
          .includes(searchText.toUpperCase())) {
          return true;
        }

        if (transaction?.payer?.fullName?.toUpperCase()
          .includes(searchText.toUpperCase())) {
          return true;
        }

        const name = translate(getTransactionTypeName(transaction.type));

        if (name?.toUpperCase().includes(searchText.toUpperCase())) {
          return true;
        }

        return false;
      });
    } else {
      searchResult = transactions;
    }

    let result = [];

    if (filtersData) {
      if (Object.values(filtersData).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(transaction => {
          let res = true;

          if (filtersData.status === 'successful' && !transaction.isSuccessful) {
            return false;
          }

          if ((filtersData.status === 'unSuccessful' && transaction.isSuccessful)) {
            return false;
          }

          if (filtersData.date) {
            const date = new Date(filtersData.date);
            date.setHours(0, 0, 0, 0);

            const dateAsTimestamp = date.getTime();

            if (dateAsTimestamp !== transaction.date) {
              return false;
            }
          }

          if (filtersData.amount) {
            if (transaction.amount < (Number(filtersData?.amount.from || 0))
              || transaction.amount > Number(filtersData.amount.to || 0)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  };

  const handleTransactionClick = (transaction) => {
    setCurrentTransaction(transaction);

    navigate(`/${pagesInterface.transactionsAdmin}/transactionDetails`);
  };

  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'date',
      Header: translate('Дата'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e?.date ? showFormattedToLanguageDate(lang, e.date)
          : translate('Не указана')}
      </div>
    },
    {
      id: 'type',
      Header: translate('Тип'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {translate(e?.type ? getTransactionTypeName(e.type) : translate('Не указан'))}
      </div>
    },
    {
      id: 'recipientName',
      Header: translate('Получатель'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e?.recipient?.fullName || translate('Не указан')}
      </div>
    },
    {
      id: 'payerName',
      Header: translate('Плательщик'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e?.payer?.fullName || translate('Не указан')}
      </div>
    },
    {
      id: 'amount',
      Header: translate('Сумма'),
      accessor: e => <div
        className='UsersTable-Cell UsersTable-Cell_Centered'
      >
        {e?.amount || translate('Не указана')}
      </div>
    },
    {
      id: 'status',
      Header: translate('Статус'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e?.isSuccessful ? translate('Успешная') : translate('Отменена')}
      </div>
    },
    {
      id: 'editAndDelete',
      Header: '',
      width: 80,
      accessor: e =>
        <div
          className='UsersTable-Cell'
        >
          {<div
            className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}>
            <button
              type={'button'}
              className={'ShowButton'}
              onClick={() => handleTransactionClick(e)}
            >
              <MdOutlineSlideshow/>
            </button>

            <button
              type={'button'}
              className={'DelButton'}
              onClick={() => deleteTransactionHandler(e, setTransactions)}
            >
              <FaTrash/>
            </button>
          </div>}
        </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      {isLoading ? <Loader />
        :
        <div className={`TransactionsListForAdmin ${addClasses}`}>
          <div className={'PaddingBlock PaddingBlock_Half'}></div>

          <div className={'TitleWithButton mb-2'}>
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>

            <div
              className={'ToggleButton'}
              onClick={
                () => navigate(`/${pagesInterface.transactionsAdmin}/withdrawals`)
              }
            >
              {translate('Заявки')}
            </div>
          </div>

          <FiltersControls
            searchText={searchText}
            setSearchText={setSearchText}
            setAppliedFilters={setAppliedFilters}
            getStatusName={getStatusName}
            statusesList={statusesList}
            priceFilterName={'Сумма'}
          />

          <ReactTable
            data={getTransactionsToDisplay(searchText, appliedFilters,
              transactions)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}
          />

          <div className={'PaddingBlock'}></div>
        </div>
      }
    </React.Fragment>
  );
  //#endregion
};

export default TransactionsListForAdmin;
