import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import TestimonialsList from "../components/TestimonialsList";
import {collectionsInterface, userModel} from "../utils/models";
import {getCollectionWhereKeyValue} from "../utils/firebaseConfigAndFunctions";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/ModalTestimonials.scss';

const ModalTestimonials = ({
  personUid,
  showModal,
  hideTestimonials = () => {},
  addClasses = '',
  title = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get person and set it to the state
  const [person, setPerson] = useState(userModel);

  useEffect(() => {
    getCollectionWhereKeyValue(collectionsInterface.users, 'uid', personUid)
      .then(users => setPerson(users[0]))
      .catch(() => {}).finally(() => setIsLoading(false))
  }, []);
  //#endregion

  //#region Get person's testimonials
  const getPersonTestimonials = (person) => {
    return (Object.values(person.testimonials))
      .filter(testimonial => testimonial?.shouldBeDisplayed);
  }
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
          hideTestimonials();
      }}
      className={`ModalTestimonials ${addClasses}`}
    >
      {isLoading ? <Loader />
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2
                className={
                  'RegularTitle RegularTitle_Small RegularTitle_Left'
                }
              >
                {translate(title)}
              </h2>

              <h2
                className={
                  'RegularTitle RegularTitle_Small RegularTitle_Left'
                }
              >
                {person?.fullName}
              </h2>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <TestimonialsList
              addClasses={'ModalTestimonials-List'}
              testimonials={getPersonTestimonials(person)}
            />
          </Modal.Body>

          <Modal.Footer>
            <div className={'FlexBlock ModalAddDriverAddress-Item'}>
              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast ' +
                  'FlexBlock-HorizontalItem ' +
                  'FlexBlock-HorizontalItem_W35'}
                onClick={() => hideTestimonials()}
              >
                {translate('Вернуться')}
              </button>
            </div>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
  //#endregion
};

export default ModalTestimonials;
