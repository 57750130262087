import React from 'react';
import '../styles/PaymentStatusBadge.scss';
import useTranslation from "../hooks/useTranslation";

const PaymentStatusBadge = ({statusText, addClasses=''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={`PaymentStatusBadge ${addClasses}`}>
        <span>{translate(statusText)}</span>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default PaymentStatusBadge;
