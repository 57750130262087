//#region Server url
import {DEFAULT_LANG} from "./data";

// export const serverUrl = 'http://127.0.0.1:5001/international-driver-eu/us-central1';
export const serverUrl = 'https://us-central1-international-driver-eu.cloudfunctions.net';
//#endregion

//#region Interfaces
export const rolesInterface = {
  admin: 'admin',
  company: 'company',
  driver: 'driver',
  superadmin: 'superadmin',
  user: 'user',
}

export const pagesInterface = {
  admin: 'admin',
  catalogs: 'catalogs',
  cookiesPolicy: 'cookiesPolicy',
  exchange: 'exchange',
  languages: 'languages',
  login: 'login',
  notepad: 'notepad',
  options: 'options',
  passengers: 'passengers',
  passengersAdmin: 'passengersAdmin',
  profile: 'profile',
  recoverPassword: 'recoverpassword',
  register: 'register',
  removalPolicy: 'removalPolicy',
  resetPassword: 'resetpassword',
  rulesAndPolicy: 'rulesandpolicy',
  settings: 'settings',
  start: 'start',
  statistic: 'statistic',
  testimonials: 'testimonials',
  testimonialsAdmin: 'testimonialsAdmin',
  textPages: 'textPages',
  transactionsAdmin: 'transactionsAdmin',
  trips: 'trips',
  tripsAdmin: 'tripsAdmin',
  termsOfUse: 'termsOfUse',
  usersManagement: 'usersManagement',
}

export const subpagesInterface = {
  myPassengers: 'myPassengers',
  createTripOrder: 'createTripOrder',
  displayTripOrderDetails: 'displayTripOrderDetails',
  displayTripDetails: 'displayTripDetails',
  favouriteDrivers: 'favouriteDrivers',
  reservedTrips: 'reservedTrips',
  activeTrips: 'activeTrips',
  archivedTrips: 'archivedTrips',
  suitableTrips: 'suitableTrips',
  reservedTripOrderDetails: 'reservedTripOrderDetails',
  reservedTripDetails: 'reservedTripDetails',
  reservationDetails: 'reservationDetails',
  createTrip: 'createTrip',
  activeTripOrderDetails: 'activeTripOrderDetails',
  myTrips: 'myTrips',
  archivedTripOrderDetails: 'archivedTripOrderDetails',
  suitableTripsForTransfer: 'suitableTripsForTransfer',
  searchPassenger: 'searchPassenger',
  passengersList: 'passengersList',
}

export const collectionsInterface = {
  adminData: 'adminData',
  balanceAccounts: 'balanceAccounts',
  depositOrders: 'depositOrders',
  dictionaries: 'dictionaries',
  driversTrips: 'driversTrips',
  placesSchemes: 'placesSchemes',
  trips: 'trips',
  tripsOrders: 'tripsOrders',
  users: 'users',
  vehicles: 'vehicles',
  vehiclesBodyTypes: 'vehiclesBodyTypes',
  vehiclesCatalog: 'vehiclesCatalog',
  vehiclesColors: 'vehiclesColors',
  vehiclesTrips: 'vehiclesTrips',
  testimonials: 'testimonials',
  tripAddOptionsList: 'tripAddOptionsList',
  transactions: 'transactions',
  claimsToAdmin: 'claimsToAdmin',
  notifications: 'notifications',
}

export const transactionsTypesInterface = {
  accountDeposit: 'accountDeposit',
  accountWithdrawal: 'accountWithdrawal',
  bonuses: 'bonuses',
  bonusesForReferralDeal: 'bonusesForReferralDeal',
  commissionTaking: 'commissionTaking',
  compensationForExchange: 'compensationForExchange',
  dispatcherFee: 'dispatcherFee',
  exchange: 'exchange',
}

export const endPointsInterface = {
  createAdmin: '/expressServer/createAdmin',
  deleteAdmin: '/expressServer/deleteAdmin',
  getPlace: '/expressServer/getPlace',
  makeDeposit: '/expressServer/createDeposit',
  makeWithdrawal: '/expressServer/makeWithdrawal',
  placesAutocomplete: '/expressServer/placesAutocomplete',
  updateAdmin: '/expressServer/updateAdmin',
  sendEmail: '/expressServer/sendEmail',
};

export const searchTypesInCollections = {
  keysValues: 'keysValues',
  anyKey: 'anyKey',
  anyKeyWithOperator: 'anyKeyWithOperator',
  withOperator: 'withOperator',
  orderedAndLimitedWithOperator: 'orderedAndLimitedWithOperator',
}
//#endregion

//#region Registration errors
export const registrationErrorsTypesInterface = {
  emptyField: 'emptyField',
  wrongValue: 'wrongValue',
}

export const defaultErrorObject = {
  isError: false,
  errorType: registrationErrorsTypesInterface.emptyField,
  errorMessage: '',
}

export const registrationErrorsModel = {
  birthDay:  defaultErrorObject,
  city: defaultErrorObject,
  confirmPassword: defaultErrorObject,
  country: defaultErrorObject,
  dayOfBirth: defaultErrorObject,
  email: defaultErrorObject,
  fullName: defaultErrorObject,
  monthOfBirth: defaultErrorObject,
  password: defaultErrorObject,
  phoneNumbers: defaultErrorObject,
  yearOfBirth: defaultErrorObject,
}

export const vehicleErrorsModel = {
  registrationCountry: defaultErrorObject,
  licensePlate: defaultErrorObject,
  brand: defaultErrorObject,
  model: defaultErrorObject,
  bodyType: defaultErrorObject,
  color: defaultErrorObject,
  productionDate: defaultErrorObject,
  placesNumber: defaultErrorObject,
  permissionNumber: defaultErrorObject,
}
//#endregion

//#region User
export const phoneNumberModel = {
  hasTelegram: false,
  hasViber: false,
  hasWhatsApp: false,
  phoneNumber: '',
};

export const balanceAccountModel = {
  amount: 0,
  reservedAmount: 0,
  ownerIdPost: '',
  ownerUid: '',
  transactions: {},
};

export const companyDriverModel = {
  email: '',
  fullName: '',
  isFulfilledRegistration: '',
  phoneNumbers: [],
  supposedEmail: '',
  uid: '',
};

export const testimonialModel = {
  author: {},
  dealId: '',
  evaluatedPersonId: '',
  evaluatedPersonFullName: '',
  isVisibleForAdmin: true,
  mark: 0,
  shouldBeDisplayed: false,
  text: '',
  tripId: '',
  hasBeenWatchedByTransporter: false,
  hasBeenWatchedByEvaluatedPerson: false,
}

export const userModel = {
  archivedExchanges: [],
  attachedToCompany: '',
  averageMark: 0,
  balanceAccount: '',
  birthDay: '',
  city: '',
  companyDrivers: [],
  companyName: '',
  country: '',
  creditors: [],
  dayOfBirth: '',
  debtors: [],
  email: '',
  favouriteDrivers: [],
  fullName: '',
  isConfirmed: false,
  isCompany: false,
  isInvited: false,
  isDriverInCompany: false,
  isVisibleForAdmin: true,
  invitations: {},
  lastTripsHistoryObservation: null,
  lastTripsOrdersHistoryObservation: null,
  languageForEmails: DEFAULT_LANG,
  monthOfBirth: '',
  phoneNumbers: [phoneNumberModel],
  photoUrl: '',
  promoCode: '',
  referrerId: '',
  role: rolesInterface.user,
  tripsAsDispatcher: 0,
  tripsAsDriver: 0,
  tripsIds: [],
  tripOrdersLastObserveDate: '',
  tripsLastObserveDate: '',
  testimonials: {},
  uid: '',
  vehicles: [],
  vehiclesIds: [],
  yearOfBirth: '',
};

export const favouriteDriverModel = {
  idPost: '',
  uid: '',
}

export const transactionModel = {
  amount: '',
  date: '',
  dealId: '',
  exactTime: '',
  extraData: {},
  isSuccessful: true,
  payer: {},
  recipient: {},
  text: '',
  type: '',
}
//#endregion

//#region Trip
export const placeModel = {
  city: {
    default: '',
  },
  country: {
    default: '',
  },
  fullAddress: {
    default: '',
  },
  lat: '',
  lon: '',
  placeId: '',
};

export const locationIqPlaceModel = {
  city: '',
  country: '',
  fullAddress: '',
  lat: '',
  lon: '',
  placeId: '',
}

export const tripStatusesInterface = {
  canceled: 'canceled',
  completed: 'completed',
  departuring: 'departuring',
  draft: 'draft',
  inProgress: 'inProgress',
  openToSearch: 'openToSearch',
}

export const locationModel = {
  dataSource: '',
  latitude: '',
  longitude: '',
  queryTime: '',
}

export const tripPointModel = {
  arrivingTime: '',
  boardingPlacesNames: [],
  city: {
    default: '',
  },
  country: {
    default: '',
  },
  departureDateWithoutTime: '',
  departureTime: '',
  departureTimeWithoutDate: '',
  fullAddress: {
    default: '',
  },
  lat: '',
  lon: '',
  placeId: '',
  priceFromPrevious: 0,
  priceFromStart: 0
};

export const tripModel = {
  acceptedDeals: null,
  addOptions: [],
  boardingPlacesFromDriver: [],
  boardingPlacesNames: [],
  cargoDescription: '',
  completionTime: '',
  creatorUid: '',
  creatorIdPost: '',
  dateCreating: '',
  dispatcherFee: '',
  drivers: [],
  endPoint: tripPointModel,
  freePlaces: '',
  hasAnyReservations: false,
  hasGivenAwayPassengers: false,
  hasNewAction: false,
  hasPassengerUnwatchedByTransporter: true,
  hasPassengerUnwatchedByDrivers: null,
  hasReservationsFromDispatchers: false,
  hasReservationsFromDrivers: false,
  hasTransferredInPassengers: false,
  isActive: false,
  isArchived: false,
  isBoardingByDriverAddresses: false,
  isByFoot: false,
  isCancelled: false,
  isCompleted: false,
  isDraft: true,
  isForChange: false,
  isFreeSeating: true,
  isInProgress: false,
  isOpenForSearch: false,
  isPassengerAddress: true,
  isPassengerTrip: true,
  isPaymentByCash: false,
  isPaymentOnCard: false,
  isPlaceForCargo: false,
  isSeparatePlaceForLuggage: false,
  isTripWithPets: false,
  isVisibleForAdmin: true,
  isVisibleForDispatchers: false,
  location: locationModel,
  placesScheme: {},
  reservationsFromDispatchers: [],
  reservationsFromDrivers: [],
  startDate: '',
  startDateWithoutTime: '',
  startPoint: tripPointModel,
  status: '',
  totalPlaces: '',
  pricePerPerson: '',
  transferredDeals: [],
  tripPoints: [],
  tripPointsNames: [],
  vehicle: {},
  vehiclePlacesScheme: {},
};

export const addOptionModel = {
  checked: false,
  optionContent: {},
  isVisible: true,
};
//#endregion

//#region Vehicle
export const vehicleModel = {
  bodyType: '',
  brand: '',
  color: '',
  isFreeSeating: true,
  licensePlate: '',
  model: '',
  ownerUid: '',
  permissionNumber: '',
  permissionType: '',
  placesNumber: '',
  placesScheme: '',
  placesSchemeId: '',
  productionDate: '',
  registrationCountry: '',
};

export const placesSchemeModel = {
  creatorUid: '',
  description: '',
  idPost: '',
  name: '',
  rows: [],
  vehicleBrand: '',
  vehicleModel: ''
};

export const placesSchemeItemModel = {
  col: '',
  description: '',
  isDriverPlace: false,
  isFreeSpace: true,
  isOccupied: false,
  isPassengerPlace: true,
  name: '',
  placeNumber: 0,
  row: '',
  status: false,
};

export const bookingItemModel = {
  dispatcherId: '',
  endPlaceId: '',
  passengerData: '',
  startPlaceId: '',
};

export const placesSchemeRowModel = {
  row: [],
};
//#endregion

//#region Vehicle trip
export const vehicleTripModel = {
  endDate: 0,
  endPoint: placeModel,
  startDate: 0,
  startPoint: placeModel,
  vehicleId: '',
};
//#endregion

//#region Trip orders
export const tripOrderModel = {
  acceptedDeal: null,
  addOptions: [],
  cancelComment: '',
  cancelledDeals: [],
  cancellationInitiatorRole: '',
  cargoDescription: '',
  completionTime: '',
  creatorIdPost: '',
  dateCreating: '',
  dateEditing: '',
  departureAddress: '',
  departureDateWithoutTime: '',
  departureTime: '',
  departureTimeWithoutDate: '',
  dispatcherEmail: '',
  dispatcherFee: '',
  dispatcherFullName: '',
  dispatcherTestimonial: {},
  driverTestimonial: {},
  endPoint: {},
  hasAnyReservations: false,
  hasBeenWatchedByDispatcherAfterCompletion: false,
  hasBeenWatchedByTransporterAfterCompletion: false,
  hasBeenWatchedByDriversAfterCompletion: null,
  hasNewAction: false,
  hasReservationsFromDispatchers: false,
  hasReservationsFromDrivers: false,
  idPost: '',
  isActive: false,
  isArchived: false,
  isByFoot: false,
  isCancelled: false,
  isCompleted: false,
  isDraft: true,
  isFeePaid: false,
  isForChange: false,
  isInProgress: false,
  isOpenForSearch: false,
  isPaymentByCash: false,
  isPaymentOnCard: false,
  isVisibleForAdmin: true,
  isVisibleForDrivers: false,
  isPassengerAddress: false,
  isPassengerTrip: true,
  isSeparatePlaceForLuggage: false,
  isSeparatePlaceForPets: false,
  isTransferred: false,
  isTripWithPets: false,
  latestCheckOfTripOrderReservations: '',
  observedTrips: [],
  passengerName: '',
  passengerPhones: [phoneNumberModel],
  pricePerPerson: '',
  requiredNumberOfPlaces: '',
  reservationsFromDispatchers: [],
  reservationsFromDrivers: [],
  sourceTripOrder: {},
  sourceTripOrderId: '',
  startPoint: {},
  tripId: '',
  tripOrderComment: '',
  wasTransferred: false,
  wasClaimToAdminSent: false,
};
//#endregion

//#region Reservations
export const anotherFinancialConditionsModel = {
  initiatorId: '',
  dispatcherFee: '',
  pricePerPerson: '',
  isPaymentOnCard: false,
  isPaymentByCash: false,
  isForChange: false,
};

export const reservationModel = {
  anotherPlacesProposition: {
    initiatorId: '',
    proposedPlacesNumbers: [],
  },
  anotherFinancialConditions: anotherFinancialConditionsModel,
  cancelComment: '',
  dateCreating: '',
  dateEditing: '',
  dispatcherFee: '',
  hasNewActionFromTransporter: false,
  hasNewActionFromDispatcher: false,
  initiator: 'dispatcher',
  isCancelled: false,
  isForChange: false,
  isPassengerInOwnTrip: false,
  isPaymentByCash: false,
  isPaymentOnCard: false,
  isSubmittedByDispatcher: false,
  isSubmittedByDriver: false,
  isTotallyAccepted: false,
  isTransferred: false,
  originalTrip: tripModel,
  originalTripOrder: tripOrderModel,
  passengerDepartureAddress: {},
  pricePerPerson: '',
  reservedPlaces: '',
  reservedPlacesNumbers: [],
  uuid: '',
}

export const dealModel = {
  cancellationInitiatorId: '',
  cancellationInitiatorRole: '',
  dateCreating: '',
  dispatcherFee: '',
  id: '',
  initiator: 'dispatcher',
  isPassengerInOwnTrip: false,
  isCancelled: false,
  isForChange: false,
  isPaymentByCash: false,
  isPaymentOnCard: false,
  hasBeenWatchedByDispatcher: false,
  hasBeenWatchedByTransporter: false,
  hasBeenWatchedByDrivers: null,
  originalTrip: tripModel,
  originalTripOrder: tripOrderModel,
  passengerDepartureAddress: {},
  pricePerPerson: '',
  reservedPlaces: '',
  reservedPlacesNumbers: [],
}
//endregion

//#region Lang
export const dictionaryModel = {
  description: '',
  dictionary: {},
  name: '',
};
//#endregion

//#region Catalogs models
export const carColorModel = {
  defaultName: 'white',
  'uk': 'білий',
}

export const carBodyTypeModel = {
  defaultName: 'sedan',
  'uk': 'седан'
}

export const carModelItemModel = {
  id: '',
  defaultName: '',
  'en': '',
  'uk': '',
  'ru': '',
}

export const carsCatalogueItemModel = {
  id: '',
  defaultName: '',
  'en': '',
  models: [carModelItemModel]
}

export const manufacturerModelFromAPI = {
  Country: '',
  Mfr_CommonName: '',
  Mfr_ID: 0,
  Mfr_Name: '',
  VehicleTypes: [],
}

export const brandModelFromAPI = {
  Make_ID: 0,
  Make_Name: 0,
  Mfr_Name: '',
};
//#endregion

//#region Results of auth providers check
export const authProvidersCheckResultsInterface = {
  emailCanBeUsed: 'emailCanBeUsed',
  googleCanBeUsed: 'googleCanBeUsed',
  noEmail: 'noEmail',
  noGoogle: 'noGoogle',
  noProviders: 'noProviders',
};
//#endregion

//#region Notifications
export const notificationRouteModel = {
  startPoint: {
    city: {
      default: '',
    },
    country: {
      default: '',
    },
  },
  endPoint: {
    city: {
      default: '',
    },
    country: {
      default: '',
    },
  }
}

export const messageModel = {
  cost: null,
  date: null,
  dispatcher: null,
  drivers: null,
  fee: null,
  hint: null,
  introduction: null,
  passenger: null,
  route: null,
  transporter: null,
  trip: null,
};

export const notificationModel = {
  dateCreating: '',
  id: '',
  isNew: true,
  recipientId: '',
  text: '',
  textForEmail: '',
  title: '',
};
//#endregion

//#region notification Titles
export const notificationsTitlesInterface = {
  addedToTripAsDriver: 'Добавление в поездку в качестве водителя',
  bonusReceived: 'Получен бонус за приглашение',
  bonusPerReferralReceived: 'Получен бонус за сделку реферала',
  commissionTaken: 'Списана комиссия за сделку',
  dealCancellation: 'Отмена поездки',
  deletedFromTripAsDriver: 'Удаление из поездки в качестве водителя',
  depositMade: 'Пополнение счета',
  evaluationMade: 'Новый отзыв',
  exchangeAccountingRequest: 'Вам отправлен запрос на оплату по обмену',
  feePaid: 'Списание вознаграждения диспетчеру',
  feeReceived: 'Начисление вознаграждения за пассажира',
  invitationCancellation: 'Отмена приглашения',
  invitationFromCompany: 'Приглашение от компании',
  invitationRejection: 'Отклонение приглашения',
  moneyFreezed: 'Резервирование средств',
  moneyUnfreezed: 'Возврат средств',
  newPassengerForTrip: 'Новый пассажир для поездки',
  newReservationForPassenger: 'Новая бронь для пассажира',
  newTripForPassenger: 'Новая поездка для пассажира',
  notEnoughMoney: 'Недостаточно средств',
  referralRegistration: 'Регистрация реферала',
  reservationConfirmation: 'Подтверждение брони',
  reservationRejection: 'Отклонение брони',
  tripStart: 'Начало поездки',
  tripEnd: 'Завершение поездки',
};
//#endregion

//#region Admin
export const adminDataTypesInterface = {
  textPages: 'textPages',
  email: 'email',
  appStoreLink: 'appStoreLink',
  googlePlayLink: 'googlePlayLink',
  dispatcherBonusPercentage: 'dispatcherBonusPercentage',
  driverBonusPercentage: 'driverBonusPercentage',
  applicationFee: 'applicationFee',
}

//#region Admin statistic data
export const statisticDataModel = {
  allUsersNumber: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  commission: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  trips: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  tripsCancelled: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  tripsCompleted: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  tripsOrders: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  tripsOrdersCancelled: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  tripsOrdersCompleted: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  usersAsAdmin: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  usersAsCompany: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  usersAsDriver: {
    title: '',
    growth: 0,
    quantity: 0,
  },
  usersAsIndividual: {
    title: '',
    growth: 0,
    quantity: 0,
  },
};

export const statisticQueryTypesInterface = {
  allUsers: 'allUsers',
  adminUsers: 'adminUsers',
  companyUsers: 'companyUsers',
  driverUsers: 'driverUsers',
  individualUsers: 'individualUsers',
  allTrips: 'allTrips',
  tripsCompleted: 'tripsCompleted',
  tripsCancelled: 'tripsCancelled',
  allTripsOrders: 'allTripsOrders',
  tripsOrdersCompleted: 'tripsOrdersCompleted',
  tripsOrdersCancelled: 'tripsOrdersCancelled',
}
//#endregion

//#region Admin catalogs
export const catalogsNamesInterface = {
  bodyTypes: 'bodyTypes',
  brands: 'brands',
  models: 'models',
  colors: 'colors',
  options: 'options',
}
//#endregion

//#region Text pages
export const textPagesTypesInterface = {
  cookiesPolicy: 'cookiesPolicy',
  bonusesPolicy: 'bonusesPolicy',
  rulesAndPolicy: 'rulesAndPolicy',
  referralProgram: 'referralProgram',
  removalPolicy: 'removalPolicy',
  termsOfUse: 'termsOfUse',
}
export const textPagesTypesNamesInterface = {
  rulesAndPolicy: 'Политика конфиденциальности',
  referralProgram: 'Реферальная программа',
  bonusesPolicy: 'Бонусы',
  cookiesPolicy: 'Политика куки',
  removalPolicy: 'Политика удаления',
  termsOfUse: 'Пользовательское соглашение',
}

export const textPageModel = {
  dataType: adminDataTypesInterface.textPages,
  dateCreating: '',
  dateEditing: '',
  images: [],
  text: {
    en: '',
    uk: '',
  },
  title: {
    en: '',
    uk: '',
  },
  type: '',
}
//#endregion

//#region App settings
export const appSettingsModel = {
  email: {
    dataType: adminDataTypesInterface.email,
    text: '',
  },
  applicationFee: {
    dataType: adminDataTypesInterface.applicationFee,
    text: '',
  },
  appStoreLink: {
    dataType: adminDataTypesInterface.appStoreLink,
    text: '',
  },
  googlePlayLink: {
    dataType: adminDataTypesInterface.googlePlayLink,
    text: '',
  },
  dispatcherBonusPercentage: {
    dataType: adminDataTypesInterface.dispatcherBonusPercentage,
    text: '',
  },
  driverBonusPercentage: {
    dataType: adminDataTypesInterface.driverBonusPercentage,
    text: '',
  },
}
//#endregion
//#endregion

//#region Pagination
export const entitiesNumberInterface = {
  notifications: 15,
  tripsOrdersCards: 9,
  tripsOrdersCardsMiddle: 6,
  tripsOrdersCardsReduced: 3,
  tripsCards: 6,
  tripsCardsReduced: 3,
  reservationCards: 6,
  reservationCardsReduced: 3,
  counterpartyCards: 15,
  counterpartyCardsReduced: 9,
  favouriteDriversCards: 18,
  companyDriversCards: 15,
  catalogs: 8,
  catalogsReduced: 4,
  languages: 8,
  withdrawals: 36,
  vehicleTrips: 15,
  driverTrips: 15,
  invitations: 18,
}
//#endregion

//#region Withdrawals
export const withdrawalStatusesInterface = {
  approved: 'approved',
  cancelled: 'cancelled',
  created: 'created',
  pending: 'pending',
}
//#endregion

//#region Drivers invitations
export const invitationModel = {
  id: '',
  companyId: '',
  driverId: '',
  dateCreating: '',
  hasResponse: false,
  isAccepted: false,
}
//#endregion

//#region Superadmin
export const superAdmin = {
  balanceAccount: 'PsZCOiVONU8Tlospa1xO',
  email: 'international.driver.eu@gmail.com',
  fullName: 'admin driver',
  idPost: 'VZPUuUFlbYuBSgLRkq0L',
  uid: 'xMHIUzqxpaXlTFpoWbOg6dhf2Fu1',
}
//#endregion

//#region Places API
export const placesTypes = {
  building: 18,
  city: 10,
  street: 16,
}
//#endregion

//#region My trips types
export const myTripsTypes = {
  actual: 'actual',
  archived: 'archived',
}
//#endregion
