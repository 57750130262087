import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  createLinkToInviteDriver,
  getPossibleAuthOptionsForEmail,
  sendMessageAboutInvitationToCompany,
  validateEmailField,
} from "../utils/helpers";
import FormInputAlertText from "./FormInputAlertText";
import {
  getCollectionWhereKeyValue,
  updateFieldInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  authProvidersCheckResultsInterface,
  collectionsInterface, invitationModel, userModel
} from "../utils/models";
import Loader from "./Loader";
import ModalNotification from "../modals/ModalNotification";
import ModalInviteDriver from "../modals/ModalInviteDriver";
import {v4 as uuidv4} from 'uuid';
import useTranslation from "../hooks/useTranslation";
import '../styles/InviteDriver.scss';

const InviteDriver = ({addClasses = '', goBack}) => {
  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Добавление водителя');
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Email and name data
  const [emailData, setEmailData] = useState('');
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Modal for invitation confirmation
  const [isInvitationModal, setIsInvitationModal] = useState(false);
  //#endregion

  //#region Errors
  const [isEmailFieldError, setIsEmailFieldError] = useState(false);
  const [emailFieldErrorText, setEmailFieldErrorText] = useState('');
  //#endregion

  //#region Handle invite driver click
  const inviteUserAndAddToInvitedList = async (user, driverEmail, driverId,
    invitationDate = '') => {
    const newDriversArray = [...user.companyDrivers, {
      supposedEmail: driverEmail,
    }];

    await updateFieldInDocumentInCollection(collectionsInterface.users,
      user.idPost, 'companyDrivers', newDriversArray);

    let emailTextForNonExisting = '';

    if (!driverId) {
      const link = createLinkToInviteDriver(user, driverEmail);

      emailTextForNonExisting = `${translate('Компания')} ${user.companyName} ${
        translate('приглашает Вас в приложение IntDriver')} \n` + `${translate(
        'Чтобы зарегистрироваться, перейдите по ссылке')} ${link}`;
    }

    await sendMessageAboutInvitationToCompany(user
      ?.companyName || user?.fullName || '', driverId, invitationDate,
      driverEmail, emailTextForNonExisting);

    setUser(state => ({
      ...state,
      companyDrivers: newDriversArray,
    }));
  }

  const handleInviteDriverClick = async (user, driverEmail) => {
    setIsLoading(true);

    try {
      if (!validateEmailField(driverEmail)) {
        setIsEmailFieldError(true);
        setEmailFieldErrorText('Неверный формат почты');

        return;
      }

      const isAlreadyInList = user.companyDrivers.some(driver => (driver
        .email === driverEmail || driver.supposedEmail === driverEmail));

      if (isAlreadyInList) {
        setIsLoading(false);
        setIsNotification(true)
        setNotificationTitle('Уведомление');
        setNotificationMessage('Вы уже приглашали водителя с указанным email');

        return;
      }

      const checkRegistration = await getPossibleAuthOptionsForEmail(driverEmail);

      if (checkRegistration !== authProvidersCheckResultsInterface.noProviders) {
        const drivers = await getCollectionWhereKeyValue(collectionsInterface
          .users, 'email', driverEmail);

        if (drivers[0]?.isDriverInCompany) {
          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Нельзя пригласить водителя, работающего в' +
            ' другой компании');

          return;
        }

        setDriver(drivers[0]);
        setIsInvitationModal(true);

        return;
      }

      await inviteUserAndAddToInvitedList(user, driverEmail);
      goBack();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Confirm of cancel invitation of existing driver
  const [driver, setDriver] = useState(userModel);

  const confirmDriverInvitation = async () => {
    try {
      setIsLoading(true);

      const id = uuidv4();

      const invitation = {
        ...invitationModel,
        id,
        companyId: user.idPost,
        driverId: driver.idPost,
        dateCreating: Date.now(),
      }

      await updateFieldInDocumentInCollection(collectionsInterface.users,
        driver.idPost, `invitations.${id}`, invitation);

      await inviteUserAndAddToInvitedList(user, emailData, invitation.driverId,
        invitation.dateCreating);

      setIsInvitationModal(false);

      goBack();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }

  const cancelDriverInvitation = () => {
    setIsInvitationModal(false);
    setDriver(userModel);
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`InviteDriver ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <form className={'InviteDriverForm'}>
            <div className={'InviteDriverForm-Item'}>
              <div className={'InviteDriverForm-InputContainer'}>
                <img
                  src={'/img/message-icon.svg'}
                  className={'InviteDriverForm-InputIcon EnvelopeIcon'}
                  alt={'Envelope icon'}
                />

                <input
                  className={'FormControl InviteDriverForm-Input'}
                  type={'text'}
                  placeholder={translate('Почта')}
                  onChange={(event) => setEmailData(event.target.value)}
                  value={emailData}
                />
              </div>

              {isEmailFieldError &&
                <FormInputAlertText
                  text={emailFieldErrorText}
                  setIsVisible={setIsEmailFieldError}
                />
              }
            </div>

            <div className={'InviteDriverForm-Item'}>
              <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => handleInviteDriverClick(user, emailData)}
              >
                {translate('Пригласить')}
              </button>
            </div>

            {goBack &&
              <div className={'InviteDriverForm-Item'}>
                <button
                  type={'button'}
                  className={'RegularButton RegularButton_Contrast'}
                  onClick={() => goBack()}
                >
                  {translate('Вернуться')}
                </button>
              </div>
            }
          </form>
        </div>
      }

      {isNotification &&
        <ModalNotification
          title={notificationTitle}
          message={notificationMessage}
          showModal={isNotification}
          setShowModal={setIsNotification}
        />
      }

      {isInvitationModal &&
        <ModalInviteDriver
          showModal={isInvitationModal}
          setShowModal={setIsInvitationModal}
          confirmInvitation={confirmDriverInvitation}
          cancelInvitation={cancelDriverInvitation}
          driver={driver}
          isLoading={isLoading}
        />
      }
    </>
  );
  //#endregion
};

export default InviteDriver;
