import React from 'react';
import {Modal} from "react-bootstrap";
import {deletePageHandler} from "../utils/helpers";
import useTranslation from "../hooks/useTranslation";

const ModalConfirmPageDelete = ({
  showModal,
  setShowModal = () => {},
  setPagesToDisplay = () => {},
  addClasses = '',
  page,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Delete confirmation
  const confirmPageDelete = async (page, setPagesToDisplay, setShowModal) => {
    try {
      await deletePageHandler(page, setPagesToDisplay);

      setShowModal(false);
    } catch (error) {
      return error;
    }
  };
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
        setShowModal(false);
      }}
      className={`ModalConfirmPageDelete ${addClasses}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className={'RegularTitle RegularTitle_Small'}>
            {translate('Уведомление')}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {translate('Вы уверены, что хотите удалить страницу?')}
      </Modal.Body>
      <Modal.Footer>
        <div className={'FlexBlock ModalAddDriverAddress-Item'}>
          <button
            type={'button'}
            className={'RegularButton FlexBlock-HorizontalItem ' +
              'FlexBlock-HorizontalItem_W60'}
            onClick={() => confirmPageDelete(page, setPagesToDisplay,
              setShowModal)}
          >
            {translate('Да')}
          </button>

          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast ' +
              'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
            onClick={() => {
              setShowModal(false)
            }}
          >
            {translate('Нет')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalConfirmPageDelete;
