import React from 'react';
import ReservationDetails from "./ReservationDetails";

const HistoryTripOrderDetails = ({
  addClasses = '',
  isDispatcher = true,
  updateAfterPaymentConfirmationOrClaimCreation = () => {},
}) => {
  //#region Render
  return (
    <>
      <div className={`HistoryTripOrderDetails ${addClasses}`}>
        <ReservationDetails
          isDispatcher={isDispatcher}
          addClasses={'App-Screen_Narrow'}
          updateAfterPaymentConfirmationOrClaimCreation={
            updateAfterPaymentConfirmationOrClaimCreation}
        />
      </div>
    </>
  );
  //#endregion
};

export default HistoryTripOrderDetails;
