import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import CounterPartyDetails from "./CounterPartyDetails";
import DisplayCreatedTripsOrders from "./DisplayCreatedTripsOrders";
import {useNavigate} from "react-router-dom";
import {entitiesNumberInterface} from "../utils/models";
import '../styles/CounterPartyData.scss';
import useTranslation from "../hooks/useTranslation";

const DispatcherDataScreen = ({
  addClasses = '',
  tripsOrders = [],
  handleTripOrderCardClick = () => {},
  counterparty,
  isLoading = true,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Обмен');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!counterparty?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`CounterPartyData ${addClasses}`}>
        <div className={'CounterPartyData-Item CounterPartyData-DetailsBlock'}>
          <CounterPartyDetails
            counterparty={counterparty}
            isCreditor={false}
            addClasses={'CounterPartyData-Details'}
          />
        </div>

        <div className={'CounterPartyData-Item'}>
          <h3 className={'RegularTitle RegularTitle_Small'}>
            {translate('Активные заявки пользователя')}
          </h3>

          <DisplayCreatedTripsOrders
            addClasses={'CardsListScreen_Reduced'}
            isReducedLength={true}
            tripsOrdersToDisplay={tripsOrders}
            isReadOnly={true}
            handleTripOrderCardClick={handleTripOrderCardClick}
            isFromExchange={true}
            isNotepad={false}
            cardsNumber={entitiesNumberInterface.tripsOrdersCardsReduced}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
  //#endregion
};

export default DispatcherDataScreen;
