import React, {useContext, useState} from 'react';
import {Modal} from "react-bootstrap";
import {AppContext} from "../context/appContext";
import {
  getTransactionTypeName,
  saveTransactionFromAdmin,
  transformTransactionTypesToSelectOptions
} from "../utils/helpers";
import {
  transactionModel,
  transactionsTypesInterface,
  userModel
} from "../utils/models";
import Select from "react-select";
import FormInputAlertText from "../components/FormInputAlertText";
import LoaderSmall from "../components/LoaderSmall";
import '../styles/ModalAddDriverAddress.scss';
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";

const ModalAddDriverAddress = ({
  showModal,
  setShowModal,
  addClasses = '',
  editingUser = userModel,
  setBalanceAccount = () => {},
  isLoading = false,
  setIsLoading = () => {},
}) => {

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Transaction data
  const [transactionData, setTransactionData] = useState({
    ...transactionModel,
    type: {
      label: translate(getTransactionTypeName(transactionsTypesInterface.bonuses)),
      value: transactionsTypesInterface.bonuses,
    }
  });

  const updateTransactionData = (fieldName, newValue) => {
    setTransactionData(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  }
  //#endregion

  //#region Errors
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
        setShowModal(false);
      }}
      className={`ModalAddTransaction ${addClasses}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className={'RegularTitle RegularTitle_Small'}>
            {translate('Добавить транзакцию')}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? <LoaderSmall />
          :
          <form
            onSubmit={(event) => saveTransactionFromAdmin(event,
              transactionData, user, editingUser, setShowModal,
              setBalanceAccount, setIsError, setErrorMessage, setIsLoading)}
          >
            <div>
              <label htmlFor={'text'} className={'DataLabel'}>
                {translate('Название транзакции')}
              </label>

              <input
                id={'text'}
                type={'text'}
                className={'FormControl'}
                onChange={(event) => updateTransactionData('text',
                  event.target.value)}
                placeholder={translate('Название транзакции')}
                value={transactionData.title}
                required={true}
              />
            </div>

            <div className={'PaddingBlock'}></div>

            <div className={'InputWithAlert'}>
              <label htmlFor={'title'} className={'DataLabel'}>
                {translate('Сумма транзакции')}
              </label>

              <NumberInputWithValidation
                externalChangeHandler={updateTransactionData}
                fieldName={'amount'}
                id={'title'}
                placeholder={translate('Сумма транзакции')}
                value={transactionData.amount}
              />

              {isError &&
                <FormInputAlertText
                  text={errorMessage}
                  setIsVisible={setIsError}
                  visibilityTime={3}
                />
              }
            </div>

            <div className={'PaddingBlock'}></div>

            <div>
              <label htmlFor={'text'} className={'DataLabel'}>
                {translate('Тип транзакции')}
              </label>

              <Select
                classNames={{
                  control: (state) =>
                    state.isFocused ? 'FormControl AddTripForm-Select' : 'FormControl AddTripForm-Select',
                }}
                options={transformTransactionTypesToSelectOptions({
                    accountDeposit: 'accountDeposit',
                    bonuses: 'bonuses',
                    commissionTaking: 'commissionTaking',
                  }, translate)}
                placeholder={translate('Тип транзакции')}
                value={{
                  value: transactionData?.type?.value,
                  label: transactionData?.type?.label,
                }}
                onChange={(event) => updateTransactionData('type',
                  event)}
              />
            </div>

            <div className={'PaddingBlock'}></div>

            <button
              type={'submit'}
              className={'RegularButton'}
            >
              {translate('Сохранить транзакцию')}
            </button>
          </form>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className={'FlexBlock ModalAddDriverAddress-Item'}>
          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast ' +
              'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
            onClick={() => {
              setShowModal(false)
            }}
          >
            {translate('Вернуться')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
  //#endregion
};

export default ModalAddDriverAddress;
