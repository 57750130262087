import React, {useState, useContext, useEffect, useRef} from 'react';
import PhoneInput from "react-phone-input-2";
import {AppContext} from "../context/appContext";
import {
  authProvidersCheckResultsInterface, balanceAccountModel,
  collectionsInterface, pagesInterface,
  phoneNumberModel,
  registrationErrorsTypesInterface,
  rolesInterface,
  userModel
} from "../utils/models";
import {Link, useNavigate} from "react-router-dom";
import {
  createBirthDate,
  createYearsArray,
  getEmptyRegistrationFields,
  getErrorText,
  getNewPhoneObj,
  getPossibleAuthOptionsForEmail,
  getRegistrationErrorObjectForEmptyFields,
  getRegistrationWrongFieldsObject,
  isDateExists,
  transformStringsArrayToSelectOptions,
} from "../utils/helpers";
import {
  getDocInCollection, setDocumentToCollection,
  signUp,
  updateFieldInDocumentInCollection, updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {FaTrash} from 'react-icons/fa';
import ModalNotification from "../modals/ModalNotification";
import Select from 'react-select';
import {auth} from '../utils/firebaseConfigAndFunctions';
import AddPhone from "./AddPhone";
import SideRegisterServices from "./SideRegisterServices";
import ChangePassword from "./ChangePassword";
import MessengersRow from "./MessengersRow";
import 'react-html5-camera-photo/build/css/index.css';
import RadioFieldSet from "../elements/RadioFieldSet";
import Loader from "./Loader";
import FormInputAlertText from "./FormInputAlertText";
import {updateUserInCompany} from "../utils/userDataService";
import {languageService} from "../utils/languageService";
import {
  PHONE_NUMBER_MIN_LENGTH,
  PHONE_NUMBERS_MAX_QUANTITY
} from "../utils/data";
import useTranslation from "../hooks/useTranslation";
import LocationIq from "./LocationIq";
import '../styles/RegistrationForm.scss';
import AgreementWithPolicyControls from "./AgreementWithPolicyControls";

const RegistrationForm = ({
  isRegistration = true,
  isEditMode = false,
  setIsEditMode = () => {},
  isAdmin = false,
  currentUser = undefined,
  addClasses = '',
  setIsVehicleScreen = () => {},
  invitationData,
  referrerData,
  setUsers = () => {},
}) => {
  //#region Get language from context, manage languages
  const {languages} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Set title
  useEffect(() => {
    if (isRegistration) {
      setScreenTitle('Регистрация');
    } else {
      setScreenTitle('Редактирование данных');
    }
  }, []);
  //#endregion

  //#region Loaders
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Get userCompleteness
  const {isUserDataComplete, setIsUserDataComplete} = useContext(AppContext);
  //#endregion

  //#region User data
  const [initialUserData, setInitialUserData] = useState(userModel);
  const [userData, setUserData] = useState(userModel);

  useEffect(() => {
    if (isAdmin) {
      setUserData(currentUser);
    } else {
      setUserData(user);
    }
  }, []);
  //#endregion

  //#region Refs
  const fullName = useRef(null);
  const birth = useRef(null);
  const phone = useRef(null);
  const email = useRef(null);
  //#endregion

  //#region Get auth providers (if not registration)
  const [providers, setProviders] = useState('');

  useEffect(() => {
    if (!isRegistration && !isAdmin) {
      getPossibleAuthOptionsForEmail(user.email).then(result => {
        setProviders(result);
      }).catch(() => {});
    }
  }, [])
  //#endregion

  //#region Error data
  const [errorData, setErrorData] = useState({});
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Manage password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
  //#endregion

  //#region Handling form fields editing
  const [updatedFields, setUpdatedFields] = useState({});

  const phoneInputChangeValueHandler = (newValue, currentPhoneIndex, type) => {
    const result = userData.phoneNumbers.map((phone, index) =>
      index === currentPhoneIndex ? getNewPhoneObj(phone, newValue, type) : phone);

    setUserData(user => ({
      ...user,
      phoneNumbers: result,
    }));

    if (!isRegistration) {
      setUpdatedFields(fields => ({
        ...fields,
        phoneNumbers: result,
      }));
    }
  }

  const deletePhoneHandler = (currentPhoneIndex) => {
    const result = [...userData.phoneNumbers];
    result.splice(currentPhoneIndex, 1);
    setUserData(user => ({
      ...user, phoneNumbers: [...result]
    }));

    if (!isRegistration) {
      setUpdatedFields(fields => ({
        ...fields,
        phoneNumbers: [...result],
      }));
    }
  }

  const addNewPhoneHandler = () => {
    setUserData(user => ({
      ...user, phoneNumbers: [...user.phoneNumbers, phoneNumberModel]
    }));
    setIsMainScreen(false);
    setIsAddPhoneScreen(true);
  }

  const changeInputValueHandler = (newValue, inputName) => {
    switch (inputName) {
      case 'phoneNumbers':
        setUserData(user => ({
          ...user,
          phoneNumbers: [...user.phoneNumbers, newValue],
        }));

        if (!isRegistration) {
          setUpdatedFields(user => ({
            ...user,
            phoneNumbers: [...user.phoneNumbers, newValue],
          }))
        }
        return;
      case 'place':
        setUserData(user => ({
          ...user,
          country: newValue.country,
          city: newValue.city,
          fullAddress: newValue.fullAddress,
        }));

        if (!isRegistration) {
          setUpdatedFields(user => ({
            ...user,
            country: newValue.country,
            city: newValue.city,
            fullAddress: newValue.fullAddress,
          }))
        }
        return;
      case 'isCompany':
        if (newValue === translate('Компания')) {
          setUserData(user => ({
            ...user,
            isCompany: true,
          }));

          if (!isRegistration) {
            setUpdatedFields(user => ({
              ...user,
              isCompany: true,
            }))
          }
        } else {
          setUserData(user => ({
            ...user,
            isCompany: false,
            companyName: '',
          }));

          if (!isRegistration) {
            setUpdatedFields(user => ({
              ...user,
              isCompany: false,
              companyName: '',
            }))
          }
        }
        return;
    }

    setUserData(user => ({
      ...user,
      [inputName]: newValue,
    }));

    if (!isRegistration) {
      setUpdatedFields(user => ({
        ...user,
        [inputName]: newValue,
      }));
    }
  }
  //#endregion

  //#region Data arrays
  const [days, setDays] = useState([]);
  const [months] = useState(transformStringsArrayToSelectOptions(
    ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']));
  const [years, setYears] = useState([]);

  const createDaysArray = () => {
    const daysArr = [];

    for (let i = 1; i <= 31; i++) {
      if (i < 10) {
        daysArr.push('0' + i);
      } else {
        daysArr.push(i);
      }
    }

    return transformStringsArrayToSelectOptions(daysArr);
  }
  //#endregion

  //#region Setting initial user data, data for selects
  useEffect(() => {
    setInitialUserData((isAdmin ? {...currentUser} : {...user}));

    setDays(createDaysArray());

    setYears(createYearsArray(1960, new Date().getFullYear() - 18));
  }, []);
  //endregion

  //#region Handling form submit
  const createBirthDateAsTimeStamp = (day, month, year) => {
    const checkResult = isDateExists(day, month, year);

    if (!checkResult) {
      return null;
    }

    const dateAsString = createBirthDate(day, month, year);

    const chosenDate = new Date(dateAsString).getTime();

    return chosenDate;
  }

  const registrationSubmitHandler = async (event, user) => {
    event.preventDefault();

    setIsLoading(true);

    const birthDay =  createBirthDateAsTimeStamp(user.dayOfBirth, user.monthOfBirth,
      user.yearOfBirth);

    const emptyFields = getEmptyRegistrationFields({
      ...user,
      birthDay,
    });

    let errorObject = {};

    if (emptyFields.length > 0) {
      errorObject = getRegistrationErrorObjectForEmptyFields(emptyFields);

      switch (true) {
        case emptyFields.includes('fullName'):
          fullName?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case (emptyFields.includes('dayOfBirth') || emptyFields
          .includes('monthOfBirth') || emptyFields.includes('yearOfBirth')):
          birth?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case emptyFields.includes('phoneNumbers'):
          phone?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case emptyFields.includes('email'):
          email?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
      }

      setErrorData(errorObject);

      setIsLoading(false);

      return;
    }

    errorObject = getRegistrationWrongFieldsObject({
      ...user,
      birthDay,
    });

    if ((Object.values(errorObject)).some(field => field.isError)) {
      setIsLoading(false);

      switch (true) {
        case errorObject?.fullName?.isError:
          // fullName?.current?.scrollIntoView({ behavior: 'smooth' });
          window.scrollTo({top: 0});
          break;
        case errorObject?.birthDay?.isError:
          birth?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case errorObject?.phoneNumbers?.isError:
          phone?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
        case errorObject?.email?.isError:
          email?.current?.scrollIntoView({ behavior: 'smooth' });
          break;
      }

      setErrorData(errorObject);

      return;
    }

    setErrorData({});

    if (isRegistration) {
      const providersResult = await getPossibleAuthOptionsForEmail(user.email);

      if (providersResult !== authProvidersCheckResultsInterface.noProviders) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage(
          'Пользователь с такой электронной почтой уже существует');

        setIsLoading(false);

        return;
      }

      if (!user?.rulesAccepted) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage(
          'Подтвердите согласие с правилами и политикой конфиденциальности');

        setIsLoading(false);
        return;
      }
    }

    try {
      if (isRegistration) {
        setUser({
          ...user,
          birthDay: createBirthDateAsTimeStamp(user.dayOfBirth,
            user.monthOfBirth, user.yearOfBirth),
        });

        if (invitationData?.inviterId) {
          if (invitationData.driverEmail !== user.email) {
            setIsNotification(true);
            setNotificationTitle('Уведомление');
            setNotificationMessage('При регистрации необходимо использовать ту же почту, на которую пришло приглашение');

            return;
          }

          const inviter = await getDocInCollection(collectionsInterface.users,
            invitationData.inviterId);

          const authResult = await signUp(user.email, user.password);

          const currentDate = new Date();

          const accountData = {
            ...balanceAccountModel,
            ownerUid: authResult?.user?.uid,
          };

          const savedAccount = await setDocumentToCollection(
            collectionsInterface.balanceAccounts, accountData);

          const userToSave = {
            ...user,
            attachedToCompany: invitationData.inviterId,
            balanceAccount: savedAccount?.result?.id || '',
            birthDay: createBirthDateAsTimeStamp(user.dayOfBirth,
              user.monthOfBirth, user.yearOfBirth),
            isInvited: true,
            isDriverInCompany: true,
            tripOrdersLastObserveDate: currentDate,
            tripsLastObserveDate: currentDate,
            uid: authResult.user.uid,
          };

          delete userToSave.password;
          delete userToSave.confirmPassword;

          const newUser = await setDocumentToCollection(
            collectionsInterface.users, userToSave);

          const newDriversArr = inviter.companyDrivers.map(driver =>
            driver.supposedEmail === invitationData.driverEmail ? {
              ...driver,
              isFulfilledRegistration: true,
              uid: authResult.user.uid,
              email: user.email,
              fullName: user.fullName,
              phoneNumbers: user.phoneNumbers,
              photoUrl: user.photoUrl,
              idPost: newUser.result.id,
            } : driver);

          await updateFieldInDocumentInCollection(collectionsInterface.users,
            invitationData.inviterId, 'companyDrivers', newDriversArr);

          navigate('/profile');
        } else {
          setIsVehicleScreen(true);
        }
      } else if (Object.keys(updatedFields).length > 0) {
        let userToSave = {
          ...updatedFields,
          dateEditing: Date.now(),
        };

        if (updatedFields.hasOwnProperty('dayOfBirth')
          || updatedFields.hasOwnProperty('monthOfBirth')
          || updatedFields.hasOwnProperty('monthOfBirth')) {
          userToSave = {
            ...userToSave,
            birthDay: createBirthDateAsTimeStamp(user.dayOfBirth,
              user.monthOfBirth, user.yearOfBirth),
          }
        }

        await updateFieldsInDocumentInCollection(collectionsInterface.users,
          user.idPost, userToSave);

        if (user.isDriverInCompany && user.attachedToCompany) {
          await updateUserInCompany(user.attachedToCompany, user.idPost,
            updatedFields);
        }

        if (!isAdmin) {
          setUser({
            ...user,
            birthDay: createBirthDateAsTimeStamp(user.dayOfBirth,
              user.monthOfBirth, user.yearOfBirth),
          });

          setIsUserDataComplete(true);
          setIsEditMode(false);
        } else {
          setUsers(users => users.map(curUser => curUser.idPost === user.idPost
            ? user : curUser));
        }

        navigate(-1);
      } else {
        navigate(-1);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Ошибка');
      setNotificationMessage('Что-то пошло не так');
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Manage displaying screens
  const [isMainScreen, setIsMainScreen] = useState(true);
  const [isAddPhoneScreen, setIsAddPhoneScreen] = useState(false);
  const [isChangePasswordScreen, setIsChangePasswordScreen] = useState(false);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div className={`RegistrationFormContainer ${addClasses}`}>
            <>
              <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
                {translate(screenTitle)}
              </h2>

              {isMainScreen ? (
                <>
                  <form
                    className={'RegistrationForm RegistrationFormContainer-RegistrationForm'}
                    onSubmit={
                      (event) =>
                        registrationSubmitHandler(event, userData)
                    }
                  >
                    {((auth.currentUser && !isUserDataComplete) && !isAdmin) &&
                      <h3
                        className={'RegularTitle RegularTitle_Tiny ColoredText'}
                      >
                        {translate('Для продолжения работы с приложением ' +
                          'необходимо заполнить все данные')}
                      </h3>
                    }

                    {isAdmin &&
                      <div className={'RegistrationForm-Item'}>
                        <label
                          className={'ShowProfileData-Label'}
                          htmlFor="userId"
                        >
                          {`${translate('Id')} / ${translate('Рейтинг')}`}
                        </label>

                        <input
                          disabled={true}
                          id="userId"
                          className={'FormControl ShowProfileData-Input'}
                          name={'userId'}
                          type={'text'}
                          value={`${userData.idPost} / ${userData.averageMark}`}
                        />
                      </div>
                    }

                    <div className={'RegistrationForm-Item'}  ref={birth}>
                      <label
                        className={'RegistrationForm-Label'}
                        htmlFor="item01"
                      >
                        {translate('ФИО')}
                      </label>

                      <input
                        id="item01"
                        placeholder={translate('ФИО')}
                        className={(errorData?.fullName?.isError && (!userData
                          ?.fullName || errorData?.fullName?.errorType ===
                          registrationErrorsTypesInterface.wrongValue))
                          ? 'FormControl FormControl_Error ' +
                          'RegistrationForm-Input' : 'FormControl' +
                          ' RegistrationForm-Input'}
                        name={'fullName'}
                        type={'text'}
                        value={userData.fullName}
                        onChange={(event) => changeInputValueHandler(
                          event.target.value, 'fullName')}
                      />

                      {(errorData?.fullName?.isError && (!userData?.fullName ||
                        errorData?.fullName?.errorType ===
                        registrationErrorsTypesInterface.wrongValue)) &&
                        <FormInputAlertText
                          text={getErrorText(errorData, 'fullName')}
                        />
                      }
                    </div>

                    {(userData?.role === rolesInterface.user &&
                        (!invitationData?.inviterId && !userData.attachedToCompany)) &&
                      <div className={'RegistrationForm-Item'}>
                        <RadioFieldSet
                          elementName={'isCompany'}
                          elementText={`${translate('Компания')}_${translate('Работаю сам')}`}
                          inputChangeHandler={changeInputValueHandler}
                          checkedInput={userData?.isCompany ?
                            translate('Компания')
                            :
                            translate('Работаю сам')
                          }
                        />
                      </div>
                    }

                    {userData?.isCompany &&
                      <div className={'RegistrationForm-Item'}>
                        <label
                          className={'RegistrationForm-Label'}
                          htmlFor="companyName"
                        >
                          {translate('Название компании')}
                        </label>

                        <input
                          id="companyName"
                          placeholder={translate('Название компании')}
                          className={'FormControl RegistrationForm-Input'}
                          name={'companyName'}
                          type={'text'}
                          value={userData.companyName}
                          onChange={(event) => changeInputValueHandler(
                            event.target.value, 'companyName')}
                        />
                      </div>
                    }

                    <div className={'RegistrationForm-Item'} ref={phone}>
                      <label className={'RegistrationForm-Label'}>
                        {translate('Дата рождения')}
                      </label>

                      <div className={'InputsRowBlock'}>
                        <Select
                          id={'item03-01'}
                          classNames={{
                            control: () =>
                              (errorData?.dayOfBirth?.isError && (!userData
                                .dayOfBirth || errorData?.birthDay?.errorType ===
                                registrationErrorsTypesInterface.wrongValue))
                                ? 'FormControl RegistrationForm-DateSelect' +
                                ' RegistrationForm-DateSelect_Error'
                                : 'FormControl RegistrationForm-DateSelect'
                          }}
                          options={days}
                          placeholder={translate('День')}
                          onChange={(event) => changeInputValueHandler(
                            event.value, 'dayOfBirth')}
                          value={userData.dayOfBirth ? {
                            label: userData.dayOfBirth,
                            value: userData.dayOfBirth,
                          } : ''}
                        />

                        <Select
                          id={'item03-02'}
                          classNames={{
                            control: () =>
                            (errorData?.monthOfBirth?.isError && (!userData
                            .monthOfBirth || errorData?.birthDay?.errorType ===
                              registrationErrorsTypesInterface.wrongValue))
                              ? 'FormControl RegistrationForm-DateSelect' +
                            ' RegistrationForm-DateSelect_Error' : 'FormControl' +
                            ' RegistrationForm-DateSelect'
                          }}
                          options={months}
                          onChange={(event) => changeInputValueHandler(
                            event.value, 'monthOfBirth')}
                          placeholder={translate('Месяц')}
                          value={userData.monthOfBirth ? {
                            label: userData.monthOfBirth,
                            value: userData.monthOfBirth,
                          } : ''}
                        />

                        <Select
                          id={'item03-03'}
                          classNames={{
                            control: () =>
                              (errorData?.yearOfBirth?.isError && (!userData
                                .yearOfBirth || errorData?.birthDay?.errorType ===
                                registrationErrorsTypesInterface.wrongValue))
                                ? 'FormControl RegistrationForm-DateSelect' +
                                ' RegistrationForm-DateSelect_Error'
                                : 'FormControl RegistrationForm-DateSelect'
                          }}
                          options={years}
                          placeholder={translate('Год')}
                          onChange={(event) => changeInputValueHandler(
                            event.value, 'yearOfBirth')}
                          value={userData.yearOfBirth ? {
                            label: userData.yearOfBirth,
                            value: userData.yearOfBirth,
                          } : ''}
                        />
                      </div>

                      {(errorData?.birthDay?.isError && ((!userData
                          ?.dayOfBirth || !userData.monthOfBirth || !userData
                          .yearOfBirth) || (errorData?.birthDay?.errorType ===
                            registrationErrorsTypesInterface.wrongValue))) &&
                        <FormInputAlertText
                          text={getErrorText(errorData, 'birthDay')}
                        />
                      }
                    </div>

                    <div className={'RegistrationForm-Item'} ref={email}>
                      <label
                        className={'RegistrationForm-Label'}
                      >
                        {translate('Номер телефона')}
                      </label>

                      {userData?.phoneNumbers &&
                        <>
                          {userData?.phoneNumbers?.map((phone, index) => (
                            <div
                              key={index}
                              className={
                                index !== 0 ? 'RegistrationForm-PhoneItem' : ''
                              }
                            >
                              <div
                                className={errorData?.phoneNumbers?.isError &&
                                  !phone?.phoneNumber ? 'PhoneRow' +
                                  ' PhoneRow_Error'
                                  : 'PhoneRow'}
                              >
                                <PhoneInput
                                  name={'PhoneNumbers'}
                                  country={'ua'}
                                  onChange={
                                    (event) => phoneInputChangeValueHandler(
                                      event, index, 'number')}
                                  specialLabel={''}
                                  value={phone?.phoneNumber}
                                />

                                {index > 0 &&
                                  <div className={'PhoneAddRemoveButtons'}>
                                    {index !== 0 &&
                                      <button
                                        type={'button'}
                                        className={'DelButton'}
                                        onClick={() => deletePhoneHandler(index)}
                                      >
                                        <FaTrash/>
                                      </button>}
                                  </div>
                                }
                              </div>

                              <MessengersRow
                                index={index}
                                phone={phone}
                                phoneInputChangeValueHandler={
                                  phoneInputChangeValueHandler
                                }
                              />
                            </div>
                          ))}

                          {(auth?.currentUser && userData?.phoneNumbers
                              .length < PHONE_NUMBERS_MAX_QUANTITY) &&
                            <button
                              type={'button'}
                              className={'RegularButton RegularButton_Contrast ' +
                                'RegistrationForm-AddPhoneButton'}
                              onClick={addNewPhoneHandler}
                            >
                              {translate('Добавить номер')}
                            </button>
                          }
                        </>
                      }

                      {(errorData?.phoneNumbers?.isError && userData
                        ?.phoneNumbers?.some(phone => (!phone?.phoneNumber
                          || phone?.phoneNumber.length < PHONE_NUMBER_MIN_LENGTH))) &&
                        <FormInputAlertText
                          text={getErrorText(errorData, 'phoneNumbers')}
                        />
                      }
                    </div>

                    <div className={'RegistrationForm-Item'}>
                      <label
                        className={'RegistrationForm-Label'}
                      >
                        {translate('E-mail')}
                      </label>

                      <div className={'RegistrationForm-InputContainer'}>
                        <img
                          src={'/img/message-icon.svg'}
                          className={'RegistrationForm-InputIcon EnvelopeIcon'}
                          alt={'Envelope icon'}
                        />
                        <input
                          disabled={!isRegistration && userData.email}
                          name={'Email'}
                          type={'email'}
                          className={(errorData?.email?.isError && (!userData
                            ?.email || errorData?.email?.errorType ===
                            registrationErrorsTypesInterface.wrongValue))
                            ? 'FormControl FormControl_Error' +
                            ' RegistrationForm-Input' : 'FormControl ' +
                            'RegistrationForm-Input'}
                          placeholder={translate('E-mail')}
                          value={userData.email}
                          onChange={(event) => changeInputValueHandler(
                            event.target.value, 'email')}
                        />
                      </div>

                      {(errorData?.email?.isError && (!userData?.email ||
                        errorData?.email?.errorType ===
                        registrationErrorsTypesInterface.wrongValue)) &&
                        <FormInputAlertText
                          text={getErrorText(errorData, 'email')}
                        />
                      }
                    </div>

                    <div className={'RegistrationForm-Item'}>
                      <label
                        className={'RegistrationForm-Label'}
                      >
                        {translate('Язык для писем')}
                      </label>

                      <Select
                        id={'EmailLang'}
                        classNames={{
                          control: () => 'FormControl AddTripForm-Select'
                        }}
                        options={languageService.transformLanguagesToSelectOptions(
                          languages)}
                        placeholder={translate('Язык для писем')}
                        onChange={(event) => changeInputValueHandler(
                          event.value?.name, 'languageForEmails')}
                        value={userData?.languageForEmails ? languageService
                          .getLanguageObjectForSelect(userData
                            ?.languageForEmails, languages): ''}
                      />
                    </div>

                    {isRegistration &&
                      <>
                        <div className={'RegistrationForm-Item'}>
                          <label
                            className={'RegistrationForm-Label'}
                          >
                            {translate('Пароль')}
                          </label>

                          <div className={'RegistrationForm-InputContainer'}>
                            {userData.password ?
                              <img
                                src={'/img/lock-blue.svg'}
                                className={'RegistrationForm-InputIcon LockIcon'}
                                alt={'Lock icon'}
                              />
                              :
                              <img
                                src={'/img/lock-icon.svg'}
                                className={'RegistrationForm-InputIcon LockIcon'}
                                alt={'Lock icon'}
                              />
                            }

                            <input
                              className={userData?.password ? (errorData
                                ?.password?.errorType ===
                                registrationErrorsTypesInterface.wrongValue
                                  ? 'FormControl FormControl_Error' +
                                  ' RegistrationForm-Input PasswordInputWithText'
                                  : 'FormControl RegistrationForm-Input' +
                                  ' PasswordInputWithText')
                                : (errorData?.password?.isError ? 'FormControl' +
                                  ' FormControl_Error RegistrationForm-Input'
                                  : 'FormControl RegistrationForm-Input')}
                              type={isPasswordVisible ? 'text' : 'password'}
                              placeholder={translate('Пароль')}
                              name={'Password'}
                              value={userData?.password || ''}
                              onChange={(event) => changeInputValueHandler(
                                event.target.value, 'password')}
                            />

                            {userData.password ?
                              (isPasswordVisible ?
                                  <img
                                    src={'/img/eye-with-line-icon-blue.svg'}
                                    className={'RegistrationForm-InputIconRight ' +
                                      'EyeIcon'}
                                    alt={'Eye icon'}
                                    onClick={() => setIsPasswordVisible(false)}
                                  />
                                  :
                                  <img
                                    src={'/img/eye-icon-blue.svg'}
                                    className={'RegistrationForm-InputIconRight ' +
                                      'EyeIcon'}
                                    alt={'Eye icon'}
                                    onClick={() => setIsPasswordVisible(true)}
                                  />
                              ) : (isPasswordVisible ?
                                <img
                                  src={'/img/eye-with-line-icon.svg'}
                                  className={
                                    'RegistrationForm-InputIconRight EyeIcon'
                                  }
                                  alt={'Eye icon'}
                                  onClick={() => setIsPasswordVisible(false)}
                                />
                                :
                                <img
                                  src={'/img/eye-icon.svg'}
                                  className={
                                    'RegistrationForm-InputIconRight EyeIcon'
                                  }
                                  alt={'Eye icon'}
                                  onClick={() => setIsPasswordVisible(true)}
                                />)
                            }
                          </div>

                          {(errorData?.password?.isError && (!userData
                            ?.password || errorData?.password?.errorType ===
                            registrationErrorsTypesInterface.wrongValue)) &&
                            <FormInputAlertText
                              text={getErrorText(errorData, 'password')}
                            />
                          }
                        </div>

                        <div className={'RegistrationForm-Item'}>
                          <label
                            className={'RegistrationForm-Label'}
                          >
                            {translate('Повторить пароль')}
                          </label>

                          <div className={'RegistrationForm-InputContainer'}>
                            {userData.confirmPassword ?
                              <img
                                src={'/img/lock-blue.svg'}
                                className={'RegistrationForm-InputIcon LockIcon'}
                                alt={'Lock icon'}
                              />
                              :
                              <img
                                src={'/img/lock-icon.svg'}
                                className={'RegistrationForm-InputIcon LockIcon'}
                                alt={'Lock icon'}
                              />
                            }

                            <input
                              className={userData?.confirmPassword ? (errorData
                                  ?.confirmPassword?.errorType ===
                                    registrationErrorsTypesInterface.wrongValue
                                  ? 'FormControl FormControl_Error' +
                                  ' RegistrationForm-Input PasswordInputWithText'
                                  : 'FormControl RegistrationForm-Input' +
                                  ' PasswordInputWithText')
                                : (errorData?.confirmPassword?.isError
                                  ? 'FormControl FormControl_Error' +
                                  ' RegistrationForm-Input' : 'FormControl' +
                                  ' RegistrationForm-Input')}
                              type={isRepeatPasswordVisible ? 'text' : 'password'}
                              placeholder={translate('Повторить пароль')}
                              name={'Password'}
                              value={userData?.confirmPassword || ''}
                              onChange={(event) => changeInputValueHandler(
                                event.target.value, 'confirmPassword')}

                            />

                            {userData.confirmPassword ?
                              (isRepeatPasswordVisible ?
                                  <img
                                    src={'/img/eye-with-line-icon-blue.svg'}
                                    className={'RegistrationForm-InputIconRight ' +
                                      'EyeIcon'}
                                    alt={'Eye icon'}
                                    onClick={
                                      () => setIsRepeatPasswordVisible(false)
                                    }
                                  />
                                  :
                                  <img
                                    src={'/img/eye-icon-blue.svg'}
                                    className={'RegistrationForm-InputIconRight ' +
                                      'EyeIcon'}
                                    alt={'Eye icon'}
                                    onClick={
                                      () => setIsRepeatPasswordVisible(true)
                                    }
                                  />
                              ) : (isRepeatPasswordVisible ?
                                <img
                                  src={'/img/eye-with-line-icon.svg'}
                                  className={'RegistrationForm-InputIconRight EyeIcon'}
                                  alt={'Eye icon'}
                                  onClick={
                                    () => setIsRepeatPasswordVisible(false)
                                  }
                                />
                                :
                                <img
                                  src={'/img/eye-icon.svg'}
                                  className={'RegistrationForm-InputIconRight ' +
                                    'EyeIcon'}
                                  alt={'Eye icon'}
                                  onClick={
                                    () => setIsRepeatPasswordVisible(true)
                                  }
                                />)
                            }
                          </div>


                          {(errorData?.confirmPassword?.isError && (!userData
                            ?.confirmPassword || errorData?.confirmPassword
                              ?.errorType === registrationErrorsTypesInterface
                              .wrongValue)) &&
                            <FormInputAlertText
                              text={getErrorText(errorData, 'confirmPassword')}
                            />
                          }
                        </div>
                      </>
                    }

                    <div className={'RegistrationForm-Item'}>
                      <label className={'RegistrationForm-Label'}>
                        {translate('Населенный пункт')}
                      </label>

                      <LocationIq
                        externalAction={changeInputValueHandler}
                        index={'place'}
                        receivedAddress={isAdmin ? currentUser.fullAddress
                          : userData.fullAddress}
                        addClassesForInputs={(errorData?.city?.isError &&
                          !userData?.city) ? 'FormControl_Error' : ''}
                      />

                      {(errorData?.city?.isError && !userData?.fullAddress) &&
                        <FormInputAlertText
                          text={getErrorText(errorData, 'city')}
                        />
                      }
                    </div>

                    {isRegistration &&
                      <AgreementWithPolicyControls
                        addClasses={'RegistrationForm-Item RegistrationForm-RulesLink'}
                        handleChange={changeInputValueHandler}
                        isChecked={!!userData.rulesAccepted}
                      />
                    }

                    {!isRegistration &&
                      (providers === authProvidersCheckResultsInterface.emailCanBeUsed) &&
                      <div className={'RegistrationForm-Item'}>
                        <button
                          className={'RegularButton RegularButton_Contrast'}
                          type="button"
                          onClick={() => {
                            setIsMainScreen(false);
                            setIsChangePasswordScreen(true);
                          }}
                        >
                          {translate('Изменить пароль')}
                        </button>
                      </div>
                    }

                    <div className={'RegistrationForm-Item'}>
                      <button
                        className={'RegularButton'}
                        type="submit"
                        name="submit"
                        id="mc-embedded-subscribe"
                      >
                        {isEditMode ?
                          translate('Сохранить изменения')
                          :
                          translate('Зарегистрироваться')
                        }
                      </button>
                    </div>

                    {(isRegistration && (!invitationData?.inviterId
                      && !referrerData?.referrerId)) &&
                      <SideRegisterServices
                        addClasses={'RegistrationForm-SideRegisterServices'}
                        setIsLoading={setIsLoading}
                      />
                    }

                    {((isEditMode && isUserDataComplete) && !isAdmin) &&
                      <div className={'RegistrationForm-Item'}>
                        <button
                          className={'RegularButton RegularButton_Contrast'}
                          type="button"
                          onClick={() => {
                            setUser(initialUserData);
                            setIsEditMode(false);
                          }}
                        >
                          {translate('Вернуться')}
                        </button>
                      </div>
                    }
                  </form>
                </>
              ) : (
                <>
                  {isAddPhoneScreen &&
                    <AddPhone
                      addClasses={'RegistrationForm-AddPhone'}
                      setIsAddPhoneScreen={setIsAddPhoneScreen}
                      setIsMainScreen={setIsMainScreen}
                      phoneInputChangeValueHandler={phoneInputChangeValueHandler}
                      deletePhoneHandler={deletePhoneHandler}
                      index={userData?.phoneNumbers?.length - 1}
                    />
                  }

                  {isChangePasswordScreen &&
                    <ChangePassword
                      addClasses={'RegistrationForm-ChangePassword'}
                      setIsChangePasswordScreen={setIsChangePasswordScreen}
                      setIsMainScreen={setIsMainScreen}
                    />
                  }
                </>
              )}
            </>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default RegistrationForm;
