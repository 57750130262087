import React from 'react';
import {NavLink} from "react-router-dom";
import {pagesInterface} from "../utils/models";
import {FiUsers} from "react-icons/fi";
import {CgProfile} from "react-icons/cg";
import {MdAirlineSeatReclineNormal, MdLanguage} from "react-icons/md";
import {GiGears} from "react-icons/gi";
import {IoMdOptions} from "react-icons/io";
import {IoCarSportOutline, IoStatsChartSharp} from "react-icons/io5";
import {BiBookAlt} from "react-icons/bi";
import {RiExchangeDollarFill} from "react-icons/ri";
import {AiOutlineComment, AiOutlineFileText} from "react-icons/ai";
import useTranslation from "../hooks/useTranslation";

const AdminNavMenu = ({
  addClasses = '',
  isFooter = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <>
      <nav className={`NavMenu Admin-NavMenu ${addClasses}`}>
        <NavLink
          to={`/${pagesInterface.statistic}`}
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <IoStatsChartSharp/>
            </span>
            :
            <span>
              {translate('Главная')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.usersManagement}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <FiUsers/>
            </span>
            :
            <span>
              {translate('Пользователи')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.catalogs}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <BiBookAlt/>
            </span>
            :
            <span>
              {translate('Справочники')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.languages}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <MdLanguage/>
            </span>
            :
            <span>
              {translate('Языки')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.textPages}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <AiOutlineFileText/>
            </span>
            :
            <span>
              {translate('Страницы')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.options}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <IoMdOptions/>
            </span>
            :
            <span>
              {translate('Опции')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.settings}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <GiGears/>
            </span>
            :
            <span>
              {translate('Настройки')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.tripsAdmin}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <IoCarSportOutline/>
            </span>
            :
            <span>
              {translate('Поездки')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.passengersAdmin}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <MdAirlineSeatReclineNormal/>
            </span>
            :
            <span>
              {translate('Пассажиры')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.transactionsAdmin}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <RiExchangeDollarFill/>
            </span>
            :
            <span>
              {translate('Транзакции')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.testimonialsAdmin}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <AiOutlineComment/>
            </span>
            :
            <span>
              {translate('Отзывы')}
            </span>
          }
        </NavLink>

        <NavLink
          className={({isActive}) =>
            isActive ? 'NavMenu-Item NavMenu-Item_Active' : 'NavMenu-Item'
          }
          to={`/${pagesInterface.profile}`}
        >
          {isFooter ?
            <span className={'NavMenu-IconForAdmin'}>
              <CgProfile/>
            </span>
            :
            <span>
              {translate('Профиль')}
            </span>
          }
        </NavLink>
      </nav>
    </>
  );
  //#region Render
};

export default AdminNavMenu;
