import React, {useEffect, useRef, useState} from 'react';
import '../styles/PlacesSuggestions.scss';

const PlacesSuggestions = ({
  addClasses = '',
  places = [],
  handlePlaceSelect = () => {},
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const listRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown') {
        setActiveIndex((prevIndex) =>
          prevIndex < places.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (event.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      } else if (event.key === 'Enter' && activeIndex >= 0) {
        handlePlaceSelect(places[activeIndex]);
        setActiveIndex(-1);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex, handlePlaceSelect, places]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
    handlePlaceSelect(places[index]);
  };

  //#region Render
  return (
    <ul
      className={`PlacesSuggestions ${addClasses}`}
      ref={listRef}
    >
      {places.map((place, i) =>
        <li
          key={i}
          onClick={() => {handleItemClick(i)}}
          className={`PlacesSuggestions-Item ${activeIndex === i 
            ? 'PlacesSuggestions-Item_Active' : ''}`}
        >
          {place.fullAddress}
        </li>)
      }
    </ul>
  );
  //#endregion
};

export default PlacesSuggestions;
