import {
  getCollectionWhereKeysValues,
  getCollectionWhereKeysValuesWithOperators,
  getDocInCollection,
  updateFieldsInDocumentInCollection
} from "./firebaseConfigAndFunctions";
import {collectionsInterface, tripModel, tripStatusesInterface} from "./models";
import {queryArraysService} from "./queryArraysService";

const extractDataFromTripPoint = (tripPoint) => {
  return {
    arrivingTime: '',
    boardingPlacesNames: tripPoint.boardingPlacesNames,
    city: tripPoint.city,
    country: tripPoint.country,
    departureDateWithoutTime: '',
    departureTime: '',
    departureTimeWithoutDate: '',
    fullAddress: tripPoint.fullAddress,
    lat: tripPoint.lat,
    lon: tripPoint.lon,
    placeId: tripPoint.placeId,
    priceFromPrevious: tripPoint.priceFromPrevious,
    priceFromStart: tripPoint.priceFromStart,
  }
}

const extractFieldsForTripCopy = (originalTrip) => {
  return {
    ...tripModel,
    boardingPlacesFromDriver: originalTrip.boardingPlacesFromDriver,
    boardingPlacesNames: originalTrip.boardingPlacesNames,
    cargoDescription: originalTrip.cargoDescription,
    creatorUid: originalTrip.creatorUid,
    creatorIdPost: originalTrip.creatorIdPost,
    dateCreating: Date.now(),
    dispatcherFee: originalTrip.dispatcherFee,
    drivers: originalTrip.drivers,
    endPoint: extractDataFromTripPoint(originalTrip.endPoint),
    freePlaces: originalTrip.freePlaces,
    isBoardingByDriverAddresses: originalTrip.isBoardingByDriverAddresses,
    isByFoot: originalTrip.isByFoot,
    isDraft: originalTrip.isDraft,
    isForChange: originalTrip.isForChange,
    isFreeSeating: originalTrip.isFreeSeating,
    isPassengerAddress: originalTrip.isPassengerAddress,
    isPassengerTrip: originalTrip.isPassengerTrip,
    isPaymentByCash: originalTrip.isPaymentByCash,
    isPaymentOnCard: originalTrip.isPaymentOnCard,
    isPlaceForCargo: originalTrip.isPlaceForCargo,
    isSeparatePlaceForLuggage: originalTrip.isSeparatePlaceForLuggage,
    isTripWithPets: originalTrip.isTripWithPets,
    isVisibleForDispatchers: originalTrip.isVisibleForDispatchers,
    placesScheme: originalTrip.placesScheme,
    startDate: '',
    startDateWithoutTime: '',
    startPoint: extractDataFromTripPoint(originalTrip.startPoint),
    totalPlaces: originalTrip.totalPlaces,
    pricePerPerson: originalTrip.pricePerPerson,
    tripPoints: originalTrip.tripPoints.map(point => extractDataFromTripPoint(point)),
    tripPointsNames: originalTrip.tripPointsNames,
    vehicle: originalTrip.vehicle,
    vehiclePlacesScheme: originalTrip.vehicle.placesScheme,
  }
}

const getTripCopyForNewTrip = async (tripId) => {
  const actualTrip = await getDocInCollection(collectionsInterface.trips, tripId);

  return extractFieldsForTripCopy(actualTrip);
}

const getTripsListDependingOnTransporterStatus = (trips, userId, attachedToCompany) => {
  if (!attachedToCompany) {
    return trips;
  }

  const driverTrips = trips.filter(trip => {
    const isDriverInTrip = trip.drivers
      .some(driver => driver.idPost === userId);

    if (isDriverInTrip) {
      return true;
    } else {
      return false;
    }
  });

  return driverTrips;
}

const getFieldsToMakeTripActual = () => {
  return {
    dateEditing: Date.now(),
    hasAnyReservations: false,
    hasReservationsFromDispatchers: false,
    hasReservationsFromDrivers: false,
    isArchived: false,
    isCancelled: false,
    isInProgress: false,
    isOpenForSearch: true,
    reservationsFromDispatchers: [],
    reservationsFromDrivers: [],
    status: tripStatusesInterface.openToSearch,
  }
}

const getTripToActivate = async (tripId) => {
  const actualTrip = await getDocInCollection(collectionsInterface.trips, tripId);

  const modifiedFields = getFieldsToMakeTripActual();

  await updateFieldsInDocumentInCollection(collectionsInterface.trips,
    tripId, modifiedFields)

  return {
    ...actualTrip,
    ...modifiedFields,
  };
}

const getTripsList = async (queryArray) => {
  const trips = await getCollectionWhereKeysValuesWithOperators(collectionsInterface
    .trips, queryArray, queryArray.length);

  return trips;
}

const getPassengersListFromTrip = (currentTrip) => {
  if (!currentTrip?.idPost) {
    return [];
  }

  return currentTrip?.acceptedDeals?.filter(deal => !deal?.isCancelled) || [];
}

const getActualTripsOrdersForPassengers = async (passengers) => {
  const passengersWithActualTrips = [];

  for (let i = 0; i < passengers.length; i++) {
    const currentOrder = await getDocInCollection(
      collectionsInterface.tripsOrders, passengers[i].id);

    passengersWithActualTrips.push({
      ...passengers[i],
      actualTripOrder: currentOrder,
    });
  }

  return passengersWithActualTrips;
}

const getPassengersList = async (tripId) => {
  const actualTrip = await getDocInCollection(collectionsInterface.trips, tripId);

  const passengers = getPassengersListFromTrip(actualTrip);

  const passengersWithActualTrips = await getActualTripsOrdersForPassengers(
    passengers);

  return passengersWithActualTrips;
}

const getArchivedPassengersListOfTransporter = async (transporterId, userId) => {
  const queryArray = queryArraysService.passengersOfTransporter(transporterId);

  const orders = await getCollectionWhereKeysValues(collectionsInterface
    .tripsOrders, queryArray, queryArray.length);

  if (transporterId === userId) {
    return orders;
  }

  const driverOrders  = orders.filter(order => order.acceptedDeal?.originalTrip
    .drivers.some(driver => driver.idPost === userId));

  return driverOrders;
}

export const tripService = {
  getTripCopyForNewTrip,
  getTripsListDependingOnTransporterStatus,
  getTripToActivate,
  getTripsList,
  getPassengersList,
  getArchivedPassengersListOfTransporter,
}
