import React, {useContext, useState, useEffect} from 'react';
import {findAllSuitableTripsAndSendMessageForTransporters} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import ModalNotification from "../modals/ModalNotification";
import {updateDocumentInCollection} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface} from "../utils/models";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";
import '../styles/ReplaceTripOrderScreen.scss';

const ReplaceTripOrderScreen = ({
  addClasses = '',
  handleGoBack = () => {},
  updateTripOrdersListAfterTripOrderReplacing = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Перенесение в пассажиры');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTripOrder?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region New trip order data
  const [updatedTripOrderData, setUpdatedTripOrderData] = useState(
    currentTripOrder);
  //#endregion

  //#region Handle change data
  const updateTripOrder = (fieldName, newValue) => {
    setUpdatedTripOrderData(data => ({
      ...data,
      [fieldName]: newValue,
    }));
  }
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Handle replace trip order to passengers section
  const replaceTripOrder = async (tripOrderData) => {
    setIsLoading(true);

    try {
      if (tripOrderData?.isVisibleForDrivers) {
        let isPaymentMethodDefined = false;

        switch (true) {
          case tripOrderData.isPaymentOnCard:
          case tripOrderData.isPaymentByCash:
          case tripOrderData.isForChange:
            isPaymentMethodDefined = true;
            break;
        }

        if (!isPaymentMethodDefined) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Необходимо выбрать способ оплаты');

          return;
        }

        if (!tripOrderData?.dispatcherFee || tripOrderData?.dispatcherFee <= 0) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Сумма гонорара должна быть больше нуля');

          return;
        }


        if (tripOrderData?.dispatcherFee > tripOrderData.pricePerPerson / 2) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Гонорар не может быть больше 50% от суммы поездки');

          return;
        }
      }

      const updatedTripOrder = {
        ...tripOrderData,
        isDraft: false,
        isOpenForSearch: true,
      };

      await updateDocumentInCollection(collectionsInterface.tripsOrders,
        updatedTripOrder, tripOrderData.idPost);

      if (tripOrderData.isVisibleForDrivers) {
        await findAllSuitableTripsAndSendMessageForTransporters(user,
          updatedTripOrder);
      }

      updateTripOrdersListAfterTripOrderReplacing(updatedTripOrder);

      handleGoBack();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`ReplaceTripOrderScreen ${addClasses}`}>
          <h2
            className={'RegularTitle RegularTitle_Small DesktopTitle'}
          >
            {translate(screenTitle)}
          </h2>

          <div className={'ReplaceTripOrderScreen-Item'}>
            <label className={'ReplaceTripOrderScreen-Label'}>
              {translate('Гонорар диспетчера')}
            </label>

            <NumberInputWithValidation
              addClasses={'ReplaceTripOrderScreen-Input'}
              externalChangeHandler={updateTripOrder}
              fieldName={'dispatcherFee'}
              placeholder={translate('Гонорар диспетчера')}
              value={updatedTripOrderData?.dispatcherFee}
            />
          </div>

          <div className={'ReplaceTripOrderScreen-Item'}>
            <h5 className={'RegularTitle RegularTitle_Small'}>
              {translate('Способ оплаты')}
            </h5>

            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripOrderScreen-Block'
              }
            >
              <div className={'OptionCheckBox'}>
                <input
                  id={'isPaymentOnCard'}
                  type={'checkbox'}
                  className={'OptionCheckBox'}
                  checked={updatedTripOrderData?.isPaymentOnCard}
                  onChange={(event) => updateTripOrder('isPaymentOnCard',
                    event.target.checked)}
                  disabled={updatedTripOrderData?.isPaymentByCash}
                />
              </div>

              <label className={'OptionText'} htmlFor={'isPaymentOnCard'}>
                {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
              </label>

              {updatedTripOrderData?.isPaymentOnCard &&
                <div className={'ReplaceTripOrderScreen-IconContainer'}>
                  <img
                    src={'/img/bank-safe-icon.svg'}
                    className={'ReplaceTripOrderScreen-Icon'}
                  />
                </div>
              }
            </div>

            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripOrderScreen-Block'
              }
            >
              <input
                id={'isPaymentByCash'}
                type={'checkbox'}
                className={'OptionCheckBox'}
                checked={updatedTripOrderData?.isPaymentByCash}
                onChange={(event) => updateTripOrder('isPaymentByCash',
                  event.target.checked)}
                disabled={updatedTripOrderData?.isPaymentOnCard}
              />
              <label className={'OptionText'} htmlFor={'isPaymentByCash'}>
                {translate('Наличные по договоренности')}
              </label>
            </div>

            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripOrderScreen-Block'
              }
            >
              <input
                id={'isForChange'}
                type={'checkbox'}
                className={'OptionCheckBox'}
                onChange={(event) => updateTripOrder('isForChange',
                  event.target.checked)}
                checked={updatedTripOrderData?.isForChange}
              />
              <label className={'OptionText'} htmlFor={'isForChange'}>
                {translate('Обмен')}
              </label>
            </div>
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div className={'ReplaceTripOrderScreen-Item'}>
            <div
              className={
                'FlexBlock FlexBlock_JustifyLeft ReplaceTripOrderScreen-Block'
              }
            >
              <input
                id={'isVisibleForDrivers'}
                type={'checkbox'}
                className={'OptionCheckBox'}
                onChange={(event) => updateTripOrder('isVisibleForDrivers',
                  event.target.checked)}
                checked={updatedTripOrderData?.isVisibleForDrivers}
              />

              <label
                className={'OptionText OptionText_Bold'}
                htmlFor={'isVisibleForDrivers'}
              >
                {translate('Опубликовать предложение для водителей')}
              </label>
            </div>
          </div>

          <div className={'ReplaceTripOrderScreen-Item'}>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={() => replaceTripOrder(updatedTripOrderData)}
            >
              {translate('Сохранить')}
            </button>
          </div>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default ReplaceTripOrderScreen;
