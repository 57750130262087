import React, {useContext, useEffect, useState} from 'react';
import {entitiesNumberInterface, tripOrderModel} from "../utils/models";
import {AppContext} from "../context/appContext";
import Loader from "./Loader";
import PaginationBlock from "./PaginationBlock";
import DisplayTripOrderCard from "./DisplayTripOrderCard";
import useTranslation from "../hooks/useTranslation";
import '../styles/CardsListScreen.scss';

const DisplayCreatedTripsOrders = ({
  tripsOrdersToDisplay = [],
  isNotepad = true,
  handleShowCreateTrip = () => {},
  handleTripOrderCardClick = () => {},
  addClasses = '',
  isReadOnly = false,
  isReducedLength = false,
  isExtendedLength = false,
  isDispatcher = false,
  isInReservedList = false,
  isInMyPassengersList = false,
  isInPassengersSearchList = false,
  isFromExchange = false,
  cardsNumber = entitiesNumberInterface.tripsOrdersCards,
  isLoading = true,
  setIsLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screen title managing function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title and current trip order on component mount
  useEffect(() => {
    if (!isNotepad) {
      if (isInReservedList) {
        setScreenTitle('Забронированные поездки');
      } else if (isInPassengersSearchList) {
        setScreenTitle('Найденные пассажиры');
      } else {
        setScreenTitle('Мои пассажиры');
      }
    }

    setCurrentTripOrder(tripOrderModel);
  }, []);
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsOrdersChunk, setTripsOrdersChunk] = useState([]);

  useEffect(() => {
    setTripsOrdersChunk(tripsOrdersToDisplay
      .slice((currentPage - 1) * cardsNumber,
        currentPage * cardsNumber));
  }, [tripsOrdersToDisplay, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div
          className={tripsOrdersToDisplay?.length > 0
            ? `CardsListScreen ${addClasses}`
            : `CardsListScreen CardsListScreen_Empty  ${addClasses}`}>
          <div className={'PaddingBlock_Half'}></div>

          {!isFromExchange && !isNotepad &&
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>
          }

          {tripsOrdersToDisplay?.length > 0 ?
            <></>
            :
            <p className={'CardsListScreen-NoEntriesText'}>
              {translate('Нет пассажиров')}
            </p>
          }

          <div className={'DataListWithPagination'}>
            <div
              className={isReducedLength ? 'ListContainer ' +
                'ListContainer_ExtremelyReduced' : (isExtendedLength
                ? 'ListContainer ListContainer_Extended' : 'ListContainer')}
            >
              <div className='CardsList'>
                {isNotepad &&
                  <button
                    type={'button'}
                    onClick={() => {
                      setIsLoading(true);
                      handleShowCreateTrip();
                      setIsLoading(false);
                    }}
                    className={'RegularButton CardsList-AddButton'}
                  >
                    {translate('Добавить пассажира')}
                  </button>
                }

                {tripsOrdersChunk?.map((tripOrder, tIndex) => (
                  <React.Fragment key={tripOrder.idPost}>
                    <DisplayTripOrderCard
                      tripOrder={tripOrder}
                      isDispatcher={isDispatcher}
                      isInMyPassengersList={isInMyPassengersList}
                      isInReservedList={isInReservedList}
                      handleShowCreateTrip={handleShowCreateTrip}
                      handleTripOrderCardClick={handleTripOrderCardClick}
                      isNotepad={isNotepad}
                      isReadOnly={isReadOnly}
                      isInPassengersSearchList={isInPassengersSearchList}
                    />
                  </React.Fragment>))
                }
              </div>
            </div>

            {tripsOrdersToDisplay.length > cardsNumber &&
              <PaginationBlock
                portionSize={cardsNumber}
                listLength={tripsOrdersToDisplay.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          </div>
        </div>
      }
    </>
  );
  //#endregion
};

export default DisplayCreatedTripsOrders;
