import React, {useState, useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import DisplayTripCard from "./DisplayTripCard";
import {
  entitiesNumberInterface,
  pagesInterface,
  subpagesInterface
} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import useTripsList from "../hooks/useTripsList";
import {queryArraysService} from "../utils/queryArraysService";
import {useNavigate} from "react-router-dom";

const DisplayActiveTrips = ({
  addClasses = '',
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get dictionaries from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Активные поездки');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Get trips list and loader
  const creatorId = user.attachedToCompany || user.idPost;

  const {isLoading, tripsList, setTripsList} = useTripsList(queryArraysService
    .activeTrips(creatorId), user.idPost, user.attachedToCompany);
  //#endregion

  //#region Handle trip card click
  const handleTripCardClick = (trip) => {
    setCurrentTrip(trip);

    navigate(`/${pagesInterface.trips}/${subpagesInterface.activeTrips}/${
      subpagesInterface.passengersList}`);
  }
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsChunk, setTripsChunk] = useState([]);

  useEffect(() => {
    setTripsChunk(tripsList
      .slice((currentPage - 1) * entitiesNumberInterface.tripsCards,
        currentPage * entitiesNumberInterface.tripsCards));
  }, [tripsList.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div
          className={tripsList.length > 0 ? `CardsListScreen ${addClasses}`
            : `CardsListScreen CardsListScreen_Empty  ${addClasses}`}
        >
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          {tripsList.length > 0 ?
            <div className={'PaddingBlock'}></div>
            :
            <p className={'CardsListScreen-NoEntriesText'}>
              {translate('Нет поездок')}
            </p>
          }

          <div className={'DataListWithPagination'}>
            <div className={'ListContainer ListContainer_Extended'}>
              <div className='CardsList'>
                {tripsChunk?.map((trip, tIndex) => (
                  <React.Fragment key={trip.idPost}>
                    <DisplayTripCard
                      trip={trip}
                      handleShowTripDetailsScreen={handleTripCardClick}
                      isDispatcher={false}
                    />
                  </React.Fragment>))
                }
              </div>
            </div>

            {tripsList.length > entitiesNumberInterface.tripsCards &&
              <PaginationBlock
                portionSize={entitiesNumberInterface.tripsCards}
                listLength={tripsList.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          </div>
        </div>
      }
    </>
  );
  //#endregion
};

export default DisplayActiveTrips;
