import React, {useContext, useEffect} from 'react';
import {Nav, Tab} from "react-bootstrap";
import Debtors from "./Debtors";
import Creditors from "./Creditors";
import {AppContext} from "../context/appContext";
import useTranslation from "../hooks/useTranslation";

const ExchangeTabs = ({
  addClasses = '',
  handleCounterpartyCardClick = () => {
  },
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Обмен');
  }, []);
  //#endregion

  //#region Render
  return (
    <div className={`ExchangeTabs ${addClasses}`}>
      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      <Tab.Container
        defaultActiveKey='debtors'
      >
        <Nav variant="pills" className={'flex-row TabControls'}>
          <Nav.Item>
            <Nav.Link eventKey='debtors'>
              {translate('Дебиторы')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='creditors'>
              {translate('Кредиторы')}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content className={'TabContent'}>
          <Tab.Pane eventKey='debtors'>
            <Debtors
              handleDebtorCardClick={handleCounterpartyCardClick}
            />
          </Tab.Pane>

          <Tab.Pane eventKey='creditors'>
            <Creditors
              handleCreditorCardClick={handleCounterpartyCardClick}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
  //#endregion
};

export default ExchangeTabs;
