import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {createLinkToInviteUser} from "../utils/helpers";
import {FaTelegram, FaViber, FaWhatsapp} from "react-icons/fa";
import ReferralLink from "./ReferralLink";
import useTranslation from "../hooks/useTranslation";
import '../styles/InviteToApp.scss';

const InviteToApp = ({addClasses = '',}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Пригласить в приложение');
  }, []);
  //#endregion

  //#region Link displaying
  const [linkData, setLinkData] = useState('');
  //#endregion

  //#region Handle create link click
  const handleCreateLinkClick = () => {
    const link = createLinkToInviteUser(user.idPost);

    navigator.clipboard.writeText(link).catch(() => {});

    setLinkData(link);
  };
  //#endregion

  //#region Render
  return (
    <>
      <div className={`InviteToApp ${addClasses}`}>
        <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {translate(screenTitle)}
        </h2>

        <div className={'InviteToApp-Item'}>
          <button
            type={'button'}
            className={'RegularButton'}
            onClick={() => handleCreateLinkClick()}
          >
            {translate('Сгенерировать ссылку')}
          </button>
        </div>

        {linkData &&
          <>
            <div className={'InviteToApp-Item'}>
              <h3 className={'RegularTitle RegularTitle_Small'}>
                {translate('Ссылка для приглашения')}
              </h3>

              <ReferralLink text={linkData} />
            </div>

            <div className={'InviteToApp-Item FlexBlock'}>
              <a
                href={`viber://forward?text=${linkData}`}
                className={
                  'MessengerIcon MessengerIcon_Viber MessengerIcon_Huge'
                }
                target={'_blank'}
                onClick={(event) => event.stopPropagation()}
              >
                <FaViber/>
              </a>

              <a
                href={`https://api.whatsapp.com/send/?text=${linkData}`}
                className={
                  'MessengerIcon MessengerIcon_WhatsApp MessengerIcon_Huge'
                }
                target={'_blank'}
                onClick={(event) => event.stopPropagation()}
              >
                <FaWhatsapp/>
              </a>

              <a
                href={`https://t.me/share/url?url=${linkData}`}
                className={
                  'MessengerIcon MessengerIcon_Telegram MessengerIcon_Huge'
                }
                target={'_blank'}
                onClick={(event) => event.stopPropagation()}
              >
                <FaTelegram/>
              </a>
            </div>
          </>
        }
      </div>
    </>
  );
  //#endregion
};

export default InviteToApp;
