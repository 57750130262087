import React, {useContext, useState} from 'react';
import {
  getPossibleAuthOptionsForEmail,
  validateEmailField,
} from "../utils/helpers";
import {Link, useNavigate} from "react-router-dom";
import {signIn} from "../utils/firebaseConfigAndFunctions";
import {AppContext} from "../context/appContext";
import SideRegisterServices from "./SideRegisterServices";
import FormInputAlertText from "./FormInputAlertText";
import {
  authProvidersCheckResultsInterface,
  pagesInterface
} from "../utils/models";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/LoginForm.scss';
import AgreementWithPolicyControls from "./AgreementWithPolicyControls";

const LoginForm = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get setUser function from context
  const {setUser, setIsUserDataComplete} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Sign in data and it's update
  const [signInData, setSignInData] = useState({
    rulesAccepted: true,
  });

  const updateSignInData = (fieldName, newValue) => {
    setSignInData(state => ({
      ...state,
      [fieldName]: newValue,
    }))
  }

  const handlePolicyAgreementChange = (newValue, fieldName) => {
    updateSignInData(fieldName, newValue)
  }
  //#endregion

  //#region Validate data after blur
  const handleEmailFieldBlur = () => {
    if (!validateEmailField(signInData.email)) {
      setIsEmailFieldError(true);
      setEmailFieldErrorText('Неверный формат почты');
    }
  }
  //#endregion

  //#region Errors
  const [isEmailFieldError, setIsEmailFieldError] = useState(false);
  const [emailFieldErrorText, setEmailFieldErrorText] = useState('');
  const [isPasswordFieldError, setIsPasswordFieldError] = useState(false);
  const [passwordFieldErrorText, setPasswordFieldErrorText] = useState('');

  const setErrorTextForEmail = async (email) => {
    const providersResult = await getPossibleAuthOptionsForEmail(email);

    switch (providersResult) {
      case authProvidersCheckResultsInterface.noProviders:
        setIsEmailFieldError(true);
        setEmailFieldErrorText('Пользователь не найден');
        return true;
      case authProvidersCheckResultsInterface.googleCanBeUsed:
        setIsEmailFieldError(true);
        setEmailFieldErrorText(
          'Ошибка! Воспользуйтесь авторизацией через гугл');
        return true;
    }

    return false;
  };

  const setErrorTextForSubmit = (errorMessage) => {
    if (errorMessage.includes('auth/wrong-password')) {
      setIsPasswordFieldError(true);
      setPasswordFieldErrorText('Неверный пароль');
      return;
    }

    setIsPasswordFieldError(true);
    setPasswordFieldErrorText('Неизвестная ошибка. Попробуйте позже');
  };
  //#endregion

  //#region Submit login form
  const submitLoginForm = async (
    event,
    email,
    password,
    navigate = () => {},
  ) => {
    event.preventDefault();

    setIsLoading(true);

    if (!validateEmailField(email)) {
      setIsEmailFieldError(true);
      setEmailFieldErrorText('Неверный формат почты');

      setIsLoading(false);

      return;
    }

    try {
      const errorResult = await setErrorTextForEmail(email);

      if (errorResult) {
        return;
      }

      await signIn(email, password, setUser, setIsUserDataComplete);

      navigate('/profile');
    } catch (error) {
      setErrorTextForSubmit(error.message);
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Manage password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading
        ? <Loader/>
        :
        <div className={`LoginContainer ${addClasses}`}>
          <div
            className={'LoginContainer-ImageContainer'}
          >
            <img
              className={'LoginImage'}
              src={'/img/login-image.svg'}
              alt={'Login image'}
            />
          </div>

          <form
            className={'LoginContainer-LoginForm LoginForm'}
            onSubmit={(event) => submitLoginForm(event,
              signInData.email, signInData.password, navigate)}
          >
            <div className={'RegularTitle LoginForm-Item'}>
              {translate('Войти в учетную запись')}
            </div>

            <div className={'LoginForm-Item'}>
              <label className={'LoginForm-Label'}>
                {translate('E-mail')}
              </label>

              <div className={'LoginForm-InputContainer'}>
                <img
                  src={'/img/message-icon.svg'}
                  className={'LoginForm-InputIcon EnvelopeIcon'}
                  alt={'Envelope icon'}
                />

                <input
                  className={'FormControl LoginForm-Input'}
                  type={'text'}
                  placeholder={translate('E-mail')}
                  onChange={(event) => updateSignInData('email',
                    event.target.value)}
                  onBlur={() => handleEmailFieldBlur()}
                  value={signInData.email}
                />
              </div>

              {isEmailFieldError &&
                <FormInputAlertText
                  text={emailFieldErrorText}
                  setIsVisible={setIsEmailFieldError}
                />
              }
            </div>

            <div className={'LoginForm-Item'}>
              <label className={'LoginForm-Label'}>
                {translate('Пароль')}
              </label>

              <div className={'LoginForm-InputContainer'}>
                {signInData.password ?
                  <img
                    src={'/img/lock-blue.svg'}
                    className={'LoginForm-InputIcon LockIcon'}
                    alt={'Lock icon'}
                  />
                  :
                  <img
                    src={'/img/lock-icon.svg'}
                    className={'LoginForm-InputIcon LockIcon'}
                    alt={'Lock icon'}
                  />
                }

                <input
                  className={
                    signInData?.password
                      ? 'FormControl LoginForm-Input PasswordInputWithText'
                      : 'FormControl LoginForm-Input'
                  }
                  type={isPasswordVisible ? 'text' : 'password'}
                  placeholder={translate('Пароль')}
                  onChange={(event) => updateSignInData('password',
                    event.target.value)}
                  value={signInData.password}
                />

                {signInData.password ?
                  (isPasswordVisible ?
                      <img
                        src={'/img/eye-with-line-icon-blue.svg'}
                        className={'LoginForm-InputIconRight EyeIcon'}
                        alt={'Eye icon'}
                        onClick={() => setIsPasswordVisible(false)}
                      />
                      :
                      <img
                        src={'/img/eye-icon-blue.svg'}
                        className={'LoginForm-InputIconRight EyeIcon'}
                        alt={'Eye icon'}
                        onClick={() => setIsPasswordVisible(true)}
                      />
                  ) : (isPasswordVisible ?
                    <img
                      src={'/img/eye-with-line-icon.svg'}
                      className={'LoginForm-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsPasswordVisible(false)}
                    />
                    :
                    <img
                      src={'/img/eye-icon.svg'}
                      className={'LoginForm-InputIconRight EyeIcon'}
                      alt={'Eye icon'}
                      onClick={() => setIsPasswordVisible(true)}
                    />)
                }

                {isPasswordFieldError &&
                  <FormInputAlertText
                    text={passwordFieldErrorText}
                    setIsVisible={setIsPasswordFieldError}
                  />
                }
              </div>

              <div className={'LoginForm-Item LoginForm-TextContainer'}>
                <Link
                  to={'/recoverpassword'}
                  className={'LoginForm-Text'}
                >
                  {translate('Забыли пароль')}
                </Link>
              </div>
            </div>

            <button
              className={'RegularButton LoginForm-Item'}
              type={'submit'}
            >
              {translate('Войти')}
            </button>

            <div className={'LoginForm-Item'}>
              <button
                type={'button'}
                onClick={() => {
                  navigate('/register')
                }}
                className={'RegularButton LoginForm-RegisterButton'}
              >
                {translate('Регистрация')}
              </button>
            </div>

            <AgreementWithPolicyControls
              addClasses={'LoginForm-Item LoginForm-RulesLink'}
              handleChange={handlePolicyAgreementChange}
              isChecked={signInData.rulesAccepted}
              isDisabled={true}
            />

            <SideRegisterServices
              addClasses={'LoginForm-SideRegisterServices'}
            />
          </form>
        </div>
      }
    </>
  );
  //#endregion
};

export default LoginForm;
