import React, {useContext} from 'react';
import {AppContext} from "../context/appContext";
import {getTranslatedLocalityName} from "../utils/placesService";

const BoardingPlacesFromDriver = ({
  reservationData,
  handlePassengerDepartureAddressChange = () => {},
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const { currentTrip } = useContext(AppContext);
  //#endregion

  //#region Render
  return (
    <>
      <fieldset>
          {currentTrip?.boardingPlacesFromDriver?.map((address, index) =>
            <React.Fragment key={index}>
              <div className={'FlexBlock RadioItem'}>
                <input
                  className={
                    'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W5'
                  }
                  type={'radio'}
                  id={address.placeId}
                  value={address.placeId}
                  checked={reservationData?.passengerDepartureAddress
                    ?.placeId === address.placeId}
                  onChange={() => handlePassengerDepartureAddressChange(address)}
                />

                <label
                  className={
                    'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W90'
                  }
                  htmlFor={address.placeId}
                >
                  {getTranslatedLocalityName(address?.fullAddress, lang)}
                </label>
              </div>
            </React.Fragment>
          )}
        </fieldset>
    </>
  );
  //#endregion
};

export default BoardingPlacesFromDriver;
