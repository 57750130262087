import React from 'react';
import {BsFillPersonFill} from "react-icons/bs";
import {FaSuitcase} from "react-icons/fa";
import useTranslation from "../hooks/useTranslation";

const PlacesSchemeDraw = ({addClasses= '', schemeToDisplay = []}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={`VehicleBody ${addClasses}`}>
      {schemeToDisplay?.map((row, index) => (
        <div className={'VehicleRow'} key={index}>
          {row?.map((cell, cIndex) => (
            <div
              key={(index + 1).toString() + (cIndex + 1).toString()}
              className={cell.isFreeSpace ? 'VehicleCell'
                : 'VehicleCell VehicleCell-Place'}
              title={cell.description}
            >
              {!cell.isFreeSpace &&
                <span className={'VehicleCell-PlaceIcon'}>
                  {cell.isPassengerPlace ?
                    <BsFillPersonFill
                      title={translate('Пассажирское')}
                    />
                    :
                    <FaSuitcase
                      title={translate('Грузовое')}
                    />
                  }
                </span>
              }

              {cell.placeNumber !== 0 &&
                <span className={'VehicleCell-PlaceNumber'}>
                  {cell.placeNumber}
                </span>
              }
            </div>
          ))}
        </div>
      ))}
    </div>
  );
  //#endregion
};

export default PlacesSchemeDraw;
