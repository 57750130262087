import {DEFAULT_LANG} from "./data";

const getLanguageDescription = (langCode, languages) => {
  let language = languages.find(lang => lang.name === langCode);

  if (!language) {
    language = languages.find(lang => lang.name === DEFAULT_LANG);
  }

  return language.description;
}

const getLanguage = (langCode, languages) => {
  return languages.find(lang => lang.name === langCode);
}

const transformLanguagesToSelectOptions = (languages) => {
  return languages.map(language => ({
    label: language.description,
    value: language,
  }));
}

const getLanguageObjectForSelect = (langCode, languages) => {
  let language = languages.find(lang => lang.name === langCode);

  return {
    label: language.description,
    value: language,
  }
}

export const languageService = {
  getLanguage,
  getLanguageDescription,
  getLanguageObjectForSelect,
  transformLanguagesToSelectOptions,
}
