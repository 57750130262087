import React, {useState} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {changeNotificationStatus} from "../utils/helpers";
import NotificationsList from "./NotificationsList";
import NotificationDetails from "./NotificationDetails";
import {notificationModel} from "../utils/models";
import '../styles/Notifications.scss';

const Notifications = ({addClasses='', notifications = []}) => {
  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Handle notification click
  const [currentNotification, setCurrentNotification] = useState(
    notificationModel);

  const handleNotificationClick = async (user, notification) => {
    setCurrentNotification(notification);
    navigate('/profile/notifications/notificationDetails');

    if (notification.isNew) {
      await changeNotificationStatus(user.idPost, notification);
    }
  }
  //#endregion

  //#region Current and start page
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={`Notifications ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <NotificationsList
                notifications={notifications}
                handleNotificationClick={handleNotificationClick}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          />

          <Route
            path={'notificationDetails'}
            element={
              <NotificationDetails
                addClasses={'App-Screen'}
                notification={currentNotification}
              />
            }
          />
        </Routes>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default Notifications;
