import React, {useContext, useState, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  collectionsInterface,
  pagesInterface,
  tripOrderModel
} from "../utils/models";
import useTranslation from "../hooks/useTranslation";
import {indicatorsNewService} from "../utils/indicatorsNewService";
import PageMenuItem from "./PageMenuItem";
import {
  getCollectionWhereKeysValues
} from "../utils/firebaseConfigAndFunctions";
import {
  defineIfBadgeNewShouldBeOnMyPassengersMenuSectionPointer
} from "../utils/helpers";
import {queryArraysService} from "../utils/queryArraysService";

const PassengersPageMenu = () => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get currentTripOrder from context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get title from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Data for indicators new, and it's handling
  const [isIndicatorNewForMyTripsOrders, setIsIndicatorNewForMyTripsOrders] = useState(
    false);
  const [isIndicatorNewForReservedTripsOrders, setIsIndicatorNewForReservedTripsOrders]
    = useState(false);

  const [isIndicatorNewForActiveTripsOrders, setIsIndicatorNewForActiveTripsOrders]
    = useState(false);

  const [isIndicatorNewForArchivedTripsOrders, setIsIndicatorNewForArchivedTripsOrders]
    = useState(false);

  const handleIndicatorNewOnMyPassengersMenuSection = async () => {
    try {
      const queryArray = queryArraysService.myTripsOrdersActual(user.idPost);
      const orders = await getCollectionWhereKeysValues(collectionsInterface
        .tripsOrders, queryArray, queryArray.length);

      if (orders.length > 0) {
        const result = await defineIfBadgeNewShouldBeOnMyPassengersMenuSectionPointer(user, orders)

        if (result) {
          setIsIndicatorNewForMyTripsOrders(true);
        } else if (isIndicatorNewForMyTripsOrders) {
          setIsIndicatorNewForMyTripsOrders(false);
        }
      } else {
        if (isIndicatorNewForMyTripsOrders) {
          setIsIndicatorNewForMyTripsOrders(false);
        }
      }
    } catch (error) {

    }
  }

  const handleIndicatorNewOnReservedTripsOrdersSection = async () => {
    const checkResult = await indicatorsNewService
      .showIndicatorNewForReservedTrips(user.idPost, true);

    setIsIndicatorNewForReservedTripsOrders(checkResult);
  }

  const handleIndicatorNewOnActiveTripsOrdersSection = async () => {
    const checkResult = await indicatorsNewService
      .showIndicatorNewForActiveTripsOrders(user.idPost);

    setIsIndicatorNewForActiveTripsOrders(checkResult);
  }

  const handleIndicatorNewOnArchivedTripsOrdersSection = async () => {
    const checkResult = await indicatorsNewService
      .showIndicatorNewForTripsHistoryForPassengers(user.idPost);

    setIsIndicatorNewForArchivedTripsOrders(checkResult);
  }
  //#endregion

  //#region Handlers for menu items click
  const handleAddPassengerClick = () => {
    setCurrentTripOrder(tripOrderModel);
    navigate(`/${pagesInterface.passengers}/createTripOrder`);
  }

  const handleMyPassengersClick = () => {
    navigate('myPassengers');
    setScreenTitle('Мои пассажиры');
  }

  const handleFavouriteDriversClick = () => {
    navigate('favouriteDrivers');
  }

  const handleReservedTripsOrdersClick = () => {
    setScreenTitle('Забронированные поездки');
    navigate('reservedTrips');
  }

  const handleActiveTripsOrdersClick = () => {
    setScreenTitle('Активные поездки');
    navigate('activeTrips');
  }

  const handleArchivedTripsOrdersClick = () => {
    navigate('archivedTrips');
  }
  //#endregion

  //#region Actions on component mount
  useEffect(() => {
    setScreenTitle('Пассажиры');

    handleIndicatorNewOnMyPassengersMenuSection().then();

    handleIndicatorNewOnReservedTripsOrdersSection().then();

    handleIndicatorNewOnActiveTripsOrdersSection().then();

    handleIndicatorNewOnArchivedTripsOrdersSection().then();
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <h1 className={'RegularTitle Passengers-Title'}>
        {translate(screenTitle)}
      </h1>

      <nav className={'Passengers-Nav SectionNavMenu'}>
        <PageMenuItem
          iconPath={'/img/trips-menu/car-menu-icon.svg'}
          iconAlt={'Car icon'}
          text={'Добавить пассажира'}
          onClick={handleAddPassengerClick}
        />

        <PageMenuItem
          iconPath={'/img/passengers-menu/passengers-menu-passengers-icon.svg'}
          iconAlt={'Passengers icon'}
          text={'Мои пассажиры'}
          onClick={handleMyPassengersClick}
          isIndicatorNew={isIndicatorNewForMyTripsOrders}
        />

        <PageMenuItem
          iconPath={'/img/passengers-menu/passengers-menu-heart-icon.svg'}
          iconAlt={'Heart icon'}
          text={'Список избранных водителей'}
          onClick={handleFavouriteDriversClick}
        />

        <PageMenuItem
          iconPath={'/img/passengers-menu/passengers-menu-reservation-icon.svg'}
          iconAlt={'Approve icon'}
          text={'Забронированные поездки'}
          onClick={handleReservedTripsOrdersClick}
          isIndicatorNew={isIndicatorNewForReservedTripsOrders}
        />

        <PageMenuItem
          iconPath={'/img/passengers-menu/passengers-menu-active-trips-icon.svg'}
          iconAlt={'Car icon'}
          text={'Активные поездки'}
          onClick={handleActiveTripsOrdersClick}
          isIndicatorNew={isIndicatorNewForActiveTripsOrders}
        />

        <PageMenuItem
          iconPath={'/img/passengers-menu/passengers-menu-archived-trips-icon.svg'}
          iconAlt={'Car icon'}
          text={'История выполненных поездок'}
          onClick={handleArchivedTripsOrdersClick}
          isIndicatorNew={isIndicatorNewForArchivedTripsOrders}
        />
      </nav>
    </>
  );
  //#endregion
};

export default PassengersPageMenu;
