import React, {useState, useContext, useEffect} from 'react';
import PhoneInput from "react-phone-input-2";
import {collectionsInterface, tripOrderModel} from "../utils/models";
import {AppContext} from "../context/appContext";
import {
  getCollection, getDocInCollection,
  setDocumentToCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  checkTripOrderCompleteness,
  findAllSuitableTripsAndSendMessageForTransporters,
  getDataFromTripOrderToSaveInReservation,
  getHoursAndMinutesFromTime,
  getNewPhoneObj,
  getTripAddOptionsFromTrip,
  handleUserClick,
  updateReservationInTripAfterPassengerChanges,
  validateFullName,
  validatePassengerName,
} from "../utils/helpers";
import TripPoint from "./TripPoint";
import RadioFieldSet from "../elements/RadioFieldSet";
import {FaTrash} from "react-icons/fa";
import MessengersRow from "./MessengersRow";
import ModalNotification from "../modals/ModalNotification";
import DisplayTripOrderDetails from "./DisplayTripOrderDetails";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import {isAfter} from "date-fns";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import {checkTripOrderService} from "../utils/checkTripOrderService";
import {
  checkReservationsByAddOptionsAndGetRejectedList,
  getUpdatedReservationsList,
  getReservationsListWithUpdatedOriginalTripOrder,
  updateOriginalTripOrderInReservationsInTrips,
} from "../utils/reservationsService";
import {
  PHONE_NUMBER_MIN_LENGTH,
  PHONE_NUMBERS_MAX_QUANTITY
} from "../utils/data";
import {
  getPointForDbFromLocationIqPlace,
  getTripPointWithTranslations
} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import '../styles/CreateTripOrder.scss';

const CreateTripOrder = ({
  isCreator = true,
  addClasses = '',
  handleGoBack = () => {},
  isNotepad = true,
  isAdmin = false,
}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get language from context, manage languages
  const {lang, languages} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    let currentTitle = '';

    if (currentTripOrder.isActive) {
      currentTitle = 'Детали поездки';
    } else {
      currentTitle = currentTripOrder?.idPost ? 'Редактирование поездки' :
        'Создание поездки';
    }

    setScreenTitle(currentTitle);
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if admin and no data
  useEffect(() => {
    if (isAdmin && !currentTripOrder?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Current trip data
  const [newTripData, setNewTripData] = useState(
    currentTripOrder || tripOrderModel);
  const [tripPointsNames] = useState(['start', 'end']);
  const [updatedFields, setUpdatedFields] = useState({});
  //#endregion

  //#region Get addOptions for trip
  const [tripAddOptions, setTripAddOptions] = useState([]);

  useEffect(() => {
    let currentOptions = [];

    getCollection('tripAddOptionsList')
      .then(result => {
        currentOptions = [...result[0].options];
        setTripAddOptions(getTripAddOptionsFromTrip(currentTripOrder,
          currentOptions));
      }).catch(() => {});
  }, []);
  //#endregion

  //#region Handling orderParametersChange
  const changeTripTypeHandler = (newValue, inputName) => {
    const tripType = newValue === translate('Пассажирская');

    setNewTripData(state => ({
      ...state,
      [inputName]: tripType,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        [inputName]: tripType,
      }))
    }
  };

  const changePlaceInputHandler = (newPlace, index) => {
    if (index === 0) {
      setNewTripData(tripData => ({
        ...tripData,
        startPoint: getPointForDbFromLocationIqPlace(newPlace, lang),
      }));

      if (newTripData.idPost) {
        setUpdatedFields(state => ({
          ...state,
          startPoint: getPointForDbFromLocationIqPlace(newPlace, lang),
        }))
      }
    } else {
      setNewTripData(tripData => ({
        ...tripData,
        endPoint: getPointForDbFromLocationIqPlace(newPlace, lang),
      }));

      if (newTripData.idPost) {
        setUpdatedFields(state => ({
          ...state,
          endPoint: getPointForDbFromLocationIqPlace(newPlace, lang),
        }))
      }
    }
  };

  const changeDateInputHandler = (newDate) => {
    const chosenDate = new Date(newDate).getTime();

    setNewTripData(tripData => ({
      ...tripData,
      departureDateWithoutTime: chosenDate,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        departureDateWithoutTime: chosenDate,
      }))
    }
  };

  const changeTimeInputHandler = (newTime) => {
    setNewTripData(tripData => ({
      ...tripData,
      departureTimeWithoutDate: newTime,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        departureTimeWithoutDate: newTime,
      }))
    }
  };

  const changePriceInputHandler = (newPrice) => {
    setNewTripData(tripData => ({
      ...tripData,
      pricePerPerson: newPrice,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        pricePerPerson: newPrice,
      }))
    }
  };

  const addNewPhoneHandler = () => {
    const newPassengerPhones = [...newTripData.passengerPhones, {
      hasViber: false,
      hasTelegram: false,
      hasWhatsApp: false,
      phoneNumber: '',
    }];

    setNewTripData(tripData => ({
      ...tripData,
      passengerPhones: newPassengerPhones
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        passengerPhones: newPassengerPhones,
      }))
    }
  };

  const deletePhoneHandler = (currentPhoneIndex) => {
    const result = [...newTripData.passengerPhones];
    result.splice(currentPhoneIndex, 1);
    setNewTripData(tripData => ({...tripData, passengerPhones: result}));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        passengerPhones: result,
      }))
    }
  };

  const phoneInputChangeValueHandler = (newValue, currentPhoneIndex, type) => {
    const result = newTripData.passengerPhones.map((phone, index) =>
      index === currentPhoneIndex ? getNewPhoneObj(phone, newValue, type) : phone);

    setNewTripData(tripData => ({
      ...tripData,
      passengerPhones: result,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        passengerPhones: result,
      }))
    }
  };

  const handleAddOptionClick = (index, checked) => {
    let newOptions = [];

    newOptions = tripAddOptions.map((curOption, curIndex) =>
      curIndex !== index ? curOption : {
      ...curOption,
      checked: checked
    });

    setTripAddOptions(newOptions);

    setNewTripData(tripData => ({
      ...tripData,
      addOptions: newOptions,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        addOptions: newOptions,
      }))
    }
  };

  const updateTripOrder = (fieldName, newValue) => {
    // if (!newValue) {
    //   return;
    // }

    let updatedValue = newValue;

    if (fieldName === 'passengerName' && newValue && !validatePassengerName(newValue)) {
      return;
    }

    setNewTripData(tripData => ({
      ...tripData,
      [fieldName]: updatedValue,
    }));

    if (newTripData.idPost) {
      setUpdatedFields(state => ({
        ...state,
        [fieldName]: updatedValue,
      }))
    }
  }

  const validateAndCutName = (passengerName) => {
    let updatedValue = passengerName.replace(/^[ -]+|[ -]+$/g, '');
    updatedValue = updatedValue.trim();

    if (updatedValue !== passengerName) {
      updateTripOrder( 'passengerName', updatedValue);
    }
  }

  const handleIsPlaceForPetNecessaryToggle = (newValue, fieldName) => {
    let isPlaceNecessary = false;

    if (newValue === translate('Да')) {
      isPlaceNecessary = true;
    }

    updateTripOrder(fieldName, isPlaceNecessary);
  }
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Handling tripOrder saving
  const handleSaveTripOrder = async (newTripData) => {
    setIsLoading(true);

    if (!newTripData.departureTimeWithoutDate || !newTripData.departureDateWithoutTime) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Заполните все поля');

      setIsLoading(false);

      return;
    }

    const timeObj = getHoursAndMinutesFromTime(newTripData.departureTimeWithoutDate);

    const fullDate = new Date(newTripData.departureDateWithoutTime)
      .setHours(timeObj?.hours || '00', timeObj?.minutes || '00',
      '00', '00');

    const today = Date.now();

    if (isAfter(today, fullDate)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Нельзя создать поездку с прошедшей датой');

      setIsLoading(false);

      return;
    }

    let newOrder = {};

    if (isAdmin) {
      newOrder = {
        ...newTripData,
      };
    } else {
      newOrder = {
        ...newTripData,
        dispatcherFullName: user.fullName,
        dispatcherEmail: user.email,
        creatorIdPost: user.idPost,
      };
    }

    newOrder = {
      ...newOrder,
      departureTime: fullDate,
    };

    if (!checkTripOrderCompleteness(newOrder)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Заполните все поля');
      setIsLoading(false);

      return;
    }

    if (!validateFullName(newOrder.passengerName)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Ошибка в имени пользователя. Допускаются' +
        ' только буквы, пробелы и дефисы. Длина не больше 30 символов');
      setIsLoading(false);

      return;
    }

    const hasAnyWrongPhoneNumber = newOrder.passengerPhones
      .some(phone => phone.phoneNumber.length < PHONE_NUMBER_MIN_LENGTH);

    if (hasAnyWrongPhoneNumber) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Некорректный номер телефона');
      setIsLoading(false);

      return;
    }

    if (!isNotepad) {
      if (newTripData?.isVisibleForDrivers) {
        let isPaymentMethodDefined = false;

        switch (true) {
          case newTripData.isPaymentOnCard:
          case newTripData.isPaymentByCash:
          case newTripData.isForChange:
            isPaymentMethodDefined = true;
            break;
        }

        if (!isPaymentMethodDefined) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Необходимо выбрать способ оплаты');
          setIsLoading(false);

          return;
        }

        if (!newTripData?.dispatcherFee || newTripData?.dispatcherFee <= 0) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Сумма гонорара должна быть больше нуля');
          setIsLoading(false);

          return;
        }


        if (newTripData?.dispatcherFee > newTripData.pricePerPerson / 2) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage('Гонорар не может быть больше 50% от суммы поездки');
          setIsLoading(false);

          return;
        }
      }
    }

    try {
      const currentDate = Date.now();

      if (newTripData.idPost) {
        if (Object.keys(updatedFields).length > 0) {
          let actualOrder = await getDocInCollection(collectionsInterface
              .tripsOrders, newTripData.idPost);

          if (actualOrder.hasAnyReservations) {
            const checkResult = checkTripOrderService
              .checkIfTripOrderCouldBeChanged(currentTripOrder, updatedFields);

            if (!checkResult) {
              setIsNotification(true);
              setNotificationTitle('Уведомление');
              setNotificationMessage('Поездка имеет брони, нельзя изменять' +
                ' запрещенные для редактирования поля');

              setCurrentTripOrder(actualOrder);

              return;
            }
          }

          let updatedData = {
            ...updatedFields,
            dateEditing: currentDate,
          }

          if (updatedFields.departureTimeWithoutDate
            || updatedFields.departureDateWithoutTime) {
            updatedData = {
              ...updatedData,
              departureTime: fullDate,
            }
          }

          if (updatedFields.startPoint) {
            updatedData.startPoint = await getTripPointWithTranslations(
              updatedData.startPoint, lang, languages.map(language => language
                .name));
          }

          if (updatedFields.endPoint) {
            updatedData.endPoint = await getTripPointWithTranslations(
              updatedData.endPoint, lang, languages.map(language => language
                .name));
          }

          let cancelledReservationsFromDispatchers = [];
          let cancelledReservationsFromDrivers = [];

          // if (updatedFields.departureDateWithoutTime && currentTripOrder
          //   .departureDateWithoutTime !== updatedFields.departureDateWithoutTime) {
          //
          //   actualTripOrder = await getDocInCollection(collectionsInterface
          //     .tripsOrders, currentTripOrder.idPost);
          //
          //   if (checkActiveReservations(actualTripOrder)) {
          //     const updatedReservationsFromDispatchers = actualTripOrder
          //       .reservationsFromDispatchers.map(reservation => reservation
          //         .isCancelled ? reservation : () => {
          //         const updatedReservation = {
          //           ...reservation,
          //           isCancelled: true,
          //           cancelComment: 'Диспетчер изменил данные пассажира',
          //         }
          //
          //         cancelledReservationsFromDispatchers.push(updatedReservation);
          //
          //         return updatedReservation
          //       });
          //
          //     const updatedReservationsFromDrivers = actualTripOrder
          //       .reservationsFromDispatchers.map(reservation => reservation
          //         .isCancelled ? reservation : () => {
          //         const updatedReservation = {
          //           ...reservation,
          //           isCancelled: true,
          //           cancelComment: 'Диспетчер изменил данные пассажира',
          //         }
          //         cancelledReservationsFromDrivers.push(updatedReservation);
          //         return updatedReservation;
          //       })
          //
          //     updatedData = {
          //       ...updatedData,
          //       reservationsFromDispatchers: updatedReservationsFromDispatchers,
          //       reservationsFromDrivers: updatedReservationsFromDrivers,
          //     }
          //   }
          // }

          const needPetOptionCheck = checkTripOrderService
            .checkAreReservationsNeedPetOptionCheck(currentTripOrder
              .isTripWithPets, updatedFields.isTripWithPets);

          const needByFootOptionCheck = checkTripOrderService
            .checkAreReservationsNeedByFootOptionCheck(currentTripOrder.isByFoot,
              updatedFields.isByFoot);

          if (needPetOptionCheck || needByFootOptionCheck) {
            actualOrder = await getDocInCollection(collectionsInterface
              .tripsOrders, currentTripOrder.idPost);

            cancelledReservationsFromDispatchers =
              checkReservationsByAddOptionsAndGetRejectedList(actualOrder
                .reservationsFromDispatchers, needPetOptionCheck,
                needByFootOptionCheck);

            cancelledReservationsFromDrivers =
              checkReservationsByAddOptionsAndGetRejectedList(actualOrder
                .reservationsFromDrivers, needPetOptionCheck,
                needByFootOptionCheck);

            if (cancelledReservationsFromDispatchers.length > 0) {
              updatedData.reservationsFromDispatchers = getUpdatedReservationsList(
                actualOrder.reservationsFromDispatchers,
                cancelledReservationsFromDispatchers);
            }

            if (cancelledReservationsFromDrivers.length > 0) {
              updatedData.reservationsFromDrivers = getUpdatedReservationsList(
                actualOrder.reservationsFromDrivers,
                cancelledReservationsFromDrivers);
            }
          }

          const reservationsFromDispatchers = updatedData
            .reservationsFromDispatchers || actualOrder.reservationsFromDispatchers;

          const reservationsFromDrivers = updatedData
            .reservationsFromDrivers || actualOrder.reservationsFromDrivers;

          const reservationsFromDispatchersToUpdateOriginalTripOrder =
            reservationsFromDispatchers.filter(reservation => !reservation.isCancelled);

          const reservationsFromDriversToUpdateOriginalTripOrder =
            reservationsFromDrivers.filter(reservation => !reservation.isCancelled);

          const dataForOriginalTripOrder = getDataFromTripOrderToSaveInReservation({
            ...actualOrder,
            ...updatedData,
          });

          if (reservationsFromDispatchersToUpdateOriginalTripOrder.length > 0) {
            updatedData.reservationsFromDispatchers =
              getReservationsListWithUpdatedOriginalTripOrder(
                dataForOriginalTripOrder, reservationsFromDispatchers);

              await updateOriginalTripOrderInReservationsInTrips(
                dataForOriginalTripOrder,
                reservationsFromDispatchersToUpdateOriginalTripOrder,
                'reservationsFromDispatchers');
          }

          if (reservationsFromDriversToUpdateOriginalTripOrder.length > 0) {
            updatedData.reservationsFromDrivers =
              getReservationsListWithUpdatedOriginalTripOrder(
                dataForOriginalTripOrder, reservationsFromDrivers);

            await updateOriginalTripOrderInReservationsInTrips(
              dataForOriginalTripOrder,
              reservationsFromDriversToUpdateOriginalTripOrder,
              'reservationsFromDrivers');
          }

          await updateFieldsInDocumentInCollection(collectionsInterface.tripsOrders,
            newOrder.idPost, updatedData);

          cancelledReservationsFromDispatchers.forEach(reservation => {
              getDocInCollection(collectionsInterface.trips,
              reservation.originalTrip.idPost).then(trip => {
                updateReservationInTripAfterPassengerChanges(trip, reservation,
                  currentTripOrder, true, 'Диспетчер изменил данные пассажира');
              });
            });

          cancelledReservationsFromDrivers.forEach(reservation => {
            getDocInCollection(collectionsInterface.trips,
              reservation.originalTrip.idPost).then(trip => {
              updateReservationInTripAfterPassengerChanges(trip, reservation,
                currentTripOrder, false, 'Диспетчер изменил данные пассажира');
            });
          });

          newOrder = {
            ...newOrder,
            ...updatedData,
          };
        }
      } else {
        const translatedStartPoint = await getTripPointWithTranslations(
          newTripData.startPoint, lang, languages.map(lan => lan.name));

        const translatedEndPoint = await getTripPointWithTranslations(
          newTripData.endPoint, lang, languages.map(lan => lan.name));

        newOrder = {
          ...newOrder,
          isDraft: isNotepad,
          isOpenForSearch: !isNotepad,
          dateEditing: currentDate,
          dateCreating: currentDate,
          endPoint: translatedEndPoint,
          startPoint: translatedStartPoint,
        };

        await setDocumentToCollection(collectionsInterface.tripsOrders, newOrder);

        if (newTripData.isVisibleForDrivers) {
          await findAllSuitableTripsAndSendMessageForTransporters(user, newOrder);
        }
      }

      setNewTripData(tripOrderModel);

      handleGoBack();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`CreateTripOrder ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          {isAdmin &&
            <>
              <div className={'CreateTripOrder-Item'}>
                <div className={'FlexBlock'}>
                  <label className={'DataLabel'}>
                    {translate('Id')}
                  </label>

                  {currentTripOrder.idPost}
                </div>

                <div className={'FlexBlock'}>
                  <label className={'DataLabel'}>
                    {translate('Диспетчер')}
                  </label>

                  <div
                    className={'UserLink'}
                    onClick={() => handleUserClick(currentTripOrder.creatorIdPost,
                      setEditingUser, navigate)}
                  >
                    {currentTripOrder.dispatcherFullName}
                  </div>
                </div>

                <div className={'FlexBlock'}>
                  <label className={'DataLabel'}>
                    {translate('Статус')}
                  </label>

                  {currentTripOrder.isActive
                    ? translate('Активная')
                    : translate('Неактивная')
                  }
                </div>
              </div>
            </>
          }

          {currentTripOrder?.isActive ?
            <>
              <div className='FlexBlock CreateTripOrder-Item'>
                <h2 className={'RegularTitle RegularTitle_Small'}>
                  {translate('Редактирование запрещено.' +
                    ' Поездка взята водителем')}
                </h2>
              </div>

              <DisplayTripOrderDetails
                isAdmin={true}
                handleGoBack={() => navigate(-1)}
                isDispatcher={false}
              />
            </>
            :
            <>
              <div
                className={'CreateTripOrder-Item RegularTitle' +
                  ' RegularTitle_Small DesktopTitle'}
              >
                {translate(screenTitle)}
              </div>

              <h5>
                {translate('Основные данные')}
              </h5>

              <div className={'CreateTripOrder-Item'}>
                <div
                  className={'FlexBlock FlexBlock_Columns ' +
                    'FlexBlock_Columns_OnDesktopToo'}
                >
                  <label className={'CreateTripOrder-Label'}>
                    {translate('ФИО контактного лица')}
                  </label>

                  <input
                    required
                    id='item01'
                    placeholder={translate('ФИО контактного лица')}
                    className={'FormControl'}
                    name={'PassengerFullName'}
                    type={'text'}
                    value={newTripData?.passengerName || ''}
                    onChange={(event) => updateTripOrder('passengerName',
                      event.target.value)}
                    onBlur={(event) => validateAndCutName(event.target.value)}
                  />
                </div>
              </div>

              <div className={'CreateTripOrder-Item'}>
                <label className={'CreateTripOrder-Label'}>
                  {translate('Вид перевозки')}
                </label>

                <RadioFieldSet
                  elementName={'isPassengerTrip'}
                  elementText={
                    `${translate('Пассажирская')}_${translate('Грузовая')}`
                  }
                  inputChangeHandler={isCreator
                    ? changeTripTypeHandler : () => {}}
                  checkedInput={newTripData.isPassengerTrip ?
                    translate('Пассажирская')
                    :
                    translate('Грузовая')
                  }
                  classes={'CreateTripOrder-TripType'}
                  disabled={currentTripOrder?.hasAnyReservations}
                />
              </div>

              <div className={'CreateTripOrder-Item'}>
                {newTripData.passengerPhones.length === 0 &&
                  <button
                    className={'RegularButton'}
                    onClick={() => addNewPhoneHandler()}
                  >
                    {translate('Добавить номер телефона')}
                  </button>
                }
              </div>

              {newTripData.passengerPhones.length > 0 &&
                <label className={'CreateTripOrder-Label'}>
                  {translate('Номера телефона контактного лица')}
                </label>
              }

              {newTripData.passengerPhones.map((phone, index, array) => (
                <React.Fragment key={index}>
                  <div
                    className={index !== array.length - 1
                      ? 'CreateTripOrder-Item BottomBorder'
                      : 'CreateTripOrder-Item'}
                  >
                    <div className={'PhoneRow'}>
                      <PhoneInput
                        name={'PhoneNumbers'}
                        country={'ua'}
                        onChange={(event) => phoneInputChangeValueHandler(
                          event, index, 'number')}
                        specialLabel={''}
                        value={phone.phoneNumber}
                      />

                      <div className={'PhoneAddRemoveButtons'}>
                        {index !== 0 &&
                          <button
                            className={'DelButton'}
                            onClick={() => deletePhoneHandler(index)}
                          >
                            <FaTrash/>
                          </button>
                        }
                      </div>
                    </div>

                    <MessengersRow
                      phoneInputChangeValueHandler={phoneInputChangeValueHandler}
                      phone={phone}
                      index={index}
                    />
                  </div>
                </React.Fragment>
              ))}

              {newTripData.passengerPhones.length < PHONE_NUMBERS_MAX_QUANTITY &&
                <div className={'CreateTripOrder-Item'}>
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast'}
                    onClick={addNewPhoneHandler}
                  >
                    {translate('Добавить номер')}
                  </button>
                </div>
              }

              <div className={'CreateTripOrder-Item'}>
                <TripPoint
                  index={0}
                  changePlaceInputHandler={changePlaceInputHandler}
                  changeDateInputHandler={changeDateInputHandler}
                  changePriceInputHandler={changePriceInputHandler}
                  changeTimeInputHandler={changeTimeInputHandler}
                  tripPointsNames={tripPointsNames}
                  currentPoint={{
                    ...newTripData.startPoint,
                    departureTimeWithoutDate: newTripData.departureTimeWithoutDate,
                    departureDateWithoutTime: newTripData.departureDateWithoutTime,
                    isPassengerTrip: newTripData.isPassengerTrip,
                  }}
                  isPassenger={true}
                  isCreator={true}
                  disabled={currentTripOrder.hasAnyReservations}
                />

                <TripPoint
                  index={1}
                  changePlaceInputHandler={changePlaceInputHandler}
                  changeDateInputHandler={changeDateInputHandler}
                  changePriceInputHandler={changePriceInputHandler}
                  changeTimeIputHandler={changeTimeInputHandler}
                  tripPointsNames={tripPointsNames}
                  currentPoint={{
                    ...newTripData.endPoint,
                    isPassengerTrip: newTripData.isPassengerTrip,
                  }}
                  isPassenger={true}
                  isCreator={true}
                  disabled={currentTripOrder.hasAnyReservations}
                />
              </div>

              <h5>
                {translate('Опции')}
              </h5>

              <div className={'CreateTripOrder-Item'}>
                <div className='FlexBlock FlexBlock_JustifyLeft'>
                  <input
                    type={'checkbox'}
                    className={'OptionCheckBox'}
                    onChange={(event) => updateTripOrder( 'isPassengerAddress',
                      event.target.checked)}
                    checked={newTripData.isPassengerAddress}
                    disabled={!isCreator}
                  />

                  <label
                    className={newTripData.isPassengerAddress
                      ? 'OptionText ColoredText' : 'OptionText'}
                  >
                    {translate('Забрать пассажиров по адресу')}
                  </label>
                </div>

                {newTripData.isPassengerAddress &&
                  <div className={'CreateTripOrder-Item'}>
                    <label
                      htmlFor="DepartureAddress"
                      className={'CreateTripOrder-Label'}
                    >
                      {translate('Адрес пассажиров')}
                    </label>

                    <input
                      required
                      id={'DepartureAddress'}
                      placeholder={translate('Адрес пассажиров')}
                      className={'FormControl CreateTripOrder-Input'}
                      name={'DepartureAddress'}
                      type={'text'}
                      value={newTripData.departureAddress}
                      onChange={(event) => updateTripOrder('departureAddress',
                        event.target.value)}
                    />
                  </div>
                }

                <div className='FlexBlock FlexBlock_JustifyLeft'>
                  <input
                    type={'checkbox'}
                    className={'OptionCheckBox'}
                    onChange={(event) => updateTripOrder(
                      'isSeparatePlaceForLuggage', event.target.checked)}
                    checked={newTripData.isSeparatePlaceForLuggage}
                    disabled={!isCreator}
                  />
                  <label
                    className={newTripData.isSeparatePlaceForLuggage
                      ? 'OptionText ColoredText'
                      : 'OptionText'}
                  >
                    {translate('Отдельное место для багажа')}
                  </label>
                </div>

                {newTripData.isSeparatePlaceForLuggage &&
                  <div className={'CreateTripOrder-Item'}>
                    <label
                      htmlFor='CargoDescription'
                      className={'CreateTripOrder-Label'}
                    >
                      {translate('Описание багажа')}
                    </label>

                    <textarea
                      required
                      id="CargoDescription"
                      placeholder={translate('Опишите багаж')}
                      className={'FormControl'}
                      name={'CargoDescription'}
                      rows={1}
                      value={newTripData.cargoDescription}
                      onChange={(event) => updateTripOrder('cargoDescription',
                        event.target.value)}
                    />
                  </div>
                }

                <div className='FlexBlock FlexBlock_JustifyLeft'>
                  <input
                    type={'checkbox'}
                    className={'OptionCheckBox'}
                    onChange={(event) => updateTripOrder('isTripWithPets',
                      event.target.checked)}
                    checked={newTripData.isTripWithPets}
                    disabled={!isCreator}
                  />

                  <label
                    className={newTripData.isTripWithPets
                      ? 'OptionText ColoredText'
                      : 'OptionText'}>
                    {translate('C животными')}
                  </label>
                </div>

                {newTripData.isTripWithPets &&
                  <div className={'CreateTripOrder-Item'}>
                    <label className={'CreateTripOrder-Label'}>
                      {translate('Нужно ли отдельное место для ' +
                        'перевозки животного')}
                    </label>

                    <RadioFieldSet
                      elementName={'isSeparatePlaceForPets'}
                      elementText={`${translate('Да')}_${translate('Нет')}`}
                      checkedInput={newTripData.isSeparatePlaceForPets ?
                        translate('Да')
                        :
                        translate('Нет')
                      }
                      inputChangeHandler={handleIsPlaceForPetNecessaryToggle}
                    />
                  </div>
                }

                <div className='FlexBlock FlexBlock_JustifyLeft'>
                  <input
                    type={'checkbox'}
                    className={'OptionCheckBox'}
                    onChange={(event) => updateTripOrder('isByFoot',
                      event.target.checked)}
                    checked={newTripData.isByFoot}
                    disabled={!isCreator}
                  />
                  <label
                    className={newTripData.isByFoot ? 'OptionText ColoredText'
                      : 'OptionText'}
                  >
                    {translate('Пеший переход')}
                  </label>
                </div>

                <div className={'FlexBlock FlexBlock_JustifyLeft'}>
                  <input
                    type={'checkbox'}
                    className={'OptionCheckBox'}
                    onChange={(event) => updateTripOrder('isForChange',
                      event.target.checked)}
                    checked={newTripData.isForChange}
                    disabled={!isCreator}
                  />
                  <label
                    className={newTripData.isForChange
                      ? 'OptionText ColoredText' : 'OptionText'}
                  >
                    {translate('По обмену')}
                  </label>
                </div>
              </div>

              <h5>
                {translate('Дополнительные опции')}
              </h5>

              <div className={'CreateTripOrder-Item'}>
                {tripAddOptions.map((option, oIndex) => (
                  <React.Fragment key={oIndex}>
                    <div className='FlexBlock FlexBlock_JustifyLeft'>
                      <input
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        checked={option.checked}
                        onChange={(event) => handleAddOptionClick(oIndex,
                          event.target.checked)}
                        disabled={!isCreator}
                      />
                      <label
                        className={option.checked
                          ? 'OptionText ColoredText' : 'OptionText'}
                      >
                        {option?.optionContent[lang] || option
                          ?.optionContent?.defaultName}
                      </label>
                    </div>
                  </React.Fragment>
                ))}
              </div>

              <h5>
                {translate('Цена и места')}
              </h5>

              <div className={'CreateTripOrder-Item'}>
                <div className={'FlexBlock'}>
                  <div
                    className={
                      'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W70'
                    }
                  >
                    {newTripData.isPassengerTrip
                      ? translate('Цена за одно место')
                      : translate('Стоимость перевозки')
                    }
                  </div>

                  <div
                    className={
                      'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W30'
                    }
                  >

                    <NumberInputWithValidation
                      placeholder={translate('Цена')}
                      name={'TotalPrice'}
                      id={'TotalPrice'}
                      value={newTripData.pricePerPerson}
                      externalChangeHandler={updateTripOrder}
                      fieldName={'pricePerPerson'}
                      disabled={currentTripOrder.hasAnyReservations}
                    />
                  </div>
                </div>
              </div>

              {newTripData.isPassengerTrip &&
                <div className={'CreateTripOrder-Item'}>
                  <div className={'FlexBlock'}>
                    <div
                      className={
                        'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W70'
                      }
                    >
                      {translate('Количество мест')}
                    </div>

                    <div
                      className={
                        'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W30'
                      }
                    >
                      <NumberInputWithValidation
                        placeholder={translate('Количество мест')}
                        value={newTripData.requiredNumberOfPlaces}
                        fieldName={'requiredNumberOfPlaces'}
                        externalChangeHandler={updateTripOrder}
                        name={'NumberOfPlaces'}
                        id={'RequiredNumberOfPlaces'}
                        disabled={newTripData.hasAnyReservations}
                      />
                    </div>
                  </div>
                </div>
              }

              {!newTripData.isPassengerTrip &&
                <div className={'CreateTripOrder-Item'}>
                  <label
                    className={'CreateTripOrder-Label'}
                  >
                    {translate('Описание груза')}
                  </label>
                  <textarea
                    id="cargoDescription"
                    placeholder={translate('Описание груза')}
                    className={'FormControl TextArea'}
                    name={'TripCargoDescription'}
                    rows={1}
                    value={newTripData.cargoDescription}
                    onChange={(event) => updateTripOrder('cargoDescription',
                      event.target.value)}
                  />
                </div>
              }
              <h5>
                {translate('Комментарий к поездке')}
              </h5>

              <div className={'CreateTripOrder-Item'}>
                <textarea
                  id="TripOrderComment"
                  placeholder={translate('Добавьте комментарий')}
                  className={'FormControl TextArea'}
                  name={'TripOrderComment'}
                  rows={1}
                  value={newTripData.tripOrderComment}
                  onChange={(event) => updateTripOrder('tripOrderComment',
                    event.target.value)}
                />
              </div>

              {!isNotepad &&
                <div className={'CreateTripOrder-Item'}>
                  <div className={'ReplaceTripOrderScreen-Item'}>
                    <label className={'ReplaceTripOrderScreen-Label'}>
                      {translate('Гонорар диспетчера')}
                    </label>

                    <NumberInputWithValidation
                      addClasses={'ReplaceTripOrderScreen-Input'}
                      placeholder={translate('Гонорар диспетчера')}
                      fieldName={'dispatcherFee'}
                      value={newTripData?.dispatcherFee || ''}
                      externalChangeHandler={updateTripOrder}
                    />
                  </div>

                  <div className={'ReplaceTripOrderScreen-Item'}>
                    <h5 className={'RegularTitle RegularTitle_Small'}>
                      {translate('Способ оплаты')}
                    </h5>

                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripOrderScreen-Block'}
                    >
                      <div className={'OptionCheckBox'}>
                        <input
                          id={'isPaymentOnCard'}
                          type={'checkbox'}
                          className={'OptionCheckBox'}
                          checked={newTripData?.isPaymentOnCard}
                          onChange={(event) => updateTripOrder('isPaymentOnCard',
                            event.target.checked)
                          }
                          disabled={newTripData?.isPaymentByCash}
                        />
                      </div>

                      <label
                        className={'OptionText'}
                        htmlFor={'isPaymentOnCard'}
                      >
                        {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                      </label>

                      {newTripData?.isPaymentOnCard &&
                        <div
                          className={'ReplaceTripOrderScreen-IconContainer'}
                        >
                          <img
                            src={'/img/bank-safe-icon.svg'}
                            className={'ReplaceTripOrderScreen-Icon'}
                          />
                        </div>
                      }
                    </div>

                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripOrderScreen-Block'}
                    >
                      <input
                        id={'isPaymentByCash'}
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        checked={newTripData?.isPaymentByCash}
                        onChange={(event) => updateTripOrder('isPaymentByCash',
                          event.target.checked)}
                        disabled={newTripData?.isPaymentOnCard}
                      />
                      <label className={'OptionText'}
                             htmlFor={'isPaymentByCash'}>
                        {translate('Наличные по договоренности')}
                      </label>
                    </div>

                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripOrderScreen-Block'}
                    >
                      <input
                        id={'isForChange'}
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        onChange={(event) => updateTripOrder('isForChange',
                          event.target.checked)}
                        checked={newTripData?.isForChange}
                      />
                      <label className={'OptionText'} htmlFor={'isForChange'}>
                        {translate('Обмен')}
                      </label>
                    </div>
                  </div>

                  <div
                    className={'SeparationBlock SeparationBlock_Limited'}></div>

                  <div className={'ReplaceTripOrderScreen-Item'}>
                    <div
                      className={'FlexBlock FlexBlock_JustifyLeft' +
                        ' ReplaceTripOrderScreen-Block'}
                    >
                      <input
                        id={'isVisibleForDrivers'}
                        type={'checkbox'}
                        className={'OptionCheckBox'}
                        onChange={(event) => updateTripOrder(
                          'isVisibleForDrivers', event.target.checked)}
                        checked={newTripData?.isVisibleForDrivers}
                      />

                      <label
                        className={'OptionText OptionText_Bold'}
                        htmlFor={'isVisibleForDrivers'}
                      >
                        {translate('Опубликовать предложение для водителей')}
                      </label>
                    </div>
                  </div>
                </div>
              }

              <div>
                <div className={'CreateTripOrder-Item'}>
                  <button
                    type={'button'}
                    onClick={() => handleSaveTripOrder(newTripData)}
                    className={'RegularButton'}
                  >
                    {translate('Сохранить поездку')}
                  </button>
                </div>
              </div>
            </>
          }
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default CreateTripOrder;
