import React, {useState} from 'react';
import {BsFillPersonFill} from "react-icons/bs";
import {FaSuitcase} from "react-icons/fa";
import {placesSchemeModel} from "../utils/models";
import useTranslation from "../hooks/useTranslation";
import '../styles/PlacesInTripEditing.scss';

const PlacesInTripEditing = ({
  initialScheme,
  addClasses = '',
  saveScheme = () => {},
  isDispatcher = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Places scheme
  const [scheme, setScheme] = useState(initialScheme || placesSchemeModel);
  //#endregion

  //#region Manage scheme displaying
  const [isSchemeVisible, setIsSchemeVisible] = useState(true);
  //#endregion

  //#region Handle all places click
  const [areAllPlacesOccupied, setAreAllPlacesOccupied] = useState(false);

  const handleAllPlacesClick = (newValue) => {
    setAreAllPlacesOccupied(newValue);

    setScheme(curScheme => ({
      ...curScheme,
      rows: curScheme.rows.map((row, rIndex) => ({
        row: row.row.map((cell, cIndex) => ({
          ...cell,
          isOccupied: newValue,
        }))
      }))
    }));
  }
  //#endregion

  //#region Handle place click
  const handleIsOccupiedClick = (newValue, rowIndex, colIndex) => {
    if (areAllPlacesOccupied) {
      setAreAllPlacesOccupied(false);
    }

    const newScheme = {
      ...scheme,
      rows: scheme.rows.map((row, rIndex) => {
        return ({
          row: rIndex !== rowIndex ? row.row :
            row.row.map((cell, cIndex) => {
              if (cIndex === colIndex) {
                return {
                  ...cell,
                  isOccupied: newValue,
                }
              } else {
                return cell;
              }
            })
        });
      })
    }

    setScheme(newScheme);

    if (newValue) {
      let isAnyFreePlace = newScheme.rows.some(row => row.row
        .some(cell => !cell.isOccupied));

      if (!isAnyFreePlace) {
        setAreAllPlacesOccupied(true);
      }
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isSchemeVisible &&
        <div className={`PlacesInTripEditing ${addClasses}`}>
          <div className={'VehicleBody PlacesInTripEditing-VehicleBody'}>
            {!isDispatcher &&
              <div
                className={
                  'PlacesInTripEditing-Head FlexBlock FlexBlock_JustifyLeft'
                }
              >
                <input
                  type={'checkbox'}
                  onChange={(event) => handleAllPlacesClick(
                    event.target.checked)}
                  checked={areAllPlacesOccupied}
                  className={
                    'OptionCheckBox PlacesInTripEditing-AllPlacesCheckBox'
                  }
                  id={'AllPlacesAreOccupied'}
                />

                <label
                  htmlFor={'AllPlacesAreOccupied'}
                  className={'OptionText PlacesInTripEditing-AllPlacesLabel'}
                >
                  {translate('Все заняты')}
                </label>
              </div>
            }

            {scheme?.rows?.map((row, index) => (
              <React.Fragment key={index}>
                <div className={'VehicleRow'}>
                  {row?.row?.map((cell, cIndex) => (
                    <div
                      key={((index + 1).toString() + (cIndex + 1).toString())}
                      className={'PlacesInTripEditing-CellContainer'}
                    >
                      {!cell.isFreeSpace &&
                        <input
                          type={'checkbox'}
                          className={'PlacesInTripEditing-Checkbox'}
                          id={((index + 1).toString() + (cIndex + 1).toString())}
                          checked={cell.isOccupied}
                          onChange={(event) => handleIsOccupiedClick(
                            event.target.checked, index, cIndex)}
                          disabled={isDispatcher}
                        />
                      }

                      <label
                        className={
                          cell.isFreeSpace ? ('VehicleCell'
                          ) : (cell.isOccupied ? 'VehicleCell VehicleCell-Place ' +
                              'PlacesInTripEditing-Place ' +
                              'PlacesInTripEditing-Place_Occupied' :
                              'VehicleCell VehicleCell-Place ' +
                              'PlacesInTripEditing-Place'
                          )}
                        title={cell.description}
                        htmlFor={
                          ((index + 1).toString() + (cIndex + 1).toString())
                        }
                      >
                        {!cell.isFreeSpace &&
                          <span className={'VehicleCell-PlaceIcon'}>
                            {cell.isPassengerPlace ?
                              <BsFillPersonFill
                                title={translate('Пассажирское')}
                              />
                              :
                              <FaSuitcase
                                title={translate('Грузовое')}
                              />
                            }
                          </span>}

                        {cell.placeNumber !== 0 &&
                          <span
                            className={'VehicleCell-PlaceNumber'}
                          >
                            {cell.placeNumber}
                          </span>
                        }
                      </label>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      }

      {!isDispatcher &&
        <div>
          {isSchemeVisible ? (
            <button
              type={'button'}
              className={'RegularButton mt-2'}
              onClick={() => {
                saveScheme(scheme);
                setIsSchemeVisible(false);
              }}
            >
              {translate('Сохранить рассадку')}
            </button>
          ) : (
            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast'}
              onClick={() => setIsSchemeVisible(true)}
            >
              {translate('Изменить рассадку')}
            </button>
          )}
        </div>
      }
    </>
  );
  //#endregion
};

export default PlacesInTripEditing;
