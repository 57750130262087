import React, {useState, useContext} from 'react';
import {saveEvaluation} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import FormInputAlertText from "./FormInputAlertText";
import useTranslation from "../hooks/useTranslation";
import '../styles/EvaluateUser.scss';
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface} from "../utils/models";

const EvaluateUser = ({
  addClasses = '',
  handleEvaluateLater = () => {},
  evaluatedUsers,
  isDispatcher = true,
  setShowModal = () => {},
  setIsLoading = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    currentTripOrder,
    setCurrentTripOrder,
    currentTrip
  } = useContext(AppContext);
  //#endregion

  //#region Handle stars filling
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  //#endregion

  //#region Trip comment
  const [tripComment, setTripComment] = useState('');
  //#endregion

  //#region Handle errors
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  //#endregion

  //#region
  const handleSaveEvaluationClick = async () => {
    setIsLoading(true);

    try {
      if (!tripComment) {
        setIsError(true);
        setErrorText('Необходимо оставить комментарий');

        return;
      }

      if (tripComment.trim().length < 10) {
        setIsError(true);
        setErrorText('Комментарий слишком короткий');

        return;
      }

      if (selectedIndex < 0) {
        setIsError(true);
        setErrorText('Оценка должна быть больше нуля');

        return;
      }

      const result = await saveEvaluation(currentTripOrder, currentTrip, user,
        isDispatcher, tripComment, evaluatedUsers, selectedIndex,
        handleEvaluateLater);

      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, currentTripOrder.idPost);

      setCurrentTripOrder(actualTripOrder);

      if (result) {
        setShowModal(false);
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false)
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`EvaluateUser ${addClasses}`}>
        <div className={'EvaluateUser-EvaluationContainer EvaluateUser-Item'}>
          <div className={'EvaluateUser-StarsTitle'}>
            {isDispatcher ? translate('Поставьте оценку водителю')
              : translate('Поставьте оценку диспетчеру')}
          </div>

          <div
            className={'EvaluateUser-Stars FlexBlock'}
            onMouseLeave={() => setHoveredIndex(-1)}
          >
            {[1, 2, 3, 4, 5].map((elem, index) => (
              <React.Fragment key={index}>
                <img
                  src={index <= hoveredIndex || index <= selectedIndex
                    ? '/img/evaluate-star-icon-filled.svg'
                    : '/img/evaluate-star-icon.svg'}
                  alt={'Star icon'}
                  onMouseEnter={() => {
                    setHoveredIndex(index)
                  }}
                  onClick={() => {
                    if (selectedIndex === index) {
                      setSelectedIndex(-1)
                    } else {
                      setSelectedIndex(index);
                    }
                  }}
                  className={'EvaluateUser-Star'}
                />
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className={'EvaluateUser-Item'}>
          <textarea
            className={'FormControl EvaluateUser-Comment'}
            placeholder={translate('Комментарий к поездке')}
            onChange={(event) => {
              setTripComment(event.target.value);
            }}
            value={tripComment}
          ></textarea>

          {isError &&
            <FormInputAlertText
              text={errorText}
              setIsVisible={setIsError}
              addClasses={'LeftPadding'}
            />
          }
        </div>

        <div className={'EvaluateUser-Item'}>
          <button
            type={'button'}
            className={'RegularButton'}
            onClick={() => handleSaveEvaluationClick()}
          >
            {translate('Отправить')}
          </button>
        </div>

        <div className={'EvaluateUser-Item'}>
          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast'}
            onClick={() => handleEvaluateLater()}
          >
            {translate('Оценить позже')}
          </button>
        </div>
      </div>
    </>
  );
  //#endregion
};

export default EvaluateUser;
