import React, {useState} from 'react';
import PhoneInput from "react-phone-input-2";
import MessengersRow from "./MessengersRow";
import ModalNotification from "../modals/ModalNotification";
import {PHONE_NUMBER_MIN_LENGTH} from "../utils/data";
import '../styles/AddPhone.scss';
import useTranslation from "../hooks/useTranslation";

const AddPhone = ({
  phoneInputChangeValueHandler,
  phone,
  index,
  addClasses = '',
  setIsAddPhoneScreen = () => {},
  setIsMainScreen = () => {},
  deletePhoneHandler = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Handle save phone
  const handleSavePhone = () => {
    if (!phone?.phoneNumber || phone?.phoneNumber?.length < PHONE_NUMBER_MIN_LENGTH) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Некорректный номер телефона');

      return;
    }

    setIsMainScreen(true);
    setIsAddPhoneScreen(false);
  };
  //#endregion

  //#region Handle go back
  const handleGoBack = () => {
    setIsMainScreen(true);
    setIsAddPhoneScreen(false);
    deletePhoneHandler(index);
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`AddPhone ${addClasses}`}>
        <h2 className={'RegularTitle RegularTitle_Small'}>
          {translate('Добавление номера')}
        </h2>

        <div className={'AddPhone-Item'}>
          <label
            className={'AddPhone-Label'}
          >
            {translate('Номер телефона')}
          </label>

          <div className={'PhoneRow'}>
            <PhoneInput
              name={'PhoneNumbers'}
              country={'ua'}
              onChange={(event) => phoneInputChangeValueHandler(event,
                index, 'number')}
              specialLabel={''}
              value={phone?.phoneNumber}
            />
          </div>

          <MessengersRow
            phoneInputChangeValueHandler={phoneInputChangeValueHandler}
            phone={phone}
            index={index}
          />
        </div>

        <div className={'AddPhone-Item'}>
          <button
            type={'button'}
            className={'RegularButton'}
            onClick={() => handleSavePhone()}
          >
            {translate('Сохранить')}
          </button>
        </div>

        <div className={'AddPhone-Item'}>
          <button
            type={'button'}
            className={'RegularButton RegularButton_Contrast'}
            onClick={() => handleGoBack()}
          >
            {translate('Вернуться')}
          </button>
        </div>
      </div>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default AddPhone;
