import React from 'react';
import {AiFillSave} from "react-icons/ai";
import {getCatalogName} from "../utils/helpers";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";

const DisplayAddOptions = ({
  addClasses = '',
  dictionariesInfo = [],
  catalog = [],
  updateCatalog = () => {},
  saveCatalog = () => {},
  catalogId = '',
  catalogName = '',
  areButtonsForEntity = false,
  buttonsData = [],
  isLoading = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`Catalog ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small TitleWithButton'}>
            {translate(getCatalogName(catalogName))}

            <button
              className={'SaveButton'}
              type={'button'}
              onClick={() => saveCatalog(catalogId)}
            >
              <AiFillSave />
            </button>
          </h2>

          <table className={'DataTableForAdmin'}>
            <thead>
            <tr>
              <th
                className={`DataTableForAdmin-Cell HeaderCell HeaderCell_W${
                  Math.round(100 / (dictionariesInfo.length + 1) + (
                    areButtonsForEntity ? 5 : 0))}`}
              >
                {translate('Дефолт')}
              </th>

              {dictionariesInfo.map((dictionary, index) =>
                <React.Fragment key={index}>
                  <th
                    className={'DataTableForAdmin-Cell HeaderCell'}
                  >
                    {dictionary.name.toUpperCase()}
                  </th>
                </React.Fragment>
              )}
            </tr>
            </thead>

            <tbody className={'DataTableForAdmin-Body'}>
              {catalog?.map((element, eIndex) =>
                <React.Fragment key={element.idPost || element.id}>
                  <tr className={'Catalog-DataRow'}>
                    <td className={'DataTableForAdmin-Cell'}>
                      <div className={'DataTableForAdmin-CellWithButtons'}>
                        {areButtonsForEntity &&
                          <div className={'DataTableForAdmin-ButtonsBlock'}>
                            {buttonsData.map((buttonData, index) => (
                              <React.Fragment key={index}>
                                <button
                                  className={'DataTableForAdmin-EntityButton'}
                                  type={'button'}
                                  onClick={() => buttonData.handler(
                                    buttonData.type, !element[buttonData
                                    ?.valueName] || '', eIndex, element.idPost
                                    || element.id)}
                                >
                                  {index === 0 && buttonData.icon.length > 1
                                    ? (element.isVisible ? buttonData.icon[1]
                                      : buttonData.icon[0])
                                    : buttonData.icon
                                  }
                                </button>
                              </React.Fragment>
                            ))}
                          </div>
                        }

                        <textarea
                          value={element.defaultName}
                          onChange={(event) => updateCatalog(
                            event.target.value, element.idPost || element.id,
                            'defaultName', catalogName)}
                          className={'FormControl Catalog-TextArea'}
                        />
                      </div>
                    </td>

                    {dictionariesInfo.map((dictionary, dIndex) =>
                      <React.Fragment key={dIndex}>
                        <td  className={'DataTableForAdmin-Cell'}>
                          <textarea
                            value={element[dictionary.name]}
                            onChange={(event) => updateCatalog(
                              event.target.value, element.idPost || element.id,
                              dictionary.name, catalogName)}
                            className={'FormControl Catalog-TextArea'}
                          />
                        </td>
                      </React.Fragment>
                    )}
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>
      }
    </>
  );
  //#endregion
};

export default DisplayAddOptions;
