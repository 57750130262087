import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface} from "../utils/models";
import LoaderSmall from "./LoaderSmall";
import {
  acceptInvitation,
  checkIfUserCanAcceptInvitation,
  rejectInvitation
} from "../utils/helpers";
import useTranslation from "../hooks/useTranslation";
import '../styles/InvitationCard.scss';

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
}

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
  hourCycle: 'h23',
}

const InvitationCard = ({
  invitation = {},
  setListLoading = () => {},
  setNotificationData = () => {},
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get company name
  const [companyName, setCompanyName] = useState('');

  const getCompanyFromDbAndSetName = async (id) => {
    try {
      const company = await getDocInCollection(collectionsInterface.users, id);

      setCompanyName(company?.companyName || company?.fullName || '');
    } catch (error) {
      setCompanyName('Not found');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCompanyFromDbAndSetName(invitation.companyId).catch(() => {});
  }, []);
  //#endregion


  //#region Handle buttons clicks
  async function updateInvitations() {
    try {
      const updatedUser = await getDocInCollection(collectionsInterface.users,
        user.idPost);

      setUser(updatedUser);
    } catch (error) {}
  }

  async function handleAcceptClick() {
    try {
      setListLoading(true);
      const checkResult = await checkIfUserCanAcceptInvitation(user.idPost,
        user.balanceAccount);

      if (!checkResult) {
        const title = 'Уведомление';
        const message = 'Вы не можете принять приглашение';
        const intro = 'Должны выполняться такие условия:';
        const textArray =['Нулевой остаток ' +
        'на балансе. Отсутствие незакрытых заявок на вывод', 'Все' +
        ' взаиморасчеты по обменам закрыты', 'Все поездкы завершены или отменены'];

        setNotificationData(true, {
          intro,
          message,
          title,
          textArray,
        });

        return;
      }

      await acceptInvitation(invitation, user);

      await updateInvitations();
    } catch (error) {
    } finally {
      setListLoading(false);
    }
  }

  async function handleRejectClick() {
    try {
      setListLoading(true);
      await rejectInvitation(invitation.id, user.idPost, user.fullName,
        invitation.companyId, user.email);

      await updateInvitations();
    } catch (error) {} finally {
      setListLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <div className={'InvitationCard TripCard'}>
        <div className='FlexBlock'>
          <div className={'TripCard-HeadBlock TripCard-DateBlock'}>
            {invitation?.dateCreating ? Intl.DateTimeFormat(lang, dateOptions)
              .format(invitation?.dateCreating) : ''}
            {' '}
            {invitation?.dateCreating ? Intl.DateTimeFormat(lang, timeOptions)
              .format(invitation?.dateCreating) : ''}
          </div>

          <div className={'TripCard-HeadBlock TripCard-PriceBlock'}>
            {isLoading ? <LoaderSmall />
              : companyName || 'Not found'}
          </div>
        </div>

        <div className={'TripCard-MainSection'}>
          {invitation?.hasResponse ?
            <div className={invitation?.isAccepted ? 'InvitationCard-Info'
              : 'InvitationCard-Info InvitationCard-Info_Contrast'}
            >
              {invitation?.isAccepted ? translate('Принято')
                : translate('Отклонено')
              }
            </div>
            :
            <div className='FlexBlock'>
              <button
                type={'button'}
                className={'RegularButton FlexBlock-HorizontalItem_W48'}
                onClick={() => handleAcceptClick()}
              >
                {translate('Принять')}
              </button>

              <button
                type={'button'}
                className={'RegularButton RegularButton_Contrast' +
                  ' FlexBlock-HorizontalItem_W48'}
                onClick={() => handleRejectClick()}
              >
                {translate('Отклонить')}
              </button>
            </div>
          }
        </div>
      </div>
  );
  //#endregion
};

export default InvitationCard;
