import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import TripReservation from "./TripReservation";
import {Route, Routes, useNavigate} from "react-router-dom";
import ConfirmReservation from "./ConfirmReservation";
import ShowSuitableTrips from "./ShowSuitableTrips";
import {sortCardsByDateOfCreation} from "../utils/helpers";

const SuitableTripsScreen = ({
  trips = [],
  addTrips = [],
  addClasses = '',
  isDispatcher = true,
  isPassengerTransfer = false,
  isFromExchange = false,
  isLoading = true,
  onlyMyTrips = false,
  }) => {
  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Filter suitable trips
  const [tripsToDisplay, setTripsToDisplay] = useState([]);

  useEffect(() => {
    const filteredTrips = trips.filter(trip => {
      let checkResult = true;

      const isPresentInReservationsFromDispatchers = trip
        .reservationsFromDispatchers.some(reservation => reservation
          .originalTripOrder.idPost === currentTripOrder.idPost);

      const isPresentInReservationsFromDrivers = trip.reservationsFromDrivers
        .some(reservation => reservation.originalTripOrder
          .idPost === currentTripOrder.idPost);

      if (isPresentInReservationsFromDispatchers
        || isPresentInReservationsFromDrivers) {
        checkResult = false;
      }

      return checkResult;
    });

    setTripsToDisplay(filteredTrips);
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  const handleShowTripDetailsScreen = (trip) => {
    setCurrentTrip(trip);
    navigate('tripReservation');
  }

  const handleShowReservationConfirmationScreen = () => {
    navigate('confirmReservation');
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`SuitableTripsScreen ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <ShowSuitableTrips
                trips={sortCardsByDateOfCreation(tripsToDisplay, false)}
                addTrips={sortCardsByDateOfCreation(addTrips, false)}
                isDispatcher={isDispatcher}
                handleShowTripDetailsScreen={handleShowTripDetailsScreen}
                isFromExchange={isFromExchange}
                isLoading={isLoading}
                onlyMyTrips={onlyMyTrips}
              />
            }
          />

          <Route path={'tripReservation'} element={
            <TripReservation
              handleShowReservationConfirmationScreen={
                handleShowReservationConfirmationScreen}
              isDispatcher={isDispatcher}
              isPassengerTransfer={isPassengerTransfer}
              addClasses={'App-Screen_Narrow'}
            />}
          />

          <Route
            path={'confirmReservation'}
            element={
              <ConfirmReservation
                isDispatcher={isDispatcher}
                isPassengerTransfer={isPassengerTransfer}
                setTripsToDisplay={setTripsToDisplay}
                isFromExchange={isFromExchange}
                addClasses={'App-Screen_Narrow'}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};

export default SuitableTripsScreen;
