//#region Query arrays for different occasions
const myTripsActual = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: false,
    operator: '==',
  },
];

const myTripsArchived = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'isCancelled',
    value: true,
    operator: '==',
  },
  {
    key: 'acceptedDeals',
    value: null,
    operator: '==',
  },
];

const reservedTrips = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '=='
  },
  {
    key: 'isDraft',
    value: false,
    operator: '=='
  },
  {
    key: 'isArchived',
    value: false,
    operator: '==',
  },
  {
    key: 'isOpenForSearch',
    value: true,
    operator: '==',
  },
  {
    key: 'hasAnyReservations',
    value: true,
    operator: '==',
  }
];

const activeTrips = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '=='
  },
  {
    key: 'isDraft',
    value: false,
    operator: '=='
  },
  {
    key: 'isArchived',
    value: false,
    operator: '==',
  },
  {
    key: 'isActive',
    value: true,
    operator: '==',
  },
];

const archivedTrips = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'isOpenForSearch',
    value: false,
    operator: '==',
  },
  {
    key: 'isActive',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  },
];

const passengersOfTransporter = (transporterId) => [
  {
    key: 'acceptedDeal.originalTrip.creatorIdPost',
    value: transporterId,
  },
  {
    key: 'isArchived',
    value: true,
  },
];

const myTripsOrdersActual = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
  },
  {
    key: 'isArchived',
    value: false,
  },
  {
    key: 'isDraft',
    value: false,
  },
  {
    key: 'isOpenForSearch',
    value: true,
  },
  {
    key: 'isActive',
    value: false,
  },
];

const archivedTripsOrders = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'acceptedDeal.id',
    value: null,
    operator: '!=',
  },
  {
    key: 'isActive',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  }
];

const archivedTripsOrdersUnwatchedByDispatcher = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'acceptedDeal.id',
    value: null,
    operator: '!=',
  },
  {
    key: 'hasBeenWatchedByDispatcherAfterCompletion',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  }
];

const archivedTripsOrdersCompletedAfterDate = (creatorId, dateToCompare) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'isDraft',
      value: false,
    operator: '==',
  },
  {
    key: 'completionTime',
    value: dateToCompare,
    operator: '>',
  },
  {
    key: 'isActive',
      value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
      value: true,
    operator: '==',
  }
];

const archivedTripsOrdersWithNewTestimonialFromTransporter = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'driverTestimonial.evaluatedPersonId',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'driverTestimonial.hasBeenWatchedByTransporter',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  }
];

const archivedTripsOrdersWithNewTestimonialFromDispatcher = (transporterId) => [
  {
    key: 'acceptedDeal.originalTrip.creatorIdPost',
    value: transporterId,
    operator: '==',
  },
  {
    key: 'dispatcherTestimonial.hasBeenWatchedByTransporter',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  }
];

const archivedTripsOrdersForTransporterCompletedAfterDate = (transporterId,
  dateToCompare) => [
  {
    key: 'acceptedDeal.originalTrip.creatorIdPost',
    value: transporterId,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'completionTime',
    value: dateToCompare,
    operator: '>',
  },
  {
    key: 'isActive',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  }
];

const archivedTripsOrdersForTransporterUnwatchedAfterCompletion = (transporterId) => [
  {
    key: 'acceptedDeal.originalTrip.creatorIdPost',
    value: transporterId,
    operator: '==',
  },
  {
    key: 'isDraft',
    value: false,
    operator: '==',
  },
  {
    key: 'hasBeenWatchedByTransporterAfterCompletion',
    value: false,
    operator: '==',
  },
  {
    key: 'isActive',
    value: false,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: true,
    operator: '==',
  }
];

const testimonialsUnwatchedByEvaluatedPerson = (evaluatedPersonId) => [
  {
    key: 'evaluatedPersonId',
    value: evaluatedPersonId,
    operator: '==',
  },
  {
    key: 'hasBeenWatchedByEvaluatedPerson',
    value: false,
    operator: '==',
  },
];

const testimonialForUserInCertainTrip = (dealId, userId) => [
  {
    key: 'evaluatedPersonId',
    value: userId,
    operator: '==',
  },
  {
    key: 'dealId',
    value: dealId,
    operator: '==',
  },
];

const tripsOrdersWithUnwatchedByDispatcherDeal = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'acceptedDeal.hasBeenWatchedByDispatcher',
    value: false,
    operator: '==',
  },
  {
    key: 'isActive',
    value: true,
    operator: '==',
  },
];

const tripsWithPassengerUnwatchedByTransporter = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'hasPassengerUnwatchedByTransporter',
    value: true,
    operator: '==',
  },
  {
    key: 'isActive',
    value: true,
    operator: '==',
  },
];

const tripsWithPassengerUnwatchedByDriver = (creatorId, driverId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: `hasPassengerUnwatchedByDrivers.${driverId}`,
    value: true,
    operator: '==',
  },
  {
    key: 'isActive',
    value: true,
    operator: '==',
  },
];

const tripsWithNewReservations = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
    operator: '==',
  },
  {
    key: 'hasNewAction',
    value: true,
    operator: '==',
  },
  {
    key: 'hasAnyReservations',
    value: true,
    operator: '==',
  },
  {
    key: 'isArchived',
    value: false,
    operator: '==',
  },
];

const passengerActiveTrips = (creatorId) => [
  {
    key: 'creatorIdPost',
    value: creatorId,
  },
  {
    key: 'isDraft',
    value: false,
  },
  {
    key: 'isOpenForSearch',
    value: false,
  },
  {
    key: 'isArchived',
    value: false,
  },
  {
    key: 'isActive',
    value: true,
  },
]
//#endregion

export const queryArraysService = {
  myTripsActual,
  myTripsArchived,
  reservedTrips,
  activeTrips,
  archivedTrips,
  passengersOfTransporter,
  myTripsOrdersActual,
  archivedTripsOrders,
  archivedTripsOrdersCompletedAfterDate,
  archivedTripsOrdersWithNewTestimonialFromTransporter,
  archivedTripsOrdersWithNewTestimonialFromDispatcher,
  archivedTripsOrdersForTransporterCompletedAfterDate,
  testimonialsUnwatchedByEvaluatedPerson,
  testimonialForUserInCertainTrip,
  tripsOrdersWithUnwatchedByDispatcherDeal,
  tripsWithPassengerUnwatchedByTransporter,
  tripsWithPassengerUnwatchedByDriver,
  tripsWithNewReservations,
  archivedTripsOrdersUnwatchedByDispatcher,
  archivedTripsOrdersForTransporterUnwatchedAfterCompletion,
  passengerActiveTrips,
}
