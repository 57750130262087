import React, {useEffect, useState, useContext} from 'react';
import {getPointsTranslationAndSetToState} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import LoaderSmall from "./LoaderSmall";
import { memo } from 'react';

const DisplayTripPointsData = memo(function DisplayTripPointsData({
  tripPoints = [],
  addClasses = '',
}) {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Points cities names
  const [tripPointsCitiesNames, setTripPointsCitiesNames] = useState([]);

  useEffect(() => {
    getPointsTranslationAndSetToState(tripPoints, lang, setIsLoading,
      setTripPointsCitiesNames);
  }, [lang]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <LoaderSmall />
        :
        <div className={`DisplayTripPointsData ${addClasses}`}>
          {tripPointsCitiesNames.map((name, index, array) =>
            <React.Fragment key={index}>
              <span>
                {name}
              </span>
              {' '}
              {index !== array.length - 1 &&
                <>
                <span className={'ArrowContainer'}>
                  <img
                    src={'/img/arrow-to-right.svg'}
                    alt={'Arrow image'}
                  />
                </span>
                {' '}
                </>
              }
            </React.Fragment>
          )}
        </div>
      }
    </>
  );
  //#endregion
});

export default DisplayTripPointsData;
