import React from 'react';
import {FaTelegram, FaViber, FaWhatsapp} from "react-icons/fa";
import {formatPhoneNumber} from "../utils/helpers";

const DisplayMessengersRow = ({
  phone,
  addClasses='',
  isPhoneNumberRefactored = false,
  isLinkToCall=false,
}) => {
  //#region Render
  return (
    <React.Fragment>
      <div className={`MessengersRow ${addClasses}`}>
        {isLinkToCall ? (
          <a
            className={'PhoneLink'}
            href={`tel:+${phone?.phoneNumber}`}
            onClick={(event) => {event.stopPropagation()}}
          >
            {isPhoneNumberRefactored
              ? formatPhoneNumber(phone?.phoneNumber)
              : phone?.phoneNumber}
          </a>
        ) : (
          isPhoneNumberRefactored
            ? formatPhoneNumber(phone?.phoneNumber)
            : phone?.phoneNumber
        )}

        {phone?.hasViber &&
          <a
            href={`viber://chat?number=${phone?.phoneNumber}`}
            className={'MessengerIcon MessengerIcon_Viber'}
            target={'_blank'}
            onClick={(event) => event.stopPropagation()}
          >
            <FaViber />
          </a>
        }

        {phone?.hasWhatsApp &&
          <a
            href={`https://api.whatsapp.com/send?phone=${phone?.phoneNumber}`}
            className={'MessengerIcon MessengerIcon_WhatsApp'}
            target={'_blank'}
            onClick={(event) => event.stopPropagation()}
          >
            <FaWhatsapp />
          </a>
        }

        {phone?.hasTelegram &&
          <a
              href={`https://t.me/+${phone?.phoneNumber}`}
              className={'MessengerIcon MessengerIcon_Telegram'}
              target={'_blank'}
              onClick={(event) => event.stopPropagation()}
          >
            <FaTelegram/>
          </a>
        }
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default DisplayMessengersRow;
