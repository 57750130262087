import React, {useContext} from 'react';
import {AppContext} from "../context/appContext";
import {getWithdrawalStatusText} from "../utils/helpers";
import TripStatusBadge from "./TripStatusBadge";
import useTranslation from "../hooks/useTranslation";
import '../styles/WithdrawalCard.scss';

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
}

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
  hourCycle: 'h23',
};

const WithdrawalCard = ({ withdrawal }) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={'WithdrawalCard TripCard'}>
      <div className='FlexBlock'>
        <div className={'TripCard-HeadBlock TripCard-DateBlock'}>
          {withdrawal?.exactTime ? Intl.DateTimeFormat(lang, dateOptions)
            .format(withdrawal?.exactTime) : ''}
          {' '}
          {withdrawal?.exactTime ? Intl.DateTimeFormat(lang, timeOptions)
            .format(withdrawal?.exactTime) : ''}
        </div>

        <div className={'TripCard-HeadBlock TripCard-PriceBlock'}>
          {`${withdrawal?.amount || ''} ${translate('грн')}`}
        </div>

        <div className={'TripCard-HeadBlock TripCard-StatusBlock'}>
          <TripStatusBadge
            statusText={getWithdrawalStatusText(withdrawal?.extraData?.status)}
          />
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default WithdrawalCard;
