import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {AppContext} from "../context/appContext";
import {rolesInterface} from "../utils/models";
import AdminNavMenu from "./AdminNavMenu";
import useTranslation from "../hooks/useTranslation";
import '../styles/Footer.scss';

const Footer = ({addClasses=''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Render
  return (
    <>
      <footer className={`Footer ${addClasses}`}>
        <div className={'Footer-Content'}>
          {user?.role === rolesInterface.admin ?
            <AdminNavMenu addClasses={'Footer-Nav'} isFooter={true}/>
            :
            <nav className={'Footer-Nav NavMenuFooter'}>
              {!user.attachedToCompany &&
                <NavLink
                  className={({isActive}) =>
                    isActive
                      ? 'NavMenuFooter-Item NavMenuFooter-Item_Active'
                      : 'NavMenuFooter-Item'
                  }
                  to={'/notepad'}
                >
                  <div
                    className={'NavMenuFooter-Icon NavMenuFooter-Icon_Notepad'}
                  ></div>

                  <span>
                    {translate('Блокнот')}
                  </span>
                </NavLink>
              }

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'NavMenuFooter-Item NavMenuFooter-Item_Active'
                    : 'NavMenuFooter-Item'
                }
                to={'/trips'}
              >
                <div
                  className={'NavMenuFooter-Icon NavMenuFooter-Icon_Trips'}
                ></div>

                <span>
                  {translate('Поездки')}
                </span>
              </NavLink>

              {!user.attachedToCompany &&
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? 'NavMenuFooter-Item NavMenuFooter-Item_Active'
                      : 'NavMenuFooter-Item'
                  }
                  to={'/passengers'}
                >
                  <div
                    className={
                      'NavMenuFooter-Icon NavMenuFooter-Icon_Passengers'
                    }
                  ></div>

                  <span>
                    {translate('Пассажиры')}
                  </span>
                </NavLink>
              }

              {!user.attachedToCompany &&
                <NavLink
                  className={({isActive}) =>
                    isActive ? 'NavMenuFooter-Item NavMenuFooter-Item_Active'
                      : 'NavMenuFooter-Item'
                  }
                  to={'/exchange'}
                >
                  <div
                    className={'NavMenuFooter-Icon NavMenuFooter-Icon_Exchange'}
                  ></div>

                  <span>
                    {translate('Обмен')}
                  </span>
                </NavLink>
              }

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'NavMenuFooter-Item NavMenuFooter-Item_Active'
                    : 'NavMenuFooter-Item'
                }
                to={'/profile'}
              >
                <div
                  className={'NavMenuFooter-Icon NavMenuFooter-Icon_Profile'}
                ></div>

                <span>
                  {translate('Профиль')}
                </span>
              </NavLink>
            </nav>
          }
        </div>
      </footer>
    </>
  );
  //#endregion
};

export default Footer;
