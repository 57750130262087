import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {isAfter} from "date-fns";
import {transactionsTypesInterface} from "../utils/models";
import {
  addThousandSeparatorToNumber,
  getClassesForTransactionAmount,
  getSignForTransaction,
  getTextForTransaction,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import '../styles/TransactionsHistory.scss';
import useTranslation from "../hooks/useTranslation";

const TransactionsHistory = ({
  addClasses = '',
  transactions = [],
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Sort transactions by date
  const [groupedTransactions, setGroupedTransactions] = useState({});

  const groupTransactionsByDate = (transactions) => {
    if (!transactions || transactions.length === 0) {
      return {};
    }

    let currentDate = '';

    const sortedTransactions = transactions.sort((transaction1, transaction2) => {
      if (isAfter(new Date(transaction2.date), new Date(transaction1.date))) {
        return 1;
      } else {
        return -1;
      }
    });

    currentDate = new Date(sortedTransactions[0].date).setHours(0, 0, 0, 0);

    let groupedTrans = {
      [currentDate]: {
        date: currentDate,
        transactions: [sortedTransactions[0]],
      }
    };

    for (let i = 1; i < sortedTransactions.length; i++) {
      if (currentDate === new Date(sortedTransactions[i].date).setHours(0, 0, 0, 0)) {
        groupedTrans[currentDate].transactions = [
          ...groupedTrans[currentDate].transactions, sortedTransactions[i]];
      } else {
        currentDate = new Date(sortedTransactions[i].date).setHours(0, 0, 0, 0);

        groupedTrans = {
          ...groupedTrans,
          [currentDate]: {
            date: currentDate,
            transactions: [sortedTransactions[i]],
          },
        }
      }
    }

    return groupedTrans;
  }

  const getTransactionsSortedByTime = (transactionsChunk) => {
    const transactions = [...transactionsChunk];

    transactions.sort((prev, next) => next.exactTime - prev.exactTime);

    return transactions;
  }

  useEffect(() => {
    setGroupedTransactions(groupTransactionsByDate(transactions));
  }, [transactions]);
  //#endregion

  //#region Render
  return (
    <div className={`TransactionsHistory ${addClasses}`}>
      {Object.values(groupedTransactions)?.map((group, gIndex) => (
        getTransactionsSortedByTime(group?.transactions)
          ?.map((transaction, index) => {
          return (
            <React.Fragment key={gIndex + index}>
              <div className={'TransactionsHistory-Item'}>
                {index === 0 &&
                  <div className={'TransactionsHistory-Date'}>
                    {showFormattedToLanguageDate(lang, transaction.date)}
                  </div>
                }

                <div className={'TransactionsHistory-Description'}>
                  <span
                    className={'TransactionsHistory-DescriptionText'}>
                      {translate(getTextForTransaction(transaction.type,
                        transaction?.recipient?.idPost === user.idPost))}
                  </span>

                  <span
                    className={getClassesForTransactionAmount(transaction.type,
                      transaction.recipient.idPost === user.idPost)}
                  >
                    {`${getSignForTransaction(transaction.type,
                      transaction?.recipient?.idPost === user.idPost)
                      } ${addThousandSeparatorToNumber(transaction.amount, ' ')
                      } ${translate('грн')}`}
                  </span>
                </div>

                {(transaction.type !== transactionsTypesInterface.accountDeposit
                    && transaction.type !== transactionsTypesInterface.bonuses) &&
                  <div className={'TransactionsHistory-CounterParty'}>
                    {transaction.recipient.idPost === user.idPost ?
                      transaction?.payer?.fullName : transaction?.recipient
                        ?.fullName}
                  </div>
                }
              </div>

              <div
                className={'SeparationBlock SeparationBlock_Limited'}></div>
            </React.Fragment>
          )
        })
      ))}
    </div>
  );
  //#endregion
};

export default TransactionsHistory;
