import React from 'react';
import ReservationDetails from "./ReservationDetails";
import {Route, Routes, useNavigate} from "react-router-dom";
import CarOnMap from "./CarOnMap";
import CarOnMapLocationIQ from "./CarOnMapLocationIQ";

const ActiveTripOrderDetails = ({
  addClasses = '',
  isDispatcher = false,
  handleTransferPassengerClick = () => {},
  updateAfterDealCancellation = () => {},
}) => {
  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Handle click on car on the map
  const handleCarOnMapClick = () => {
    navigate('carOnMap');
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`ActiveTripOrderDetails ${addClasses}`}>

      <Routes>
        <Route
          index
          element={
            <ReservationDetails
              isDispatcher={isDispatcher}
              addClasses={'App-Screen App-Screen_Narrow'}
              handleTransferPassengerClick={handleTransferPassengerClick}
              handleCarOnMapClick={handleCarOnMapClick}
              updateAfterDealCancellation={updateAfterDealCancellation}
            />
          }
        />

        <Route
          path={'carOnMap'}
          element={<CarOnMap/>}
          // element={<CarOnMapLocationIQ />}
        />
      </Routes>
    </div>
    </>);
  //#endregion
};

export default ActiveTripOrderDetails;
