import React, {useContext, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {AppContext} from "../context/appContext";
import ModalNotification from "./ModalNotification";
import LocationIq from "../components/LocationIq";
import {
  getPointForDbFromLocationIqPlace,
  getTranslatedLocalityName
} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import '../styles/ModalAddDriverAddress.scss';

const ModalAddDriverAddress = ({
  showModal,
  setShowModal,
  updateBoardingAddresses = () => {},
  tripData,
  addClasses = '',
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region State and handlers
  const findLargestPlaceNameIndex = () => {
    if (boardingPlacesNames.length < 2) {
      return 0;
    }
    const boardingPlacesNamesWithoutEdgePoint = boardingPlacesNames.slice(1);

    const boardingPlacesOnlyNumbers = boardingPlacesNamesWithoutEdgePoint
      .map(place => place.slice(9));

    const largestIndex = boardingPlacesOnlyNumbers.sort((a, b) => b - a)[0];

    return Number(largestIndex) + 1;
  }

  const [boardingPlaces, setBoardingPlaces] = useState([]);
  const [boardingPlacesNames, setBoardingPlacesNames] = useState([]);
  const [countPlaces, setCountPlaces] = useState(findLargestPlaceNameIndex());

  useEffect(() => {
    if (tripData?.boardingPlacesFromDriver && tripData
      ?.boardingPlacesFromDriver?.length > 0) {
      setBoardingPlaces(tripData.boardingPlacesFromDriver);
      setBoardingPlacesNames(tripData.boardingPlacesNames);
    } else {
      setBoardingPlaces([{fullAddress: {
        default: '',
      }}]);
      setBoardingPlacesNames(['firstAddress']);
    }
  }, []);

  const handlePlaceSelect = (newPlace, index) => {
    const newBoardingPlaces = [...boardingPlaces];
    const placeForDb = getPointForDbFromLocationIqPlace(newPlace, lang);

    newBoardingPlaces.splice(index, 1, {
      ...newBoardingPlaces[index],
      ...placeForDb,
    });

    setBoardingPlaces([...newBoardingPlaces]);
  }

  const addNewDriverAddressHandler = (index) => {
    setCountPlaces(state => state + 1);
    const currentBoardingPlacesNames = [...boardingPlacesNames];
    currentBoardingPlacesNames
      .splice(index + 1, 0, ('nextPlace' + countPlaces));
    setBoardingPlacesNames([...currentBoardingPlacesNames]);

    let newBoardingPlaces = [...boardingPlaces];

    newBoardingPlaces.splice(index + 1, 0, {fullAddress: {
      default: '',
    }});

    setBoardingPlaces([...newBoardingPlaces]);
  }

  const deleteDriverAddressHandler = (index) => {
    const currentBoardingPlacesNames = [...boardingPlacesNames];
    currentBoardingPlacesNames.splice(index, 1);
    setBoardingPlacesNames([...currentBoardingPlacesNames]);

    const newBoardingPlaces = [...boardingPlaces];

    newBoardingPlaces.splice(index, 1);

    setBoardingPlaces([...newBoardingPlaces]);
  }

  const saveDriverAddresses = () => {
    if (!validateBoardingPlacesCompleteness()) {
      return;
    }

    updateBoardingAddresses(boardingPlaces, boardingPlacesNames);

    setShowModal(false);
  }
  //#endregion

  //#region Errors and validation
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorRow, setErrorRow] = useState(null);

  const validateBoardingPlacesCompleteness = () => {
    let isValid = true;

    boardingPlaces.forEach((place, pIndex) => {

      if (!isValid) {
        return;
      }

      if (!place?.fullAddress?.default) {
        setShowError(true);
        setErrorMessage('Вы пытаетесь сохранить пустой адрес. ' +
          'Ошибка в строке номер ');
        setErrorRow((pIndex + 1))
        isValid = false;
      }
    });

    return isValid;
  }
  //#endregion

  //#region Render
  return (
    <>
      <Modal
        show={showModal}
        fullscreen='true'
        onHide={() => {
          setShowModal(false);
        }}
        className={`ModalAddDriverAddress ${addClasses}`}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className={'RegularTitle RegularTitle_Small'}>
              {translate('Добавьте места посадки пассажиров')}
            </h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {boardingPlaces.map((place, bIndex) => (
            <React.Fragment key={boardingPlacesNames[bIndex]}>
              {bIndex === 0 &&
                <>
                  <div className={'ModalAddDriverAddress-Item'}>
                    <label className={'ModalAddDriverAddress-Label'}>
                      {translate('Искать место')}
                    </label>

                    <LocationIq
                      addClassesForInputs={'FullFreeSpace'}
                      externalAction={handlePlaceSelect}
                      index={bIndex}
                      receivedAddress={getTranslatedLocalityName(place
                        .fullAddress, lang)}
                    />
                  </div>

                  <div className={'ModalAddDriverAddress-Item'}>
                    <button
                      type={'button'}
                      className={'RegularButton'}
                      onClick={() => addNewDriverAddressHandler(bIndex)}
                    >
                      {translate('Добавить')}
                    </button>
                  </div>
                </>
              }

              {bIndex > 0 &&
                <div className={'TripFieldsContainer'}>
                  <div className={'ModalAddDriverAddress-Item'}>
                    <LocationIq
                      addClassesForInputs={'FullFreeSpace'}
                      externalAction={handlePlaceSelect}
                      index={bIndex}
                      receivedAddress={getTranslatedLocalityName(place
                        .fullAddress, lang)}
                    />
                  </div>

                  {bIndex < boardingPlaces.length &&
                    <div className={'FlexBlock ModalAddDriverAddress-Item'}>
                      <button
                        type={'button'}
                        className={'RegularButton RegularButton_Contrast ' +
                          'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W45'
                        }
                        onClick={() => deleteDriverAddressHandler(bIndex)}
                      >
                        {translate('Удалить')}
                      </button>
                      <button
                        type={'button'}
                        className={'RegularButton FlexBlock-HorizontalItem ' +
                          'FlexBlock-HorizontalItem_W45'}
                        onClick={() => addNewDriverAddressHandler(bIndex)}
                      >
                        {translate('Добавить')}
                      </button>
                    </div>
                  }
                </div>
              }
            </React.Fragment>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <div className={'FlexBlock ModalAddDriverAddress-Item'}>
            <button
              type={'button'}
              className={'RegularButton FlexBlock-HorizontalItem ' +
                'FlexBlock-HorizontalItem_W60'}
              onClick={() => saveDriverAddresses()}
            >
              {translate('Сохранить адреса')}
            </button>

            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast ' +
                'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
              onClick={() => {
                setShowModal(false)
              }}
            >
              {translate('Вернуться')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {showError &&
        <ModalNotification
          showModal={showError}
          title={'Ошибка ввода данных'}
          setShowModal={setShowError}
          message={errorMessage}
          addData={errorRow}
        />
      }
    </>
  );
  //#endregion
};

export default ModalAddDriverAddress;
