import React, {useContext} from 'react';
import {AppContext} from "../context/appContext";
import {transactionsTypesInterface} from "../utils/models";
import {addThousandSeparatorToNumber} from "../utils/helpers";
import '../styles/TransactionsSummary.scss';
import useTranslation from "../hooks/useTranslation";

const TransactionsSummary = ({
  addClasses = '',
  transactions = [],
  amount = 0,
  reservedAmount = 0,
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Function for returning text
  const getTextForTransaction = (transactionType, isRecipient = true) => {
    switch (transactionType) {
      case transactionsTypesInterface.accountDeposit:
        return translate('Пополнение аккаунта через карту');
      case transactionsTypesInterface.dispatcherFee:
        return isRecipient ? translate('Получение гонорара')
          : translate('Выплата гонорара');
      case transactionsTypesInterface.commissionTaking:
        return translate('Списание комиссии');
      case transactionsTypesInterface.bonuses:
        return translate('Бонусы');
      case transactionsTypesInterface.bonusesForReferralDeal:
        return translate('Бонусы от рефералов');
    }
  }
  //#endregion

  //#region Calculate transactions sum for transaction type
  const calculateTransactionsSumByType = (transactionType, isRecipient = true) => {
    let suitableTransactions = [];

    switch (transactionType) {
      case transactionsTypesInterface.accountDeposit:
        suitableTransactions = transactions.filter(transaction => transaction
          .type === transactionsTypesInterface.accountDeposit);
        break;
      case transactionsTypesInterface.dispatcherFee:
        if (isRecipient) {
          suitableTransactions = transactions
            .filter(transaction => (transaction
                .type === transactionsTypesInterface.dispatcherFee)
              && (transaction.recipient.idPost === user.idPost));
        } else {
          suitableTransactions = transactions
            .filter(transaction => (transaction
                .type === transactionsTypesInterface.dispatcherFee)
              && (transaction.payer.idPost === user.idPost));
        }

        break;
      case transactionsTypesInterface.bonuses:
        suitableTransactions = transactions
          .filter(transaction => transaction.type === transactionsTypesInterface
            .bonuses);
        break;
      case transactionsTypesInterface.commissionTaking:
        if (isRecipient) {
          suitableTransactions = transactions
            .filter(transaction => (transaction.type === transactionsTypesInterface
              .commissionTaking) && (transaction.recipient.idPost === user.idPost));
        } else {
          suitableTransactions = transactions
            .filter(transaction => (transaction.type === transactionsTypesInterface
              .commissionTaking) && (transaction.payer.idPost === user.idPost));
        }
        break;
      case transactionsTypesInterface.bonusesForReferralDeal:
        if (isRecipient) {
          suitableTransactions = transactions
            .filter(transaction => (transaction.type === transactionsTypesInterface
              .bonusesForReferralDeal) && (transaction.recipient.idPost === user.idPost));
        } else {
          suitableTransactions = transactions
            .filter(transaction => (transaction.type === transactionsTypesInterface
              .bonusesForReferralDeal) && (transaction.payer.idPost === user.idPost));
        }
        break;
    }

    return suitableTransactions.reduce((accumulator, current) => accumulator
      + current.amount, 0);
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`TransactionsSummary ${addClasses}`}>
        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {translate('Всего на балансе') + ': '}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {amount + reservedAmount + ' '}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {translate('Свободные средства') + ': '}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {amount + ' '}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {translate('Зарезервированные средства') + ': '}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {reservedAmount + ' '}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {`${getTextForTransaction(transactionsTypesInterface.accountDeposit)}: `}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {`${addThousandSeparatorToNumber(calculateTransactionsSumByType(
              transactionsTypesInterface.accountDeposit), ' ')} `}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {`${getTextForTransaction(transactionsTypesInterface.dispatcherFee, 
              false)}: `}
          </span>

          <span
            className={
              'TransactionsSummary-Amount TransactionsSummary-Amount_Negative'
            }
          >
              {`- ${addThousandSeparatorToNumber(calculateTransactionsSumByType(
                transactionsTypesInterface.dispatcherFee, false), ' ')} `}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {`${translate('Выплата комиссий, бонусов')}: `}
          </span>

          <span
            className={
              'TransactionsSummary-Amount TransactionsSummary-Amount_Negative'
            }
          >
              {`- ${addThousandSeparatorToNumber(calculateTransactionsSumByType(
                transactionsTypesInterface.commissionTaking, false) 
                + calculateTransactionsSumByType(transactionsTypesInterface
                  .bonusesForReferralDeal, false), ' ')} `}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {`${getTextForTransaction(transactionsTypesInterface.dispatcherFee, 
              true)}: `}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {`${addThousandSeparatorToNumber(calculateTransactionsSumByType(
              transactionsTypesInterface.dispatcherFee, true), ' ')} `}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {`${getTextForTransaction(transactionsTypesInterface.bonuses)}: `}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {`${addThousandSeparatorToNumber(calculateTransactionsSumByType(
              transactionsTypesInterface.bonuses, true), ' ')} `}

            {`${translate('грн')}`}
          </span>
        </div>

        <div className={'TransactionsSummary-Item'}>
          <span className={'TransactionsSummary-Description'}>
            {`${getTextForTransaction(transactionsTypesInterface.bonusesForReferralDeal)}: `}
          </span>

          <span className={'TransactionsSummary-Amount'}>
            {`${addThousandSeparatorToNumber(calculateTransactionsSumByType(
              transactionsTypesInterface.bonusesForReferralDeal, true), ' ')} `}

            {`${translate('грн')}`}
          </span>
        </div>
      </div>
    </>
  );
  //#endregion
};

export default TransactionsSummary;
