import React from 'react';
import DisplayMessengersRow from "./DisplayMessengersRow";
import '../styles/FavouriteDriverCard.scss';

const FavouriteDriverCard = ({
  addClasses = '',
  driver,
  deleteFromFavouriteDrivers = () => {},
  handleDriverCardClick = () => {},
}) => {
  //#region Render
  return (
    <>
      <div
        className={`FavouriteDriverCard ${addClasses}`}
        onClick={() => handleDriverCardClick(driver)}
      >
        <div
          className={'FlexBlock FavouriteDriverCard-Item FavouriteDriverCard-NameBlock'}>
          <span>
            {driver?.fullName}
          </span>

          <span className={'FavouriteDriverCard-IconContainer'}>
            <img
              src={'/img/mark-star-icon.svg'}
              alt={'Star icon'}
            />
          </span>

          <span>
            {`${driver?.averageMark || 0} (${driver?.tripsAsDriver || 0})`}
          </span>

          <span
            className={'FavouriteDriverCard-IconContainer FavouriteDriverCard-Button'}
            onClick={(event) => deleteFromFavouriteDrivers(driver, event)}
          >
            <img
              className={'MenuIcon_Clickable'}
              src={'/img/filled-heart-icon.svg'}
              alt={'Heart icon'}
            />
          </span>
        </div>

        {driver?.phoneNumbers?.map((phone, pIndex) => (
          <React.Fragment key={pIndex}>
            <div
              className='FavouriteDriverCard-Item-Item'
            >
              <DisplayMessengersRow
                phone={phone}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
  //#endregion
};

export default FavouriteDriverCard;
