import React, {useContext, useEffect, useState} from 'react';
import WithdrawalCard from "./WithdrawalCard";
import {AppContext} from "../context/appContext";
import {getTransactionsArrayFromObject} from "../utils/helpers";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface, entitiesNumberInterface,
  transactionsTypesInterface
} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";

const WithdrawalsList = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Заявки на вывод');
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get withdrawals list
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    if (user.balanceAccount) {
      getDocInCollection(collectionsInterface.balanceAccounts, user.balanceAccount)
        .then(account => setWithdrawals((getTransactionsArrayFromObject(account
          .transactions, true)).filter(transaction => transaction.type ===
          transactionsTypesInterface.accountWithdrawal)))
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, []);
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [withdrawalsChunk, setWithdrawalsChunk] = useState([]);

  useEffect(() => {
    if (withdrawals.length > 0) {
      setWithdrawalsChunk(withdrawals
        .slice((currentPage - 1) * entitiesNumberInterface.withdrawals,
          currentPage * entitiesNumberInterface.withdrawals));
    }
  }, [withdrawals.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'DataListWithPagination'}>
            <div className={'ListContainer ListContainer_Extended'}>
              <div className={'CardsList'}>
                {withdrawalsChunk.map((withdrawal, index) =>
                  <React.Fragment key={withdrawal.exactTime || index}>
                    <WithdrawalCard
                      withdrawal={withdrawal}
                    />
                  </React.Fragment>)
                }
              </div>
            </div>

            {withdrawals.length > entitiesNumberInterface.withdrawals &&
              <PaginationBlock
                portionSize={entitiesNumberInterface.withdrawals}
                listLength={withdrawals.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          </div>
        </div>
      }
    </>
  )
  //#endregion
};

export default WithdrawalsList;
