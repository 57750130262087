import React, {useState, useEffect} from 'react';
import {placesSchemeItemModel, placesSchemeModel} from "../utils/models";
import {setDocumentToCollection} from "../utils/firebaseConfigAndFunctions";
import {
  validatePlacesScheme
} from "../utils/helpers";
import {AiFillEdit} from "react-icons/ai";
import ModalPlaceDescription from "../modals/ModalPlaceDescription";
import ModalShowVehicleScheme from "../modals/ModalShowVehicleScheme";
import ModalNotification from "../modals/ModalNotification";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";
import '../styles/PlacesConstructor.scss';

const PlacesConstructor = ({
  setSchemeId,
  addClasses = '',
  addPlacesScheme,
  placesNumber = 0,
  setIsLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  const [rowsNumber, setRowsNumber] = useState('');
  const [colsNumber, setColsNumber] = useState('');
  const [currentRow, setCurrentRow] = useState(-1);
  const [currentCol, setCurrentCol] = useState(-1);
  const [currentCell, setCurrentCell] = useState(placesSchemeItemModel);
  const [table, setTable] = useState([]);
  const [isPlaceDataVisible, setIsPlaceDataVisible] = useState(false);
  const [isShowSchemeVisible, setIsShowSchemeVisible] = useState(false);

  useEffect(() => {
    let rows = [];
    for (let i = 0; i < rowsNumber; i++) {
      let cols = [];
      for (let j = 0; j < colsNumber; j++) {
        cols.push({
          ...placesSchemeItemModel,
          row: i,
          col: j,
        });
      }
      rows.push(cols);
    }
    setTable(rows);
  }, [rowsNumber, colsNumber]);

  const updateConfiguration = (fieldName, value) => {
    if (fieldName === 'rows') {
      setRowsNumber(value);

      return;
    }

    setColsNumber(value);
  }

  const handlePlaceDetailsClick = (cell, row, col, isEmpty) => {
    if (!isEmpty) {
      setCurrentCell(cell);
      setCurrentRow(row);
      setCurrentCol(col);
      setIsPlaceDataVisible(true);
    }
  }

  const updatePlaceOnScheme = (row, col, placeData) => {
    const newTable = table.map((tRow, rIndex, array) => (
      rIndex === row ? tRow.map((tCell, cIndex) => (
        cIndex === col ? {...tCell, ...placeData} : tCell
      )) : tRow
    ));

    setTable(newTable);
  }

  const handleShowSchemeClick = () => {
    setIsShowSchemeVisible(true);
  }

  const createScheme = (currentScheme) => {
    let rows = [];
    for (let i = 0; i < rowsNumber; i++) {
      let cols = [];
      for (let j = 0; j < colsNumber; j++) {
        cols.push(currentScheme[i][j]);
      }
      rows.push(cols);
    }
    setTable(rows);

    const rowsObj = {
      ...placesSchemeModel,
      rows: rows.map((row, index) => ({row: row})),
    };

    return rowsObj;
  }

  const handleSaveSchemeClick = async () => {
    setIsLoading(true);

    const validationResult = validatePlacesScheme(table, placesNumber);

    if (validationResult.isError) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage(validationResult.errorMessage);

      setIsLoading(false);

      return;
    }

    const schemeForSave = createScheme(table);

    try {
      const scheme = await setDocumentToCollection('placesSchemes',
        schemeForSave)
      setSchemeId(scheme.result.id);

      addPlacesScheme({
        ...schemeForSave,
        idPost: scheme.result.id,
      }, 'placesScheme');
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }

  //#region Render
  return (
    <>
      <div className={`PlacesConstructor ${addClasses}`}>
        <div className={'InputsRowBlock PlacesConstructor-Item'}>
          <NumberInputWithValidation
            addClasses={'PlacesConstructor-Input'}
            externalChangeHandler={updateConfiguration}
            fieldName={'rows'}
            placeholder={translate('Количество рядов')}
            value={rowsNumber}
          />

          <NumberInputWithValidation
            addClasses={'PlacesConstructor-Input'}
            externalChangeHandler={updateConfiguration}
            fieldName={'cols'}
            placeholder={translate('Количество мест в ряду')}
            value={colsNumber}
          />
        </div>

        {table.map((r, index) => (
          <div key={index} className={'EditPlaceRow'}>
            {r.map((c, cIndex) => (
              <div
                key={(index + 1).toString() + (cIndex + 1).toString()}
                className={'EditPlaceCol'}
              >
                <div className={'FieldsContainer'}>
                  <button
                    type={'button'}
                    className={c.isFreeSpace ? 'EditPlaceButton' +
                      ' EditPlaceButton_Disabled' : 'EditPlaceButton'}
                    onClick={() => handlePlaceDetailsClick(c, index, cIndex,
                      c.isFreeSpace)}
                  >
                    <AiFillEdit/>
                  </button>
                  <input
                    type={'checkbox'}
                    onChange={() => updatePlaceOnScheme(index, cIndex,
                      {
                        ...placesSchemeItemModel,
                        isFreeSpace: !c.isFreeSpace,
                      })}
                    className={'EditPlaceCheckBox'}
                  />
                </div>
              </div>
            ))
            }
          </div>
        ))}

        {isPlaceDataVisible &&
          <ModalPlaceDescription
            showModal={isPlaceDataVisible}
            setShowModal={setIsPlaceDataVisible}
            row={currentRow}
            col={currentCol}
            cellData={currentCell}
            updatePlaceOnScheme={updatePlaceOnScheme}
          />
        }

        {(table.length > 0 && table[0]?.length > 0) &&
          <div className={'PlacesConstructor-Item'}>
            {translate('Обязательно добавьте номера мест в схеме с ' +
              'помощью кнопки "Редактировать"')}
          </div>
        }

        <div className={'PlacesConstructor-Item'}>
          <button
            type={'button'}
            className={'RegularButton PlacesConstructor'}
            onClick={() => handleShowSchemeClick()}
          >
            {translate('Отобразить схему')}
          </button>
        </div>

        <button
          type={'button'}
          className={'RegularButton PlacesConstructor-ContrastButton'}
          onClick={handleSaveSchemeClick}
        >
          {translate('Сохранить схему')}
        </button>
      </div>

      {isShowSchemeVisible &&
        <ModalShowVehicleScheme
          showModal={isShowSchemeVisible}
          setShowModal={setIsShowSchemeVisible}
          schemeToDisplay={table}
        />
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default PlacesConstructor;
