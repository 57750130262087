import React, {useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {vehicleModel} from "../utils/models";
import VehicleCard from "./VehicleCard";
import DisplayVehicleDetails from "./DisplayVehicleDetails";
import {useNavigate} from "react-router-dom";
import useTranslation from "../hooks/useTranslation";
import '../styles/CarsData.scss';

const CarsDataScreen = ({
  addClasses = '',
  currentVehicle = vehicleModel,
  setCurrentVehicle = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screens data from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Handle go back from vehicle details
  const handleGoBackFromDetails = () => {
    setCurrentVehicle(vehicleModel);
  }
  //#endregion

  //#region Handle add vehicle click
  const handleAddVehicleClick = () => {
    setScreenTitle('Добавить ТС');
    navigate('addVehicle');
  }
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Мои автомобили');
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      {!currentVehicle?.idPost ?
        <div className={`CarsData ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'CarsList CarsData-CarsList'}>
            {user?.vehicles.map(vehicle =>
              <React.Fragment key={vehicle.idPost}>
                <VehicleCard
                  addClasses={'CarsList-Item'}
                  vehicle={vehicle}
                  setCurrentVehicle={setCurrentVehicle}
                />
              </React.Fragment>
            )}

            <button
              type={'button'}
              className={'RegularButton CarsList-AddVehicleButton'}
              onClick={() => handleAddVehicleClick()}
            >
              {translate('Добавить')}
            </button>
          </div>
        </div>
        :
        <DisplayVehicleDetails
          addClasses={'CarsData-DisplayVehicleDetails'}
          goBack={() => handleGoBackFromDetails()}
          setCurrentVehicle={setCurrentVehicle}
          vehicle={currentVehicle}
        />
      }
    </>
  );
  //#endregion
};

export default CarsDataScreen;
