import React, {useEffect, useState, useContext} from 'react';
import {Col, Dropdown, Row} from "react-bootstrap";
import {AppContext} from "../context/appContext";
import {
  collectionsInterface,
  dictionaryModel,
  entitiesNumberInterface
} from "../utils/models";
import {isoLangs} from "../utils/data";
import {
  getCollection,
  getDocInCollection,
  updateDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import '../styles/DictionariesEditing.scss';
import ModalNotification from "../modals/ModalNotification";
import Loader from "./Loader";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";

const DictionariesEditing = ({ addClasses = '' }) => {
  //#region Get language from context
  const {
    lang,
    setDictionary,
    setDictionaries
  } = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen title managing function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title and current trip order on component mount
  useEffect(() => {
    setScreenTitle('Языки');
  }, []);
  //#endregion

  //#region Dictionaries and setting there initial states
  const [mainDictionary, setMainDictionary] = useState(dictionaryModel);
  const [mainDictionaryEntries, setMainDictionaryEntries] = useState([]);
  const [editingDictionary, setEditingDictionary] = useState(dictionaryModel);
  const [readyDictionaries, setReadyDictionaries] = useState([]);
  const [newPossibleDictionaries, setNewPossibleDictionaries] = useState([]);

  useEffect(() => {
    getDocInCollection(collectionsInterface.dictionaries, '8RiWx0Ktl729nrGbOL3G')
      .then(dictionaries => {
      setMainDictionary(dictionaries.dictionaries[0]);
      const mainEntries = Object.entries(dictionaries.dictionaries[0].dictionary);
      setMainDictionaryEntries(mainEntries);

      setReadyDictionaries(dictionaries.dictionaries);

      let filteredNewDictionaries = isoLangs.filter(lang => {
        if (!dictionaries.dictionaries.some(dict => dict.name === lang.code)) {
          return true;
        }
      });

      setNewPossibleDictionaries(filteredNewDictionaries);
      let newDictionary = {};

      for (let i = 0; i < mainEntries.length; i++) {
        newDictionary = {
          ...newDictionary,
          [mainEntries[i][0]]: '',
        }
      };

      setEditingDictionary({
        name: 'newDict',
        description: translate('Выбрать язык'),
        dictionary: newDictionary,
      });
    }).catch(() => {});
  }, []);
  //#endregion

  //#region Handle choose language for adding or editing
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [isEditingLanguage, setIsEditingLanguage] = useState(false);
  const [areDictionariesVisible, setAreDictionariesVisible] = useState(false);
  const [isNewLanguage, setIsNewLanguage] = useState(false);

  const handleGoToDictionaryEditing = () => {
    if (editingDictionary.name === 'newDict') {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Выберите язык');
      return;
    }

    setAreDictionariesVisible(true);
  };

  const handleAddLanguage = () => {
    setIsEditingLanguage(false);
    setIsAddingLanguage(true);

    setEditingDictionary({
      ...dictionaryModel,
      name: 'newDict',
      description: translate('Выбрать новый язык'),
    });
  };

  const handleEditLanguage = () => {
    setIsEditingLanguage(true);
    setIsAddingLanguage(false);

    setEditingDictionary(dict => ({
      ...dict,
      name: 'newDict',
      description: translate('Выбрать язык из БД'),
    }));
  }
  //#endregion

  //#region Handling translation filling
  const changeTranslation = (newValue, fieldToTranslate) => {
    let newEditingDictionary = {
      ...editingDictionary,
      dictionary: {
        ...editingDictionary.dictionary,
        [fieldToTranslate]: newValue,
      }
    }

    setEditingDictionary(newEditingDictionary);
  };

  const handleNewLanguageClick = (lang) => {
    setEditingDictionary(dict => ({
      ...dict,
      name: lang.code,
      description: lang.nativeName,
    }));
    setIsNewLanguage(true);
  };

  const handleLanguageFromDbClick = (lang) => {
    setEditingDictionary(lang);
    setIsNewLanguage(false);
  };

  const handleGoBackToChoose = () => {
    setAreDictionariesVisible(false);
  };
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Save new language or edited language
  const updateDictionaries = async () => {
    try {
      let dictionaries = await getCollection('dictionaries');
      setDictionaries(dictionaries[0].dictionaries);
      const currentDictionary = dictionaries[0].dictionaries
        .find(dict => dict.name === lang);
      setDictionary(currentDictionary);
      setReadyDictionaries(dictionaries[0].dictionaries);
    } catch (error) {
      return error;
    };
  }

  const createDictionariesWithNewLanguage = (dictionary, dictionaries) => {
    let newDictionaries = [...dictionaries];

    newDictionaries.push(dictionary);

    return newDictionaries;
  };

  const createDictionariesWithEditedLanguage = (dictionary, dictionaries) => {
    let newDictionaries = dictionaries
      .map(dict => dictionary.name === dict.name ? dictionary : dict);

    return newDictionaries;
  };

  const handleSaveLanguage = async (isNewLang, dictionary, dictionaries) => {
    setIsLoading(true);

    let newDictionaries = {};

    if (isNewLang) {
      newDictionaries = createDictionariesWithNewLanguage(dictionary,
        dictionaries);
    } else {
      newDictionaries = createDictionariesWithEditedLanguage(dictionary,
        dictionaries);
    }

    try {
      await updateDocumentInCollection('dictionaries', {
        dictionaries: newDictionaries,
      }, '8RiWx0Ktl729nrGbOL3G');

      await updateDictionaries();

      setAreDictionariesVisible(false);
      let newDictionary = {};

      for (let i = 0; i < mainDictionaryEntries.length; i++) {
        newDictionary = {
          ...newDictionary,
          [mainDictionaryEntries[i][0]]: '',
        }
      };

      setEditingDictionary({
        name: 'newDict',
        description: 'Выбрать язык',
        dictionary: newDictionary,
      });
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [mainDictionaryEntriesChunk, setMainDictionaryEntriesChunk] =
    useState([]);

  useEffect(() => {
    setCurrentPage(1);
    setStartPage(1);
  }, [editingDictionary.name]);

  useEffect(() => {
    if (mainDictionaryEntries.length > 0) {
      setMainDictionaryEntriesChunk(mainDictionaryEntries
        .slice((currentPage - 1) * entitiesNumberInterface.languages,
          currentPage * entitiesNumberInterface.languages));
    }
  }, [currentPage, mainDictionaryEntries]);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`DictionariesEditing ${addClasses}`}>
        <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
          {translate(screenTitle)}
        </h2>

        <div className={'PaddingBlock PaddingBlock_Half'}></div>

        {!areDictionariesVisible &&
          <>
            <Row>
              <Col xs={6} md={6}>
                <button
                  type={'button'}
                  className={'RegularButton'}
                  onClick={() => handleAddLanguage()}
                >
                  {translate('Добавить язык')}
                </button>
              </Col>

              <Col xs={6} md={6}>
                <button
                  type={'button'}
                  className={'RegularButton RegularButton_Contrast'}
                  onClick={() => handleEditLanguage()}
                >
                  {translate('Изменить перевод')}
                </button>
              </Col>
            </Row>

            <div className={'PaddingBlock'}></div>

            {isAddingLanguage &&
              <Row className={'mb--20'}>
                <Col xs={6} md={6}>
                  <Dropdown
                    className={
                      'DictionariesEditing-SelectLanguageButtonContainer'
                    }
                  >
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      className={'DictionariesEditing-SelectLanguageButton'}
                    >
                      {editingDictionary.description}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {
                        newPossibleDictionaries.map((lang, lIndex) => {
                          return (
                            <Dropdown.Item
                              key={lang.code}
                              value={lang.code}
                              onClick={() => handleNewLanguageClick(lang)}
                            >
                              {`${lang.name} (${lang.nativeName})`}
                            </Dropdown.Item>
                          )
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col xs={6} md={6}>
                  <button
                    type={'button'}
                    className={'RegularButton'}
                    onClick={() => handleGoToDictionaryEditing()}
                  >
                    {translate('Подтвердить')}
                  </button>
                </Col>
              </Row>
            }

            {isEditingLanguage &&
              <Row>
                <Col xs={6} md={6}>
                  <Dropdown
                    className={
                      'DictionariesEditing-SelectLanguageButtonContainer'
                    }
                    defaultValue={'uk'}
                  >
                    <Dropdown.Toggle
                      variant="primary"
                      id="dropdown-basic"
                      className={'DictionariesEditing-SelectLanguageButton'}
                    >
                      {editingDictionary.description}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {
                        readyDictionaries.map((lang, lIndex) => {
                          return (
                            <Dropdown.Item
                              key={lIndex}
                              value={lang.name}
                              onClick={() => handleLanguageFromDbClick(lang)}
                            >
                              {`${lang.description}`}
                            </Dropdown.Item>
                          )
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                <Col xs={6} md={6}>
                  <button
                    type={'button'}
                    className={'RegularButton'}
                    onClick={() => handleGoToDictionaryEditing()}
                  >
                    {translate('Подтвердить')}
                  </button>
                </Col>
              </Row>
            }
          </>
        }

        {areDictionariesVisible &&
          <>
            {isLoading ? <Loader />
              :
              <>
                <Row className={'mb--20'}>
                  <Col xs={6} md={6}>
                    <button
                      type={'button'}
                      className={'RegularButton RegularButton_Contrast'}
                      onClick={() => handleGoBackToChoose()}
                    >
                      {translate('Назад к выбору')}
                    </button>
                  </Col>

                  <Col xs={6} md={6}>
                    <button
                      type={'button'}
                      className={'RegularButton'}
                      onClick={() => handleSaveLanguage(isNewLanguage,
                        editingDictionary, readyDictionaries)}
                    >
                      {translate('Сохранить словарь')}
                    </button>
                  </Col>
                </Row>

                <div className={'PaddingBlock'}></div>
                <div
                  className={'DataListWithPagination DictionariesEditing-DataList'}
                >
                  <table className={'DataTableForAdmin'}>
                    <thead>
                    <tr>
                      <th
                        className={
                          'HeaderCell DataTableForAdmin-Cell HeaderCell_W50'
                        }
                      >
                        <div>
                          {`${translate('Обозначение')}: ${
                            mainDictionary?.name}`}
                        </div>

                        <div>{`${translate('Наименование')}: ${
                          mainDictionary?.description}`}
                        </div>
                      </th>

                      <th className={'HeaderCell DataTableForAdmin-Cell'}>
                        <div>
                          {`${translate('Обозначение')}: ${
                            editingDictionary.name}`}
                        </div>

                        <div>
                          {`${translate('Наименование')}: ${
                            editingDictionary.description}`}
                        </div>
                      </th>
                    </tr>
                    </thead>

                    <tbody>
                      {mainDictionaryEntriesChunk.map((entry, index) => (
                        <React.Fragment key={index}>
                          <tr
                            className={'DataRow'}
                          >
                            <td className={'DataTableForAdmin-Cell'}>
                              {entry[1]}
                            </td>

                            <td className={'DataTableForAdmin-Cell'}>
                              <textarea
                                className={'FormControl TextArea Catalog-TextArea'}
                                value={editingDictionary.dictionary[entry[0]] ||
                                  ''}
                                onChange={
                                  (event) => changeTranslation(
                                    event.target.value, entry[0])
                                }
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>

                  {(mainDictionaryEntries.length > entitiesNumberInterface
                      .languages) &&
                    <PaginationBlock
                      startPage={startPage}
                      currentPage={currentPage}
                      setStartPage={setStartPage}
                      setCurrentPage={setCurrentPage}
                      portionSize={entitiesNumberInterface.languages}
                      listLength={mainDictionaryEntries.length}
                    />
                  }
                </div>
              </>
            }
          </>
        }
      </div>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default DictionariesEditing;
