import React, {useState, useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import AddVehicle from "./AddVehicle";
import {Route, Routes, useNavigate} from "react-router-dom";
import CarsDataScreen from "./CarsDataScreen";
import {vehicleModel} from "../utils/models";
import '../styles/CarsData.scss';

const CarsData = ({addClasses=''}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Current vehicle
  const [currentVehicle, setCurrentVehicle] = useState(vehicleModel);
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <Routes>
        <Route
          index
          element={
            <CarsDataScreen
              addClasses={addClasses}
              currentVehicle={currentVehicle}
              setCurrentVehicle={setCurrentVehicle}
            />
          }
        />

        <Route
          path={'/addVehicle'}
          element={
            <AddVehicle
              addClasses={'CarsData-AddVehicle'}
              goBack={false}
            />
          }
        />

        <Route
          path={'/editVehicle'}
          element={
            <AddVehicle
              addClasses={'CarsData-AddVehicle'}
              goBack={() => navigate(-1)}
              isEditing={true}
              vehicle={currentVehicle}
              setCurrentVehicle={setCurrentVehicle}
            />
          }
        />
      </Routes>
    </>
  );
  //#endregion
};

export default CarsData;
