import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  textPagesTypesInterface,
} from "../utils/models";
import Loader from "../components/Loader";
import '../styles/RulesAndConfidentialityPolicy.scss';
import {textPagesDisplayingService} from "../utils/textPagesDisplayingService";

const RulesAndConfidentialityPolicy = ({
  addClasses = '',
  policyName = textPagesTypesInterface.rulesAndPolicy,
}) => {
  //#region Get language from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Get text from DB and set to state
  const [policyText, setPolicyText] = useState('');

  useEffect(() => {
    textPagesDisplayingService.getTranslatedPageData(policyName, lang).then(pageData => {
      setPolicyText(pageData?.text || '');
      setScreenTitle(pageData.title || '');
    })
      .catch(() => {}).finally(() => setIsLoading(false));
  }, [lang]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`RulesAndConfidentialityPolicy ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <h2 className={'RegularTitle RegularTitle_Small'}>
            {screenTitle}
          </h2>

          <div
            className={'RulesAndConfidentialityPolicy-Text'}
            dangerouslySetInnerHTML={{__html: policyText}}
          />
        </div>
      }
    </>
  );
  //#endregion
};

export default RulesAndConfidentialityPolicy;
