import React, {useContext, useEffect} from 'react';
import {Nav, Tab} from "react-bootstrap";
import DisplayCreatedTripsOrders from "./DisplayCreatedTripsOrders";
import DisplayCreatedTrips from "./DisplayCreatedTrips";
import {AppContext} from "../context/appContext";
import {tripModel, tripOrderModel} from "../utils/models";
import useTranslation from "../hooks/useTranslation";

const NotePadTabs = ({
  tripsOrdersToDisplay = [],
  handleShowCreateTripOrder = () => {},
  handleShowCreateTrip = () => {},
  userTrips = [],
  handleShowTripDetailsScreen = () => {},
  activeTabKey = 'passengers',
  handleTripOrderCardClick = () => {},
  setActiveTabKey = () => {},
  isTripOrdersLoading = true,
  isTripsLoading = true,
  setIsTripOrdersLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTrip, setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screenTitle function from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Блокнот');
  }, []);

  //#region Set current trip and current trip order initial values
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
  }, []);
  //#endregion

  //#region Render
  return (
    <Tab.Container
      activeKey={activeTabKey}
    >
      <Nav variant="pills" className={'flex-row TabControls'}>
        <div className={'TabControls-AddButtonContainer' +
          ' TabControls-AddButtonContainer_Left'}>
          <button
            type={'button'}
            onClick={() => {
              handleShowCreateTripOrder();
            }}
            className={'RegularButton TabControls-AddButton'}
          >
            {translate('Добавить пассажира')}
          </button>
        </div>

        <Nav.Item>
          <Nav.Link
            eventKey='passengers'
            onClick={() => setActiveTabKey('passengers')}
          >
            {translate('Пассажиры')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='trips'
            onClick={() => setActiveTabKey('trips')}
          >
            {translate('Поездки')}
          </Nav.Link>
        </Nav.Item>

        <div className={'TabControls-AddButtonContainer' +
          ' TabControls-AddButtonContainer_Right'}>
          <button
            type={'button'}
            onClick={() => {
              handleShowCreateTrip();
            }}
            className={'RegularButton TabControls-AddButton'}
          >
            {translate('Добавить поездку')}
          </button>
        </div>
      </Nav>

      <Tab.Content>
        <Tab.Pane eventKey='passengers'>
          <DisplayCreatedTripsOrders
            tripsOrdersToDisplay={tripsOrdersToDisplay}
            handleShowCreateTrip={handleShowCreateTripOrder}
            handleTripOrderCardClick={handleTripOrderCardClick}
            isNotepad={true}
            isLoading={isTripOrdersLoading}
            setIsLoading={setIsTripOrdersLoading}
          />
        </Tab.Pane>

        <Tab.Pane eventKey='trips'>
          <DisplayCreatedTrips
            trips={userTrips}
            handleShowTripDetailsScreen={handleShowTripDetailsScreen}
            handleShowCreateTrip={handleShowCreateTrip}
            isNotepad={true}
            isLoading={isTripsLoading}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
  //#endregion
};
//#endregion

export default NotePadTabs;
