import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  deleteTripOrderHandler,
  getStatusTextForAdmin,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  pagesInterface,
  reservationModel,
  tripModel,
  tripOrderModel
} from "../utils/models";
import {FaTrash} from "react-icons/fa";
import ReactTable from "react-table-v6";
import {MdOutlineSlideshow} from "react-icons/md";
import Loader from "./Loader";
import FiltersControls from "./FiltersControls";
import {getTranslatedLocalityName} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";

const ActiveTripsOrdersListForAdmin = ({
  addClasses = '',
  tripsOrders = [],
  setTripsOrders = () => {},
  isLoading = false,
}) => {
  //#region Get dictionaries from context
  const { lang } = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Clear currentTripOrder, tripOrder, reservation
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
    setCurrentReservation(reservationModel);
  }, []);
  //#endregion

  //#region Get tripOrder data from context
  const {
    setCurrentTripOrder,
    setCurrentTrip,
    setCurrentReservation
  } = useContext(AppContext);
  //#endregion

  //#region Search and filters
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');
  const statusesList = [{
    name: 'Забронирована',
    value: 'reserved'
  }, {
    name: 'В пути',
    value: 'inProgress',
  }, {
    name: 'Завершена',
    value: 'completed',
  }, {
    name: 'Отменена',
    value: 'cancelled',
  }];

  const getStatusName = (status) => {
    if (status === 'reserved') {
      return translate('Забронирована');
    }

    if (status === 'completed') {
      return translate('Завершена');
    }

    if (status === 'inProgress') {
      return translate('В пути');
    }

    if (status === 'cancelled') {
      return translate('Отменена');
    }

    return translate('Статус');
  }
  //#endregion

  //#region Data for React table
  const getTripsOrdersToDisplay = (searchText, filtersData, orders) => {
    let searchResult = [];

    if (searchText) {
      searchResult = orders.filter(order => {
        let res = false;

        if (order.hasOwnProperty('dispatcherFullName')) {
          if (order.dispatcherFullName.toUpperCase().includes(searchText
            .toUpperCase())) {
            res = true;
          }
        }

        if (order.hasOwnProperty('drivers')) {
          if (order?.acceptedDeal?.originalTrip?.drivers
            .some(driver => driver.fullName.toUpperCase()
              .includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        if (order.startPoint?.hasOwnProperty('city')) {
          if (Object.values(order.startPoint.city).some(city => city
            .toUpperCase().includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        if (order.endPoint?.hasOwnProperty('city')) {
          if (Object.values(order.endPoint.city).some(city => city.toUpperCase()
            .includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        return res;
      });
    } else {
      searchResult = orders;
    }

    let result = [];

    if (filtersData) {
      if (Object.values(filtersData).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(order => {
          let res = true;

          if (filtersData.status === 'completed' && (!order.isCompleted ||
            order.isCancelled)) {
            return false;
          }

          if ((filtersData.status === 'cancelled' && !order.isCancelled)) {
            return false;
          }

          if ((filtersData.status === 'inProgress' && !order.isInProgress)) {
            return false;
          }

          if ((filtersData.status === 'reserved' && (order.isCompleted
            || order.isCancelled || order.isInProgress))) {
            return false;
          }

          if (filtersData.date) {
            const dateAsTimestamp = new Date(filtersData.date).getTime();

            if (dateAsTimestamp !== order.departureDateWithoutTime) {
              return false;
            }
          }

          if (filtersData.amount) {
            if (order?.acceptedDeal?.pricePerPerson < (Number(
              filtersData?.amount.from || 0))
              || order?.acceptedDeal?.pricePerPerson > Number(
                filtersData.amount.to || 0)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  };

  const handleTripOrderClick = async (order) => {
    try {
      setCurrentTripOrder(order);
      setCurrentReservation(order.acceptedDeal);

      const trip = await getDocInCollection(collectionsInterface.trips,
        order?.acceptedDeal?.originalTrip?.idPost);

      setCurrentTrip(trip);

      navigate(`/${pagesInterface.tripsAdmin}/activeTripDetails`)
    } catch (error) {
      return error;
    }
  };

  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e =>
        <div>
          {e.idPost === '' ?
            translate('Не указан')
            :
            e.idPost
          }
        </div>
    },
    {
      id: 'departureTime',
      width: 150,
      Header: translate('Время выезда'),
      accessor: e =>
        <div>
          {!e.departureTime ?
            translate('Не указано')
            :
            showFormattedToLanguageDate(lang, e.departureTime)
          }
        </div>
    },
    {
      id: 'dispatcherName',
      Header: translate('Диспетчер'),
      accessor: e =>
        <div>
          {!e.dispatcherFullName ?
            translate('Не указано')
            :
            e.dispatcherFullName
          }
        </div>
    },
    {
      id: 'passengerName',
      Header: translate('Пассажир'),
      accessor: e =>
        <div>
          {e?.passengerName
            || translate('Не указан')
          }
        </div>
    },
    {
      id: 'driverName',
      Header: translate('Водитель'),
      accessor: e =>
        <div>
          {e?.acceptedDeal?.originalTrip?.drivers ?
            e.acceptedDeal?.originalTrip.drivers.map((driver, index) =>
              <div key={index}>
                {driver.fullName}
              </div>)
            :
            translate('Не указан')
          }
        </div>
    },
    {
      id: 'from',
      Header: translate('Откуда'),
      accessor: e =>
        <div>
          {!e.startPoint.city ?
            translate('Не указано')
            :
            getTranslatedLocalityName(e.startPoint.city, lang)
          }
        </div>
    },
    {
      id: 'to',
      Header: translate('Куда'),
      accessor: e =>
        <div>
          {!e.endPoint.city ?
            translate('Не указано')
            :
            getTranslatedLocalityName(e.endPoint.city, lang)
          }
        </div>
    },
    {
      id: 'price',
      Header: translate('Цена'),
      accessor: e =>
        <div>
          {e?.acceptedDeal?.pricePerPerson || 0}
        </div>
    },
    {
      id: 'status',
      Header: translate('Статус'),
      accessor: e =>
        <div>
          {translate(getStatusTextForAdmin(e))}
        </div>
    },
    {
      id: 'editAndDelete',
      Header: '',
      width: 80,
      accessor: e =>
        <div>
          <div
            className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}
          >
            <button
              type={'button'}
              className={'ShowButton'}
              onClick={() => handleTripOrderClick(e)}
            >
              <MdOutlineSlideshow/>
            </button>

            <button
              type={'button'}
              className={'DelButton'}
              onClick={() => deleteTripOrderHandler(e, setTripsOrders)}
            >
              <FaTrash/>
            </button>
          </div>
        </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      {isLoading ? <Loader />
        :
        <div className={`TripsOrdersListForAdmin ${addClasses}`}>
          <FiltersControls
            setAppliedFilters={setAppliedFilters}
            searchText={searchText}
            setSearchText={setSearchText}
            getStatusName={getStatusName}
            statusesList={statusesList}
          />

          <ReactTable
            data={getTripsOrdersToDisplay(searchText, appliedFilters,
              tripsOrders)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}

          />

          <div className={'PaddingBlock'}></div>
        </div>
      }
    </React.Fragment>
  );
  //#endregion
};

export default ActiveTripsOrdersListForAdmin;
