import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {entitiesNumberInterface, tripOrderModel} from "../utils/models";
import ReservationCard from "./ReservationCard";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/CardsListScreen.scss';
import {indicatorsNewService} from "../utils/indicatorsNewService";

const DisplayArchivedTripsOrders = ({
  tripsOrdersToDisplay = [],
  trips = [],
  handleTripOrderCardClick = () => {},
  addClasses = '',
  isDispatcher = true,
  isFullDispatcherInfo = false,
  isLoading = true,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screen title managing function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title and current trip order, update observation date
  useEffect(() => {
    setScreenTitle('История поездок');

    setCurrentTripOrder(tripOrderModel);

    indicatorsNewService.updateHistoryObservationByUser(user.idPost,
      isDispatcher).then();
  }, []);
  //#endregion

  //#region Function for defining trip of tripOrder
  const findTripDataForTripOrder = (tripOrder, trips) => {
    if (!trips || trips.length === 0) {
      return tripOrder.acceptedDeal?.originalTrip;
    }

    const suitableTrip = trips.find(trip => trip.idPost === tripOrder
      .acceptedDeal?.originalTrip.idPost);

    return suitableTrip || tripOrder.acceptedDeal?.originalTrip;
  }
  //#endregion

  const calculateSumToPay = (tripsOrders) => {
    let totalAmount = 0;
    let unpaidTripsOrders = [];

    unpaidTripsOrders = tripsOrders.filter(order => !order?.isFeePaid
      && order?.acceptedDeal?.isPaymentByCash && !order.isCancelled);

    totalAmount = unpaidTripsOrders
      .reduce((accum, curElement) => accum +
        (curElement?.acceptedDeal?.dispatcherFee || 0) * curElement
          .requiredNumberOfPlaces, 0);

    return totalAmount;
  }
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tripsOrdersChunk, setTripsOrdersChunk] = useState([]);

  useEffect(() => {
    setTripsOrdersChunk(tripsOrdersToDisplay
      .slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
        currentPage * entitiesNumberInterface.reservationCardsReduced));
  }, [tripsOrdersToDisplay.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader/>
        :
        <div
          className={tripsOrdersToDisplay.length > 0
            ? `CardsListScreen ${addClasses}`
            : `CardsListScreen CardsListScreen_Empty  ${addClasses}`}
        >
          {tripsOrdersToDisplay.length > 0 ?
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>
            :
            <p className={'CardsListScreen-NoEntriesText'}>
              {translate(isDispatcher ? 'Нет пассажиров'
                : 'Нет поездок')}
            </p>
          }

          {!user.attachedToCompany &&
            <>
              <div className={'BadgeInfoBlock CardsListScreen-BadgeInfoBlock'}>
                <span className={'BadgeInfoBlock-Title'}>
                  {`${translate('Сумма к выплате')}: `}
                </span>

                <span>
                  {`${calculateSumToPay(tripsOrdersToDisplay, isDispatcher)} ${
                    translate('грн.')}`}
                </span>
              </div>
            </>
          }
          <div className={'DataListWithPagination'}>
            <div className={'ListContainer'}>
              <div className='CardsList'>
                {tripsOrdersChunk?.map((tripOrder, tIndex) => (
                  <React.Fragment key={tripOrder.idPost}>
                    <ReservationCard
                      reservation={tripOrder.acceptedDeal}
                      isDispatcher={isDispatcher}
                      isActive={true}
                      handleReservationCardClick={handleTripOrderCardClick}
                      tripOrder={tripOrder}
                      trip={findTripDataForTripOrder(tripOrder, trips)}
                      isFullDispatcherInfo={isFullDispatcherInfo}
                      isArchivedTrip={true}
                    />
                  </React.Fragment>))
                }
              </div>
            </div>

            {tripsOrdersToDisplay.length > entitiesNumberInterface
                .reservationCardsReduced &&
              <PaginationBlock
                portionSize={entitiesNumberInterface.reservationCardsReduced}
                listLength={tripsOrdersToDisplay.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                startPage={startPage}
                setStartPage={setStartPage}
              />
            }
          </div>
        </div>
      }
    </>
  );
  //#endregion
};

export default DisplayArchivedTripsOrders;
