import React from 'react';
import {Col, Row} from "react-bootstrap";
import useTranslation from "../hooks/useTranslation";
import '../styles/WithdrawalPaymentInfo.scss';

const WithdrawalPaymentInfo = ({ data, addClasses = '' }) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div className={`WithdrawalPaymentInfo ${addClasses}`}>
      <Row>
        <Col md={3} lg={2} >
          <span className={'DataLabel'}>
            {translate('Номер карты')}
          </span>
          {' '}
        </Col>

        <Col md={9} lg={10} >
          <span>
            {data?.cardNumber}
          </span>
        </Col>
      </Row>

      <Row>
        <Col md={3} lg={2} >
          <span className={'DataLabel'}>
            {translate('IBAN')}
          </span>
          {' '}
        </Col>

        <Col md={9} lg={10}>
          <span>
            {data?.iban}
          </span>
        </Col>
      </Row>

      <Row>
        <Col md={3} lg={2} >
          <span className={'DataLabel'}>
            {translate('Получатель')}
          </span>
          {' '}
        </Col>

        <Col md={9} lg={10}>
          <span>
            {data?.fullName}
          </span>
        </Col>
      </Row>

      <Row>
        <Col md={3} lg={2} >
          <span className={'DataLabel'}>
            {translate('SWIFT-код')}
          </span>
          {' '}
        </Col>

        <Col md={9} lg={10}>
          <span>
            {data?.swiftCode}
          </span>
        </Col>
      </Row>
    </div>
  );
  //#endregion
};

export default WithdrawalPaymentInfo;
