import React, {useState, useContext, useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import {tripOrderModel} from "../utils/models";
import {AppContext} from "../context/appContext";
import SearchTripOrder from "./SearchTripOrder";
import SuitableTripsScreen from "./SuitabeTripsScreen";
import SuitableTripsOrdersScreen from "./SuitableTripsOrdersScreen";
import DisplayTripOrderDetails from "./DisplayTripOrderDetails";

const SearchPassengers = ({
  addClasses = '',
  updateTripsListAfterMakingReservation = () => {},
  updateAfterOwnPassengerReservation = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Suitable passengers after search
  const [suitablePassengers, setSuitablePassengers] = useState([]);
  const [suitableOwnPassengers, setSuitableOwnPassengers] = useState([]);
  //#endregion

  //#region Current trip order
  const {setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Handle click on trip order card
  const handleTripOrderCardClick = (tripOrder) => {
    setCurrentTripOrder(tripOrder);

    navigate('tripOrderDetails');
  }
  //#endregion

  //#region Go back from trip order details
  const handleGoBackFromTripOrderDetails = () => {
    navigate('searchResults');
    setCurrentTripOrder(tripOrderModel);
  }
  //#endregion

  //#region Loader
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  //#endregion

  //#region Suitable trips and their displaying
  const [suitableTrips, setSuitableTrips] = useState([]);

  const handleShowSuitableTrips = (trips) => {
    setSuitableTrips(trips);

    navigate('suitableTrips')
  }

  const handleGoBackSuitableTripsList = () => {
    navigate('trips/searchPassenger');
  }
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Update trips and tripOrders after making reservation
  const updateAfterMakingReservation = (tripOrder, trip, isOwnPassenger = false) => {
    if (isOwnPassenger) {
      updateAfterOwnPassengerReservation(tripOrder, trip);
    } else {
      updateTripsListAfterMakingReservation(tripOrder, trip);
    }

    setSuitablePassengers(passengers => passengers
      .map(passenger => passenger.idPost === tripOrder.idPost ? tripOrder
        : passenger));
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={`SearchPassengers ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <SearchTripOrder
                setSuitablePassengers={setSuitablePassengers}
                setSuitableOwnPassengers={setSuitableOwnPassengers}
                addClasses={'App-Screen_Narrow'}
                setIsOrdersLoading={setIsOrdersLoading}
              />
            }
          />

          <Route
            path={'searchResults'}
            element={
              <SuitableTripsOrdersScreen
                tripsOrders={suitablePassengers}
                addTripsOrders={suitableOwnPassengers}
                isLoading={isOrdersLoading}
                handleTripOrderCardClick={handleTripOrderCardClick}
              />
            }
          />

          <Route
            path={'tripOrderDetails'}
            element={
              <DisplayTripOrderDetails
                isFromTripReservation={false}
                handleGoBack={handleGoBackFromTripOrderDetails}
                handleShowSuitableTrips={handleShowSuitableTrips}
                isDispatcher={false}
                addClasses={'App-Screen_Narrow'}
                searchOnlyOwnTrips={true}
              />
            }
          />

          <Route
            path={'suitableTrips/*'}
            element={
              <SuitableTripsScreen
                addTrips={suitableTrips}
                handleGoBack={handleGoBackSuitableTripsList}
                isDispatcher={false}
                updateAfterMakingReservation={updateAfterMakingReservation}
                isLoading={false}
                onlyMyTrips={true}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
  //#endregion
};

export default SearchPassengers;
