import React, {useContext, useState} from 'react';
import {validateAndCorrectCardNumber} from "../utils/helpers";
import {Modal} from "react-bootstrap";
import {AppContext} from "../context/appContext";
import FormInputAlertText from "../components/FormInputAlertText";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";

const ModalMakeAccountingForExchange = ({
  showModal = false,
  setShowModal = () => {},
  counterparty,
  isCreditor = false,
  makeAccounting = () => {},
  addClasses = '',
  isLoading = false,
  setIsLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region paymentData and its update
  const [paymentData, setPaymentData] = useState({});

  const updatePaymentData = (fieldName, newValue) => {
    let validatedValue = '';

    if (newValue === '') {
      validatedValue = '';

      setPaymentData(state => ({
        ...state,
        [fieldName]: validatedValue,
      }));

      return;
    }

    switch (fieldName) {
      case 'cardNumber':
        validatedValue = validateAndCorrectCardNumber(newValue,
          paymentData[fieldName] || '');
        break;
    }

    setPaymentData(state => ({
      ...state,
      [fieldName]: validatedValue,
    }));
  }
  //#endregion

  //#region Error alert
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //#endregion

  //#region Handle form submit
  const handleMakeAccountingSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const clearedCardNumber = paymentData?.cardNumber.replaceAll(' ', '');

      if (clearedCardNumber?.length === 16 || clearedCardNumber?.length === 18) {
        await makeAccounting(counterparty, user, isCreditor,
          paymentData.cardNumber);

        setShowModal(false);
      } else {
        setShowError(true);
        setErrorMessage('Номер карты должен содержать 16 или 18 цифр');
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      fullscreen='true'
      onHide={() => {
        setShowModal(false);
      }}
      className={`ModalMakeAccountingForExchange ${addClasses}`}
    >
      {isLoading ? <Loader/>
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className={'RegularTitle RegularTitle_Small'}>
                {translate('Введите номер карты')}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className={'AddToBalance-Item'}
              onSubmit={(event) =>
                handleMakeAccountingSubmit(event)}
            >
              <label className={'AddToBalance-Label'}>
                {translate('Номер карты')}
              </label>

              <div className={'InputWithAlert'}>
                <input
                  required={true}
                  className={'FormControl AddToBalance-Input'}
                  name={'CardNumber'}
                  type={'text'}
                  placeholder={translate('Номер карты')}
                  value={paymentData.cardNumber}
                  onChange={(event) => updatePaymentData(
                    'cardNumber', event.target.value)}
                  required
                />

                {showError &&
                  <FormInputAlertText
                    text={errorMessage}
                    setIsVisible={setShowError}
                  />
                }
              </div>

              <div className={'PaddingBlock'}></div>

              <button
                type={'submit'}
                className={'RegularButton'}
              >
                {translate('Отправить запрос')}
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast ' +
                'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W35'}
              onClick={() => {
                setShowModal(false)
              }}
            >
              {translate('Вернуться')}
            </button>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
  //#endregion
};

export default ModalMakeAccountingForExchange;
