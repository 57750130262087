import React, {useContext, useEffect} from "react";
import {notificationModel} from "../utils/models";
import {AppContext} from "../context/appContext";
import {
  getMessageText,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import '../styles/NotificationDetails.scss';
import useTranslation from "../hooks/useTranslation";

const NotificationDetails = ({
    addClasses='',
    notification = notificationModel,
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get screens data from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Уведомление');
  }, []);
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!notification.id) {
      navigate('/profile/notifications');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <div className={`NotificationDetails ${addClasses}`}>
      <h3
        className={
          'RegularTitle RegularTitle_Small  NotificationDetails-Item'
        }
      >
        {translate(notification.title)
          || notification.title}
      </h3>

      <div className={'NotificationDetails-Head NotificationDetails-Item'}>
        <div
          className={
            'NotificationDetails-Block NotificationDetails-CreatingTime'
          }
        >
          {notification?.dateCreating ? showFormattedToLanguageDate(lang,
            notification?.dateCreating, true) : 'Invalid time'}
        </div>
      </div>

      <div className={'NotificationDetails-Text'}>
        {getMessageText(notification.text, translate, lang)}
      </div>
    </div>
  );
  //#endregion
};

export default NotificationDetails;
