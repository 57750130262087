import React, {useContext, useEffect, useState} from 'react';
import ReservationCard from "./ReservationCard";
import {AppContext} from "../context/appContext";
import {getStatusText} from "../utils/helpers";
import {entitiesNumberInterface} from "../utils/models";
import TripStatusBadge from "./TripStatusBadge";
import ModalChangeTripStatus from "../modals/ModalChangeTripStatus";
import DraggableReservationCard from "./DraggableReservationCard";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import update from "immutability-helper";
import {useNavigate} from "react-router-dom";
import PaginationBlock from "./PaginationBlock";
import Loader from "./Loader";
import DisplayTripPointsData from "./DisplayTripPointsData";
import useTranslation from "../hooks/useTranslation";
import {tripService} from "../utils/tripService";
import '../styles/PassengersInTripList.scss';

const isTouchDevice = () => {
  if ("ontouchstart" in window) {
    return true;
  }
  return false;
};

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

const PassengersInTripList = ({
  handlePassengerInTripClick = () => {},
  addClasses = '',
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get dictionaries from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Пассажиры в поездке');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Edit trip status
  const [isEditStatus, setIsEditStatus] = useState(false);

  //#region HandleEditStatusClick
  const handleEditStatusClick = () => {
    setIsEditStatus(true);
  }
  //#endregion
  //#endregion

  //#region Initial passengers and passengers to display
  const [passengersToDisplay, setPassengersToDisplay] = useState([]);

  const getPassengersAndSet = async () => {
    setIsLoading(true);
    try {
      const passengers = await tripService.getPassengersList(currentTrip.idPost);

      if (passengers.length < 1) {
        const index = window.location.pathname.lastIndexOf('/');
        const redirectPath = window.location.pathname.slice(0, index);
        navigate(redirectPath || '/');
      } else {
        setPassengersToDisplay(passengers);
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPassengersAndSet().then();
  }, []);
  //#endregion

  //#region Handle card movement
  const moveCard = (dragIndex, hoverIndex) => {
    const draggedCard = passengersToDisplay[dragIndex];

    setPassengersToDisplay(
      update(passengersToDisplay, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, draggedCard]]
      })
    );
  };
  //#endregion

  //#region Handle reservation card click
  const handleReservationCardClick = async (...args) => {
    setIsLoading(true);

    try {
      await handlePassengerInTripClick(...args);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [passengersChunk, setPassengersChunk] = useState([]);

  useEffect(() => {
    setPassengersChunk(passengersToDisplay
      .slice((currentPage - 1) * entitiesNumberInterface.reservationCards,
        currentPage * entitiesNumberInterface.reservationCards));
  }, [passengersToDisplay.length, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`PassengersInTripList ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <div className={'PassengersInTripList-Head FlexBlock'}>
            <div className={'ColoredText RegularTitle RegularTitle_Small' +
              ' PassengersInTripList-StatusTitle'}>
              {translate('Статус поездки')}
            </div>

            <div>
              <TripStatusBadge
                statusText={getStatusText(currentTrip.status)}
              />
            </div>

            <button
              className={'RegularButton PassengersInTripList-EditButton'}
              onClick={() => handleEditStatusClick()}
            >
              {translate('Изменить статус поездки')}
            </button>
            {/*<div*/}
            {/*  className={'PassengersInTripList-EditButton'}*/}
            {/*  onClick={() => handleEditStatusClick()}*/}
            {/*>*/}
            {/*  <img*/}
            {/*    src={'/img/pencil-icon.svg'}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>

          <div className={'FlexBlock FlexBlock_Columns'}>
            <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
              {translate(screenTitle)}
            </h2>

            <span className={'RegularTitle RegularTitle_Small'}>
              <DisplayTripPointsData
                addClasses={'ColoredText'}
                tripPoints={[currentTrip.startPoint, currentTrip.endPoint]}
              />
            </span>
          </div>

          <DndProvider backend={backendForDND}>
            <div className={'DataListWithPagination'}>
              <div className={'ListContainer'}>
                <div className={'CardsList'}>
                  {passengersChunk?.map((passenger, index) =>
                    <React.Fragment key={passenger.idPost || index}>
                      {currentTrip?.isPassengerAddress ?
                        <DraggableReservationCard
                          tripOrder={passenger?.actualTripOrder ||
                            passenger.originalTripOrder}
                          reservation={passenger}
                          trip={currentTrip || passenger.originalTrip}
                          isPassengerInTrip={true}
                          handleReservationCardClick={handleReservationCardClick}
                          index={index}
                          moveCard={moveCard}
                          isDispatcher={false}
                        />
                        :
                        <ReservationCard
                          isPassengerInTrip={true}
                          reservation={passenger}
                          tripOrder={passenger?.actualTripOrder ||
                            passenger.originalTripOrder}
                          trip={passenger?.actualTrip || currentTrip}
                          handleReservationCardClick={handleReservationCardClick}
                          isDispatcher={false}
                          isActiveTrip={true}
                        />
                      }
                    </React.Fragment>
                  )}
                </div>
              </div>

              {passengersToDisplay.length > entitiesNumberInterface.reservationCards &&
                <PaginationBlock
                  portionSize={entitiesNumberInterface.reservationCards}
                  listLength={passengersToDisplay.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  startPage={startPage}
                  setStartPage={setStartPage}
                />
              }
            </div>
          </DndProvider>
        </div>
      }

      {isEditStatus &&
        <ModalChangeTripStatus
          showModal={isEditStatus}
          setShowModal={setIsEditStatus}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      }
    </>
  );
  //#endregion
};

export default PassengersInTripList;
