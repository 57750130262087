import React from 'react';
import {useNavigate} from "react-router-dom";
import useTranslation from "../hooks/useTranslation";
import '../styles/StartPage.scss';

const StartPage = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Render
  return (
    <>
      <div className={`StartPage ${addClasses}`}>
        <div className={'StartPage-Content'}>
          <div
            className={'GreetingImageContainer StartPage-GreetingImage'}
          ></div>

          <h2 className={'RegularTitle StartPage-Title'}>
            {translate('Найдите своего перевозчика')}
          </h2>

          <p className={'StartPage-Description'}>
            {translate('Какой-то текст с коротким описанием сервиса')}
          </p>

          <button
            type={'button'}
            onClick={() => navigate('/login')}
            className={'RegularButton StartPage-LoginButton'}
          >
            {translate('Войти')}
          </button>

          <button
            type={'button'}
            onClick={() => navigate('/register')}
            className={'RegularButton StartPage-RegisterButton'}
          >
            {translate('Зарегистрироваться')}
          </button>
        </div>
      </div>
    </>
  );
  //#endregion
};

export default StartPage;
