import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {sortCardsByDateOfCreation} from "../utils/helpers";
import {Nav, Tab} from "react-bootstrap";
import ReservationCard from "./ReservationCard";
import {useNavigate} from "react-router-dom";
import {collectionsInterface, entitiesNumberInterface} from "../utils/models";
import PaginationBlock from "./PaginationBlock";
import useTranslation from "../hooks/useTranslation";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import Loader from "./Loader";

const ReservedTripDetails = ({
  addClasses = '',
  activeTabKey = 'myReservations',
  handleReservationCardClick = () => {},
  setActiveTabKey = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTrip, setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Get screen title function from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Брони для поездки');
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Update trip and set
  const getActualTripAndSet = async () => {
    setIsLoading(true);

    try {
      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        currentTrip.idPost);

      setCurrentTrip(actualTrip);
      updateDataChunks(actualTrip);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Redirect if invited driver or no data
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }

    if (!currentTrip?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    } else {
      getActualTripAndSet().then();
    }
  }, []);
  //#endregion

  //#region Pagination
  const [startPage, setStartPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reservationsFromDriversChunk, setReservationsFromDriversChunk]
    = useState([]);
  const [reservationsFromDispatchersChunk, setReservationsFromDispatchersChunk]
    = useState([]);

  const updateDataChunks = (currentTrip) => {
    if (activeTabKey === 'myReservations') {
      setReservationsFromDriversChunk(sortCardsByDateOfCreation(currentTrip
        ?.reservationsFromDrivers, false)?.filter(reservation => !reservation.isTotallyAccepted)
        ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
          currentPage * entitiesNumberInterface.reservationCardsReduced));
    } else {
      setReservationsFromDispatchersChunk(sortCardsByDateOfCreation(currentTrip
        ?.reservationsFromDispatchers, false)?.filter(reservation => !reservation.isTotallyAccepted)
        ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
          currentPage * entitiesNumberInterface.reservationCardsReduced));
    }
  }

  // useEffect(() => {
  //   setReservationsFromDriversChunk(sortCardsByDateOfCreation(currentTrip
  //     ?.reservationsFromDrivers, false)?.filter(reservation => !reservation.isTotallyAccepted)
  //     ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
  //       currentPage * entitiesNumberInterface.reservationCardsReduced));
  // }, [currentTrip.reservationsFromDrivers.length, currentPage]);
  //
  // useEffect(() => {
  //   setReservationsFromDispatchersChunk(sortCardsByDateOfCreation(currentTrip
  //     ?.reservationsFromDispatchers, false)?.filter(reservation => !reservation.isTotallyAccepted)
  //     ?.slice((currentPage - 1) * entitiesNumberInterface.reservationCardsReduced,
  //       currentPage * entitiesNumberInterface.reservationCardsReduced));
  // }, [currentTrip?.reservationsFromDispatchers?.length, currentPage]);

  useEffect(() => {
    updateDataChunks(currentTrip);
  },[activeTabKey, currentPage]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader /> :
        <div className={`ReservedTripOrderDetails ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <Tab.Container
            activeKey={activeTabKey}
          >
            <Nav variant="pills" className={'flex-row TabControls'}>
              <Nav.Item>
                <Nav.Link
                  eventKey='myReservations'
                  onClick={() => {
                    setStartPage(1);
                    setCurrentPage(1);
                    setActiveTabKey('myReservations');
                  }}
                >
                  {translate('Мои брони')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey='reservationsFromDispatchers'
                  onClick={() => {
                    setStartPage(1);
                    setCurrentPage(1);
                    setActiveTabKey('reservationsFromDispatchers')
                  }}
                >
                  {translate('Брони от диспетчеров')}
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey='myReservations'>
                <div className={'PaddingBlock_Half'}></div>
                <div className={'DataListWithPagination'}>
                  <div className={'ListContainer'}>
                    <div className={'CardsList'}>
                      {reservationsFromDriversChunk
                        ?.map((reservation, index) => (
                          <React.Fragment key={reservation.uuid || index}>
                            <ReservationCard
                              reservation={reservation}
                              tripOrder={reservation.originalTripOrder}
                              trip={currentTrip}
                              isDispatcher={false}
                              isActive={true}
                              handleReservationCardClick={
                                handleReservationCardClick
                              }
                            />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>

                  {currentTrip?.reservationsFromDrivers
                      ?.filter(reservation => !reservation.isTotallyAccepted)
                      ?.length > entitiesNumberInterface.reservationCardsReduced &&
                    <PaginationBlock
                      portionSize={
                        entitiesNumberInterface.reservationCardsReduced
                      }
                      listLength={
                        currentTrip?.reservationsFromDrivers?.length || 0
                      }
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      startPage={startPage}
                      setStartPage={setStartPage}
                    />
                  }
                </div>

              </Tab.Pane>

              <Tab.Pane eventKey='reservationsFromDispatchers'>
                <div className={'PaddingBlock_Half'}></div>

                <div className={'DataListWithPagination'}>
                  <div className={'ListContainer'}>
                    <div className={'CardsList'}>
                      {reservationsFromDispatchersChunk
                        ?.map((reservation, index) => (
                          <React.Fragment key={reservation.id || index}>
                            <ReservationCard
                              reservation={reservation}
                              tripOrder={reservation.originalTripOrder}
                              trip={currentTrip}
                              isDispatcher={false}
                              isActive={true}
                              handleReservationCardClick={
                                handleReservationCardClick
                              }
                            />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>

                  {currentTrip?.reservationsFromDispatchers
                      ?.filter(reservation => !reservation.isTotallyAccepted)
                      ?.length > entitiesNumberInterface.reservationCardsReduced &&
                    <PaginationBlock
                      portionSize={
                        entitiesNumberInterface.reservationCardsReduced
                      }
                      listLength={
                        currentTrip?.reservationsFromDispatchers?.length || 0
                      }
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      startPage={startPage}
                      setStartPage={setStartPage}
                    />
                  }
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      }
    </>
  );
  //#endregion
};

export default ReservedTripDetails;
