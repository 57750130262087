import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  getCollection,
  updateDocumentInCollection,
} from "../utils/firebaseConfigAndFunctions";
import {
  addOptionModel,
  catalogsNamesInterface,
  collectionsInterface,
  rolesInterface
} from "../utils/models";
import {
  checkAreAllDefaultNamesPresent,
  getOptionsAsCatalog,
} from "../utils/helpers";
import ModalNotification from "../modals/ModalNotification";
import {useNavigate} from "react-router-dom";
import {AiOutlineArrowDown, AiOutlineArrowUp} from "react-icons/ai";
import {FaPlus, FaTrash} from "react-icons/fa";
import {BsLightbulb, BsLightbulbOff} from "react-icons/bs";
import Loader from "./Loader";
import DisplayAddOptions from "./DisplayAddOptions";
import '../styles/EditAddOptions.scss';

const EditAddOptions = ({addClasses = ''}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get language from context, manage languages
  const {dictionaries} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }
    setScreenTitle('Дополнительные опции для поездок');
  }, []);
  //#endregion

  //#region Notification managing
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Set shorten dictionaries info
  const [dictionariesInfo, setDictionariesInfo] = useState([]);

  useEffect(() => {
    if (dictionaries.length > 0) {
      const info = dictionaries.map(dictionary => ({
        name: dictionary.name,
        description: dictionary.description,
      }));

      setDictionariesInfo(info);
    }
  }, [dictionaries]);
  //#endregion

  //#region Additional options list and its handling
  const [addOptionsList, setAddOptionsList] = useState([]);
  const [addOptionsIdPost, setAddOptionsIdPost] = useState('');

  useEffect(() => {
    getCollection(collectionsInterface.tripAddOptionsList)
      .then(options => {
        setAddOptionsList(options[0].options);
        setAddOptionsIdPost(options[0].idPost);
      }).catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  const updateAddOptions = (actionType, newValue, optionIndex, optionId) => {
    switch (actionType) {
      case 'add':
        setAddOptionsList(optionsList => [...optionsList, {
          ...addOptionModel,
          id: Date.now(),
        }]);
        break;
      case 'delete':
        const newAddOptions = [...addOptionsList];
        newAddOptions.splice(optionIndex, 1);
        setAddOptionsList(newAddOptions);
        break;
      case 'toggleVisibility':
        setAddOptionsList(optionsList => optionsList
          .map((option) => optionId === option.id ? {
            ...option,
            isVisible: newValue,
          } : option));
        break;
      case 'moveUp':
        if (optionIndex === 0) {
          return;
        }

        setAddOptionsList(optionsList => {
          const movedOption = optionsList[optionIndex];
          const firstPart = optionsList.slice(0, optionIndex - 1);
          const secondPart = optionsList.slice(optionIndex - 1)
            .filter(option => option.id !== optionId);
          const newOptionsList = [...firstPart, movedOption, ...secondPart];

          return newOptionsList
        });
        break;
      case 'moveDown':
        if (optionIndex === addOptionsList.length - 1) {
          return;
        }

        setAddOptionsList(optionsList => {
          const movedOption = optionsList[optionIndex];
          const firstPart = optionsList.slice(0, optionIndex + 2)
            .filter(option => option.id !== optionId);
          const secondPart = optionsList.slice(optionIndex + 2);
          const newOptionsList = [...firstPart, movedOption, ...secondPart];

          return newOptionsList
        });
        break;
    }
    ;
  };
  //#endregion

  //#region Update options translation
  const updateCatalog = (newValue, id, languageType) => {
    setAddOptionsList(options => options.map(option => option.id === id ? {
      ...option,
      optionContent: {
        ...option.optionContent,
        [languageType]: newValue,
      }
    } : option));
  };
  //#endregion

  //#region Submit add options
  const addOptionsSubmit = async (catalogId) => {
    setIsLoading(true);

    try {
      const checkDefaultNamesResult = checkAreAllDefaultNamesPresent(
        addOptionsList);

      if (!checkDefaultNamesResult) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Все дефолтные значения должны быть заполнены');

        return;
      }

      const newAddOptions = {
        idPost: addOptionsIdPost,
        options: addOptionsList,
      };
      await updateDocumentInCollection(collectionsInterface.tripAddOptionsList,
        newAddOptions, catalogId);

      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Новые дополнительные опции сохранены в базе данных');
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    };
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ?
        <Loader />
        :
        <div className={`EditAddOptions ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          {addOptionsList.length > 0 &&
            <DisplayAddOptions
              catalog={getOptionsAsCatalog(addOptionsList)}
              dictionariesInfo={dictionariesInfo}
              catalogName={catalogsNamesInterface.options}
              catalogId={addOptionsIdPost}
              updateCatalog={updateCatalog}
              saveCatalog={addOptionsSubmit}
              areButtonsForEntity={true}
              buttonsData={[
                {
                  type: 'toggleVisibility',
                  handler: updateAddOptions,
                  icon: [<BsLightbulb/>, <BsLightbulbOff/>],
                  valueName: 'isVisible',
                },
                {
                  type: 'delete',
                  handler: updateAddOptions,
                  icon: <FaTrash/>,
                },
                {
                  type: 'moveUp',
                  handler: updateAddOptions,
                  icon: <AiOutlineArrowUp/>
                },
                {
                  type: 'moveDown',
                  handler: updateAddOptions,
                  icon: <AiOutlineArrowDown/>
                },
              ]}
              isPagination={false}
            />
          }

          <button
            type={'button'}
            className={'EditAddOptions-AddButton'}
            onClick={() => updateAddOptions('add')}
          >
            <FaPlus/>
          </button>
        </div>
      }

      {isNotification &&
        <ModalNotification
          title={notificationTitle}
          message={notificationMessage}
          showModal={isNotification}
          setShowModal={setIsNotification}
        />
      }
    </>
  );
  //#endregion
};

export default EditAddOptions;
