import React, {useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Modal} from "react-bootstrap";
import FormInputAlertText from "../components/FormInputAlertText";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";

const ModalCancellingComment = ({
  title = '',
  showModal,
  isDispatcher,
  message = '',
  setShowModal = () => {},
  cancelDeal = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, currentTrip, currentReservation} = useContext(
    AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Handle comment update
  const [cancelComment, setCancelComment] = useState('');
  //#endregion

  //#region Handle errors
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  //#endregion

  //#regiton Handle cancelling confirmation
  const handleCancelConfirmation = async (cancelComment) => {
    setIsLoading(true);

    if (!cancelComment) {
      setIsError(true);
      setErrorText('Необходимо оставить комментарий');

      setIsLoading(false);
      return;
    }

    if (cancelComment.trim().length < 10) {
      setIsError(true);
      setErrorText('Комментарий слишком короткий');

      setIsLoading(false);
      return;
    }

    try {
      await cancelDeal(currentTripOrder, currentTrip,
        cancelComment, isDispatcher, currentReservation);

      setShowModal(false);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      {isLoading ? <Loader/>
        :
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {translate(title)}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <label
              htmlFor='CancelingComment'
              className={'CreateTrip-Label'}
            >
              {translate(message)}
            </label>

            <textarea
              required
              id="CancelingComment"
              placeholder={translate('Комментарий к отмене')}
              className={'FormControl TextArea'}
              value={cancelComment}
              onChange={(event) => setCancelComment(event.target.value)}
            />

            {isError &&
              <FormInputAlertText
                text={errorText}
                setIsVisible={setIsError}
                addClasses={'LeftPadding'}
              />
            }
          </Modal.Body>

          <div className={'PaddingBlock'}></div>

          <Modal.Footer>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={() => handleCancelConfirmation(cancelComment)}
            >
              {translate('Подтвердить отмену')}
            </button>
            <button
              type={'button'}
              className={'RegularButton RegularButton_Contrast'}
              onClick={() => {
                setShowModal(false)
              }}
            >
              {translate('Вернуться')}
            </button>
          </Modal.Footer>
        </>
      }
    </Modal>
  );
  //#endregion
};

export default ModalCancellingComment;
