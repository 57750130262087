import React from 'react';
import {Spinner} from "react-bootstrap";

const LoaderSmall = () => {
  //#region Render
  return (
    <div className={'LoaderSmall'}>
      <Spinner animation='border' variant='primary' />
    </div>
  );
  //#endregion
};

export default LoaderSmall;
