import React, {useContext, useEffect} from 'react';
import {getTransactionsArrayFromObject} from "../utils/helpers";
import TransactionsSummary from "./TransactionsSummary";
import TransactionsHistory from "./TransactionsHistory";
import {AppContext} from "../context/appContext";
import useTranslation from "../hooks/useTranslation";

const TransactionsMainScreen = ({
  addClasses = '',
  balance = {},
  handleMakeDepositButtonClick = () => {},
  handleMakeWithdrawalButtonClick = () => {},
  handleInviteToAppButtonClick = () => {},
  handleSeeWithdrawalButtonClick = () => {},
  handleShowReferralsButtonClick = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen data and functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Мой кошелек');
  }, []);
  //#endregion

  //#region Render
  return (
    <div className={`TransactionsMainScreen ${addClasses}`}>
      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      <TransactionsSummary
        addClasses={'MyWallet-TransactionsSummary'}
        transactions={getTransactionsArrayFromObject(balance?.transactions)}
        amount={balance?.amount}
        reservedAmount={balance?.reservedAmount}
      />

      <div className={'MyWallet-ButtonsBlock'}>
        <div className={'MyWallet-ButtonContainer'}>
          <button
            className={'RegularButton'}
            onClick={() => {
              handleMakeDepositButtonClick();
            }}
          >
            {translate('Пополнить баланс')}
          </button>
        </div>

        <div className={'MyWallet-ButtonContainer'}>
          <button
            className={'RegularButton RegularButton_Contrast'}
            onClick={() => {
              handleMakeWithdrawalButtonClick();
            }}
          >
            {translate('Вывести средства')}
          </button>
        </div>

        <div className={'MyWallet-ButtonContainer'}>
          <button
            className={'RegularButton'}
            onClick={() => {
              handleInviteToAppButtonClick();
            }}
          >
            {translate('Пригласить в приложение')}
          </button>
        </div>

        <div className={'MyWallet-ButtonContainer'}>
          <button
            className={'RegularButton RegularButton_Contrast'}
            onClick={() => {
              handleShowReferralsButtonClick();
            }}
          >
            {translate('Показать рефералов')}
          </button>
        </div>

        <div className={'MyWallet-ButtonContainer'}>
          <button
            className={'RegularButton'}
            onClick={() => handleSeeWithdrawalButtonClick()}
          >
            {translate('Заявки на вывод')}
          </button>
        </div>
      </div>

      <div className={'SeparationBlock SeparationBlock_Limited'}></div>

      <TransactionsHistory
        transactions={getTransactionsArrayFromObject(balance?.transactions)}
      />
    </div>
  );
  //#endregion
};

export default TransactionsMainScreen;
