import React, {useContext, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  sendMessageAboutExchangeAccountingRequest,
  sendMessageToUser
} from "../utils/helpers";
import DisplayMessengersRow from "./DisplayMessengersRow";
import DisplayCounterPartyData from "./DisplayCounterPartyData";
import {
  getDocInCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, tripModel, tripOrderModel} from "../utils/models";
import ModalMakeAccountingForExchange
  from "../modals/ModalMakeAccountingForExchange";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/CounterPartyDetails.scss';

const CounterPartyDetails = ({
  addClasses = '',
  isCreditor,
  counterparty,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {setUser} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Make accounting
  const handleMakeAccountingClick = async () => {
    setShowAccountingModal(true);
  }

  const makeAccounting = async (counterparty, user, isCreditor, cardNumber) => {
    setIsLoading(true);

    try {
      const actualUser = await getDocInCollection(collectionsInterface.users,
        user.idPost);

      let actualCounterParty;
      let messageTitle = '';
      let messageText = '';
      let archivedExchange = {};

      const currentTime = Date.now();

      if (!isCreditor) {
        archivedExchange = {
          id: counterparty.idPost + user.idPost + currentTime.toString(),
          payer: {
            fullName: counterparty.fullName,
            idPost: counterparty.idPost,
            uid: counterparty.uid,
          },
          recipient: {
            fullName: user.fullName,
            idPost: user.idPost,
            uid: user.uid,
          },
          amount: counterparty.amount,
          isFeePaid: false,
        }

        const updatedDebtors = actualUser.debtors
          .filter(debtor => debtor.idPost !== counterparty.idPost);

        const updatedArchivedExchanges = [...actualUser.archivedExchanges,
          archivedExchange];

        await updateFieldsInDocumentInCollection(collectionsInterface.users,
          user.idPost, {
          debtors: updatedDebtors,
          archivedExchanges: updatedArchivedExchanges,
        });

        actualCounterParty = await getDocInCollection(collectionsInterface
          .users, counterparty.idPost);

        const updatedCreditors = actualCounterParty.creditors
          .filter(creditor => creditor.idPost !== user.idPost);

        const updatedArchivedExchangesForCounterparty =
          [...actualCounterParty.archivedExchanges, archivedExchange];

        await updateFieldsInDocumentInCollection(collectionsInterface.users,
          actualCounterParty.idPost, {
            creditors: updatedCreditors,
            archivedExchanges: updatedArchivedExchangesForCounterparty,
          });

        await sendMessageAboutExchangeAccountingRequest(counterparty.amount,
          counterparty.fullName, cardNumber, counterparty.idPost,
          actualCounterParty.email);

        setUser(state => ({
          ...state,
          debtors: updatedDebtors,
          archivedExchanges: updatedArchivedExchanges,
        }));

        navigate('/exchange');
      }
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Modal for card number and accounting confirmation
  const [showAccountingModal, setShowAccountingModal] = useState(false);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`CounterPartyDetails ${addClasses}`}>
          <div
            className={
              'CounterPartyDetails-Item RegularTitle RegularTitle_Small'
            }
          >
            {!isCreditor
              ? translate('Водитель')
              : translate('Диспетчер')}
          </div>

          <DisplayCounterPartyData
            counterparty={counterparty}
            isCreditor={isCreditor}
          />

          <div className={'CounterPartyCard-Item'}>
            {counterparty?.phoneNumbers?.map((phone, pIndex) => (
              <React.Fragment key={pIndex}>
                <DisplayMessengersRow
                  phone={phone}
                  isLinkToCall={true}
                  isPhoneNumberRefactored={true}
                />
              </React.Fragment>
            ))}
          </div>

          <div className={'CounterPartyCard-Item FlexBlock'}>
            <span>
              {`${translate('Сумма гонорара на обмен')}: `}
            </span>

            <span>
              {`${counterparty.amount} ${translate('грн')}`}
            </span>
          </div>

          {!isCreditor &&
            <div className={'CounterPartyCard-Item'}>
              <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => handleMakeAccountingClick()}
              >
                {translate('Сделать взаиморасчет')}
              </button>
            </div>
          }
        </div>
      }

      {showAccountingModal &&
        <ModalMakeAccountingForExchange
          showModal={showAccountingModal}
          setShowModal={setShowAccountingModal}
          counterparty={counterparty}
          isCreditor={isCreditor}
          makeAccounting={makeAccounting}
          isLoading={isLoading}
        />
      }
    </>
  );
  //#endregion
};

export default CounterPartyDetails;
