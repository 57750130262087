import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  searchTripsOrdersByQuery,
  transformStringsArrayToSelectOptions
} from "../utils/helpers";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import RadioFieldSet from "../elements/RadioFieldSet";
import Loader from "./Loader";
import {format} from "date-fns";
import ModalNotification from "../modals/ModalNotification";
import LocationIq from "./LocationIq";
import useTranslation from "../hooks/useTranslation";
import '../styles/SearchTripOrder.scss';

const SearchTripOrder = ({
  addClasses = '',
  setSuitablePassengers = () => {},
  setSuitableOwnPassengers = () => {},
  setIsOrdersLoading = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Поиск пассажиров в поездку');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Data for Selects
  let placesNumber = [];
  for (let i = 1; i < 61; i++) {
    placesNumber.push(i);
  }
  //#endregion

  //#region Data for query
  const [tripQuery, setTripQuery] = useState({
    start: {},
    end: {},
    departure: '',
    placesNumber: 0,
    isPassengerTrip: true,
  });

  const updateTripQuery = (fieldName, newValue) => {
    setTripQuery(query => ({
      ...query,
      [fieldName]: newValue,
    }))
  };
  //#endregion

  //#region Handle query change
  const changePlaceInputHandler = (newPlace, index) => {
    let fieldName = 'start'
    if (index !== 0) {
      fieldName = 'end';
    }

    updateTripQuery(fieldName, {
      city: newPlace.city,
      country: newPlace.country,
      placeId: newPlace.placeId,
      fullAddress: newPlace.fullAddress,
    })
  };

  const handleChangeDeparture = (newValue) => {
    const departureDate = new Date(newValue).getTime();

    updateTripQuery('departure', departureDate);
  };
  //#endregion

  //#region Handle
  const changeTripTypeHandler = (newValue, inputName) => {
    const tripType = newValue === translate('Пассажирская');

    updateTripQuery(inputName, tripType);
  };
  //#endregion

  //#region Check query completeness
  const checkQueryCompleteness = (query) => {
    let result = true;

    for (const item in query) {
      if (item === 'start' || item === 'end') {
        if (!query[item]?.placeId) {
          result = false;
        }
      } else {
        if (!query[item] && item !== 'isPassengerTrip') {
          result = false;
        }
      }
    }

    return result;
  }
  //#endregion

  //#region Handling search suitable passengers (tripOrders)
  const handleSearchPassengerClick = async (query) => {
    setIsLoading(true);

    if (!checkQueryCompleteness(query)) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Заполните все поля');

      setIsLoading(false);
      return;
    }
    try {
      // const result = await searchSuitableTripsWithExactDate(query.start,
      //   query.end, query.departure, query.isPassengerTrip, true);

      const result = await searchTripsOrdersByQuery(user.idPost, query.start
        .placeId, query.end.placeId, query.departure, query.isPassengerTrip);

      const passengersWithSuitableNumberOfPlaces = result
        .filter(order => parseInt(order.requiredNumberOfPlaces) <= query
          .placesNumber);

      const otherPassengers = passengersWithSuitableNumberOfPlaces
        .filter(order => (order.creatorIdPost !== user.idPost));

      const ownPassengers = passengersWithSuitableNumberOfPlaces
        .filter(order => (order.creatorIdPost === user.idPost));

      setSuitablePassengers(otherPassengers);
      setSuitableOwnPassengers(ownPassengers);
      navigate('searchResults');
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
      setIsOrdersLoading(false);
    }
  };
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      {isLoading ?
        <Loader/>
        :
        <div className={`SearchTripOrder ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'SearchTripOrder-Item'}>
            <label className={'SearchTripOrder-Label'}>
              {translate('Откуда')}
            </label>

            <LocationIq
              externalAction={changePlaceInputHandler}
              index={0}
              receivedAddress={tripQuery.start.fullAddress}
            />
          </div>

          <div className={'SearchTripOrder-Item'}>
            <label className={'SearchTripOrder-Label'}>
              {translate('Куда')}
            </label>

            <LocationIq
              externalAction={changePlaceInputHandler}
              index={1}
              receivedAddress={tripQuery.end.fullAddress}
            />
          </div>

          <div className={'SearchTripOrder-Item'}>
            <label className={'SearchTripOrder-Label'}>
              {translate('Дата')}
            </label>

            <input
              type={'date'}
              className={'FormControl'}
              onChange={(event) => handleChangeDeparture(event.target.value)}
              value={tripQuery.departure ? format(new Date(tripQuery.departure),
                'yyyy-MM-dd') : ''}
            />
          </div>

          <div className={'SearchTripOrder-Item FlexBlock'}>
            <div
              className={
                'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W65'
              }
            >
              {translate('Максимальное количество мест')}
            </div>

            <div
              className={
                'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W30'
              }
            >
              <Select
                classNames={{
                  control: (state) =>
                    state.isFocused ? 'FormControl AddTripForm-Select'
                      : 'FormControl AddTripForm-Select',
                }}
                options={transformStringsArrayToSelectOptions(placesNumber)}
                placeholder={translate('Выбрать')}
                onChange={(event) => updateTripQuery('placesNumber',
                  parseInt(event.value))}
                value={{
                  value: tripQuery?.placesNumber || '',
                  label: tripQuery?.placesNumber || '',
                }}
              />
            </div>
          </div>

          <div className={'SearchTripOrder-Item'}>
            <label className={'AddTripForm-Label'}>
              {translate('Тип поездки')}
            </label>

            <RadioFieldSet
              elementName={'isPassengerTrip'}
              elementText={`${translate('Пассажирская')}_${translate('Грузовая')}`}
              inputChangeHandler={changeTripTypeHandler}
              checkedInput={tripQuery.isPassengerTrip ? translate('Пассажирская')
                : translate('Грузовая')
              }
              classes={'FormControl'}
            />
          </div>

          <div className={'SearchTripOrder-Item'}>
            <button
              type={'button'}
              className={'RegularButton'}
              onClick={() => handleSearchPassengerClick(tripQuery)}
            >
              {translate('Найти')}
            </button>
          </div>
        </div>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default SearchTripOrder;
