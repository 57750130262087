import React, {useContext, useEffect, useState} from 'react';
import {
  FaDog,
  FaLuggageCart,
  FaWalking,
} from "react-icons/fa";
import {BiAddToQueue} from 'react-icons/bi';
import {RiExchangeFill, RiMapPinUserLine} from 'react-icons/ri';
import {FcApproval} from "react-icons/fc";
import {AppContext} from "../context/appContext";
import {
  cancelReservationsInAllTrips,
  searchTripsForTripOrder,
  showFormattedToLanguageDate,
} from "../utils/helpers";
import DisplayMessengersRow from "./DisplayMessengersRow";
import {AiFillSafetyCertificate} from "react-icons/ai";
import DisplayUserData from "./DisplayUserData";
import {useNavigate} from "react-router-dom";
import Loader from "./Loader";
import DisplayTripPointsData from "./DisplayTripPointsData";
import {MdOutlineTransferWithinAStation} from "react-icons/md";
import ModalConfirmAction from "../modals/ModalConfirmAction";
import {
  deleteDocumentFromCollectionWithID, getDocInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  pagesInterface,
  tripOrderModel
} from "../utils/models";
import ModalNotification from "../modals/ModalNotification";
import useTranslation from "../hooks/useTranslation";
import {tripOrderService} from "../utils/tripOrderService";

const DisplayTripOrderDetails = ({
  isDispatcher = true,
  isReadOnly = false,
  handleShowCreateClick = () => {},
  isNotePad = false,
  handleShowSuitableTrips = () => {},
  handleShowPublishScreen = () => {},
  handleGoBack = () => {},
  addClasses = '',
  isReadyToReservation = false,
  handleGoToReservationClick = () => {},
  isFromExchange = false,
  isAdmin = false,
  searchOnlyOthersTrips = false,
  searchOnlyOwnTrips = false,
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {currentTripOrder, setCurrentTripOrder} = useContext(AppContext);
  //#endregion

  //#region Get screen managing functions from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    if (!currentTripOrder?.idPost) {
      handleGoBack();
    }
    setScreenTitle('Детали поездки');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loaders
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!currentTripOrder?.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, []);
  //#endregion

  //#region Handle find trip click
  const handleFindTripClick = async () => {
    setIsLoading(true);

    try {
      const searchResult = await searchTripsForTripOrder(currentTripOrder,
        user?.idPost, searchOnlyOthersTrips, searchOnlyOwnTrips);

      handleShowSuitableTrips(searchResult);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: '',
    title: '',
  });

  const updateNotificationData = (isVisible, data) => {
    setIsNotification(isVisible);

    if (isVisible) {
      setNotificationData(data);
    }
  }
  //#endregion

  //#region Delete confirmation modal
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  //#endregion

  //#region Delete trip order
  const handleDeleteClick = () => {
    setIsConfirmationVisible(true);
    setConfirmationTitle('Подтверждение действия');
    setConfirmationMessage('Вы действительно хотите удалить поездку?');
  }

  const confirmDelete = async () => {
    try {
      setIsLoading(true);
      setIsConfirmationVisible(false);

      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, currentTripOrder.idPost);

      if (actualTripOrder.isActive) {
        updateNotificationData(true, {
          message: 'Нельзя удалить активную поездку',
          title: 'Уведомление',
        })

        return;
      }

      if (actualTripOrder.hasAnyReservations) {
        await cancelReservationsInAllTrips(actualTripOrder, 'Пассажир был' +
          ' удален');
      }

      await deleteDocumentFromCollectionWithID(collectionsInterface.tripsOrders,
        actualTripOrder.idPost);

      setCurrentTripOrder(tripOrderModel);

      if (isNotePad) {
        navigate('/notepad');
      } else {
        navigate('/passengers/myPassengers');
      }
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  const cancelDelete = () => {
    setIsConfirmationVisible(false);
  }
  //#endregion

  //#region Activate trip order
  const handleActivateClick = async () => {
    setIsLoading(true);

    try {
      const tripOrder = await tripOrderService.getTripOrderToActivate(
        currentTripOrder.idPost);

      setCurrentTripOrder(tripOrder);

      navigate(`/${pagesInterface.passengers}/createTripOrder`)
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Handle buttons displaying
  const defineIfSearchTripButtonShouldBeShown = () => {
    return ((!isNotePad && !isReadyToReservation) && (!currentTripOrder
      .isTransferred && !isAdmin) && (!currentTripOrder.isCancelled))
  }

  const defineIfEditButtonShouldBeShown = () => {
    return (((isDispatcher && currentTripOrder
          ?.creatorIdPost === user?.idPost) || isAdmin) &&
        (!currentTripOrder.isActive) && (!isFromExchange)) &&
      (!currentTripOrder.isTransferred) && (!currentTripOrder.isCancelled)
  }

  const defineIfDeleteButtonShouldBeShown = () => {
    return ((isDispatcher && !isFromExchange) && (!currentTripOrder
      ?.isActive && !currentTripOrder?.isTransferred));
  }

  const defineIfActivateButtonShouldBeShown = () => {
    return currentTripOrder.isCancelled && currentTripOrder.acceptedDeal === null;
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`TripDetails ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <div
            className={
              'TripDetails-Block RegularTitle RegularTitle_Small DesktopTitle'
            }
          >
            {translate(screenTitle)}
          </div>

          <div
            className={'TripDetails-Block TripDetails-DepartureTime'}>
            {currentTripOrder?.departureTime ? showFormattedToLanguageDate(lang,
              currentTripOrder?.departureTime, true) : 'Invalid time'
            }
          </div>

          <h5 className={'RegularTitle RegularTitle_Small'}>
            {`${currentTripOrder?.passengerName}`}
          </h5>

          {currentTripOrder?.passengerPhones?.map((phone, pIndex) => (
            <React.Fragment key={pIndex}>
              <div
                className='TripDetails-Item'
              >
                <DisplayMessengersRow
                  phone={phone}
                  isPhoneNumberRefactored={true}
                  isLinkToCall={true}
                />
              </div>
            </React.Fragment>
          ))}

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {(!isDispatcher && currentTripOrder?.creatorIdPost) &&
            <DisplayUserData
              userId={currentTripOrder?.creatorIdPost}
              isDriver={false}
              isFullInfo={true}
              areContactsVisible={isDispatcher}
            />
          }

          <div
            className={
              'TripDetails-Block TripDetails-RouteBlock'
            }
          >
            <DisplayTripPointsData
              tripPoints={[currentTripOrder?.startPoint,
                currentTripOrder?.endPoint]}
            />
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div
            className={'TripDetails-Block TripDetails-TripOrderType'}>
              <span className='TripDetails-BlockLabel'>
                {`${translate('Тип перевозки')}: `}
              </span>

            {`${currentTripOrder?.isPassengerTrip ?
              translate('Пассажирская')
              :
              translate('Грузовая')
            }`}
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {currentTripOrder?.isPassengerTrip ?
            <div
              className={'TripDetails-Block FlexBlock FlexBlock_JustifyLeft ' +
                'TripDetails-OptionsBlock'}
            >
              {currentTripOrder?.isByFoot &&
                <p
                  className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                >
                  <FaWalking/>
                </p>
              }

              {currentTripOrder?.isTripWithPets &&
                <p
                  className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                >
                  <FaDog/>
                </p>
              }

              {currentTripOrder?.isForChange &&
                <p
                  className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                >
                  <RiExchangeFill/>
                </p>
              }

              {currentTripOrder?.isPassengerAddress &&
                <p
                  className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                >
                  <RiMapPinUserLine/>
                </p>
              }

              {currentTripOrder?.isSeparatePlaceForLuggage &&
                <p
                  className={'FlexBlock FlexBlock_Columns TripDetails-Option'}
                >
                  <FaLuggageCart/>
                </p>
              }

              {currentTripOrder?.isTransferred &&
                <MdOutlineTransferWithinAStation/>
              }

              {currentTripOrder?.addOptions.length > 0 &&
                <p
                  className={'FlexBlock FlexBlock_Columns TripDetails-Option'}>
                  <BiAddToQueue/>
                </p>
              }

              {currentTripOrder?.addOptions.map((option, oIndex) => {
                if (option.checked) {
                  return (
                    <p
                      key={oIndex}
                      className={
                        'FlexBlock FlexBlock_Columns TripDetails-Option'
                      }
                    >
                      <FcApproval/>
                    </p>
                  )
                } else {
                  return <></>
                }
              })}
            </div>
            :
            <div
              className={
                'TripDetails-Block ' +
                'TripDetails-TripOrderComment'
              }
            >
                <span className='TripDetails-BlockLabel'>
                  {`${translate('Комментарий к грузу')}: `}
                </span>
              <div>
                {currentTripOrder?.cargoDescription}
              </div>
            </div>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {currentTripOrder?.isPassengerTrip &&
            <>
              <div
                className={'TripDetails-Block TripDetails-PlacesAmount'}>
                  <span
                    className='TripDetails-BlockLabel'
                  >
                    {`${translate('Количество мест')}: `}
                  </span>

                {`${currentTripOrder?.requiredNumberOfPlaces}`}
              </div>

              <div className={'SeparationBlock SeparationBlock_Limited'}></div>
            </>
          }

          <div
            className={'TripDetails-Block TripDetails-TripPrice'}
          >
            <span className='TripDetails-BlockLabel'>
              {`${translate('Стоимость поездки')}: `}
            </span>

            {`${currentTripOrder.isPassengerTrip ? currentTripOrder
              ?.pricePerPerson * currentTripOrder?.requiredNumberOfPlaces
              : currentTripOrder?.pricePerPerson
            }
              ${translate('грн.')}`}
          </div>

          {!isNotePad && currentTripOrder?.dispatcherFee &&
            <>
              <div
                className={'TripDetails-Block FlexBlock FlexBlock_JustifyLeft'}
              >
                  <span
                    className='TripDetails-BlockLabel'>
                      {translate('Гонорар диспетчера')}
                  </span>

                {`: ${currentTripOrder?.dispatcherFee * currentTripOrder
                  .requiredNumberOfPlaces} `}

                {translate('грн.')}
              </div>

              {(currentTripOrder?.isPaymentByCash || currentTripOrder
                  ?.isPaymentOnCard || currentTripOrder?.isForChange) &&
                <div className={'TripDetails-SecondaryBlockText'}>
                  {currentTripOrder?.isPaymentOnCard &&
                    <div className={'FlexBlock FlexBlock_JustifyLeft'}>
                      <span>
                        {translate('Оплата через Аutostop (оплата проводится через платежную систему)')}
                      </span>

                      <span
                        className={'ColoredText TripDetails-SafeBadge'}
                      >
                        <AiFillSafetyCertificate/>
                      </span>
                    </div>
                  }

                  {currentTripOrder?.isPaymentByCash &&
                    <div>
                      {translate('Наличными')}
                    </div>
                  }

                  {currentTripOrder?.isForChange &&
                    <div>
                      {translate('По обмену')}
                    </div>
                  }
                </div>
              }
            </>
          }

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          <div
            className={'TripDetails-Block TripDetails-TripOrderComment'}>
            <div className='TripDetails-BlockLabel'>
              {`${translate('Комментарий к поездке')}:`}
            </div>

            <div className={'TripDetails-BlockText'}>
              {currentTripOrder?.tripOrderComment || translate(
                'Отсутствует')}
            </div>
          </div>

          <div className={'SeparationBlock SeparationBlock_Limited'}></div>

          {isNotePad &&
            <div className={'TripDetails-Block'}>
              <button
                type={'button'}
                className={'RegularButton TripDetails-FindTripButton'}
                onClick={() => handleShowPublishScreen()}
              >
                {translate('Перенести в раздел пассажиры')}
              </button>
            </div>
          }

          {isReadyToReservation &&
            <div className={'TripDetails-Block'}>
              <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => handleGoToReservationClick()}
              >
                {translate('К бронированию')}
              </button>
            </div>
          }

          {!isReadOnly &&
            <>
              {(!currentTripOrder?.acceptedDeal?.id) &&
                <>
                  {defineIfSearchTripButtonShouldBeShown() &&
                    <div className={'TripDetails-Block'}>
                      <button
                        type={'button'}
                        className={'RegularButton RegularButton_Contrast' +
                          ' TripDetails-FindTripButton'}
                        onClick={() => handleFindTripClick()}
                      >
                        {translate('Подобрать поездку')}
                      </button>
                    </div>
                  }

                  {defineIfEditButtonShouldBeShown() &&
                    <div className={'TripDetails-Block'}>
                      <button
                        type={'button'}
                        className={'RegularButton'}
                        onClick={() => handleShowCreateClick()}
                      >
                        {translate('Редактировать')}
                      </button>
                    </div>
                  }

                  <div className={'TripDetails-Block'}>
                    <button
                      type={'button'}
                      className={'RegularButton RegularButton_Contrast'}
                      onClick={() => handleGoBack()}
                    >
                      {translate('Вернуться')}
                    </button>
                  </div>
                </>
              }

              {defineIfDeleteButtonShouldBeShown() &&
                <div className={'TripDetails-Block'}>
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast' +
                      ' RegularButton_Danger'}
                    onClick={handleDeleteClick}
                  >
                    {translate('Удалить')}
                  </button>
                </div>
              }

              {defineIfActivateButtonShouldBeShown() &&
                <button
                  type={'button'}
                  className={'RegularButton'}
                  onClick={handleActivateClick}
                >
                  {translate('Перевести в актуальные')}
                </button>
              }
            </>
          }
        </div>
      }

      {isConfirmationVisible &&
        <ModalConfirmAction
          message={confirmationMessage}
          title={confirmationTitle}
          isVisible={isConfirmationVisible}
          hideModal={() => setIsConfirmationVisible(false)}
          confirmAction={confirmDelete}
          cancelAction={cancelDelete}
        />
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          message={notificationData.message}
          title={notificationData.title}
        />
      }
    </>
  );
  //#endregion
};

export default DisplayTripOrderDetails;
