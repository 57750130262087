import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Route, Routes, useNavigate} from "react-router-dom";
import {
  collectionsInterface, pagesInterface,
  reservationModel, subpagesInterface,
  tripModel,
  tripOrderModel
} from "../utils/models";
import FavouriteDriversList from "../components/FavouriteDriversList";
import ReservedTripsForPassengers
  from "../components/ReservedTripsForPassengers";
import ActiveTripsForPassengers from "../components/ActiveTripsForPassengers";
import TripsHistoryForPassengers from "../components/TripsHistoryForPassengers";
import PassengersPageMenu from "../components/PassengersPageMenu";
import CreateTripOrder from "../components/CreateTripOrder";
import DisplayTripOrderDetails from "../components/DisplayTripOrderDetails";
import SuitableTripsScreen from "../components/SuitabeTripsScreen";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import ModalNotification from "../modals/ModalNotification";
import '../styles/Passengers.scss';
import MyPassengers from "../components/MyPassengers";

const Passengers = ({addClasses = ''}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    currentTripOrder,
    setCurrentTripOrder,
    setCurrentReservation,
    setCurrentTrip,
  } = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Пассажиры');
  }, []);
  //#endregion

  //#region Clear data for current tripOrder, trip, reservation
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
    setCurrentReservation(reservationModel);
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: '',
    title: '',
  });

  const updateNotificationData = (isVisible, data) => {
    setIsNotification(isVisible);

    if (isVisible) {
      setNotificationData(data);
    }
  }
  //#endregion

  //#region Handling show create trip order or editing (MOVE TO CUSTOM HOOK)
  const handleShowCreateTrip = async () => {
    try {
      if (currentTripOrder.idPost) {
        const actualTripOrder = await getDocInCollection(collectionsInterface
          .tripsOrders, currentTripOrder.idPost);

        if (actualTripOrder?.isActive) {
          updateNotificationData(true, {
            message: 'Нельзя редактировать активную поездку',
            title: 'Уведомление',
          })

          return;
        }

        setCurrentTripOrder(actualTripOrder);
      }

      navigate(`/${pagesInterface.passengers}/${subpagesInterface.createTripOrder}`);
    } catch (error) {
      return error;
    }
  };
  //#endregion

  //#region Handling show suitable trips
  const [suitableTrips, setSuitableTrips] = useState([]);
  const [mySuitableTrips, setMySuitableTrips] = useState([]);

  const handleShowSuitableTrips = (searchResult) => {
    const otherUsersTrips = searchResult
      .filter(trip => trip.creatorIdPost !== user.idPost);
    const userTrips = searchResult
      .filter(trip => trip.creatorIdPost === user.idPost);

    setSuitableTrips(otherUsersTrips);
    setMySuitableTrips(userTrips);

    setIsLoading(false);

    navigate(`${subpagesInterface.suitableTrips}`);
  }
  //#endregion

  //#region Handlers for go back actions
  const handleGoBackFromTripCreation = () => {
    setCurrentTripOrder(tripOrderModel);
    navigate(`/${pagesInterface.passengers}/${subpagesInterface.myPassengers}`,
      { replace: true });
  }

  const handleGoBackFromTripOrderDetails = () => {
    setCurrentTripOrder(tripOrderModel);
    navigate(`/${pagesInterface.passengers}/myPassengers`);
  }

  const handleGoBackSuitableTripsList = () => {
    navigate('displayTripOrderDetails');
  }
  //#endregion

  //#region Handle trip order card click in different screens

  const handleTripOrderCardClickInReservedTrips = (tripOrder) => {
    setCurrentTripOrder(tripOrder);

    navigate('/passengers/reservedTrips/reservedTripOrderDetails');
  }

  const handleTripOrderCardClickInActiveTrips = (reservation, tripOrder,
    trip) => {
    setCurrentTripOrder(tripOrder);
    setCurrentTrip(trip);
    setCurrentReservation(reservation);

    navigate('/passengers/activeTrips/activeTripOrderDetails')
  }

  const handleTripOrderCardClickInArchivedTrips = (reservation, tripOrder,
    trip) => {
    setCurrentTripOrder(tripOrder);
    setCurrentReservation(reservation);
    setCurrentTrip(trip);

    navigate('/passengers/archivedTrips/archivedTripOrderDetails')
  }
  //#endregion

  //#region Handle reservation card click
  const handleReservationCardClick = (reservation, tripOrder, trip) => {
    setCurrentTripOrder(tripOrder);
    setCurrentTrip(trip);
    setCurrentReservation(reservation);

    navigate('reservedTrips/reservationDetails');
  }
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <main className={`Passengers ${addClasses}`}>
        <div className={'Passengers-Content'}>
          <Routes>
            <Route
              index
              element={
                <PassengersPageMenu />
              }
            />

            <Route
              path={`${subpagesInterface.myPassengers}/*`}
              element={
                <MyPassengers
                  handleShowCreateTrip={handleShowCreateTrip}
                />
              }
            />

            <Route
              path={`${subpagesInterface.createTripOrder}/*`}
              element={
                <CreateTripOrder
                  isNotepad={false}
                  handleGoBack={handleGoBackFromTripCreation}
                  addClasses={'App-Screen App-Screen_Narrow'}
                />
              }
            />

            <Route
              path={`${subpagesInterface.displayTripOrderDetails}/*`}
              element={
                <DisplayTripOrderDetails
                  isNotePad={false}
                  isDispatcher={true}
                  handleShowCreateClick={handleShowCreateTrip}
                  handleGoBack={handleGoBackFromTripOrderDetails}
                  addClasses={'App-Screen App-Screen_Narrow'}
                  handleShowSuitableTrips={handleShowSuitableTrips}
                />
              }
            />

            <Route
              path={`${subpagesInterface.favouriteDrivers}/*`}
              element={
                <FavouriteDriversList
                  addClasses={'App-Screen'}
                />
              }
            />

            <Route
              path={`${subpagesInterface.reservedTrips}/*`}
              element={
                <ReservedTripsForPassengers
                  addClasses={'App-Screen'}
                  isNotepad={false}
                  handleShowCreateTrip={handleShowCreateTrip}
                  handleTripOrderCardClick={
                    handleTripOrderCardClickInReservedTrips}
                  handleReservationCardClick={handleReservationCardClick}
                />
              }
            />

            <Route
              path={`${subpagesInterface.activeTrips}/*`}
              element={
                <ActiveTripsForPassengers
                  isNotepad={false}
                  handleShowCreateTrip={handleShowCreateTrip}
                  handleTripOrderCardClick={
                    handleTripOrderCardClickInActiveTrips}
                />
              }
              replace
            />

            <Route
              path={`${subpagesInterface.archivedTrips}/*`}
              element={
                <TripsHistoryForPassengers
                  addClasses={'App-Screen'}
                  isNotepad={false}
                  handleShowCreateTrip={handleShowCreateTrip}
                  handleTripOrderCardClick={
                    handleTripOrderCardClickInArchivedTrips}
                />
              }
            />

            <Route
              path={`${subpagesInterface.suitableTrips}/*`}
              element={
                <SuitableTripsScreen
                  addClasses={'App-Screen'}
                  trips={suitableTrips}
                  addTrips={mySuitableTrips}
                  handleGoBack={handleGoBackSuitableTripsList}
                  isDispatcher={true}
                  isLoading={isLoading}
                />
              }
            />
          </Routes>
        </div>
      </main>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          message={notificationData.message}
          title={notificationData.title}
        />
      }
    </React.Fragment>
  );
  //#endregion
};

export default Passengers;
