import React, {useContext, useEffect} from 'react';
import {userModel} from "../utils/models";
import {AppContext} from "../context/appContext";
import {Nav, Tab} from "react-bootstrap";
import RegistrationForm from "./RegistrationForm";
import UserTransactionsForAdmin from "./UserTransactionsForAdmin";
import UserVehiclesForAdmin from "./UserVehiclesForAdmin";
import {useNavigate} from "react-router-dom";
import useTranslation from "../hooks/useTranslation";

const ShowUserDataToAdmin = ({
  user = userModel,
  setUsers = () => {},
  setCurrentVehicle = () => {},
  activeTabKey,
  setActiveTabKey = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get handler to manage screen title
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Данные пользователя');
  }, []);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Redirect if no data
  useEffect(() => {
    if (!user.idPost) {
      const index = window.location.pathname.lastIndexOf('/');
      const redirectPath = window.location.pathname.slice(0, index);
      navigate(redirectPath || '/');
    }
  }, [])
  //#endregion

  //#region Render
  return (
    <>
      <div className={'PaddingBlock'}></div>

      <Tab.Container
        activeKey={activeTabKey}
      >
        <Nav variant="pills" className={'flex-row TabControls'}>
          <Nav.Item>
            <Nav.Link
              eventKey='personData'
              onClick={() => setActiveTabKey('personData')}
            >
              {translate('Личные данные')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='transactions'
              onClick={() => setActiveTabKey('transactions')}
            >
              {translate('Транзакции')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='vehicles'
              onClick={() => setActiveTabKey('vehicles')}
            >
              {translate('Автомобили')}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane
            eventKey='personData'
          >
            <RegistrationForm
              currentUser={user}
              isRegistration={false}
              isEditMode={true}
              isAdmin={true}
              setUsers={setUsers}
            />
          </Tab.Pane>

          <Tab.Pane
            eventKey='transactions'
          >
            <UserTransactionsForAdmin
              user={user}
            />
          </Tab.Pane>

          <Tab.Pane
            eventKey='vehicles'
          >
            <UserVehiclesForAdmin
              user={user}
              setCurrentVehicle={setCurrentVehicle}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
  //#endregion
};

export default ShowUserDataToAdmin;
