import React from 'react';
import LoginForm from "../components/LoginForm";
import '../styles/Login.scss';

const Login = ({addClasses=''}) => {
  //#region Render
  return (
    <div className={`Login ${addClasses}`}>
      <LoginForm />
    </div>
  );
  //#endregion
};

export default Login;
