import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {auth} from "../utils/firebaseConfigAndFunctions";
import StartPage from "./StartPage";

const Home = () => {
  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    if (auth.currentUser) {
      navigate('/profile');
    }
  }, [auth.currentUser]);

  //#region Render
  return (
    <React.Fragment>
      <div className={'Home'}>
        <StartPage addClasses={'Home-StartPage'}/>
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default Home;
