import {getCollectionWhereKeysValues} from "./firebaseConfigAndFunctions";
import {
  adminDataTypesInterface,
  collectionsInterface,
} from "./models";
import {DEFAULT_LANG} from "./data";

async function getTextPageFromDb(pageType) {
  const pages = await getCollectionWhereKeysValues(collectionsInterface.adminData, [
    {
      key: 'dataType',
      value: adminDataTypesInterface.textPages,
    },
    {
      key: 'type',
      value: pageType,
    },
  ], 2);

  const pageData = pages
    .sort((prev, next) => next.dateCreating
      - prev.dateCreating)[0];

  return pageData
};

function getTextPageTextTranslation(textObj, lang) {
  const translatedText = textObj[lang];

  return translatedText || textObj[DEFAULT_LANG] || '';
}

async function getTranslatedPageData(pageType, lang) {
  const pageFromDb = await getTextPageFromDb(pageType);

  const title = getTextPageTextTranslation(pageFromDb.title, lang);

  const text = getTextPageTextTranslation(pageFromDb.text, lang);

  return {
    title,
    text,
  }
}

export const textPagesDisplayingService = {
  getTranslatedPageData,
}
