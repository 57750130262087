import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "./context/appContext";
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import {
  auth,
  fire,
  getCollection,
  getCollectionWhereKeyValue,
  getDocumentFromRdb,
} from "./utils/firebaseConfigAndFunctions";
import Profile from "./pages/Profile";
import NotePad from "./pages/NotePad";
import RegistrationPage from "./pages/RegistrationPage";
import Passengers from "./pages/Passengers";
import Trips from "./pages/Trips";
import {
  collectionsInterface,
  pagesInterface,
  rolesInterface, textPagesTypesInterface,
  userModel
} from "./utils/models";
import RecoverPassword from "./pages/RecoverPassword";
import RulesAndConfidentialityPolicy
  from "./pages/RulesAndConfidentialityPolicy";
import Home from "./pages/Home";
import Header from "./components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./components/Footer";
import {
  checkIfDriverHasTripsInProgress,
  checkUserDataCompletenessAndSetToState,
  defineLanguageOnLoad,
  getLanguageCodeFromBrowser,
  getLocationAndSaveToDb,
  listenToNotificationAdding,
  listenToNotificationChange,
} from "./utils/helpers";
import Exchange from "./pages/Exchange";
import ResetPasswordScreen from "./pages/ResetPasswordScreen";
import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { englishDictionary } from "./utils/dictionaries";
import UsersManagement from "./components/UsersManagement";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Statistic from "./components/Statistic";
import Catalogs from "./components/Catalogs";
import DictionariesEditing from "./components/DictionariesEditing";
import EditAddOptions from "./components/EditAddOptions";
import TextPages from "./components/TextPages";
import AppSettings from "./components/AppSettings";
import DisplayTripsOrdersForAdmin
  from "./components/DisplayTripsOrdersForAdmin";
import DisplayTripsForAdmin from "./components/DisplayTripsForAdmin";
import DisplayTestimonialsForAdmin
  from "./components/DisplayTestimonialsForAdmin";
import TransactionsHistoryForAdmin
  from "./components/TransactionsHistoryForAdmin";
import Loader from "./components/Loader";
import PersonData from "./components/PersonData";
import './styles/App.scss';

const functions = getFunctions(getApp());
connectFunctionsEmulator(functions, "localhost", 5001);

const App = () => {
  //#region Get lang, dictionaries and functions from context
  const {
    setLang,
    setLanguages,
    setDictionary,
    setDictionaries,
    setDefaultDictionary
  } = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get trip in progress trigger from context
  const {hasTripBecomeInProgress} = useContext(AppContext);
  //#endregion

  //#region Get user from context
  const {isUserDataComplete, setIsUserDataComplete} = useContext(AppContext);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Set initial dictionaries and subscribe to auth changes
  useEffect(() => {
    const userLanguage = getLanguageCodeFromBrowser();

    getCollection(collectionsInterface.dictionaries).then(dictionaries => {
      const availableLanguages = dictionaries[0].dictionaries.map(dict => ({
        name: dict.name,
        description: dict.description,
      }));
      setLanguages(availableLanguages);

      const appLanguage = defineLanguageOnLoad(availableLanguages
        .map(language => language.name), userLanguage);
      setLang(appLanguage);

      setDictionaries(dictionaries[0].dictionaries);
      const currentDictionary = dictionaries[0].dictionaries
        .find(dict => dict.name === appLanguage);
      setDictionary(currentDictionary);
      setDefaultDictionary(dictionaries[0].dictionaries
        .find(dict => dict.name === 'ru'));
    }).catch(() => setDefaultDictionary({
      name: 'en',
      description: 'English',
      dictionary: englishDictionary,
    }))
      .finally(() => setIsLoading(false));

    const unsubscribe = fire.auth().onAuthStateChanged((result) => {
      try {
        if (result) {
          getCollectionWhereKeyValue(collectionsInterface.users, 'uid',
            result.uid).then(users => {
              if (users.length > 0) {
                checkUserDataCompletenessAndSetToState(users[0],
                  setUser, setIsUserDataComplete);
              } else {
                setTimeout(() => {
                  getCollectionWhereKeyValue(collectionsInterface.users,
                    'uid', result.uid)
                    .then(users => {
                      if (users.length > 0) {
                        checkUserDataCompletenessAndSetToState(users[0],
                          setUser, setIsUserDataComplete);
                      }
                    }).catch(() => {});
                }, 500);
              }
            }).catch(() => {});
        } else {
          setIsUserDataComplete(true);
          setUser(userModel);
        }
      } catch (error) {
        return error.message;
      }
    })

    return () => {
      unsubscribe();
    }
  }, []);
  //#endregion

  //#region Define GeoLocation if there is trip in progress
  useEffect(() => {
    let intervalId = null;

    if (user.idPost) {
      checkIfDriverHasTripsInProgress(user).then(result => {
        if (result.hasTripInProgress) {
          getLocationAndSaveToDb(user, result.trip);

          intervalId = setInterval(() => {
            getLocationAndSaveToDb(user, result.trip);
          }, 3600000);
        }
      }).catch(() => {});

      return () => {
        if (intervalId) {
          clearInterval(intervalId)
          intervalId = null;
        }
      }
    }
  }, [user.idPost, hasTripBecomeInProgress]);
  //#endregion

  //#region Google maps API
  const loadScript = (url, callback) => {
    const apiScript = document.getElementById('googleApiScript');

    if (!apiScript) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.async = true;
      script.defer = true;
      script.id = 'googleApiScript';
      document.getElementsByTagName("head")[0].appendChild(script);
      callback();
    }
  };

  const actionAfterLoad = () => {
    return 'Google API loaded';
  };

  useEffect(() => {
    const Google_Places_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${Google_Places_API_KEY
      }&libraries=places`,
      actionAfterLoad,
    );
  }, []);
  //#endregion

  //#region Listen to notifications and make actions
  const [notifications, setNotifications] = useState([]);

  function addNotificationToList(data) {
    if (data) {
      setNotifications(notifications => ([data, ...notifications]));
    }
  }

  function changeNotificationList(data) {
    if (data) {
      setNotifications(notifications => notifications
        .map(notification => notification.id === data.id ? data : notification));
    }
  }

  const getInitialNotifications = async () => {
    const result = await getDocumentFromRdb(`/notifications/${user.idPost}`);
    const resultAsArray = Object.values(result);
    const sortedResult = resultAsArray
      .sort((prev, next) => next.dateCreating - prev.dateCreating);

    setNotifications(sortedResult);
  }

  useEffect(() => {
    let subscription;
    let changesSubscription;

    getInitialNotifications().catch(() => {});

    if (user?.idPost) {
      subscription = listenToNotificationAdding(user?.idPost,
        addNotificationToList);
      changesSubscription = listenToNotificationChange(user?.idPost,
        changeNotificationList);
    } else {
      setNotifications([]);
    }

    return () => {
      try {
        if (subscription) {
          subscription.unsubscribe();
          changesSubscription.unsubscribe();
        }
      } catch (error) {
        return error;
      }
    };
  }, [user?.idPost]);
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={'App'}>
          <Header
            addClasses={'App-Header'}
            notifications={notifications}
          />
          <Routes>
            <Route index element={<Home/>}/>

            {auth.currentUser ? (isUserDataComplete ?
              <>
                {user?.role === rolesInterface.admin ? (
                  <>
                    <Route
                      path={`${pagesInterface.statistic}/*`}
                      element={<Statistic addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.usersManagement}/*`}
                      element={<UsersManagement addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.catalogs}/*`}
                      element={<Catalogs addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.languages}/*`}
                      element={<DictionariesEditing addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.options}/*`}
                      element={<EditAddOptions addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.textPages}/*`}
                      element={<TextPages addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.settings}/*`}
                      element={<AppSettings addClasses={'Page App-Screen'}/>}
                    />

                    <Route
                      path={`${pagesInterface.passengersAdmin}/*`}
                      element={
                        <DisplayTripsOrdersForAdmin
                          addClasses={'Page App-Screen'}
                        />
                      }
                    />

                    <Route
                      path={`${pagesInterface.tripsAdmin}/*`}
                      element={
                        <DisplayTripsForAdmin addClasses={'Page App-Screen'}/>
                      }
                    />

                    <Route
                      path={`${pagesInterface.transactionsAdmin}/*`}
                      element={
                        <TransactionsHistoryForAdmin
                          addClasses={'Page App-Screen'}
                        />
                      }
                    />

                    <Route
                      path={`${pagesInterface.testimonialsAdmin}/*`}
                      element={
                        <DisplayTestimonialsForAdmin
                          addClasses={'Page App-Screen'}
                        />
                      }
                    />

                    <Route
                      path={`${pagesInterface.profile}/*`}
                      element={<Profile notifications={notifications} />}
                    />
                  </>) : (
                    <>
                      <Route
                        path={`${pagesInterface.profile}/*`}
                        element={<Profile notifications={notifications} />}
                      />

                      <Route
                        path={`${pagesInterface.notepad}/*`}
                        element={auth?.currentUser ? <NotePad/> : <Home/>}
                      />

                      <Route
                        path={`${pagesInterface.passengers}/*`}
                        element={auth?.currentUser ? <Passengers/> : <Home/>}
                      />

                      <Route
                        path={`${pagesInterface.exchange}/*`}
                        element={<Exchange addClasses={'App-Screen'}/>}
                      />

                      <Route
                        path={`${pagesInterface.trips}/*`}
                        element={<Trips/>}
                      />
                    </>
                  )
                }
              </>
                :
              <Route
                path={'*'}
                element={
                  <PersonData addClasses={'Profile App-Screen'}/>
                }
              />
              ) : (
                <>
                  <Route
                    path={`${pagesInterface.login}/*`}
                    element={<Login/>}
                  />

                  <Route
                    path={pagesInterface.recoverPassword}
                    element={<RecoverPassword addClasses={'App-RecoverPassword'}/>}
                  />

                  <Route
                    path={`${pagesInterface.register}/*`}
                    element={<RegistrationPage/>}
                  />

                  <Route
                    path={pagesInterface.rulesAndPolicy}
                    element={<RulesAndConfidentialityPolicy
                      addClasses={'App-RulesAndPolicy'}
                    />}
                  />

                  <Route
                    path={pagesInterface.removalPolicy}
                    element={<RulesAndConfidentialityPolicy
                      policyName={textPagesTypesInterface.removalPolicy}
                      addClasses={'App-RulesAndPolicy'}
                    />}
                  />

                  <Route
                    path={pagesInterface.termsOfUse}
                    element={<RulesAndConfidentialityPolicy
                      policyName={textPagesTypesInterface.termsOfUse}
                      addClasses={'App-RulesAndPolicy'}
                    />}
                  />

                  <Route
                    path={pagesInterface.cookiesPolicy}
                    element={<RulesAndConfidentialityPolicy
                      addClasses={'App-RulesAndPolicy'}
                      policyName={pagesInterface.cookiesPolicy}
                    />}
                  />

                  <Route
                    path={`${pagesInterface.resetPassword}/*`}
                    element={<ResetPasswordScreen
                      addClasses={'App-RecoverPasswordScreen'}/>}
                  />
                </>)
            }

            <Route
              path={'*'}
              element={<Home />}
            />
          </Routes>

          {auth.currentUser &&
            <Footer/>
          }
        </div>
      }
    </>
  );
  //#endregion
}

export default App;
