import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  checkAdminDataCompleteness,
  getNewPhoneObj,
  getPossibleAuthOptionsForEmail,
  updateAdmin,
  validateFullName
} from "../utils/helpers";
import {
  authProvidersCheckResultsInterface, collectionsInterface,
  endPointsInterface,
  phoneNumberModel,
  rolesInterface, serverUrl,
  userModel
} from "../utils/models";
import PhoneInput from "react-phone-input-2";
import {FaTrash} from "react-icons/fa";
import MessengersRow from "./MessengersRow";
import AddPhone from "./AddPhone";
import ModalNotification from "../modals/ModalNotification";
import axios from "axios";
import Loader from "./Loader";
import {getCollectionWhereKeyValue} from "../utils/firebaseConfigAndFunctions";
import {PHONE_NUMBER_MIN_LENGTH} from "../utils/data";
import useTranslation from "../hooks/useTranslation";

const AddAdmin = ({
  addClasses = '',
  admin = userModel,
  setAdmins = () => {},
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get title from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isPasswordsCompareError] = useState(false);
  //#endregion

  //#region Manage displaying screens
  const [isMainScreen, setIsMainScreen] = useState(true);
  const [isAddPhoneScreen, setIsAddPhoneScreen] = useState(false);
  //#endregion

  //#region Admin data
  const [adminData, setAdminData] = useState(userModel);
  const [updatedFields, setUpdatedFields] = useState({});

  const updateAdminData = (fieldName, newValue) => {
    setAdminData(data => ({
      ...data,
      [fieldName]: newValue,
    }));

    if (admin.idPost) {
      setUpdatedFields(data => ({
        ...data,
        [fieldName]: newValue,
      }));
    }
  };

  const phoneInputChangeValueHandler = (newValue, currentPhoneIndex, type) => {
    const result = adminData.phoneNumbers
      .map((phone, index) => index === currentPhoneIndex ?
        getNewPhoneObj(phone, newValue, type) : phone);

    updateAdminData('phoneNumbers', result);
  };

  const deletePhoneHandler = (currentPhoneIndex) => {
    const result = [...adminData.phoneNumbers];
    result.splice(currentPhoneIndex, 1);
    updateAdminData('phoneNumbers', result)
  };

  const addNewPhoneHandler = () => {
    setAdminData(admin => ({
      ...admin,
      phoneNumbers: [...admin.phoneNumbers, phoneNumberModel]
    }));
    setIsMainScreen(false);
    setIsAddPhoneScreen(true);
  };

  useEffect(() => {
    setAdminData(admin);
  }, [admin]);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (admin.idPost) {
      setScreenTitle('Редактирование админа');
    } else {
      setScreenTitle('Добавление админа');
    }
  }, []);
  //#endregion

  //#region Manage password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Check password and it's confirmation equality
  const checkPasswords = (psw, confirmationPsw) => {
    if (!psw || psw.length < 6) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Пароль должен быть не короче 6 символов');

      return false;
    }

    if (psw !== confirmationPsw) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Пароли не совпадают');

      return false;
    }

    return true;
  }
  //#endregion

  //#region Handle save admin
  const registrationSubmitHandler = async (event, user) => {
    event.preventDefault();
    setIsLoading(true);

    const areDataComplete = checkAdminDataCompleteness(user);

    if (!areDataComplete) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Заполните все поля');

      setIsLoading(false);

      return;
    }
    ;

    if (!validateFullName(user.fullName)) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage(
        'Ошибка в имени – недопустимые символы или превышена длина');

      setIsLoading(false);

      return;
    }

    const hasAnyWrongPhoneNumber = user?.phoneNumbers
      ?.some(phone => phone.phoneNumber.length < PHONE_NUMBER_MIN_LENGTH);

    if (hasAnyWrongPhoneNumber) {
      setIsNotification(true);
      setNotificationTitle('Ошибка ввода данных');
      setNotificationMessage('Некорректный номер телефона');
      setIsLoading(false);

      return;
    }

    if (!user.idPost) {
      try {
        const providersResult = await getPossibleAuthOptionsForEmail(user.email);

        if (providersResult !== authProvidersCheckResultsInterface.noProviders) {
          setIsNotification(true);
          setNotificationTitle('Ошибка ввода данных');
          setNotificationMessage(
            'Пользователь с такой электронной почтой уже существует');

          setIsLoading(false);

          return;
        }

        const arePasswordsEqual = checkPasswords(user.password,
          user.confirmPassword);

        if (!arePasswordsEqual) {
          setIsLoading(false);

          return;
        }
      } catch (error) {
        setIsLoading(false);

        return error;
      }

    }

    try {
      if (user.idPost) {
        if (Object.keys(updatedFields).length > 0) {
          const fieldsToSave = updatedFields;

          if (updatedFields.hasOwnProperty('password')) {
            const confirmPassword = updatedFields?.confirmPassword ||
              user.confirmPassword;

            const arePasswordsEqual = checkPasswords(updatedFields.password,
              confirmPassword);

            if (!arePasswordsEqual) {
              setIsLoading(false);

              return;
            }
          }

          if (updatedFields.hasOwnProperty('confirmPassword')) {
            delete fieldsToSave.confirmPassword;
          }

          const result = await updateAdmin(fieldsToSave, adminData.uid,
            adminData.idPost);

          if (result) {
            setAdmins(admins => admins
              .map(admin => admin.idPost === user.idPost ? user : admin));

            navigate(-1);
          } else {
            setIsNotification(true);
            setNotificationTitle('Уведомление');
            setNotificationMessage('Произошла ошибка. Действие не выполнено');
          }
        } else {
          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Изменений не было');

        }
      } else {
        const response = await axios.post(`${serverUrl}${endPointsInterface
          .createAdmin}`, {
          regInfo: {
            ...user,
            role: rolesInterface.admin,
          }
        });

        if (response.status === 201) {
          const newAdmin = await getCollectionWhereKeyValue(
            collectionsInterface.users, 'uid', response.data);

          setAdmins(state => [...state, newAdmin[0]]);

          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Администратор добавлен');

          setTimeout(() => {
            navigate('/usersManagement/adminsList')
          }, 500);
        } else {
          setIsNotification(true);
          setNotificationTitle('Уведомление');
          setNotificationMessage('Произошла ошибка. Администратор не создан');
        }
      }

      return true;
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Произошла ошибка. Администратор не создан');

      return error;
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region Render
  return (
    <div className={`AddAdmin ${addClasses}`}>
      <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
        {translate(screenTitle)}
      </h2>

      {isLoading ?
        <Loader/>
        :
        <>
          {isMainScreen ? (
            <form
              className={
                'RegistrationForm RegistrationFormContainer-RegistrationForm'
              }
              onSubmit={(event) => registrationSubmitHandler(event, adminData)}
            >
              <div className={'RegistrationForm-Item'}>
                <label
                  className={'RegistrationForm-Label'}
                  htmlFor="item01"
                >
                  {translate('ФИО')}
                </label>

                <input
                  id="item01"
                  placeholder={translate('ФИО')}
                  className={'FormControl RegistrationForm-Input'}
                  name={'fullName'}
                  type={'text'}
                  value={adminData.fullName}
                  onChange={(event) => updateAdminData('fullName',
                    event.target.value)}
                />
              </div>

              <div className={'RegistrationForm-Item'}>
                <label
                  className={'RegistrationForm-Label'}
                >
                  {translate('Номер телефона')}
                </label>

                {adminData?.phoneNumbers?.map((phone, index) => (
                  <div
                    key={index}
                    className={index !== 0 ? 'RegistrationForm-PhoneItem' : ''}
                  >
                    <div className={'PhoneRow'}>
                      <PhoneInput
                        name={'PhoneNumbers'}
                        country={'ua'}
                        onChange={(event) => phoneInputChangeValueHandler(
                          event, index, 'number')}
                        specialLabel={''}
                        value={phone?.phoneNumber}
                      />

                      {index > 0 &&
                        <div className={'PhoneAddRemoveButtons'}>
                          {index !== 0 &&
                            <button
                              type={'button'}
                              className={'DelButton'}
                              onClick={() => deletePhoneHandler(index)}
                            >
                              <FaTrash/>
                            </button>}
                        </div>
                      }
                    </div>

                    <MessengersRow
                      index={index}
                      phone={phone}
                      phoneInputChangeValueHandler={phoneInputChangeValueHandler}
                    />
                  </div>
                ))}

                {(admin?.phoneNumbers.length < 11) &&
                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast ' +
                      'RegistrationForm-AddPhoneButton'}
                    onClick={addNewPhoneHandler}
                  >
                    {translate('Добавить номер')}
                  </button>
                }
              </div>

              <div className={'RegistrationForm-Item'}>
                <label
                  className={'RegistrationForm-Label'}
                >
                  {translate('E-mail')}
                </label>

                <div className={'RegistrationForm-InputContainer'}>
                  <img
                    src={'/img/message-icon.svg'}
                    className={'RegistrationForm-InputIcon EnvelopeIcon'}
                    alt={'Envelope icon'}
                  />
                  <input
                    name={'Email'}
                    type={'email'}
                    className={'FormControl RegistrationForm-Input'}
                    placeholder={translate('E-mail')}
                    value={adminData.email}
                    onChange={(event) => updateAdminData('email',
                      event.target.value)}
                  />
                </div>
              </div>

              <div className={'RegistrationForm-Item'}>
                <label
                  className={'RegistrationForm-Label'}
                >
                  {translate('Пароль')}
                </label>

                <div className={'RegistrationForm-InputContainer'}>
                  {adminData?.password ?
                    <img
                      src={'/img/lock-blue.svg'}
                      className={'RegistrationForm-InputIcon LockIcon'}
                      alt={'Lock icon'}
                    />
                    :
                    <img
                      src={'/img/lock-icon.svg'}
                      className={'RegistrationForm-InputIcon LockIcon'}
                      alt={'Lock icon'}
                    />
                  }

                  <input
                    className={adminData?.password ? 'FormControl ' +
                      'RegistrationForm-Input PasswordInputWithText'
                      : 'FormControl RegistrationForm-Input'}
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder={translate('Пароль')}
                    name={'Password'}
                    value={adminData?.password || ''}
                    onChange={(event) => updateAdminData('password',
                      event.target.value)}
                  />

                  {adminData?.password ?
                    (isPasswordVisible ?
                        <img
                          src={'/img/eye-with-line-icon-blue.svg'}
                          className={'RegistrationForm-InputIconRight EyeIcon'}
                          alt={'Eye icon'}
                          onClick={() => setIsPasswordVisible(false)}
                        />
                        :
                        <img
                          src={'/img/eye-icon-blue.svg'}
                          className={'RegistrationForm-InputIconRight EyeIcon'}
                          alt={'Eye icon'}
                          onClick={() => setIsPasswordVisible(true)}
                        />
                    ) : (isPasswordVisible ?
                      <img
                        src={'/img/eye-with-line-icon.svg'}
                        className={'RegistrationForm-InputIconRight EyeIcon'}
                        alt={'Eye icon'}
                        onClick={() => setIsPasswordVisible(false)}
                      />
                      :
                      <img
                        src={'/img/eye-icon.svg'}
                        className={'RegistrationForm-InputIconRight EyeIcon'}
                        alt={'Eye icon'}
                        onClick={() => setIsPasswordVisible(true)}
                      />)
                  }
                </div>
              </div>

              <div className={'RegistrationForm-Item'}>
                <label
                  className={'RegistrationForm-Label'}
                >
                  {translate('Повторить пароль')}
                </label>
                <div className={'RegistrationForm-InputContainer'}>
                  {adminData?.confirmPassword ?
                    <img
                      src={'/img/lock-blue.svg'}
                      className={'RegistrationForm-InputIcon LockIcon'}
                      alt={'Lock icon'}
                    />
                    :
                    <img
                      src={'/img/lock-icon.svg'}
                      className={'RegistrationForm-InputIcon LockIcon'}
                      alt={'Lock icon'}
                    />
                  }

                  <input
                    className={adminData?.confirmPassword ? 'FormControl ' +
                      'RegistrationForm-Input PasswordInputWithText'
                      : 'FormControl RegistrationForm-Input'}
                    type={isRepeatPasswordVisible ? 'text' : 'password'}
                    placeholder={translate('Повторить пароль')}
                    name={'Password'}
                    value={adminData?.confirmPassword || ''}
                    onChange={(event) => updateAdminData(
                      'confirmPassword', event.target.value)}

                  />

                  {adminData?.confirmPassword ?
                    (isRepeatPasswordVisible ?
                        <img
                          src={'/img/eye-with-line-icon-blue.svg'}
                          className={'RegistrationForm-InputIconRight'}
                          alt={'Eye icon'}
                          onClick={() => setIsRepeatPasswordVisible(false)}
                        />
                        :
                        <img
                          src={'/img/eye-icon-blue.svg'}
                          className={'RegistrationForm-InputIconRight EyeIcon'}
                          alt={'Eye icon'}
                          onClick={() => setIsRepeatPasswordVisible(true)}
                        />
                    ) : (isRepeatPasswordVisible ?
                      <img
                        src={'/img/eye-with-line-icon.svg'}
                        className={'RegistrationForm-InputIconRight EyeIcon'}
                        alt={'Eye icon'}
                        onClick={() => setIsRepeatPasswordVisible(false)}
                      />
                      :
                      <img
                        src={'/img/eye-icon.svg'}
                        className={'RegistrationForm-InputIconRight EyeIcon'}
                        alt={'Eye icon'}
                        onClick={() => setIsRepeatPasswordVisible(true)}
                      />)
                  }
                </div>

                {isPasswordsCompareError &&
                  <div
                    className={
                      'AlertNotification RegistrationForm-PasswordsNoMatchAlert'
                    }
                  >
                    {translate('Пароли не совпадают')}
                  </div>
                }
              </div>

              <div className={'RegistrationForm-Item'}>
                <button
                  className={'RegularButton'}
                  type='submit'
                  name='submit'
                >
                  {adminData?.idPost ?
                    translate('Сохранить изменения')
                    :
                    translate('Добавить')
                  }
                </button>
              </div>
            </form>
          ) : (
            <>
              {isAddPhoneScreen &&
                <AddPhone
                  addClasses={'RegistrationForm-AddPhone'}
                  setIsAddPhoneScreen={setIsAddPhoneScreen}
                  setIsMainScreen={setIsMainScreen}
                  phoneInputChangeValueHandler={phoneInputChangeValueHandler}
                  deletePhoneHandler={deletePhoneHandler}
                  index={adminData?.phoneNumbers?.length - 1}
                />
              }
            </>
          )}
        </>
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </div>
  );
  //#endregion
};

export default AddAdmin;
