import React, {useState, useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {Route, Routes, useNavigate} from "react-router-dom";
import {rolesInterface, textPageModel} from "../utils/models";
import TextPagesList from "./TextPagesList";
import CreateTextPage from "./CreateTextPage";

const TextPages = ({addClasses}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }
    setScreenTitle('Текстовые страницы');
  }, []);
  //#endregion

  //#region Current page
  const [currentPage, setCurrentPage] = useState(textPageModel);
  //#endregion

  //#region Render
  return (
    <div className={`TextPages ${addClasses}`}>
      <Routes>
        <Route index element={
          <TextPagesList
            setCurrentPage={setCurrentPage}
          />
        }
        />

        <Route path={'addPage'} element={
          <CreateTextPage
            page={currentPage}
          />}
        />
      </Routes>
    </div>
  );
  //#endregion
};

export default TextPages;
