import React, {useEffect, useState} from 'react';
import {checkPasswordsAndSetErrorText} from "../utils/helpers";
import {Link, useLocation} from 'react-router-dom';
import {verifyPasswordResetCode, confirmPasswordReset} from "firebase/auth";
import {auth} from '../utils/firebaseConfigAndFunctions'
import {pagesInterface} from "../utils/models";
import FormInputAlertText from "../components/FormInputAlertText";
import Loader from "../components/Loader";
import useTranslation from "../hooks/useTranslation";
import '../styles/RecoverPassword.scss';

const ResetPasswordScreen = ({addClasses = ''}) => {
  //#region Url search params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Sign in data and it's update
  const [signInData, setSignInData] = useState({});

  const updateSignInData = (fieldName, newValue) => {
    setSignInData(state => ({
      ...state,
      [fieldName]: newValue,
    }))
  };
  //#endregion

  //#region Errors
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
  const [isPasswordsCompareError, setIsPasswordsCompareError] = useState(false);
  const [errorText, setErrorText] = useState('');
  //#endregion

  //#region Render
  const [resetData, setResetData] = useState({});
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Get search params and check is code valid
  const [isLinkCodeValid, setIsLinkCodeValid] = useState(true);
  const [isSuccessScreen, setIsSuccessScreen] = useState(false);

  const checkCode = async (actionCode) => {
    try {
      const email = await verifyPasswordResetCode(auth, actionCode);

      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setResetData(data => (({
      ...data,
      mode: searchParams.get('mode'),
      code: searchParams.get('oobCode'),
    })));

    checkCode(searchParams.get('oobCode')).then(result => {
      if (!result) {
        setIsLinkCodeValid(false);
      }
    }).catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Reset password
  const resetPassword = async (actionCode, newPassword) => {
    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
    } catch (error) {
      return error;
    }
  };
  //#endregion

  //#region Password reset submit
  const handlePasswordSubmitClick = async () => {
    setIsLoading(true);

    try {
      const checkResult = checkPasswordsAndSetErrorText(signInData.password,
        signInData.repeatPassword);

      if (checkResult) {
        setIsPasswordsCompareError(true);
        setErrorText(checkResult);

        return;
      }

      await resetPassword(resetData.code, signInData.password);

      setIsSuccessScreen(true);
    } catch (error) {
      setIsLinkCodeValid(false);

      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader/>
        :
        <div className={`RecoverPassword ${addClasses}`}>
          {isLinkCodeValid ? (
            <>
              {isSuccessScreen &&
                <>
                  <div className={'RecoverPassword-Item'}>
                    <div className={'RecoverPassword-ImageContainer'}>
                      <img
                        src={'/img/happy-face.svg'}
                        alt={'Happy face'}
                      />
                    </div>
                  </div>
                  <div className={'RecoverPassword-Item'}>
                    <h2 className={'RegularTitle'}>
                      {translate('Пароль изменен')}
                    </h2>
                  </div>

                  <div className={'RecoverPassword-Item'}>
                    <Link
                      className={'RegularButton RecoverPassword-TryAgainLink'}
                      to={`/${pagesInterface.login}`}
                    >
                      {'Войти'}
                    </Link>
                  </div>
                </>
              }

              {!isSuccessScreen &&
                <>
                  <div className={'RecoverPassword-Item'}>
                    <h2 className={'RegularTitle RegularTitle_Small'}>
                      {translate('Восстановление пароля')}
                    </h2>
                  </div>

                  <div className={'RecoverPassword-Item'}>
                    <label className={'RecoverPassword-Label'}>
                      {translate('Новый пароль')}
                    </label>

                    <div className={'RecoverPassword-InputContainer'}>
                      {signInData.password ?
                        <img
                          src={'/img/lock-blue.svg'}
                          className={'RecoverPassword-InputIcon LockIcon'}
                          alt={'Lock icon'}
                        />
                        :
                        <img
                          src={'/img/lock-icon.svg'}
                          className={'RecoverPassword-InputIcon LockIcon'}
                          alt={'Lock icon'}
                        />
                      }

                      <input
                        className={signInData?.password ? 'FormControl ' +
                          'RecoverPassword-Input PasswordInputWithText'
                          : 'FormControl RecoverPassword-Input'}
                        type={isPasswordVisible ? 'text' : 'password'}
                        placeholder={translate('Новый пароль')}
                        onChange={(event) => updateSignInData('password',
                          event.target.value)}
                        value={signInData.password}
                      />

                      {signInData.password ?
                        (isPasswordVisible ?
                          <img
                            src={'/img/eye-with-line-icon-blue.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsPasswordVisible(false)}
                          />
                          :
                          <img
                            src={'/img/eye-icon-blue.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsPasswordVisible(true)}
                          />
                        ) : (isPasswordVisible ?
                          <img
                            src={'/img/eye-with-line-icon.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsPasswordVisible(false)}
                          />
                          :
                          <img
                            src={'/img/eye-icon.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsPasswordVisible(true)}
                          />)
                      }
                    </div>
                  </div>

                  <div className={'RecoverPassword-Item'}>
                    <label className={'RecoverPassword-Label'}>
                      {translate('Подтвердите новый пароль')}
                    </label>

                    <div className={'RecoverPassword-InputContainer'}>
                      {signInData.repeatPassword ?
                        <img
                          src={'/img/lock-blue.svg'}
                          className={'RecoverPassword-InputIcon LockIcon'}
                          alt={'Lock icon'}
                        />
                        :
                        <img
                          src={'/img/lock-icon.svg'}
                          className={'RecoverPassword-InputIcon LockIcon'}
                          alt={'Lock icon'}
                        />
                      }

                      <input
                        className={signInData?.repeatPassword ? 'FormControl ' +
                          'RecoverPassword-Input PasswordInputWithText'
                          : 'FormControl RecoverPassword-Input'}
                        type={isRepeatPasswordVisible ? 'text' : 'password'}
                        placeholder={translate('Подтвердите новый пароль')}
                        onChange={(event) => updateSignInData(
                          'repeatPassword', event.target.value)}
                        value={signInData.repeatPassword}
                      />

                      {signInData?.repeatPassword ?
                        (isRepeatPasswordVisible ?
                          <img
                            src={'/img/eye-with-line-icon-blue.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsRepeatPasswordVisible(false)}
                          />
                          :
                          <img
                            src={'/img/eye-icon-blue.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsRepeatPasswordVisible(true)}
                          />
                        ) : (isRepeatPasswordVisible ?
                          <img
                            src={'/img/eye-with-line-icon.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsRepeatPasswordVisible(false)}
                          />
                          :
                          <img
                            src={'/img/eye-icon.svg'}
                            className={'RecoverPassword-InputIconRight EyeIcon'}
                            alt={'Eye icon'}
                            onClick={() => setIsRepeatPasswordVisible(true)}
                          />)
                      }
                    </div>

                    {isPasswordsCompareError &&
                      <FormInputAlertText
                        text={errorText}
                        setIsVisible={setIsPasswordsCompareError}
                      />
                    }
                  </div>

                  <div className={'RecoverPassword-Item'}>
                    <button
                      type={'button'}
                      className={'RegularButton'}
                      onClick={() => handlePasswordSubmitClick()}
                    >
                      {translate('Сохранить')}
                    </button>
                  </div>
                </>
              }
            </>
          ) : (
            <>
              <div className={'RecoverPassword-Item'}>
                <div className={'RecoverPassword-ImageContainer'}>
                  <img
                    src={'/img/sad-face.svg'}
                    alt={'Sad face'}
                  />
                </div>
              </div>

              <div className={'RecoverPassword-Item'}>
                <h2 className={'RegularTitle'}>
                  {'Ссылка не валидная'}
                </h2>
              </div>

              <div className={'RecoverPassword-Item'}>
                <Link
                  className={'RegularButton RecoverPassword-TryAgainLink'}
                  to={`/${pagesInterface.recoverPassword}`}
                >
                  {'Попробовать снова'}
                </Link>
              </div>
            </>
          )}
        </div>
      }
    </>
  );
  //#endregion
};

export default ResetPasswordScreen;
