import React, {useState, useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {pagesInterface, tripModel} from "../utils/models";
import useTranslation from "../hooks/useTranslation";
import PageMenuItem from "./PageMenuItem";
import '../styles/TripsPageMenu.scss';
import {indicatorsNewService} from "../utils/indicatorsNewService";

const TripsPageMenu = ({addClasses = ''}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get current trip from context
  const {setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Get screen title from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Handlers for menu items click
  const handleAddTripClick = () => {
    setCurrentTrip(tripModel);
    navigate(`/${pagesInterface.trips}/createTrip`);
  }

  const handleSearchPassengersClick = () => {
    navigate('searchPassenger');
    setScreenTitle('Поиск пассажиров в поездку');
  }

  const handleMyTripsClick = () => {
    setScreenTitle('Мои поездки');
    navigate('myTrips');
  }

  const handleReservedTripsClick = () => {
    setScreenTitle('Забронированные поездки');
    navigate('reservedTrips');
  }

  const handleActiveTripsClick = () => {
    setScreenTitle('Активные поездки');
    navigate('activeTrips');
  }

  const handleArchivedTripsClick = () => {
    navigate('archivedTrips');
  }
  //#endregion

  //#region Data for indicators new, and it's handling
  const [isIndicatorNewForReservedTrips, setIsIndicatorNewForReservedTrips]
    = useState(false);

  const [isIndicatorNewForActiveTrips, setIsIndicatorNewForActiveTrips]
    = useState(false);

  const [isIndicatorNewForArchivedTrips, setIsIndicatorNewForArchivedTrips]
    = useState(false);


  const handleIndicatorNewOnReservedTripsSection = async () => {
    const checkResult = await indicatorsNewService
      .showIndicatorNewForReservedTrips(user.idPost, false);

    setIsIndicatorNewForReservedTrips(checkResult);
  }

  const handleIndicatorNewOnActiveTripsSection = async () => {
    const checkResult = await indicatorsNewService
      .showIndicatorNewForActiveTrips(user.idPost, user.attachedToCompany);

    setIsIndicatorNewForActiveTrips(checkResult);
  }

  const handleIndicatorNewOnArchivedTripsSection = async () => {
    const checkResult = await indicatorsNewService
      .showIndicatorNewForForTripHistoryInTripsSection(!user.attachedToCompany,
        user.idPost);

    setIsIndicatorNewForArchivedTrips(checkResult);
  }
  //#endregion

  //#region Set screen title and indicators data
  useEffect(() => {
    setScreenTitle('Поездки');

    handleIndicatorNewOnReservedTripsSection().then();

    handleIndicatorNewOnActiveTripsSection().then();

    handleIndicatorNewOnArchivedTripsSection().then();
  }, []);
  //#endregion

  //#region Render
  return (
    <>
      <div className={`TripsPageMenu ${addClasses}`}>
        <nav className={'TripsPageMenu-Nav SectionNavMenu'}>
          {user?.idPost && !user.attachedToCompany &&
            <>
              <PageMenuItem
                onClick={handleAddTripClick}
                iconPath={'/img/trips-menu/car-menu-icon.svg'}
                iconAlt={'Car icon'}
                text={'Добавить поездку'}
              />

              <PageMenuItem
                onClick={handleSearchPassengersClick}
                iconPath={'/img/trips-menu/search-icon.svg'}
                iconAlt={'Search icon'}
                text={'Поиск пассажиров в поездку'}
              />
            </>
          }

          <PageMenuItem
            onClick={handleMyTripsClick}
            iconPath={'/img/trips-menu/car-menu-icon.svg'}
            iconAlt={'Car icon'}
            text={'Мои поездки'}
          />

          {user.idPost && !user.attachedToCompany &&
            <PageMenuItem
              onClick={handleReservedTripsClick}
              iconPath={'/img/passengers-menu/passengers-menu-reservation-icon.svg'}
              iconAlt={'Approve icon'}
              text={'Забронированные поездки'}
              isIndicatorNew={isIndicatorNewForReservedTrips}
            />
          }

          <PageMenuItem
            onClick={handleActiveTripsClick}
            iconPath={'/img/passengers-menu/passengers-menu-active-trips-icon.svg'}
            iconAlt={'Car icon'}
            text={'Активные поездки'}
            isIndicatorNew={isIndicatorNewForActiveTrips}
          />

          <PageMenuItem
            onClick={handleArchivedTripsClick}
            iconPath={'/img/passengers-menu/passengers-menu-archived-trips-icon.svg'}
            iconAlt={'Car icon'}
            text={'История выполненных поездок'}
            isIndicatorNew={isIndicatorNewForArchivedTrips}
          />
        </nav>
      </div>
    </>
  );
  //#endregion
};

export default TripsPageMenu;
