import React, {useState} from 'react';
import DisplayCreatedTrips from "./DisplayCreatedTrips";
import useTranslation from "../hooks/useTranslation";
import {Nav, Tab} from "react-bootstrap";

const ShowSuitableTrips = ({
  trips = [],
  addTrips = [],
  addClasses = '',
  handleShowTripDetailsScreen = () => {},
  isDispatcher = false,
  isFromExchange = false,
  isLoading = true,
  onlyMyTrips = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Toggle trips orders
  const [activeTabKey, setActiveTabKey] = useState('otherTrips');
  //#endregion

  //#region Render
  return (
    <div className={`ShowSuitableTrips ${addClasses}`}>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      {onlyMyTrips ?
        <DisplayCreatedTrips
          trips={addTrips}
          isNotepad={false}
          isReadMode={true}
          isDispatcher={isDispatcher}
          // isExtendedDisplaying={addTrips.length === 0}
          handleShowTripDetailsScreen={handleShowTripDetailsScreen}
          isFromExchange={isFromExchange}
          isLoading={isLoading}
        />
        :
        <Tab.Container
          activeKey={activeTabKey}
        >
          <Nav variant="pills" className={'flex-row TabControls'}>
            <Nav.Item>
              <Nav.Link
                eventKey='otherTrips'
                onClick={() => setActiveTabKey('otherTrips')}
              >
                {translate('Поездки водителей')}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                eventKey='myTrips'
                onClick={() => setActiveTabKey('myTrips')}
              >
                {translate('Мои поездки')}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey='otherTrips'>
              <DisplayCreatedTrips
                trips={trips}
                isNotepad={false}
                isReadMode={true}
                isDispatcher={isDispatcher}
                // isExtendedDisplaying={addTrips.length === 0}
                handleShowTripDetailsScreen={handleShowTripDetailsScreen}
                isFromExchange={isFromExchange}
                isLoading={isLoading}
              />
            </Tab.Pane>

            <Tab.Pane eventKey='myTrips'>
              <DisplayCreatedTrips
                trips={addTrips}
                isNotepad={false}
                isReadMode={true}
                isDispatcher={isDispatcher}
                // isExtendedDisplaying={addTrips.length === 0}
                handleShowTripDetailsScreen={handleShowTripDetailsScreen}
                isFromExchange={isFromExchange}
                isLoading={isLoading}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      }
    </div>
  );
  //#endregion
};

export default ShowSuitableTrips;
