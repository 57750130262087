import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {Route, Routes, useNavigate} from "react-router-dom";
import {
  collectionsInterface,
  pagesInterface,
  reservationModel, subpagesInterface,
  tripModel,
  tripOrderModel, tripStatusesInterface
} from "../utils/models";
import {
  findAllSuitableTripsOrdersAndSendMessagesToDispatchers,
  searchForTrips,
  sendMessageAboutAddingToTripAsDriver,
  sendMessageAboutDeletingFromTripAsDriver,
} from "../utils/helpers";
import TripsPageMenu from "../components/TripsPageMenu";
import TripsWithReserves from "../components/TripsWithReserves";
import ActiveTripsForDrivers from "../components/ActiveTripsForDrivers";
import TripsHistoryForDrivers from "../components/TripsHistoryForDrivers";
import {
  getDocInCollection,
  setDocumentToCollection,
  updateFieldsInDocumentInCollection
} from "../utils/firebaseConfigAndFunctions";
import DisplayTripDetails from "../components/DisplayTripDetails";
import AddTrip from "../components/AddTrip";
import SearchPassengers from "../components/SearchPassengers";
import ModalNotification from "../modals/ModalNotification";
import {
  handleOriginalTripInReservationsAfterEditing,
  rejectAllUnsuitableReservationsInTripAfterChange,
} from "../utils/reservationsService";
import {checkTripService} from "../utils/checkTripService";
import MyTrips from "../components/MyTrips";
import '../styles/Trips.scss';

const Trips = ({addClasses = ''}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Поездки');
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({
    message: '',
    title: '',
  });

  const updateNotificationData = (isVisible, data) => {
    setIsNotification(isVisible);

    if (isVisible) {
      setNotificationData(data);
    }
  }
  //#endregion

  //#region Get current trip, current trip order and current reservation from context
  const {
    currentTripOrder, setCurrentTripOrder, currentTrip, setCurrentTrip, setCurrentReservation
  } = useContext(AppContext);
  //#endregion

  //#region Clear data for current tripOrder, trip, reservation
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
    setCurrentReservation(reservationModel);
  }, []);
  //#endregion

  //#region Handling show create trip
  const handleShowCreateTrip = () => {
    setCurrentTrip(tripModel);
    navigate(`/${pagesInterface.trips}/createTrip`);
  };
  //#endregion

  //#region Handling show create trip
  const handleShowEditTrip = async (trip) => {
    if (trip?.idPost) {
      const actualTrip = await getDocInCollection(collectionsInterface
        .trips, trip.idPost);

      if (actualTrip?.isActive) {
        updateNotificationData(true, {
          message: 'Нельзя редактировать активную поездку',
          title: 'Уведомление',
        })

        setCurrentTrip(actualTrip);
        return;
      }
    }

    setCurrentTrip(trip);
    navigate('createTrip');
  };
  //#endregion

  //#region Handling passenger in trip click to show details
  const handlePassengerInTripClick = async (reservation, tripOrder, trip) => {
    try {
      const actualTripOrder = await getDocInCollection(collectionsInterface
        .tripsOrders, tripOrder.idPost);
      const actualTrip = await getDocInCollection(collectionsInterface.trips,
        trip.idPost);

      setCurrentTrip(actualTrip);
      setCurrentTripOrder(actualTripOrder);
      setCurrentReservation(reservation);
      navigate('/trips/activeTrips/reservationDetails');
    } catch (error) {
      return error;
    }
  };
  //#endregion

  //#region Handle go back from create trip
  const handleGoBackFromCreateTrip = () => {
    setCurrentTrip(tripModel);

    navigate(`/${pagesInterface.trips}/${subpagesInterface.myTrips}`, {replace: true});
  }
  //#endregion

  //#region Handle show reservations from trip details
  const handleShowReservations = (trip) => {
    setCurrentTrip(trip);

    navigate(`/${pagesInterface.trips}/${subpagesInterface.reservedTrips}/${
      subpagesInterface.reservedTripDetails}`)
  }
  //#endregion

  //#region Handle reservation card click in different blocks
  const handleReservationCardClick = (reservation, tripOrder, trip) => {
    setCurrentReservation(reservation);
    setCurrentTrip(trip);
    setCurrentTripOrder(tripOrder);

    navigate(`/${pagesInterface.trips}/${subpagesInterface.reservedTrips}/${
      subpagesInterface.reservationDetails}`);
  }

  const handleReservationCardClickInHistoryBlock = (reservation, tripOrder,
    trip) => {
    setCurrentReservation(reservation);
    setCurrentTripOrder(tripOrder);
    setCurrentTrip(trip);
    navigate(`/${pagesInterface.trips}/${subpagesInterface.archivedTrips}/${
      subpagesInterface.archivedTripOrderDetails}`);
  }
  //#endregion

  //#region Passenger transfer to another company handling
  const [suitableTripsForTransfer, setSuitableTripsForTransfer] = useState([]);

  const setSuitableTripsAndNavigate = (trips) => {
    const tripsWithoutOriginalDispatcherTrips = trips
      .filter(trip => trip.creatorIdPost !== currentTripOrder?.creatorIdPost);

    setSuitableTripsForTransfer(tripsWithoutOriginalDispatcherTrips);

    navigate(`/${pagesInterface.trips}/${subpagesInterface.activeTrips}/${
      subpagesInterface.suitableTripsForTransfer}`);
  }

  const handleTransferPassengerClick = async (tripOrder) => {
    try {
      await searchForTrips(tripOrder, true,
        setSuitableTripsAndNavigate, user);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Submit add trip
  const submitAddTrip = async (newTripData, isEdit, driversChanges) => {
    try {
      if (isEdit) {
        let updatedData = {...newTripData};

        await updateFieldsInDocumentInCollection(collectionsInterface.trips,
          newTripData.idPost, updatedData);

        let actualTrip = await getDocInCollection(collectionsInterface.trips,
          currentTrip.idPost);

        let updatedTrip = actualTrip;

        const needPetOptionCheck = checkTripService
          .checkAreReservationsNeedPetOptionCheck(currentTrip
            .isTripWithPets, updatedTrip.isTripWithPets);

        const needByFootOptionCheck = checkTripService
          .checkAreReservationsNeedByFootOptionCheck(currentTrip.isByFoot,
            updatedTrip.isByFoot);

        if (newTripData.hasOwnProperty('tripPoints')
          || needPetOptionCheck || needByFootOptionCheck) {
          const actionResult = await rejectAllUnsuitableReservationsInTripAfterChange(
            actualTrip.idPost, newTripData.tripPoints, actualTrip
              .reservationsFromDispatchers, actualTrip.reservationsFromDrivers,
            user.companyName || user.fullName, needPetOptionCheck,
            needByFootOptionCheck);

          if (actionResult?.wasUpdated) {
            updatedTrip = {
              ...actualTrip,
              ...actionResult?.fieldsToUpdate,
            }
          }
        }

        await handleOriginalTripInReservationsAfterEditing(updatedTrip);

        updatedTrip = await getDocInCollection(collectionsInterface.trips,
          actualTrip.idPost);

        if (driversChanges  && driversChanges.wereDriversChanged) {
          const changedTrip = {
            ...currentTrip,
            ...newTripData,
          }

          const tripDataForMessage = {
            startPoint: changedTrip.startPoint,
            endPoint: changedTrip.endPoint,
            startDate: changedTrip.startDate,
            driversNames: changedTrip.drivers.map(driver => driver.fullName),
          }

          driversChanges?.added?.forEach(driver => {
            if (driver.idPost !== user.idPost) {
              sendMessageAboutAddingToTripAsDriver(user?.companyName
                || user.fullName,  tripDataForMessage, {
                idPost: driver.idPost,
                email: driver.email,
              });
            }
          });

          driversChanges?.dismissed?.forEach(driver => {
            if (driver.idPost !== user.idPost) {
              sendMessageAboutDeletingFromTripAsDriver(user?.companyName
                || user.fullName,  tripDataForMessage,{
                idPost: driver.idPost,
                email: driver.email,
              });
            }
          });
        }
      } else {
        const saveResult = await setDocumentToCollection(
          collectionsInterface.trips, newTripData);

        if (newTripData.isVisibleForDispatchers) {
          await findAllSuitableTripsOrdersAndSendMessagesToDispatchers(user,
            newTripData);
        }

        for (let driver of newTripData.drivers) {
          if (driver.idPost !== user.idPost) {
            await sendMessageAboutAddingToTripAsDriver(user
              ?.companyName || user.fullName, {
              startPoint: newTripData.startPoint,
              endPoint: newTripData.endPoint,
              startDate: newTripData.startDate,
              driversNames: newTripData.drivers.map(driver => driver.fullName),
            }, {
              idPost: driver.idPost,
              email: driver.email,
            });
          }
        }
      }

      handleGoBackFromCreateTrip();
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      <main className={`Trips ${addClasses}`}>
        <div className={'Trips-Content'}>
          <Routes>
            <Route
              index
              element={<TripsPageMenu />}
            />

            <Route
              path={`/${subpagesInterface.searchPassenger}/*`}
              element={
                <SearchPassengers
                  addClasses={'App-Screen'}
                />
              }
            />

            <Route
              path={`/${subpagesInterface.myTrips}/*`}
              element={
                <MyTrips handleShowCreateTrip={handleShowCreateTrip} />
              }
            />

            {!user.attachedToCompany &&
              <Route
                path={`/${subpagesInterface.reservedTrips}/*`}
                element={
                  <TripsWithReserves
                    addClasses={'App-Screen'}
                    handleShowTripDetails={handleShowReservations}
                    handleReservationCardClick={handleReservationCardClick}
                  />
                }
              />
            }

            <Route
              path={`/${subpagesInterface.activeTrips}/*`}
              element={
                <ActiveTripsForDrivers
                  isNotepad={false}
                  handleShowCreateTrip={handleShowCreateTrip}
                  handlePassengerInTripClick={handlePassengerInTripClick}
                  handleTransferPassengerClick={handleTransferPassengerClick}
                  suitableTripsForTransfer={suitableTripsForTransfer}
                  addClasses={'App-Screen'}
                />
              }
            />

            <Route
              path={`/${subpagesInterface.archivedTrips}/*`}
              element={
                <TripsHistoryForDrivers
                  addClasses={'App-Screen'}
                  handleTripOrderCardClick={
                    handleReservationCardClickInHistoryBlock
                  }
                />
              }
            />

            {!user.attachedToCompany &&
              <Route
                path={`/${subpagesInterface.createTrip}/*`}
                element={
                  <AddTrip
                    addClasses={'App-Screen App-Screen_Narrow'}
                    completeExternalSubmitAddTripActions={submitAddTrip}
                    isCreator={true}
                    isNotePad={false}
                    user={user}
                  />
                }
              />
            }

            {/*{!user.attachedToCompany &&*/}
              <Route
                path={`/${subpagesInterface.displayTripDetails}/*`}
                element={
                  <DisplayTripDetails
                    isNotePad={false}
                    isReadOnly={false}
                    handleShowAddTripScreen={handleShowEditTrip}
                    handleShowReservations={handleShowReservations}
                    addClasses={'App-Screen App-Screen_Narrow'}
                  />
                }
              />
            {/*}*/}
          </Routes>
        </div>
      </main>

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          message={notificationData.message}
          title={notificationData.title}
        />
      }
    </>
  );
  //#endregion
};

export default Trips;
