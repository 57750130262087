import React, {useContext, useEffect, useState} from 'react';
import {searchTripsOrders} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import DriverDataScreen from "../components/DriverDataScreen";
import DispatcherDataScreen from "../components/DispatcherDataScreen";
import {Route, Routes, useNavigate} from "react-router-dom";
import ExchangeTabs from "../components/ExchangeTabs";
import DisplayCreatedTripsOrders from "../components/DisplayCreatedTripsOrders";
import {
  getCollectionWhereKeysValues, getDocInCollection
} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  reservationModel,
  tripModel,
  tripOrderModel
} from "../utils/models";
import DisplayTripOrderDetails from "../components/DisplayTripOrderDetails";
import DisplayTripDetails from "../components/DisplayTripDetails";
import SuitableTripsScreen from "../components/SuitabeTripsScreen";
import TripReservation from "../components/TripReservation";
import ConfirmReservation from "../components/ConfirmReservation";
import '../styles/Exchange.scss';

const Exchange = ({addClasses = ''}) => {
  //#region get userData from context
  const {user, setUser} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {
    setCurrentTrip,
    currentTripOrder,
    setCurrentTripOrder,
    setCurrentReservation,
  } = useContext(AppContext);
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Update user data
  const updateUser = async () => {
    try {
      const actualUser = await getDocInCollection(collectionsInterface.users,
        user.idPost);

      setUser(actualUser);
    } catch (error) {}
  }
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Обмен');
  }, []);
  //#endregion

  //#region Clear data for current tripOrder, trip, reservation update user
  useEffect(() => {
    setCurrentTripOrder(tripOrderModel);
    setCurrentTrip(tripModel);
    setCurrentReservation(reservationModel);

    updateUser().then();
  }, []);
  //#endregion

  //#region Loaders
  const [loaders, setLoaders] = useState({
    ordersLoader: true,
    tripsLoader: true,
  });

  const updateLoader = (loaderName, newValue) => {
    setLoaders(data => ({
      ...data,
      [loaderName]: newValue,
    }));
  }
  //#endregion

  //#region Current role
  const [isCreditor, setIsCreditor] = useState(false);
  //#endregion

  //#region Trip orders of chosen counterparty
  const [tripsOrders, setTripsOrders] = useState([]);
  //#endregion

  //#region Trips of chosen counterparty
  const [trips, setTrips] = useState([]);
  //#endregion

  //#region Trips of chosen counterparty
  const [currentCounterParty, setCurrentCounterparty] = useState([]);
  //#endregion

  //#region Handle debtor counterparty card click
  const handleDebtorSearchClick = async (debtor) => {
    try {
      const debtorTripsOrders = await getCollectionWhereKeysValues(
        collectionsInterface.tripsOrders, [
        {
          key: 'creatorIdPost',
          value: debtor.idPost,
        },
        {
          key: 'isOpenForSearch',
          value: true,
        },
        {
          key: 'isVisibleForDrivers',
          value: true,
        },
        {
          key: 'isArchived',
          value: false,
        },
      ], 4);

      setTripsOrders(debtorTripsOrders);
    } catch (error) {
      return error;
    } finally {
      updateLoader('ordersLoader', false);
    }
  }
  //#endregion

  //#region Handle creditor counterparty card click
  const handleCreditorSearchClick = async (creditor) => {
    try {
      const creditorTrips = await getCollectionWhereKeysValues(
        collectionsInterface.trips, [
        {
          key: 'creatorIdPost',
          value: creditor.idPost,
        },
        {
          key: 'isOpenForSearch',
          value: true,
        },
        {
          key: 'isVisibleForDispatchers',
          value: true,
        },
        {
          key: 'isArchived',
          value: false,
        }
      ], 4);

      setTrips(creditorTrips);
    } catch (error) {
      return error;
    } finally {
      updateLoader('tripsLoader', false);
    }
  }
  //#endregion

  //#region Handle counterparty card click
  const handleCounterpartyCardClick = async (counterparty, isCreditor) => {
    setCurrentCounterparty(counterparty);

    if (isCreditor) {
      setIsCreditor(true);

      await handleCreditorSearchClick(counterparty);
    } else {
      setIsCreditor(false);

      await handleDebtorSearchClick(counterparty);
    }

    navigate('/exchange/counterpartyData');
  }
  //#endregion

  //#region Work with trip order
  const handleTripOrderCardClick = (tripOrder) => {
    setCurrentTripOrder(tripOrder);

    navigate('/exchange/tripOrderDetails');
  }

  const handleGoBackFromTripOrderDetails = () => {
    navigate(-1);
  }
  //#endregion

  //#region Handle show suitable trips
  const handleShowSuitableTrips = (trips) => {
    setTrips(trips);

    navigate('/exchange/suitableTrips');
  }
  //#endregion

  //#region Handle trip card click
  const handleTripCardClick = (trip) => {
    setCurrentTrip(trip);

    navigate('/exchange/tripDetails');
  }
  //#endregion

  //#region Handle search my passengers for trip and make reservation
  const handleSearchMyPassengersForTrip = async (trip, user) => {
    updateLoader('ordersLoader', true);

    try {
      const myTripsOrders = await searchTripsOrders(trip.startPoint,
        trip.endPoint, trip.startPoint.departureTime, trip.isPassengerTrip,
        true, true, user.idPost);

      setTripsOrders(myTripsOrders);

      navigate('/exchange/myTripsOrders');
    } catch (error) {
      return error;
    } finally {
      updateLoader('ordersLoader', false)
    }
  }

  const handleGoToReservationFromTripOrder = () => {
    navigate('/exchange/tripReservation');
  }

  const handleShowReservationConfirmationScreen = () => {
    navigate('/exchange/confirmReservation');
  }
  //#endregion

  //#region Redirect if invited driver
  useEffect(() => {
    if (user.idPost && user.attachedToCompany) {
      navigate('/profile');
    }
  }, []);
  //#endregion

  //#region Render
  return (
    <React.Fragment>
      <div className={`Exchange ${addClasses}`}>
        <Routes>
          <Route
            index
            element={
              <ExchangeTabs
                handleCounterpartyCardClick={handleCounterpartyCardClick}
              />
            }
          />

          <Route
            path={'counterpartyData'}
            element={isCreditor ?
              <DriverDataScreen
                counterparty={currentCounterParty}
                trips={trips}
                handleTripCardClick={handleTripCardClick}
                isLoading={loaders.tripsLoader}
              />
              :
              <DispatcherDataScreen
                counterparty={currentCounterParty}
                tripsOrders={tripsOrders}
                handleTripOrderCardClick={handleTripOrderCardClick}
                isLoading={loaders.ordersLoader}
              />
            }
          />

          <Route
            path={'tripOrderDetails/*'}
            element={
              <DisplayTripOrderDetails
                isDispatcher={
                  currentTripOrder.creatorIdPost === user.idPost
                }
                isReadyToReservation={
                  currentTripOrder.creatorIdPost === user.idPost
                }
                handleShowSuitableTrips={handleShowSuitableTrips}
                handleGoBack={handleGoBackFromTripOrderDetails}
                handleGoToReservationClick={handleGoToReservationFromTripOrder}
                addClasses={'App-Screen_Narrow'}
                isFromExchange={true}
                searchOnlyOwnTrips={true}
              />
            }
          />

          <Route
            path={'suitableTrips/*'}
            element={
              <SuitableTripsScreen
                addTrips={trips}
                isDispatcher={false}
                isLoading={false}
                isFromExchange={true}
                onlyMyTrips={true}
              />
            }
          />

          <Route
            path={'tripDetails/*'}
            element={
              <DisplayTripDetails
                isDispatcher={true}
                isFromExchange={true}
                handleSearchMyPassengersForTrip={handleSearchMyPassengersForTrip}
                addClasses={'App-Screen_Narrow'}
              />
            }
          />

          <Route
            path={'myTripsOrders/*'}
            element={
              <DisplayCreatedTripsOrders
                tripsOrdersToDisplay={tripsOrders}
                isReadOnly={true}
                handleTripOrderCardClick={handleTripOrderCardClick}
                isNotepad={false}
                isLoading={loaders.ordersLoader}
              />
            }
          />

          <Route
            path={'tripReservation/*'}
            element={
              <TripReservation
                isDispatcher={true}
                isFromExchange={true}
                handleShowReservationConfirmationScreen={
                  handleShowReservationConfirmationScreen
                }
                addClasses={'App-Screen_Narrow'}
              />
            }
          />

          <Route
            path={'confirmReservation/*'}
            element={
              <ConfirmReservation
                isDispatcher={true}
                isPassengerTransfer={false}
                isFromExchange={true}
                addClasses={'App-Screen_Narrow'}
              />
            }
          />
        </Routes>

      </div>
    </React.Fragment>
  );
  //#endregion
};

export default Exchange;
