import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import useTranslation from "../hooks/useTranslation";
import {getCoordinatesStringFromWaypoint} from "../utils/placesService";
import '../styles/RouteOnMap.scss';

const RouteOnMap = ({addClasses = '', route}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get screen title managing function from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title and current trip order on component mount
  useEffect(() => {
    setScreenTitle('Маршрут на карте');
  }, []);
  //#endregion

  //#region Init and show map
  const [errorMessage, setErrorMessage] = useState('');

  function initMap() {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: {lat: 48.43324, lng: 22.18426},
      zoom: 8
    });

    const directionsService = new window.google.maps.DirectionsService();
    const directionsDisplay = new window.google.maps.DirectionsRenderer(
      {map: map});

    const waypoints = route.map(function (place) {
      return {
        location: {lat: place.lat, lng: place.lon},
        stopover: true
      };
    });

    const origin = getCoordinatesStringFromWaypoint(waypoints[0]);
    const destination =getCoordinatesStringFromWaypoint(
      waypoints[waypoints.length - 1]);

    const intermediatePoints = waypoints.slice(1, waypoints.length - 1)
      .map(point => ({
        ...point,
        location: getCoordinatesStringFromWaypoint(point),
      }));

    const request = {
      origin,
      destination,
      waypoints: intermediatePoints,
      travelMode: window.google.maps.TravelMode.DRIVING
    };

    directionsService.route(request, function (response, status) {
      if (status === window.google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
      } else {
        setErrorMessage(translate('Не удалось построить маршрут'));
      }
    });
  };

  useEffect(() => {
    initMap();
  }, [])
  //#endregion

  //#region Render
  return (
    <div className={'RouteOnMapContainer'}>
      {errorMessage}

      <div
        id={'map'}
        className={`RouteOnMap ${addClasses}`}
      >

      </div>
    </div>
  );
  //#endregion
};

export default RouteOnMap;
