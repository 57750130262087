import React from 'react';
import {Link} from "react-router-dom";
import {pagesInterface} from "../utils/models";
import useTranslation from "../hooks/useTranslation";

const AgreementWithPolicyControls = ({
  addClasses = '',
  isChecked = true,
  handleChange = () => {},
  isDisabled = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div
      className={`RulesLinkContainer ${addClasses}`}
    >
      <div>
        <input
          type={'checkbox'}
          onChange={(event) => handleChange(
            event.target.checked, 'rulesAccepted')}
          checked={isChecked}
          className={'RulesLinkContainer-CheckBox'}
          disabled={isDisabled}
        />
      </div>

      <div className={'RulesLinkContainer-Text'}>
        {translate('Соглашаюсь с')}{' '}

        {<Link
          to={`/${pagesInterface.rulesAndPolicy}`}
          className={'RulesLinkContainer-Link'}
          target={'_blank'}
        >
          {translate('Политикой конфиденциальности')}{', '}
        </Link>}

        {<Link
          to={`/${pagesInterface.cookiesPolicy}`}
          className={'RulesLinkContainer-Link'}
          target={'_blank'}
        >
          {translate('Политикой куки')}{', '}
        </Link>}

        {<Link
          to={`/${pagesInterface.termsOfUse}`}
          className={'RulesLinkContainer-Link'}
          target={'_blank'}
        >
          {translate('Пользовательским соглашением')}{' '}
        </Link>}

        {translate('и')}{' '}

        {<Link
          to={`/${pagesInterface.removalPolicy}`}
          className={'RulesLinkContainer-Link'}
          target={'_blank'}
        >
          {translate('Политикой удаления')}
        </Link>}
      </div>
    </div>
  );
  //#endregion
};

export default AgreementWithPolicyControls;
