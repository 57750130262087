import React from 'react';
import DisplayMessengersRow from "./DisplayMessengersRow";

const UserPhonesInfo = ({userPhones = []}) => {
  //#region Render
  return (
    <>
      {userPhones.map((phone, pIndex) => (
        <div className={'LeftPadding'} key={pIndex}>
          <DisplayMessengersRow
            phone={phone}
            isPhoneNumberRefactored={true}
            isLinkToCall={true}
          />
        </div>
      ))}
    </>
  );
  //#endregion
};

export default UserPhonesInfo;
