import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  adminDataTypesInterface,
  appSettingsModel,
  collectionsInterface,
  rolesInterface,
} from "../utils/models";
import {
  getCollection,
  setDocumentToCollection,
  updateDocumentInCollection,
} from "../utils/firebaseConfigAndFunctions";
import Loader from "./Loader";
import '../styles/AppSettings.scss';
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";

const AppSettings = ({addClasses = ''}) => {
  //#region Get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    if (user?.role !== rolesInterface.admin) {
      navigate('/');
    }

    setScreenTitle('Настройки проекта');
  }, []);
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Settings data
  const [settingsData, setSettingsData] = useState(appSettingsModel);

  const setInitialData = async () => {
    try {
      const data = await getCollection(collectionsInterface.adminData);
      const settings = data
        .filter(item => item.dataType !== adminDataTypesInterface.textPages);

      const settingsForState = {};

      settings.forEach(setting => settingsForState[setting.dataType] = setting);

      setSettingsData({
        ...appSettingsModel,
        ...settingsForState,
      });
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    setInitialData().catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  const updateSettings = (fieldName, newValue) => {
    setSettingsData(data => ({
      ...data,
      [fieldName]: {
        ...data[fieldName],
        text: newValue,
      }
    }));
  }

  const saveSettings = async (event, settings) => {
    setIsLoading(true);

    event.preventDefault();

    try {
      for (const setting of settings) {
        if (setting.idPost) {
          await updateDocumentInCollection(collectionsInterface.adminData,
            setting, setting.idPost);
        } else {
          await setDocumentToCollection(collectionsInterface.adminData, setting);
        }
      }

      await setInitialData();
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`AppSettings ${addClasses}`}>
          <div className={'PaddingBlock'}></div>

          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <form
            onSubmit={(event) => saveSettings(event,
              Object.values(settingsData))}
          >
            <div className={'FlexBlock FlexBlock_Columns'}>
              <label
                htmlFor={'email'}
                className={'DataLabel AppSettings-Item'}
              >
                {translate('Почта службы поддержки')}
              </label>

              <input
                type={'email'}
                className={'FormControl AppSettings-Item'}
                id={'email'}
                value={settingsData?.email?.text || ''}
                onChange={(event) => updateSettings(
                  adminDataTypesInterface.email, event.target.value)}
                required
              />
            </div>

            <div className={'PaddingBlock'}></div>

            <div className={'FlexBlock FlexBlock_Columns'}>
              <label htmlFor={'appLink'}
                className={'DataLabel AppSettings-Item'}
              >
                {translate('Ссылка на App Store')}
              </label>

              <input
                type={'text'}
                className={'FormControl AppSettings-Item'}
                id={'appLink'}
                value={settingsData?.appStoreLink?.text || ''}
                onChange={(event) => updateSettings(
                  adminDataTypesInterface.appStoreLink, event.target.value)}
                required
              />
            </div>

            <div className={'PaddingBlock'}></div>

            <div className={'FlexBlock FlexBlock_Columns'}>
              <label htmlFor={'googleLink'}
                className={'DataLabel AppSettings-Item'}
              >
                {translate('Ссылка на Google Play')}
              </label>

              <input
                type={'text'}
                className={'FormControl AppSettings-Item'}
                id={'googleLink'}
                value={settingsData?.googlePlayLink?.text || ''}
                onChange={(event) => updateSettings(
                  adminDataTypesInterface.googlePlayLink, event.target.value)}
                required
              />
            </div>

            <div className={'PaddingBlock'}></div>

            <div className={'FlexBlock FlexBlock_Columns'}>
              <label htmlFor={'dispatcherBonus'}
                className={'DataLabel AppSettings-Item'}
              >
                {translate('Процент бонуса для пассажира')}
              </label>

              <NumberInputWithValidation
                addClasses={'AppSettings-Item'}
                externalChangeHandler={updateSettings}
                fieldName={adminDataTypesInterface.dispatcherBonusPercentage}
                value={settingsData?.dispatcherBonusPercentage?.text || ''}
              />
            </div>

            {/*<div className={'PaddingBlock'}></div>*/}

            {/*<div className={'FlexBlock FlexBlock_Columns'}>*/}
            {/*  <label htmlFor={'driverBonus'}*/}
            {/*    className={'DataLabel AppSettings-Item'}*/}
            {/*  >*/}
            {/*    {translate('Процент бонуса для перевозчика')}*/}
            {/*  </label>*/}

            {/*  <NumberInputWithValidation*/}
            {/*    addClasses={'AppSettings-Item'}*/}
            {/*    externalChangeHandler={updateSettings}*/}
            {/*    fieldName={adminDataTypesInterface.driverBonusPercentage}*/}
            {/*    id={'driverBonus'}*/}
            {/*    value={settingsData?.driverBonusPercentage?.text || ''}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className={'PaddingBlock'}></div>

            <div className={'FlexBlock FlexBlock_Columns'}>
              <label
                htmlFor={'driverBonus'}
                className={'DataLabel AppSettings-Item'}
              >
                {translate('Комиссия приложения')}
              </label>

              <NumberInputWithValidation
                addClasses={'AppSettings-Item'}
                externalChangeHandler={updateSettings}
                fieldName={adminDataTypesInterface.applicationFee}
                id={'applicationFee'}
                value={settingsData?.applicationFee?.text || ''}
              />
            </div>

            <div className={'PaddingBlock'}></div>

            <button
              type={'submit'}
              className={'RegularButton'}
            >
              {translate('Сохранить')}
            </button>

            <div className={'PaddingBlock'}></div>
          </form>
        </div>
      }
    </>
  );
  //#endregion
};

export default AppSettings;
