import React, {useContext} from 'react';
import {AppContext} from "../context/appContext";
import {tripPointModel} from "../utils/models";
import {convertTimestampToCalendarDate} from "../utils/helpers";
import LocationIq from "./LocationIq";
import { getTranslatedLocalityName } from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";
import '../styles/TripPoint.scss';

const TripPoint = ({
  index,
  tripPointsNames,
  changePlaceInputHandler = () => {},
  changeDateInputHandler = () => {},
  changeTimeInputHandler = () => {},
  addNewTripPointHandler = () => {},
  deleteTripPointHandler = () => {},
  isPassenger = true,
  currentPoint = tripPointModel,
  isCreator,
  disabled = false,
}) => {
  //#region Get language from context, manage languages
  const { lang } = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Render
  return (
    <div
      className={'TripPoint'}
    >
      <div className={'TripPoint-Item'}>
        <label
          className={'TripPoint-Label'}
        >
          {index === 0 &&
            translate('Пункт отправления')
          }
          {index === tripPointsNames.length - 1 &&
            translate('Пункт прибытия')
          }
          {(index > 0 && index < tripPointsNames.length - 1) &&
            translate('Промежуточный пункт')
          }
        </label>

        {(isCreator && !disabled) ?
          <LocationIq
            externalAction={changePlaceInputHandler}
            index={index}
            receivedAddress={getTranslatedLocalityName(currentPoint
              .fullAddress, lang)}
          />
          :
          <input
            className={'FormControl'}
            disabled={true}
            placeholder={getTranslatedLocalityName(currentPoint.fullAddress,
              lang)}
          />
        }
      </div>

      {!isPassenger ?
        (<div className={'TripPoint-Item'}>
            <div className={'FlexBlock'}>

              {index !== tripPointsNames.length - 1 &&
                <div
                  className={'FlexBlock-HorizontalItem' +
                    ' FlexBlock-HorizontalItem_W45'}
                >
                  <label
                    className={'TripPoint-Label'}
                  >
                    {translate('Дата отправления')}
                  </label>

                  <input
                    type={'date'}
                    className={'FormControl'}
                    onChange={(event) => changeDateInputHandler(
                      event.target.value, index)}
                    value={convertTimestampToCalendarDate(
                      currentPoint?.departureDateWithoutTime)}
                    required
                    disabled={
                      disabled || index === tripPointsNames
                        .length - 1 || !isCreator
                    }
                  />
                </div>
              }

              {index !== tripPointsNames.length - 1 &&
                <div
                  className={
                    'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W45'
                  }
                >
                  <label
                    className={'TripPoint-Label'}
                  >
                    {translate('Время отправления')}
                  </label>

                  <input
                    type={'time'}
                    className={'FormControl'}
                    onChange={(event) => changeTimeInputHandler(
                      event.target.value, index)}
                    value={currentPoint?.departureTimeWithoutDate}
                    required
                    disabled={
                      index === tripPointsNames.length - 1 || !isCreator
                    }
                  />
                </div>
              }
            </div>
          </div>
        ) : (index < tripPointsNames.length - 1 &&
          <div className={'TripPoint-Item'}>
            <div className={'FlexBlock'}>
              <div
                className={
                  'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W45'
                }
              >
                <label
                  className={'TripPoint-Label'}
                >
                  {translate('Дата отправления')}
                </label>

                <input
                  type={'date'}
                  className={'FormControl'}
                  onChange={(event) => changeDateInputHandler(
                    event.target.value, index)}
                  value={convertTimestampToCalendarDate(
                    currentPoint?.departureDateWithoutTime)}
                  disabled={!isCreator || disabled}
                  required
                />
              </div>

              <div
                className={
                  'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W45'
                }
              >
                <label
                  className={'TripPoint-Label'}
                >
                  {translate('Время отправления')}
                </label>

                <input
                  type={'time'}
                  className={'FormControl'}
                  onChange={(event) => changeTimeInputHandler(
                    event.target.value, index)}
                  value={currentPoint?.departureTimeWithoutDate}
                  disabled={!isCreator}
                  required
                />
              </div>
            </div>
          </div>)
      }

      {!isPassenger &&
        <>
          {(index === 0 && isCreator) &&
            <div className={'AddTripForm-Item'}>
              <button
                type={'button'}
                className={'RegularButton'}
                onClick={() => addNewTripPointHandler(index)}
              >
                {translate('Добавить промежуточный пункт')}
              </button>
            </div>
          }

          {index > 0 &&
            <div>
              {(index < tripPointsNames.length - 1 && isCreator) &&
                <>
                  <div className={'TripPoint-Item'}>
                    <button
                      type={'button'}
                      className={'RegularButton RegularButton_Contrast'}
                      onClick={() => deleteTripPointHandler(index)}
                    >
                      {translate('Удалить промежуточный пункт')}
                    </button>
                  </div>

                  <div className={'TripPoint-Item'}>
                    <button
                      type={'button'}
                      className={'RegularButton'}
                      onClick={() => addNewTripPointHandler(index)}
                    >
                      {translate('Добавить промежуточный пункт')}
                    </button>
                  </div>
                </>
              }
            </div>
          }
        </>
      }
    </div>
  );
  //#endregion
};

export default TripPoint;
