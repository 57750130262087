import React, {useContext, useState, useEffect, useRef} from 'react';
import {AppContext} from '../context/appContext';
import {
  makeDeposit,
  validateAndCorrectCardDate,
  validateAndCorrectCardNumber,
  validateCardCvv,
} from "../utils/helpers";
import ModalNotification from "../modals/ModalNotification";
import Loader from "./Loader";
import '../styles/AddToBalance.scss';
import {useNavigate} from "react-router-dom";
import NumberInputWithValidation from "../elements/NumberInputWithValidation";
import useTranslation from "../hooks/useTranslation";

const AddToBalance = ({addClasses = ''}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Data
  const [paymentData, setPaymentData] = useState({});

  const updatePaymentData = (fieldName, newValue) => {
    let validatedValue = '';

    if (newValue === '') {
      validatedValue = '';

      setPaymentData(state => ({
        ...state,
        [fieldName]: validatedValue,
      }));

      return;
    }

    switch (fieldName) {
      case 'cardNumber':
        validatedValue = validateAndCorrectCardNumber(newValue,
          paymentData[fieldName] || '');
        break;
      case 'cardTerm':
        validatedValue = validateAndCorrectCardDate(newValue,
          paymentData[fieldName] || '');
        break;
      case 'cardCVV':
        validatedValue = validateCardCvv(newValue,
          paymentData[fieldName] || '');
        break;
      default:
        validatedValue = newValue;
    }

    setPaymentData(state => ({
      ...state,
      [fieldName]: validatedValue,
    }));
  }
  //#endregion

  //#region Get screen data and functions from context
  const {setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set screen title
  useEffect(() => {
    setScreenTitle('Пополнить баланс');
  }, []);
  //#endregion

  //#region Payment link
  const paymentLink = useRef(null);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  async function submitDepositAmount(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (!paymentData?.amount || paymentData?.amount <= 0) {
        setIsNotification(true);
        setNotificationTitle('Ошибка ввода данных');
        setNotificationMessage('Введите сумму пополнения. Сумма должна быть' +
          ' больше нуля');

        return;
      }

      const checkoutUrl = await makeDeposit(user, paymentData.amount * 100);

      paymentLink.current.href = checkoutUrl;

      paymentLink.current.click();
      navigate('/profile');
    } catch (error) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage('Произошла ошибка, платеж не осуществлен');
    } finally {
      setIsLoading(false);
    }
  }

  //#region Render
  return (
    <React.Fragment>
      {isLoading ? <Loader/>
        :
        <div className={`AddToBalance ${addClasses}`}>
          <div className={'AddToBalance-Item FlexBlock AddToBalance-FlexBlock'}>
            <div
              className={'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W48'}
            >
              <label className={'AddToBalance-Label'}>
                {translate('Сумма пополнения')}
              </label>

              <NumberInputWithValidation
                externalChangeHandler={updatePaymentData}
                fieldName={'amount'}
                name={'Amount'}
                placeholder={translate('Сумма пополнения')}
                value={paymentData.amount}
              />
            </div>

            <div
              className={'FlexBlock-HorizontalItem FlexBlock-HorizontalItem_W48'}
            >
              <button
                type={'button'}
                className={'RegularButton'}
                onClick={(event) => submitDepositAmount(event)}
              >
                {translate('Подтвердить')}
              </button>
            </div>
          </div>

          {isNotification &&
            <ModalNotification
              showModal={isNotification}
              setShowModal={setIsNotification}
              title={notificationTitle}
              message={notificationMessage}
            />
          }
        </div>
      }

      <a ref={paymentLink} target={'_blank'}></a>
    </React.Fragment>
  );
  //#endregion
};

export default AddToBalance;
