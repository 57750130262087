import {
  appleSignIn,
  deleteDocumentFromCollectionWithID,
  facebookSignIn,
  getCollection,
  getCollectionLength,
  getCollectionWhereKeysValues,
  getCollectionWhereKeysValuesWithOperatorOrderedAndLimited,
  getCollectionWhereKeysValuesWithOperators,
  getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit,
  getCollectionWhereKeyValue,
  getDocInCollection,
  getLengthOfCollectionWhereKeysValuesWithOperators,
  googleSignIn,
  realTimeDb,
  setDocumentToCollection,
  setDocumentToRdb,
  updateFieldInDocumentInCollection,
  updateFieldsInDocumentInCollection,
} from './firebaseConfigAndFunctions';
import {
  adminDataTypesInterface,
  authProvidersCheckResultsInterface,
  balanceAccountModel,
  catalogsNamesInterface,
  collectionsInterface,
  endPointsInterface,
  messageModel,
  notificationsTitlesInterface,
  pagesInterface,
  placesTypes,
  registrationErrorsModel,
  registrationErrorsTypesInterface,
  rolesInterface,
  serverUrl,
  statisticDataModel,
  statisticQueryTypesInterface,
  superAdmin,
  testimonialModel,
  textPagesTypesNamesInterface,
  transactionsTypesInterface,
  tripStatusesInterface as tripsStatusesInterface,
  userModel,
  vehicleErrorsModel,
  withdrawalStatusesInterface,
} from './models';
import firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from 'uuid';
import {
  compareAsc,
  differenceInYears,
  format,
  getMonth,
  getYear,
  isAfter,
  startOfMonth,
  subDays,
} from 'date-fns';
import { ref, onChildAdded, onChildChanged } from 'firebase/database';
import { englishDictionary } from './dictionaries';
import axios from 'axios';
import { DEFAULT_LANG, PHONE_NUMBER_MIN_LENGTH } from './data';
import { cancelAcceptedDeal } from './reservationsService';
import {
  getLocationIqPlaceDetails,
  getTranslatedLocalityName,
} from './placesService';

//#region Detect language
const getLanguageCodeFromBrowserLanguage = (language) => {
  const chunks = language.split('-');

  return chunks[0];
};

export const getLanguageCodeFromBrowser = () => {
  const userLanguage = window.navigator.language;

  const languageCode = getLanguageCodeFromBrowserLanguage(userLanguage);

  return languageCode || DEFAULT_LANG;
};

export const defineLanguageOnLoad = (
  dictionariesLanguages,
  detectedLanguage
) => {
  if (dictionariesLanguages.includes(detectedLanguage)) {
    return detectedLanguage;
  }

  return DEFAULT_LANG;
};
//#endregion

//#region Work with dates
export function checkIsLeapYear(year) {
  const yearToCheck = parseInt(year);

  if (yearToCheck % 4 !== 0) {
    return false;
  } else if (yearToCheck % 400 !== 0 && yearToCheck % 100 === 0) {
    return false;
  }

  return true;
}

export function getNumberOfDaysInMonth(month, year) {
  const monthToCheck = parseInt(month);

  if (monthToCheck < 1 || monthToCheck > 12) {
    return 0;
  }

  switch (monthToCheck) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 2:
      if (checkIsLeapYear(year)) {
        return 29;
      } else {
        return 28;
      }
    default:
      return 30;
  }
}

export function isDateExists(day, month, year) {
  if (parseInt(day) > getNumberOfDaysInMonth(month, year)) {
    return false;
  }
  return true;
}

export function getDateWithoutTime(date) {
  if (date === undefined) {
    return 0;
  }

  try {
    const initialDate = new Date(date);

    return (
      initialDate.getFullYear() +
      '-' +
      (initialDate.getMonth() + 1) +
      '-' +
      initialDate.getDate()
    );
  } catch (e) {
    return 0;
  }
}

export const showFormattedToLanguageDate = (
  lang,
  dateInput,
  isWithTime = false
) => {
  try {
    const givenDate = new Date(dateInput);

    let formattedDate = '';

    if (isWithTime) {
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
      };

      const formattedDatePart = Intl.DateTimeFormat(lang).format(givenDate);
      const formattedTimePart = Intl.DateTimeFormat(lang, options).format(
        givenDate
      );

      formattedDate = `${formattedDatePart} ${formattedTimePart}`;
    } else {
      formattedDate = Intl.DateTimeFormat(lang).format(givenDate);
    }

    return formattedDate;
  } catch (error) {
    return dateInput;
  }
};

export const getHoursAndMinutesFromTime = (timeString) => {
  if (timeString) {
    const timeArray = timeString.split(':');

    return {
      hours: timeArray[0],
      minutes: timeArray[1],
    };
  } else {
    return {
      hours: 0,
      minutes: 0,
    };
  }
};

export const convertTimestampToCalendarDate = (timestamp) => {
  try {
    if (!timestamp) {
      return '';
    }

    const newDate = new Date(timestamp);

    return format(newDate, 'yyyy-MM-dd');
  } catch (error) {
    return error.message;
  }
};
//#endregion

//#region Vehicles, places scheme creation and displaying
export async function updateUserVehicles(collection, key, value, vehicle) {
  try {
    const users = await getCollectionWhereKeyValue(collection, key, value);

    if (users.length > 0) {
      const user = users[0];
      const userVehicles = [...user.vehicles, vehicle];

      await updateFieldInDocumentInCollection(
        collection,
        user.idPost,
        'vehicles',
        userVehicles
      );
    } else {
      return 'No user detected';
    }
  } catch (error) {
    return error;
  }
}

export function getAllPlacesFromScheme(schemeTable) {
  const places = [];

  schemeTable.forEach((row) => {
    row.forEach((item) => {
      if (!item.isFreeSpace) {
        places.push(item);
      }
    });
  });

  return places;
}

export function checkArePlacesInSchemeUniqAndHaveNumber(placesArray) {
  const result = placesArray.some((item, index, array) => {
    if (item.placeNumber <= 0) {
      return true;
    }

    let count = 0;

    for (const place of array) {
      if (place.placeNumber === item.placeNumber) {
        count++;
      }

      if (count > 1) {
        return true;
      }
    }

    return false;
  });

  return !result;
}

export function validatePlacesScheme(schemeTable, totalPlacesNumber) {
  if (schemeTable.length === 0) {
    return {
      isError: true,
      errorMessage: 'Схема не может быть пустой',
    };
  }

  if (schemeTable.every((row) => row.every((place) => place.isFreeSpace))) {
    return {
      isError: true,
      errorMessage: 'Схема не может состоять только из пустых мест',
    };
  }

  const placesArray = getAllPlacesFromScheme(schemeTable);

  if (placesArray.length !== totalPlacesNumber) {
    return {
      isError: true,
      errorMessage:
        'Количество мест на схеме не совпадает с указанным количеством мест в ТС',
    };
  }

  if (!checkArePlacesInSchemeUniqAndHaveNumber(placesArray)) {
    return {
      isError: true,
      errorMessage: 'Недопустимый номер места или места на схеме не уникальны',
    };
  }

  return {
    isError: false,
    errorMessage: '',
  };
}

export const convertSchemeIntoTable = (schemeFromDatabase) => {
  let rows = [];
  for (let i = 0; i < schemeFromDatabase.rows.length; i++) {
    let cols = [];
    for (let j = 0; j < schemeFromDatabase.rows[i].row.length; j++) {
      cols.push(schemeFromDatabase.rows[i].row[j]);
    }
    rows.push(cols);
  }

  return rows;
};

export const getFreePlacesArrayFromPlacesScheme = (scheme) => {
  const placesNumbers = [];

  try {
    scheme.rows.forEach((row) => {
      row.row.forEach((cell) => {
        if (!cell.isOccupied && !cell.isFreeSpace) {
          placesNumbers.push(cell.placeNumber);
        }
      });
    });

    return placesNumbers;
  } catch (error) {
    return [];
  }
};

export const checkVehicleOrDriverTripCompleteness = (trip) => {
  const hasEmptyDataFields =
    !trip?.startDate ||
    !trip?.endDate ||
    !trip?.startPoint?.placeId ||
    !trip.endPoint?.placeId;

  return !hasEmptyDataFields;
};
//#endregion

//#region Work with Google places, handle Google Places data
export const getPlaceWithoutIndex = (place, defaultAddress = 'Not found') => {
  if (!place) {
    return defaultAddress;
  }

  const numbersInPlaceDescription = '0123456789';
  const isPlaceContainsNumberAtTheEnd = numbersInPlaceDescription.includes(
    place[place.length - 1]
  );
  const isPlaceContainsNumberAtTheStart = numbersInPlaceDescription.includes(
    place[0]
  );
  let placeWithoutNumbers = place;

  if (isPlaceContainsNumberAtTheEnd) {
    const lastComaIndex = placeWithoutNumbers.lastIndexOf(',');
    placeWithoutNumbers = place.slice(0, lastComaIndex);
  }

  if (isPlaceContainsNumberAtTheStart) {
    const firstSpaceIndex = placeWithoutNumbers.indexOf(' ');
    placeWithoutNumbers = place.slice(firstSpaceIndex + 1);
  }

  return placeWithoutNumbers;
};

export const getAllTripPointsCitiesNames = (tripPoints, lang) => {
  const citiesNames = [];

  let city = '';

  for (let i = 0; i < tripPoints.length; i++) {
    try {
      city =
        tripPoints[i]?.city[lang] ||
        tripPoints[i]?.city?.default ||
        'Not found';

      citiesNames.push(city);
    } catch (error) {
      citiesNames.push(tripPoints[i].city.default);
    }
  }

  return citiesNames;
};

export const getPointsTranslationAndSetToState = (
  tripPoints,
  lang,
  setIsLoading = () => {},
  setCitiesNames = () => {}
) => {
  setIsLoading(true);

  try {
    const translatedPoints = getAllTripPointsCitiesNames(tripPoints, lang);

    setCitiesNames(translatedPoints);
  } catch (error) {
    throw new Error(error);
  } finally {
    setIsLoading(false);
  }
};
//#endregion

//#region API request
export const apiRequest = async (BASE_URL, url = '', options) => {
  let response;

  if (options) {
    response = await fetch(`${BASE_URL}${url}`, options);
  } else {
    response = await fetch(`${BASE_URL}${url}`);
  }

  if (!response.ok) {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

  return response.json();
};
//#endregion

//#region Messages (notifications) to users
export const sendMessageToUser = async (user, title, message) => {
  const url = endPointsInterface.sendEmail;

  const options = {
    method: 'POST',
    body: JSON.stringify({
      email: user.email,
      text: message,
      title: title,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await apiRequest(serverUrl, url, options);
};

export const createNotification = (user, title, message, emailMessage) => {
  const currentDate = Date.now();

  const notification = {
    dateCreating: currentDate,
    id: currentDate,
    isNew: true,
    recipientId: user.idPost,
    text: message,
    textForEmail: emailMessage,
    title: title,
  };

  return notification;
};

export const saveNotificationToRdb = async (notificationData, userId) => {
  const notificationToSave = {
    ...notificationData,
    textForEmail: '',
  };

  try {
    const path = `notifications/${userId}/${notificationData?.dateCreating}`;
    await setDocumentToRdb(path, notificationToSave);

    return true;
  } catch (error) {
    return false;
  }
};

export const changeNotificationStatus = async (userId, notification) => {
  try {
    const path = `notifications/${userId}/${notification?.dateCreating}/isNew`;

    await setDocumentToRdb(path, false);

    return true;
  } catch (error) {
    return false;
  }
};

export const listenToNotificationAdding = (
  userId,
  actionFunction = () => {},
  ...args
) => {
  const notificationsRef = ref(realTimeDb, `notifications/${userId}`);

  const callback = (snapshot) => {
    const data = snapshot.val();

    actionFunction(data, userId, ...args);
  };

  const unsubscribe = onChildAdded(notificationsRef, callback);

  return {
    ref: notificationsRef,
    unsubscribe: () => {
      unsubscribe();
    },
  };
};

export const listenToNotificationChange = (
  userId,
  actionFunction = () => {},
  ...args
) => {
  const notificationsRef = ref(realTimeDb, `notifications/${userId}`);

  const callback = (snapshot) => {
    const data = snapshot.val();

    actionFunction(data, userId, ...args);
  };

  const unsubscribe = onChildChanged(notificationsRef, callback);

  return {
    ref: notificationsRef,
    unsubscribe: () => {
      unsubscribe();
    },
  };
};

export const checkAreThereNewNotificationsInList = (notifications) => {
  const result = notifications.some((notification) => notification.isNew);

  return result;
};

//#region Create notifications for different situations
export const createRouteTextData = (startPoint, endPoint) => {
  const result = {
    startPoint: {
      city: startPoint.city,
      country: startPoint.country,
    },
    endPoint: {
      city: endPoint.city,
      country: endPoint.country,
    },
  };

  return result;
};

export const createMessageText = (message) => {
  let notificationText = '';
  const notificationArray = [];

  const messageKeys = Object.keys(message);

  for (let key of messageKeys) {
    if (message[key]) {
      let textChunk = '';

      if (key === 'date') {
        textChunk =
          message[key].intro +
          ' ' +
          format(new Date(message[key].data), 'dd.MM.yyyy HH:mm') +
          '.\n';
      } else {
        textChunk = message[key].intro + ' ' + message[key].data + '.\n';
      }

      if (key === 'route') {
        textChunk =
          message[key]?.startPoint?.city?.default +
          ', ' +
          message[key]?.startPoint?.country?.default +
          ' -> ' +
          message[key]?.endPoint?.city?.default +
          ', ' +
          message[key]?.endPoint?.country?.default;
      }

      notificationArray.push({
        order: message[key].order,
        text: textChunk,
      });
    }
  }

  notificationText = notificationArray
    .sort((prev, next) => prev.order - next.order)
    .map((item) => item.text)
    .join('');

  return notificationText;
};

export const getMessageText = (message, translate, lang) => {
  let notificationText = '';
  const notificationArray = [];

  const messageKeys = Object.keys(message);

  for (let key of messageKeys) {
    let textChunk = '';

    if (message[key]) {
      if (key === 'date') {
        textChunk =
          translate(message[key]?.intro) +
          ' ' +
          showFormattedToLanguageDate(lang, message[key].data, true) +
          '.\n';
      } else if (key === 'cost' || key === 'fee') {
        textChunk =
          translate(message[key]?.intro) +
          ' ' +
          message[key].data +
          ' ' +
          translate(message[key].currency) +
          '.\n';
      } else if (key === 'route' || key === 'trip') {
        textChunk =
          translate(message[key]?.intro) +
          ' ' +
          getTranslatedLocalityName(
            message[key]?.data?.startPoint?.city,
            lang
          ) +
          ', ' +
          getTranslatedLocalityName(
            message[key]?.data?.startPoint?.country,
            lang
          ) +
          ' -> ' +
          getTranslatedLocalityName(message[key]?.data?.endPoint?.city, lang) +
          ', ' +
          getTranslatedLocalityName(
            message[key]?.data?.endPoint?.country,
            lang
          ) +
          '.\n';
      } else {
        textChunk =
          translate(message[key]?.intro) +
          (message[key]?.data ? ' ' + message[key]?.data : '') +
          (message[key]?.additionalData
            ? ' ' + translate(message[key]?.additionalData)
            : '') +
          '.\n';
      }

      notificationArray.push({
        order: message[key].order,
        text: textChunk,
      });
    }
  }

  notificationText = notificationArray
    .sort((prev, next) => prev.order - next.order)
    .map((item) => item.text)
    .join('');

  return notificationText;
};

export const getUserLanguageForEmails = async (userId) => {
  try {
    const { languageForEmails } = await getDocInCollection(
      collectionsInterface.users,
      userId
    );

    return languageForEmails;
  } catch (error) {
    return DEFAULT_LANG;
  }
};

export const getDictionaryForEmail = async (langCode) => {
  try {
    const dictionariesCollection = await getCollection(
      collectionsInterface.dictionaries
    );

    if (dictionariesCollection.length === 0) {
      return;
    }

    const dictionaries = dictionariesCollection[0].dictionaries;

    const dictionary = dictionaries.find((dict) => dict.name === langCode);

    if (!dictionary) {
      return dictionaries.find((dict) => dict.name === DEFAULT_LANG);
    }

    return dictionary;
  } catch (error) {
    return;
  }
};

export const getTranslatedEmailMessage = async (
  title,
  messageObject,
  recipientId
) => {
  try {
    const langCode = await getUserLanguageForEmails(recipientId);

    const dictionary = await getDictionaryForEmail(langCode);

    const translatedTitle = getTranslation(title, dictionary, dictionary);

    const translate = (key) => getTranslation(key, dictionary, dictionary);

    const message = getMessageText(messageObject, translate);

    return { message, title: translatedTitle };
  } catch (error) {
    return;
  }
};

export const createNotificationAboutNewTripsAppearanceForDispatcher = (
  transporter,
  trip,
  tripOrder
) => {
  const title = notificationsTitlesInterface.newTripForPassenger;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: 'Появилась новая поездка для пассажира',
      data: tripOrder.passengerName,
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут:',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    transporter: {
      intro: 'Перевозчик:',
      data: transporter.companyName || transporter.fullName,
      order: 4,
    },
    cost: {
      intro: 'Стоимость билета:',
      data: trip.pricePerPerson,
      currency: 'грн',
      order: 5,
    },
    fee: {
      intro: 'Вознаграждение диспетчера:',
      data: trip.dispatcherFee,
      currency: 'грн',
      order: 6,
    },
    drivers: {
      intro: 'Водители в поездке:',
      data:
        trip.drivers[0].fullName +
        (trip.drivers.length > 1 ? ' ' + trip.drivers[1].fullName : ''),
      order: 7,
    },
  };
  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutAddingToTripAsDriver = (
  companyName,
  trip,
  driver
) => {
  const title = notificationsTitlesInterface.addedToTripAsDriver;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: 'Вас добавили водителем в поездку компании',
      data: companyName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут:',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 3,
    },
    drivers: {
      intro: 'Водители в поездке:',
      data:
        trip.driversNames[0] +
        (trip.driversNames.length > 1 ? ' ' + trip.driversNames[1] : ''),
      order: 4,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: driver.idPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutDeletingFromTripAsDriver = (
  companyName,
  trip,
  driverId
) => {
  const title = notificationsTitlesInterface.deletedFromTripAsDriver;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: 'Вас удалили из поездки компании',
      data: companyName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут:',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 3,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: driverId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutTripDeletingForDriver = (
  companyName,
  trip,
  driverId
) => {
  const title = notificationsTitlesInterface.deletedFromTripAsDriver;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro:
        'Поездка, в которой Вы были назначены водителем, была удалена' +
        ' компанией',
      data: companyName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут:',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 3,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: driverId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutNewTripsOrdersAppearanceForTransporter = (
  transporterId,
  trip,
  tripOrder
) => {
  const title = notificationsTitlesInterface.newPassengerForTrip;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: 'Появился новый пассажир для поездки',
      data: tripOrder.passengerName,
      order: 1,
    },
    date: {
      intro: 'Желаемые дата и время выезда:',
      data: tripOrder.departureTime,
      order: 2,
    },
    route: {
      intro: 'Маршрут:',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    cost: {
      intro: 'Желаемая стоимость билета:',
      data: tripOrder.pricePerPerson,
      currency: 'грн',
      order: 4,
    },
    fee: {
      intro: 'Желаемое вознаграждение диспетчера:',
      data: tripOrder.dispatcherFee,
      currency: 'грн',
      order: 5,
    },
    dispatcher: {
      intro: 'Диспетчер, создавший пассажира:',
      data: tripOrder.dispatcherFullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: transporterId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutPassengerReservation = (
  transporter,
  trip,
  tripOrder
) => {
  const title = notificationsTitlesInterface.newReservationForPassenger;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: 'Ваш пассажир забронирован в поездку компании',
      data: transporter?.companyName || transporter?.fullName || '',
      order: 1,
    },
    passenger: {
      intro: 'Имя пассажира:',
      data: tripOrder.passengerName,
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут:',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    drivers: {
      intro: 'Водители в поездке:',
      data:
        trip.drivers[0].fullName +
        (trip.drivers.length > 1 ? ' ' + trip.drivers[1].fullName : ''),
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutTripReservation = (trip, tripOrder) => {
  const title = notificationsTitlesInterface.newReservationForPassenger;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `В вашу поездку забронирован пассажир`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Желаемые дата и время выезда:',
      data: tripOrder.departureTime,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutReservationConfirmation = (
  trip,
  tripOrder,
  transporter,
  isDispatcher
) => {
  const title = notificationsTitlesInterface.reservationConfirmation;

  const userRole = isDispatcher ? 'Диспетчер' : 'Водитель';

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `${userRole} подтвердил Вашу бронь по пассажиру`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    dispatcher: {
      intro: `${userRole}`,
      data: isDispatcher
        ? tripOrder.dispatcherFullName
        : transporter?.companyName || transporter?.fullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: isDispatcher ? trip.creatorIdPost : tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutReservationRejectionByDispatcher = (
  trip,
  tripOrder,
  introductionIntro = 'Диспетчер отклонил Вашу бронь по пассажиру'
) => {
  const title = notificationsTitlesInterface.reservationRejection;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: introductionIntro,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    dispatcher: {
      intro: `Диспетчер`,
      data: tripOrder.dispatcherFullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutReservationRejectionByTransporter = (
  trip,
  tripOrder,
  transporter,
  introductionIntro = 'Перевозчик отклонил Вашу бронь по пассажиру'
) => {
  const title = notificationsTitlesInterface.reservationRejection;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: introductionIntro,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    transporter: {
      intro: `Перевозчик`,
      data: transporter.companyName || transporter.fullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutTripCancellationByDispatcher = (
  trip,
  tripOrder
) => {
  const title = notificationsTitlesInterface.dealCancellation;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Диспетчер отменил поездку для пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutTripCancellationByTransporter = (
  trip,
  tripOrder,
  transporter
) => {
  const title = notificationsTitlesInterface.dealCancellation;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Перевозчик отменил поездку для пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    transporter: {
      intro: 'Перевозчик',
      data: transporter?.companyName || transporter?.fullName,
      order: 5,
    },
    extra: {
      intro: 'Пассажир перемещен в блокнот',
      data: '',
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutTripStart = (
  trip,
  tripOrder,
  transporter
) => {
  const title = notificationsTitlesInterface.tripStart;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Началась поездка пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Запланированные дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    transporter: {
      intro: 'Перевозчик',
      data: transporter?.companyName || transporter?.fullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutTripEnd = (
  trip,
  tripOrder,
  transporter
) => {
  const title = notificationsTitlesInterface.tripEnd;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Завершилась поездка пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 2,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 3,
    },
    transporter: {
      intro: 'Перевозчик',
      data: transporter?.companyName || transporter?.fullName,
      order: 4,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutEvaluationByDispatcher = (
  trip,
  tripOrder,
  evaluatedUserId
) => {
  const title = notificationsTitlesInterface.evaluationMade;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Диспетчер оценил поездку пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 5,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: evaluatedUserId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutEvaluationByDriver = (
  trip,
  tripOrder,
  transporter
) => {
  const title = notificationsTitlesInterface.evaluationMade;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Водитель оценил поездку пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 2,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 3,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 4,
    },
    transporter: {
      intro: 'Перевозчик',
      data: transporter.companyName || transporter.fullName,
      order: 5,
    },
    drivers: {
      intro: 'Водители',
      data:
        trip.drivers[0].fullName + trip.drivers.length > 1
          ? trip.drivers[1].fullName
          : '',
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutDeposit = (userId, amount) => {
  const title = notificationsTitlesInterface.depositMade;

  const messageObject = {
    ...messageModel,
    cost: {
      intro: `Ваш счет пополнен на сумму`,
      data: amount,
      currency: 'грн',
      order: 1,
    },
  };
  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: userId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationMoneyFreezed = (trip, tripOrder, amount) => {
  const title = notificationsTitlesInterface.moneyFreezed;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Заморожена сумма на счету по пассажиру`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    fee: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 4,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 5,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationMoneyUnfreezed = (trip, tripOrder, amount) => {
  const title = notificationsTitlesInterface.moneyUnfreezed;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Разморожена сумма на счету по пассажиру`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    fee: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 4,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 5,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationNotEnoughMoney = (trip, tripOrder, amount) => {
  const title = notificationsTitlesInterface.notEnoughMoney;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `У Вас недостаточно средств. Диспетчер не смог подтвердить бронь для пассажира`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    fee: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 4,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 5,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutFeePaidToDispatcher = (
  trip,
  tripOrder,
  amount
) => {
  const title = notificationsTitlesInterface.feePaid;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Списано вознаграждение диспетчеру по пассажиру`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    fee: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 4,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 5,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutFeeReceived = (
  trip,
  tripOrder,
  amount,
  transporter
) => {
  const title = notificationsTitlesInterface.feeReceived;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Начислено вознаграждение по пассажиру`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    fee: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 4,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 5,
    },
    transporter: {
      intro: 'Перевозчик',
      data: transporter.companyName || transporter.fullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: tripOrder.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutCommissionTaking = (
  trip,
  tripOrder,
  amount
) => {
  const title = notificationsTitlesInterface.commissionTaken;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Списана комиссия по пассажиру`,
      data: tripOrder?.passengerName || '',
      order: 1,
    },
    fee: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
    date: {
      intro: 'Дата и время выезда:',
      data: trip.startDate,
      order: 3,
    },
    route: {
      intro: 'Маршрут пассажира',
      data: createRouteTextData(tripOrder.startPoint, tripOrder.endPoint),
      order: 4,
    },
    trip: {
      intro: 'Маршрут поездки',
      data: createRouteTextData(trip.startPoint, trip.endPoint),
      order: 5,
    },
    dispatcher: {
      intro: 'Диспетчер',
      data: tripOrder.dispatcherFullName,
      order: 6,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: trip.creatorIdPost,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutBonusReceived = (
  userId,
  registeredUser,
  amount
) => {
  const title = notificationsTitlesInterface.bonusReceived;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Вам начислен бонус за приглашение пользователя`,
      data: registeredUser.fullName,
      order: 1,
    },
    cost: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
  };
  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: userId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutReferralRegistration = (
  referrerId,
  registeredUser
) => {
  const title = notificationsTitlesInterface.referralRegistration;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `По вашей реферальной ссылке зарегистрировался пользователь`,
      data: registeredUser.fullName,
      order: 1,
    },
  };
  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: referrerId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutBonusReceivedForReferralDeal = (
  referrerId,
  referralName,
  amount
) => {
  const title = notificationsTitlesInterface.bonusPerReferralReceived;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Вам начислен бонус за сделку пользователя`,
      data: referralName,
      order: 1,
    },
    cost: {
      intro: 'Сумма',
      data: amount,
      currency: 'грн',
      order: 2,
    },
  };
  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: referrerId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutInvitationToCompany = (
  companyName = '',
  driverId,
  invitationDate
) => {
  const title = notificationsTitlesInterface.invitationFromCompany;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Вас пригласили стать водителем в компании`,
      data: companyName,
      order: 1,
    },
    date: {
      intro: 'Дата приглашения',
      data: invitationDate,
      order: 2,
    },
    hint: {
      intro: 'Зайдите в пункт меню профиля "Приглашения от компаний"',
      data: '',
      order: 3,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: driverId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutInvitationCancellation = (
  companyName = '',
  driverId,
  cancellationDate
) => {
  const title = notificationsTitlesInterface.invitationCancellation;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Было отозвано приглашение в компанию`,
      data: companyName,
      order: 1,
    },
    date: {
      intro: 'Дата отмены',
      data: cancellationDate,
      order: 2,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: driverId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutInvitationRejection = (
  driverName = '',
  companyId,
  rejectionDate
) => {
  const title = notificationsTitlesInterface.invitationRejection;

  const messageObject = {
    ...messageModel,
    introduction: {
      intro: `Ваше приглашение работать в компании было отклонено водителем`,
      data: driverName,
      order: 1,
    },
    date: {
      intro: 'Дата действия',
      data: rejectionDate,
      order: 2,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: companyId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};

export const createNotificationAboutExchangeAccountingRequest = (
  amount,
  userName,
  cardNumber,
  recipientId
) => {
  const title = notificationsTitlesInterface.exchangeAccountingRequest;

  const messageObject = {
    ...messageModel,
    cost: {
      intro: 'Необходимо оплатить',
      data: amount,
      currency: 'грн',
      order: 1,
    },
    introduction: {
      intro: `пользователю`,
      data: userName,
      order: 2,
    },
    hint: {
      intro: `Номер карты`,
      data: cardNumber,
      order: 3,
    },
  };

  const message = createMessageText(messageObject);

  const notification = createNotification(
    {
      idPost: recipientId,
    },
    title,
    messageObject,
    message
  );

  return notification;
};
//#endregion

//#region Handle different situations to send notifications
export const findAllSuitableTripsOrdersAndSendMessagesToDispatchers = async (
  user,
  trip
) => {
  try {
    const tripsOrders = await searchTripsOrders(
      trip.startPoint,
      trip.endPoint,
      trip.departureDateWithoutTime,
      trip.isPassengerTrip,
      true,
      false,
      user.idPost
    );

    tripsOrders.forEach((tripOrder) => {
      const notification =
        createNotificationAboutNewTripsAppearanceForDispatcher(
          user,
          trip,
          tripOrder
        );

      saveNotificationToRdb(notification, tripOrder.creatorIdPost);

      getDocInCollection(
        collectionsInterface.users,
        tripOrder.creatorIdPost
      ).then((dispatcher) => {
        getTranslatedEmailMessage(
          notification.title,
          notification.text,
          dispatcher.idPost
        ).then(({ message, title }) => {
          sendMessageToUser(dispatcher, title, message);
        });
      });
    });
  } catch (error) {
    return false;
  }
};

export const sendMessageAboutAddingToTripAsDriver = async (
  companyName,
  trip,
  driver
) => {
  try {
    let notification = {};

    notification = createNotificationAboutAddingToTripAsDriver(
      companyName,
      trip,
      driver
    );

    await saveNotificationToRdb(notification, driver.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      driver.idPost
    );

    await sendMessageToUser({ email: driver.email }, title, message);

    return true;
  } catch (error) {
    return false;
  }
};

export const sendMessageAboutDeletingFromTripAsDriver = async (
  companyName,
  trip,
  driver
) => {
  try {
    let notification = {};

    notification = createNotificationAboutDeletingFromTripAsDriver(
      companyName,
      trip,
      driver.idPost
    );

    await saveNotificationToRdb(notification, driver.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      driver.idPost
    );

    await sendMessageToUser({ email: driver.email }, title, message);

    return true;
  } catch (error) {
    return false;
  }
};

export const sendMessageAboutDeletingForDriver = async (
  companyName,
  trip,
  driver
) => {
  try {
    let notification = {};

    notification = createNotificationAboutTripDeletingForDriver(
      companyName,
      trip,
      driver.idPost
    );

    await saveNotificationToRdb(notification, driver.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      driver.idPost
    );

    await sendMessageToUser({ email: driver.email }, title, message);

    return true;
  } catch (error) {
    return false;
  }
};

export const findAllSuitableTripsAndSendMessageForTransporters = async (
  user,
  tripOrder
) => {
  try {
    const trips = await searchTripsForPassengerFromStartPointToEndPoint(
      tripOrder,
      user.idPost,
      false
    );
    let notification = '';

    trips.forEach((trip) => {
      notification =
        createNotificationAboutNewTripsOrdersAppearanceForTransporter(
          trip.creatorIdPost,
          trip,
          tripOrder
        );

      saveNotificationToRdb(notification, trip.creatorIdPost);

      getDocInCollection(collectionsInterface.users, trip.creatorIdPost).then(
        (transporter) => {
          getTranslatedEmailMessage(
            notification.title,
            notification.text,
            transporter.idPost
          ).then(({ message, title }) => {
            sendMessageToUser(transporter, title, message);
          });
        }
      );
    });
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutPassengerReservation = async (
  transporterId,
  trip,
  tripOrder
) => {
  const transporter = await getDocInCollection(
    collectionsInterface.users,
    transporterId
  );
  const dispatcher = await getDocInCollection(
    collectionsInterface.users,
    tripOrder.creatorIdPost
  );

  try {
    const notification = createNotificationAboutPassengerReservation(
      transporter,
      trip,
      tripOrder
    );

    await saveNotificationToRdb(notification, dispatcher.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      dispatcher.idPost
    );

    await sendMessageToUser(dispatcher, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutTripReservation = async (
  transporterId,
  trip,
  tripOrder
) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      transporterId
    );

    const notification = createNotificationAboutTripReservation(
      trip,
      tripOrder
    );

    await saveNotificationToRdb(notification, transporter.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      transporter.idPost
    );

    await sendMessageToUser(transporter, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutReservationConfirmation = async (
  transporterId,
  trip,
  tripOrder,
  isDispatcher
) => {
  try {
    let notification = '';
    let dispatcher = userModel;
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      transporterId
    );
    if (isDispatcher) {
      notification = createNotificationAboutReservationConfirmation(
        trip,
        tripOrder,
        transporter,
        isDispatcher
      );
    } else {
      dispatcher = await getDocInCollection(
        collectionsInterface.users,
        tripOrder.creatorIdPost
      );

      notification = createNotificationAboutReservationConfirmation(
        trip,
        tripOrder,
        transporter,
        isDispatcher
      );
    }

    await saveNotificationToRdb(
      notification,
      isDispatcher ? transporter.idPost : tripOrder.creatorIdPost
    );

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      isDispatcher ? transporter.idPost : dispatcher.idPost
    );

    await sendMessageToUser(
      isDispatcher ? transporter : dispatcher,
      title,
      message
    );
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutReservationRejectionByDispatcher = async (
  trip,
  tripOrder,
  introductionIntro
) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification =
      createNotificationAboutReservationRejectionByDispatcher(
        trip,
        tripOrder,
        introductionIntro
      );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser(transporter, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutReservationRejectionByTransporter = async (
  trip,
  tripOrder,
  comment = ''
) => {
  try {
    const dispatcher = await getDocInCollection(
      collectionsInterface.users,
      tripOrder.creatorIdPost
    );

    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification =
      createNotificationAboutReservationRejectionByTransporter(
        trip,
        tripOrder,
        transporter,
        comment
      );

    await saveNotificationToRdb(notification, tripOrder.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      tripOrder.creatorIdPost
    );

    await sendMessageToUser(dispatcher, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutTripCancellationByDispatcher = async (
  trip,
  tripOrder
) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutTripCancellationByDispatcher(
      trip,
      tripOrder
    );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser(transporter, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutTripCancellationByTransporter = async (
  trip,
  tripOrder
) => {
  try {
    const dispatcher = await getDocInCollection(
      collectionsInterface.users,
      tripOrder.creatorIdPost
    );

    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutTripCancellationByTransporter(
      trip,
      tripOrder,
      transporter
    );

    await saveNotificationToRdb(notification, tripOrder.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      tripOrder.creatorIdPost
    );

    await sendMessageToUser(dispatcher, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutTripStart = async (trip, tripOrder) => {
  try {
    const dispatcher = await getDocInCollection(
      collectionsInterface.users,
      tripOrder.creatorIdPost
    );

    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutTripStart(
      trip,
      tripOrder,
      transporter
    );

    await saveNotificationToRdb(notification, tripOrder.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      tripOrder.creatorIdPost
    );

    await sendMessageToUser(dispatcher, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutTripEnd = async (trip, tripOrder) => {
  try {
    const dispatcher = await getDocInCollection(
      collectionsInterface.users,
      tripOrder.creatorIdPost
    );

    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutTripEnd(
      trip,
      tripOrder,
      transporter
    );

    await saveNotificationToRdb(notification, tripOrder.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      tripOrder.creatorIdPost
    );

    await sendMessageToUser(dispatcher, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutEvaluationByDispatcher = async (
  trip,
  tripOrder,
  evaluatedPersonId
) => {
  try {
    const driver = await getDocInCollection(
      collectionsInterface.users,
      evaluatedPersonId
    );

    const notification = createNotificationAboutEvaluationByDispatcher(
      trip,
      tripOrder,
      evaluatedPersonId
    );

    await saveNotificationToRdb(notification, driver.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      driver.idPost
    );

    await sendMessageToUser(driver, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutEvaluationByDriver = async (trip, tripOrder) => {
  try {
    const dispatcher = await getDocInCollection(
      collectionsInterface.users,
      tripOrder.creatorIdPost
    );

    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutEvaluationByDriver(
      trip,
      tripOrder,
      transporter
    );

    await saveNotificationToRdb(notification, tripOrder.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      tripOrder.creatorIdPost
    );

    await sendMessageToUser(dispatcher, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutDeposit = async (user, amount) => {
  try {
    const notification = createNotificationAboutDeposit(user.idPost, amount);

    await saveNotificationToRdb(notification, user.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      user.idPost
    );

    await sendMessageToUser(user, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageNotEnoughMoney = async (trip, tripOrder, amount) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationNotEnoughMoney(
      trip,
      tripOrder,
      amount
    );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser({ email: transporter.email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutMoneyFreezed = async (trip, tripOrder, amount) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationMoneyFreezed(
      trip,
      tripOrder,
      amount
    );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser({ email: transporter.email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutMoneyUnfreezed = async (
  trip,
  tripOrder,
  amount
) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationMoneyUnfreezed(
      trip,
      tripOrder,
      amount
    );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser({ email: transporter.email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutFeePaidToDispatcher = async (
  trip,
  tripOrder,
  amount
) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutFeePaidToDispatcher(
      trip,
      tripOrder,
      amount
    );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser(transporter, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutFeeReceived = async (trip, tripOrder, amount) => {
  try {
    const transporter = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );

    const notification = createNotificationAboutFeeReceived(
      trip,
      tripOrder,
      amount,
      transporter
    );

    await saveNotificationToRdb(notification, tripOrder.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      tripOrder.creatorIdPost
    );

    await sendMessageToUser(
      { email: tripOrder.dispatcherEmail },
      title,
      message
    );
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutCommissionTaking = async (
  trip,
  tripOrder,
  amount,
  email
) => {
  try {
    const notification = createNotificationAboutCommissionTaking(
      trip,
      tripOrder,
      amount
    );

    await saveNotificationToRdb(notification, trip.creatorIdPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      trip.creatorIdPost
    );

    await sendMessageToUser({ email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutBonusReceived = async (
  userId,
  registeredUser,
  amount
) => {
  try {
    const user = await getDocInCollection(collectionsInterface.users, userId);

    const notification = createNotificationAboutBonusReceived(
      userId,
      registeredUser,
      amount
    );

    await saveNotificationToRdb(notification, userId);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      userId
    );

    await sendMessageToUser(user, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutReferralRegistration = async (
  referrerId,
  registeredUser
) => {
  try {
    const referrer = await getDocInCollection(
      collectionsInterface.users,
      referrerId
    );

    const notification = createNotificationAboutReferralRegistration(
      referrerId,
      registeredUser
    );

    await saveNotificationToRdb(notification, referrerId);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      referrerId
    );

    await sendMessageToUser({ email: referrer.email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutBonusForReferralDeal = async (
  referrerId,
  referralName,
  amount
) => {
  try {
    const referrer = await getDocInCollection(
      collectionsInterface.users,
      referrerId
    );

    const notification = createNotificationAboutBonusReceivedForReferralDeal(
      referrerId,
      referralName,
      amount
    );

    await saveNotificationToRdb(notification, referrer.idPost);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      referrer.idPost
    );

    await sendMessageToUser({ email: referrer.email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutInvitationToCompany = async (
  companyName,
  driverId,
  invitationDate,
  email,
  emailTextForNewUser
) => {
  const title = 'Приглашение';
  let emailText = '';

  if (driverId) {
    const notification = createNotificationAboutInvitationToCompany(
      companyName,
      driverId,
      invitationDate,
      (emailText = '')
    );

    await saveNotificationToRdb(notification, driverId);

    const { message } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      driverId
    );

    emailText = message || '';
  } else {
    emailText = emailTextForNewUser;
  }

  await sendMessageToUser({ email }, title, emailText);
};

export const sendMessageAboutInvitationCancellation = async (
  companyName,
  driverId,
  email,
  isExisting
) => {
  try {
    const currentDate = Date.now();

    const notification = createNotificationAboutInvitationCancellation(
      companyName,
      driverId,
      currentDate
    );

    if (isExisting) {
      await saveNotificationToRdb(notification, driverId);
    }

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      driverId
    );

    await sendMessageToUser({ email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutInvitationRejection = async (
  driverName,
  companyId
) => {
  try {
    const company = await getDocInCollection(
      collectionsInterface.users,
      companyId
    );

    const currentDate = Date.now();

    const notification = createNotificationAboutInvitationRejection(
      driverName,
      companyId,
      currentDate
    );

    await saveNotificationToRdb(notification, companyId);

    const { message, title } = await getTranslatedEmailMessage(
      notification.title,
      notification.text,
      companyId
    );

    await sendMessageToUser({ email: company.email }, title, message);
  } catch (error) {
    return error;
  }
};

export const sendMessageAboutExchangeAccountingRequest = async (
  amount,
  creditorName,
  cardNumber,
  recipientId,
  recipientEmail
) => {
  const notification = createNotificationAboutExchangeAccountingRequest(
    amount,
    creditorName,
    cardNumber,
    recipientId
  );

  await saveNotificationToRdb(notification, recipientId);

  const { message, title } = await getTranslatedEmailMessage(
    notification.title,
    notification.text,
    recipientId
  );

  await sendMessageToUser({ email: recipientEmail }, title, message);
};
//#endregion
//#endregion

//#region Work with transactions
//#region Function for returning text and amount sign
export const getTextForTransaction = (transactionType, isRecipient = false) => {
  switch (transactionType) {
    case transactionsTypesInterface.accountDeposit:
      return 'Пополнение аккаунта через карту';
    case transactionsTypesInterface.accountWithdrawal:
      return 'Вывод средств';
    case transactionsTypesInterface.dispatcherFee:
      return isRecipient ? 'Гонорары от водителей' : 'Гонорары диспетчерам';
    case transactionsTypesInterface.commissionTaking:
      return 'Списание комиссии';
    case transactionsTypesInterface.bonuses:
      return 'Бонусы';
    case transactionsTypesInterface.bonusesForReferralDeal:
      return 'Бонусы';
  }
};

export const getSignForTransaction = (transactionType, isRecipient = false) => {
  switch (transactionType) {
    case transactionsTypesInterface.accountWithdrawal:
      return '-';
    case transactionsTypesInterface.dispatcherFee:
      if (!isRecipient) {
        return '-';
      } else {
        return '+';
      }
    case transactionsTypesInterface.commissionTaking:
      if (isRecipient) {
        return '+';
      } else {
        return '-';
      }
    case transactionsTypesInterface.bonusesForReferralDeal:
      if (isRecipient) {
        return '+';
      } else {
        return '-';
      }
    default:
      return '+';
  }
};

export const getClassesForTransactionAmount = (
  transactionType,
  isRecipient = false
) => {
  switch (transactionType) {
    case transactionsTypesInterface.accountWithdrawal:
      return (
        'TransactionsHistory-DescriptionAmount ' +
        'TransactionsHistory-DescriptionAmount_Negative'
      );
    case transactionsTypesInterface.dispatcherFee:
      if (!isRecipient) {
        return (
          'TransactionsHistory-DescriptionAmount ' +
          'TransactionsHistory-DescriptionAmount_Negative'
        );
      } else {
        return 'TransactionsHistory-DescriptionAmount';
      }
    case transactionsTypesInterface.commissionTaking:
      if (!isRecipient) {
        return (
          'TransactionsHistory-DescriptionAmount ' +
          'TransactionsHistory-DescriptionAmount_Negative'
        );
      } else {
        return 'TransactionsHistory-DescriptionAmount';
      }
    case transactionsTypesInterface.bonusesForReferralDeal:
      if (isRecipient) {
        return 'TransactionsHistory-DescriptionAmount';
      } else {
        return (
          'TransactionsHistory-DescriptionAmount ' +
          'TransactionsHistory-DescriptionAmount_Negative'
        );
      }
    case transactionsTypesInterface.bonuses:
    default:
      return 'TransactionsHistory-DescriptionAmount';
  }
};
//#endregion

export const createTransactionAndSaveToTransactions = async (
  transactionType,
  deal,
  recipient,
  payer
) => {
  try {
    const currentTime = Date.now();
    const currentDate = new Date().setHours(0, 0, 0, 0);

    let newTransaction = {
      type: transactionType,
      amount: deal?.dispatcherFee
        ? Number(deal?.dispatcherFee) * Number(deal?.reservedPlaces)
        : Number(deal?.amount),
      date: currentDate,
      exactTime: currentTime,
      recipient: {
        idPost: recipient.idPost,
        fullName: recipient.fullName,
      },
      payer: {
        idPost: payer.idPost,
        fullName: payer.fullName,
      },
      dealId: deal?.id || '',
      extraData: deal?.extraData || {},
      isSuccessful:
        transactionType !== transactionsTypesInterface.accountWithdrawal &&
        transactionType !== transactionsTypesInterface.accountDeposit,
      isVisibleForAdmin: true,
    };

    const saveResult = await setDocumentToCollection(
      collectionsInterface.transactions,
      newTransaction
    );

    newTransaction = {
      ...newTransaction,
      idPost: saveResult.result.id,
    };

    return newTransaction;
  } catch (error) {
    throw new Error(error);
  }
};

export const saveTransactionFromAdmin = async (
  event,
  transactionData,
  admin,
  user,
  setShowModal = () => {},
  updateBalanceAccount = () => {},
  setIsError = () => {},
  setErrorMessage = () => {},
  setIsLoading = () => {}
) => {
  event.preventDefault();

  setIsLoading(true);

  try {
    if (transactionData.amount <= 0) {
      setIsError(true);
      setErrorMessage('Сумма должна быть больше нуля');

      return;
    }

    let recipient = {};
    let payer = {};
    let amount = parseFloat(transactionData.amount);
    let isRecipient = true;
    let actualAccount = {};

    if (transactionData.amount < 0) {
      amount = Math.abs(amount);

      recipient = {
        fullName: admin.fullName,
        idPost: admin.idPost,
      };

      payer = {
        fullName: user.fullName,
        idPost: user.idPost,
        appAccountId: user.balanceAccount,
      };

      isRecipient = false;

      actualAccount = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        payer.appAccountId
      );
    } else {
      recipient = {
        fullName: user.fullName,
        idPost: user.idPost,
        appAccountId: user.balanceAccount,
      };
      payer = {
        fullName: admin.fullName,
        idPost: admin.idPost,
      };

      actualAccount = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        recipient.appAccountId
      );
    }

    const deal = {
      id: uuidv4(),
      amount,
    };

    const transaction = await createTransactionAndSaveToTransactions(
      transactionData.type.value,
      deal,
      recipient,
      payer
    );

    const newAmount =
      actualAccount.amount + (isRecipient ? amount : amount * -1);

    await updateFieldsInDocumentInCollection(
      collectionsInterface.balanceAccounts,
      actualAccount.idPost,
      {
        amount: newAmount,
        [`transactions.${transaction.idPost}`]: transaction,
      }
    );

    updateBalanceAccount((state) => ({
      ...state,
      amount: newAmount,
      [`transactions.${transaction.idPost}`]: transaction,
    }));

    setShowModal(false);
    return true;
  } catch (error) {
    return error;
  } finally {
    setIsLoading(false);
  }
};
//#endregion

//#region Work with trips and trips orders
export const findTripPointInTripByPlaceId = (placeId, curTrip) => {
  return curTrip?.tripPoints?.find((point) => point?.placeId === placeId);
};

export const findTripPointIndexInTripByPlaceId = (placeId, curTrip) => {
  return curTrip.tripPoints.findIndex((point) => point.placeId === placeId);
};

export const getRouteFromTrip = (startPlaceId, endPlaceId, curTrip) => {
  if (!startPlaceId || !endPlaceId || !curTrip) {
    return [];
  }

  const startIndex = findTripPointIndexInTripByPlaceId(startPlaceId, curTrip);
  const endIndex = findTripPointIndexInTripByPlaceId(endPlaceId, curTrip);

  return curTrip.tripPoints.slice(startIndex, endIndex + 1);
};

export const getTripAddOptionsFromTrip = (currentTrip, addOptionsList) => {
  if (currentTrip.addOptions.length > 0) {
    return currentTrip.addOptions;
  } else {
    return addOptionsList;
  }
};

export const getTripAddOptionsList = (currentTrip, addOptionsList) => {
  if (currentTrip.addOptions.length > 0) {
    const list = [];

    addOptionsList
      .filter((option) => option.isVisible)
      .forEach((option) => {
        if (
          currentTrip.addOptions.some(
            (addOption) =>
              addOption.optionContent?.defaultName ===
              option.optionContent?.defaultName
          )
        ) {
          list.push({
            ...option,
            checked: true,
          });
        } else {
          list.push(option);
        }
      });

    return list;
  } else {
    return addOptionsList.filter((option) => option.isVisible);
  }
};

export const findAllFreeTripsOrders = async () => {
  const allActiveTripsOrders = await getCollectionWhereKeyValue(
    collectionsInterface.tripsOrders,
    'isOpenForSearch',
    true
  );

  return allActiveTripsOrders;
};

export const findAllPassengerTripsOrders = async () => {
  const allFreeTripsOrders = await findAllFreeTripsOrders();

  return allFreeTripsOrders.filter((trip) => trip.isPassengerTrip);
};

export const findAllCargoTripsOrders = async () => {
  const allFreeTripsOrders = await findAllFreeTripsOrders();

  return allFreeTripsOrders.filter((trip) => !trip.isPassengerTrip);
};

export const findAllMyPassengerTripsOrders = async (myId) => {
  const allFreeTripsOrders = await getCollectionWhereKeysValues(
    collectionsInterface.tripsOrders,
    [
      {
        key: 'creatorIdPost',
        value: myId,
      },
      {
        key: 'isPassengerTrip',
        value: true,
      },
      {
        key: 'isOpenForSearch',
        value: true,
      },
    ],
    3
  );

  return allFreeTripsOrders;
};

export const findAllMyCargoTripsOrders = async (myId) => {
  const allFreeTripsOrders = await getCollectionWhereKeysValues(
    collectionsInterface.tripsOrders,
    [
      {
        key: 'creatorIdPost',
        value: myId,
      },
      {
        key: 'isPassengerTrip',
        value: false,
      },
      {
        key: 'isOpenForSearch',
        value: true,
      },
    ],
    3
  );

  return allFreeTripsOrders;
};

export const findTripOrdersWithSuitableDeparturePointAndTimeAfterQueryTime =
  async (startTripPoint, departureTime, isPassengerTrip) => {
    let allFreeTripsOrders = [];

    if (isPassengerTrip) {
      allFreeTripsOrders = await findAllPassengerTripsOrders();
    } else {
      allFreeTripsOrders = await findAllCargoTripsOrders();
    }

    return allFreeTripsOrders.filter(
      (trip) =>
        trip.startPoint.placeId === startTripPoint.placeId &&
        compareAsc(new Date(departureTime), new Date(trip.departureTime)) < 0
    );
  };

export const findTripOrdersWithSuitableDeparturePointAndExactDate = async (
  startTripPoint,
  departureTime,
  isPassengerTrip,
  areMyPassengers = false,
  myId = ''
) => {
  let allFreeTripsOrders = [];

  if (isPassengerTrip) {
    if (areMyPassengers) {
      allFreeTripsOrders = await findAllMyPassengerTripsOrders(myId);
    } else {
      allFreeTripsOrders = await findAllPassengerTripsOrders();
    }
  } else {
    if (areMyPassengers) {
      allFreeTripsOrders = await findAllMyCargoTripsOrders(myId);
    } else {
      allFreeTripsOrders = await findAllCargoTripsOrders();
    }
  }

  const tripOrdersWithSuitableStartPoint = allFreeTripsOrders.filter(
    (trip) => trip.startPoint.placeId === startTripPoint.placeId
  );

  const departureDate = getDateWithoutTime(new Date(departureTime));

  // return tripOrdersWithSuitableStartPoint.filter(trip => isEqual(
  //   new Date(departureDate), new Date(getDateWithoutTime(trip.departureTime))));

  return tripOrdersWithSuitableStartPoint.filter(
    (trip) => departureDate === getDateWithoutTime(trip.departureTime)
  );
};

export const findTripOrdersWithSuitableArrivingPoint = async (
  startTripPoint,
  endTripPoint,
  departureTime,
  isExactDate,
  isPassengerTrip,
  areMyPassengers,
  myId
) => {
  let tripsOrdersWithSuitableDeparturePoint = [];

  if (isExactDate) {
    tripsOrdersWithSuitableDeparturePoint =
      await findTripOrdersWithSuitableDeparturePointAndExactDate(
        startTripPoint,
        departureTime,
        isPassengerTrip,
        areMyPassengers,
        myId
      );
  } else {
    tripsOrdersWithSuitableDeparturePoint =
      await findTripOrdersWithSuitableDeparturePointAndTimeAfterQueryTime(
        startTripPoint,
        departureTime,
        isPassengerTrip,
        areMyPassengers,
        myId
      );
  }

  return tripsOrdersWithSuitableDeparturePoint.filter(
    (trip) => trip.endPoint.placeId === endTripPoint.placeId
  );
};

export const searchTripsOrders = async (
  startTripPoint,
  endTripPoint,
  departureTime,
  isPassengerTrip,
  isExactDate,
  areMyPassengers,
  myId
) => {
  const tripsWithSuitableArrivingPoint =
    await findTripOrdersWithSuitableArrivingPoint(
      startTripPoint,
      endTripPoint,
      departureTime,
      isExactDate,
      isPassengerTrip,
      areMyPassengers,
      myId
    );

  return tripsWithSuitableArrivingPoint;
};

export const findAllActiveTrips = async () => {
  const activeTrips = await getCollectionWhereKeysValues(
    collectionsInterface.trips,
    [
      {
        key: 'status',
        value: tripsStatusesInterface.openToSearch,
      },
      {
        key: 'isVisibleForDispatchers',
        value: true,
      },
      {
        key: 'isDraft',
        value: false,
      },
    ],
    3
  );

  return activeTrips;
};

export const findAllPassengerTrips = async (areMyTrips, user) => {
  const allFreeTrips = await findAllActiveTrips();

  let resultTrips = [];

  if (areMyTrips) {
    resultTrips = allFreeTrips.filter(
      (trip) => trip.isPassengerTrip && trip.creatorUid === user.uid
    );
  } else {
    resultTrips = allFreeTrips.filter(
      (trip) => trip.isPassengerTrip && trip.creatorUid !== user.uid
    );
  }

  return resultTrips;
};

export const findAllCargoTrips = async (areMyTrips, user) => {
  const allFreeTrips = await findAllActiveTrips();

  let resultTrips = [];

  if (areMyTrips) {
    resultTrips = allFreeTrips.filter(
      (trip) => !trip.isPassengerTrip && trip.creatorUid === user.uid
    );
  } else {
    resultTrips = allFreeTrips.filter(
      (trip) => !trip.isPassengerTrip && trip.creatorUid !== user.uid
    );
  }

  return resultTrips;
};

export const findTripsWithSuitableDeparturePointAndTimeAfterQueryTime = async (
  startTripPoint,
  departureTime,
  isPassengerTrip,
  areMyTrips,
  user
) => {
  let allFreeTrips = [];

  if (isPassengerTrip) {
    allFreeTrips = await findAllPassengerTrips(areMyTrips, user);
  } else {
    allFreeTrips = await findAllCargoTrips(areMyTrips, user);
  }

  const allTripsWithDeparturePoint = allFreeTrips.filter((trip) =>
    trip.tripPoints.find(
      (point, index, array) =>
        point.placeId === startTripPoint.placeId &&
        index < array.length - 1 &&
        compareAsc(new Date(departureTime), new Date(point.departureTime)) < 0
    )
  );

  return allTripsWithDeparturePoint;
};

export const findTripsWithSuitableDeparturePointAndExactDate = async (
  startTripPoint,
  departureTime,
  isPassengerTrip,
  areMyTrips,
  user
) => {
  let allFreeTrips = [];

  if (isPassengerTrip) {
    allFreeTrips = await findAllPassengerTrips(areMyTrips, user);
  } else {
    allFreeTrips = await findAllCargoTrips(areMyTrips, user);
  }

  const departureDate = getDateWithoutTime(new Date(departureTime));

  // const tripsWithSuitableStartPoint = allFreeTrips
  //   .filter(trip => trip.tripPoints.find((point, index, array) => (
  //     point.placeId === startTripPoint.placeId) && (index < array.length - 1) &&
  //     isEqual(new Date(departureDate), new Date(getDateWithoutTime(point
  //       .departureTime)))));

  const tripsWithSuitableStartPoint = allFreeTrips.filter((trip) =>
    trip.tripPoints.find(
      (point, index, array) =>
        point.placeId === startTripPoint.placeId &&
        index < array.length - 1 &&
        departureDate === getDateWithoutTime(point.departureTime)
    )
  );

  return tripsWithSuitableStartPoint;
};

export const finTripsWithArrivingPoint = async (
  startTripPoint,
  endTripPoint,
  departureTime,
  isExactDate,
  isPassengerTrip,
  areMyTrips,
  user
) => {
  let tripsWithSuitableDeparturePoint = [];

  if (isExactDate) {
    tripsWithSuitableDeparturePoint =
      await findTripsWithSuitableDeparturePointAndExactDate(
        startTripPoint,
        departureTime,
        isPassengerTrip,
        areMyTrips,
        user
      );
  } else {
    tripsWithSuitableDeparturePoint =
      await findTripsWithSuitableDeparturePointAndTimeAfterQueryTime(
        startTripPoint,
        departureTime,
        isPassengerTrip,
        areMyTrips,
        user
      );
  }

  const tripsWithArrivingPoint = tripsWithSuitableDeparturePoint.filter(
    (trip) =>
      trip.tripPoints.find(
        (point, index) => point.placeId === endTripPoint.placeId && index > 0
      )
  );

  return tripsWithArrivingPoint;
};

export const findTripsWithSuitableArrivingPoint = async (
  startTripPoint,
  endTripPoint,
  departureTime,
  isExactDate,
  isPassengerTrip,
  areMyTrips,
  user
) => {
  const tripsWithArrivingPoint = await finTripsWithArrivingPoint(
    startTripPoint,
    endTripPoint,
    departureTime,
    isExactDate,
    isPassengerTrip,
    areMyTrips,
    user
  );

  const tripsWithSuitableArrivingPoint = tripsWithArrivingPoint.filter(
    (trip) => {
      let startPointIndex = 0;
      let endPointIndex = 0;
      trip.tripPoints.forEach((point, index, array) => {
        if (point.placeId === startTripPoint.placeId) {
          startPointIndex = index;
          endPointIndex = array.findIndex(
            (suitableTrip) => suitableTrip.placeId === endTripPoint.placeId
          );
        }
      });

      if (endPointIndex > startPointIndex) {
        return true;
      }

      return false;
    }
  );

  return tripsWithSuitableArrivingPoint;
};

export const searchTrips = async (
  startTripPoint,
  endTripPoint,
  departureTime,
  isPassengerTrip,
  isExactDate,
  areMyTrips,
  user
) => {
  const suitableTrips = await findTripsWithSuitableArrivingPoint(
    startTripPoint,
    endTripPoint,
    departureTime,
    isExactDate,
    isPassengerTrip,
    areMyTrips,
    user
  );

  return suitableTrips;
};

export const searchSuitableTripsWithExactDate = async (
  startTripPoint,
  endTripPoint,
  departureTime,
  isPassengerTrip,
  isOrderSearch = false,
  areMyTrips,
  user,
  areMyPassengers
) => {
  let result = [];

  if (isOrderSearch) {
    result = await searchTripsOrders(
      startTripPoint,
      endTripPoint,
      departureTime,
      isPassengerTrip,
      true,
      areMyPassengers,
      user?.idPost
    );
  } else {
    result = await searchTrips(
      startTripPoint,
      endTripPoint,
      departureTime,
      isPassengerTrip,
      true,
      areMyTrips,
      user
    );
  }

  return result;
};

export const searchForTrips = async (
  tripOrder,
  isFromDispatcher,
  afterSearchActionFunction,
  user,
  areMyPassengers = false
) => {
  try {
    let searchResult = [];

    searchResult = await searchSuitableTripsWithExactDate(
      tripOrder?.startPoint,
      tripOrder?.endPoint,
      tripOrder?.departureTime,
      tripOrder?.isPassengerTrip,
      false,
      !isFromDispatcher,
      user,
      areMyPassengers
    );

    afterSearchActionFunction(searchResult);

    return true;
  } catch (error) {
    return false;
  }
};

export const findAllAvailableTripsOfOtherDriversWithExactDate = async (
  userIdPost,
  dateForSearch
) => {
  const suitableTrips = getCollectionWhereKeysValuesWithOperators(
    collectionsInterface.trips,
    [
      { key: 'creatorIdPost', value: userIdPost, operator: '!=' },
      { key: 'isOpenForSearch', value: true, operator: '==' },
      { key: 'isVisibleForDispatchers', value: true, operator: '==' },
      { key: 'startDateWithoutTime', value: dateForSearch, operator: '==' },
    ],
    4
  );

  return suitableTrips;
};

export const searchTripsForPassengerFromStartPointToEndPoint = async (
  tripOrder,
  userId,
  areMyTrips = false
) => {
  let allOpenTripsWithDate = [];

  try {
    if (areMyTrips) {
      allOpenTripsWithDate = await findAllMyAvailableTripsWithExactDate(
        userId,
        tripOrder.departureDateWithoutTime
      );
    } else {
      allOpenTripsWithDate =
        await findAllAvailableTripsOfOtherDriversWithExactDate(
          userId,
          tripOrder.departureDateWithoutTime
        );
    }

    const searchResult = findTripsForTripOrderFromStartToEnd(
      allOpenTripsWithDate,
      tripOrder
    );

    return searchResult;
  } catch (error) {
    return [];
  }
};

//#region Search functions after refactor (without dividing for mine and not
// mine)
export const findAllActiveTripsInDbCreatedByOtherUsers = async (
  userId,
  isPassengerTrip
) => {
  const trips =
    await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
      collectionsInterface.trips,
      [
        {
          key: 'isOpenForSearch',
          operator: '==',
          value: true,
        },
        {
          key: 'isVisibleForDispatchers',
          operator: '==',
          value: true,
        },
        {
          key: 'isArchived',
          operator: '==',
          value: false,
        },
        {
          key: 'isDraft',
          operator: '==',
          value: false,
        },
        {
          key: 'creatorIdPost',
          operator: '!=',
          value: userId,
        },
        {
          key: 'isPassengerTrip',
          operator: '==',
          value: isPassengerTrip,
        },
      ]
    );

  return trips.filter((trip) => trip.freePlaces !== 0);
};

export const findAllActiveTripsInDbCreatedByExactUser = async (
  userId,
  isPassengerTrip
) => {
  const trips =
    await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
      collectionsInterface.trips,
      [
        {
          key: 'isOpenForSearch',
          operator: '==',
          value: true,
        },
        {
          key: 'isArchived',
          operator: '==',
          value: false,
        },
        {
          key: 'isDraft',
          operator: '==',
          value: false,
        },
        {
          key: 'creatorIdPost',
          operator: '==',
          value: userId,
        },
        {
          key: 'isPassengerTrip',
          operator: '==',
          value: isPassengerTrip,
        },
        {
          key: 'freePlaces',
          operator: '!=',
          value: 0,
        },
      ]
    );

  return trips;
};

export const getTripsWithSuitableDeparturePointAndExactDate = (
  trips,
  departureTime,
  startPlaceId
) => {
  const departureDate = getDateWithoutTime(new Date(departureTime));

  // const tripsWithSuitableStartPoint = trips
  //   .filter(trip => trip.tripPoints.find((point, index, array) => (
  //       point.placeId === startPlaceId) && (index < array.length - 1) &&
  //     isEqual(new Date(departureDate), new Date(getDateWithoutTime(point
  //       .departureTime)))));

  const tripsWithSuitableStartPoint = trips.filter((trip) =>
    trip.tripPoints.find(
      (point, index, array) =>
        point.placeId === startPlaceId &&
        index < array.length - 1 &&
        departureDate === getDateWithoutTime(point.departureTime)
    )
  );

  return tripsWithSuitableStartPoint;
};

export const getTripsWithSuitableArrivingPoint = (trips, endPointPlaceId) => {
  const tripsWithArrivingPoint = trips.filter((trip) =>
    trip.tripPoints.find(
      (point, index) => point.placeId === endPointPlaceId && index > 0
    )
  );

  return tripsWithArrivingPoint;
};

export const getTripsWithSuitableDepartmentAndArrivingPoint = (
  trips,
  startPlaceId,
  endPlaceId
) => {
  const tripsWithSuitableArrivingPoint = trips.filter((trip) => {
    let startPointIndex = 0;
    let endPointIndex = 0;
    trip.tripPoints.forEach((point, index, array) => {
      if (point.placeId === startPlaceId) {
        startPointIndex = index;
        endPointIndex = array.findIndex(
          (suitableTrip) => suitableTrip.placeId === endPlaceId
        );
      }
    });

    if (endPointIndex > startPointIndex) {
      return true;
    }

    return false;
  });

  return tripsWithSuitableArrivingPoint;
};

export const searchTripsForTripOrder = async (
  tripOrder,
  userId,
  onlyOthers = false,
  onlyMine = false
) => {
  try {
    let allOtherUsersTrips = [];
    let allUsersTrips = [];

    if (!onlyMine) {
      allOtherUsersTrips = await findAllActiveTripsInDbCreatedByOtherUsers(
        userId,
        tripOrder.isPassengerTrip
      );
    }

    if (!onlyOthers) {
      allUsersTrips = await findAllActiveTripsInDbCreatedByExactUser(
        userId,
        tripOrder.isPassengerTrip
      );
    }

    const allTrips = [...allOtherUsersTrips, ...allUsersTrips];

    const tripsWithSuitableDeparturePoint =
      getTripsWithSuitableDeparturePointAndExactDate(
        allTrips,
        tripOrder.departureTime,
        tripOrder.startPoint.placeId
      );

    const tripsWithSuitableArrivingPoint = getTripsWithSuitableArrivingPoint(
      tripsWithSuitableDeparturePoint,
      tripOrder.endPoint.placeId
    );

    const searchResult = getTripsWithSuitableDepartmentAndArrivingPoint(
      tripsWithSuitableArrivingPoint,
      tripOrder.startPoint.placeId,
      tripOrder.endPoint.placeId
    );

    return searchResult;
  } catch (error) {
    return [];
  }
};

export const findAllSuitableTripsOrdersInDbCreatedByOtherUsers = async (
  userId,
  startPlaceId,
  endPlaceId,
  departureDate,
  isPassengerTrip
) => {
  const tripsOrders =
    await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
      collectionsInterface.tripsOrders,
      [
        {
          key: 'isOpenForSearch',
          operator: '==',
          value: true,
        },
        {
          key: 'isVisibleForDrivers',
          operator: '==',
          value: true,
        },
        {
          key: 'isCancelled',
          operator: '==',
          value: false,
        },
        {
          key: 'isDraft',
          operator: '==',
          value: false,
        },
        {
          key: 'creatorIdPost',
          operator: '!=',
          value: userId,
        },
        {
          key: 'isPassengerTrip',
          operator: '==',
          value: isPassengerTrip,
        },
        {
          key: 'departureDateWithoutTime',
          operator: '==',
          value: departureDate,
        },
        {
          key: 'startPoint.placeId',
          operator: '==',
          value: startPlaceId,
        },
        {
          key: 'endPoint.placeId',
          operator: '==',
          value: endPlaceId,
        },
      ]
    );

  return tripsOrders;
};

export const findAllSuitableTripsOrdersInDbCreatedByExactUser = async (
  userId,
  startPlaceId,
  endPlaceId,
  departureDate,
  isPassengerTrip
) => {
  const tripsOrders =
    await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
      collectionsInterface.tripsOrders,
      [
        {
          key: 'isOpenForSearch',
          operator: '==',
          value: true,
        },
        {
          key: 'isCancelled',
          operator: '==',
          value: false,
        },
        {
          key: 'isDraft',
          operator: '==',
          value: false,
        },
        {
          key: 'creatorIdPost',
          operator: '==',
          value: userId,
        },
        {
          key: 'isPassengerTrip',
          operator: '==',
          value: isPassengerTrip,
        },
        {
          key: 'departureDateWithoutTime',
          operator: '==',
          value: departureDate,
        },
        {
          key: 'startPoint.placeId',
          operator: '==',
          value: startPlaceId,
        },
        {
          key: 'endPoint.placeId',
          operator: '==',
          value: endPlaceId,
        },
      ]
    );

  return tripsOrders;
};

export const searchTripsOrdersByQuery = async (
  userId,
  startPlaceId,
  endPlaceId,
  departureDate,
  isPassengerTrip,
  onlyOthers = false,
  onlyMine = false
) => {
  try {
    let otherUsersTripsOrders = [];
    let usersTripsOrders = [];

    if (!onlyMine) {
      otherUsersTripsOrders =
        await findAllSuitableTripsOrdersInDbCreatedByOtherUsers(
          userId,
          startPlaceId,
          endPlaceId,
          departureDate,
          isPassengerTrip
        );
    }

    if (!onlyOthers) {
      usersTripsOrders = await findAllSuitableTripsOrdersInDbCreatedByExactUser(
        userId,
        startPlaceId,
        endPlaceId,
        departureDate,
        isPassengerTrip
      );
    }

    const allTripsOrders = [...otherUsersTripsOrders, ...usersTripsOrders];

    // return allTripsOrders.filter(order => isEqual(new Date(getDateWithoutTime(departureDate)),
    //   new Date(getDateWithoutTime(order.departureTime))));

    return allTripsOrders.filter(
      (order) =>
        getDateWithoutTime(departureDate) ===
        getDateWithoutTime(order.departureTime)
    );
  } catch (error) {
    return [];
  }
};
//#endregion

export const checkActiveReservations = (trip) => {
  if (!trip?.idPost) {
    return false;
  }

  const hasActiveReservations =
    trip.hasAnyReservations &&
    (trip.reservationsFromDispatchers.some(
      (reservation) => !reservation.isCancelled
    ) ||
      trip.reservationsFromDrivers.some(
        (reservation) => !reservation.isCancelled
      ));

  return hasActiveReservations;
};

export const compareStandardTripAddOptions = (trip, order) => {
  if (trip.isByFoot === true && order.isByFoot === false) {
    return false;
  }

  if (trip.isTripWithPets === false && order.isTripWithPets === true) {
    return false;
  }

  if (
    trip.isSeparatePlaceForLuggage === false &&
    order.isSeparatePlaceForLuggage === true
  ) {
    return false;
  }

  if (trip.isPassengerAddress === false && order.isPassengerAddress === true) {
    return false;
  }

  return true;
};

export const compareCustomTripAddOptions = (trip, order) => {
  if (order.addOptions.length === 0) {
    return true;
  }

  let res = true;

  order.addOptions.forEach((option) => {
    const appropriateOption = trip.addOptions.find(
      (tripOption) =>
        tripOption.optionContent?.defaultName ===
        option.optionContent?.defaultName
    );

    if (!appropriateOption) {
      res = false;
    } else {
      res = appropriateOption.checked;
    }
  });

  return res;
};

export const compareAllAddOptions = (trip, order) => {
  if (!compareStandardTripAddOptions(trip, order)) {
    return false;
  }

  if (!compareCustomTripAddOptions(trip, order)) {
    return false;
  }

  return true;
};

export const compareSchemeAndDesirablePlaces = (scheme, places) => {
  const occupiedPlaces = [];

  places.forEach((place) => {
    scheme.rows.forEach((curRow) => {
      if (
        curRow.row.some((cell) => cell.placeNumber === place && cell.isOccupied)
      ) {
        occupiedPlaces.push(place);
      }
    });
  });

  return occupiedPlaces;
};

export const getCommissionAmount = async (amount) => {
  const appInterestCollection = await getCollectionWhereKeysValues(
    collectionsInterface.adminData,
    [
      {
        key: 'dataType',
        value: adminDataTypesInterface.applicationFee,
      },
    ],
    1
  );

  const appInterest = parseFloat(appInterestCollection[0].text);
  const commission = (amount * appInterest) / 100;
  const commissionAmount = parseFloat(commission.toFixed(2));

  return commissionAmount;
};

export const getMoneyAmountToFreeze = async (amount) => {
  const commission = await getCommissionAmount(amount);

  const amountToFreeze = amount + commission;

  return amountToFreeze;
};

export const freezeFeeAfterReservationSubmit = async (amount, payerUid) => {
  const balanceAccountCollection = await getCollectionWhereKeysValues(
    collectionsInterface.balanceAccounts,
    [
      {
        key: 'ownerUid',
        value: payerUid,
      },
    ],
    1
  );

  const balanceAccount = balanceAccountCollection[0];

  const newAmount = balanceAccount.amount - amount;

  if (newAmount < 0) {
    return false;
  }

  const newReservedAmount = balanceAccount.reservedAmount + amount;

  await updateFieldsInDocumentInCollection(
    collectionsInterface.balanceAccounts,
    balanceAccount.idPost,
    {
      amount: newAmount,
      reservedAmount: newReservedAmount,
    }
  );

  return true;
};

export const unfreezeFeeAfterDealCancellation = async (amount, payerUid) => {
  const balanceAccounts = await getCollectionWhereKeysValues(
    collectionsInterface.balanceAccounts,
    [
      {
        key: 'ownerUid',
        value: payerUid,
      },
    ],
    1
  );

  const balanceAccount = balanceAccounts[0];

  const newAmount = balanceAccount.amount + amount;

  const newReservedAmount = balanceAccount.reservedAmount - amount;

  await updateFieldsInDocumentInCollection(
    collectionsInterface.balanceAccounts,
    balanceAccount.idPost,
    {
      amount: newAmount,
      reservedAmount: newReservedAmount,
    }
  );
};

export const getPreparedTripsByCreatorAndStartData = async (
  creatorUid,
  startDateWithoutTime
) => {
  const suitableTrips = await getCollectionWhereKeysValues(
    collectionsInterface.trips,
    [
      {
        key: 'creatorUid',
        value: creatorUid,
      },
      {
        key: 'startDateWithoutTime',
        value: startDateWithoutTime,
      },
      {
        key: 'isArchived',
        value: false,
      },
    ],
    3
  );

  return suitableTrips;
};

export const getStatusText = (status) => {
  let statusText = '';

  switch (status) {
    case tripsStatusesInterface.draft:
      statusText = 'Черновик';
      break;
    case tripsStatusesInterface.openToSearch:
      statusText = 'Активная';
      break;
    case tripsStatusesInterface.inProgress:
      statusText = 'В пути';
      break;
    case tripsStatusesInterface.completed:
      statusText = 'Завершена';
      break;
    case tripsStatusesInterface.canceled:
      statusText = 'Отменена';
      break;
    case tripsStatusesInterface.departuring:
      statusText = 'Выезжает';
      break;
    default:
      statusText = 'Черновик';
      break;
  }

  return statusText;
};

export const getStatusTextFromTripOrder = (tripOrder) => {
  let statusText = '';

  switch (true) {
    case tripOrder.isDraft:
      statusText = 'Черновик';
      break;
    case tripOrder.isOpenForSearch || tripOrder.isActive:
      statusText = 'Активная';
      break;
    case tripOrder.isInProgress:
      statusText = 'В пути';
      break;
    case tripOrder.isCancelled:
      statusText = 'Отменена';
      break;
    case tripOrder.isCompleted:
      statusText = 'Завершена';
      break;
    default:
      statusText = 'Черновик';
      break;
  }

  return statusText;
};

// export const getBoardingAddressesTranslation = async (boardingPlacesFromDriver,
//   lang) => {
//   const boardingPlaces = [];
//
//   try {
//     for (const place of boardingPlacesFromDriver) {
//       const currentPlace = await getLocationIqPlaceDetails(place.lat, place.lon,
//         lang, placesTypes.building);
//
//       boardingPlaces.push({
//         placeId: currentPlace?.placeId || place.placeId,
//         fullAddress: currentPlace?.fullAddress || place.fullAddress,
//       });
//     }
//   } catch (error) {
//     return error;
//   } finally {
//     return boardingPlaces;
//   }
// }

export const getDriverCompany = async (driver) => {
  if (driver.attachedToCompany) {
    const company = await getDocInCollection(
      collectionsInterface.users,
      driver.attachedToCompany
    );

    return company;
  } else {
    return driver;
  }
};

export const getPassengerDepartureAddressTranslation = async (
  currentTrip,
  currentReservation,
  lang
) => {
  if (
    currentTrip?.isPassengerTrip &&
    currentReservation?.passengerDepartureAddress?.hasOwnProperty('fullAddress')
  ) {
    const place = await getLocationIqPlaceDetails(
      currentReservation?.passengerDepartureAddress?.lat,
      currentReservation?.passengerDepartureAddress?.lon,
      lang,
      placesTypes.building
    );

    return (
      place?.fullAddress ||
      currentReservation?.passengerDepartureAddress.fullAddress
    );
  }

  return '';
};

export const getPriceForReservation = (trip, tripOrder, initiatorType) => {
  let price = tripOrder.pricePerPerson;

  if (
    trip.startPoint.placeId === tripOrder.startPoint.placeId &&
    trip.endPoint.placeId === tripOrder.endPoint.placeId
  ) {
    if (tripOrder.pricePerPerson !== trip.pricePerPerson) {
      if (initiatorType === 'dispatcher') {
        price = trip.pricePerPerson;
      }
    }
  }

  return parseInt(price);
};

export const takeCommission = async (deal, payer) => {
  const recipient = await getDocInCollection(
    collectionsInterface.users,
    superAdmin.idPost
  );

  const transaction = await createTransactionAndSaveToTransactions(
    transactionsTypesInterface.commissionTaking,
    deal,
    recipient,
    payer
  );

  const payerAccount = await getDocInCollection(
    collectionsInterface.balanceAccounts,
    payer.balanceAccount
  );

  await updateFieldsInDocumentInCollection(
    collectionsInterface.balanceAccounts,
    payer.balanceAccount,
    {
      reservedAmount: payerAccount.reservedAmount - transaction.amount,
      [`transactions.${transaction.idPost}`]: transaction,
    }
  );

  const recipientAccount = await getDocInCollection(
    collectionsInterface.balanceAccounts,
    superAdmin.balanceAccount
  );

  await updateFieldsInDocumentInCollection(
    collectionsInterface.balanceAccounts,
    superAdmin.balanceAccount,
    {
      amount: recipientAccount.amount + transaction.amount,
      [`transactions.${transaction.idPost}`]: transaction,
    }
  );
};

export const getBonusAmount = async (dealAmount) => {
  const bonusCollection = await getCollectionWhereKeysValues(
    collectionsInterface.adminData,
    [
      {
        key: 'dataType',
        value: adminDataTypesInterface.dispatcherBonusPercentage,
      },
    ],
    1
  );

  const bonusPercentage = parseFloat(bonusCollection[0].text);

  const bonusAmount = (dealAmount * bonusPercentage) / 100;

  return bonusAmount;
};

export const provideBonus = async (deal, recipient, payer) => {
  const transaction = await createTransactionAndSaveToTransactions(
    transactionsTypesInterface.bonusesForReferralDeal,
    deal,
    recipient,
    payer
  );

  const payerAccount = await getDocInCollection(
    collectionsInterface.balanceAccounts,
    payer.balanceAccount
  );

  await updateFieldsInDocumentInCollection(
    collectionsInterface.balanceAccounts,
    payer.balanceAccount,
    {
      amount: payerAccount.reservedAmount - transaction.amount,
      [`transactions.${transaction.idPost}`]: transaction,
    }
  );

  const recipientAccount = await getDocInCollection(
    collectionsInterface.balanceAccounts,
    recipient.balanceAccount
  );

  await updateFieldsInDocumentInCollection(
    collectionsInterface.balanceAccounts,
    recipient.balanceAccount,
    {
      amount: recipientAccount.amount + transaction.amount,
      [`transactions.${transaction.idPost}`]: transaction,
    }
  );
};

export const takeCommissionAndProvideBonus = async (deal, payer) => {
  let commissionAmount = await getCommissionAmount(deal.amount);
  let bonusAmount = 0;

  if (payer.referrerId) {
    bonusAmount = await getBonusAmount(deal.amount);

    if (bonusAmount > commissionAmount) {
      bonusAmount = commissionAmount;
    }

    commissionAmount = commissionAmount - bonusAmount;

    const bonusRecipient = await getDocInCollection(
      collectionsInterface.users,
      payer.referrerId
    );

    await provideBonus(
      {
        amount: bonusAmount,
        id: deal.id,
      },
      {
        balanceAccount: bonusRecipient.balanceAccount,
        fullName: bonusRecipient.fullName,
        idPost: bonusRecipient.idPost,
      },
      payer
    );
  }

  await takeCommission(
    {
      amount: commissionAmount,
      id: deal.id,
    },
    payer
  );

  return {
    commissionAmount,
    bonusAmount,
  };
};

export const checkDealInfoAndMakeAccounting = async (deal, tripOrder, trip) => {
  try {
    let transaction;
    let recipient;
    let payer;

    if (deal?.type === transactionsTypesInterface.accountDeposit) {
      recipient = {
        idPost: tripOrder.idPost,
        fullName: tripOrder.fullName,
      };

      payer = {
        idPost: trip.idPost,
        fullName: trip.fullName,
      };

      transaction = await createTransactionAndSaveToTransactions(
        transactionsTypesInterface.accountDeposit,
        deal,
        recipient,
        payer
      );

      const actualAccount = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        deal.appAccountId
      );

      const newAmount = actualAccount.amount + deal.amount;

      await updateFieldsInDocumentInCollection(
        collectionsInterface.balanceAccounts,
        actualAccount.idPost,
        {
          amount: newAmount,
          [`transactions.${transaction.idPost}`]: transaction,
        }
      );

      return true;
    }

    if (deal?.type === transactionsTypesInterface.bonuses) {
      recipient = {
        idPost: tripOrder.idPost,
        fullName: tripOrder.fullName,
      };

      payer = {
        idPost: trip.idPost,
        fullName: trip.fullName,
      };

      transaction = await createTransactionAndSaveToTransactions(
        transactionsTypesInterface.bonuses,
        deal,
        recipient,
        payer
      );

      const actualAccount = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        deal.appAccountId
      );

      const newAmount = actualAccount.amount + deal.amount;

      await updateFieldsInDocumentInCollection(
        collectionsInterface.balanceAccounts,
        actualAccount.idPost,
        {
          amount: newAmount,
          [`transactions.${transaction.idPost}`]: transaction,
        }
      );

      return true;
    }

    recipient = await getDocInCollection(
      collectionsInterface.users,
      tripOrder.creatorIdPost
    );
    payer = await getDocInCollection(
      collectionsInterface.users,
      trip.creatorIdPost
    );
    let newDebtors;
    let newCreditors;

    if (deal?.isForChange) {
      transaction = await createTransactionAndSaveToTransactions(
        transactionsTypesInterface.exchange,
        deal,
        recipient,
        payer
      );

      const payerAsCreditor = recipient?.creditors?.find(
        (creditor) => creditor.idPost === payer.idPost
      );

      const payerAsDebtor = recipient?.debtors?.find(
        (debtor) => debtor.idPost === payer.idPost
      );

      let newBalance = 0;

      if (payerAsCreditor) {
        newBalance = payerAsCreditor.amount - transaction.amount;

        if (newBalance === 0) {
          newCreditors = recipient.creditors.filter(
            (creditor) => creditor.idPost !== payer.idPost
          );
          newDebtors = payer.debtors.filter(
            (debtor) => debtor.idPost !== recipient.idPost
          );

          await updateFieldInDocumentInCollection(
            collectionsInterface.users,
            payer.idPost,
            'debtors',
            newDebtors
          );

          await updateFieldInDocumentInCollection(
            collectionsInterface.users,
            recipient.idPost,
            'creditors',
            newCreditors
          );

          return;
        }

        if (newBalance > 0) {
          newCreditors = recipient.creditors.map((creditor) =>
            creditor.idPost !== payer.idPost
              ? creditor
              : {
                  ...creditor,
                  amount: newBalance,
                }
          );

          newDebtors = payer.debtors.map((debtor) =>
            debtor.idPost !== recipient.idPost
              ? debtor
              : {
                  ...debtor,
                  amount: newBalance,
                }
          );

          await updateFieldInDocumentInCollection(
            collectionsInterface.users,
            payer.idPost,
            'debtors',
            newDebtors
          );

          await updateFieldInDocumentInCollection(
            collectionsInterface.users,
            recipient.idPost,
            'creditors',
            newCreditors
          );

          return;
        }

        if (newBalance < 0) {
          newCreditors = recipient.creditors.filter(
            (creditor) => creditor.idPost !== payer.idPost
          );
          newDebtors = payer.debtors.filter(
            (debtor) => debtor.idPost !== recipient.idPost
          );

          const newCreditorsOfPayer = [
            ...payer.creditors,
            {
              idPost: recipient.idPost,
              amount: -newBalance,
              fullName: recipient.fullName,
            },
          ];

          const newDebtorsOfRecipient = [
            ...recipient.debtors,
            {
              idPost: payer.idPost,
              amount: -newBalance,
              fullName: payer.fullName,
            },
          ];

          await updateFieldsInDocumentInCollection(
            collectionsInterface.users,
            payer.idPost,
            {
              debtors: newDebtors,
              creditors: newCreditorsOfPayer,
            }
          );

          await updateFieldsInDocumentInCollection(
            collectionsInterface.users,
            recipient.idPost,
            {
              creditors: newCreditors,
              debtors: newDebtorsOfRecipient,
            }
          );

          return;
        }
      } else {
        if (payerAsDebtor) {
          newBalance = payerAsDebtor.amount + transaction.amount;

          newCreditors = payer.creditors.map((creditor) =>
            creditor.idPost !== recipient.idPost
              ? creditor
              : {
                  ...creditor,
                  amount: newBalance,
                }
          );

          newDebtors = recipient.debtors.map((debtor) =>
            debtor.idPost !== payer.idPost
              ? debtor
              : {
                  ...debtor,
                  amount: newBalance,
                }
          );
        } else {
          newBalance = transaction.amount;

          newCreditors = [
            ...payer.creditors,
            {
              idPost: recipient.idPost,
              amount: newBalance,
              fullName: recipient.fullName,
            },
          ];

          newDebtors = [
            ...recipient.debtors,
            {
              idPost: payer.idPost,
              amount: newBalance,
              fullName: payer.fullName,
            },
          ];
        }

        await updateFieldInDocumentInCollection(
          collectionsInterface.users,
          recipient.idPost,
          'debtors',
          newDebtors
        );

        await updateFieldInDocumentInCollection(
          collectionsInterface.users,
          payer.idPost,
          'creditors',
          newCreditors
        );

        return true;
      }
    }

    if (deal?.isPaymentOnCard) {
      transaction = await createTransactionAndSaveToTransactions(
        transactionsTypesInterface.dispatcherFee,
        deal,
        recipient,
        payer
      );

      const payerAccount = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        payer.balanceAccount
      );

      if (payerAccount.reservedAmount < transaction.amount) {
        return;
      }

      await updateFieldsInDocumentInCollection(
        collectionsInterface.balanceAccounts,
        payer.balanceAccount,
        {
          reservedAmount: payerAccount.reservedAmount - transaction.amount,
          [`transactions.${transaction.idPost}`]: transaction,
        }
      );

      const recipientAccount = await getDocInCollection(
        collectionsInterface.balanceAccounts,
        recipient.balanceAccount
      );

      await updateFieldsInDocumentInCollection(
        collectionsInterface.balanceAccounts,
        recipient.balanceAccount,
        {
          amount: recipientAccount.amount + transaction.amount,
          [`transactions.${transaction.idPost}`]: transaction,
        }
      );

      await updateFieldInDocumentInCollection(
        collectionsInterface.tripsOrders,
        tripOrder.idPost,
        'isFeePaid',
        true
      );

      const commissionResult = await takeCommissionAndProvideBonus(
        {
          amount: deal.dispatcherFee * deal.reservedPlaces,
          id: deal.id,
        },
        {
          balanceAccount: payer.balanceAccount,
          referrerId: payer.referrerId,
          idPost: payer.idPost,
          fullName: payer.fullName,
        }
      );

      await sendMessageAboutFeeReceived(trip, tripOrder, transaction.amount);

      await sendMessageAboutFeePaidToDispatcher(
        trip,
        tripOrder,
        transaction.amount
      );

      await sendMessageAboutCommissionTaking(
        trip,
        tripOrder,
        commissionResult.commissionAmount + commissionResult.bonusAmount,
        payer.email
      );

      if (commissionResult.bonusAmount) {
        await sendMessageAboutBonusForReferralDeal(
          payer.referrerId,
          payer.fullName,
          commissionResult.bonusAmount
        );
      }

      return true;
    }
  } catch (error) {
    return false;
  }
};

export const getActualTripOrderAndChangeStatusAndSendMessage = async (
  tripOrderId,
  newStatus,
  trip,
  deal,
  currentTime,
  shouldBeCheckedForTransfer = false
) => {
  try {
    const actualTripOrder = await getDocInCollection(
      collectionsInterface.tripsOrders,
      tripOrderId
    );

    switch (newStatus) {
      case tripsStatusesInterface.inProgress:
        await updateFieldsInDocumentInCollection(
          collectionsInterface.tripsOrders,
          tripOrderId,
          {
            isOpenForSearch: false,
            isInProgress: true,
          }
        );

        await sendMessageAboutTripStart(trip, actualTripOrder);

        break;
      case tripsStatusesInterface.completed:
        await updateFieldsInDocumentInCollection(
          collectionsInterface.tripsOrders,
          tripOrderId,
          {
            isActive: false,
            isInProgress: false,
            isArchived: true,
            isCompleted: true,
            completionTime: currentTime,
          }
        );

        await sendMessageAboutTripEnd(trip, actualTripOrder);

        if (!deal.isPaymentByCash && !deal.isPassengerInOwnTrip) {
          await checkDealInfoAndMakeAccounting(deal, actualTripOrder, trip);
        }

        break;
      case tripsStatusesInterface.canceled:
        await cancelAcceptedDeal(
          actualTripOrder,
          deal,
          false,
          'Поездка отменена',
          trip.creatorIdPost,
          trip.idPost,
          () => {},
          false
        );

        break;
    }

    if (shouldBeCheckedForTransfer && actualTripOrder.isTransferred) {
      const actualTripOrderSource = await getDocInCollection(
        collectionsInterface.tripsOrders,
        actualTripOrder.sourceTripOrderId
      );

      switch (newStatus) {
        case tripsStatusesInterface.inProgress:
          await updateFieldsInDocumentInCollection(
            collectionsInterface.tripsOrders,
            actualTripOrderSource.idPost,
            {
              isOpenForSearch: false,
              isInProgress: true,
            }
          );

          break;
        case tripsStatusesInterface.completed:
          await updateFieldsInDocumentInCollection(
            collectionsInterface.tripsOrders,
            actualTripOrderSource.idPost,
            {
              isActive: false,
              isInProgress: false,
              isArchived: true,
              isCompleted: true,
            }
          );

          await checkDealInfoAndMakeAccounting(
            actualTripOrderSource.acceptedDeal,
            actualTripOrderSource,
            actualTripOrderSource.acceptedDeal?.originalTrip
          );
          break;
        case tripsStatusesInterface.canceled:
          await updateFieldsInDocumentInCollection(
            collectionsInterface.tripsOrders,
            actualTripOrderSource.idPost,
            {
              isOpenForSearch: true,
              acceptedDeal: {},
            }
          );

          const actualSourceTrip = await getDocInCollection(
            collectionsInterface.trips,
            actualTripOrderSource.acceptedDeal.originalTrip.idPost
          );

          const updatedTransferredDeals = actualSourceTrip.transferredDeals.map(
            (tDeal) =>
              tDeal.id !== deal.id
                ? tDeal
                : {
                    ...tDeal,
                    isCancelled: true,
                    cancellationInitiatorId: deal.originalTrip.creatorIdPost,
                    cancellationInitiatorRole: 'driver',
                  }
          );

          await updateFieldInDocumentInCollection(
            collectionsInterface.trips,
            actualTripOrderSource.acceptedDeal?.originalTrip.idPost,
            'transferredDeals',
            updatedTransferredDeals
          );

          break;
      }
    }
  } catch (error) {
    return error;
  }
};

export const changeStatusForAllPassengersInTrip = async (
  trip,
  newStatus,
  currentTime
) => {
  try {
    const deals = [...trip.acceptedDeals, ...trip.transferredDeals];

    for (const deal of deals) {
      if (!deal.isCancelled) {
        await getActualTripOrderAndChangeStatusAndSendMessage(
          deal.id,
          newStatus,
          trip,
          deal,
          currentTime,
          trip?.hasTransferredInPassengers
        );
      }
    }
  } catch (error) {
    return error;
  }
};

export const addTripToDriversTrips = async (drivers) => {
  let actualDriver;
  let updatedAmount = 0;

  try {
    for (let i = 0; i < drivers.length; i++) {
      actualDriver = await getDocInCollection(
        collectionsInterface.users,
        drivers[i].idPost
      );

      updatedAmount = actualDriver.tripsAsDriver + 1;

      await updateFieldInDocumentInCollection(
        collectionsInterface.users,
        drivers[i].idPost,
        'tripsAsDriver',
        updatedAmount
      );
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const updateReservationsInTripOrderAndSendMessage = async (
  reservation,
  fieldName,
  trip
) => {
  const actualTripOrder = await getDocInCollection(
    collectionsInterface.tripsOrders,
    reservation.originalTripOrder.idPost
  );

  const updatedReservations = actualTripOrder[fieldName].map(
    (orderReservation) =>
      orderReservation.originalTrip.idPost === reservation.originalTrip.idPost
        ? reservation
        : orderReservation
  );

  await updateFieldInDocumentInCollection(
    collectionsInterface.tripsOrders,
    actualTripOrder.idPost,
    fieldName,
    updatedReservations
  );

  await sendMessageAboutReservationRejectionByTransporter(
    trip,
    actualTripOrder,
    'Перевозчик выбрал другого пассажира вместо Вашего пассажира'
  );
};

export const cancelReservationsAfterTripOrderSubmit = async (
  submittedReservation,
  actualTripOrder,
  isDispatcher
) => {
  // setIsLoading(true);

  const updatedReservationsFromDispatchersInTripOrder =
    actualTripOrder.reservationsFromDispatchers.map((reservation) =>
      reservation.uuid === submittedReservation.uuid
        ? submittedReservation
        : {
            ...reservation,
            isCancelled: true,
            cancelComment: 'Выбран другой контрагент',
          }
    );

  const updatedReservationsFromDriversInTripOrder =
    actualTripOrder.reservationsFromDrivers.map((reservation) =>
      reservation.uuid === submittedReservation.uuid
        ? submittedReservation
        : {
            ...reservation,
            isCancelled: true,
            cancelComment: 'Выбран другой контрагент',
          }
    );

  try {
    const updatedFieldsInTripOrder = {
      reservationsFromDispatchers:
        updatedReservationsFromDispatchersInTripOrder,
      reservationsFromDrivers: updatedReservationsFromDriversInTripOrder,
    };

    await updateFieldsInDocumentInCollection(
      collectionsInterface.tripsOrders,
      actualTripOrder.idPost,
      updatedFieldsInTripOrder
    );

    const allReservations = [
      ...updatedReservationsFromDispatchersInTripOrder,
      ...updatedReservationsFromDriversInTripOrder,
    ];

    for (const reservation of allReservations) {
      if (!reservation?.isTotallyAccepted) {
        const actualTrip = await getDocInCollection(
          collectionsInterface.trips,
          reservation.originalTrip.idPost
        );

        let fieldToUpdate = '';

        if (reservation.initiator === 'dispatcher') {
          fieldToUpdate = 'reservationsFromDispatchers';
        } else {
          fieldToUpdate = 'reservationsFromDrivers';
        }

        const updatedReservations = actualTrip[fieldToUpdate].map((reserv) =>
          reserv.uuid === reservation.uuid
            ? {
                ...reserv,
                isCancelled: true,
                cancelComment: 'Выбран другой контрагент',
              }
            : reserv
        );

        await updateFieldInDocumentInCollection(
          collectionsInterface.trips,
          actualTrip.idPost,
          fieldToUpdate,
          updatedReservations
        );

        if (isDispatcher) {
          await sendMessageAboutReservationRejectionByDispatcher(
            actualTrip,
            actualTripOrder,
            'Диспетчер выбрал другого исполнителя по пассажиру'
          );
        } else {
          await sendMessageAboutReservationRejectionByTransporter(
            actualTrip,
            actualTripOrder,
            'Перевозчик выбрал другого пассажира вместо Вашего пассажира'
          );
        }
      }
    }

    return {
      ...actualTripOrder,
      ...updatedFieldsInTripOrder,
    };
  } catch (error) {
    return error;
  } finally {
    // setIsLoading(false);
  }
};

export const getAllDealsInTripAsCancelled = (
  deals,
  userId,
  cancelComment = ''
) => {
  const updatedDeals = deals.map((deal) => ({
    ...deal,
    isCancelled: true,
    cancellationInitiatorRole: 'driver',
    cancellationInitiatorId: userId,
    cancelComment: cancelComment,
  }));

  return updatedDeals;
};

export const handleChangeTripStatus = async (user, trip, newStatus) => {
  try {
    const currentTime = Date.now();

    let fieldsToUpdateInTrip = {};
    let actualTrip;

    switch (newStatus) {
      case tripsStatusesInterface.departuring:
        fieldsToUpdateInTrip = {
          status: tripsStatusesInterface.departuring,
        };

        await updateFieldsInDocumentInCollection(
          collectionsInterface.trips,
          trip.idPost,
          fieldsToUpdateInTrip
        );
        break;
      case tripsStatusesInterface.inProgress:
        actualTrip = await getDocInCollection(
          collectionsInterface.trips,
          trip.idPost
        );

        const cancelledReservationsFromDispatchers = [];
        const cancelledReservationsFromDrivers = [];

        const updatedReservationsFromDispatchers =
          actualTrip.reservationsFromDispatchers.map((reservation) => {
            if (!reservation.isTotallyAccepted && !reservation.isCancelled) {
              const updatedReservation = {
                ...reservation,
                isCancelled: true,
                cancelComment: 'Выбран другой контрагент',
                dateEditing: currentTime,
              };

              cancelledReservationsFromDispatchers.push(updatedReservation);
            } else return reservation;
          });

        const updatedReservationsFromDrivers =
          actualTrip.reservationsFromDrivers.map((reservation) => {
            if (!reservation.isTotallyAccepted && !reservation.isCancelled) {
              const updatedReservation = {
                ...reservation,
                isCancelled: true,
                cancelComment: 'Выбран другой контрагент',
                dateEditing: currentTime,
              };
              cancelledReservationsFromDrivers.push(updatedReservation);
            } else return reservation;
          });

        fieldsToUpdateInTrip = {
          isInProgress: true,
          isOpenForSearch: false,
          status: tripsStatusesInterface.inProgress,
          reservationsFromDispatchers: updatedReservationsFromDispatchers,
          reservationsFromDrivers: updatedReservationsFromDrivers,
        };

        await updateFieldsInDocumentInCollection(
          collectionsInterface.trips,
          trip.idPost,
          fieldsToUpdateInTrip
        );

        await changeStatusForAllPassengersInTrip(trip, newStatus);

        for (const reservation of cancelledReservationsFromDispatchers) {
          await updateReservationsInTripOrderAndSendMessage(
            reservation,
            'reservationsFromDispatchers',
            actualTrip
          );
        }

        for (const reservation of cancelledReservationsFromDrivers) {
          await updateReservationsInTripOrderAndSendMessage(
            reservation,
            'reservationsFromDrivers',
            actualTrip
          );
        }

        getLocationAndSaveToDb(user, trip);
        break;
      case tripsStatusesInterface.completed:
        fieldsToUpdateInTrip = {
          isInProgress: false,
          isActive: false,
          isArchived: true,
          isCompleted: true,
          completionTime: currentTime,
          status: tripsStatusesInterface.completed,
        };

        await updateFieldsInDocumentInCollection(
          collectionsInterface.trips,
          trip.idPost,
          fieldsToUpdateInTrip
        );

        if (trip.acceptedDeals?.some((deal) => !deal.isPassengerInOwnTrip)) {
          await addTripToDriversTrips(trip.drivers);
        }

        await changeStatusForAllPassengersInTrip(trip, newStatus, currentTime);

        break;
      case tripsStatusesInterface.canceled:
        actualTrip = await getDocInCollection(
          collectionsInterface.trips,
          trip.idPost
        );

        const updatedDeals = getAllDealsInTripAsCancelled(
          actualTrip.acceptedDeals,
          user.idPost,
          'Поездка отменена'
        );

        fieldsToUpdateInTrip = {
          acceptedDeals: updatedDeals,
          isOpenForSearch: false,
          isActive: false,
          isArchived: true,
          isCancelled: true,
          completionTime: currentTime,
          status: tripsStatusesInterface.canceled,
        };

        await updateFieldsInDocumentInCollection(
          collectionsInterface.trips,
          trip.idPost,
          fieldsToUpdateInTrip
        );

        await changeStatusForAllPassengersInTrip(
          actualTrip,
          newStatus,
          currentTime
        );
        break;
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const getPassengerDepartureText = (reservation, trip, translate) => {
  if (trip?.isBoardingByDriverAddresses) {
    return translate('По адресу водителя');
  } else {
    return translate('По адресу пассажира');
  }
};

export const getDataFromTripOrderToSaveInReservation = (tripOrder) => {
  const originalTripOrder = {
    addOptions: tripOrder.addOptions,
    cargoDescription: tripOrder.cargoDescription,
    creatorIdPost: tripOrder.creatorIdPost,
    dateCreating: tripOrder.dateCreating,
    departureAddress: tripOrder.departureAddress,
    departureDateWithoutTime: tripOrder.departureDateWithoutTime,
    departureTime: tripOrder.departureTime,
    dispatcherFee: tripOrder.dispatcherFee,
    dispatcherFullName: tripOrder.dispatcherFullName,
    endPoint: tripOrder.endPoint,
    idPost: tripOrder.idPost,
    isByFoot: tripOrder.isByFoot,
    isForChange: tripOrder.isForChange,
    isPaymentByCash: tripOrder.isPaymentByCash,
    isPaymentOnCard: tripOrder.isPaymentOnCard,
    isPassengerAddress: tripOrder.isPassengerAddress,
    isPassengerTrip: tripOrder.isPassengerTrip,
    isSeparatePlaceForLuggage: tripOrder.isSeparatePlaceForLuggage,
    isSeparatePlaceForPets: tripOrder.isSeparatePlaceForPets,
    isTransferred: tripOrder.isTransferred,
    isTripWithPets: tripOrder.isTripWithPets,
    passengerName: tripOrder.passengerName,
    passengerPhones: tripOrder.passengerPhones,
    requiredNumberOfPlaces: tripOrder.requiredNumberOfPlaces,
    sourceTripOrderId: tripOrder.sourceTripOrderId,
    startPoint: tripOrder.startPoint,
    pricePerPerson: tripOrder.pricePerPerson,
    tripOrderComment: tripOrder.tripOrderComment || '',
    wasTransferred: tripOrder.wasTransferred,
  };

  return originalTripOrder;
};

export const getDataFromTripToSaveInReservation = (trip) => {
  const originalTrip = {
    addOptions: trip.addOptions,
    boardingPlacesFromDriver: trip.boardingPlacesFromDriver,
    boardingPlacesNames: trip.boardingPlacesNames,
    cargoDescription: trip.cargoDescription,
    creatorIdPost: trip.creatorIdPost,
    creatorUid: trip.creatorUid,
    dateCreating: trip.dateCreating || '',
    endPoint: trip.endPoint,
    dispatcherFee: trip.dispatcherFee,
    drivers: trip.drivers,
    freePlaces: trip.freePlaces,
    hasGivenAwayPassengers: trip.hasGivenAwayPassengers,
    hasTransferredInPassengers: trip.hasTransferredInPassengers,
    idPost: trip.idPost,
    isByFoot: trip.isByFoot,
    isBoardingByDriverAddresses: trip.isBoardingByDriverAddresses,
    isForChange: trip.isForChange,
    isFreeSeating: trip.isFreeSeating,
    isPassengerAddress: trip.isPassengerAddress,
    isPassengerTrip: trip.isPassengerTrip,
    isPaymentByCash: trip.isPaymentByCash,
    isPaymentOnCard: trip.isPaymentOnCard,
    isSeparatePlaceForLuggage: trip.isSeparatePlaceForLuggage,
    isTripWithPets: trip.isTripWithPets,
    startDate: trip.startDate,
    startPoint: trip.startPoint,
    totalPlaces: trip.totalPlaces,
    pricePerPerson: trip.pricePerPerson,
    tripPoints: trip.tripPoints,
    tripPointsNames: trip.tripPointsNames,
    vehicle: trip.vehicle,
  };

  return originalTrip;
};

export const updateReservationInTripAfterPassengerChanges = async (
  trip,
  reservation,
  tripOrder,
  isOwnReservation,
  comment = 'Диспетчер удалил пассажира'
) => {
  const fieldName = isOwnReservation
    ? 'reservationsFromDispatchers'
    : 'reservationsFromDrivers';

  const updatedReservations = trip[fieldName].map((reserv) =>
    reserv.uuid === reservation.uuid
      ? {
          ...reserv,
          isCancelled: true,
          cancelComment: comment,
        }
      : reserv
  );

  await updateFieldsInDocumentInCollection(
    collectionsInterface.trips,
    trip.idPost,
    {
      [fieldName]: updatedReservations,
    }
  );

  // if (!isOwnReservation) {
  await sendMessageAboutReservationRejectionByDispatcher(
    trip,
    tripOrder,
    comment
  );
  // }
};

export const updateReservationInTripOrderAfterTripChanges = async (
  reservation,
  trip,
  isOwnReservation,
  comment = 'Перевозчик удалил поездку'
) => {
  const tripOrder = await getDocInCollection(
    collectionsInterface.tripsOrders,
    reservation.originalTripOrder.idPost
  );

  const fieldName = isOwnReservation
    ? 'reservationsFromDrivers'
    : 'reservationsFromDispatchers';

  const updatedReservations = tripOrder[fieldName].map((reserv) =>
    reserv.uuid === reservation.uuid
      ? {
          ...reserv,
          isCancelled: true,
          cancelComment: comment,
        }
      : reserv
  );

  await updateFieldsInDocumentInCollection(
    collectionsInterface.tripsOrders,
    tripOrder.idPost,
    {
      [fieldName]: updatedReservations,
    }
  );

  await sendMessageAboutReservationRejectionByTransporter(
    trip,
    tripOrder,
    comment
  );
};

export const cancelReservationsInAllTrips = async (
  actualTripOrder,
  comment
) => {
  try {
    const activeReservationsFromDispatchers =
      actualTripOrder.reservationsFromDispatchers.filter(
        (reservation) => !reservation.isCancelled
      );

    activeReservationsFromDispatchers.forEach((reservation) => {
      getDocInCollection(
        collectionsInterface.trips,
        reservation.originalTrip.idPost
      ).then((trip) => {
        updateReservationInTripAfterPassengerChanges(
          trip,
          reservation,
          actualTripOrder,
          true,
          comment
        );
      });
    });

    const activeReservationsFromDrivers =
      actualTripOrder.reservationsFromDrivers.filter(
        (reservation) => !reservation.isCancelled
      );

    activeReservationsFromDrivers.forEach((reservation) => {
      getDocInCollection(
        collectionsInterface.trips,
        reservation.originalTrip.idPost
      ).then((trip) => {
        updateReservationInTripAfterPassengerChanges(
          trip,
          reservation,
          actualTripOrder,
          false,
          comment
        );
      });
    });
  } catch (error) {}
};

export const cancelReservationsInAllTripsOrders = async (
  actualTrip,
  comment
) => {
  try {
    const activeReservationsFromDispatchers =
      actualTrip.reservationsFromDispatchers.filter(
        (reservation) => !reservation.isCancelled
      );

    activeReservationsFromDispatchers.forEach((reservation) => {
      updateReservationInTripOrderAfterTripChanges(
        reservation,
        actualTrip,
        comment
      );
    });

    const activeReservationsFromDrivers =
      actualTrip.reservationsFromDrivers.filter(
        (reservation) => !reservation.isCancelled
      );

    activeReservationsFromDrivers.forEach((reservation) => {
      updateReservationInTripOrderAfterTripChanges(
        reservation,
        actualTrip,
        comment
      );
    });
  } catch (error) {}
};

export const getAvailablePlacesFromList = (
  allPlacesList,
  occupiedPlacesList
) => {
  const availablePlacesList = allPlacesList.filter(
    (placeNumber) =>
      !occupiedPlacesList.find(
        (occupiedPlaceNumber) => occupiedPlaceNumber === placeNumber
      )
  );

  return availablePlacesList;
};

export const editTripForTransferredPassenger = async (tripId, tripOrderId) => {
  const sourceTrip = await getDocInCollection(
    collectionsInterface.trips,
    tripId
  );

  const dealToReplace = sourceTrip.acceptedDeals.find(
    (deal) => deal.id === tripOrderId
  );
  const updatedAcceptedDeals = sourceTrip.acceptedDeals.filter(
    (deal) => deal.id !== tripOrderId
  );

  const updatedTransferredDeals = [
    ...sourceTrip.transferredDeals,
    dealToReplace,
  ];

  const updatedFreePlaces =
    parseInt(sourceTrip.freePlaces) + parseInt(dealToReplace.reservedPlaces);

  let fieldsToUpdateInSourceTrip = {
    hasGivenAwayPassengers: true,
    acceptedDeals: updatedAcceptedDeals,
    transferredDeals: updatedTransferredDeals,
    freePlaces: updatedFreePlaces,
  };

  if (sourceTrip.isFreeSeating === false) {
    const updatedScheme = {
      ...sourceTrip.placesScheme,
      rows: sourceTrip.placesScheme.rows.map((curRow) => ({
        row: curRow.row.map((cell) =>
          dealToReplace.reservedPlacesNumbers.includes(cell.placeNumber)
            ? {
                ...cell,
                isOccupied: false,
              }
            : cell
        ),
      })),
    };

    fieldsToUpdateInSourceTrip = {
      ...fieldsToUpdateInSourceTrip,
      placesScheme: updatedScheme,
    };
  }

  if (
    updatedAcceptedDeals?.length === 0 ||
    updatedAcceptedDeals.every((deal) => deal.isCancelled)
  ) {
    fieldsToUpdateInSourceTrip = {
      ...fieldsToUpdateInSourceTrip,
      isActive: false,
    };
  }

  await updateFieldsInDocumentInCollection(
    collectionsInterface.trips,
    tripId,
    fieldsToUpdateInSourceTrip
  );
};
//#endregion

//#region Data validation
export const createBirthDate = (day, month, year) => {
  return `${year}-${month}-${day}`;
};

export const validateFullName = (fullName) => {
  if (fullName.length > 30) {
    return false;
  }

  const checkNameExpression = new RegExp(
    /^[\p{Alphabetic}+]([-.'\s]?\p{Alphabetic}+)*$/u
  );

  if (checkNameExpression.exec(fullName)) {
    return true;
  } else {
    return false;
  }
};

export const validatePassengerName = (passengerName) => {
  const checkNameExpression = new RegExp(
    /^[\p{Alphabetic}+]([-'\s]?\p{Alphabetic}+)*[-\s]?$/u
  );

  if (checkNameExpression.exec(passengerName)) {
    return true;
  } else {
    return false;
  }
};

export const validateEmailField = (email) => {
  if (!email) {
    return false;
  }

  if (email.length > 320) {
    return false;
  }

  const checkEmailExpression = new RegExp(
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/
  );

  if (checkEmailExpression.exec(email)) {
    return true;
  } else {
    return false;
  }
};

export const validateIsAdult = (date) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const dateToCompare = new Date(date).setHours(0, 0, 0, 0);

  const difference = differenceInYears(today, dateToCompare);

  if (difference >= 18) {
    return true;
  } else {
    return false;
  }
};

export const validateAndCorrectCardNumber = (newInput, currentCardNumber) => {
  let updatedCardNumber = '';

  const newInputString = newInput.toString();

  if (newInputString.length < currentCardNumber.toString().length) {
    if (currentCardNumber.length === 18) {
      updatedCardNumber = newInput.split(' ').join('');

      updatedCardNumber =
        updatedCardNumber.slice(0, 4) +
        ' ' +
        updatedCardNumber.slice(4, 8) +
        ' ' +
        updatedCardNumber.slice(8, 12) +
        ' ' +
        updatedCardNumber.slice(12);
    } else {
      updatedCardNumber = newInput;
    }

    return updatedCardNumber;
  }

  const inputSymbol = newInput[newInput.length - 1];

  if (inputSymbol === ' ') {
    return currentCardNumber;
  }

  const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  if (!digits.includes(Number(inputSymbol))) {
    return currentCardNumber;
  }

  updatedCardNumber = currentCardNumber + inputSymbol;

  if (updatedCardNumber.indexOf(' ') > 4 && updatedCardNumber.length > 19) {
    return currentCardNumber;
  }

  switch (updatedCardNumber.length) {
    case 4:
    case 9:
    case 14:
      updatedCardNumber += ' ';
  }

  if (updatedCardNumber.length === 21) {
    updatedCardNumber = updatedCardNumber.split(' ').join('');

    updatedCardNumber =
      updatedCardNumber.slice(0, 9) + ' ' + updatedCardNumber.slice(9);
  }

  return updatedCardNumber;
};

export const correctCardNumber = (cardNumber) => {
  let step = cardNumber.length > 16 ? 8 : 4;
  let currentPosition = 0;
  const chunks = [];

  while (true) {
    const numberChunk = cardNumber.slice(
      currentPosition,
      currentPosition + step
    );

    currentPosition += step;

    chunks.push(numberChunk);

    if (!numberChunk) {
      break;
    }
  }

  return chunks.join(' ').trim();
};

export const validateCardNumberLength = (cardNumber) => {
  const pureCardNumber = cardNumber.split(' ').join('');

  if (pureCardNumber.length === 16 || pureCardNumber.length === 18) {
    return true;
  }

  return false;
};

export const validateAndCorrectCardDate = (newInput, currentCardDate) => {
  let updatedCardDate = '';

  if (newInput.length < currentCardDate.length) {
    return newInput;
  }

  if (newInput.length > 5) {
    return currentCardDate;
  }

  const inputSymbol = newInput[newInput.length - 1];

  if (inputSymbol === ' ') {
    return currentCardDate;
  }

  const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  if (!digits.includes(Number(inputSymbol))) {
    return currentCardDate;
  }

  if (currentCardDate.length === 2) {
    updatedCardDate = currentCardDate + '/' + inputSymbol;
  } else {
    updatedCardDate = currentCardDate + inputSymbol;
  }

  return updatedCardDate;
};

export const validateCardDate = (cardDate) => {
  if (cardDate.length < 5) {
    return false;
  }

  const parts = cardDate.split('/');

  const month = Number(parts[0]);
  const year = Number(parts[1]);

  if (year > 99) {
    return false;
  }

  if (month > 12 || month === 0) {
    return false;
  }

  const currentDate = new Date();

  const currentYear = getYear(currentDate) % 100;

  if (currentYear > year) {
    return false;
  }

  const currentMonth = getMonth(currentDate) + 1;

  if (currentYear === year) {
    if (currentMonth > month) {
      return false;
    }
  }

  return true;
};

export const validateCardCvv = (newInput, currentCardCvv) => {
  if (newInput.length < currentCardCvv.length) {
    return newInput;
  }

  if (newInput.length > 4) {
    return currentCardCvv;
  }

  const inputSymbol = newInput[newInput.length - 1];

  if (inputSymbol === ' ') {
    return currentCardCvv;
  }

  const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  if (!digits.includes(Number(inputSymbol))) {
    return currentCardCvv;
  } else {
    return currentCardCvv + inputSymbol;
  }
};

export const checkPasswordLength = (password) => {
  if (password.length < 6) {
    return false;
  }

  return true;
};

export const checkPasswordsEquality = (password1, password2) => {
  if (password1 !== password2) {
    return false;
  }

  return true;
};

export const checkPasswordsAndSetErrorText = (password1, password2) => {
  if (!checkPasswordLength(password1)) {
    return 'Пароль должен быть не короче 6 символов';
  }

  if (!checkPasswordsEquality(password1, password2)) {
    return 'Пароли не совпадают';
  }

  return '';
};

export const checkUserDataCompleteness = (user) => {
  const userData = Object.keys(user);

  const hasEmptyDataFields = userData.some((field) => {
    switch (field) {
      case 'fullName':
      case 'dayOfBirth':
      case 'monthOfBirth':
      case 'yearOfBirth':
      case 'birthDay':
      case 'phoneNumbers':
      case 'email':
      case 'country':
      case 'city':
        if (user[field] === undefined) {
          return true;
        }
        if (user[field]?.length === 0) {
          return true;
        }
      default:
        return false;
    }
  });

  if (user?.phoneNumbers?.some((phone) => phone?.phoneNumber?.length === 0)) {
    return false;
  }

  return !hasEmptyDataFields;
};

export const getEmptyRegistrationFields = (user) => {
  const userData = Object.keys(user);

  const emptyDataFields = userData.filter((field) => {
    switch (field) {
      case 'fullName':
      case 'dayOfBirth':
      case 'monthOfBirth':
      case 'yearOfBirth':
      case 'birthDay':
      case 'phoneNumbers':
      case 'email':
      case 'password':
      case 'confirmPassword':
      case 'country':
      case 'city':
        if (user[field] === undefined) {
          return true;
        }
        if (user[field]?.length === 0) {
          return true;
        }
      default:
        return false;
    }
  });

  if (
    user?.phoneNumbers?.some(
      (phone) => !phone?.phoneNumber || phone?.phoneNumber?.length === 0
    )
  ) {
    emptyDataFields.push('phoneNumbers');
  }

  return emptyDataFields;
};

export const getRegistrationErrorObjectForEmptyFields = (emptyFields) => {
  const errorData = { ...registrationErrorsModel };

  emptyFields.forEach((field) => {
    if (errorData[field]?.isError !== undefined) {
      errorData[field].isError = true;
    }
  });

  return errorData;
};

export const getRegistrationWrongFieldsObject = (user) => {
  let errorData = {};

  try {
    if (!validateFullName(user.fullName)) {
      errorData = {
        ...errorData,
        fullName: {
          isError: true,
          errorType: registrationErrorsTypesInterface.wrongValue,
          errorMessage: 'Значение поля невалидно',
        },
      };

      // 'Ошибка в имени – недопустимые символы или превышена длина';
    }

    if (
      !user.birthDay ||
      !validateIsAdult(
        createBirthDate(user.dayOfBirth, user.monthOfBirth, user.yearOfBirth)
      )
    ) {
      errorData = {
        ...errorData,
        birthDay: {
          isError: true,
          errorType: registrationErrorsTypesInterface.wrongValue,
          errorMessage: 'Значение поля невалидно',
        },
      };

      // errorData.birthDay.errorMessage = 'Значение поля невалидно';
      // 'Зарегистрироваться может только совершеннолетний';
    }

    if (!validateEmailField(user.email)) {
      errorData = {
        ...errorData,
        email: {
          isError: true,
          errorType: registrationErrorsTypesInterface.wrongValue,
          errorMessage: 'Значение поля невалидно',
        },
      };

      // 'Введен невалидный email'
    }

    if (user.password !== undefined && !checkPasswordLength(user.password)) {
      errorData = {
        ...errorData,
        password: {
          isError: true,
          errorType: registrationErrorsTypesInterface.wrongValue,
          errorMessage: 'Пароль должен быть не короче 6 символов',
        },
      };
    }

    if (
      user.password !== undefined &&
      !checkPasswordsEquality(user.password, user.confirmPassword)
    ) {
      errorData = {
        ...errorData,
        confirmPassword: {
          isError: true,
          errorType: registrationErrorsTypesInterface.wrongValue,
          errorMessage: 'Пароли не совпадают',
        },
      };
    }

    const hasAnyWrongPhoneNumber = user?.phoneNumbers?.some(
      (phone) => phone.phoneNumber.length < PHONE_NUMBER_MIN_LENGTH
    );

    if (hasAnyWrongPhoneNumber) {
      errorData = {
        ...errorData,
        phoneNumbers: {
          isError: true,
          errorType: registrationErrorsTypesInterface.wrongValue,
          errorMessage: 'Некорректный номер телефона',
        },
      };
    }

    return errorData;
  } catch (error) {
    throw new Error(error);
  }
};

export const getEmptyAddVehicleFields = (vehicle) => {
  const vehicleData = Object.keys(vehicle);

  const emptyDataFields = vehicleData.filter((field) => {
    switch (field) {
      case 'registrationCountry':
      case 'licensePlate':
      case 'brand':
      case 'model':
      case 'bodyType':
      case 'color':
      case 'productionDate':
      case 'placesNumber':
      case 'permissionNumber':
        if (!vehicle[field]) {
          return true;
        }

        if (vehicle[field]?.length === 0) {
          return true;
        }
      default:
        return false;
    }
  });

  return emptyDataFields;
};

export const getAddVehicleErrorObjectForEmptyFields = (emptyFields) => {
  const errorData = { ...vehicleErrorsModel };

  emptyFields.forEach((field) => {
    if (errorData[field]?.isError !== undefined) {
      errorData[field].isError = true;
    }
  });

  return errorData;
};

export const getErrorText = (errorData, fieldName) => {
  try {
    if (
      errorData[fieldName]?.errorType ===
      registrationErrorsTypesInterface.emptyField
    ) {
      return 'Обязательное поле для заполнения';
    } else {
      return errorData[fieldName].errorMessage;
    }
  } catch (error) {
    return '';
  }
};

export const checkAdminDataCompleteness = (user) => {
  const userData = Object.keys(user);

  const hasEmptyDataFields = userData.some((field) => {
    switch (field) {
      case 'fullName':
      case 'phoneNumbers':
      case 'email':
        if (user[field] === undefined) {
          return true;
        }
        if (user[field]?.length === 0) {
          return true;
        }
      default:
        return false;
    }
  });

  if (
    user?.phoneNumbers?.some(
      (phone) => !phone?.phoneNumber || phone?.phoneNumber?.length === 0
    )
  ) {
    return false;
  }

  return !hasEmptyDataFields;
};

export const checkVehicleDataCompleteness = (vehicle) => {
  const vehicleData = Object.keys(vehicle);

  const hasEmptyDataFields = vehicleData.some((field) => {
    switch (field) {
      case 'registrationCountry':
      case 'licensePlate':
      case 'brand':
      case 'model':
      case 'bodyType':
      case 'color':
      case 'productionDate':
      case 'placesNumber':
      case 'permissionType':
      case 'permissionNumber':
        if (!vehicle[field]) {
          return true;
        }

        if (vehicle[field]?.length === 0) {
          return true;
        }
      default:
        return false;
    }
  });

  return !hasEmptyDataFields;
};

export const getVehicleData = async (
  lang,
  brand,
  model,
  setNames = () => {},
  setIsLoading = () => {},
  color,
  bodyType
) => {
  setIsLoading(true);

  try {
    const brandName = await getBrandName(lang, brand);

    const modelName = await getModelName(lang, model, brand);

    let translatedNames = {
      brandName,
      modelName,
    };

    if (color) {
      const colorName = await getColorName(lang, color);

      translatedNames = {
        ...translatedNames,
        colorName,
      };
    }

    if (bodyType) {
      const bodyTypeName = await getBodyTypeName(lang, bodyType);

      translatedNames = {
        ...translatedNames,
        bodyTypeName,
      };
    }

    setNames(translatedNames);
  } catch (error) {
    throw new Error(error);
  } finally {
    setIsLoading(false);
  }
};

export const checkTripPoint = (tripPoint, index, isLast) => {
  const tripPointData = Object.keys(tripPoint);

  if (tripPointData.length === 0) {
    return false;
  }

  let hasEmptyDataFields = tripPointData.some((field) => {
    switch (field) {
      case 'city':
      case 'country':
      case 'placeId':
      case 'fullAddress':
        if (!tripPoint[field]) {
          return true;
        }

        if (typeof tripPoint[field] === 'number') {
          if (tripPoint[field]?.length === 0) {
            return true;
          }
        }

        if (typeof tripPoint[field] === 'object') {
          if (Object.keys(tripPoint[field]).length === 0) {
            return true;
          }
        }

        if (tripPoint[field].length === 0) {
          return true;
        }
      case 'departureTimeWithoutDate':
      case 'departureDateWithoutTime':
        if (!isLast) {
          if (!tripPoint[field]) {
            return true;
          }

          if (typeof tripPoint[field] === 'number') {
            if (tripPoint[field]?.length === 0) {
              return true;
            }
          }

          if (typeof tripPoint[field] === 'object') {
            if (Object.keys(tripPoint[field]).length === 0) {
              return true;
            }
          }

          if (tripPoint[field].length === 0) {
            return true;
          }
        }
      default:
        return false;
    }
  });

  return !hasEmptyDataFields;
};

export const checkTripOrderCompleteness = (tripOrder) => {
  const tripOrderData = Object.keys(tripOrder);

  if (tripOrderData.length === 0) {
    return false;
  }

  let hasEmptyDataFields = tripOrderData.some((field) => {
    switch (field) {
      case 'passengerName':
      case 'passengerPhones':
      case 'startPoint':
      case 'endPoint':
      case 'departureTime':
      case 'pricePerPerson':
        if (!tripOrder[field] || tripOrder[field].length === 0) {
          return true;
        }

        if (
          typeof tripOrder[field] === 'string' &&
          tripOrder[field]?.trim()?.length === 0
        ) {
          return true;
        }
      case 'requiredNumberOfPlaces':
        if (tripOrder.isPassengerTrip) {
          if (!tripOrder[field] || tripOrder[field].length === 0) {
            return true;
          }
        }
      default:
        return false;
    }
  });

  if (!tripOrder.isPassengerTrip) {
    if (
      !tripOrder?.cargoDescription ||
      tripOrder.cargoDescription.trim().length === 0
    ) {
      hasEmptyDataFields = true;
    }
  }

  if (tripOrder.isPassengerAddress) {
    if (!tripOrder.departureAddress) {
      hasEmptyDataFields = true;
    }
  }

  if (!checkTripPoint(tripOrder.startPoint, 0, false)) {
    return false;
  }

  if (!checkTripPoint(tripOrder.endPoint, 0, true)) {
    return false;
  }

  return !hasEmptyDataFields;
};

export const checkTripCompleteness = (trip) => {
  const tripData = Object.keys(trip);

  if (tripData.length === 0) {
    return false;
  }

  let hasEmptyDataFields = tripData.some((field) => {
    switch (field) {
      case 'drivers':
      case 'startDate':
      case 'vehicleId':
      case 'totalPlaces':
        if (tripData?.isPassengerTrip) {
          if (!trip[field] || trip[field].length === 0) {
            return true;
          }
        }
      case 'freePlaces':
        if (tripData?.isPassengerTrip) {
          if (!trip[field] || trip[field].length === 0) {
            return true;
          }
        }
      default:
        return false;
    }
  });

  for (let i = 0; i < trip?.tripPoints?.length || 0; i++) {
    if (
      !checkTripPoint(trip.tripPoints[i], i, i === trip.tripPoints.length - 1)
    ) {
      return false;
    }
  }

  return !hasEmptyDataFields;
};

// export const validateIsDriverBusy = (trips, driver) => {
//   let isDriverRepeated = false;
//
//   let i = 0;
//
//   while (!isDriverRepeated && i < trips.length) {
//     isDriverRepeated = trips[i]?.drivers?.some(tDriver => {
//       if (tDriver.uid === driver.uid) {
//         return true;
//       } else {
//         return false;
//       }
//     });
//
//     i++;
//   }
//
//   return isDriverRepeated;
// }

// export const validateIsVehicleBusy = (trips, vehicle) => {
//   const isVehicleRepeated = trips
//     .some(trip => trip.vehicle.idPost === vehicle.idPost);
//
//   return isVehicleRepeated;
// }

export const validateIsDriverOrVehicleBusy = (trips, startDate) => {
  const isBusy = trips.some((trip) => trip.startDate <= startDate);

  return isBusy;
};

// export const validateDriversAndVehicles = async (currentTrip) => {
//   let isVehicleBusy;
//
//   let areDriversBusy = [];
//
//   const trips = await getPreparedTripsByCreatorAndStartData(
//     currentTrip.creatorUid, currentTrip.startDateWithoutTime);
//
//   if (validateIsVehicleBusy(trips, currentTrip.vehicle)) {
//     isVehicleBusy = true;
//   }
//
//   currentTrip?.drivers?.forEach(driver => {
//     if (validateIsDriverBusy(trips, driver)) {
//       areDriversBusy = [...areDriversBusy, driver.fullName];
//     }
//   });
//
//   return [isVehicleBusy, areDriversBusy];
// }

export const validateDriversAndVehicles = async (currentTrip) => {
  let isVehicleBusy;
  let areDriversBusy = [];
  const startDate = currentTrip.startDateWithoutTime;

  const nearestVehicleTrips =
    await getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
      collectionsInterface.vehiclesTrips,
      [
        {
          key: 'vehicleId',
          value: currentTrip.vehicle.idPost,
          operator: '==',
        },
        {
          key: 'endDate',
          value: startDate,
          operator: '>=',
        },
      ],
      'endDate',
      true,
      1
    );

  isVehicleBusy = validateIsDriverOrVehicleBusy(nearestVehicleTrips, startDate);

  for (const driver of currentTrip?.drivers) {
    const nearestDriverTrips =
      await getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
        collectionsInterface.driversTrips,
        [
          {
            key: 'driverId',
            value: driver.idPost,
            operator: '==',
          },
          {
            key: 'endDate',
            value: startDate,
            operator: '>=',
          },
        ],
        'endDate',
        true,
        1
      );

    if (validateIsDriverOrVehicleBusy(nearestDriverTrips, startDate)) {
      areDriversBusy = [...areDriversBusy, driver.fullName];
    }
  }

  return [isVehicleBusy, areDriversBusy];
};
//#endregion

//#region Check is user present in auth DB Firebase
export const checkIsEmailSuitableForLogin = (email, providers) => {
  if (providers.includes('password')) {
    return true;
  }

  return false;
};

export const checkIsGoogleUsedAsLoginProvider = (email, providers) => {
  if (providers.includes('google.com')) {
    return true;
  }

  return false;
};

export const getPossibleAuthOptionsForEmail = async (email) => {
  const providers = await firebase.auth().fetchSignInMethodsForEmail(email);

  if (providers.length === 0) {
    return authProvidersCheckResultsInterface.noProviders;
  }

  switch (true) {
    case checkIsEmailSuitableForLogin(email, providers):
      return authProvidersCheckResultsInterface.emailCanBeUsed;
    case checkIsGoogleUsedAsLoginProvider(email, providers):
      return authProvidersCheckResultsInterface.googleCanBeUsed;
    default:
      return authProvidersCheckResultsInterface.noEmail;
  }
};
//#endregion

//#region Process user data
export const getNewPhoneObj = (oldPhone, newValue, type) => {
  switch (type) {
    case 'number':
      return {
        ...oldPhone,
        phoneNumber: newValue,
      };
    case 'viber':
      return {
        ...oldPhone,
        hasViber: newValue,
      };
    case 'whatsapp':
      return {
        ...oldPhone,
        hasWhatsApp: newValue,
      };
    case 'telegram':
      return {
        ...oldPhone,
        hasTelegram: newValue,
      };
  }
};

export const checkIsDriverInFavourites = (driver, user) => {
  let checkResult = false;

  if (
    user?.favouriteDrivers.find((uDriver) => uDriver.idPost === driver.idPost)
  ) {
    checkResult = true;
  }

  return checkResult;
};
//#endregion

//#region Add to or delete driver from favourites
export const handleChangeFavouriteClick = async (
  event,
  driver,
  user,
  setUser
) => {
  event.stopPropagation();

  const isInFavourites = checkIsDriverInFavourites(driver, user);

  let newFavouriteDriversArray = [];

  if (isInFavourites) {
    newFavouriteDriversArray = user.favouriteDrivers.filter(
      (fDriver) => driver.idPost !== fDriver.idPost
    );
  } else {
    newFavouriteDriversArray = [
      ...user.favouriteDrivers,
      {
        uid: driver.uid,
        idPost: driver.idPost,
      },
    ];
  }

  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.users,
      user.idPost,
      'favouriteDrivers',
      newFavouriteDriversArray
    );

    setUser((prevUser) => ({
      ...prevUser,
      favouriteDrivers: newFavouriteDriversArray,
    }));
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Work with user after auth
export const checkUserDataCompletenessAndSetToState = (
  user,
  setUser = () => {},
  setIsUserDataComplete = () => {}
) => {
  if (checkUserDataCompleteness(user)) {
    setIsUserDataComplete(true);
  } else {
    setIsUserDataComplete(false);
  }

  setUser(user);
};

export function createNewUser(
  regInfo,
  actionFunction = () => {},
  isAdmin = false
) {
  return new Promise(function (resolve, reject) {
    getCollectionWhereKeyValue(collectionsInterface.users, 'uid', regInfo.uid)
      .then((r) => {
        if (r.length === 0) {
          let user_to_firebase_start = {
            ...userModel,
            ...regInfo,
            dateCreating: Date.now(),
            role: isAdmin ? rolesInterface.admin : rolesInterface.user,
          };

          const accountData = {
            ...balanceAccountModel,
            ownerUid: regInfo?.uid,
          };

          setDocumentToCollection(
            collectionsInterface.balanceAccounts,
            accountData
          )
            .then((savedAccount) => {
              const userToSave = {
                ...user_to_firebase_start,
                balanceAccount: savedAccount?.result?.id || '',
              };

              setDocumentToCollection(collectionsInterface.users, userToSave)
                .then((r) => {
                  getCollectionWhereKeyValue(
                    collectionsInterface.users,
                    'uid',
                    regInfo.uid
                  )
                    .then((res) => {
                      actionFunction(res[0]);
                      resolve(res[0]);
                    })
                    .catch((error) => reject(error));
                })
                .catch((e) => {
                  reject(e);
                });
            })
            .catch((error) => reject(error));
        } else {
          resolve(r[0]);
        }
      })
      .catch((e) => {
        reject('');
      });
  });
}

export const signInWithGoogleButtonClickHandler = async (
  event,
  setUser,
  navigate
) => {
  event.preventDefault();

  try {
    await googleSignIn(setUser);

    navigate('/profile');
  } catch (error) {
    return error;
  }
};

export const signInWithFacebookButtonClickHandler = async (
  event,
  setUser,
  navigate
) => {
  event.preventDefault();

  try {
    await facebookSignIn(setUser);

    navigate('/profile');
  } catch (error) {
    return error;
  }
};

export const signInWithAppleButtonClickHandler = async (
  event,
  setUser,
  navigate
) => {
  event.preventDefault();

  try {
    await appleSignIn(setUser);

    navigate('/profile');
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Work with user data
//#region Define if company has registered drivers
export const checkIsCompanyHasRegisteredDrivers = (user) => {
  const hasDrivers = user?.companyDrivers?.some(
    (driver) => driver?.isFulfilledRegistration
  );

  return hasDrivers;
};
//#endregion
//#endregion

//#region Lang
export const getTranslation = (key, dictionary, defaultDictionary) => {
  if (!dictionary) {
    return englishDictionary[key] || key;
  }

  if (!dictionary.dictionary.hasOwnProperty(key)) {
    return defaultDictionary.dictionary[key] || key;
  }
  if (!dictionary.dictionary[key]) {
    return defaultDictionary.dictionary[key] || key;
  }

  return dictionary.dictionary[key];
};
//#endregion

//#region Work with data for selects
export const transformStringsArrayToSelectOptions = (data) => {
  return data?.map((item) => ({
    value: item,
    label: item,
  }));
};

export const createYearsArray = (startYear, endYear) => {
  const yearsArr = [];

  for (let i = startYear; i <= endYear; i++) {
    yearsArr.push(i);
  }

  return transformStringsArrayToSelectOptions(yearsArr);
};

export const getAppropriateCountriesList = (lang, countries) => {
  return countries.map((country) => {
    return {
      value: country,
      label: country[lang],
    };
  });
};

export const getAppropriateColorsList = (lang, colors) => {
  if (!colors) {
    return [];
  }

  return colors.map((color) => {
    return {
      value: color,
      label: color[lang] || color.defaultName,
    };
  });
};

export const getAppropriateBodyTypesList = (lang, bodyTypes) => {
  if (!bodyTypes) {
    return [];
  }

  return bodyTypes.map((bodyType) => {
    return {
      value: bodyType,
      label: bodyType[lang] || bodyType.defaultName,
    };
  });
};

export const getAppropriateBrandsList = (lang, brands) => {
  if (!brands) {
    return [];
  }

  return brands.map((brand) => {
    return {
      value: brand,
      label: brand[lang] || brand.defaultName,
    };
  });
};

export const getAppropriateModelsList = (lang, models) => {
  if (!models) {
    return [];
  }

  return models.map((model) => {
    return {
      value: model,
      label: model[lang] || model.defaultName,
    };
  });
};

const getAllDriverNumbersAsString = (driver) => {
  const phonesString = driver?.phoneNumbers
    ?.map((phone) => phone.phoneNumber)
    .join(' ');

  return phonesString || '';
};

export const transformDriversToSelectOptions = (drivers) => {
  return drivers?.map((driver) => ({
    value: driver,
    label: driver.fullName + ' (' + getAllDriverNumbersAsString(driver) + ')',
  }));
};

export const transformVehiclesToSelectOptions = (vehicles) => {
  return vehicles?.map((vehicle) => ({
    value: vehicle,
    label:
      vehicle?.brand?.defaultName +
      ' ' +
      vehicle?.model?.defaultName +
      ' ' +
      vehicle?.licensePlate,
  }));
};

export const transformPageTypesToSelectOptions = (
  pageTypes,
  pageNames,
  translate
) => {
  const keys = Object.keys(pageTypes);

  const result = [];

  for (const key of keys) {
    result.push({
      label: translate(pageNames[key]),
      value: pageTypes[key],
    });
  }

  return result;
};

export const getTransactionTypeName = (type) => {
  switch (type) {
    case transactionsTypesInterface.accountDeposit:
      return 'Пополнение счета';
    case transactionsTypesInterface.bonuses:
      return 'Бонусы';
    case transactionsTypesInterface.commissionTaking:
      return 'Комиссия';
    case transactionsTypesInterface.dispatcherFee:
      return 'Вознаграждение диспетчеру';
    case transactionsTypesInterface.exchange:
      return 'Обмен';
    case transactionsTypesInterface.compensationForExchange:
      return 'Компенсация за обмен';
  }
};

export const transformTransactionTypesToSelectOptions = (types, translate) => {
  const keys = Object.keys(types);
  const result = [];

  for (const key of keys) {
    result.push({
      label: translate(getTransactionTypeName(types[key])),
      value: types[key],
    });
  }

  return result;
};
//#endregion

//#region Numbers formatting
export const addThousandSeparatorToNumber = (num, separator) => {
  let stringNum = num.toString();
  const parts = stringNum.split('.');
  let integerPart;
  let fractionPart;

  integerPart = parts[0];

  if (parts.length > 1) {
    fractionPart = parts[1];
  }

  let count = 0;
  let result = '';

  for (let i = integerPart.length - 1; i >= 0; i--) {
    if (count === 2) {
      count = 0;

      result = separator + integerPart[i] + result;
    } else {
      count++;
      result = integerPart[i] + result;
    }
  }

  if (fractionPart) {
    return result + '.' + fractionPart;
  }

  return result;
};
//#endregion

//#region Work with image
export const createImageFromBase64Text = (photoUrl) => {
  const arr = photoUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], 'avatar', { type: mime });
};
//#endregion

//#region Work with phone numbers
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  if (phoneNumber.length < 7) {
    return phoneNumber;
  }
  let result = '';

  const mainPart = phoneNumber.slice(-7);

  result =
    mainPart.slice(0, 3) +
    ' ' +
    mainPart.slice(-4, -2) +
    ' ' +
    mainPart.slice(-2);

  result = '(' + phoneNumber.slice(-9, -7) + ') ' + result;

  result = '+' + phoneNumber.slice(0, -9) + ' ' + result;

  return result;
};
//#endregion

//#region Get names for vehicle
export const getCountryName = (lang, country) => {
  return country[lang] || country.en;
};

export const getBrandName = async (lang, brand) => {
  if (brand?.hasOwnProperty(lang) && brand[lang]) {
    return brand[lang];
  } else {
    const brandsCollection = await getCollection(
      collectionsInterface.vehiclesCatalog
    );

    const actualBrand = Object.values(
      brandsCollection[0]?.vehiclesCatalog
    )?.find((vehicle) => vehicle.id === brand.id);

    if (actualBrand) {
      return actualBrand[lang] || brand.defaultName;
    } else {
      return brand.defaultName;
    }
  }
};

export const getModelName = async (lang, model, brand) => {
  if (model?.hasOwnProperty(lang) && model[lang]) {
    return model[lang];
  } else {
    const brandsCollection = await getCollection(
      collectionsInterface.vehiclesCatalog
    );
    const actualBrand = Object.values(
      brandsCollection[0]?.vehiclesCatalog
    )?.find((vehicle) => vehicle.id === brand.id);

    if (actualBrand) {
      const actualModel = actualBrand?.models?.find(
        (item) => item.id === model.id
      );

      if (actualModel) {
        return actualModel[lang] || model.defaultName;
      }
    }

    return model.defaultName;
  }
};

export const getBodyTypeName = async (lang, bodyType) => {
  if (bodyType?.hasOwnProperty(lang) && bodyType[lang]) {
    return bodyType[lang];
  } else {
    const bodyTypesCollection = await getCollection(
      collectionsInterface.vehiclesBodyTypes
    );

    const actualBodyType = bodyTypesCollection?.vehiclesBodyTypes?.find(
      (body) => body.idPost === bodyType.idPost
    );

    if (actualBodyType) {
      return actualBodyType[lang] || bodyType.defaultName;
    }

    return bodyType.defaultName;
  }
};

export const getColorName = async (lang, color) => {
  if (color?.hasOwnProperty(lang) && color[lang]) {
    return color[lang];
  } else {
    const colorsCollection = await getCollection(
      collectionsInterface.vehiclesColors
    );

    const actualColor = colorsCollection?.vehiclesColors?.find(
      (item) => item.defaultName === color.defaultName
    );

    if (actualColor) {
      return actualColor[lang] || color.defaultName;
    }

    return color.defaultName;
  }
};
//#endregion

//#region Work with invitations
//#region Creating and parsing link for driver inviting
export const createLinkToInviteDriver = (user, driverEmail) => {
  let link = '';

  const id = user.idPost;

  link = `https://autostop.club/register?invitedBy=${id}&driverSupposedEmail=${driverEmail}`;
  // link = `http://localhost:3000/register?invitedBy=${id}&driverSupposedEmail=${driverEmail}`;

  return link;
};

export const parseInviteLink = (searchParams) => {
  const inviterId = searchParams.get('invitedBy');
  const driverEmail = searchParams.get('driverSupposedEmail');

  return {
    inviterId: inviterId,
    driverEmail: driverEmail,
  };
};

export const handleDriverInvitationLink = async (invitation) => {
  const inviter = await getDocInCollection(
    collectionsInterface.users,
    invitation.inviterId
  );

  const isLinkValid =
    invitation?.driverEmail &&
    inviter.companyDrivers.some(
      (driver) => driver?.supposedEmail === invitation.driverEmail
    );

  if (isLinkValid) {
    return [true, invitation];
  }

  return [
    false,
    {
      nonValidLink: true,
    },
  ];
};

export const handleReferralLink = async (referrerId) => {
  const referrer = await getDocInCollection(
    collectionsInterface.users,
    referrerId
  );

  const isLinkValid = !!referrer.idPost;

  if (isLinkValid) {
    return [
      true,
      {
        referrerId,
      },
    ];
  }

  return [
    false,
    {
      nonValidLink: true,
    },
  ];
};

export const checkInvitation = async (searchParams) => {
  const invitation = parseInviteLink(searchParams);
  const referrerId = parseReferralLink(searchParams);

  if (invitation?.inviterId) {
    const invitationData = await handleDriverInvitationLink(invitation);

    return invitationData;
  }

  if (referrerId) {
    const referrerData = await handleReferralLink(referrerId);

    return referrerData;
  }

  return [false, {}];
};
//#endregion

//#region Creating and parsing link for user inviting
export const createLinkToInviteUser = (id) => {
  let link = '';

  // link = `http://localhost:3000/register?referrerId=${id}`;
  link = `https://autostop.club/register?referrerId=${id}`;

  return link;
};

export const parseReferralLink = (searchParams) => {
  const referrerId = searchParams.get('referrerId');

  return referrerId;
};
//#endregion

export async function checkIfUserHasUnclosedTripsOrders(userId) {
  try {
    const tripsOrders =
      await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
        collectionsInterface.tripsOrders,
        [
          { key: 'creatorIdPost', operator: '==', value: userId },
          { key: 'isCompleted', operator: '==', value: false },
          { key: 'isCancelled', operator: '==', value: false },
          { key: 'isArchived', operator: '==', value: false },
        ]
      );

    if (tripsOrders.length > 0) {
      return true;
    }

    return false;
  } catch (error) {
    throw new Error(error);
  }
}

export async function checkIfUserHasUnclosedTrips(userId) {
  try {
    const trips =
      await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
        collectionsInterface.trips,
        [
          { key: 'creatorIdPost', operator: '==', value: userId },
          { key: 'isCompleted', operator: '==', value: false },
          { key: 'isCancelled', operator: '==', value: false },
          { key: 'isArchived', operator: '==', value: false },
        ]
      );

    if (trips.length > 0) {
      return true;
    }

    return false;
  } catch (error) {
    throw new Error(error);
  }
}

export async function checkIfUserHasMoney(userId, balanceId) {
  const balance = await getDocInCollection(
    collectionsInterface.balanceAccounts,
    balanceId
  );

  if (balance.reservedAmount !== 0) {
    return true;
  }

  if (balance.amount === 0) {
    const unclosedWithdrawals =
      await getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
        collectionsInterface.transactions,
        [
          { key: 'payer.idPost', operator: '==', value: userId },
          {
            key: 'type',
            operator: '==',
            value: transactionsTypesInterface.accountWithdrawal,
          },
          { key: 'isSuccessful', operator: '==', value: false },
        ]
      );

    return unclosedWithdrawals.length > 0;
  }

  return true;
}

export async function checkIfUserHasDebtorsOrCreditors(userId) {
  const user = await getDocInCollection(collectionsInterface.users, userId);

  const hasActiveCounterparties =
    user.debtors.length > 0 || user.creditors.length > 0;

  const hasArchivedCreditors = user.archivedExchanges.some(
    (creditor) => !creditor.isFeePaid
  );

  return hasActiveCounterparties || hasArchivedCreditors;
}

export async function checkIfUserCanAcceptInvitation(userId, balanceId) {
  try {
    let checkResult;

    checkResult = await checkIfUserHasMoney(userId, balanceId);

    if (checkResult) {
      return false;
    }

    checkResult = await checkIfUserHasDebtorsOrCreditors(userId);

    if (checkResult) {
      return false;
    }

    checkResult = await checkIfUserHasUnclosedTripsOrders(userId);

    if (checkResult) {
      return false;
    }

    checkResult = await checkIfUserHasUnclosedTrips(userId);

    if (checkResult) {
      return false;
    }

    return true;
  } catch (error) {
    throw new Error(error);
  }
}

export async function acceptInvitation(invitation, driver) {
  try {
    await updateFieldsInDocumentInCollection(
      collectionsInterface.users,
      driver.idPost,
      {
        attachedToCompany: invitation.companyId,
        isDriverInCompany: true,
        [`invitations.${invitation.id}.hasResponse`]: true,
        [`invitations.${invitation.id}.isAccepted`]: true,
      }
    );

    const company = await getDocInCollection(
      collectionsInterface.users,
      invitation.companyId
    );

    const updatedCompanyDrivers = company.companyDrivers.map((curDriver) =>
      curDriver.supposedEmail === driver.email
        ? {
            ...curDriver,
            isFulfilledRegistration: true,
            uid: driver.uid,
            email: driver.email,
            fullName: driver.fullName,
            phoneNumbers: driver.phoneNumbers,
            photoUrl: driver.photoUrl,
            idPost: driver.idPost,
          }
        : curDriver
    );

    await updateFieldInDocumentInCollection(
      collectionsInterface.users,
      invitation.companyId,
      'companyDrivers',
      updatedCompanyDrivers
    );

    const actualDriver = await getDocInCollection(
      collectionsInterface.users,
      driver.idPost
    );

    const invitations = Object.values(actualDriver.invitations)?.filter(
      (curInvitation) =>
        curInvitation.id !== invitation.id && !curInvitation.hasResponse
    );

    invitations.forEach((curInvitation) => {
      rejectInvitation(
        curInvitation.id,
        driver.idPost,
        driver.fullName,
        curInvitation.companyId,
        driver.email
      );
    });
  } catch (error) {
    throw new Error(error);
  }
}

export async function rejectInvitation(
  invitationId,
  userId,
  driverName,
  companyId,
  driverEmail
) {
  try {
    await updateFieldsInDocumentInCollection(
      collectionsInterface.users,
      userId,
      {
        [`invitations.${invitationId}.hasResponse`]: true,
        [`invitations.${invitationId}.isAccepted`]: false,
      }
    );

    const company = await getDocInCollection(
      collectionsInterface.users,
      companyId
    );

    const updatedCompanyDrivers = company.companyDrivers.filter(
      (driver) => driver.supposedEmail !== driverEmail
    );

    await updateFieldInDocumentInCollection(
      collectionsInterface.users,
      companyId,
      'companyDrivers',
      updatedCompanyDrivers
    );

    await sendMessageAboutInvitationRejection(driverName, companyId);
  } catch (error) {
    throw new Error(error);
  }
}
//#endregion

//#region Get payment status text and badge class
export const getPaymentStatusText = (
  tripOrder,
  isPassengerInOwnTrip = false
) => {
  if (isPassengerInOwnTrip) {
    return 'Внутренняя';
  }

  if (tripOrder.isCancelled) {
    return 'Отменена';
  }

  if (tripOrder.isFeePaid) {
    return 'Оплачено';
  }

  if (tripOrder.acceptedDeal?.isForChange) {
    return 'Обмен';
  }

  return 'Ожидание оплаты';
};

export const getPaymentBadgeClass = (tripOrder) => {
  if (tripOrder.isCancelled) {
    return 'PaymentStatusBadge-Cancelled';
  }

  if (tripOrder.isFeePaid) {
    return 'PaymentStatusBadge-Paid';
  }

  return 'PaymentStatusBadge-Awaiting';
};
//#endregion

//#region Functions for displaying auto on map
export const checkIfDriverHasTripsInProgress = async (driver) => {
  try {
    const valueForSearch = driver.attachedToCompany || driver.idPost;

    const tripsInProgress = await getCollectionWhereKeysValues(
      collectionsInterface.trips,
      [
        { key: 'creatorIdPost', value: valueForSearch },
        { key: 'isInProgress', value: true },
      ],
      2
    );

    if (tripsInProgress.length === 0) {
      return {
        hasTripInProgress: false,
        trip: {},
      };
    }

    const targetTrip = tripsInProgress.find((trip) =>
      trip.drivers.some((driverInTrip) => driverInTrip.idPost === driver.idPost)
    );

    if (targetTrip) {
      return {
        hasTripInProgress: true,
        trip: targetTrip,
      };
    }

    return {
      hasTripInProgress: false,
      trip: {},
    };
  } catch (error) {
    return {
      hasTripInProgress: false,
      trip: {},
    };
  }
};

export const saveLocationToDb = async (position, user, trip) => {
  const lastTripLocation = {
    dataSource: user.idPost,
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
    queryTime: Date.now(),
  };

  await updateFieldInDocumentInCollection(
    collectionsInterface.trips,
    trip.idPost,
    'location',
    lastTripLocation
  );
};

const geoLocationError = (err) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
};

export const getLocationAndSaveToDb = (user, trip) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const position = navigator.geolocation;
  position.getCurrentPosition(
    (position) => saveLocationToDb(position, user, trip),
    geoLocationError,
    options
  );
};

export const getCurrentTripLocation = async (trip) => {
  try {
    const actualTrip = await getDocInCollection(
      collectionsInterface.trips,
      trip.idPost
    );

    return {
      lat: actualTrip.location.latitude,
      lng: actualTrip.location.longitude,
    };
  } catch (error) {
    return {
      lat: 0,
      lng: 0,
    };
  }
};
//#endregion

//#region Functions for search for badges 'new' and notifications
export const findAllAvailableTripsOfOtherDriversWithExactDateAndCreatedAfterTripOrder =
  async (userIdPost, dateForSearch, tripOrderCreatingDate) => {
    const suitableTrips = await getCollectionWhereKeysValuesWithOperators(
      collectionsInterface.trips,
      [
        { key: 'isOpenForSearch', value: true, operator: '==' },
        { key: 'isVisibleForDispatchers', value: true, operator: '==' },
        { key: 'startDateWithoutTime', value: dateForSearch, operator: '==' },
        { key: 'dateCreating', value: tripOrderCreatingDate, operator: '>=' },
        { key: 'isArchived', value: false, operator: '==' },
      ],
      5
    );

    return suitableTrips.filter((trip) => trip.creatorIdPost !== userIdPost);
  };

export const findAllMyAvailableTripsWithExactDate = async (
  userIdPost,
  dateForSearch
) => {
  const suitableTrips = getCollectionWhereKeysValuesWithOperators(
    collectionsInterface.trips,
    [
      { key: 'creatorIdPost', value: userIdPost, operator: '==' },
      { key: 'isOpenForSearch', value: true, operator: '==' },
      { key: 'isVisibleForDispatchers', value: true, operator: '==' },
      { key: 'startDateWithoutTime', value: dateForSearch, operator: '==' },
    ],
    4
  );

  return suitableTrips;
};

export const findTripsForTripOrder = (trips, tripOrder) => {
  const departureDate = getDateWithoutTime(new Date(tripOrder.departureTime));

  // const tripsWithSuitableDeparturePoint = trips.filter(trip => trip.tripPoints
  //   .find((point, index, array) => (point.placeId === tripOrder.startPoint.placeId)
  //     && (index < array.length - 1) && isEqual(new Date(departureDate),
  //       new Date(getDateWithoutTime(point.departureTime)))));

  const tripsWithSuitableDeparturePoint = trips.filter((trip) =>
    trip.tripPoints.find(
      (point, index, array) =>
        point.placeId === tripOrder.startPoint.placeId &&
        index < array.length - 1 &&
        departureDate === getDateWithoutTime(point.departureTime)
    )
  );

  const tripsWithArrivingPoint = tripsWithSuitableDeparturePoint.filter(
    (trip) =>
      trip.tripPoints.find(
        (point, index) =>
          point.placeId === tripOrder.endPoint.placeId && index > 0
      )
  );

  return tripsWithArrivingPoint;
};

export const findTripsForTripOrderFromStartToEnd = (trips, tripOrder) => {
  const suitableTrips = trips.filter(
    (trip) =>
      trip.startPoint.placeId === tripOrder.startPoint.placeId &&
      trip.endPoint.placeId === tripOrder.endPoint.placeId
  );

  return suitableTrips;
};
//#endregion

//#region Functions for badges 'new'
export const checkIsTripInTheObservedTripsList = (tripOrder, trip) => {
  const result = !!tripOrder?.observedTrips?.find(
    (tripId) => trip.idPost === tripId
  );

  return result;
};

export const checkIsTripInTheReservationsFromDriversList = (
  tripOrder,
  trip
) => {
  const result = !!tripOrder?.reservationsFromDrivers?.find(
    (reservation) => trip.idPost === reservation.originalTrip.idPost
  );

  return result;
};

export const checkIsTripInTheReservationsFromDispatchersList = (
  tripOrder,
  trip
) => {
  const result = !!tripOrder?.reservationsFromDispatchers?.find(
    (reservation) => trip.idPost === reservation.originalTrip.idPost
  );

  return result;
};

export const checkIsTripOrderAlreadyReservedInTrip = (tripOrder, trip) => {
  if (checkIsTripInTheReservationsFromDriversList(tripOrder, trip)) {
    return true;
  }

  if (checkIsTripInTheReservationsFromDispatchersList(tripOrder, trip)) {
    return true;
  }

  return false;
};

export const defineListOfNewTripsForTripOrder = (suitableTrips, tripOrder) => {
  const nonObservedTrips = suitableTrips.filter(
    (trip) => !checkIsTripInTheObservedTripsList(tripOrder, trip)
  );

  const newTrips = nonObservedTrips.filter(
    (trip) => !checkIsTripOrderAlreadyReservedInTrip(tripOrder, trip)
  );

  return newTrips;
};

export const findNewTripsForTripOrder = async (userId, tripOrder) => {
  try {
    const trips =
      await findAllAvailableTripsOfOtherDriversWithExactDateAndCreatedAfterTripOrder(
        userId,
        tripOrder.departureDateWithoutTime,
        tripOrder.dateCreating
      );

    const suitableTrips = findTripsForTripOrder(trips, tripOrder);

    const newTrips = defineListOfNewTripsForTripOrder(suitableTrips, tripOrder);

    return newTrips;
  } catch (error) {
    return [];
  }
};

export const defineIfBadgeNewShouldBeOnMyPassengersMenuSectionPointer = async (
  user,
  tripOrders
) => {
  let i = 0;
  let checkResultForTripOrder = [];

  try {
    while (i < tripOrders.length) {
      checkResultForTripOrder = await findNewTripsForTripOrder(
        user,
        tripOrders[i]
      );

      if (checkResultForTripOrder.length > 0) {
        return true;
      } else {
        i++;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const findIfTripOrderHasNewReservationsFromDrivers = (tripOrder) => {
  const result = tripOrder.reservationsFromDrivers.some((reservation) =>
    isAfter(
      new Date(reservation.dateCreating),
      new Date(tripOrder.latestCheckOfTripOrderReservations)
    )
  );

  return result;
};

export const updateObservedTripsListInTripOrder = async (
  newTrips,
  tripOrder
) => {
  const listForAdding = newTrips.map((trip) => trip.idPost);

  try {
    const actualTripOrder = await getDocInCollection(
      collectionsInterface.tripsOrders,
      tripOrder.idPost
    );

    const tripsToAdd = [];

    for (const trip of listForAdding) {
      if (
        !actualTripOrder.observedTrips.some(
          (observedTrip) => observedTrip === trip
        )
      ) {
        tripsToAdd.push(trip);
      }
    }

    const updatedObservedTrips = [
      ...actualTripOrder.observedTrips,
      ...tripsToAdd,
    ];

    await updateFieldInDocumentInCollection(
      collectionsInterface.tripsOrders,
      tripOrder.idPost,
      'observedTrips',
      updatedObservedTrips
    );
    return {
      ...actualTripOrder,
      observedTrips: updatedObservedTrips,
    };
  } catch (error) {
    return false;
  }
};

export const updateLastCheckOfReservesDateForTripOrder = async (tripOrder) => {
  const currentDate = Date.now();

  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.tripsOrders,
      tripOrder.idPost,
      'latestCheckOfTripOrderReservations',
      currentDate
    );

    return {
      ...tripOrder,
      latestCheckOfTripOrderReservations: currentDate,
    };
  } catch (error) {
    return false;
  }
};

export const isTripOrderHasReservationInUsersTrip = (user, tripOrder) => {
  let creatorId = user.attachedToCompany || user.idPost;

  const isReservationFromDriver = tripOrder.reservationsFromDrivers.some(
    (reservation) => reservation.originalTrip.creatorIdPost === creatorId
  );

  if (isReservationFromDriver) {
    return true;
  }

  const isReservationFromDispatcher =
    tripOrder.reservationsFromDispatchers.some(
      (reservation) => reservation.originalTrip.creatorIdPost === creatorId
    );

  if (isReservationFromDispatcher) {
    return true;
  }

  return false;
};
//#endregion

//#region Payments
//#region Deposits
export const makeDeposit = async (user, amount) => {
  try {
    const transactionId = uuidv4();

    const options = {
      method: 'POST',
      body: JSON.stringify({
        userId: user.idPost,
        amount,
        transactionId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const result = await apiRequest(
      serverUrl,
      endPointsInterface.makeDeposit,
      options
    );

    if (result.response_status === 'success') {
      const deal = {
        amount: amount / 100,
        appAccountId: user.balanceAccount,
        id: transactionId,
      };

      const payer = {
        fullName: user.fullName,
        idPost: user.idPost,
      };

      const transaction = await createTransactionAndSaveToTransactions(
        transactionsTypesInterface.accountDeposit,
        deal,
        payer,
        payer
      );

      await updateFieldInDocumentInCollection(
        collectionsInterface.balanceAccounts,
        user.balanceAccount,
        `transactions.${transaction.idPost}`,
        transaction
      );

      return result.checkout_url;
    } else {
      throw new Error({
        message: result.error_message,
      });
    }
  } catch (error) {
    throw new Error(error);
  }
};
//#endregion

//#region Withdrawals
export const makeOrderForWithdrawal = async (paymentData, balanceAccount) => {
  try {
    const deal = {
      amount: paymentData.amount,
      extraData: {
        balanceAccount,
        cardNumber: paymentData.cardNumber,
        fullName: paymentData.fullName,
        iban: paymentData.iban,
        status: withdrawalStatusesInterface.created,
        swiftCode: paymentData.swiftCode,
      },
      id: uuidv4(),
    };

    const counterparty = {
      idPost: paymentData.userId,
      fullName: paymentData.userFullName,
    };

    const transaction = await createTransactionAndSaveToTransactions(
      transactionsTypesInterface.accountWithdrawal,
      deal,
      counterparty,
      counterparty
    );

    const actualAccount = await getDocInCollection(
      collectionsInterface.balanceAccounts,
      balanceAccount
    );

    const newAmount = actualAccount.amount - deal.amount;

    await updateFieldsInDocumentInCollection(
      collectionsInterface.balanceAccounts,
      actualAccount.idPost,
      {
        amount: newAmount,
        [`transactions.${transaction.idPost}`]: transaction,
      }
    );

    return true;
  } catch (error) {
    throw new Error(error);
  }
};

export const handleTakeInProcessingClick = async (
  withdrawal,
  setWithdrawals
) => {
  try {
    const updatedWithdrawal = {
      ...withdrawal,
      extraData: {
        ...withdrawal.extraData,
        status: withdrawalStatusesInterface.pending,
      },
    };

    await updateFieldsInDocumentInCollection(
      collectionsInterface.transactions,
      withdrawal.idPost,
      {
        extraData: updatedWithdrawal.extraData,
      }
    );

    await updateFieldsInDocumentInCollection(
      collectionsInterface.balanceAccounts,
      withdrawal?.extraData?.balanceAccount,
      {
        [`transactions.${withdrawal.idPost}`]: updatedWithdrawal,
      }
    );

    setWithdrawals((state) =>
      state.map((curWithdrawal) =>
        curWithdrawal.idPost === withdrawal.idPost
          ? updatedWithdrawal
          : curWithdrawal
      )
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const confirmWithdrawal = async (withdrawal, setWithdrawals) => {
  try {
    const updatedWithdrawal = {
      ...withdrawal,
      extraData: {
        ...withdrawal.extraData,
        status: withdrawalStatusesInterface.approved,
      },
      isSuccessful: true,
    };

    await updateFieldsInDocumentInCollection(
      collectionsInterface.transactions,
      withdrawal.idPost,
      {
        extraData: updatedWithdrawal.extraData,
        isSuccessful: true,
      }
    );

    await updateFieldsInDocumentInCollection(
      collectionsInterface.balanceAccounts,
      withdrawal?.extraData?.balanceAccount,
      {
        [`transactions.${withdrawal.idPost}`]: updatedWithdrawal,
      }
    );

    setWithdrawals((state) =>
      state.map((curWithdrawal) =>
        curWithdrawal.idPost === withdrawal.idPost
          ? updatedWithdrawal
          : curWithdrawal
      )
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const cancelWithdrawal = async (
  withdrawal,
  setWithdrawals = () => {}
) => {
  try {
    const updatedWithdrawal = {
      ...withdrawal,
      extraData: {
        ...withdrawal.extraData,
        status: withdrawalStatusesInterface.cancelled,
        isSuccessful: false,
      },
    };

    await updateFieldsInDocumentInCollection(
      collectionsInterface.transactions,
      withdrawal.idPost,
      {
        extraData: updatedWithdrawal.extraData,
        isSuccessful: false,
      }
    );

    const actualAccount = await getDocInCollection(
      collectionsInterface.balanceAccounts,
      withdrawal?.extraData?.balanceAccount
    );

    const actualAmount =
      Number(actualAccount.amount) + Number(withdrawal.amount);

    await updateFieldsInDocumentInCollection(
      collectionsInterface.balanceAccounts,
      withdrawal?.extraData?.balanceAccount,
      {
        [`transactions.${withdrawal.idPost}`]: updatedWithdrawal,
        amount: actualAmount,
      }
    );

    setWithdrawals((state) =>
      state.map((curWithdrawal) =>
        curWithdrawal.idPost === withdrawal.idPost
          ? updatedWithdrawal
          : curWithdrawal
      )
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const getWithdrawalStatusText = (status) => {
  switch (status) {
    case withdrawalStatusesInterface.created:
      return 'Создана';
    case withdrawalStatusesInterface.pending:
      return 'Ожидает';
    case withdrawalStatusesInterface.cancelled:
      return 'Отклонена';
    case withdrawalStatusesInterface.approved:
      return 'Одобрена';
    default:
      return 'Создана';
  }
};
//#endregion
//#endregion

//#region Work with testimonials
//#region Handle testimonial saving to DB
export const checkIfSecondTestimonial = async (tripOrder, isDispatcher) => {
  let checkResult = false;

  const actualTripOrder = await getDocInCollection(
    collectionsInterface.tripsOrders,
    tripOrder.idPost
  );

  if (isDispatcher) {
    if (actualTripOrder?.driverTestimonial?.evaluatedPersonId) {
      checkResult = true;
    }
  } else {
    if (actualTripOrder?.dispatcherTestimonial?.evaluatedPersonId) {
      checkResult = true;
    }
  }

  return checkResult;
};

export const recalculateAverageMark = (testimonials) => {
  let count = 0;

  const aggregatedMark = testimonials.reduce((accum, curElement) => {
    if (curElement.shouldBeDisplayed) {
      count += 1;
      return accum + curElement.mark;
    } else {
      return accum;
    }
  }, 0);

  const updatedAverageMark =
    count === 0 ? 0 : (aggregatedMark / count).toFixed(2);

  return parseFloat(updatedAverageMark);
};

export const saveTestimonialToTripOrder = async (
  testimonialToSave,
  tripOrder,
  isDispatcher
) => {
  const currentTestimonialType = isDispatcher
    ? 'dispatcherTestimonial'
    : 'driverTestimonial';

  await updateFieldInDocumentInCollection(
    collectionsInterface.tripsOrders,
    tripOrder.idPost,
    currentTestimonialType,
    testimonialToSave
  );
};

export const updateTestimonialInTripOrder = async (tripOrder, isDispatcher) => {
  const counterPartyTestimonialType = !isDispatcher
    ? 'dispatcherTestimonial.shouldBeDisplayed'
    : 'driverTestimonial.shouldBeDisplayed';

  await updateFieldInDocumentInCollection(
    collectionsInterface.tripsOrders,
    tripOrder.idPost,
    counterPartyTestimonialType,
    true
  );
};

export const updateTestimonialForUser = async (
  evaluatedUserId,
  tripOrder,
  shouldTripBeAdded = false
) => {
  try {
    const actualUser = await getDocInCollection(
      collectionsInterface.users,
      evaluatedUserId
    );

    let targetTestimonial;

    const updatedTestimonials = Object.values(actualUser.testimonials).map(
      (testimonial) => {
        if (testimonial.dealId !== tripOrder.idPost) {
          return testimonial;
        } else {
          targetTestimonial = {
            ...testimonial,
            shouldBeDisplayed: true,
          };

          return targetTestimonial;
        }
      }
    );

    const updatedAverageMark = recalculateAverageMark(updatedTestimonials);

    let fieldsToUpdate = {
      [`testimonials.${targetTestimonial.idPost}`]: targetTestimonial,
      averageMark: updatedAverageMark,
    };

    if (shouldTripBeAdded) {
      const updatedTripsAmount = actualUser.tripsAsDispatcher + 1;

      fieldsToUpdate = {
        ...fieldsToUpdate,
        tripsAsDispatcher: updatedTripsAmount,
      };
    }

    await updateFieldsInDocumentInCollection(
      collectionsInterface.users,
      actualUser.idPost,
      fieldsToUpdate
    );

    await updateFieldInDocumentInCollection(
      collectionsInterface.testimonials,
      targetTestimonial.idPost,
      'shouldBeDisplayed',
      true
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const saveTestimonialToCollectionAndEvaluatedPerson = async (
  testimonialToSave,
  evaluatedPerson,
  isSecondTestimonialInTripOrder = false,
  isDispatcher = false,
  tripOrder,
  trip
) => {
  try {
    let fieldsToUpdate;

    let testimonial = testimonialToSave;

    if (isSecondTestimonialInTripOrder) {
      testimonial = {
        ...testimonial,
        shouldBeDisplayed: true,
      };
    }

    const savedTestimonial = await setDocumentToCollection(
      collectionsInterface.testimonials,
      testimonial
    );

    const testimonialId = savedTestimonial.result.id;

    testimonial = {
      ...testimonial,
      idPost: testimonialId,
    };

    const actualUser = await getDocInCollection(
      collectionsInterface.users,
      evaluatedPerson?.idPost
    );

    const updatedTestimonials = [
      ...Object.values(actualUser.testimonials),
      testimonial,
    ];

    if (isSecondTestimonialInTripOrder) {
      if (isDispatcher) {
        fieldsToUpdate = {
          [`testimonials.${testimonialId}`]: testimonial,
          averageMark: recalculateAverageMark(updatedTestimonials),
        };
      } else {
        const updatedTripsAmount = actualUser.tripsAsDispatcher + 1;

        fieldsToUpdate = {
          [`testimonials.${testimonialId}`]: testimonial,
          averageMark: recalculateAverageMark(updatedTestimonials),
          tripsAsDispatcher: updatedTripsAmount,
        };
      }
    } else {
      fieldsToUpdate = {
        [`testimonials.${testimonialId}`]: testimonial,
      };
    }

    await updateFieldsInDocumentInCollection(
      collectionsInterface.users,
      evaluatedPerson?.idPost,
      fieldsToUpdate
    );

    if (isDispatcher) {
      await sendMessageAboutEvaluationByDispatcher(
        trip,
        tripOrder,
        evaluatedPerson.idPost
      );
    } else {
      await sendMessageAboutEvaluationByDriver(trip, tripOrder);
    }
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Save evaluation
export const saveEvaluation = async (
  tripOrder,
  trip,
  user,
  isDispatcher,
  tripComment,
  evaluatedUsers,
  selectedIndex,
  handleEvaluateLater
) => {
  let testimonialToSave = {
    ...testimonialModel,
    author: {
      uid: user.uid,
      fullName: user.fullName,
      idPost: user.idPost,
    },
    evaluatedPersonId: evaluatedUsers[0]?.idPost,
    evaluatedPersonFullName: evaluatedUsers[0]?.fullName,
    text: tripComment,
    mark: selectedIndex + 1,
    dealId: tripOrder?.idPost,
    tripId: trip?.idPost,
  };

  try {
    const isSecondTestimonialInTripOrder = await checkIfSecondTestimonial(
      tripOrder,
      isDispatcher
    );

    await saveTestimonialToCollectionAndEvaluatedPerson(
      testimonialToSave,
      evaluatedUsers[0],
      isSecondTestimonialInTripOrder,
      isDispatcher,
      tripOrder,
      trip
    );

    if (isSecondTestimonialInTripOrder) {
      await updateTestimonialInTripOrder(tripOrder, isDispatcher);

      if (isDispatcher) {
        await updateTestimonialForUser(user.idPost, tripOrder, true);
      } else {
        for (const driver of trip.drivers) {
          await updateTestimonialForUser(driver.idPost, tripOrder, false);
        }
      }
    }

    if (isSecondTestimonialInTripOrder) {
      testimonialToSave = {
        ...testimonialToSave,
        shouldBeDisplayed: true,
      };
    }

    await saveTestimonialToTripOrder(
      testimonialToSave,
      tripOrder,
      isDispatcher,
      isSecondTestimonialInTripOrder
    );

    if (evaluatedUsers.length === 2) {
      await saveTestimonialToCollectionAndEvaluatedPerson(
        {
          ...testimonialToSave,
          evaluatedPersonId: evaluatedUsers[1]?.idPost,
          evaluatedPersonFullName: evaluatedUsers[1]?.fullName,
        },
        evaluatedUsers[1],
        isDispatcher,
        isSecondTestimonialInTripOrder,
        tripOrder,
        trip
      );
    }

    return true;
  } catch (error) {
    return false;
  }
};
//#endregion
//#endregion

//#region Functions for admin
//#region Statistic
export const getBaseQuery = (queryType) => {
  let baseQuery = [];
  switch (queryType) {
    case statisticQueryTypesInterface.adminUsers:
      baseQuery = [
        {
          key: 'role',
          value: rolesInterface.admin,
          operator: '==',
        },
      ];
      break;
    case statisticQueryTypesInterface.companyUsers:
      baseQuery = [
        {
          key: 'role',
          value: rolesInterface.user,
          operator: '==',
        },
        {
          key: 'isCompany',
          value: true,
          operator: '==',
        },
      ];
      break;
    case statisticQueryTypesInterface.driverUsers:
      baseQuery = [
        {
          key: 'role',
          value: rolesInterface.user,
          operator: '==',
        },
        {
          key: 'isDriverInCompany',
          value: true,
          operator: '==',
        },
      ];
      break;
    case statisticQueryTypesInterface.individualUsers:
      baseQuery = [
        {
          key: 'role',
          value: rolesInterface.user,
          operator: '==',
        },
        {
          key: 'isDriverInCompany',
          value: false,
          operator: '==',
        },
        {
          key: 'isCompany',
          value: false,
          operator: '==',
        },
      ];
      break;
    case statisticQueryTypesInterface.tripsCompleted ||
      statisticQueryTypesInterface.tripsOrdersCompleted:
      baseQuery = [
        {
          key: 'isCompleted',
          value: true,
          operator: '==',
        },
      ];
      break;
    case statisticQueryTypesInterface.tripsCancelled ||
      statisticQueryTypesInterface.tripsOrdersCancelled:
      baseQuery = [
        {
          key: 'isCancelled',
          value: true,
          operator: '==',
        },
      ];
      break;
    default:
      baseQuery = [];
      break;
  }

  return baseQuery;
};

export const getAllUsersQuantity = async () => {
  try {
    const result = await getCollectionLength(collectionsInterface.users);

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAdminUsersQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.adminUsers);

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.users,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getUsersAsCompanyQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.companyUsers);

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.users,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getUsersAsDriversQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.driverUsers);

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.users,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getUsersAsIndividualsQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(
      statisticQueryTypesInterface.individualUsers
    );

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.users,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAllTripsQuantity = async () => {
  try {
    const result = await getCollectionLength(collectionsInterface.trips);

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAllTripsCompletedQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.tripsCompleted);

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.trips,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAllTripsCancelledQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.tripsCancelled);

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.trips,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAllTripsOrdersQuantity = async () => {
  try {
    const result = await getCollectionLength(collectionsInterface.tripsOrders);

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAllTripsOrdersCompletedQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(
      statisticQueryTypesInterface.tripsOrdersCompleted
    );

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.tripsOrders,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getAllTripsOrdersCancelledQuantity = async () => {
  try {
    const baseQuery = getBaseQuery(
      statisticQueryTypesInterface.tripsOrdersCancelled
    );

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionsInterface.tripsOrders,
      baseQuery,
      baseQuery.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getMonthsToCompare = () => {
  const currentDate = new Date().setHours(0, 0, 0, 0);

  const startOfCurrentMonth = startOfMonth(currentDate);

  let lastDayOfPreviousMonth = subDays(startOfCurrentMonth, 1);

  const firstDayOfPreviousMonth = startOfMonth(lastDayOfPreviousMonth);

  const secondMonth = {
    startPoint: firstDayOfPreviousMonth.getTime(),
    endPoint: startOfCurrentMonth.getTime(),
  };

  lastDayOfPreviousMonth = subDays(firstDayOfPreviousMonth, 1);

  const firstDayOfFirstMonth = startOfMonth(lastDayOfPreviousMonth);

  const firstMonth = {
    startPoint: firstDayOfFirstMonth.getTime(),
    endPoint: firstDayOfPreviousMonth.getTime(),
  };

  return {
    firstMonth,
    secondMonth,
  };
};

export const getEntitiesQuantityForMonth = async (
  collectionName,
  baseQuery,
  startDate,
  endDate
) => {
  try {
    const queryArray = [
      ...baseQuery,
      {
        key: 'dateCreating',
        value: startDate,
        operator: '>=',
      },
      {
        key: 'dateCreating',
        value: endDate,
        operator: '<',
      },
    ];

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionName,
      queryArray,
      queryArray.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const calculateQuantityProgressForTwoPreviousMonths = async (
  collectionName,
  baseQuery
) => {
  const monthsToCompare = getMonthsToCompare();

  const comparedMonthData = await getEntitiesQuantityForMonth(
    collectionName,
    baseQuery,
    monthsToCompare.firstMonth.startPoint,
    monthsToCompare.firstMonth.endPoint
  );

  const comparableMonthData = await getEntitiesQuantityForMonth(
    collectionName,
    baseQuery,
    monthsToCompare.secondMonth.startPoint,
    monthsToCompare.secondMonth.endPoint
  );

  if (comparedMonthData === 0 && comparableMonthData === 0) {
    return 0;
  }

  if (comparedMonthData === 0) {
    return 100;
  }

  if (comparableMonthData === 0) {
    return -100;
  }

  return ((comparableMonthData / comparedMonthData - 1) * 100).toFixed(2);
};

export const calculateAllUsersQuantityProgressForTwoPreviousMonths =
  async () => {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.allUsers);

    try {
      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.users,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateUsersAsCompanyQuantityProgressForTwoPreviousMonths =
  async () => {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.companyUsers);

    try {
      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.users,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAdminUsersQuantityProgressForTwoPreviousMonths =
  async () => {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.adminUsers);

    try {
      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.users,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateUsersAsDriversQuantityProgressForTwoPreviousMonths =
  async () => {
    const baseQuery = getBaseQuery(statisticQueryTypesInterface.driverUsers);

    try {
      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.users,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateUsersAsIndividualsQuantityProgressForTwoPreviousMonths =
  async () => {
    const baseQuery = getBaseQuery(
      statisticQueryTypesInterface.individualUsers
    );

    try {
      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.users,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAllTripsQuantityProgressForTwoPreviousMonths =
  async () => {
    try {
      const baseQuery = getBaseQuery(statisticQueryTypesInterface.allTrips);

      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.trips,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAllCompletedTripsQuantityProgressForTwoPreviousMonths =
  async () => {
    try {
      const baseQuery = getBaseQuery(
        statisticQueryTypesInterface.tripsCompleted
      );

      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.trips,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAllCancelledTripsQuantityProgressForTwoPreviousMonths =
  async () => {
    try {
      const baseQuery = getBaseQuery(
        statisticQueryTypesInterface.tripsCancelled
      );

      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.trips,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAllTripsOrdersQuantityProgressForTwoPreviousMonths =
  async () => {
    try {
      const baseQuery = getBaseQuery(
        statisticQueryTypesInterface.allTripsOrders
      );

      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.tripsOrders,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAllCompletedTripsOrdersQuantityProgressForTwoPreviousMonths =
  async () => {
    try {
      const baseQuery = getBaseQuery(
        statisticQueryTypesInterface.tripsOrdersCompleted
      );

      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.tripsOrders,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const calculateAllCancelledTripsOrdersQuantityProgressForTwoPreviousMonths =
  async () => {
    try {
      const baseQuery = getBaseQuery(
        statisticQueryTypesInterface.tripsOrdersCancelled
      );

      const result = await calculateQuantityProgressForTwoPreviousMonths(
        collectionsInterface.tripsOrders,
        baseQuery
      );

      return result;
    } catch (error) {
      return 0;
    }
  };

export const getAllCommissionAmount = async () => {
  try {
    const allCommissionTransactions = await getCollectionWhereKeyValue(
      collectionsInterface.transactions,
      'type',
      transactionsTypesInterface.commissionTaking
    );

    const result = allCommissionTransactions.reduce(
      (accum, current) => accum + current.amount,
      0
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const getCommissionAmountPerCustomPeriod = async (
  startDate,
  endDate
) => {
  try {
    const commissionTransactionsPerCustomPeriod =
      await getCollectionWhereKeysValuesWithOperators(
        collectionsInterface.transactions,
        [
          {
            key: 'type',
            value: transactionsTypesInterface.commissionTaking,
            operator: '==',
          },
          {
            key: 'date',
            value: startDate,
            operator: '>=',
          },
          {
            key: 'date',
            value: endDate,
            operator: '<',
          },
        ],
        3
      );

    const result = commissionTransactionsPerCustomPeriod.reduce(
      (accum, current) => accum + current.amount,
      0
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const calculateCommissionAmountProgressForTwoPreviousMonths =
  async () => {
    const monthsToCompare = getMonthsToCompare();

    try {
      const comparedMonthData = await getCommissionAmountPerCustomPeriod(
        monthsToCompare.firstMonth.startPoint,
        monthsToCompare.firstMonth.endPoint
      );
      const comparableMonthData = await getCommissionAmountPerCustomPeriod(
        monthsToCompare.firstMonth.startPoint,
        monthsToCompare.firstMonth.endPoint
      );

      if (comparedMonthData === 0 && comparableMonthData === 0) {
        return 0;
      }

      if (comparedMonthData === 0) {
        return 100;
      }

      if (comparableMonthData === 0) {
        return -100;
      }

      return ((comparableMonthData / comparedMonthData - 1) * 100).toFixed(2);
    } catch (error) {
      return 0;
    }
  };
//#endregion

//#region Work with custom period
export const getEntitiesQuantityForCustomPeriod = async (
  collectionName,
  baseQuery,
  startDate,
  endDate,
  comparationKey
) => {
  try {
    const queryArray = [
      ...baseQuery,
      {
        key: comparationKey,
        value: startDate,
        operator: '>=',
      },
      {
        key: comparationKey,
        value: endDate,
        operator: '<',
      },
    ];

    const result = await getLengthOfCollectionWhereKeysValuesWithOperators(
      collectionName,
      queryArray,
      queryArray.length
    );

    return result;
  } catch (error) {
    return 0;
  }
};

export const setAllStatisticDataOnComponentMount = async () => {
  try {
    const allUsersQuantity = await getAllUsersQuantity();
    const allUsersProgress =
      await calculateAllUsersQuantityProgressForTwoPreviousMonths();
    const usersAsAdminQuantity = await getAdminUsersQuantity();
    const usersAsAdminProgress =
      await calculateAdminUsersQuantityProgressForTwoPreviousMonths();
    const usersAsCompanyQuantity = await getUsersAsCompanyQuantity();
    const usersAsCompanyProgress =
      await calculateUsersAsCompanyQuantityProgressForTwoPreviousMonths();
    const usersAsDriverQuantity = await getUsersAsDriversQuantity();
    const usersAsDriverProgress =
      await calculateUsersAsDriversQuantityProgressForTwoPreviousMonths();
    const usersAsIndividualQuantity = await getUsersAsIndividualsQuantity();
    const usersAsIndividualProgress =
      await calculateUsersAsIndividualsQuantityProgressForTwoPreviousMonths();
    const allTripsQuantity = await getAllTripsQuantity();
    const allTripsProgress =
      await calculateAllTripsQuantityProgressForTwoPreviousMonths();
    const completedTripsQuantity = await getAllTripsCompletedQuantity();
    const completedTripsProgress =
      await calculateAllCompletedTripsQuantityProgressForTwoPreviousMonths();
    const cancelledTripsQuantity = await getAllTripsCancelledQuantity();
    const cancelledTripsProgress =
      await calculateAllCancelledTripsQuantityProgressForTwoPreviousMonths();
    const allTripsOrdersQuantity = await getAllTripsOrdersQuantity();
    const allTripsOrdersProgress =
      await calculateAllTripsOrdersQuantityProgressForTwoPreviousMonths();
    const completedTripsOrdersQuantity =
      await getAllTripsOrdersCompletedQuantity();
    const completedTripsOrdersProgress =
      await calculateAllCompletedTripsOrdersQuantityProgressForTwoPreviousMonths();
    const cancelledTripsOrdersQuantity =
      await getAllTripsOrdersCancelledQuantity();
    const cancelledTripsOrdersProgress =
      await calculateAllCancelledTripsOrdersQuantityProgressForTwoPreviousMonths();
    const allCommissionAmount = await getAllCommissionAmount();
    const commissionProgress =
      await calculateCommissionAmountProgressForTwoPreviousMonths();

    const statistic = {
      allUsersNumber: {
        growth: allUsersProgress,
        quantity: allUsersQuantity,
      },
      usersAsAdmin: {
        growth: usersAsAdminProgress,
        quantity: usersAsAdminQuantity,
      },
      usersAsCompany: {
        growth: usersAsCompanyProgress,
        quantity: usersAsCompanyQuantity,
      },
      usersAsDriver: {
        growth: usersAsDriverProgress,
        quantity: usersAsDriverQuantity,
      },
      usersAsIndividual: {
        growth: usersAsIndividualProgress,
        quantity: usersAsIndividualQuantity,
      },
      trips: {
        growth: allTripsProgress,
        quantity: allTripsQuantity,
      },
      tripsCompleted: {
        growth: completedTripsProgress,
        quantity: completedTripsQuantity,
      },
      tripsCancelled: {
        growth: cancelledTripsProgress,
        quantity: cancelledTripsQuantity,
      },
      tripsOrders: {
        growth: allTripsOrdersProgress,
        quantity: allTripsOrdersQuantity,
      },
      tripsOrdersCompleted: {
        growth: completedTripsOrdersProgress,
        quantity: completedTripsOrdersQuantity,
      },
      tripsOrdersCancelled: {
        growth: cancelledTripsOrdersProgress,
        quantity: cancelledTripsOrdersQuantity,
      },
      commission: {
        growth: commissionProgress,
        quantity: allCommissionAmount,
      },
    };

    return statistic;
  } catch (error) {
    return statisticDataModel;
  }
};

export const recalculateAllStatisticForCustomPeriod = async (
  currentStatistic = statisticDataModel,
  startDate,
  endDate
) => {
  try {
    let statistic = {
      ...currentStatistic,
    };

    statistic.allUsersNumber.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.users,
        getBaseQuery(statisticQueryTypesInterface.allUsers),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.usersAsAdmin.quantity = await getEntitiesQuantityForCustomPeriod(
      collectionsInterface.users,
      getBaseQuery(statisticQueryTypesInterface.adminUsers),
      startDate,
      endDate,
      'dateCreating'
    );
    statistic.usersAsCompany.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.users,
        getBaseQuery(statisticQueryTypesInterface.companyUsers),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.usersAsDriver.quantity = await getEntitiesQuantityForCustomPeriod(
      collectionsInterface.users,
      getBaseQuery(statisticQueryTypesInterface.driverUsers),
      startDate,
      endDate,
      'dateCreating'
    );
    statistic.usersAsIndividual.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.users,
        getBaseQuery(statisticQueryTypesInterface.individualUsers),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.trips.quantity = await getEntitiesQuantityForCustomPeriod(
      collectionsInterface.trips,
      getBaseQuery(statisticQueryTypesInterface.allTrips),
      startDate,
      endDate,
      'dateCreating'
    );
    statistic.tripsCompleted.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.trips,
        getBaseQuery(statisticQueryTypesInterface.tripsCompleted),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.tripsCancelled.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.trips,
        getBaseQuery(statisticQueryTypesInterface.tripsCancelled),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.tripsOrders.quantity = await getEntitiesQuantityForCustomPeriod(
      collectionsInterface.tripsOrders,
      getBaseQuery(statisticQueryTypesInterface.allTripsOrders),
      startDate,
      endDate,
      'dateCreating'
    );
    statistic.tripsOrdersCompleted.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.tripsOrders,
        getBaseQuery(statisticQueryTypesInterface.tripsOrdersCompleted),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.tripsOrdersCancelled.quantity =
      await getEntitiesQuantityForCustomPeriod(
        collectionsInterface.tripsOrders,
        getBaseQuery(statisticQueryTypesInterface.tripsOrdersCancelled),
        startDate,
        endDate,
        'dateCreating'
      );
    statistic.commission.quantity = await getCommissionAmountPerCustomPeriod(
      collectionsInterface.transactions,
      getBaseQuery(statisticQueryTypesInterface.tripsOrdersCancelled),
      startDate,
      endDate
    );

    return statistic;
  } catch (error) {
    return statisticDataModel;
  }
};
//#endregion

//#region Manage users
export const deleteUserHandler = async (userId, setUsers) => {
  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.users,
      userId,
      'isVisibleForAdmin',
      false
    );

    setUsers((users) => users.filter((curUser) => curUser.idPost !== userId));
  } catch (error) {
    return error;
  }
};

export const deleteAdminHandler = async (user, setUsers) => {
  try {
    const response = await axios.delete(
      `${serverUrl}${endPointsInterface.deleteAdmin}/${user.uid}`
    );

    if (response.status === 200) {
      await deleteDocumentFromCollectionWithID(
        collectionsInterface.users,
        user.idPost
      );

      setUsers((users) =>
        users.filter((curUser) => curUser.idPost !== user.idPost)
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateAdmin = async (updatedFields, uid, idPost) => {
  try {
    const response = await axios.patch(
      `${serverUrl}${endPointsInterface.updateAdmin}/${uid}`,
      { fields: updatedFields }
    );

    if (response.status === 200) {
      const fieldsToSave = updatedFields;

      if (fieldsToSave.hasOwnProperty('password')) {
        delete fieldsToSave.password;
      }

      await updateFieldsInDocumentInCollection(
        collectionsInterface.users,
        idPost,
        updatedFields
      );

      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

//#region Handle user click, work with user
export const handleUserClick = async (userId, setEditingUser, navigate) => {
  const user = await getDocInCollection(collectionsInterface.users, userId);

  setEditingUser(user);

  navigate(`/${pagesInterface.usersManagement}/userData`);
};
//#endregion
//#endregion

//#region Handle trip click
export const handleTripClick = (
  tripOrder,
  trip,
  setCurrentReservation,
  setCurrentTripOrder,
  setCurrentTrip,
  navigate
) => {
  if (tripOrder?.idPost && trip?.idPost) {
    setCurrentTripOrder(tripOrder);
    setCurrentTrip(trip);
    setCurrentReservation(tripOrder.acceptedDeal);

    navigate(`/${pagesInterface.tripsAdmin}/activeTripDetails`);
  }
};

export const handleTripClickAndGetActualData = async (
  dealId,
  setCurrentReservation,
  setCurrentTripOrder,
  setCurrentTrip,
  navigate
) => {
  try {
    const tripOrder = await getDocInCollection(
      collectionsInterface.tripsOrders,
      dealId
    );
    const trip = await getDocInCollection(
      collectionsInterface.trips,
      tripOrder?.acceptedDeal?.originalTrip?.idPost
    );

    setCurrentReservation(tripOrder.acceptedDeal);
    setCurrentTripOrder(tripOrder);
    setCurrentTrip(trip);

    navigate(`/${pagesInterface.usersManagement}/trip`);

    return true;
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Catalogs
export const getCatalogName = (catalog) => {
  switch (catalog) {
    case catalogsNamesInterface.bodyTypes:
      return 'Типы кузова';
    case catalogsNamesInterface.brands:
      return 'Марки авто';
    case catalogsNamesInterface.models:
      return 'Модели авто';
    case catalogsNamesInterface.colors:
      return 'Цвета авто';
    case catalogsNamesInterface.options:
      return 'Дополнительные опции для поездок';
  }
};
//#endregion

//#region Work with add options
export const getOptionsAsCatalog = (options) => {
  return options.map((option) => ({
    isVisible: option.isVisible,
    id: option.id,
    ...option.optionContent,
  }));
};

export const checkAreAllDefaultNamesPresent = (optionsList) => {
  const result = optionsList.some(
    (option) => !option.optionContent.defaultName
  );

  return !result;
};
//#endregion

//#region Work with text pages
export const deletePageHandler = async (page, setPagesToDisplay) => {
  try {
    await deleteDocumentFromCollectionWithID(
      collectionsInterface.adminData,
      page.idPost
    );

    setPagesToDisplay((state) =>
      state.filter((curPage) => curPage.idPost !== page.idPost)
    );
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Work with tripsOrders
export const deleteTripOrderHandler = async (order, setTripsOrders) => {
  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.tripsOrders,
      order.idPost,
      'isVisibleForAdmin',
      false
    );

    setTripsOrders((state) =>
      state.filter((curOrder) => curOrder.idPost !== order.idPost)
    );
  } catch (error) {
    return error;
  }
};

export const getStatusTextForAdmin = (tripOrder) => {
  if (tripOrder.isCompleted && !tripOrder.isCancelled) {
    return 'Завершена';
  }

  if (tripOrder.isInProgress) {
    return 'В пути';
  }

  if (tripOrder.isCancelled) {
    return 'Отменена';
  }

  return 'Забронирована';
};

export const getPassengerRouteInTrip = (tripOrder, trip) => {
  if (
    tripOrder.startPoint.placeId === trip.startPoint.placeId &&
    tripOrder.endPoint.placeId === trip.endPoint.placeId
  ) {
    return trip.tripPoints.map((point) => ({
      lat: point.lat,
      lon: point.lon,
    }));
  }

  let route = [];

  const startPointIndexInTrip = trip.tripPoints.findIndex(
    (point) => point.placeId === tripOrder.startPoint.placeId
  );
  const endPointIndexInTrip = trip.tripPoints.findIndex(
    (point) => point.placeId === tripOrder.endPoint.placeId
  );

  route = trip.tripPoints.slice(startPointIndexInTrip, endPointIndexInTrip + 1);

  route = route.map((point) => ({
    lat: point.lat,
    lon: point.lon,
  }));

  return route;
};
//#endregion

//#region Work with trips
export const deleteTripHandler = async (trip, setTrips) => {
  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.trips,
      trip.idPost,
      'isVisibleForAdmin',
      false
    );

    setTrips((state) =>
      state.filter((curTrip) => curTrip.idPost !== trip.idPost)
    );
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Work with testimonials
export const deleteTestimonialHandler = async (
  testimonial,
  setTestimonials
) => {
  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.testimonials,
      testimonial.idPost,
      'isVisibleForAdmin',
      false
    );

    setTestimonials((state) =>
      state.filter(
        (curTestimonial) => curTestimonial.idPost !== testimonial.idPost
      )
    );
  } catch (error) {
    return error;
  }
};
//#endregion

//#region Work with transactions
export const getTransactionsArrayFromObject = (
  transactions,
  isWithdrawals = false
) => {
  if (typeof transactions === 'object') {
    return isWithdrawals
      ? Object.values(transactions)
      : Object.values(transactions).filter(
          (transaction) => transaction.isSuccessful
        );
  }

  return [];
};

export const deleteTransactionHandler = async (
  transaction,
  setTransactions
) => {
  try {
    await updateFieldInDocumentInCollection(
      collectionsInterface.transactions,
      transaction.idPost,
      'isVisibleForAdmin',
      false
    );

    setTransactions((state) =>
      state.filter(
        (curTransaction) => curTransaction.idPost !== transaction.idPost
      )
    );
  } catch (error) {
    return error;
  }
};
//#endregion
//#endregion

//#region Functions for displaying cards (sorting etc.)
export const sortCardsByDateOfCreation = (cardsData, asc = true) => {
  const dataCopy = [...cardsData];

  dataCopy.sort((prev, next) =>
    asc
      ? prev.dateCreating - next.dateCreating
      : next.dateCreating - prev.dateCreating
  );

  return dataCopy;
};
//#endregion

//#region Referrals
export const getReferralsList = async (referrerId) => {
  try {
    const referrals = await getCollectionWhereKeyValue(
      collectionsInterface.users,
      'referrerId',
      referrerId
    );

    return referrals;
  } catch (error) {
    return [];
  }
};

export const defineIfBonusReferral = (referrerId, referralId, transaction) => {
  const isBonusFromTheReferral =
    transaction.type === transactionsTypesInterface.bonusesForReferralDeal &&
    transaction.recipient.idPost === referrerId &&
    transaction.payer.idPost === referralId;

  return isBonusFromTheReferral;
};

export const getProfitAmountFromReferral = async (
  referrerId,
  referrerAccountId,
  referralId
) => {
  try {
    const referrerAccount = await getDocInCollection(
      collectionsInterface.balanceAccounts,
      referrerAccountId
    );

    const profitAmount = Object.values(referrerAccount.transactions).reduce(
      (accum, transaction) => {
        if (defineIfBonusReferral(referrerId, referralId, transaction)) {
          return accum + transaction.amount;
        }

        return accum;
      },
      0
    );

    return profitAmount;
  } catch (error) {
    return 0;
  }
};
//#endregion
