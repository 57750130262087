import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {getDatabase, ref, set, get} from "firebase/database";
import {
  collection,
  query,
  where,
  getDocs,
  getCountFromServer,
  or,
  orderBy,
  limit,
  deleteField,
  initializeFirestore,
} from "firebase/firestore";
import {searchTypesInCollections, userModel} from "./models";
import {createNewUser} from "./helpers";
import {
  ref as refForStorage,
  getStorage,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from "firebase/storage";

//#region Firebase creds and main variables
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSANGER_SENDING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const fire = firebase.initializeApp(firebaseConfig);
export const db = fire.firestore();
export const realTimeDb = getDatabase(fire,
  'https://international-driver-eu-default-rtdb.europe-west1.firebasedatabase.app/');
export const auth = getAuth();
//#endregion

//#region Auth
export function signUp(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
};

export async function signIn(email, password) {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);

    return result;
  } catch (error) {
    throw new Error(error);
  }
};

export async function googleSignIn() {
  const provider = new GoogleAuthProvider();

  try {
    const googleSignInResult = await signInWithPopup(auth, provider);

    const fullName = googleSignInResult.user.displayName;

    const names = fullName.split(' ');

    let firstName = '';
    let lastName = '';

    if (names.length > 1) {
      firstName = names[0];
      lastName = names[names.length - 1];
    }

    const currentDate = new Date();

    const newUser = {
      ...userModel,
      uid: googleSignInResult.user.uid,
      email: googleSignInResult.user.email || '',
      photoUrl: googleSignInResult.user.photoURL || '',
      phoneNumbers: [googleSignInResult.user.phoneNumber || ''],
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      isEmailVerified: googleSignInResult.user.emailVerified,
      tripOrdersLastObserveDate: currentDate,
      tripsLastObserveDate: currentDate,
    }

    await createNewUser(newUser);
  } catch (error) {
    return error;
  }
};

export async function facebookSignIn() {
  const provider = new FacebookAuthProvider();

  provider.addScope('user_birthday');

  try {
    const facebookSignInResult = await signInWithPopup(auth, provider);

    const fullName = facebookSignInResult.user.displayName;

    const names = fullName.split(' ');

    let firstName = '';
    let lastName = '';

    if (names.length > 1) {
      firstName = names[0];
      lastName = names[names.length - 1];
    }

    const currentDate = new Date();

    const newUser = {
      ...userModel,
      uid: facebookSignInResult.user.uid,
      email: facebookSignInResult.user.email || '',
      photoUrl: facebookSignInResult.user.photoURL || '',
      phoneNumbers: [facebookSignInResult.user.phoneNumber || ''],
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      isEmailVerified: facebookSignInResult.user.emailVerified,
      tripOrdersLastObserveDate: currentDate,
      tripsLastObserveDate: currentDate,
    }

    await createNewUser(newUser);

    return true;
  } catch (error) {
    return error;
  }
};

export async function appleSignIn() {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');

  try {
    const appleSignInResult = await signInWithPopup(auth, provider);

    const fullName = appleSignInResult.user.displayName;

    const names = fullName?.split(' ') || [];

    let firstName = '';
    let lastName = '';

    if (names.length > 1) {
      firstName = names[0];
      lastName = names[names.length - 1];
    }

    const currentDate = new Date();

    const newUser = {
      ...userModel,
      uid: appleSignInResult.user.uid,
      email: appleSignInResult.user?.email || '',
      photoUrl: appleSignInResult.user?.photoURL || '',
      phoneNumbers: [appleSignInResult.user.phoneNumber || ''],
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      isEmailVerified: appleSignInResult?.user?.emailVerified || false,
      tripOrdersLastObserveDate: currentDate,
      tripsLastObserveDate: currentDate,
    }

    await createNewUser(newUser);
  } catch (error) {
    return error;
  }
};

export const logOut = (setUser = () => {}) => {
  return new Promise((resolve, reject) => {
    firebase.auth().signOut().then(() => {
      setUser(userModel);
      resolve(true);
    }).catch((error) => {
      reject(error);
    })
  });
};
//#endregion

//#region Work with DB
export async function getCollection(collection) {
  return new Promise(await function (resolve, reject) {
    fire.firestore().collection(collection).get().then(res => {
      const data = [];
      res.forEach(doc => {
        data.push({
          idPost: doc.id,
          ...doc.data()
        })
      });
      resolve(data)
    }).catch(err => {
      reject(err);
    });
  });
};

export function getDocInCollection(collection, id) {
  return new Promise(function (resolve, reject) {
    try {
      fire.firestore().collection(collection).doc(id)
        .get()
        .then(querySnapshot => {
          resolve(querySnapshot.data());
        });
    } catch (e) {
      reject(e);
    }
  })
};

export function getCollectionWhereKeyValue(collection, key, value) {
  return new Promise(function (resolve, reject) {
    fire.firestore().collection(collection).where(key, "==", value)
      .get().then(res => {
      const data = [];
      res.forEach(doc => {
        data.push({
          idPost: doc.id,
          ...doc.data(),
        })
      });
      resolve(data)
    }).catch(err => {
      reject(err);
    });
  });
};

export function getQueryRef(collectionRef, queryArray, keysNumber) {
  let queryRef;

  try {
    switch (keysNumber) {
      case 1:
        queryRef = query(collectionRef, where(queryArray[0].key, '==', queryArray[0].value));
        break;
      case 2:
        queryRef = query(collectionRef, where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value));
        break;
      case 3:
        queryRef = query(collectionRef, where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value),
          where(queryArray[2].key, '==', queryArray[2].value));
        break;
      case 4:
        queryRef = query(collectionRef, where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value),
          where(queryArray[2].key, '==', queryArray[2].value),
          where(queryArray[3].key, '==', queryArray[3].value));
        break;
      case 5:
        queryRef = query(collectionRef, where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value),
          where(queryArray[2].key, '==', queryArray[2].value),
          where(queryArray[3].key, '==', queryArray[3].value),
          where(queryArray[4].key, '==', queryArray[4].value));
        break;
    };

    return queryRef;
  } catch (error) {
    return error;
  }
};

export function getQueryRefWithOrCondition(collectionRef, queryArray, keysNumber) {
  let queryRef;

  try {
    switch (keysNumber) {
      case 1:
        queryRef = query(collectionRef,
          where(queryArray[0].key, '==', queryArray[0].value));
        break;
      case 2:
        queryRef = query(collectionRef, or(where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value)));
        break;
      case 3:
        queryRef = query(collectionRef, or(where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value),
          where(queryArray[2].key, '==', queryArray[2].value)));
        break;
      case 4:
        queryRef = query(collectionRef, or(where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value),
          where(queryArray[2].key, '==', queryArray[2].value),
          where(queryArray[3].key, '==', queryArray[3].value)));
        break;
      case 5:
        queryRef = query(collectionRef, or(where(queryArray[0].key, '==', queryArray[0].value),
          where(queryArray[1].key, '==', queryArray[1].value),
          where(queryArray[2].key, '==', queryArray[2].value),
          where(queryArray[3].key, '==', queryArray[3].value),
          where(queryArray[4].key, '==', queryArray[4].value)));
        break;
    }
    ;

    return queryRef;
  } catch (error) {
    return error;
  }
};

export function getQueryRefWithOrConditionWithOperator(collectionRef, queryArray, keysNumber) {
  let queryRef;

  try {
    switch (keysNumber) {
      case 1:
        queryRef = query(collectionRef,
          where(queryArray[0].key, queryArray[0].operator, queryArray[0].value));
        break;
      case 2:
        queryRef = query(collectionRef, or(
          where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value)));
        break;
      case 3:
        queryRef = query(collectionRef, or(
          where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value),
          where(queryArray[2].key, queryArray[2].operator, queryArray[2].value)));
        break;
      case 4:
        queryRef = query(collectionRef, or(
          where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value),
          where(queryArray[2].key, queryArray[2].operator, queryArray[2].value),
          where(queryArray[3].key, queryArray[3].operator, queryArray[3].value)));
        break;
      case 5:
        queryRef = query(collectionRef, or(
          where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value),
          where(queryArray[2].key, queryArray[2].operator, queryArray[2].value),
          where(queryArray[3].key, queryArray[3].operator, queryArray[3].value),
          where(queryArray[4].key, queryArray[4].operator, queryArray[4].value)));
        break;
    }
    ;

    return queryRef;
  } catch (error) {
    return error;
  }
};

export function getQueryRefWithOperator(collectionRef, queryArray, keysNumber) {
  let queryRef;

  try {
    switch (keysNumber) {
      case 1:
        queryRef = query(collectionRef, where(queryArray[0].key, queryArray[0].operator, queryArray[0].value));
        break;
      case 2:
        queryRef = query(collectionRef, where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value));
        break;
      case 3:
        queryRef = query(collectionRef, where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value),
          where(queryArray[2].key, queryArray[2].operator, queryArray[2].value));
        break;
      case 4:
        queryRef = query(collectionRef, where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value),
          where(queryArray[2].key, queryArray[2].operator, queryArray[2].value),
          where(queryArray[3].key, queryArray[3].operator, queryArray[3].value));
        break;
      case 5:
        queryRef = query(collectionRef, where(queryArray[0].key, queryArray[0].operator, queryArray[0].value),
          where(queryArray[1].key, queryArray[1].operator, queryArray[1].value),
          where(queryArray[2].key, queryArray[2].operator, queryArray[2].value),
          where(queryArray[3].key, queryArray[3].operator, queryArray[3].value),
          where(queryArray[4].key, queryArray[4].operator, queryArray[4].value));
        break;
    };

    return queryRef;
  } catch (error) {
    return error;
  }
}

export function getQueryRefWithOperatorWithUnlimitedQueryArray(collectionRef, queryArray) {
  let queryRef;

  try {
    const whereClauses = queryArray.map(item =>
      where(item.key, item.operator || '==', item.value)
    );

    queryRef = query(collectionRef, ...whereClauses);

    return queryRef;
  } catch (error) {
    throw new Error(error.message);
  }
}

export function getQueryRefWithOperatorOrderingAndLimit(collectionRef,
  queryArray, orderKey, asc = true, limitNumber) {
  let queryRef;

  try {
    const whereClauses = queryArray.map(item =>
      where(item.key, item.operator || '==', item.value)
    );

    queryRef = query(collectionRef, ...whereClauses, orderBy(orderKey, asc ? 'asc' : 'desc'),
      limit(limitNumber));

    return queryRef;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getDataArrayFromCollectionWithoutQueryArrayLimit(
  collectionName, queryArray) {
  try {
    const collectionRef = collection(db, collectionName);

    let queryRef = getQueryRefWithOperatorWithUnlimitedQueryArray(collectionRef,
      queryArray);

    const snapshot = await getDocs(queryRef);

    const data = snapshot.docs.map(doc => ({
      ...doc.data(),
      idPost: doc.id,
    }));

    return data;
  } catch (error) {
    return [];
  }
}

export async function getDataArrayFromCollection(collectionName, queryArray,
  keysNumber, type, orderKey = 'idPost', asc = true, limitNumber = 10) {
  let snapshot = [];

  if (keysNumber < 1) {
    return [];
  }

  if (keysNumber > 5) {
    return [];
  }

  if (queryArray.length !== keysNumber) {
    return [];
  }

  try {
    const collectionRef = collection(db, collectionName);

    let queryRef = {};

    switch (type) {
      case searchTypesInCollections.keysValues:
        queryRef = getQueryRef(collectionRef, queryArray, keysNumber);
        break;
      case searchTypesInCollections.withOperator:
        queryRef = getQueryRefWithOperator(collectionRef, queryArray, keysNumber);
        break;
      case searchTypesInCollections.anyKey:
        queryRef = getQueryRefWithOrCondition(collectionRef, queryArray, keysNumber);
        break;
      case searchTypesInCollections.anyKeyWithOperator:
        queryRef = getQueryRefWithOrConditionWithOperator(collectionRef, queryArray, keysNumber);
        break;
      case searchTypesInCollections.orderedAndLimitedWithOperator:
        queryRef = getQueryRefWithOperatorOrderingAndLimit(collectionRef,
          queryArray, orderKey, asc, limitNumber);
        break;
    }

    snapshot = await getDocs(queryRef);

    const data = snapshot.docs.map(doc => ({
      ...doc.data(),
      idPost: doc.id,
    }));

    return data;
  } catch (error) {
    return [];
  }
}

export async function getCollectionWhereKeysValues(collectionName, queryArray, keysNumber) {
  if (keysNumber < 1) {
    return [];
  }

  if (keysNumber > 5) {
    return [];
  }

  if (queryArray.length !== keysNumber) {
    return [];
  }

  try {
    const data = await getDataArrayFromCollection(collectionName, queryArray,
      keysNumber, searchTypesInCollections.keysValues);

    return data;
  } catch (error) {
    return [];
  }
}

export async function getCollectionWhereKeysValuesWithOperators(collectionName,
  queryArray, keysNumber) {
  if (keysNumber < 1) {
    return [];
  }

  if (keysNumber > 5) {
    return [];
  }

  if (queryArray.length !== keysNumber) {
    return [];
  }

  try {
    const data = await getDataArrayFromCollection(collectionName, queryArray,
      keysNumber, searchTypesInCollections.withOperator);

    return data;
  } catch (error) {
    return [];
  }
}

export async function getCollectionWhereKeysValuesWithOperatorsWithoutQueryLimit(
  collectionName, queryArray) {
  try {
    const data = await getDataArrayFromCollectionWithoutQueryArrayLimit(
      collectionName, queryArray);

    return data;
  } catch (error) {
    return [];
  }
}

export async function getCollectionWhereAnyOfKeysValues(collectionName,
  queryArray, keysNumber) {
  if (keysNumber < 1) {
    return [];
  }

  if (keysNumber > 5) {
    return [];
  }

  if (queryArray.length !== keysNumber) {
    return [];
  }

  try {
    const data = await getDataArrayFromCollection(collectionName, queryArray,
      keysNumber, searchTypesInCollections.anyKey);

    return data;
  } catch (error) {
    return [];
  }
}

export async function getCollectionWhereKeysValuesWithOperatorOrderedAndLimited(
  collectionName, queryArray, orderKey, asc = true, limitNumber) {

  try {
    const data = await getDataArrayFromCollection(collectionName, queryArray,
      queryArray.length, searchTypesInCollections.orderedAndLimitedWithOperator,
      orderKey, asc, limitNumber);

    return data;
  } catch (error) {
    return [];
  }
}

export async function setDocumentToCollection(collection, document) {
  return new Promise(function (resolve, reject) {
    try {
      fire.firestore().collection(collection).add(document)
        .then(r => {
          updateDocumentInCollection(collection, {
            ...document,
            idPost: r.id
          }, r.id).catch(() => {});

          resolve({result: r});
        }).catch(e => {
        reject(e);
      })
    } catch (e) {
      reject(e);
    }
  });
};

export function updateDocumentInCollection(collection, document, idDocument) {
  return new Promise(function (resolve, reject) {
    try {
      fire.firestore().collection(collection).doc(idDocument)
        .update(document).then(r => {
        resolve({result: r})
      }).catch(e => {
        reject(e);
      })
    } catch (e) {
      reject(e);
    }
  })
};

export function deleteDocumentFromCollectionWithID(collection, idPost) {
  return new Promise(function (resolve, reject) {
    try {
      fire.firestore().collection(collection).doc(idPost).delete()
        .then(result => {
          resolve(result)
        }).catch(function (error) {
        reject(error)
      });
    } catch (e) {
      reject(e)
    }
  })
};

export async function updateFieldInDocumentInCollection(collection,
  docId, fieldName, newValue) {
  let result;

  try {
    const docRef = fire.firestore().collection(collection).doc(docId);
    result = await docRef.update({[fieldName]: newValue});
  } catch (error) {
    return error;
  }

  return result;
};

export async function deleteFieldInDocumentInCollection(collection,
  docId, fieldName) {
  let result;

  try {
    const docRef = db.collection(collection).doc(docId);
    result = await docRef.update({[fieldName]: deleteField()});
  } catch (error) {
    return error;
  }

  return result;
};

export async function updateFieldsInDocumentInCollection(collection, docId,
  fieldsToUpdateObject) {

  let result;

  try {
    const docRef = fire.firestore().collection(collection).doc(docId);
    result = await docRef.update(fieldsToUpdateObject);
  } catch (error) {
    return error;
  }

  return result;
};
//#endregion

//#region Work with RDB
export const setDocumentToRdb = async (path, document) => {
  try {
    await set(ref(realTimeDb, path), document);

    return true;
  } catch (error) {
    return false;
  }
};

export const getDocumentFromRdb = async (path) => {
  try {
    const dbRef = ref(realTimeDb, path);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return {};
    }

    return true;
  } catch (error) {
    return false;
  }
}
//#endregion

//#region Aggregation functions
export const getCollectionLength = async (collectionName) => {
  const collectionRef = collection(db, collectionName);
  const snapshot = await getCountFromServer(collectionRef);

  return snapshot.data().count;
}

export const getLengthOfCollectionWhereKeysValuesWithOperators = async (
  collectionName, queryArray, keysNumber) => {
  const collectionRef = collection(db, collectionName);

  const queryRef = getQueryRefWithOperator(collectionRef, queryArray, keysNumber);

  const snapshot = await getCountFromServer(queryRef);

  return snapshot.data().count;
}
//#endregion

//#region Work with storage
export const uploadFileToStorageAndGetLink = async (file, storagePath) => {
  return new Promise((resolve, reject) => {
    try {
      const storageRef = refForStorage(getStorage(), `${storagePath}/${Date.now()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      let fileLink = '';

      uploadTask.on("state_changed", (snapshot) => {
        }, (err) => {}, async () => {
          fileLink = await getDownloadURL(uploadTask.snapshot.ref);

          resolve(fileLink);
        }
      );
    } catch (error) {
      reject(error);
    }
  });
}

export const deleteFileFromStorage = async (filePath) => {
  try {
    const storage = getStorage();

    const fileRef = refForStorage(storage, filePath);

    await deleteObject(fileRef);

    return true;
  } catch (error) {
    return error;
  }
}
//#endregion
