import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  deleteTripHandler,
  showFormattedToLanguageDate
} from "../utils/helpers";
import {useNavigate} from "react-router-dom";
import {getDocInCollection} from "../utils/firebaseConfigAndFunctions";
import {
  collectionsInterface,
  pagesInterface,
  tripModel,
} from "../utils/models";
import {FaTrash} from "react-icons/fa";
import ReactTable from "react-table-v6";
import {MdOutlineSlideshow} from "react-icons/md";
import ModalNotification from "../modals/ModalNotification";
import Loader from "./Loader";
import FiltersControls from "./FiltersControls";
import {getTranslatedLocalityName} from "../utils/placesService";
import useTranslation from "../hooks/useTranslation";

const TripsListForAdmin = ({
  addClasses = '',
  trips = [],
  setTrips = () => {},
  isLoading = false,
}) => {
  //#region Get dictionaries from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get editing user from context
  const {setEditingUser} = useContext(AppContext);
  //#endregion

  //#region Get tripOrder, trip, reservation data from context
  const {setCurrentTrip} = useContext(AppContext);
  //#endregion

  //#region Set screen title and clear currentTripOrder
  useEffect(() => {
    setCurrentTrip(tripModel);
  }, []);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  //#endregion

  //#region Data for React table
  //#region Search and filters
  const [searchText, setSearchText] = useState('');
  const [appliedFilters, setAppliedFilters] = useState('');
  const statusesList = [{
    name: 'Активная',
    value: 'active',
  }, {
    name: 'Неактивная',
    value: 'notActive',
  }];

  const getStatusName = (status) => {
    if (status === 'active') {
      return translate('Активная');
    }

    return translate('Неактивная');
  };
  //#endregion

  const getTripsToDisplay = (searchText, filtersData, trips) => {
    let searchResult = [];

    if (searchText) {
      searchResult = trips.filter(trip => {
        let res = false;

        if (trip.hasOwnProperty('drivers')) {
          if (trip.drivers.some(driver => driver.fullName.toUpperCase()
            .includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        if (trip?.startPoint?.hasOwnProperty('city')) {
          if (Object.values(trip.startPoint.city).some(city => city
            .toUpperCase().includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        if (trip?.endPoint?.hasOwnProperty('city')) {
          if (Object.values(trip.endPoint.city).some(city => city.toUpperCase()
            .includes(searchText.toUpperCase()))) {
            res = true;
          }
        }

        return res;
      });
    } else {
      searchResult = trips;
    }

    let result = [];

    if (filtersData) {
      if (Object.values(filtersData).every(value => !value && value !== 0)) {
        result = searchResult;
      } else {
        result = searchResult.filter(trip => {
          let res = true;

          if (filtersData.status === 'active' && !trip.isActive) {
            return false;
          }

          if ((filtersData.status === 'notActive' && trip.isActive)) {
            return false;
          }

          if (filtersData.date) {
            const dateAsTimestamp = new Date(filtersData.date).getTime();

            if (dateAsTimestamp !== trip.startDateWithoutTime) {
              return false;
            }
          }

          if (filtersData.amount) {
            if (trip.pricePerPerson < (Number(filtersData?.amount.from || 0))
              || trip.pricePerPerson > Number(filtersData.amount.to || 0)) {
              return false;
            }
          }

          return res;
        });
      }
    } else {
      result = searchResult;
    }

    return result;
  };

  const handleTripClick = async (trip) => {
    try {
      const tripCreator = await getDocInCollection(collectionsInterface.users,
        trip.creatorIdPost);

      setEditingUser(tripCreator);
      setCurrentTrip(trip);

      navigate(`/${pagesInterface.tripsAdmin}/tripEditing`);
    } catch (error) {
      return error;
    }
  };

  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'startDate',
      width: 150,
      Header: translate('Время выезда'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {!e.startDate ?
          translate('Не указано')
          :
          showFormattedToLanguageDate(lang, e.startDate, true)
        }
      </div>
    },
    {
      id: 'driverName',
      minWidth: 100,
      Header: translate('Водитель'),
      accessor: e =>
        <div>
          {e.drivers ?
            e.drivers.map((driver, index) =>
              <div key={index}>
                {driver.fullName}
              </div>)
            :
            translate('Не указано')
          }
        </div>
    },
    {
      id: 'from',
      minWidth: 100,
      Header: translate('Откуда'),
      accessor: e =>
        <div>
          {!e?.startPoint?.city ?
            translate('Не указано')
            :
            getTranslatedLocalityName(e.startPoint.city, lang)
          }
        </div>
    },
    {
      id: 'to',
      minWidth: 100,
      Header: translate('Куда'),
      accessor: e =>
        <div>
          {!e?.endPoint?.city ?
            translate('Не указано')
            :
            getTranslatedLocalityName(e.endPoint.city, lang)
          }
        </div>
    },
    {
      id: 'price',
      Header: translate('Цена'),
      accessor: e =>
        <div>
          {!e?.pricePerPerson ?
            0
            :
            e.pricePerPerson
          }
        </div>
    },
    {
      id: 'status',
      Header: translate('Статус'),
      accessor: e =>
        <div>
          {e?.isActive ? translate('Активная') : translate('Неактивная')
          }
        </div>
    },
    {
      id: 'editAndDelete',
      Header: '',
      width: 80,
      accessor: e =>
        <div>
          <div
            className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}
          >
            <button
              type={'button'}
              className={'ShowButton'}
              onClick={() => handleTripClick(e)}
            >
              <MdOutlineSlideshow/>
            </button>

            <button
              type={'button'}
              className={'DelButton'}
              onClick={() => deleteTripHandler(e, setTrips)}
            >
              <FaTrash/>
            </button>
          </div>
        </div>
    },
  ];
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`TripsListForAdmin ${addClasses}`}>
          <FiltersControls
            statusesList={statusesList}
            priceFilterName={'Цена'}
            searchText={searchText}
            getStatusName={getStatusName}
            setSearchText={setSearchText}
            setAppliedFilters={setAppliedFilters}
          />

          <ReactTable
            data={getTripsToDisplay(searchText, appliedFilters, trips)}
            columns={columns}
            defaultPageSize={10}
            pageSize={10}
            showPageSizeOptions={false}
            showPaginationTop={true}
            showPaginationBottom={false}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}
          />

          <div className={'PaddingBlock'}></div>

          {isNotification &&
            <ModalNotification
              showModal={isNotification}
              setShowModal={setIsNotification}
              title={''}
              message={''}
            />
          }
        </div>
      }
    </>
  );
  //#endregion
};

export default TripsListForAdmin;
