import React from 'react';
import {getCatalogName} from "../utils/helpers";
import {AiFillSave} from "react-icons/ai";
import {catalogsNamesInterface} from "../utils/models";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill
} from "react-icons/bs";
import Loader from "./Loader";
import useTranslation from "../hooks/useTranslation";

const DisplayBrandsForTranslation = ({
  addClasses = '',
  catalogId = '',
  brands = [],
  currentBrand = {},
  setCurrentBrand = () => {},
  currentBrandIndex = 0,
  setCurrentBrandIndex = () => {},
  saveCatalog = () => {},
  dictionariesInfo = [],
  updateCatalog = () => {},
  isLoading = false,
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Switch brand
  const switchBrandBack = () => {
    setCurrentBrand(brands[currentBrandIndex - 1]);
    setCurrentBrandIndex(state => state - 1);
  };

  const switchBrandForth = () => {
    setCurrentBrand(brands[currentBrandIndex + 1]);
    setCurrentBrandIndex(state => state + 1);
  };
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`DisplayBrandsForTranslation ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small'}>
            {translate(getCatalogName(catalogsNamesInterface.brands))}
          </h2>

          <div className={'FlexBlock'}>
            <button
              type={'button'}
              disabled={currentBrandIndex === 0}
              onClick={() => switchBrandBack()}
              className={currentBrandIndex === 0 ?
                'SwitchButton SwitchButton_Disabled' : 'SwitchButton'}
            >
              <BsFillArrowLeftSquareFill/>
            </button>

            <h2 className={'RegularTitle RegularTitle_Small TitleWithButton'}>
              {currentBrand?.defaultName}

              <button
                className={'SaveButton'}
                type={'button'}
                onClick={() => saveCatalog(catalogId)}
              >
                <AiFillSave/>
              </button>
            </h2>

            <button
              type={'button'}
              className={currentBrandIndex === brands.length - 1 ?
                'SwitchButton SwitchButton_Disabled' : 'SwitchButton'}
              disabled={currentBrandIndex === brands.length - 1}
              onClick={() => switchBrandForth()}
            >
              <BsFillArrowRightSquareFill/>
            </button>
          </div>

          <div className={'PaddingBlock'}></div>

          <table className={'DataTableForAdmin'}>
            <thead>
            <tr>
              <th
                className={'DataTableForAdmin-Cell HeaderCell'}
              >
                {translate('Дефолт')}
              </th>

              {dictionariesInfo.map((dictionary, index) =>
                <React.Fragment key={index}>
                  <th
                    className={'DataTableForAdmin-Cell HeaderCell'}
                  >
                    {dictionary.name.toUpperCase()}
                  </th>
                </React.Fragment>
              )}
            </tr>
            </thead>

            <tbody>
            <tr className={'Catalog-DataRow'}>
              <td className={'Catalog-FirstRowCell'}>
                <textarea
                  value={currentBrand.defaultName}
                  onChange={(event) => updateCatalog(event.target.value,
                    currentBrand.idPost, 'defaultName',
                    catalogsNamesInterface.brands)}
                  className={'FormControl Catalog-TextArea'}
                />
              </td>

              {dictionariesInfo.map((dictionary, dIndex) =>
                <React.Fragment key={dIndex}>
                  <td className={'Catalog-FirstRowCell'}>
                    <textarea
                      value={currentBrand[dictionary.name]}
                      onChange={(event) => updateCatalog(event.target.value,
                        currentBrand.idPost, dictionary.name,
                        catalogsNamesInterface.brands)}
                      className={'FormControl Catalog-TextArea'}
                    />
                  </td>
                </React.Fragment>
              )}
            </tr>
            </tbody>
          </table>
        </div>
      }
    </>
  );
  //#endregion
};

export default DisplayBrandsForTranslation;
