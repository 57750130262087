import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import {
  convertSchemeIntoTable,
  getCountryName,
  getVehicleData,
} from "../utils/helpers";
import PlacesSchemeDraw from "./PlacesSchemeDraw";
import LoaderSmall from "./LoaderSmall";
import {Nav, Tab} from "react-bootstrap";
import DisplayVehicleTrips from "./DisplayVehicleTrips";
import {
  deleteDocumentFromCollectionWithID,
  getCollectionWhereKeysValuesWithOperators
} from "../utils/firebaseConfigAndFunctions";
import {collectionsInterface, pagesInterface} from "../utils/models";
import AddTripForDriverOrVehicle from "./AddTripForDriverOrVehicle";
import '../styles/DisplayVehicleDetails.scss';
import {ONE_DAY} from "../utils/data";
import ModalDeleteIndicationTrip from "../modals/ModalDeleteIndicationTrip";
import {useNavigate} from "react-router-dom";
import ModalNotification from "../modals/ModalNotification";
import {busyInTripsService} from "../utils/busyInTripsService";
import useTranslation from "../hooks/useTranslation";

const DisplayVehicleDetails = ({
  addClasses = '',
  vehicle,
  goBack = () => {},
  setCurrentVehicle = () => {},
}) => {
  //#region Get user from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Get language from context
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Get country name, brand name, model name
  const [names, setNames] = useState({});

  useEffect(() => {
    getVehicleData(lang, vehicle.brand, vehicle.model, setNames, setIsLoading,
      vehicle.color, vehicle.bodyType).catch(() => {});
  }, [lang]);
  //#endregion

  //#region Handle edit click
  const handleEditVehicleClick = async () => {
    const isVehicleInAnyTrip = await busyInTripsService
      .checkIsThereTripWithVehicle(vehicle.idPost, user.idPost);

    if (isVehicleInAnyTrip) {
      setIsNotification(true);
      setNotificationTitle('Уведомление');
      setNotificationMessage("Нельзя редактировать транспортное средство," +
        " занятое в поездке");

      return;
    }

    setCurrentVehicle(vehicle);
    navigate(`/${pagesInterface.profile}/myVehicles/editVehicle`)
  }
  //#endregion

  //#region Tab keys
  const [activeTabKey, setActiveTabKey] = useState('info');
  //#endregion

  //#region Trips
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    getCollectionWhereKeysValuesWithOperators(collectionsInterface.vehiclesTrips,
      [{
        key: 'vehicleId',
        value: vehicle.idPost,
        operator: '==',
      },  {
        key: 'endDate',
        value: (new Date().getTime() - ONE_DAY),
        operator: '>=',
      }], 2).then(vehicleTrips => setTrips(vehicleTrips.sort(
        (prev, next) => next.startDate - prev.startDate)))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  //#endregion

  //#region Delete trip modal
  const [isModal, setIsModal] = useState(false);
  const [tripIdToDelete, setTripIdToDelete] = useState(null);

  const deleteTripClick = (tripId) => {
    setIsModal(true);
    setTripIdToDelete(tripId);
  }
  //#endregion

  //#region Handle delete trip
  const deleteVehicleTrip = async (tripId) => {
    try {
      await deleteDocumentFromCollectionWithID(collectionsInterface
        .vehiclesTrips, tripId);

      setTrips(trips => trips
        .filter(trip => trip.idPost !== tripId)
        .sort((prev, next) => next.startDate - prev.startDate));

      setIsModal(false);
    } catch (error) {
      return error;
    }
  }
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Render
  return (
    <>
      <div className={'PaddingBlock PaddingBlock_Half'}></div>

      <Tab.Container
        activeKey={activeTabKey}
      >
        <Nav variant="pills" className={'flex-row TabControls'}>
          <Nav.Item>
            <Nav.Link
              eventKey='info'
              onClick={() => setActiveTabKey('info')}
            >
              {translate('Информация')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='trips'
              onClick={() => setActiveTabKey('trips')}
            >
              {translate('Поездки')}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='addTrip'
              onClick={() => setActiveTabKey('addTrip')}
            >
              {translate('Добавить поездку')}
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey='info'>
            <div className={`DisplayVehicleDetails ${addClasses}`}>
              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Марка авто')}
                </label>

                {isLoading ? <LoaderSmall />
                  :
                  <div className={'FormControl DisplayVehicleDetails-Input'}>
                    {names?.brandName}
                  </div>
                }
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Модель авто')}
                </label>

                {isLoading ? <LoaderSmall/>
                  :
                  <div className={'FormControl DisplayVehicleDetails-Input'}>
                    {names?.modelName}
                  </div>
                }
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Тип кузова')}
                </label>

                {isLoading ? <LoaderSmall/>
                  :
                  <div className={'FormControl DisplayVehicleDetails-Input'}>
                    {names?.bodyTypeName}
                  </div>
                }
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Цвет транспорта')}
                </label>

                {isLoading ? <LoaderSmall />
                  :
                  <div className={'FormControl DisplayVehicleDetails-Input'}>
                    {names?.colorName}
                  </div>
                }
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Страна')}
                </label>

                <div className={'FormControl DisplayVehicleDetails-Input'}>
                  {getCountryName(lang, vehicle.registrationCountry)}
                </div>
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Номер авто')}
                </label>

                <div
                  className={'FormControl DisplayVehicleDetails-Input'}
                >
                  {vehicle?.licensePlate}
                </div>
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Год выпуска транспорта')}
                </label>

                <div className={'FormControl DisplayVehicleDetails-Input'}>
                  {vehicle?.productionDate}
                </div>
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Количество мест')}
                </label>

                <div className={'FormControl DisplayVehicleDetails-Input'}>
                  {vehicle?.placesNumber}
                </div>
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Вид разрешающего документа')}
                </label>

                <div className={'FormControl DisplayVehicleDetails-Input'}>
                  {vehicle?.permissionType}
                </div>
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Номер документа')}
                </label>

                <div className={'FormControl DisplayVehicleDetails-Input'}>
                  {vehicle?.permissionNumber}
                </div>
              </div>

              <div className={'DisplayVehicleDetails-Item'}>
                <label
                  className={'DisplayVehicleDetails-Label'}
                >
                  {translate('Вид рассадки')}
                </label>

                <div className={'FormControl DisplayVehicleDetails-Input'}>
                  {vehicle.isFreeSeating ? translate('Свободная рассадка')
                    : translate('Схема рассадки')}
                </div>
              </div>

              {!vehicle.isFreeSeating && vehicle?.placesScheme?.rows &&
                <div className={'DisplayVehicleDetails-Item'}>
                  <PlacesSchemeDraw
                    schemeToDisplay={convertSchemeIntoTable(vehicle.placesScheme)}
                    addClasses={'AddVehicle-SchemeDraw'}
                  />
                </div>
              }

              <div className={'DisplayVehicleDetails-Item'}>
                <div className={'FlexBlock'}>
                  <button
                    type={'button'}
                    className={'RegularButton FlexBlock-HorizontalItem_W48'}
                    onClick={() => handleEditVehicleClick()}
                  >
                    {translate('Редактировать')}
                  </button>

                  <button
                    type={'button'}
                    className={'RegularButton RegularButton_Contrast FlexBlock-HorizontalItem_W48'}
                    onClick={() => goBack()}
                  >
                    {translate('Вернуться')}
                  </button>
                </div>
              </div>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey='trips'>
            <DisplayVehicleTrips
              addClasses={'App-Screen'}
              isLoading={isLoading}
              trips={trips}
              vehicle={vehicle}
              deleteTrip={deleteTripClick}
              isReducedDisplaying={true}
            />
          </Tab.Pane>

          <Tab.Pane eventKey='addTrip'>
            <AddTripForDriverOrVehicle
              addClasses={'App-Screen App-Screen_Narrow'}
              vehicle={vehicle}
              setActiveTabKey={setActiveTabKey}
              setTrips={setTrips}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {isModal &&
        <ModalDeleteIndicationTrip
          showModal={isModal}
          setShowModal={setIsModal}
          tripId={tripIdToDelete}
          deleteTrip={deleteVehicleTrip}
        />
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </>
  );
  //#endregion
};

export default DisplayVehicleDetails;
