import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../context/appContext";
import DebtsList from "./DebtsList";
import {entitiesNumberInterface} from "../utils/models";
import useTranslation from "../hooks/useTranslation";

const Debtors = ({
  addClasses = '',
  handleDebtorCardClick = () => {
  },
}) => {
  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region get userData from context
  const {user} = useContext(AppContext);
  //#endregion

  //#region Manage screens
  const [isArchive, setIsArchive] = useState(false);
  const [counterpartiesToDisplay, setCounterPartiesToDisplay] = useState(
    user.debtors);

  const handleArchiveButtonClick = () => {
    if (isArchive) {
      setCounterPartiesToDisplay(user.debtors);
      setIsArchive(false);
    } else {
      setCounterPartiesToDisplay(user.archivedExchanges
        .filter(exchange => exchange.recipient.idPost === user.idPost));
      setIsArchive(true);
    }
  }

  useEffect(() => {
    if (user?.idPost && isArchive) {
      setCounterPartiesToDisplay(user.archivedExchanges
        .filter(exchange => exchange.recipient.idPost === user.idPost));
    }
  }, [user]);
  //#end region

  //#region Render
  return (
    <React.Fragment>
      <div className={`Debtors ${addClasses}`}>
        <button
          type={'button'}
          className={'RegularButton RegularButton_Centered'}
          onClick={() => handleArchiveButtonClick()}
        >
          {isArchive ?
            translate('Выйти из архива')
            :
            translate('В архив')
          }
        </button>

        <div className={'RegularTitle RegularTitle_Small'}>
          {!isArchive && translate('Мои должники')}
        </div>

        <div className={'PaddingBlock'}></div>

        <DebtsList
          counterparties={counterpartiesToDisplay}
          isCreditor={false}
          addClasses={'Debtors-DebtsList'}
          handleCardClick={handleDebtorCardClick}
          isArchive={isArchive}
          cardsNumber={isArchive
            ? entitiesNumberInterface.counterpartyCardsReduced
            : entitiesNumberInterface.counterpartyCards}
        />
      </div>
    </React.Fragment>
  );
  //#endregion
};

export default Debtors;
