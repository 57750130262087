import React, {memo, useEffect, useState, useContext} from 'react';
import {getProfitAmountFromReferral} from "../utils/helpers";
import {AppContext} from "../context/appContext";
import LoaderSmall from "./LoaderSmall";
import useTranslation from "../hooks/useTranslation";

const ReferralCard = memo(function ReferralCard({
  addClasses='',
  referral,
}) {
  //#region Get user from context
  const { user: { idPost, balanceAccount } } = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Profit from referral
  const [profitAmount, setProfitAmount] = useState(0);

  useEffect(() => {
    getProfitAmountFromReferral(idPost, balanceAccount, referral.idPost)
      .then((amount) => setProfitAmount(amount))
      .finally(() => setIsLoading(false))
  }, []);
  //#endregion


  //#region Render
  return (
    <div className={`TransactionsHistory-Item ${addClasses}`}>
      <div className={'TransactionsHistory-Description'}>
        <span
          className={'TransactionsHistory-DescriptionText'}
        >
          {referral.fullName}
        </span>

        <span
          className={'TransactionsHistory-DescriptionAmount'}
        >
          {isLoading ?
            <LoaderSmall />
            :
            <span>
              {profitAmount} {translate('грн.')}
            </span>
          }
        </span>
      </div>

      <div className={'TransactionsHistory-CounterParty'}>
      </div>
    </div>
  );
  //#endregion
});

export default ReferralCard;
