import React, {useContext} from 'react';
import {AppContext} from "../context/appContext";
import {getTranslation} from "../utils/helpers";

const useTranslation = () => {
  const { dictionary, defaultDictionary } = useContext(AppContext);

  const translate = (key) => getTranslation(key, dictionary, defaultDictionary);

  return translate;
};

export default useTranslation;
