import React from 'react';
import {FaTelegram, FaViber, FaWhatsapp} from "react-icons/fa";
import '../styles/MessengersRow.scss';

const MessengersRow = ({
  addClasses = '',
  phoneInputChangeValueHandler,
  phone,
  index
}) => {
  //#region Render
  return (
    <>
      <div className={`MessengersRow ${addClasses}`}>
        <input
          type={'checkbox'}
          className={'MessengerCheckBox'}
          onChange={(event) => phoneInputChangeValueHandler(
            event.target.checked, index, 'viber')}
          checked={phone?.hasViber}
          id={'hasViber'}
        />

        <label
          htmlFor={'hasViber'}
          className={'MessengerIcon MessengerIcon_Viber'}
        >
          <FaViber/>
        </label>

        <input
          type={'checkbox'}
          className={'MessengerCheckBox'}
          onChange={(event) => phoneInputChangeValueHandler(
            event.target.checked, index, 'whatsapp')}
          checked={phone?.hasWhatsApp}
          id={'hasWhatsApp'}
        />
        <label
          className={'MessengerIcon MessengerIcon_WhatsApp'}
          htmlFor={'hasWhatsApp'}
        >
          <FaWhatsapp/>
        </label>

        <input
          type={'checkbox'}
          className={'MessengerCheckBox'}
          onChange={(event) => phoneInputChangeValueHandler(
            event.target.checked, index, 'telegram')}
          checked={phone?.hasTelegram}
          id={'hasTelegram'}
        />
        <label
          className={'MessengerIcon MessengerIcon_Telegram'}
          htmlFor={'hasTelegram'}
        >
          <FaTelegram/>
        </label>
      </div>
    </>
  );
  //#endregion
};

export default MessengersRow;
