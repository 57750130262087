import React, {useEffect, useState} from 'react';
import 'react-phone-input-2/lib/material.css'
import RegistrationForm from "../components/RegistrationForm";
import {useLocation} from "react-router-dom";
import AddVehicle from "../components/AddVehicle";
import {checkInvitation} from "../utils/helpers";
import ModalNotification from "../modals/ModalNotification";
import '../styles/RegistrationPage.scss';

const RegistrationPage = ({addClasses}) => {
  //#region Manage screens displaying
  const [isVehicleScreen, setIsVehicleScreen] = useState(false);
  //#endregion

  //#region Get UrlSearchParam
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  //#endregion

  //#region Handling notification, error messages
  const [isNotification, setIsNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  //#endregion

  //#region Parse search params and set inviter or referrer
  const [invitationData, setInvitationData] = useState({});
  const [referrerData, setReferrerData] = useState({});

  useEffect(() => {
    checkInvitation(searchParams).then(result => {
      if (result[0]) {
        if (result[1]?.inviterId) {
          setInvitationData(result[1]);
        }

        if (result[1]?.referrerId) {
          setReferrerData(result[1]);
        }
      } else if(result[1]?.nonValidLink) {
        setIsNotification(true);
        setNotificationTitle('Уведомление');
        setNotificationMessage('Ссылка для регистрации невалидна. Вы будете' +
          ' зарегистрированы в качестве обычного пользователя');
      }
    }).catch(() => {});

  }, []);

  //#endregion

  //#region Render
  return (
    <div className={`RegistrationPage ${addClasses}`}>
      {isVehicleScreen ?
        <AddVehicle
          addClasses={'RegistrationPage-AddVehicle'}
          isRegistration={true}
          referrerData={referrerData}
          goBack={() => setIsVehicleScreen(false)}
        />
        :
        <RegistrationForm
          invitationData={invitationData}
          referrerData={referrerData}
          addClasses={'RegistrationPage-RegistrationForm'}
          setIsVehicleScreen={setIsVehicleScreen}
        />
      }

      {isNotification &&
        <ModalNotification
          showModal={isNotification}
          setShowModal={setIsNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      }
    </div>
  );
  //#endregion
}

export default RegistrationPage;
