import React, {useEffect, useState} from 'react';
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';

const EditTextPage = ({pageLang, pageText = '', updatePageText = () => {}}) => {
  //#region Editor
  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(pageText || '')
        .contentBlocks)));
  }, [pageLang]);

  const changePageText = () => {
    const contentState = editorState.getCurrentContent();
    const htmlContent = draftToHtml(convertToRaw(contentState));

    return htmlContent;
  }

  const onEditorStateChange = (event) => {
    const text = changePageText(event);
    updatePageText('text', text);
    setEditorState(event);
  };
  //#endregion

  //#region Render
  return (
    <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={onEditorStateChange}
    />
  );
  //#endregion
};

export default EditTextPage;
