import {
  getDocInCollection,
  updateFieldsInDocumentInCollection
} from "./firebaseConfigAndFunctions";
import {collectionsInterface} from "./models";

export const getFieldsToUpdateForDriverInCompany = (updatedFields) => {
  const fieldsToUpdate = {};

  const companyDriverFields = ['email', 'fullName', 'phoneNumbers', 'photoUrl'];

  Object.keys(updatedFields).forEach(field => {
    if (companyDriverFields.includes(field)) {
      fieldsToUpdate[field] = updatedFields[field];
    }
  });

  return fieldsToUpdate;
}

export const updateUserInCompany = async (companyId, driverId, updatedFields) => {
  const fieldsToUpdate = getFieldsToUpdateForDriverInCompany(updatedFields);

  if (Object.keys(fieldsToUpdate).length > 0) {
    const actualCompany = await getDocInCollection(collectionsInterface.users,
      companyId);

    const updatedDrivers = actualCompany.companyDrivers.map(driver => driver
      .idPost === driverId ? {
      ...driver,
      ...fieldsToUpdate,
    } : driver);

    await updateFieldsInDocumentInCollection(collectionsInterface.users,
      companyId, {
        companyDrivers: updatedDrivers,
      });
  }
}
