import React, {useState, useContext, useEffect} from 'react';
import {AppContext} from "../context/appContext";
import {useNavigate} from "react-router-dom";
import {
  adminDataTypesInterface,
  collectionsInterface,
  pagesInterface,
  textPageModel
} from "../utils/models";
import {FaTrash} from "react-icons/fa";
import {getCollectionWhereKeyValue} from "../utils/firebaseConfigAndFunctions";
import ModalConfirmPageDelete from "../modals/ModalConfirmPageDelete";
import Loader from "./Loader";
import ReactTable from "react-table-v6";
import useTranslation from "../hooks/useTranslation";

const TextPagesList = ({
  addClasses = '',
  setCurrentPage = () => {},
}) => {
  //#region Get language from context, manage languages
  const {lang} = useContext(AppContext);
  //#endregion

  //#region Get translation function
  const translate = useTranslation();
  //#endregion

  //#region Get navigation from router
  const navigate = useNavigate();
  //#endregion

  //#region Get user from context
  const {screenTitle, setScreenTitle} = useContext(AppContext);
  //#endregion

  //#region Set title
  useEffect(() => {
    setScreenTitle('Текстовые страницы');
  }, []);
  //#endregion

  //#region Handling page delete
  const [isNotification, setIsNotification] = useState(false);
  const [pageToDelete, setPageToDelete] = useState({});

  const handleDeletePageClick = (page) => {
    setIsNotification(true);
    setPageToDelete(page);
  };
  //#endregion

  //#region Loader
  const [isLoading, setIsLoading] = useState(true);
  //#endregion

  //#region Data for React table
  const [pagesToDisplay, setPagesToDisplay] = useState([]);

  useEffect(() => {
    getCollectionWhereKeyValue(collectionsInterface.adminData, 'dataType',
      adminDataTypesInterface.textPages).then(data => {
      setPagesToDisplay(data);
    }).catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);


  const handleEditPageClick = (page) => {
    navigate(`/${pagesInterface.textPages}/addPage`)
    setCurrentPage(page);
  };

  const columns = [
    {
      id: 'id',
      Header: translate('Id'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {e.idPost === '' ?
          translate('Не указан')
          :
          e.idPost
        }
      </div>
    },
    {
      id: 'title',
      minWidth: 300,
      Header: translate('Название страницы'),
      accessor: e => <div
        className='UsersTable-Cell'
      >
        {!e.title[lang]?
          translate('Не указан')
          :
          e.title[lang]
        }
      </div>
    },
    {
      id: 'editAndDelete',
      Header: '',
      width: 80,
      accessor: e => <div className='UsersTable-Cell'>{
        <div
          className={'FlexBlock FlexBlock_SpaceAround FlexBlock_AlignCenter'}
        >
          <button
            type={'button'}
            className={'EditButton'}
            onClick={() => handleEditPageClick(e)}
          >
            <img
              src={'/img/pencil-icon.svg'}
              alt={'Pencil'}
              className={'EditIconInTable'}
            />
          </button>
          <button
            type={'button'}
            className={'DelButton'}
            onClick={() => handleDeletePageClick(e)}
          >
            <FaTrash/>
          </button>
        </div>}
      </div>
    },
  ];
  //#endregion

  //#region Add page
  const handleAddPageClick = () => {
    setCurrentPage(textPageModel);
    navigate(`/${pagesInterface.textPages}/addPage`)
  }
  //#endregion

  //#region Render
  return (
    <>
      {isLoading ? <Loader />
        :
        <div className={`TextPagesList ${addClasses}`}>
          <h2 className={'RegularTitle RegularTitle_Small DesktopTitle'}>
            {translate(screenTitle)}
          </h2>

          <div className={'PaddingBlock PaddingBlock_Half'}></div>

          <button
            type={'button'}
            className={'RegularButton'}
            onClick={() => handleAddPageClick()}
          >
            {translate('Добавить')}
          </button>

          <div className={'PaddingBlock'}></div>

          <ReactTable
            data={pagesToDisplay}
            columns={columns}
            defaultPageSize={10}
            showPageSizeOptions={false}
            pageSize={10}
            showPaginationTop={true}
            showPaginationBottom={false}
            sortable={true}
            pageText={translate('Страница')}
            ofText={translate('из')}
            previousText={translate('Предыдущая')}
            nextText={translate('Следующая')}
          />

          {isNotification &&
            <ModalConfirmPageDelete
              showModal={isNotification}
              setShowModal={setIsNotification}
              setPagesToDisplay={setPagesToDisplay}
              page={pageToDelete}
            />
          }
        </div>
      }
    </>
  );
  //#endregion
};

export default TextPagesList;
